import type { IconName } from '@/iconTypes';
import { SVGProps } from 'react';

// Import all icons statically
import * as Icons from './icons';

/**
 * This function will get the appropriate icon component
 * @param name - The name of the icon
 * @returns The icon component
 */
const getIconComponent = (name: IconName): React.FC<SVGProps<SVGSVGElement>> => {
  const IconComponent = Icons[name];
  if (!IconComponent) {
    throw new Error(`Icon with name ${name} does not exist.`);
  }
  return IconComponent;
};

const iconsMap = {} as Record<IconName, ReturnType<typeof getIconComponent>>;

/**
 * Usage: Icon.NameOfTheIconInTheSameDirectory
 *
 * ! THE FILE NAME AND EXPORT CONSTANT NAME MUST BE THE SAME.
 */
export const Icon = new Proxy(iconsMap, {
  get(_, prop: IconName) {
    if (typeof iconsMap[prop] === 'undefined') {
      iconsMap[prop] = getIconComponent(prop);
    }
    iconsMap[prop].displayName = `Icon.${prop}`;
    return iconsMap[prop];
  }
});
