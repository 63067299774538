import { getHotelImportantEvents, getHotelRoomEvents } from '@common/api/hotel';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useHotelStore } from '@common/store/auth';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const THREE_HOURS = 1000 * 60 * 60 * 3;

export const useHotelEventsByStartDateAndEndDate = ({
  startDate,
  endDate
}: {
  startDate: Date;
  endDate: Date;
}) => {
  const { hotelDetails } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();

  const query = useQuery({
    queryKey: [
      HotelQueryKeys.GET_HOTEL_EVENTS,
      dayjs(startDate).format(API_DATE_FORMAT),
      dayjs(endDate).format(API_DATE_FORMAT)
    ],
    queryFn: ({ signal }) => {
      if (!hotelDetails || !hotelAuthToken) {
        return;
      }

      return getHotelRoomEvents(
        {
          start_date: dayjs(startDate).format(API_DATE_FORMAT),
          end_date: dayjs(endDate).format(API_DATE_FORMAT),
          event_types: hotelDetails.event_types?.toString() ?? '2',
          token: hotelAuthToken
        },
        signal
      );
    },
    staleTime: THREE_HOURS
  });

  const events = useMemo(() => {
    return query.data?.data.data;
  }, [query.data]);

  const sumOfAllEvents = useMemo(() => {
    return Object.values(events ?? {}).reduce((acc, eventArray) => acc + eventArray.length, 0);
  }, [events]);

  return { query, events, sumOfAllEvents };
};

export const useHotelEvents = (day?: Date) => {
  const { hotelDetails } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();

  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_IMPORTANT_EVENTS],
    queryFn: () => getHotelImportantEvents(),
    staleTime: Infinity
  });

  const getHotelDayEvents = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_DAY_EVENTS, dayjs(day).format(API_DATE_FORMAT)],
    queryFn: ({ signal }) => {
      if (!hotelDetails || !hotelAuthToken) {
        return;
      }

      return getHotelRoomEvents(
        {
          end_date: dayjs(day).format(API_DATE_FORMAT),
          start_date: dayjs(day).format(API_DATE_FORMAT),
          event_types: hotelDetails.event_types?.toString() ?? '2',
          token: hotelAuthToken
        },
        signal
      );
    },
    enabled: !!hotelDetails && !!hotelAuthToken,
    staleTime: THREE_HOURS
  });

  const importantEvents = useMemo(() => {
    return query?.data?.data.data;
  }, [query.data]);

  const events = useMemo(() => {
    return getHotelDayEvents.data?.data?.data;
  }, [getHotelDayEvents.data]);

  const getEventNameByDay = (day: Date) => {
    if (!importantEvents) return;

    const key = dayjs(day).format(API_DATE_FORMAT);

    return importantEvents[key as keyof typeof importantEvents]?.[0].title;
  };

  const getHighRankEventByDay = (day: Date) => {
    if (!events) return;

    const key = dayjs(day).format(API_DATE_FORMAT);

    return events[key as keyof typeof events].filter((event) => {
      parseInt(event.local_rank) > 93;
    })?.[0].title;
  };

  return {
    query,
    importantEvents,
    isFetchingHotelDayEvents: getHotelDayEvents.isLoading,
    events,
    getEventNameByDay,
    getHighRankEventByDay
  };
};
