export function ErrorOutlined(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM11.5 14C11.2239 14 11 14.2239 11 14.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V14.5C13 14.2239 12.7761 14 12.5 14H11.5ZM11.32 8H12.68C12.8237 7.99896 12.9609 8.05979 13.0566 8.16698C13.1523 8.27417 13.1972 8.41735 13.18 8.56L12.78 11.78C12.7647 11.9062 12.6572 12.0009 12.53 12H11.47C11.3428 12.0009 11.2353 11.9062 11.22 11.78L10.82 8.56C10.8028 8.41735 10.8477 8.27417 10.9434 8.16698C11.0391 8.05979 11.1763 7.99896 11.32 8Z"
        fill="currentColor"
      />
    </svg>
  );
}
