import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import dayjs from 'dayjs';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import '@pages/Client/PricingStrategy/MarketIntelligence/components/ChartStyle.css';
import { ChartValue } from '@pages/Client/PricingStrategy/MarketIntelligence/types/chart';
import { useCallback, useRef } from 'react';

interface DataProps {
  data: ChartValue[];
  height?: number;
  xAxisVisible?: boolean;
  isTooltipEnabled?: boolean;
}

export const MarketIntelligenceChart = ({
  data,
  height = 200,
  xAxisVisible = false,
  isTooltipEnabled = false
}: DataProps) => {
  const grey = useTailwindColor('grey');
  const green = useTailwindColor('uiGreen');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '10px';
  const fontWeight = 400;

  const lastLabelDisplayedRef = useRef('');

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white" className="bg-opacity-90 backdrop-blur">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dayjs(dataPoint.date).format('ddd, DD MMM YYYY')}
        </Typography>
        <div className="flex items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-uiGreen" />
          <Typography variant="meta-2" color="darkGrey">
            Value: <b>£{dataPoint.value}</b>
          </Typography>
        </div>
      </Card>
    );
  };

  const dataMax = Math.floor(Math.max(...data.map((item) => item.value as number)) * 1.1);

  const datMin = Math.floor(
    Math.min(...data.filter((item) => item.value !== 0).map((item) => item.value as number)) * 0.9
  );

  const xAxisTickFormatter = useCallback((value: any) => {
    const currentLabel = dayjs(value).tz().format('MMM YYYY');

    if (currentLabel === lastLabelDisplayedRef.current) {
      return '';
    }

    lastLabelDisplayedRef.current = currentLabel;
    return currentLabel;
  }, []);

  return (
    <Card backgroundColor="white" className="p-0 shadow-none">
      <ResponsiveContainer width="100%" height={height}>
        <LineChart
          data={data}
          style={{
            hover: {
              cursor: 'pointer'
            }
          }}>
          <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
          {isTooltipEnabled ? <Tooltip content={(props) => <CustomTooltip {...props} />} /> : null}
          {xAxisVisible && (
            <XAxis
              axisLine={false}
              tick={{ fill: grey }}
              tickCount={8}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              tickFormatter={xAxisTickFormatter}
              visibility={xAxisVisible ? 'visible' : 'hidden'}
              dataKey={'date'}
              interval="preserveStartEnd"
              textAnchor="end"
              height={45}
              angle={-45}
            />
          )}
          <YAxis
            axisLine={false}
            tick={{ fill: grey }}
            tickCount={8}
            tickLine={false}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            domain={[datMin, dataMax]}
            dataKey={'value'}
            allowDataOverflow={true}
            tickFormatter={(value) => `£${value}`}
          />
          <Line
            type="monotone"
            strokeWidth={1.5}
            dataKey="value"
            stroke={green}
            dot={false}
            connectNulls={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};
