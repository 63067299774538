import { Modal } from '@common/components/molecules/Modal';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { SelectedPartner } from '@pages/Admin/PartnerUsers/types/selectedPartner';
import { useDeletePartner } from '@pages/Admin/PartnerUsers/hooks/useDeletePartner';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  user?: SelectedPartner;
};

export const DeletePartnerModal = ({ user, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { deletePartner } = useDeletePartner();

  const handleDeletePartner = () => {
    if (user?.id) {
      deletePartner(user?.id);
    }
  };
  return (
    <Modal
      onClick={handleDeletePartner}
      open={isOpen}
      okText="Delete"
      onClose={onClose}
      size={'sm'}
      continueIntent="danger"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-error p-3">
                <Icon.Delete color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t('Delete Partner: ') + ' '}
              {user?.name}
            </Typography>
            <Typography>
              {t('Are you sure you want to delete') + ' '}
              {user?.name}
            </Typography>
          </div>
        </>
      }
    />
  );
};
