import { registerPms } from '@common/api/hotel';
import { RegisterPmsPayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useRegisterPms = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: RegisterPmsPayload) => {
      return registerPms(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_LIST] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', `Failed to add ${channelManagerOrPms} connection`);
    }
  });

  return {
    registerPms: mutate,
    isLoading
  };
};
