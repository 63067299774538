import { FormInput } from '@common/components/atoms/Input';
import { Fragment, useEffect, useState } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Switcher } from '@common/components/atoms/Switcher';
import { isEmpty, isNil, map, result, round } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'use-media';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Typography } from '@common/components/foundations/Typography';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import dayjs from 'dayjs';
import { useRecommendedPrice } from '@pages/Client/Calendar/components/Tables/hooks';
import { Icon } from '@common/components/foundations/icons';
import { useBuildAdjustmentInputsArray } from '@pages/Client/Calendar/components/BulkEdit/hooks/useBuildAdjustmentInputsArray';
import { useFormPreflight } from '@pages/Client/Calendar/components/BulkEdit/hooks/useFormPreflight';
import { absoluteOrPercentage } from '@pages/Client/Calendar/components/BulkEdit/helpers/absoluteOrPercentage';
import { MinMax } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minMaxSchema';
import { useFeaturesStore } from '@common/store/features';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useViewStore } from '@common/store/view';
import { Group } from '@mantine/core';

export function MinMaxAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.MIN_MAX;
  const { hotelDetails } = useHotelDetails();
  const { pricingSettings, getRoomData } = useRoomPrices();
  const { view } = useViewStore();
  const isMobile = useMedia({ minWidth: 768 });
  const { displayHotelRooms } = useHotelRoomsList();
  const { preflightCallback, isUpdating } = useFormPreflight();
  const adjustmentFields: MinMax[] = useBuildAdjustmentInputsArray<MinMax>(formName);
  const [prefillDerivedRooms, setPrefillDerivedRooms] = useState(true);
  const { features } = useFeaturesStore();
  const { control, setValue, formState, getValues, watch, trigger } = useFormContext();
  const { fields, replace } = useFieldArray({ name: formName, control });
  const editDate = getValues('editDate');
  const isBulkEdit = getValues('isBulkEdit');
  const { recommendedPriceData } = useRecommendedPrice(dayjs(editDate));
  const enabledFeature = features?.includes(6) ?? false;

  function updatePrefillDerivedRooms(name?: string) {
    const minRef = Number(getValues(`${formName}.0.minPrice`));
    const maxRef = Number(getValues(`${formName}.0.maxPrice`));
    map(fields, (_, index) => {
      const root = `${formName}.${index}`;
      const isAbsolute = Boolean(getValues(`${root}.isAbsolute`));
      const adjustment = Number(getValues(`${root}.adjustment`));
      if (prefillDerivedRooms && name === `${formName}.0.minPrice`) {
        if (index > 0) {
          setValue(`${root}.minPrice`, absoluteOrPercentage(isAbsolute, minRef, adjustment));
        }
      }
      if (prefillDerivedRooms && name === `${formName}.0.maxPrice`) {
        if (index > 0) {
          setValue(`${root}.maxPrice`, absoluteOrPercentage(isAbsolute, maxRef, adjustment));
        }
      }
    });
    trigger(formName);
  }

  useEffect(() => {
    if (isEmpty(fields) && !isEmpty(pricingSettings)) replace(adjustmentFields);
  }, [adjustmentFields, pricingSettings]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      updatePrefillDerivedRooms(name);
      trigger(formName);
      if (type === 'change') {
        preflightCallback();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, prefillDerivedRooms, fields]);

  return (
    <Fragment>
      <Group justify="space-between" align="center">
        {pricingSettings?.hotel?.all_room_pricing ? (
          <Switcher
            checked={prefillDerivedRooms}
            onChange={setPrefillDerivedRooms}
            size="small"
            label={`${t(`Prefill Derived ${hotelDetails?.room_apartment_space_name}s`)}`}
            className="text-sm font-medium text-copyTextGrey"
          />
        ) : null}
        {!isBulkEdit && isUpdating ? (
          <Icon.LoadingCircle className="ml-2 inline-block opacity-50" />
        ) : null}
      </Group>
      <LineTable>
        <colgroup>
          <col width={!isBulkEdit && view === 'admin' ? '22.5%' : '25%'} />
          <col width={!isBulkEdit && view === 'admin' ? '23.75%' : '25%'} />
          <col width={!isBulkEdit && view === 'admin' ? '23.75%' : '25%'} />
          {!isBulkEdit && view === 'admin' ? <col width="20%" /> : null}
          {!isBulkEdit && view === 'client' ? <col width="25%" /> : null}
          {!isBulkEdit && view === 'admin' ? <col width="10%" /> : null}
        </colgroup>
        <thead>
          <tr>
            <th>{hotelDetails?.room_apartment_space_name}</th>
            <th>{t('Minimum Price')}</th>
            <th>{t('Maximum Price')}</th>
            {!isBulkEdit ? <th className="text-center">{t('Recommended/Fix Price')}</th> : null}
            {!isBulkEdit && view === 'admin' ? (
              <th className="text-center">{t('Price in PMS')}</th>
            ) : null}
          </tr>
        </thead>

        <tbody>
          {map(fields, (field, index) => {
            const room = displayHotelRooms[index];
            const errorMin = result(formState, `errors.${formName}[${index}].minPrice.message`);
            const errorMax = result(formState, `errors.${formName}[${index}].maxPrice.message`);
            const isReferenceRoom = room?.is_reference_room ?? false;
            const isEditable = (isReferenceRoom || enabledFeature) ?? false;

            return !isNil(room) ? (
              <tr key={field.id}>
                <td>
                  {room?.name} {`${import.meta.env.DEV ? room?.id : ''}`}
                </td>
                <td className="align-top">
                  {isEditable ? (
                    <FormInput
                      type="number"
                      nameKey={`${formName}.${index}.id` as const}
                      valueKey={`${formName}.${index}.minPrice` as const}
                      background="grey"
                      leadingAddon={!isMobile ? null : CurrencyFormatter.currencySymbol()}
                      trailingAddon={
                        !isMobile ? null : (
                          <span className="hidden flex-col items-end md:flex">
                            <Typography variant="meta-3" color="grey">
                              {t('Default')}
                            </Typography>
                            <Typography variant="meta-2" color="grey">
                              {CurrencyFormatter.format(
                                round(getValues(`${formName}.${index}.defaultMinPrice`)) || 0
                              )}
                            </Typography>
                          </span>
                        )
                      }
                      helperMessage={errorMin}
                      showClearButton={false}
                    />
                  ) : (
                    <div className="flex items-center justify-center gap-2">
                      <Typography>
                        {CurrencyFormatter.format(getValues(`${formName}.${index}.minPrice`), '-')}
                      </Typography>
                      {result(formState, `errors.${formName}[${index}].minPrice`) ? (
                        <div className="h-2.5 w-2.5 rounded-full bg-error" />
                      ) : null}
                    </div>
                  )}
                </td>
                <td className="align-top">
                  {isEditable ? (
                    <FormInput
                      type="number"
                      nameKey={`${formName}.${index}.id` as const}
                      valueKey={`${formName}.${index}.maxPrice` as const}
                      background="grey"
                      leadingAddon={!isMobile ? null : CurrencyFormatter.currencySymbol()}
                      trailingAddon={
                        !isMobile ? null : (
                          <span className="hidden flex-col items-end md:flex">
                            <Typography variant="meta-3" color="grey">
                              {t('Default')}
                            </Typography>
                            <Typography variant="meta-2" color="grey">
                              {CurrencyFormatter.format(
                                round(getValues(`${formName}.${index}.defaultMaxPrice`)) || 0
                              )}
                            </Typography>
                          </span>
                        )
                      }
                      helperMessage={errorMax}
                      showClearButton={false}
                    />
                  ) : (
                    <div className="flex items-center justify-center gap-2">
                      <Typography>
                        {CurrencyFormatter.format(getValues(`${formName}.${index}.maxPrice`), '-')}
                      </Typography>
                      {result(formState, `errors.${formName}[${index}].maxPrice`) ? (
                        <div className="h-2.5 w-2.5 rounded-full bg-error" />
                      ) : null}
                    </div>
                  )}
                </td>
                {!isBulkEdit ? (
                  <>
                    <td className="text-center">
                      {CurrencyFormatter.format(
                        Math.round(recommendedPriceData?.[room.id] ?? 0),
                        '-'
                      )}
                    </td>

                    {view === 'admin' ? (
                      <td className="text-center">
                        {CurrencyFormatter.format(
                          getRoomData(room.id, dayjs(editDate).format(API_DATE_FORMAT))
                            ?.original_price,
                          '-'
                        )}
                      </td>
                    ) : null}
                  </>
                ) : null}
              </tr>
            ) : null;
          })}
        </tbody>
      </LineTable>
    </Fragment>
  );
}
