import { getHotelPmsDataMap } from '@common/api/hotel';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useHotelPmsDataMap = () => {
  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP],
    queryFn: getHotelPmsDataMap
  });
  const hotelPmsDataMap = useMemo(() => {
    return query.data;
  }, [query.data]);

  return {
    query,
    hotelPmsDataMap
  };
};
