import {
  addRatePlansRestriction,
  getRateRestrictions,
  getRoomTypesRestrictions
} from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useRoomRestrictions = () => {
  const query = useInfiniteQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_ROOM_RESTRICTIONS],
    queryFn: ({ pageParam = 1 }) => getRoomTypesRestrictions(pageParam),
    getNextPageParam: (lastPage) => {
      const nextPageUrl = lastPage.metadata.next;
      if (nextPageUrl) {
        const url = new URL(nextPageUrl.toString());
        const pageParam = url.searchParams.get('page');
        return pageParam ? Number(pageParam) : undefined;
      }
      return undefined;
    },
    initialPageParam: 1
  });

  return {
    query,
    refetchRooms: query.refetch,
    isGetRoomListLoading: query.isLoading || query.isFetching,
    roomTypes: query.data?.pages.flatMap((page) => page.data) || [],
    fetchNextPage: query.fetchNextPage,
    hasNextPage: query.hasNextPage
  };
};

export const useRateRestrictions = (code: string | null | undefined) => {
  const query = useInfiniteQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_RATE_RESTRICTIONS, code],
    queryFn: ({ pageParam = 1 }) => getRateRestrictions(code, pageParam),
    getNextPageParam: (lastPage) => {
      const nextPageUrl = lastPage.metadata.next;
      if (nextPageUrl) {
        const url = new URL(nextPageUrl.toString());
        const pageParam = url.searchParams.get('page');
        return pageParam ? Number(pageParam) : undefined;
      }
      return undefined;
    },
    enabled: !!code,
    initialPageParam: 1
  });

  return {
    query,
    refetchRates: query.refetch,
    isGetRateLoading: query.isLoading || query.isFetching,
    ratePlans: query.data?.pages.flatMap((page) => page.data) || [],
    fetchNextPageRates: query.fetchNextPage,
    ratesHasNextPage: query.hasNextPage
  };
};

export const useAddRatePlansRestriction = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: addRatePlansRestriction,
    onSuccess: () => {
      addNotification('success', 'Rate Plan Restrictions Updated Successfully');
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_ROOM_RESTRICTIONS] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_RATE_RESTRICTIONS] });
    },
    onError: () => {
      addNotification('fail', 'Failed to update rate plan restrictions');
    }
  });

  return {
    saveRatePlansRestrictions: mutate,
    isLoading,
    isSuccess
  };
};
