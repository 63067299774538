import { RunPricingSetupStep } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/RunPricingSetupStep';
import { SetMinMaxDerivedRoomsSetupStep } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/SetMinMaxDerivedRoomsSetupStep';
import { SetNumberOfRoomsSetupStep } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/SetNumberOfRoomsSetupStep';
import { SetPMSAverageSetupStep } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/SetPMSAverageSetupStep';
import { SetAllRoomsSetupStep } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/SetupAllRoomsSetupStep';

export type StepComponentProps = {
  index?: number;
};

export const setupSteps = [
  { id: 'setup-all-rooms', component: SetAllRoomsSetupStep },
  { id: 'run-pricing', component: RunPricingSetupStep },
  { id: 'set-number-of-rooms', component: SetNumberOfRoomsSetupStep },
  { id: 'get-pms-average-all-rooms', component: SetPMSAverageSetupStep },
  { id: 'set-min-max-derived-rooms', component: SetMinMaxDerivedRoomsSetupStep }
];

export function AdminSetupRoomsCard() {
  return (
    <div className="h-full rounded-lg bg-white p-4">
      <div className="flex flex-wrap items-center justify-start gap-2 space-x-2 space-y-2 align-middle sm:space-x-0 sm:space-y-0">
        {setupSteps.map((step, index) => {
          const StepComponent = step.component;
          return <StepComponent key={step.id} index={index + 1} />;
        })}
      </div>
    </div>
  );
}
