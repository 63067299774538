export const Refresh = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M20.4999 10.0002H15.4999C15.2238 10.0002 14.9999 9.77638 14.9999 9.50024V9.21024C14.9979 9.07465 15.0523 8.94431 15.1499 8.85024L16.9299 7.07024C15.6297 5.74853 13.854 5.00296 11.9999 5.00024C8.19706 5.00882 5.09669 8.05195 5.01723 11.854C4.93778 15.656 7.90832 18.826 11.7075 18.9934C15.5067 19.1609 18.7446 16.2645 18.9999 12.4702C19.0158 12.2059 19.2351 11.9998 19.4999 12.0002H20.4999C20.6362 12.0043 20.7655 12.0618 20.8599 12.1602C20.9518 12.2607 20.9988 12.3943 20.9899 12.5302C20.7415 16.7672 17.5668 20.2549 13.3717 20.8993C9.17666 21.5436 5.10156 19.1696 3.59301 15.2025C2.08446 11.2353 3.55252 6.75345 7.11582 4.44762C10.6791 2.14179 15.369 2.63879 18.3699 5.64024L19.8599 4.15024C19.9516 4.0551 20.0778 4.00101 20.2099 4.00024H20.4999C20.7761 4.00024 20.9999 4.2241 20.9999 4.50024V9.50024C20.9999 9.77638 20.7761 10.0002 20.4999 10.0002Z" />
    </svg>
  );
};
