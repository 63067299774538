import { isUndefined } from 'lodash-es';
import * as z from 'zod';
import { isValid } from '@pages/Client/Calendar/components/BulkEdit/helpers/isValidValidationNumber';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';
import { isPositive } from '@pages/Client/Calendar/components/BulkEdit/types/helpers';

export const baseSchemaClosedSpacesSchema = z.object({
  id: z.number(),
  value: z.union([z.number(), z.nan(), z.null()]).optional(),
  numberOfRooms: z.union([z.number(), z.nan(), z.null()]).optional(),
  isBulkEdit: z.boolean().default(true)
});

/**
 * Form Validation Logic for Closed Spaces Schema:
 *
 * - First Validation Rule:
 *   - Condition:
 *     - If both 'value' and 'numberOfRooms' are valid and defined,
 *       then 'value' must be less than or equal to 'numberOfRooms'.
 *   - Message:
 *     - If validation fails, the error message will indicate the number of rooms available to close.
 *       If 'numberOfRooms' is 0, the message will say 'zero rooms to close',
 *       otherwise it will specify 'only X rooms to close', where X is the number of rooms.
 *
 * - Second Validation Rule:
 *   - Condition:
 *     - The 'value' must not have any decimal places and must be a positive number.
 *   - Message:
 *     - If validation fails, the error message will be 'Value is not allowed.'
 *
 * These rules are applied to 'closedSpacesSchemaZodOutputSchema', which is based on 'baseSchemaClosedSpacesSchema'.
 */

export const closedSpacesSchemaZodOutputSchema = baseSchemaClosedSpacesSchema
  .refine(
    (val) =>
      isValid(val?.value) && !isUndefined(val?.numberOfRooms)
        ? Number(val?.value) <= Number(val?.numberOfRooms)
        : true,
    (val) => ({
      message: `There are ${
        val?.numberOfRooms === 0 ? 'zero' : `only ${val?.numberOfRooms}`
      } rooms to close.`
    })
  )
  .refine((val) => validateNoDecimals(val?.value) && isPositive(val?.value), {
    message: 'Value is not allowed.'
  });

export type ClosedSpaces = z.infer<typeof baseSchemaClosedSpacesSchema>;
