import { Divider } from '@common/components/atoms/Divider';
import {
  GoogleAutoCompleteInput,
  GoogleMapsLibrary
} from '@common/components/atoms/GoogleMapsAutoComplete';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { getEnvVar } from '@common/utils/env';
import { useMemo, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { Button } from '@common/components/atoms/Button';
import { useViewStore } from '@common/store/view';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoadingStore } from '@pages/Client/Account/Property/store/loading';
import { HotelFormData } from '@pages/Client/Account/Property/types/property';
import {
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Tooltip
} from '@common/components/molecules/Tooltip';
import { useCountryList, useCurrencyList } from '@common/hooks/useSystem';

const libraries = [GoogleMapsLibrary.Places];

export function NameAndLocation() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    watch
  } = useFormContext();
  const { isLoading } = useLoadingStore();
  const { view } = useViewStore();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: getEnvVar('VITE_GOOGLEAPIKEY'),
    libraries
  });

  const {
    name,
    country_id,
    currency_id,
    pms,
    postcode,
    timezone,
    starts_monday,
    room_apartment_space,
    enc_hotel_id
  }: Partial<HotelFormData> = getValues();

  const {
    countryList,
    query: { isLoading: countryFetchLoading }
  } = useCountryList();
  const {
    currencyList,
    query: { isLoading: currencyFetchLoading }
  } = useCurrencyList();
  const [isCopySuccess, setIsCopySuccess] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setIsCopySuccess(true);

          setTimeout(() => {
            setIsCopySuccess(false);
          }, 3000);
        })
        .catch(() => {
          setIsCopySuccess(false);
        });
    }
  };

  const _PREFERRED_DATE_FORMAT = [
    {
      label: 'DD/MM/YYYY, hh:mm A',
      value: 'DD/MM/YYYY, hh:mm A'
    },
    {
      label: 'MM/DD/YYYY, hh:mm A',
      value: 'MM/DD/YYYY, hh:mm A'
    }
  ];

  const START_CALENDAR = [
    {
      label: t('Sunday'),
      value: false
    },
    {
      label: t('Monday'),
      value: true
    }
  ];

  const TYPES_OF_UNITS = [
    {
      label: t('Room'),
      value: 1
    },
    {
      label: t('Apartment'),
      value: 2
    }
  ];

  const center = useMemo(
    () => ({ lat: +watch('lat'), lng: +watch('lng') }),
    [watch('lat'), watch('lng')]
  );

  return (
    <>
      <div className="flex flex-col py-7 md:flex-row lg:flex-row">
        <div className="mb-2 flex flex-1 flex-col sm:w-full md:w-1/2 lg:w-1/2">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Property Name')}
          </Typography>
        </div>
        <div className="flex-1 sm:w-full  md:w-1/2  lg:w-1/2">
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <Controller
              name="name"
              control={control}
              defaultValue={name}
              rules={{
                required: `${t('Please enter hotel name')}`
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  placeholder={`${t('Please enter property name')}`}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  onClear={() => onChange('')}
                  error={errors.name ? true : false}
                  type="text"
                  helperMessage={
                    error ? (
                      <>
                        <div className="flex items-center gap-2 text-error">
                          <Icon.WarningOutline className="w-4" />
                          <Typography element="span" color="error" variant="meta-2">
                            {t(`${error?.message || ''}`)}
                          </Typography>
                        </div>
                      </>
                    ) : null
                  }
                />
              )}
            />
          )}
        </div>
      </div>
      <Divider />
      <div className="flex flex-col py-7 md:flex-row lg:flex-row">
        <div className="mb-2 flex flex-1 flex-col sm:w-full md:w-1/2 lg:w-1/2">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Property Location')}
          </Typography>
        </div>
        <div className="flex-1 sm:w-full md:w-1/2 lg:w-1/2">
          <div className="flex flex-col gap-4">
            <div className="w-full flex-1">
              {!isLoaded ? <Skeleton className="h-10 w-full" /> : <GoogleAutoCompleteInput />}
            </div>
            <div className="flex gap-3">
              <div className="w-1/3">
                {isLoading ? (
                  <Skeleton className="h-10 w-full" />
                ) : (
                  <Controller
                    name="postcode"
                    control={control}
                    defaultValue={postcode}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        placeholder={t('Postcode') as string}
                        label={t('Postcode') as string}
                        background="white"
                        onBlur={onBlur}
                        onChange={onChange}
                        showClearButton={false}
                        value={value}
                        type="text"
                      />
                    )}
                  />
                )}
              </div>
              <div className="w-full">
                {isLoading ? (
                  <Skeleton className="h-10 w-full" />
                ) : (
                  <>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t('Country')}
                    </Typography>
                    <SelectDropdown
                      global
                      fullWidth
                      name="country_id"
                      value={country_id}
                      options={
                        countryFetchLoading
                          ? []
                          : countryList?.map((item) => ({ label: item.name, value: item.id }))
                      }
                    />
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-3">
              {view === 'admin' ? (
                <>
                  <div className="w-1/4">
                    {isLoading ? (
                      <Skeleton className="h-10 w-full" />
                    ) : (
                      <Controller
                        name="lat"
                        control={control}
                        render={({ field }) => (
                          <Input
                            showClearButton={false}
                            label={`${t('Latitude')}`}
                            background="grey"
                            readOnly
                            {...field}
                            type="text"
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="w-1/4 ">
                    {isLoading ? (
                      <Skeleton className="h-10 w-full" />
                    ) : (
                      <Controller
                        name="lng"
                        control={control}
                        render={({ field }) => (
                          <Input
                            showClearButton={false}
                            label={`${t('Longitude')}`}
                            placeholder={`${t('Longitude')}`}
                            background="grey"
                            readOnly
                            {...field}
                            type="text"
                          />
                        )}
                      />
                    )}
                  </div>
                </>
              ) : null}
              <div className="w-full flex-1">
                {isLoading ? (
                  <Skeleton className="h-10 w-full" />
                ) : (
                  <Controller
                    name="timezone"
                    control={control}
                    defaultValue={timezone}
                    render={({ field }) => (
                      <Input
                        showClearButton={false}
                        label={`${t('Timezone')}`}
                        placeholder={`${t('Timezone')}`}
                        background="grey"
                        readOnly
                        {...field}
                        type="text"
                      />
                    )}
                  />
                )}
              </div>
            </div>

            <div className="h-[200px] w-full">
              {getValues().lat && getValues().lng && center ? (
                !isLoaded ? (
                  <Skeleton className="h-[400px] w-full" />
                ) : (
                  <GoogleMap
                    mapContainerClassName="h-full w-full"
                    center={center}
                    zoom={10}
                    options={{
                      disableDefaultUI: true,
                      zoomControl: true,
                      fullscreenControl: true
                    }}>
                    <Marker
                      position={{
                        lat: center.lat,
                        lng: center.lng
                      }}
                    />
                  </GoogleMap>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col items-start py-7 md:flex-row lg:flex-row">
        <div className="w-1/2 flex-1">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Calendar Preferences')}
          </Typography>
        </div>
        <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
          <div className="flex flex-col gap-4">
            <div className="w-full flex-1">
              {isLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <SelectDropdown
                  global
                  hint={t('Currency') as string}
                  fullWidth
                  name="currency_id"
                  value={currency_id}
                  options={
                    currencyFetchLoading
                      ? []
                      : currencyList?.map((item) => ({
                          label: `${item.name} (${item.symbol})`,
                          value: item.id
                        }))
                  }
                />
              )}
            </div>
            <div className="flex gap-3">
              <div className="w-full flex-1">
                {isLoading ? (
                  <Skeleton className="h-10 w-full" />
                ) : (
                  <SelectDropdown
                    global
                    hint={t('Start Calendar From') as string}
                    fullWidth
                    name="starts_monday"
                    value={starts_monday}
                    options={START_CALENDAR}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col py-7 md:flex-row lg:flex-row">
        <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Types of Units')}
          </Typography>
        </div>
        <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <SelectDropdown
              global
              fullWidth
              name="room_apartment_space"
              value={room_apartment_space}
              options={TYPES_OF_UNITS}
            />
          )}
        </div>
      </div>
      {view === 'admin' ? <Divider /> : null}
      {view === 'admin' ? (
        <>
          <div className="flex flex-col py-7 md:flex-row lg:flex-row">
            <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
              <Typography element="p" variant="meta-1" color="copyTextGrey">
                {t('PMS ID')}
              </Typography>
            </div>
            <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
              {isLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <Controller
                  name="pms"
                  control={control}
                  defaultValue={pms}
                  render={({ field }) => (
                    <Input
                      placeholder={'Enter PMS ID'}
                      background="grey"
                      readOnly
                      showClearButton={false}
                      {...field}
                      type="text"
                    />
                  )}
                />
              )}
            </div>
          </div>
          <Divider />
          <div className="flex flex-col py-7 md:flex-row lg:flex-row">
            <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
              <Typography element="p" variant="meta-1" color="copyTextGrey">
                {t('Iframe Hotel ID')}
              </Typography>
            </div>
            <div className="w-full flex-1 md:w-1/2 lg:w-1/2">
              <div className="grid grid-cols-12 items-center gap-2">
                <div className="col-span-11">
                  <Controller
                    name="enc_hotel_id"
                    control={control}
                    defaultValue={enc_hotel_id}
                    render={({ field }) => (
                      <Input
                        showClearButton={false}
                        readOnly
                        background="grey"
                        {...field}
                        ref={inputRef}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="group relative col-span-1">
                  <TooltipProvider>
                    <Tooltip delayDuration={200}>
                      <TooltipTrigger>
                        <Button icon intent="primary" size="small" onClick={handleCopy}>
                          {isCopySuccess ? (
                            <Icon.CheckList className="h-5 w-5  text-uiGreen" />
                          ) : (
                            <Icon.Copy className="h-5 w-5  text-opacity-100" />
                          )}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="bg-white text-darkGrey">
                        {isCopySuccess ? 'Copied!' : 'Copy'}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
