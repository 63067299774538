import { Hotel } from '@pages/Client/Account/Profile/hooks/useUserDetail';
import { isEmpty, filter } from 'lodash-es';

export function getClientHotelId(hotelList: Hotel[]): number | null {
  if (isEmpty(hotelList)) return null;
  return (
    filter(
      hotelList,
      (hotel) =>
        (hotel.state === 0 && !hotel.is_client_access_disabled) ||
        (hotel.state === 1 && !hotel.is_client_access_disabled)
    )[0]?.id ?? hotelList[0].id
  );
}
