import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';

export const ScheduleReportsHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-5">
      <Typography element="h3" variant="h3" color="darkGrey">
        {t('Scheduled Reports')}
      </Typography>
      <div className="mt-3">
        <Typography element="p" variant="paragraph-3" color="copyTextGrey">
          {t(
            `Set up scheduled reports and keep your colleagues and other stackeholders informed about your property's performance.`
          )}
        </Typography>
      </div>
    </div>
  );
};
