import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { getEnvVar } from '@common/utils/env';
import { zodResolver } from '@hookform/resolvers/zod';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react18-json-view/src/style.css';
import * as z from 'zod';
import { useEditNote } from '@pages/Client/Notes/hooks/useNotes';
import { EditNote } from '@pages/Client/Notes/types/note';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  note: EditNote;
};

const schema = z.object({
  note: z.string().nonempty('Note is required')
});

export const EditNoteModal = ({ isOpen, onClose, note }: Props) => {
  const { t } = useTranslation();
  const { editNote } = useEditNote();

  const { reset, control, getValues, handleSubmit, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      note: note.note
    },
    resolver: zodResolver(schema)
  });

  const onSubmit = async () => {
    if (note.id) {
      await editNote({
        id: note.id,
        note: getValues('note')
      });
    }
    onClose();
  };

  useEffect(() => {
    if (note.id) {
      setValue('note', note.note);
    }
  }, [note]);

  return (
    <Modal
      showFooter={false}
      open={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      overFlowHidden
      size="custom">
      <div className="min-w-[50vw] max-w-[50vw] rounded-xl bg-white">
        <div className="p-4">
          <Typography variant="h5" element="h5" color="darkGrey">
            {t('Edit Note')}
          </Typography>
          <div className="mt-5 flex min-h-[100px] flex-col gap-4">
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange } }) => (
                <Editor
                  apiKey={`${getEnvVar('VITE_TINYMCE')}`}
                  initialValue={note?.note}
                  init={{
                    height: 320,
                    menubar: false,
                    branding: false,
                    plugins:
                      'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link  template codesample table  pagebreak nonbreaking anchor insertdatetime  lists wordcount  help charmap emoticons',
                    toolbar:
                      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | emoticons fullscreen link  codesample '
                  }}
                  onChange={(_event, editor) => {
                    const data = editor.getContent();
                    onChange(data);
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => onClose()}
              className="text-copyTextGrey">
              {t('Cancel')}
            </Button>
            <Button type="button" intent="primary" onClick={handleSubmit(onSubmit)}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
