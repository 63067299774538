import { axiosInstance } from '@common/api/axiosInstance';
import {
  CreatePartnerPayload,
  DetailPartnerApiResponse,
  DetailProviderConfigResponse,
  IntegrationPartnerApiResponse,
  ParamsIntegrationPartner,
  ProvidersResponse,
  UpdatePartnerPayload
} from '@common/api/integration/schema';
import axios, { AxiosError } from 'axios';

const INT_BASE_PATH = '/integrations/api/v1';

export const createIntegrationPartner = async (payload: CreatePartnerPayload) => {
  try {
    const { data } = await axiosInstance.post(`${INT_BASE_PATH}/partners/`, payload);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const getIntegrationPartners = async ({
  ordering,
  page = 1,
  search
}: ParamsIntegrationPartner) => {
  try {
    const { data } = await axiosInstance.get<IntegrationPartnerApiResponse>(
      `${INT_BASE_PATH}/partners/`,
      {
        params: {
          ordering,
          page,
          search
        }
      }
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const getIntegrationPartner = async (id: number | null) => {
  try {
    const { data } = await axiosInstance.get<DetailPartnerApiResponse>(
      `${INT_BASE_PATH}/partners/${id}/`
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const updateIntegrationPartner = async (id: number, payload: UpdatePartnerPayload) => {
  try {
    const { data } = await axiosInstance.patch(`${INT_BASE_PATH}/partners/${id}/`, payload);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const deleteIntegrationPartner = async (id: number) => {
  try {
    await axiosInstance.delete(`${INT_BASE_PATH}/partners/${id}/`);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

// Provider Config

export const getProviderConfigs = async () => {
  try {
    const { data } = await axiosInstance.get<ProvidersResponse>(`${INT_BASE_PATH}/providers/`);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};

export const getDetailProviderConfigs = async () => {
  try {
    const { data } = await axiosInstance.get<DetailProviderConfigResponse>(
      `${INT_BASE_PATH}/providers/detail/`
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError;
    }
  }
};
