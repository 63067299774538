import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useNotificationsStore } from '@common/store/notifications';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  defaultPrice?: number | undefined;
};

const schema = z.object({
  price: z.number().positive().min(1).max(1000000)
});

export const EditBarModal = ({ isOpen, onClose, defaultPrice }: ModalProps) => {
  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();
  const { control, reset, handleSubmit, setValue } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (defaultPrice) {
      setValue('price', defaultPrice);
    }
  }, [defaultPrice]);

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;
  const { savePricingSettings } = useUpdatePricingSettings();

  const onSubmit = async (data: z.infer<typeof schema>) => {
    if (defaultPrice === data.price) {
      return;
    }
    const oldPrices = pricingSettings?.predefined_rates;
    const newPrice = data.price;
    const valueToUpdate = defaultPrice;
    const updatedPrice = data.price;
    const updatedPrices = oldPrices?.map((price: number) =>
      price === valueToUpdate ? updatedPrice : price
    );

    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const settings = {
      ...latestPricingSettings,
      predefined_rates: updatedPrices
    };
    // check if the new price is already in the list
    if (oldPrices?.includes(newPrice)) {
      addNotification('fail', 'Price already exists');
    } else {
      await savePricingSettings(JSON.stringify(settings));
    }
  };

  return (
    <Modal
      okText={`${t('Save')}`}
      continueIntent="primary"
      size="sm"
      open={isOpen}
      onClick={handleSubmit(onSubmit)}
      onClose={onClose}
      children={
        <div className="w-full">
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            {t('Edit BAR Level')}
          </Typography>
          <Controller
            name="price"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                name="subject"
                background="grey"
                className="w-full"
                type="text"
                value={value}
                label={`${t('Price')}*`}
                leadingAddon={CurrencyFormatter.currencySymbol()}
                placeholder={`${t('Please enter price')}`}
                onChange={(e) => {
                  const parsedValue = parseInt(e.target.value);
                  if (isNaN(parsedValue)) {
                    onChange(null);
                  } else {
                    onChange(parsedValue);
                  }
                }}
                onClear={() => reset({ price: 0 })}
                error={!!error}
                helperMessage={
                  error && (
                    <div className="flex items-center gap-2 text-error">
                      {error ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {error ? error.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
        </div>
      }
    />
  );
};
