import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { DateRangePicker } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { Modal } from '@common/components/molecules/Modal';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useModal } from '@common/hooks/useModal';
import { useHotelStore } from '@common/store/auth';
import { browserTimezone } from '@common/utils/browserTimezone';
import { cn } from '@common/utils/cn';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDocumentTitle } from '@mantine/hooks';
import {
  useDownloadPricingHistory,
  useProgramExecute
} from '@pages/Client/PricingSchedule/hooks/usePricingSchedule';
import { getStatusDetails } from '@pages/Client/PricingSchedule/utils/getStatus';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

const schema = z.object({
  page: z.number(),
  start_date: z.string().optional(),
  end_date: z.string().optional()
});

type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== '' && value !== undefined && value !== null
    )
  ) as Params;
};

const defaultValues = {
  page: 1,
  start_date: undefined,
  end_date: undefined
};

export const PricingScheduleLogs = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Pricing Logs'));
  const { hotelDetails } = useHotelDetails();
  const { getPricingHistory, isLoading: isGetPricingHistoryLoading } = useDownloadPricingHistory();
  const { hotelAuthToken } = useHotelStore();
  const { isOpen: filterOpen, openModal: filterClick, closeModal: filterClose } = useModal();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const [indexLoading, setIndexLoading] = useState(null);

  const { watch, reset, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const {
    algoProgramExecute,
    isRefetchLoading,
    query: { isLoading, refetch }
  } = useProgramExecute(filterEmptyValues(watch()));

  const ROWS_PER_PAGE = 20;

  const startIndex = algoProgramExecute?.current
    ? (algoProgramExecute.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    algoProgramExecute?.current && algoProgramExecute.results
      ? startIndex + algoProgramExecute.results.length - 1
      : 0;

  const handleNext = () => {
    if (!algoProgramExecute?.next) return;
    const params = new URLSearchParams(algoProgramExecute.next.split('?')[1]);
    const page = params.get('page');
    if (!page) return;

    reset(
      filterEmptyValues({
        ...watch(),
        page: parseInt(page)
      })
    );
  };

  const getStatus = (status: number) => {
    const statusMap: { [key: number]: string } = {
      0: 'ERROR',
      1: 'SUCCESS',
      2: 'SKIP'
    };

    return statusMap[status] || '';
  };
  const getStatusBackground = (status: number) => {
    const statusMap: { [key: number]: string } = {
      0: 'error',
      1: 'darkGreen',
      2: 'darkGrey'
    };

    return statusMap[status] || '';
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...watch(),
        page: previousPage
      })
    );
  };

  const columns = [
    {
      header: 'Task',
      accessorKey: 'dd_url',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Button
              intent={'text'}
              className={cn('text-indigo', !row?.getValue() && 'text-grey')}
              disabled={!row?.getValue()}
              onClick={() => {
                window.open(`${row?.getValue()}`, '_blank');
              }}>
              See DataDog
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Planed Execution',
      accessorKey: 'created',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-col">
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dayjs(row?.getValue()).utc().format('DD/MM/YYYY HH:mm [UTC]')}
                    </Typography>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Hotel Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(hotelDetails?.timezone)
                              .format('DD/MM/YYYY HH:mm')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Browser Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue()).tz(browserTimezone).format('DD/MM/YYYY HH:mm')}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Auto Upload',
      accessorKey: 'is_update_to_pms',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Badge background={`${row?.getValue() ? 'darkGreen' : 'darkGrey'}`}>
              {row?.getValue() ? 'ON' : 'OFF'}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Upload to PMS',
      accessorKey: 'update_status',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Badge background={`${getStatusBackground(row?.getValue())}`}>
              {getStatus(row?.getValue())}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Response',
      accessorKey: 'update_result',
      cell: (row: any) => {
        function downloadJSONFile(): void {
          const executionDate = dayjs
            .utc(row?.row?.original.created)
            .tz(hotelDetails?.timezone)
            .format('DD_MM_YYYY HH_mm');
          const fileName = `Response_${executionDate}.json`;
          const jsonDataStr = JSON.stringify(row?.getValue(), null, 2);
          const url = 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonDataStr);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return (
          <div className="flex items-center gap-3">
            <Button intent={'text'} color="copyTextGrey" onClick={downloadJSONFile}>
              {(row?.getValue() as string)?.slice(0, 10)}
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Request Count',
      accessorKey: 'requests_count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue()}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Upload Price Request Count',
      accessorKey: 'upload_price_requests_count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue()}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Total Price Count',
      accessorKey: 'total_price_count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue()}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Total Upload Date Count',
      accessorKey: 'dates_count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue()}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Threshold Date Count',
      accessorKey: 'above_threshold_dates_count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue()}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },

    {
      header: 'Total Threshold Price Count',
      accessorKey: 'total_above_threshold_price_count',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue()}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row: any) => {
        const status = getStatusDetails(row?.getValue());
        return (
          <div className="flex items-center gap-3">
            <Badge background={status.bgColor}>{status.label.toUpperCase()}</Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Pricing History',
      accessorKey: 'delete',
      cell: (row: any) => {
        const { original, index } = row.row;

        return (
          <div>
            <Button
              disabled={row?.row.original?.pricing_history_id.length === 0}
              icon
              onClick={() => {
                setIndexLoading(index);
                getPricingHistory({
                  date: dayjs
                    .utc(original?.created)
                    .tz(hotelDetails?.timezone)
                    .format('DD/MM/YYYY HH:mm'),
                  pricing_history_id: original?.pricing_history_id,
                  name: original?.program_name,
                  token: hotelAuthToken as string
                });
              }}>
              {isGetPricingHistoryLoading && indexLoading === index ? (
                <Icon.LoadingCircle className="mr-0 text-indigo" />
              ) : (
                <Icon.Download />
              )}
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const TableHeader = () => {
    return (
      <div className="flex w-full justify-between py-3">
        <Typography variant="h5" element="h1" className="font-medium" color="darkGrey">
          Pricing Schedule Logs
        </Typography>
        <div>
          <Button icon onClick={filterClick}>
            <Icon.FilterList />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Table
        isHover
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading || isRefetchLoading}
        data={!isLoading ? (algoProgramExecute?.results as any) : []}
        headerComponent={<TableHeader />}
        next={handleNext}
        isNext={!!algoProgramExecute?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={algoProgramExecute?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
      />

      <Modal
        onClick={() => {
          refetch();
          setValue('page', 1);
        }}
        open={filterOpen}
        okText="Search"
        onClose={filterClose}
        size={'lg'}
        children={
          <div className="flex flex-col items-start gap-4">
            <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
              Filter Logs By Date
            </Typography>
            <div className="mt-4 flex justify-between gap-4">
              <DateRangePicker
                ISOWeek={hotelDetails?.starts_monday}
                timezone={hotelDetails?.timezone}
                allowPastDates
                startDate={startDate}
                endDate={endDate}
                onDateChange={(date) => {
                  setStartDate(date?.from);
                  setEndDate(date?.to);
                  setValue('start_date', dayjs(date?.from).format(API_DATE_FORMAT));
                  setValue('end_date', dayjs(date?.to).format(API_DATE_FORMAT));
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};
