/**
 * `Analytics` component is a part of the dashboard page and provides an overview of the analytics data
 * based on the selected analytics filter (weekday, roomType, channel, segmentTag).
 * The component displays a series of `AnalyticsCard` components that show total values and segments for
 * different metrics such as Revenue, ADR, Occupancy, Median Lead Time, and Median LOS in Nights.
 * When the view is set to 'yearly', the component also renders an `AnalyticsCharts` component to display
 * line charts for each metric.
 *
 * Props:
 * None
 *
 * @component
 * @example
 * return (
 *   <Analytics />
 * );
 */

import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Typography } from '@common/components/foundations/Typography';
import { AnalyticsCard } from '@common/components/molecules/Card';
import { Icon } from '@common/components/foundations/icons';
import {
  useAnalyticsDashboardCache,
  useAnalyticsDashboardFilters
} from '@pages/Client/Dashboard/hooks/useAnalyticsDashboardCache';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import {
  KPIData,
  MultipleReportTable,
  Report,
  ReportSegmentID,
  ReportTable,
  ReportType,
  ReportWithStayDateRange
} from '@common/api/dashboard/types';
import { AnalyticsCharts } from '@pages/Client/Dashboard/components/AnalyticsCharts';
import {
  ANALYTICS_OPTIONS,
  AnalyticsFilter,
  PerformanceFilter,
  useDashboardPageStore
} from '@pages/Client/Dashboard/store/dashboard';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import { Button } from '@common/components/atoms/Button';
import { find, isEmpty, orderBy, sortBy } from 'lodash-es';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { AnalyticsFilters } from '@pages/Client/Dashboard/pages/Analytics/components/AnalyticsFilters';
import { usePerformanceFilter } from '@pages/Client/Dashboard/hooks/usePerformanceFilter';
import { useFeaturesStore } from '@common/store/features';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { useDocumentTitle } from '@mantine/hooks';
import { useMultiPropertyFilters } from '@pages/Client/hooks/useMultiPropertyFilters';
import { WEEK_DAYS } from '@common/constants/date';
import { ANALYTICS_COMPARE_OPTIONS } from '@pages/Client/Calendar/constants';
import { useAppFlags } from '@common/hooks/useAppFlags';

export type ReportKey =
  | 'revenue'
  | 'occupancy'
  | 'adr'
  | 'median_lead_time'
  | 'median_length_of_stay';

type FormatterFunction = (value: number | undefined) => number | string | undefined | null;
interface ReportConfig {
  title: string;
  formatter: FormatterFunction;
  renderTooltipText?: () => string | ReactNode;
}
type ReportConfigs = {
  [key in ReportKey]: ReportConfig;
};

/**
 * `generateLineChartData` is a utility function that generates an array of line chart data objects based
 * on the given report data, analytics filter, current year, and analytics lookup object. The returned
 * data is filtered to exclude the 'total' field and includes only relevant items found in the report.
 *
 * @param {keyof ReportTable} reportData - The report data key to use for generating line chart data.
 * @param {string} currentYear - The current year in 'YYYY' format.
 * @param {Report | ReportSegmentID | undefined} analyticsDashboardCache - Analytics data.
 *
 * @returns {{
 *   name: string;
 *   date: string;
 *   value: number;
 * }[]} An array of line chart data objects with name, date, and value properties.
 */
const generateLineChartData = (
  reportData: keyof ReportTable,
  currentYear: string,
  analyticsDashboardCache: Report | ReportSegmentID | undefined,
  sortedWeekdays?: ReturnType<typeof WEEK_DAYS>
) => {
  const monthsArray = Array.from({ length: 12 }, (_, i) =>
    dayjs(currentYear).month(i).format('YYYY-M')
  );

  return monthsArray.flatMap((currMonth) => {
    const reportDataValue = Object.keys(
      analyticsDashboardCache?.report_table_monthly?.[currMonth]?.[reportData] ?? {}
    );
    const { name } = analyticsDashboardCache || {};

    const result = reportDataValue
      .filter((item) => item !== 'total')
      .map((item) => {
        if (Object.keys(name || {}).includes(item)) {
          const typedItem = item as keyof ReportType;
          return {
            name: name?.[typedItem]?.toString() ?? '',
            date: dayjs(currMonth).format('YYYY-MM'),
            value:
              analyticsDashboardCache?.report_table_monthly?.[currMonth]?.[reportData]?.[
                typedItem
              ] ?? 0
          };
        } else {
          return undefined;
        }
      })
      .filter((item): item is Exclude<typeof item, undefined> => item !== undefined);

    const formattedResult = sortedWeekdays
      ? sortBy(result, (item) => {
          return sortedWeekdays.indexOf(item.name as (typeof sortedWeekdays)[number]);
        })
      : result;

    return formattedResult;
  });
};

export const revenueLookup: Record<PerformanceFilter, keyof KPIData<string>> = {
  grossValuesExclExtraServices: 'REVENUE',
  grossValuesInclExtraServices: 'REVENUE_INCL_SERVICES',
  netValuesExclExtraServices: 'REVENUE_NET',
  netValuesInclExtraServices: 'REVENUE_NET_INCL_SERVICES'
};

const adrLookup: Record<PerformanceFilter, keyof KPIData<string>> = {
  grossValuesExclExtraServices: 'ADR',
  grossValuesInclExtraServices: 'ADR_INCL_SERVICES',
  netValuesExclExtraServices: 'ADR_NET',
  netValuesInclExtraServices: 'ADR_NET_INCL_SERVICES'
};

const getRevenueKey = (performanceFilter: PerformanceFilter): keyof KPIData<string> => {
  const key = revenueLookup[performanceFilter];
  return key.toLowerCase() as keyof KPIData<string>;
};

const getAdrKey = (performanceFilter: PerformanceFilter): keyof KPIData<string> => {
  const key = adrLookup[performanceFilter];
  return key.toLowerCase() as keyof KPIData<string>;
};

export const Analytics = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Analytics Reporting'));
  const {
    view,
    analyticsFilter,
    compareToFilter,
    occupancyTypeFilter,
    setAnalyticsFilter,
    setCompareToFilter,
    setOccupancyTypeFilter,
    advancedAnalyticsFilters,
    performanceFilter,
    liliusInstance,
    updateHotelAnalyticsFilters
  } = useDashboardPageStore();
  const {
    analyticsDashboardCache,
    isCustomDateRange,
    customRangeText,
    query: { isLoading }
  } = useAnalyticsDashboardCache();
  const {
    query: { isLoading: isAnalyticsFiltersLoading }
  } = useAnalyticsDashboardFilters();
  const { hotelDetails } = useHotelDetails();
  const { features } = useFeaturesStore();
  const { isAnalyticsCompareToEnabled } = useAppFlags();
  const {
    performanceOptions,
    currentPerformanceFilter,
    performanceOptionsDisabled,
    onPerformanceOptionChange,
    isHovered: isTaxModeHovered,
    setIsHovered: setIsTaxModeHovered
  } = usePerformanceFilter();
  const { SparkIcon, isProDashboardUpselling, setUpsellingModalOpen, ProEntryPoints } =
    useUpselling();

  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState(false);

  const indigoOpacity = 'rgb(91, 72, 238, 0.2)';

  const analyticsFilterTitle = ANALYTICS_OPTIONS.find(
    (option) => option.value === analyticsFilter
  )?.label;

  const currentMonthDisplay = dayjs(liliusInstance?.viewing).tz().format('MMM YYYY');
  const currentMonth = dayjs(liliusInstance?.viewing).tz().format('YYYY-M');
  const currentYear = dayjs(liliusInstance?.viewing).tz().format('YYYY');

  const previousYearCurrentMonth = dayjs(liliusInstance?.viewing)
    .subtract(1, 'year')
    .tz()
    .format('YYYY-M');
  const previousYear = dayjs(liliusInstance?.viewing).subtract(1, 'year').tz().format('YYYY');

  const { isMultipleHotels } = useMultiPropertyFilters();

  const lineChartData = useMemo(
    () => (reportData: keyof ReportTable) =>
      generateLineChartData(
        reportData,
        currentYear,
        analyticsDashboardCache,
        analyticsFilter === 'weekday' ? WEEK_DAYS(!!hotelDetails?.starts_monday) : undefined
      ),
    [analyticsFilter, currentYear, analyticsDashboardCache]
  );

  if (!analyticsDashboardCache && !isLoading)
    return (
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div className="w-full rounded-lg border-2 border-dashed border-mediumGrey p-12">
          <div className="flex flex-col items-center gap-4">
            <Icon.Dashboard className="text-copyTextGrey" />
            <div className="flex flex-col items-center gap-1">
              <Typography element="h2" variant="h6" color="copyTextGrey">
                {t('Reservation data is not yet available')}
              </Typography>
              <Typography element="p" variant="paragraph-2" color="copyTextGrey">
                {t('We are currently setting up your dashboard. Please check back later.')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );

  const getKeyForReport = (key: string, performanceFilter: PerformanceFilter) => {
    if (key === 'revenue') {
      return getRevenueKey(performanceFilter);
    }
    if (key === 'adr') {
      return getAdrKey(performanceFilter);
    }
    return key;
  };

  function isReportWithStayDateRange(report: any): report is ReportWithStayDateRange {
    return (report as ReportWithStayDateRange)?.report_table_stay_date_range !== undefined;
  }

  const reportConfigs: ReportConfigs = {
    revenue: {
      title: 'Revenue',
      formatter: (value: number | undefined) => formattedCurrency(value ?? 0),
      renderTooltipText: () =>
        t(
          'Revenue refers to the total income generated by a property from its various services and offerings. Depending on the user choice we can show either revenue from Room Only (excl. Extra Services), or Total Revenue (incl. Extra Services), which includes food and beverage, and other ancillary services. Depending on the tax mode selected, we are displaying the revenue either including or excluding taxes.'
        )
    },
    occupancy: {
      title: 'Occupancy',
      formatter: (value: number | undefined) => formattedPercentage(value),
      renderTooltipText: () => (
        <div className="flex flex-col gap-2">
          <div>
            {t(
              'Occupancy is calculated as the ratio of sold rooms to the total available rooms, excluding any rooms that are out of order.'
            )}
          </div>
          <div>
            {t('Formula: Occupancy = Sold Rooms ÷ (Total Number of Rooms - Out of Order Rooms).')}
          </div>
        </div>
      )
    },
    adr: {
      title: 'ADR',
      formatter: (value: number | undefined) => formattedCurrency(value ?? 0),
      renderTooltipText: () => (
        <div className="flex flex-col gap-2">
          <div>
            {t(
              'Average Daily Rate represents the average revenue earned for an occupied room per day. It’s calculated by dividing the total revenue by the number of rooms sold.'
            )}
          </div>
          <div>{t('Formula: ADR = Total Revenue ÷ Number of Sold Rooms.')}</div>
        </div>
      )
    },
    median_lead_time: {
      title: 'Median Lead Time',
      formatter: (value: number | undefined) => value,
      renderTooltipText: () =>
        t(
          'Median Lead Time denotes the median duration between a reservation’s booking date and the guest’s check-in date. The Median Lead time is the number of days before check-in at which half of your bookings have been received. A longer Median Lead Time means people tend to book your hotel earlier, and vice versa for a shorter one.'
        )
    },
    median_length_of_stay: {
      title: 'Median LOS in Nights',
      formatter: (value: number | undefined) => value
    }
  };

  const shouldHighlightAdvancedFilters =
    hotelDetails?.id && !isEmpty(advancedAnalyticsFilters?.[hotelDetails.id]);

  const currentAnalyticsFilterLabel = `${t(currentPerformanceFilter?.label as string)} | ${t(
    ANALYTICS_OPTIONS.find((item) => item.value === analyticsFilter)?.label || ''
  )}`;

  const onAnalyticsOptionChange = (filter: AnalyticsFilter) => {
    setAnalyticsFilter(filter);
  };

  const isKeyOfTaxModeHovered = (key: any): key is keyof typeof isTaxModeHovered => {
    return key in isTaxModeHovered;
  };
  const shouldRenderAdjacent = (option: { value: any; label?: string }) =>
    isProDashboardUpselling &&
    isKeyOfTaxModeHovered(option.value) &&
    isTaxModeHovered?.[option.value] &&
    performanceOptionsDisabled(option);

  const getAdvancedFiltersCount = (): number => {
    const currentFilters = hotelDetails?.id ? advancedAnalyticsFilters?.[hotelDetails.id] : null;
    if (!currentFilters) return 0;

    const groupPresenceCounts = [
      'filter_booking_date_from' in currentFilters || 'filter_booking_date_to' in currentFilters
        ? 1
        : 0,
      'filter_stay_date_from' in currentFilters || 'filter_stay_date_to' in currentFilters ? 1 : 0
    ].reduce((acc, val) => acc + val, 0);

    const otherFiltersCount = Object.keys(currentFilters).filter(
      (key) =>
        ![
          'filter_booking_date_from',
          'filter_booking_date_to',
          'filter_stay_date_from',
          'filter_stay_date_to'
        ].includes(key)
    ).length;

    return groupPresenceCounts + otherFiltersCount;
  };
  const advancedAnalyticsFiltersCount = getAdvancedFiltersCount();

  const clearAnalyticsFilters = () => {
    if (!hotelDetails?.id) return;
    updateHotelAnalyticsFilters(hotelDetails.id, {});
  };

  const categoryOptions = [
    ...ANALYTICS_OPTIONS.map((option) => ({
      label: t(option.label) as string,
      value: option.value
    })),
    ...(isMultipleHotels
      ? [
          {
            label: t('Multi-Property Analytics'),
            value: 'multiProperty',
            disabled: !!advancedAnalyticsFiltersCount
          }
        ]
      : [])
  ];
  const currentAnalyticsFilter = find(categoryOptions, ['value', analyticsFilter]);

  const selectedCategory = currentAnalyticsFilter
    ? {
        value: currentAnalyticsFilter?.value,
        label: currentAnalyticsFilter?.label ? t(currentAnalyticsFilter.label as string) : ''
      }
    : undefined;

  const analyticsCompareOptions = ANALYTICS_COMPARE_OPTIONS.map((option) => ({
    ...option,
    label: t(option.label)
  }));

  return (
    <>
      <div className="flex flex-col space-y-12">
        <div className="flex flex-1 flex-col gap-y-6">
          <div className="flex flex-col items-start justify-between md:flex-row md:items-end">
            <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
              {t(analyticsFilterTitle as string)} {t('Overview')}{' '}
              {isCustomDateRange
                ? customRangeText
                : view === 'monthly'
                  ? currentMonthDisplay
                  : currentYear}
            </Typography>
            <div className="flex items-end gap-1">
              <div>
                {isAnalyticsFiltersLoading ? <Skeleton className="mr-2 h-8 w-20" /> : null}
                {!isAnalyticsFiltersLoading ? (
                  <Button
                    intent="text"
                    disabled={analyticsFilter === 'multiProperty'}
                    className="mr-1 bg-white px-4 py-2"
                    data-html2canvas-ignore="true"
                    style={
                      shouldHighlightAdvancedFilters
                        ? {
                            backgroundColor: indigoOpacity
                          }
                        : {}
                    }
                    onClick={() => setIsAdvancedFiltersOpen(true)}
                  >
                    <Icon.FilterOutline className="h-5 w-5" />
                    {`${t('Advanced Filters')}${
                      advancedAnalyticsFiltersCount ? ` (${advancedAnalyticsFiltersCount})` : ''
                    }`}
                    {advancedAnalyticsFiltersCount ? (
                      <Icon.Clear
                        className="h-4 w-4 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          clearAnalyticsFilters();
                        }}
                      />
                    ) : null}
                  </Button>
                ) : null}
              </div>
              <div className="flex items-center gap-2">
                {(features?.includes(25) || features?.includes(20)) &&
                ProEntryPoints.isProDashboardEntryPoint ? (
                  <>
                    {isAnalyticsCompareToEnabled ? (
                      <div data-html2canvas-ignore={true} className="min-w-[140px]">
                        <Dropdown
                          label={t('Compare to') as string}
                          onOptionClick={(option) => setCompareToFilter(option.value)}
                          selectedOption={analyticsCompareOptions.find(
                            (option) => option.value === compareToFilter
                          )}
                          options={analyticsCompareOptions}
                        />
                      </div>
                    ) : null}
                    <div data-html2canvas-ignore={true}>
                      <Dropdown
                        label={t('Tax Mode') as string}
                        selectedOption={
                          currentPerformanceFilter
                            ? {
                                value: currentPerformanceFilter?.value,
                                label: currentPerformanceFilter?.label
                                  ? t(currentPerformanceFilter.label)
                                  : ''
                              }
                            : undefined
                        }
                        options={performanceOptions.map((option) => ({
                          label: t(option.label) as string,
                          value: option.value,
                          renderAdjacent: () =>
                            shouldRenderAdjacent(option) ? <SparkIcon className="ml-1.5" /> : null,
                          onMouseEnter: () =>
                            setIsTaxModeHovered({ ...isTaxModeHovered, [option.value]: true }),
                          onMouseLeave: () =>
                            setIsTaxModeHovered({ ...isTaxModeHovered, [option.value]: false })
                        }))}
                        onOptionClick={(option) => {
                          if (
                            isProDashboardUpselling &&
                            performanceOptionsDisabled({ value: option.value })
                          ) {
                            return setUpsellingModalOpen(true);
                          }
                          return onPerformanceOptionChange(option.value);
                        }}
                      />
                    </div>
                    <div data-html2canvas-ignore={true}>
                      <Dropdown
                        label={t('Category') as string}
                        selectedOption={selectedCategory}
                        options={categoryOptions}
                        onOptionClick={(option) => onAnalyticsOptionChange(option.value)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="relative hidden items-center justify-self-end md:flex">
                    <Typography variant="meta-2" className="text-copyTextGrey">
                      {currentAnalyticsFilterLabel}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {/*
             * This block of code maps over the `reportConfigs` object entries and generates an array of
             * <AnalyticsCard /> components. Each <AnalyticsCard /> component receives data based on the
             * current analytics filter, view, and report configurations.
             *
             * For each entry in the `reportConfigs`, the code block retrieves the corresponding report data
             * and currentItemData, then generates an array of segment data objects by filtering and mapping
             * the currentItemData keys.
             *
             * Finally, it renders an <AnalyticsCard /> component with the following properties:
             * - key: The unique key for the component in the array.
             * - isLoading: A boolean value indicating whether the data is still loading.
             * - total: The formatted total value based on the report configuration formatter.
             * - totalLabel: The title of the report configuration.
             * - segment: The generated array of segment data objects.
             */}
            {(Object.entries(reportConfigs) as [ReportKey, ReportConfig][]).map(([key, config]) => {
              const report = analyticsDashboardCache;

              let reportData;
              if (isReportWithStayDateRange(report)) {
                reportData = report.report_table_stay_date_range;
              } else if (report) {
                reportData =
                  view === 'monthly' ? report.report_table_monthly : report.report_table_yearly;
              }
              const reportName = report?.name;

              const adjustedKey = getKeyForReport(key, performanceFilter);

              let currentItemData = isCustomDateRange
                ? (reportData as ReportTable)?.[adjustedKey]
                : (reportData as MultipleReportTable)?.[
                    view === 'monthly' ? currentMonth : currentYear
                  ]?.[adjustedKey];

              let previousYearCurrentItemData = isCustomDateRange
                ? {} // LY values not available yet for custom date range
                : (reportData as MultipleReportTable)?.[
                    view === 'monthly' ? previousYearCurrentMonth : previousYear
                  ]?.[adjustedKey];

              let currentNumberOfStaysData = isCustomDateRange
                ? (reportData as ReportTable)?.['number_of_stays']
                : (reportData as MultipleReportTable)?.[
                    view === 'monthly' ? currentMonth : currentYear
                  ]?.['number_of_stays'];

              let previousYearNumberOfStaysData = isCustomDateRange
                ? {} // LY values not available yet for custom date range
                : (reportData as MultipleReportTable)?.[
                    view === 'monthly' ? previousYearCurrentMonth : previousYear
                  ]?.['number_of_stays'];

              if (!currentItemData) {
                currentItemData = {};
              }
              if (!previousYearCurrentItemData) {
                previousYearCurrentItemData = {};
              }
              if (!currentNumberOfStaysData) {
                currentNumberOfStaysData = {};
              }
              if (!previousYearNumberOfStaysData) {
                previousYearNumberOfStaysData = {};
              }

              reportName
                ? // Populate missing keys with 0
                  Object.keys(reportName).forEach((key) => {
                    if (currentItemData && !(key in currentItemData)) {
                      (currentItemData as Record<string, any>)[key] = 0;
                    }
                  })
                : null;

              reportName
                ? // Populate missing keys with 0
                  Object.keys(reportName).forEach((key) => {
                    if (previousYearCurrentItemData && !(key in previousYearCurrentItemData)) {
                      (previousYearCurrentItemData as Record<string, any>)[key] = 0;
                    }
                  })
                : null;

              reportName
                ? // Populate missing keys with 0
                  Object.keys(reportName).forEach((key) => {
                    if (currentNumberOfStaysData && !(key in currentNumberOfStaysData)) {
                      (currentNumberOfStaysData as Record<string, any>)[key] = 0;
                    }
                  })
                : null;

              reportName
                ? // Populate missing keys with 0
                  Object.keys(reportName).forEach((key) => {
                    if (previousYearNumberOfStaysData && !(key in previousYearNumberOfStaysData)) {
                      (previousYearNumberOfStaysData as Record<string, any>)[key] = 0;
                    }
                  })
                : null;

              const revenueKey = getRevenueKey(performanceFilter);
              const revenueReport =
                isCustomDateRange && isReportWithStayDateRange(report)
                  ? (reportData as ReportTable)?.[adjustedKey as keyof ReportTable]
                  : (reportData as MultipleReportTable)?.[
                      view === 'monthly' ? currentMonth : currentYear
                    ]?.[revenueKey as keyof ReportTable];

              let segmentData: {
                id: number;
                value: string;
                rawValue?: number;
                segment: string;
                numericValue?: number;
                subtotalKey: string;
              }[] = Object.keys(currentItemData || {})
                .filter((subtotalKey) => Object.hasOwn(currentItemData || {}, subtotalKey))
                .filter((subtotalKey) => subtotalKey !== 'total')
                .map((subtotalKey, i) => ({
                  id: i,
                  numericValue: revenueReport?.[subtotalKey as keyof ReportType],
                  value:
                    config
                      .formatter(currentItemData?.[subtotalKey as keyof ReportType])
                      ?.toString() ?? '',
                  rawValue: currentItemData?.[subtotalKey as keyof ReportType],
                  segment: reportName?.[subtotalKey as keyof ReportType]?.toString() ?? '',
                  subtotalKey
                }));

              if (analyticsFilter !== 'weekday') {
                segmentData = segmentData.sort(
                  (a, b) => (b.numericValue || 0) - (a.numericValue || 0)
                );
              }

              if (analyticsFilter === 'channel' || analyticsFilter === 'segmentTag') {
                // get top 10 segments by numericValue
                segmentData = segmentData.slice(0, 10);
              }

              if (analyticsFilter === 'weekday') {
                segmentData = segmentData.sort((a, b) =>
                  a.subtotalKey.localeCompare(b.subtotalKey)
                );
              }
              if (analyticsFilter === 'multiProperty') {
                segmentData = orderBy(segmentData, ['segment'], ['asc']);
              }
              segmentData = segmentData.map(({ numericValue, ...rest }) => rest); // remove numericValue from final objects

              const showSortByIcon =
                key === 'revenue' &&
                analyticsFilter !== 'weekday' &&
                analyticsFilter !== 'multiProperty';

              return (
                <AnalyticsCard
                  showCompareTo={isAnalyticsCompareToEnabled}
                  key={key}
                  isLoading={isLoading}
                  reportType={key}
                  compareType={compareToFilter}
                  occupancyType={occupancyTypeFilter}
                  setOccupancyType={setOccupancyTypeFilter}
                  rawTotal={currentItemData?.total}
                  total={config.formatter(currentItemData?.total)?.toString() ?? '-'}
                  totalLabel={t(config.title)}
                  totalTooltipIcon={
                    config.renderTooltipText ? (
                      <TooltipIcon content={config.renderTooltipText()} />
                    ) : null
                  }
                  sortByIcon={
                    showSortByIcon ? (
                      <Icon.ArrowLongDown className="h-4 w-4 fill-mediumGrey" />
                    ) : null
                  }
                  segment={segmentData ?? []}
                  formatter={config.formatter}
                  previousYearData={previousYearCurrentItemData}
                  numberOfStaysData={currentNumberOfStaysData}
                  previousYearNumberOfStaysData={previousYearNumberOfStaysData}
                  isCustomDateRange={isCustomDateRange}
                />
              );
            })}
          </div>
        </div>

        {view === 'yearly' ? (
          <AnalyticsCharts
            data={[
              {
                id: 'revenue',
                lineData: lineChartData(getRevenueKey(performanceFilter)),
                title: t(
                  `${reportConfigs.revenue.title} per ${
                    analyticsFilter === 'multiProperty'
                      ? 'Property'
                      : analyticsFilterTitle?.replace(' Analytics', '')
                  }`
                )
              },
              {
                id: 'adr',
                lineData: lineChartData(getAdrKey(performanceFilter)),
                title: t(
                  `${reportConfigs.adr.title} per ${
                    analyticsFilter === 'multiProperty'
                      ? 'Property'
                      : analyticsFilterTitle?.replace(' Analytics', '')
                  }`
                )
              },
              {
                id: 'occupancy',
                lineData: lineChartData('occupancy'),
                title: t(
                  `${reportConfigs.occupancy.title} per ${
                    analyticsFilter === 'multiProperty'
                      ? 'Property'
                      : analyticsFilterTitle?.replace(' Analytics', '')
                  }`
                )
              },
              {
                id: 'median_lead_time',
                lineData: lineChartData('median_lead_time'),
                title: t(
                  `${reportConfigs.median_lead_time.title} per ${
                    analyticsFilter === 'multiProperty'
                      ? 'Property'
                      : analyticsFilterTitle?.replace(' Analytics', '')
                  }`
                )
              },
              {
                id: 'median_length_of_stay',
                lineData: lineChartData('median_length_of_stay'),
                title: t(
                  `${reportConfigs.median_length_of_stay.title} per ${
                    analyticsFilter === 'multiProperty'
                      ? 'Property'
                      : analyticsFilterTitle?.replace(' Analytics', '')
                  }`
                )
              }
            ]}
          />
        ) : null}

        <AnalyticsFilters
          isModalOpen={isAdvancedFiltersOpen}
          onClose={() => setIsAdvancedFiltersOpen(false)}
        />
      </div>
    </>
  );
};
