import { setAuthToken } from '@common/api/axiosInstance';
import { getHotelSettingsLogs } from '@common/api/hotel';
import { getInventoryPrice, getRunPricingFetchInventoryStatus } from '@common/api/pricingAlgorithm';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { PricingQueryKeys, UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useSettingsLog = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [UtilQueryKeys.HOTEL_SETTINGS_LOG],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getHotelSettingsLogs();
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get hotel settings log');

  return {
    settingsLog: data,
    isLoading,
    error
  };
};

export const useInventoryPrice = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [PricingQueryKeys.PRICING_ALGORITHM_INVENTORY_PRICE],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getInventoryPrice();
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get inventory price');

  return {
    inventoryPrice: data,
    isLoading,
    error
  };
};

export const usePricingSettings = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [PricingQueryKeys.PRICING_ALGORITHM_INVENTORY_PRICE],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getInventoryPrice();
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get inventory price');
  return {
    inventoryPrice: data,
    isLoading,
    error
  };
};

export const useRunPricingFetchInventoryStatus = ({
  onSuccess
}: { onSuccess?: () => void } = {}) => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();

  const { data, isLoading, isError, isSuccess, error, refetch } = useQuery({
    queryKey: [PricingQueryKeys.RUN_PRICING_FETCH_INVENTORY_PRICE],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getRunPricingFetchInventoryStatus();
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to run pricing and fetch inventory price');
  if (isSuccess) onSuccess?.();

  return {
    data,
    isLoading,
    isSuccess,
    refetch,
    error
  };
};
