import { ViewingDates, WeekDay } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { find, has, isEmpty, range } from 'lodash-es';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';

export function generateDateRange(dateRange?: ViewingDates, daysToOmit?: WeekDay[]) {
  if (isEmpty(dateRange) || isEmpty(daysToOmit)) return [];
  if (!has(dateRange, 'from') || !has(dateRange, 'to')) return [];
  const { from, to } = dateRange;
  const startDate = dayjs(from);
  const endDate = dayjs(to);
  const daysDiff = endDate.diff(startDate, 'day');
  const dates: string[] = [];

  range(0, daysDiff + 1).map((day) => {
    const date = startDate.add(day, 'day');
    const allowed = find(daysToOmit, { name: date.locale('en').format('ddd') })?.value || false;
    allowed ? dates.push(date.format(API_DATE_FORMAT)) : false;
  });

  return dates;
}
