import React from 'react';
import { create } from 'zustand';

export type BannerMessage = {
  id: string;
  title?: string;
  type: 'info' | 'warning' | 'error' | 'surge';
  description: string | React.ReactNode;
  components?: React.ReactNode;
  showAction?: boolean;
  actionTitle?: string;
  showClose?: boolean;
  onAction?: () => void;
  onClose?: () => void;
  extraAction?: () => JSX.Element;
};

type BannerStore = {
  banners: BannerMessage[];
  addBanner: (msg: BannerMessage) => void;
  removeBanner: (id: string) => void;
};

export const useBannerStore = create<BannerStore>((set) => ({
  banners: [],
  addBanner: (newBanner) =>
    set((state) => {
      // Filter out all banners with the same ID as the new banner
      const filteredBanners = state.banners.filter((banner) => banner.id !== newBanner.id);

      // Add the new banner to the end of the array
      return {
        banners: [...filteredBanners, newBanner]
      };
    }),
  removeBanner: (id) =>
    set((state) => ({
      banners: state.banners.filter((banner) => banner.id !== id)
    }))
}));

export function useBanners() {
  const { addBanner, removeBanner } = useBannerStore();

  const createBanner = ({
    id,
    title,
    description,
    type = 'info',
    showAction = true,
    actionTitle = 'Action',
    components,
    showClose = true,
    onAction,
    onClose,
    extraAction
  }: BannerMessage) => {
    addBanner({
      id,
      title,
      description,
      type,
      showAction,
      components,
      actionTitle,
      showClose,
      onAction,
      onClose,
      extraAction
    });
  };

  return { createBanner, removeBanner };
}
