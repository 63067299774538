import { ErrorResponse } from '@common/api/auth';
import { getHotelReservationLogs } from '@common/api/hotel';
import { HotelReservationApiLogsPagination } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

type Params = {
  page: number;
  request_type: number;
  search?: string;
  date?: string;
  ordering?: string;
};

export const useGetReservationApiLogs = ({
  page = 1,
  search,
  request_type = 1,
  date,
  ordering
}: Params) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery<HotelReservationApiLogsPagination, ErrorResponse>({
    queryKey: [
      HotelQueryKeys.GET_HOTEL_RESERVATION_API_LOGS,
      page,
      search,
      request_type,
      date,
      ordering
    ],
    queryFn: () => {
      return getHotelReservationLogs(page, request_type, search, date, ordering);
    }
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to get hotel reservation API logs');
    }
  }, [query.isError]);

  const hotelReservationApiLogs = useMemo(() => {
    return query.data;
  }, [query.data]);

  const refetchLogs = async () => {
    await query.refetch();
    addNotification('success', 'Reservation API logs have been updated');
  };

  useEffect(() => {
    query.refetch();
  }, [ordering]);

  const isRefetchLoading = query.isLoading || query.isFetching;

  return { hotelReservationApiLogs, query, refetchLogs, isRefetchLoading };
};
