import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { buttonStyles } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { cn } from '@common/utils/cn';
import { useUserLocale } from '@common/hooks/useUserLocale';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  const { locale } = useUserLocale();
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-meta-1 font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonStyles({ intent: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border-0'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-lightGrey rounded-md w-9 font-normal text-[0.8rem] m-0.5',
        row: 'flex w-full mt-2',
        cell: 'text-center text-meta-1 p-0 relative [&:has([aria-selected])]:bg-transparent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonStyles({ intent: 'outline' }),
          'h-9 w-9 p-0 m-0.5 font-normal aria-selected:opacity-100 hover:bg-indigo-reduced hover:text-darkGrey border-0'
        ),
        day_selected:
          'bg-indigo text-lightGrey hover:bg-indigo hover:text-darkGrey focus:bg-indigo focus:text-lightGrey',
        day_today: 'text-lightGrey font-semibold',
        day_outside: 'invisible',
        day_disabled: 'text-mediumGrey opacity-50',
        day_range_middle:
          'aria-selected:bg-indigo aria-selected:bg-opacity-60 hover:aria-selected:bg-indigo-reduced hover:aria-selected:text-darkGrey aria-selected:text-lightGrey',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={{
        IconLeft: () => <Icon.ChevronLeft className="h-4 w-4" />,
        IconRight: () => <Icon.ChevronRight className="h-4 w-4" />
      }}
      locale={locale}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
