import { useTranslation } from 'react-i18next';
import { find } from 'lodash-es';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import { UploadPrices } from '@pages/Client/Calendar/components/UploadPrices/UploadPrices';
import {
  AutoUploadModal,
  EditAutoUploadModal,
  OnOkArgument,
  UPLOAD_NEXT_OPTIONS
} from '@pages/Client/Calendar/components/AutoUpload/AutoUploadModal';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import styles from '@pages/Client/Calendar/components/CalendarHeader.module.css';
import { Modal } from '@common/components/molecules/Modal';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { updateHotelProperty } from '@common/api/hotel';
import { useMutation } from '@tanstack/react-query';
import { rainConfetti } from '@pages/Client/Calendar/utils/rainConfetti';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelStore } from '@common/store/auth';
import { GenieAlt } from '@common/components/foundations/Logo/GenieAlt';
import { uploadPrice } from '@common/api/pricingAlgorithm';
import dayjs from 'dayjs';
import { API_DATE_FORMAT, API_DATE_FORMAT_FULL } from '@common/constants/date';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';

export function ConsolidatedUploadPricesButton() {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const {
    hotelDetails,
    pmsChannelManagerText,
    query: { refetch: refetchHotelDetails, isLoading: isLoadingHotel }
  } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();
  const { addNotification } = useNotificationsStore();
  const [initialized, setInitialized] = useState(false);
  const [autoUpload, setAutoUpload] = useState<boolean | null>(null);
  const [showAutoUploadModal, setShowAutoUploadModal] = useState(false);
  const [showUploadPricesModal, setShowUploadPricesModal] = useState(false);
  const [showEditAutoUploadModal, setShowEditAutoUploadModal] = useState(false);
  const [showStopAutoUploadModal, setShowStopAutoUploadModal] = useState(false);
  const { mutateAsync: uploadPriceMutate, isPending: isUploadingPrice } = useMutation({
    mutationFn: uploadPrice
  });
  const { mutateAsync: updateHotelPropertyMutate, isPending: isUpdatingHotel } = useMutation({
    mutationFn: updateHotelProperty
  });
  const uploadPricesFormRef: ComponentProps<typeof UploadPrices>['ref'] = useRef(null);
  const { hasUploadPriceButton, hasNoUploadPricesButton } = useGetDetailProviderConfig();

  useEffect(() => {
    if (hotelDetails) setAutoUpload(hotelDetails.is_update_to_pms);
    setInitialized(true);
  }, [hotelDetails]);

  async function onAutoUploadChange(checked: boolean) {
    if (checked) {
      setShowAutoUploadModal(true);
    } else {
      setShowStopAutoUploadModal(true);
    }
  }

  const onUploadPricesToPMS = async (ok: boolean) => {
    if (!ok) {
      setShowUploadPricesModal(false);
      return;
    }

    const form = uploadPricesFormRef.current!.form.getValues();
    const valid = await uploadPricesFormRef.current!.form.trigger();

    if (!valid) return;

    const clientTimeZone = dayjs.tz.guess();

    try {
      await uploadPriceMutate({
        client_timezone: clientTimeZone,
        client_time: dayjs().tz().format(API_DATE_FORMAT_FULL),
        command: 'upload_price_to_pms',
        disable_threshold: form.uploadAllPrices,
        start_date: dayjs(form.startDate).tz().format(API_DATE_FORMAT),
        end_date: dayjs(form.endDate).tz().format(API_DATE_FORMAT)
      });
      setShowUploadPricesModal(false);
      addNotification(
        'success',
        `${t('Sending Prices. Waiting for the')} ${pmsChannelManagerText} ${t('to confirm')}.`
      );
    } catch (error) {
      addNotification('fail', 'Something Went Wrong!');
    }
  };

  async function onAutoUploadUserOk({ skipUploadType, uploadNextType }: OnOkArgument) {
    await updateHotelPropertyMutate({
      is_update_to_pms: true,
      token: hotelAuthToken,
      update_to_pms_skip_type: skipUploadType,
      update_to_pms_type: uploadNextType
    });
    await refetchHotelDetails();
    setAutoUpload(true);
    rainConfetti();
    trackEvent('AutoUploadPricesEnabled');
    setShowAutoUploadModal(false);
    addNotification('success', t('Hotel updated successfully'));
  }

  async function onEditAutoUploadUserOk({ skipUploadType, uploadNextType }: OnOkArgument) {
    await updateHotelPropertyMutate({
      is_update_to_pms: true,
      token: hotelAuthToken,
      update_to_pms_skip_type: skipUploadType,
      update_to_pms_type: uploadNextType
    });
    await refetchHotelDetails();
    setAutoUpload(true);
    setShowEditAutoUploadModal(false);
    addNotification('success', t('Hotel updated successfully'));
  }

  const onStopAutoUpload = async () => {
    await updateHotelPropertyMutate({
      is_update_to_pms: false,
      token: hotelAuthToken
    });
    await refetchHotelDetails();
    setAutoUpload(false);
    setShowStopAutoUploadModal(false);
    addNotification('success', t('Hotel updated successfully'));
  };

  if (!hasUploadPriceButton) return null;
  return initialized ? (
    <>
      {autoUpload ? (
        <RPGPopover className="relative flex items-center" actionOnMouseOver>
          <RPGPopover.Button
            as={Button}
            intent={autoUpload ? 'primary' : 'outline'}
            onClickAction={() => setShowUploadPricesModal(true)}>
            {t('Upload Prices')}
            {autoUpload ? (
              <Icon.TurnOff className="h-5 w-5 rounded-full " />
            ) : (
              <Icon.TurnOff className="h-5 w-5 rounded-full opacity-30" />
            )}
          </RPGPopover.Button>

          <RPGPopover.Panel className="!-left-5 max-w-xs px-0 pb-5 pt-4">
            {hasNoUploadPricesButton ? null : (
              <div className={styles.actionItem} onClick={() => setShowUploadPricesModal(true)}>
                <Icon.Publish className="h-5 w-5" />
                <Typography color="darkGrey">{t('Upload Prices Manually')}</Typography>
              </div>
            )}
            {autoUpload ? (
              <div className={styles.actionItem} onClick={() => setShowEditAutoUploadModal(true)}>
                <Icon.Calendar className="h-5 w-5" />
                <Typography color="darkGrey">
                  {t('Auto-Upload Next')}{' '}
                  {t(
                    find(
                      UPLOAD_NEXT_OPTIONS,
                      (item) => item.value === hotelDetails?.update_to_pms_type
                    )?.label || ''
                  )}
                </Typography>
              </div>
            ) : null}
            <div className={styles.actionItem} onClick={() => onAutoUploadChange(!autoUpload)}>
              <Icon.TurnOff className="h-5 w-5" />
              <Typography color="darkGrey">
                <div>{t('Turn off Auto-Upload')}</div>
              </Typography>
              {isLoadingHotel ? (
                <div className="flex justify-center">
                  <Icon.LoadingCircle className="h-5 w-5 text-indigo" />
                </div>
              ) : null}
            </div>
          </RPGPopover.Panel>
        </RPGPopover>
      ) : (
        <>
          {hasNoUploadPricesButton ? null : (
            <Button intent="outline" onClick={() => setShowUploadPricesModal(true)}>
              {t('Upload Prices')}
            </Button>
          )}
          <Button
            intent="primary"
            className="bg-orange p-2 pl-3"
            onClick={() => onAutoUploadChange(!autoUpload)}>
            <div className="flex items-center justify-between gap-2 align-middle">
              {t('Turn on Auto-Upload')}
              <Icon.TurnOff className="h-6 w-6 pt-0.5" />
            </div>
          </Button>
        </>
      )}

      <Modal onClose={setShowAutoUploadModal} open={showAutoUploadModal} size="custom">
        <AutoUploadModal
          isLoading={isUpdatingHotel}
          onClose={setShowAutoUploadModal}
          onOk={onAutoUploadUserOk}
        />
      </Modal>

      <Modal onClose={setShowEditAutoUploadModal} open={showEditAutoUploadModal} size="custom">
        <EditAutoUploadModal
          isLoading={isUpdatingHotel}
          onClose={setShowEditAutoUploadModal}
          onOk={onEditAutoUploadUserOk}
        />
      </Modal>

      <Modal
        onClose={setShowStopAutoUploadModal}
        open={showStopAutoUploadModal}
        okText={t('Cancel') as string}
        cancelText={t('Stop') as string}
        size="custom"
        showFooter={false}>
        <div className="flex w-[560px] items-center overflow-hidden rounded-xl bg-indigo">
          <div className="px-10">
            <GenieAlt width={91} height="100%" />
          </div>
          <div className="flex-1 bg-white">
            <Button
              type="button"
              icon
              className="absolute right-8 top-8 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
              onClick={() => setShowStopAutoUploadModal(false)}>
              <Icon.Clear className="h-5 w-5" />
            </Button>
            <div className="px-10 py-10 ">
              <h2 className="font-medium">{t('Stop Auto-upload')}</h2>
              <div className="mt-4">
                {t(
                  'This will stop RoomPriceGenie from uploading your prices automatically. To upload the latest prices, you now need to click the Upload button manually.'
                )}
              </div>
            </div>

            <div className="border-t border-lightGrey bg-lightGrey-light py-5 pr-6 text-right">
              <div className="mt-2 text-right">
                <Button
                  intent="text"
                  disabled={isUpdatingHotel}
                  className="mr-2"
                  onClick={onStopAutoUpload}>
                  {t('Stop')}
                </Button>
                <Button intent="primary" onClick={() => setShowStopAutoUploadModal(false)}>
                  {t('Cancel')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isLoading={isUploadingPrice}
        onClose={onUploadPricesToPMS}
        size="xl"
        open={showUploadPricesModal}
        cancelText={t('Cancel') as string}
        okText={t('Upload Prices') as string}>
        <UploadPrices ref={uploadPricesFormRef} />
      </Modal>
    </>
  ) : (
    <Button intent="outline" className="w-32">
      <Icon.LoadingCircle className="h-5 w-5 text-indigo" />
    </Button>
  );
}
