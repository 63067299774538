import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { Draft } from 'immer';
import {
  PropertyDefaultsSchema,
  TransformSchema
} from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { Engine, Rule } from 'json-rules-engine';
import { PricingSettings } from '@common/api/hotel/types';
import { isEmpty } from 'lodash-es';

/**
 * Rules for setting and deleting the median key using json-rules-engine:
 *
 * Bulk edit only:
 * - Delete Median Key Rule:
 *   - Condition:
 *     - Checks if the value in the 'medianAdjustment' field of 'formData' is equal to the value of ADJUSTMENT_DB.MEDIAN_KEY.
 *   - Action:
 *     - If the condition is true, it triggers the 'deleteMedianKey' event, which should delete the median key.
 *     - This rule implies that if the median adjustment value has not changed from its original setting, the median key will be deleted.
 *
 * - Set Median Key Rule:
 *   - Condition:
 *     - Checks if the value in the 'medianAdjustment' field of 'formData' is not equal to the value of ADJUSTMENT_DB.MEDIAN_KEY.
 *   - Action:
 *     - If the condition is true, it triggers the 'setMedianKey' event, which should set or update the median key.
 *     - This rule implies that if the median adjustment value has changed from its original setting, the median key will be set or updated.
 *
 * These rules are designed to manage the state of the median key based on whether the current adjustment value differs from the original value.
 */

const actions = { setMedianKey: 'setMedianKey', deleteMedianKey: 'deleteMedianKey' };

const deleteMedianKey = new Rule({
  conditions: {
    all: [
      {
        fact: ADJUSTMENT_DB.MEDIAN_KEY,
        operator: 'equal',
        value: { fact: 'formData', path: '$.medianAdjustment' }
      }
    ]
  },
  event: { type: actions.deleteMedianKey }
});

const setMedianKey = new Rule({
  conditions: {
    all: [
      {
        fact: ADJUSTMENT_DB.MEDIAN_KEY,
        operator: 'notEqual',
        value: { fact: 'formData', path: '$.medianAdjustment' }
      }
    ]
  },
  event: { type: actions.setMedianKey }
});

export async function transformMedianWindow(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  rpgDefaults: PropertyDefaultsSchema,
  formData: Partial<TransformSchema>,
  date: string
) {
  if (isEmpty(formData) || !formData[ADJUSTMENT.MEDIAN]) return;
  const engine = new Engine();
  engine.addRule(setMedianKey);
  engine.addRule(deleteMedianKey);

  engine.on('success', (event) => {
    if (event.type === actions.setMedianKey) {
      if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
      currentPricingDraft.dates[date][ADJUSTMENT_DB.MEDIAN_KEY] = formData[
        ADJUSTMENT.MEDIAN
      ] as number;
    }
    if (event.type === actions.deleteMedianKey) {
      delete currentPricingDraft.dates[date]?.[ADJUSTMENT_DB.MEDIAN_KEY];
    }
  });

  await engine.run({ formData, [ADJUSTMENT_DB.MEDIAN_KEY]: rpgDefaults[ADJUSTMENT_DB.MEDIAN_KEY] });
  return;
}
