import { Drawer } from '@common/components/molecules/Drawer/Drawer';
import {
  BulkEditTab,
  useBulkEditsStore
} from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { BulkEdit } from '@pages/Client/Calendar/components/BulkEdit/BulkEdit';
import { BulkRemove } from '@pages/Client/Calendar/components/BulkRemove/BulkRemove';
import { BulkSurges } from '@pages/Client/Calendar/components/BulkManageSurge/BulkSurges';

export function BulkEdits({ runPricing }: { runPricing: () => Promise<void> }) {
  const {
    drawerState,
    bulkEditTab,
    setBulkEditTab,
    setBulkEditDrawerState,
    setBulkEditViewingDates
  } = useBulkEditsStore();

  function handleClose() {
    setBulkEditTab(BulkEditTab.EDIT);
    setBulkEditDrawerState(false);
    setBulkEditViewingDates(undefined);
  }

  return (
    <Drawer isOpen={drawerState} onClose={handleClose}>
      {bulkEditTab === BulkEditTab.EDIT ? <BulkEdit runPricing={runPricing} /> : null}
      {bulkEditTab === BulkEditTab.REMOVE ? <BulkRemove runPricing={runPricing} /> : null}
      {bulkEditTab === BulkEditTab.SURGES ? <BulkSurges /> : null}
    </Drawer>
  );
}
