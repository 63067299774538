import { SignOutButton, useUser } from '@clerk/clerk-react';
import { useClerkConditionalRender } from '@common/hooks/useClerkConditionalRender';
import { useLogout, useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useClerkUserDetail } from '@pages/Client/Account/Profile/hooks/useUserDetail';
import { FullscreenLoading } from '@pages/Client/components/FullscreenLoading';
import isNil from 'lodash-es/isNil';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export function ClerkCompassPage() {
  const rpgClerkUser = useClerkUserDetail();
  const logoutUser = useLogout();
  const { hotel, setOTPValidation } = useAuthStore();
  const { addNotification } = useNotificationsStore();
  const { renderClerk, clerk } = useClerkConditionalRender();

  useEffect(() => {
    if (rpgClerkUser?.error) {
      addNotification('fail', 'User query failed');
    }
  }, [rpgClerkUser?.error]);

  useEffect(() => {
    if (isNil(renderClerk)) return;
    if (!renderClerk) logoutUser();
    if (renderClerk && clerk.isSignedIn) setOTPValidation(true);
  }, [renderClerk]);

  switch (true) {
    case clerk.isLoaded && !clerk.isSignedIn:
      return <Navigate to="/login" replace />;

    case !clerk.isLoaded:
    case isNil(renderClerk):
    case clerk.isSignedIn && rpgClerkUser.isLoading:
      return <FullscreenLoading />;

    case clerk.isSignedIn && !isNil(hotel) && !rpgClerkUser?.data?.user?.is_staff:
      return <Navigate to={`/client/calendar/${hotel}`} replace />;

    case clerk.isSignedIn && rpgClerkUser?.data?.user?.sub_account_type === 2:
      return <Navigate to="/admin/clients" replace />;

    case clerk.isSignedIn && rpgClerkUser?.data?.user?.is_staff && !clerk?.user?.totpEnabled:
      return <Navigate to="/login-create-otp" replace />;

    case clerk.isSignedIn && rpgClerkUser?.data?.user?.is_staff && clerk?.user?.totpEnabled:
      return <Navigate to="/admin/clients/login" replace />;

    default:
      return <SignOutButton />;
  }
}
