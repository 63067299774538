import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Card } from '@common/components/molecules/Card';
import { cn } from '@common/utils/cn';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import React from 'react';

export function AirbnbDataCard({
  topLeftValue,
  topLeftText,
  topLeftTooltipIcon,
  isLoading,
  diff,
  bottomLeftValue,
  bottomLeftText,
  icon,
  progressBar
}: {
  topLeftValue: string | number;
  topLeftText: string;
  topLeftTooltipIcon?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  diff?: number;
  bottomLeftValue?: string | number;
  bottomLeftText?: string;
  icon?: React.ReactNode;
  progressBar?: React.ReactNode;
}) {
  return (
    <Card
      backgroundColor={'white'}
      className={cn(
        'relative grid min-h-[150px] w-full min-w-[200px] max-w-sm snap-start',
        isLoading ? 'animate-pulse bg-grey-reduced' : null
      )}>
      <div className="grid h-full">
        <div className="grid grid-flow-col justify-between">
          <div className="flex flex-col">
            <div className="flex gap-2">
              {icon && <div className="mr-2 flex items-center">{icon}</div>}
              <div>
                {isLoading ? (
                  <Skeleton className="h-7 w-16" />
                ) : (
                  <Typography element="span" variant="h6" color={'indigo'}>
                    {topLeftValue}
                  </Typography>
                )}
                <div className="flex items-center gap-1">
                  <Typography element="span" variant="meta-2" color={'copyTextGrey'}>
                    {topLeftText}
                  </Typography>
                  {topLeftTooltipIcon}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Skeleton className="h-5 w-14 rounded-full" />
          ) : diff ? (
            <div className="flex h-6 items-center gap-x-1 rounded-full bg-white px-2 py-1">
              {diff < 0 ? (
                <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
              ) : (
                <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
              )}
              <Typography
                element="span"
                variant="meta-2"
                color={diff < 0 ? 'uiRed' : 'uiGreen'}
                >
                {formattedPercentage(diff)}
              </Typography>
            </div>
          ) : null}
        </div>

        {isLoading && progressBar ? <Skeleton className="h-6 w-24" /> : <div>{progressBar}</div>}

        {bottomLeftText && bottomLeftValue ? (
          <div className={cn('grid place-content-end justify-stretch gap-y-2')}>
            <div className="flex flex-col justify-between gap-x-5">
              <div className="flex flex-col">
                {isLoading ? (
                  <Skeleton className="h-5 w-12" />
                ) : (
                  <Typography element="span" variant="paragraph-2" color={'darkGrey'}>
                    {bottomLeftValue}
                  </Typography>
                )}
                <div className="flex items-center gap-1">
                  <Typography element="span" variant="meta-2" color={'copyTextGrey'}>
                    {bottomLeftText}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Card>
  );
}
