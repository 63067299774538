import { Flags } from '@common/constants/flags';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useAppFlags = () => {
  const flags = useFlags();
  return {
    isDerivedRatesV2Enabled: flags[Flags.DerivedRatesV2] ?? false,
    isAnalyticsCompareToEnabled: flags[Flags.AnalyticsCompareTo] ?? false,
    is12MonthsBudgetBannerEnabled: flags[Flags.Show12MonthsBudgetBanner] ?? false
  };
};
