import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useAuthStore } from '@common/store/auth';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const PersonalInfo = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { control, setValue, reset } = useFormContext();

  useEffect(() => {
    reset({
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email
    });
  }, [user, reset]);
  return (
    <div className="flex w-full ">
      <div className="flex  w-full flex-col justify-between md:flex-row lg:flex-row">
        <div>
          <Typography>{t('Edit Your Personal Information')}</Typography>
        </div>
        <div className="w-full flex-col gap-5 md:w-1/2">
          <div className="pb-5">
            <Controller
              name="first_name"
              control={control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <Input
                  error={!!error}
                  onClear={() => setValue('first_name', '')}
                  onChange={onChange}
                  name={name}
                  label={`${t('First Name')} *`}
                  value={value}
                  placeholder={t('Please enter your first name') as string}
                  type={'text'}
                  helperMessage={
                    error ? (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error?.message?.toString() : ''}
                        </Typography>
                      </div>
                    ) : null
                  }
                />
              )}
            />
          </div>
          {/* Last Name Input */}
          <div className="pb-5">
            <Controller
              name="last_name"
              control={control}
              rules={{
                required: t('Please enter your last name') as string
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <Input
                  error={!!error}
                  onClear={() => setValue('last_name', '')}
                  onChange={onChange}
                  name={name}
                  label={`${t('Last Name')} *`}
                  value={value}
                  placeholder={t('Please enter your last name') as string}
                  type={'text'}
                  helperMessage={
                    error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message : ''}
                        </Typography>
                      </div>
                    )
                  }
                />
              )}
            />
          </div>

          {/* Email Input */}
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue={user?.email}
              rules={{
                required: t('Please enter your email') as string,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                  message: t('Please enter a valid email')
                }
              }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <Input
                  error={!!error}
                  onClear={() => setValue('email', '')}
                  onChange={onChange}
                  name={name}
                  label={`${t('Email')} *`}
                  value={value}
                  placeholder={t('Please enter your email') as string}
                  type={'email'}
                  helperMessage={
                    error ? (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message : ''}
                        </Typography>
                      </div>
                    ) : null
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
