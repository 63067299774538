import { reactivateChargebee } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation } from '@tanstack/react-query';

export const useReactivateChargebee = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const {
    query: { refetch: hotelDetailsRefetch }
  } = useHotelDetails();

  return useMutation({
    mutationFn: async () => reactivateChargebee(),
    onSuccess: () => {
      addNotification(
        'success',
        'Successfully reconnected subscription. This will take a second to process.'
      );

      setTimeout(() => {
        window.location.reload();
        hotelDetailsRefetch();
      }, 5000);
    },
    onError: () => {
      addNotification('fail', 'Failed to reconnect subscription');
    }
  });
};
