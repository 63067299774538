import { Button } from '@common/components/atoms/Button';
import { Divider } from '@common/components/atoms/Divider';
import { Input } from '@common/components/atoms/Input';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useViewStore } from '@common/store/view';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { MonthlyAdjustmentsChart } from '@pages/Client/PricingStrategy/MonthlyAdjustments/components/MonthlyAdjustmentsChart';
import { calculateAdjustment } from '@pages/Client/PricingStrategy/MonthlyAdjustments/utils/calculateAdjustments';
import { formattedCurrency } from '@pages/Client/PricingStrategy/MonthlyAdjustments/utils/formattedCurrency';
import { getFullMonthName } from '@pages/Client/PricingStrategy/MonthlyAdjustments/utils/getFullMonthName';
import { transformPriceCacheData } from '@pages/Client/PricingStrategy/MonthlyAdjustments/utils/tranformPriceCahceData';
import { transformedDataMonthly } from '@pages/Client/PricingStrategy/MonthlyAdjustments/utils/transformDataMonthly';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useTranslation } from 'react-i18next';
import { useWarnings } from '@common/store/warnings';
import { useDocumentTitle } from '@mantine/hooks';

const isWithinRange = (val: number | undefined) => val === undefined || (val >= -50 && val <= 80);
const validationMessage = 'Please enter a value between -50% and 80%.';
const validationEmptyMessage = 'Please enter a value';

const adjustmentErrorMap: z.ZodErrorMap = (error) => {
  switch (error.code) {
    case 'invalid_type':
      return { message: validationEmptyMessage };

    default:
      return { message: validationEmptyMessage };
  }
};

const schema = z.object({
  adjustment: z.object({
    monthly: z.object({
      January: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      February: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      March: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      April: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      May: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      June: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      July: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      August: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      September: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      October: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      November: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      }),
      December: z.object({
        standard: z
          .number({ errorMap: adjustmentErrorMap })
          .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
          .refine(isWithinRange, { message: validationMessage })
      })
    })
  })
});

export const MonthlyAdjustments = () => {
  const { createWarning } = useWarnings();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { features } = useFeaturesStore();
  const { data: pricingSettings } = pricingSettingsQuery;
  const { data: cachePrice } = cachePriceQuery;
  const { view } = useViewStore();
  const { t } = useTranslation();
  useDocumentTitle(t('Monthly Adjustments'));

  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();

  const {
    watch,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const DATA_AVERAGE_BASE_PRICE_FOR_THE_MONTH = cachePrice
    ? cachePrice?.data?.prices?.average_price_week_month?.comp_adjusted_price_averages?.monthly
    : {};
  const formatAverageBasePriceForTheMonth = transformPriceCacheData(
    DATA_AVERAGE_BASE_PRICE_FOR_THE_MONTH
  );

  const DATA_AVERAGE_PMS_PRICE = cachePrice
    ? cachePrice?.data?.prices?.average_price_week_month?.pms_price_averages?.monthly
    : {};
  const formatAveragePMSPrice = transformPriceCacheData(DATA_AVERAGE_PMS_PRICE);

  const DATA_AVERAGE_PICKUP_BOOST = cachePrice
    ? cachePrice?.data?.prices?.average_price_week_month?.pickupboostprice_averages?.monthly
    : {};
  const formatAveragePickupBoost = transformPriceCacheData(DATA_AVERAGE_PICKUP_BOOST);

  const DATA_ADJUSTMENT = pricingSettings ? pricingSettings?.adjustment?.monthly : {};
  const formatAdjustment = transformedDataMonthly(DATA_ADJUSTMENT);

  const calculateData =
    formatAverageBasePriceForTheMonth && transformedDataMonthly(watch('adjustment.monthly'))
      ? calculateAdjustment(
          transformedDataMonthly(watch('adjustment.monthly')),
          formatAverageBasePriceForTheMonth
        )
      : [];

  const onSubmit = async (data: any) => {
    const warningsToProcess: Promise<void>[] = [];

    const addWarningIfNeeded = (condition: boolean, warningMessage: string) => {
      if (condition) {
        warningsToProcess.push(createWarning({ message: t(warningMessage) }));
      }
    };

    const isAllAbove10 = Object.values(data.adjustment.monthly).every(
      (item: any) => item.standard > 10
    );

    const isAllBelowNegative5 = Object.values(data.adjustment.monthly).every(
      (item: any) => item.standard < -5
    );

    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const settings = JSON.stringify({
      ...latestPricingSettings,
      adjustment: {
        weekday: latestPricingSettings?.adjustment.weekday,
        lead_time: latestPricingSettings?.adjustment.lead_time,
        monthly: data?.adjustment.monthly
      }
    });

    addWarningIfNeeded(
      isAllAbove10,
      'When you have set all monthly adjustments to positive it means your base price may be too low. Are you sure?'
    );

    addWarningIfNeeded(
      isAllBelowNegative5,
      'When you have set all monthly adjustments to negative it means your base price may be too high. Are you sure?'
    );

    const processWarnings = async () => {
      if (warningsToProcess.length > 0) {
        try {
          await warningsToProcess.shift();
          processWarnings();
        } catch (_) {
          console.log('User cancelled warning');
        }
      } else {
        savePricingSettings(settings);
      }
    };

    await processWarnings();
  };

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess]);

  const IsAvgPickupBoostEnable = features?.includes(22);

  return (
    <Page
      header={
        <Header
          title={`${t('Monthly Adjustments')}`}
          description={
            features?.includes(Feature.NoMarketData)
              ? `${t(
                  'Use Monthly Adjustments to adjust price recommendations to suit your property.'
                )}`
              : `${t(
                  "We check the rates of other properties that represent your local 'market'. Next, we estimate what they would charge if they had the same base price as you. Below, we show these 'market' prices for the different months. However, every property is different; use Monthly Adjustments to adjust price recommendations to suit your property."
                )}`
          }
          actions={
            <Button
              disabled={isSavePricingLoading}
              isSuccess={isSuccessRef.current}
              isLoading={isSavePricingLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t('Save')}
            </Button>
          }
        />
      }
    >
      <div className="flex flex-col gap-4">
        <div className="mb-2">
          <MonthlyAdjustmentsChart
            abpnm={calculateData}
            pmsprice={formatAveragePMSPrice}
            llm={formatAverageBasePriceForTheMonth}
          />
        </div>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1">
            <div className="max-w-[60px]">
              <Typography color="darkGrey" variant="meta-2" element="p">
                {t('Adjust')}
                <br />
                {t('Price by')}
              </Typography>
            </div>
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-1 items-center gap-1 md:grid-cols-12">
              {formatAdjustment.map((item) => (
                <div key={item.month}>
                  <Controller
                    name={`adjustment.monthly.${getFullMonthName(item.month)}.standard` as any}
                    control={control}
                    defaultValue={item.value}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        name={`adjustment.monthly.${getFullMonthName(item.month)}.standard`}
                        type="number"
                        value={value}
                        label={`${t(`${item.month}`)}`}
                        placeholder={`${t(`${item.month}`)}`}
                        onChange={onChange}
                        showClearButton={false}
                        error={!!error}
                        trailingAddon={<>%</>}
                        helperMessage={
                          (
                            errors.adjustment?.monthly?.[
                              getFullMonthName(item.month) as keyof typeof errors.adjustment.monthly
                            ] as FieldError & { standard?: any }
                          )?.standard && (
                            <div className="flex items-center gap-2 text-error">
                              <Typography element="span" color="error" variant="meta-2">
                                {(
                                  errors?.adjustment?.monthly?.[
                                    getFullMonthName(
                                      item.month
                                    ) as keyof typeof errors.adjustment.monthly
                                  ] as FieldError & { standard?: any }
                                )?.standard.message?.toString()}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Divider />
        <div className="grid grid-cols-12 items-center ">
          <div className="col-span-1">
            <Typography color="darkGrey" variant="meta-2" element="p">
              {t('Base Price ')}
              <br />
              {t('for the Month')}
            </Typography>
          </div>
          <div className="col-span-11">
            <div className="grid grid-cols-1 items-center gap-1 md:grid-cols-12">
              {calculateData.map((item) => (
                <div className="text-right" key={item.month}>
                  <Typography
                    color="copyTextGrey"
                    variant="meta-1"
                    element="p"
                    className="font-light"
                  >
                    <span className="md:sr-only">{`${item} : `}</span>
                    {formattedCurrency(item.value)}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
        {IsAvgPickupBoostEnable && view === 'admin' && DATA_AVERAGE_PICKUP_BOOST !== undefined ? (
          <>
            <Divider />
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-1">
                <Typography color="darkGrey" variant="meta-2" element="p">
                  {t('Average Pickup Boost')}
                </Typography>
              </div>
              <div className="col-span-11">
                <div className="grid grid-cols-1 items-center gap-1 md:grid-cols-12">
                  {formatAveragePickupBoost.map((item) => (
                    <div className="text-right" key={item.month}>
                      <Typography
                        color="copyTextGrey"
                        variant="meta-1"
                        element="p"
                        className="font-light"
                      >
                        <span className="md:sr-only">{`${item.month} : `}</span>
                        {formattedCurrency(item.value)}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Page>
  );
};
