import defaultTheme from 'tailwindcss/defaultTheme';
import tailwindCSSForms from '@tailwindcss/forms';
import tailwindCSSContainerQueries from '@tailwindcss/container-queries';
import tailwindAnimate from 'tailwindcss-animate';

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px'
      }
    },
    extend: {
      keyframes: {
        'rpg-bounce': {
          '0%, 100%': {
            transform: 'translateY(-5%)',
            'animation-timing-function': 'cubic-bezier(0.8,0,1,1)'
          },
          '50%': {
            transform: 'none',
            'animation-timing-function': 'cubic-bezier(0,0,0.2,1)'
          }
        },
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' }
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' }
        }
      },
      animation: {
        'rpg-bounce': 'rpg-bounce 2s infinite',
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out'
      },
      boxShadow: {
        xs: '0px 1px 2px rgba(43, 43, 43, 0.05)',
        sm: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        md: '0px 4px 8px rgba(43, 43, 43, 0.1)',
        lg: '0px 12px 16px rgba(43, 43, 43, 0.08)',
        xl: '0px 20px 24px -4px rgba(30, 30, 30, 0.08)',
        '2xl': '0px 24px 48px -12px rgba(0, 0, 0, 0.18)',
        '3xl': '0px 32px 64px rgba(43, 43, 43, 0.12)'
      },
      zIndex: {
        '60': 60
      }
    },
    borderRadius: {
      ...defaultTheme.borderRadius,
      DEFAULT: '4px',
      sm: '8px',
      md: '12px',
      lg: '16px',
      xl: '20px'
    },
    colors: {
      ...defaultTheme.colors,
      appBackground: '#F3F5F5',
      transparent: 'transparent',
      // Primary colors
      indigo: {
        DEFAULT: '#5B48EE',
        contrast: '#FFFFFF',
        reduced: '#CEC8FA',
        darker: '#2712c7'
      },
      darkGreen: {
        DEFAULT: '#06815B',
        contrast: '#FFFFFF',
        reduced: '#B4D9CE'
      },
      uiGreen: {
        DEFAULT: '#09B07B',
        contrast: '#FFFFFF',
        reduced: '#B9E2D9'
      },
      uiRed: {
        DEFAULT: '#D62057',
        contrast: '#FFFFFF'
      },
      // Accent colors
      orange: {
        DEFAULT: '#FE764B',
        contrast: '#FFFFFF',
        reduced: '#FFD6C9',
        darker: '#e53801'
      },
      gold: {
        DEFAULT: '#ECBA59',
        reduced: '#F9EACD'
      },
      // Error colors
      error: {
        DEFAULT: '#D92D20',
        contrast: '#FFFFFF',
        reduced: '#F4C0BC'
      },
      // Neutral colors
      beigeLight: {
        DEFAULT: '#FFFAF3'
      },
      darkGrey: {
        DEFAULT: '#2B2B2B',
        contrast: '#FFFFFF',
        reduced: '#BFBFBF'
      },
      grey: {
        DEFAULT: '#949494',
        contrast: '#FFFFFF',
        reduced: '#DFDFDF'
      },
      mediumGrey: {
        DEFAULT: '#D4D4D4',
        reduced: '#F2F2F2'
      },
      lightGrey: {
        DEFAULT: '#F4F4F4',
        reduced: '#FCFCFC',
        light: '#F9F9F9'
      },
      copyTextGrey: {
        DEFAULT: '#676767',
        contrast: '#FFFFFF',
        reduced: '#D1D1D1'
      },
      white: '#FFFFFF',
      // Focus state colors
      focusNative: 'rgba(91, 72, 238, 0.3)',
      focusError: 'rgba(217, 45, 32, 0.3)'
    },
    fontFamily: {
      sans: ['Inter', ...defaultTheme.fontFamily.sans],
      mono: [...defaultTheme.fontFamily.mono],
      heading: ['PPNeueMontreal', ...defaultTheme.fontFamily.sans]
    },
    fontSize: {
      ...defaultTheme.fontSize,
      badge: '11px',
      h1: [
        '56px',
        {
          lineHeight: '68px',
          letterSpacing: '0.01em'
        }
      ],
      'h1-bold': ['56px', { fontWeight: 700, lineHeight: '68px', letterSpacing: '0.01em' }],
      h2: [
        '48px',
        {
          lineHeight: '58px',
          letterSpacing: '0.01em'
        }
      ],
      'h2-bold': ['48px', { fontWeight: 700, lineHeight: '58px', letterSpacing: '0.01em' }],
      h3: [
        '40px',
        {
          lineHeight: '48px',
          letterSpacing: '0.01em'
        }
      ],
      'h3-bold': ['40px', { fontWeight: 700, lineHeight: '48px', letterSpacing: '0.01em' }],
      h4: [
        '32px',
        {
          lineHeight: '38px',
          letterSpacing: '0.01em'
        }
      ],
      'h4-bold': ['32px', { fontWeight: 700, lineHeight: '38px', letterSpacing: '0.01em' }],
      h5: [
        '24px',
        {
          lineHeight: '28px',
          letterSpacing: '0.01em'
        }
      ],
      'h5-bold': ['24px', { fontWeight: 700, lineHeight: '28px', letterSpacing: '0.01em' }],
      h6: [
        '20px',
        {
          lineHeight: '24px',
          letterSpacing: '0.01em'
        }
      ],
      'h6-bold': ['20px', { fontWeight: 700, lineHeight: '24px', letterSpacing: '0.01em' }],
      'paragraph-1': ['16px', '24px'],
      'paragraph-1-medium': ['16px', { fontWeight: 500, lineHeight: '24px' }],
      'paragraph-2': ['14px', '24px'],
      'paragraph-2-medium': ['14px', { fontWeight: 500, lineHeight: '24px' }],
      'paragraph-3': ['12px', '21px'],
      'paragraph-3-medium': ['12px', { fontWeight: 500, lineHeight: '21px' }],
      'paragraph-3-semibold': ['12px', { fontWeight: 600, lineHeight: '21px' }],
      'meta-1': ['14px', { letterSpacing: '-0.01em' }],
      'meta-1-medium': ['14px', { fontWeight: 500, letterSpacing: '-0.01em' }],
      'meta-2': ['12px', { letterSpacing: '-0.01em' }],
      'meta-2-medium': ['12px', { fontWeight: 500, letterSpacing: '-0.01em' }],
      'meta-2-semibold': ['12px', { fontWeight: 600, letterSpacing: '-0.01em' }],
      'meta-3': ['10px', { letterSpacing: '-0.01em' }],
      'meta-3-medium': ['10px', { fontWeight: 500, letterSpacing: '-0.01em' }],
      'meta-3-semibold': ['10px', { fontWeight: 600, letterSpacing: '-0.01em' }],
      tooltip: ['13px', '18px'],
      calendar: ['13px', '16px'],
      nav: ['14px', '18px'],
      'nav-medium': ['14px', { fontWeight: 500, lineHeight: '15px' }],
      'nav-semibold': ['14px', { fontWeight: 500, lineHeight: '15px' }],
      'table-cell': ['14px', '18px'],
      'table-cell-medium': ['14px', { lineHeight: '18px', fontWeight: 500 }],
      'table-cell-semibold': ['14px', { lineHeight: '18px', fontWeight: 600 }],
      input: [
        '14px',
        {
          lineHeight: '15px',
          letterSpacing: '-0.02em'
        }
      ]
    }
  },
  plugins: [
    tailwindCSSForms({
      strategy: 'class'
    }),
    tailwindCSSContainerQueries,
    tailwindAnimate,
    function ({ addUtilities }: any) {
      const newUtilities = {
        '.text-security-disc': {
          '-webkit-text-security': 'disc'
        }
      };
      addUtilities(newUtilities);
    }
  ],
  important: '#tailwind-root'
};
