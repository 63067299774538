export function AccountSquare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3ZM12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6ZM6.10956 17.6427C6.2463 17.8652 6.48889 18.0005 6.75 18H17.25C17.5093 17.9971 17.7487 17.8604 17.8831 17.6385C18.0174 17.4167 18.0276 17.1412 17.91 16.91L17 15.1C16.6611 14.4274 15.9731 14.0023 15.22 14H8.78C8.02688 14.0023 7.3389 14.4274 7 15.1L6.08 16.91C5.96163 17.1427 5.97282 17.4203 6.10956 17.6427Z"
      />
    </svg>
  );
}
