export function DiscountTag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      {...props}>
      <path d="M14.845 8.04035L7.56896 0.764354C7.50646 0.701837 7.42169 0.666706 7.33329 0.666687H0.999959C0.911554 0.666687 0.826769 0.701806 0.764257 0.764318C0.701745 0.82683 0.666626 0.911615 0.666626 1.00002V7.33335C0.666645 7.42175 0.701776 7.50652 0.764293 7.56902L8.04029 14.845C8.35311 15.1571 8.77693 15.3323 9.21879 15.3323C9.66065 15.3323 10.0845 15.1571 10.3973 14.845L14.845 10.3974C15.1575 10.0848 15.3331 9.66088 15.3331 9.21885C15.3331 8.77683 15.1575 8.35291 14.845 8.04035ZM4.66663 6.00002C4.40292 6.00002 4.14513 5.92182 3.92587 5.77531C3.7066 5.6288 3.5357 5.42057 3.43479 5.17693C3.33387 4.9333 3.30747 4.66521 3.35891 4.40657C3.41036 4.14793 3.53735 3.91035 3.72382 3.72388C3.91029 3.53741 4.14786 3.41042 4.40651 3.35897C4.66515 3.30753 4.93324 3.33393 5.17687 3.43485C5.42051 3.53576 5.62874 3.70666 5.77525 3.92593C5.92176 4.14519 5.99996 4.40298 5.99996 4.66669C5.99996 5.02031 5.85948 5.35945 5.60944 5.6095C5.35939 5.85954 5.02025 6.00002 4.66663 6.00002ZM9.23563 11.2357C9.17312 11.2982 9.08835 11.3333 8.99996 11.3333C8.91157 11.3333 8.8268 11.2982 8.76429 11.2357L6.09763 8.56902C6.03691 8.50615 6.00331 8.42195 6.00407 8.33455C6.00483 8.24715 6.03988 8.16355 6.10169 8.10175C6.16349 8.03994 6.24709 8.00489 6.33449 8.00413C6.42189 8.00337 6.50609 8.03697 6.56896 8.09769L9.23563 10.7644C9.29812 10.8269 9.33322 10.9116 9.33322 11C9.33322 11.0884 9.29812 11.1732 9.23563 11.2357ZM11.2356 9.23569C11.1731 9.29818 11.0883 9.33328 11 9.33328C10.9116 9.33328 10.8268 9.29818 10.7643 9.23569L8.09763 6.56902C8.03691 6.50615 8.00331 6.42195 8.00407 6.33455C8.00483 6.24715 8.03988 6.16355 8.10169 6.10175C8.16349 6.03994 8.24709 6.00489 8.33449 6.00413C8.42189 6.00337 8.50609 6.03697 8.56896 6.09769L11.2356 8.76435C11.2981 8.82686 11.3332 8.91163 11.3332 9.00002C11.3332 9.08841 11.2981 9.17318 11.2356 9.23569Z" />
    </svg>
  );
}
