import { getAdminUserList } from '@common/api/account';
import { RPGUsersQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useAdminUsersList = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [RPGUsersQueryKeys.GET_ADMIN_USER_LIST],
    queryFn: getAdminUserList,
    retry: false
  });
  return {
    adminUserList: data,
    isLoading,
    error
  };
};
