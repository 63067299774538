import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

interface MonthDetails {
  key: string;
  name: string;
  shortName: string;
}

export const useDate = ({ isHotelAccount }: { isHotelAccount?: boolean } = {}) => {
  const { hotelDetails } = useHotelDetails(isHotelAccount);

  const hotelTimezone = hotelDetails?.timezone ?? dayjs.tz.guess();

  const getNowInHotelTimezone = () => dayjs().tz(hotelTimezone);

  const convertToHotelTimezone = (date: Date) => {
    return dayjs(date).tz(hotelTimezone, true);
  };

  const isToday = (date: Date) => {
    const now = getNowInHotelTimezone();
    const inputDate = convertToHotelTimezone(date);
    return now.startOf('day').isSame(inputDate.startOf('day'));
  };

  const isWeekend = (date: Date) => {
    const day = convertToHotelTimezone(date).day();
    return day === 0 || day === 6; // 0 for Sunday, 6 for Saturday
  };

  const getMonthDetails = (date: dayjs.Dayjs): MonthDetails => ({
    key: date.format('YYYY-MM'),
    name: date.format('MMMM'),
    shortName: date.format('MMM')
  });

  const currentDate = getNowInHotelTimezone();

  const currentMonth: MonthDetails = getMonthDetails(currentDate);
  const nextMonth: MonthDetails = getMonthDetails(currentDate.add(1, 'month'));

  const startOfCurrentMonth = dayjs(currentDate).startOf('month');

  const next12Months = Array.from({ length: 12 }, (_, i) =>
    startOfCurrentMonth.add(i, 'month').format('YYYY-MM')
  );

  return {
    isToday,
    isWeekend,
    currentMonth,
    nextMonth,
    getNowInHotelTimezone,
    convertToHotelTimezone,
    startOfCurrentMonth,
    next12Months
  };
};
