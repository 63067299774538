import { Modal } from '@common/components/molecules/Modal';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
  children?: React.ReactNode;
};

export const ResetPriceModal = ({ onReset, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClick={onReset}
      open={isOpen}
      okText="Reset Price"
      onClose={onClose}
      size={'sm'}
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-indigo p-3">
                <Icon.Refresh color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t('Reset Pickup Boost ') + ' '}
            </Typography>
            <Typography>{t('Are you sure you want to reset the pickup boost model?')}</Typography>
          </div>
        </>
      }
    />
  );
};
