import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useScrapeBookingUrl } from '../../../hooks/useScrapeBookingUrl';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const pattern =
  /^(https?:\/\/)?((((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,})|((\d{1,3}\.){3}\d{1,3})))(:\d+)?(\/[-a-z\d%_.~+]*)*\.en-gb\.html(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

const schema = z.object({
  hotel_url: z.string().refine((value) => pattern.test(value), {
    message: 'Invalid booking URL'
  })
});

export const AddBookingUrlModal = ({ isOpen, onClose }: ModalProps) => {
  const { scrapeBookingUrl } = useScrapeBookingUrl();

  const { control, reset, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const onSubmit = (data: z.infer<typeof schema>) => {
    scrapeBookingUrl(data);
    setTimeout(() => {
      reset({ hotel_url: '' });
    }, 1000);
  };

  return (
    <Modal
      okText="Scrape Hotel"
      continueIntent="primary"
      overFlowHidden
      size="xl"
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      open={isOpen}
      onClose={onClose}>
      <div className="p-2">
        <div className="my-2 flex flex-col  gap-4">
          <Typography variant="h5" element="h5" color="darkGrey">
            Scrape New Hotel
          </Typography>
          <Controller
            name="hotel_url"
            control={control}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <Input
                name={name}
                background="grey"
                className="w-full"
                type="text"
                value={value}
                label="Booking URL"
                placeholder="Please enter booking url"
                onChange={onChange}
                onClear={() => reset({ hotel_url: '' })}
                error={!!error}
                helperMessage={
                  error ? (
                    <div className="flex items-center gap-2 text-error">
                      {error ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {error ? error.message?.toString() : ''}
                      </Typography>
                    </div>
                  ) : (
                    <Typography element="span" color="copyTextGrey" variant="meta-2">
                      example: https://www.booking.com/hotel/nl/hoteldezeeuwsestromen.en-gb.html
                    </Typography>
                  )
                }
              />
            )}
          />
        </div>
      </div>
    </Modal>
  );
};
