import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@common/components/foundations/icons';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { LAST_YEAR_OPTIONS, LastYearFilter } from '@pages/Client/Dashboard/store/dashboard';

interface PerformanceOptionsProps {
  lastYearType: LastYearFilter;
  onLastYearTypeChange: (value: LastYearFilter) => void;
}

export const PerformanceOptions = ({
  lastYearType,
  onLastYearTypeChange
}: PerformanceOptionsProps) => {
  const { t } = useTranslation();

  return (
    <Popover className="relative">
      <Popover.Button as={Button} icon>
        <Icon.MoreVertical />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        <Popover.Panel className="absolute right-0 z-10 mt-5 w-[308px] rounded-2xl bg-white p-6 shadow-md">
          {({ close }) => (
            <>
              <Button type="button" icon className="absolute right-2 top-2" onClick={() => close()}>
                <Icon.Clear className="h-4 w-4" />
              </Button>

              <Typography element="h2" className="text-h6-bold" color="darkGrey">
                {t('Display Settings')}
              </Typography>

              <Typography element="h3" className="my-3" color="grey">
                <div className="flex items-center gap-1">
                  <span className="text-meta-2-semibold">{t('Last Year Comparison')}</span>
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <Icon.Help className="h-4 w-4 fill-grey" />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-xs">
                        <div className="flex flex-col gap-y-4">
                          <div>
                            {t(
                              `Day-of-Week: How are your days of the week performing compared to last year? A comparison of the day of week performances (Saturday this year to Saturday last year).`
                            )}
                          </div>
                          <div>
                            {t(
                              `Date-to-Date: How is the 31st of December performing compared to last year? A comparison of the calendar date performances (New Year's Eve this year to New Year's Eve last year).`
                            )}
                          </div>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </Typography>

              <RadioGroup
                onChange={(value: any) => onLastYearTypeChange(value as LastYearFilter)}
                className="mt-2"
                value={lastYearType}
                options={LAST_YEAR_OPTIONS.map((option) => ({
                  label: t(option.label) as string,
                  value: option.value
                }))}
              />
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
