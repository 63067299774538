import { create } from 'zustand';

export enum Tabs {
  FIXED_PRICE = 'fixedPrice',
  DECAY_PRICE = 'decayPrice'
}

type SurgeProtectionStore = {
  isDontSendEmail: boolean;
  setIsDontSendEmail: (value: boolean) => void;
  isAutoProtectionCheckboxEnable: boolean;
  setIsAutoProtectionCheckboxEnable: (value: boolean) => void;
  pricingTypeTabValue: Tabs;
  setPricingTypeTabValue: (value: Tabs) => void;
};

export const useSurgeProtectionStore = create<SurgeProtectionStore>((set) => ({
  isDontSendEmail: false,
  setIsDontSendEmail: (value: boolean) => set({ isDontSendEmail: value }),
  isAutoProtectionCheckboxEnable: false,
  setIsAutoProtectionCheckboxEnable: (value: boolean) =>
    set({ isAutoProtectionCheckboxEnable: value }),
  pricingTypeTabValue: Tabs.FIXED_PRICE,
  setPricingTypeTabValue: (value: Tabs) => set({ pricingTypeTabValue: value })
}));
