import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../auth';
import { axiosInstance } from '../axiosInstance';
import {
  DashboardErrorTicketList,
  ErrorTicketList,
  ParamsDashboardErrorTickets,
  ParamsErrorTickets,
  ParamsTicketTags,
  TicketTagsResponse,
  UpdateDashboardTicketPayload,
  UpdateMultipleDashboardTicketsPayload,
  UpdateMultipleTicketsPayload,
  UpdateTicketPayload
} from './types';

export const getErrorTicketTagList = async ({ ticket_type = 1 }: ParamsTicketTags) => {
  try {
    const { data } = await axiosInstance.get<TicketTagsResponse>('/errors/error-ticket/tag-list/', {
      params: {
        ticket_type
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getErrorTicketList = async ({
  ticket_status = 1,
  ticket_type = 1,
  page = 1,
  start_date,
  end_date,
  search,
  assignee,
  ticket_tag,
  provider,
  ordering,
  open_api_partner_id
}: ParamsErrorTickets) => {
  try {
    const { data } = await axiosInstance.get<ErrorTicketList>('/errors/error-ticket/', {
      params: {
        ticket_status,
        ticket_type,
        start_date,
        end_date,
        page,
        search,
        assignee,
        ticket_tag,
        provider,
        ordering,
        open_api_partner_id
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateErrorTicket = async (payload: UpdateTicketPayload, id: number | undefined) => {
  try {
    const { data } = await axiosInstance.patch(`/errors/error-ticket/${id}/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getDashboardErrorTicketList = async ({
  ticket_status = 1,
  tags_type = 'dashboard',
  page = 1,
  tags,
  search,
  developer
}: ParamsDashboardErrorTickets) => {
  try {
    const { data } = await axiosInstance.get<DashboardErrorTicketList>('/errors/error/', {
      params: {
        ticket_status,
        tags_type,
        tags,
        page,
        search,
        developer
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateMultipleTickets = async (payload: UpdateMultipleTicketsPayload) => {
  try {
    const { data } = await axiosInstance.patch(`/errors/error-ticket/update-multiple/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateDashboardErrorTicket = async (
  payload: UpdateDashboardTicketPayload,
  id: string | undefined
) => {
  try {
    const { data } = await axiosInstance.patch(`/errors/error/${id}/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateMultipleDashboardErrorTickets = async (
  payload: UpdateMultipleDashboardTicketsPayload
) => {
  try {
    const { data } = await axiosInstance.post(`/errors/error/`, payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
