import { Table } from '@common/components/molecules/Table';
import { ColumnDef, SortingState, Updater } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Badge } from '@common/components/atoms/Badge';
import { useCallback, useEffect, useState } from 'react';
import { ActionIcon, TextInput, Group, Title } from '@mantine/core';
import { getExportHotelClientList } from '@common/api/hotel';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue, useDocumentTitle } from '@mantine/hooks';
import { isEmpty, isNil, map } from 'lodash-es';
import { IconX } from '@tabler/icons-react';
import { Icon } from '@common/components/foundations/icons';
import {
  clientOrderingLookup,
  Hotel,
  HotelClientList,
  HotelClientListPagination
} from '@common/api/hotel/types';
import { useAuthStore } from '@common/store/auth';
import { useClientListStore } from '@common/store/hotel-list-store';
import { planLookup, subscriptionStatus } from '@common/config/app-config';
import { useHotelClientList } from '@pages/Admin/queries/use-hotel';
import { ClientListFiltersPopover } from '@pages/Admin/components/popovers/client-list-filters';
import { usePusherStore } from '@common/store/pusher';
import { PusherEventNames } from '@common/constants/pusher';
import { ActiveFiltersList } from '@pages/Admin/components/filters/active-filters-list';

const ROWS_PER_PAGE = 20;

const columns: ColumnDef<Hotel>[] = [
  { header: 'ID', accessorKey: 'id' },
  { header: 'Hotel', accessorKey: 'hotel' },
  { header: 'Email', accessorKey: 'email' },
  { header: 'Country', accessorKey: 'country' },
  { header: 'Address', accessorKey: 'address' },
  { header: 'Plans', accessorKey: 'plans' },
  {
    header: 'State',
    accessorKey: 'state',
    cell: (row) => (
      <Badge variant="contained" background={subscriptionStatus[row.row.original.state]?.color}>
        {subscriptionStatus[row.cell.row.original.state]?.label}
      </Badge>
    )
  },
  { header: 'End Of Free Trial', accessorKey: 'end_of_free_trial' },
  { header: 'PMS/CM', accessorKey: 'pms_provider_name' },
  { header: 'Auto Upload', accessorKey: 'auto_upload' },
  { header: 'Last Upload', accessorKey: 'last_upload' },
  { header: 'Last Login', accessorKey: 'last_login' },
  { header: 'Deal Owner', accessorKey: 'deal_owner' }
];

function pickHotelListValues(hotels: HotelClientListPagination | undefined) {
  const output: HotelClientList[] = [];
  if (isNil(hotels)) return output;
  map(hotels.results, (hotel) => {
    output.push({
      id: hotel.id,
      hotel: hotel.name || '-',
      email: hotel.email || '-',
      country: hotel.country.name || '-',
      address: hotel.address || '-',
      plans: planLookup[hotel.plan] || '-',
      state: hotel.state,
      end_of_free_trial: dayjs(hotel.free_trial_end).tz().format('DD/MM/YYYY') || '-',
      pms_provider_name: hotel.pms_provider_name || '-',
      auto_upload: hotel.is_update_to_pms ? 'ON' : 'OFF',
      last_upload: hotel.last_update_price_pms
        ? dayjs(hotel.last_update_price_pms).tz().format('DD/MM/YYYY HH:mm')
        : !hotel.last_update_price_pms_status && hotel.last_update_price_pms !== null
          ? 'Failed'
          : '-',
      last_login: hotel.last_login ? dayjs(hotel.last_login).tz().format('DD/MM/YYYY HH:mm') : '-',
      deal_owner: hotel.responsible_person
        ? hotel.responsible_person.sub_account_type == 2
          ? hotel.reseller
            ? hotel.reseller.name
            : `${hotel.responsible_person.first_name} ${hotel.responsible_person.last_name}`
          : `${hotel.responsible_person.first_name} ${hotel.responsible_person.last_name}`
        : ''
    });
  });
  return output;
}

function ClientListSearch() {
  const paramKey = 'search';
  const { params, setParams, setFilters } = useClientListStore();
  const [value, setValue] = useState(params.search || '');
  const [debounced] = useDebouncedValue(value, 1000);

  useEffect(() => {
    if (params.search !== value) setValue(params.search || '');
  }, [params.search]);

  useEffect(() => {
    setFilters({ [paramKey]: isEmpty(debounced) ? undefined : debounced });
    setParams({ [paramKey]: isEmpty(debounced) ? undefined : debounced });
  }, [debounced]);

  return (
    <TextInput
      placeholder="Search"
      value={value}
      variant="filled"
      onChange={(event) => setValue(event.currentTarget.value)}
      rightSection={
        isEmpty(value) ? null : (
          <ActionIcon size="xs" p={2} onClick={() => setValue('')}>
            <IconX />
          </ActionIcon>
        )
      }
    />
  );
}

export function ClientsAdminPage() {
  const { t } = useTranslation();
  useDocumentTitle(t('Login to Client'));
  const { user } = useAuthStore();
  const hotels = useHotelClientList();
  const { setParams, sorting, setSorting } = useClientListStore();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const currentCount = hotels?.data?.current;
  const results = hotels?.data?.results;
  const resultsCount = results?.length || 0;
  const isRpgAdmin = user?.is_staff && user?.sub_account_type !== 1;
  const { channel, channelBind, channelUnbind, preparePusher } = usePusherStore();

  const startIndex = currentCount ? (currentCount - 1) * ROWS_PER_PAGE + 1 : 0;
  const endIndex = currentCount && results ? startIndex + resultsCount - 1 : 0;

  function handleSortingChange(updater: Updater<SortingState>) {
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
    const columnIndex = sorting.findIndex((s) => s.id === newSorting[0].id);
    if (columnIndex >= 0) newSorting[0].desc = !sorting[columnIndex].desc;

    const ordering = newSorting[0].desc
      ? `-${clientOrderingLookup[newSorting[0].id]}`
      : clientOrderingLookup[newSorting[0].id];

    setParams({ ordering });
    setSorting(newSorting);
  }

  function handleNext() {
    if (isNil(currentCount)) return;
    setParams({ page: currentCount + 1 });
  }

  function handlePrevious() {
    if (isNil(currentCount) || currentCount === 0) return;
    setParams({ page: currentCount - 1 });
  }

  const initDownloadCLientListPusher = useCallback(() => {
    channelBind(PusherEventNames.DownloadHotelListMessage, async (data: any) => {
      if (data.download_url) {
        const link = document.createElement('a');
        link.href = data.download_url;
        link.click();
        setIsLoadingDownload(false);
      }
    });
  }, [channel]);

  // Prepare Pusher By User ID
  useEffect(() => {
    preparePusher(user?.id as number);
  }, [user]);

  // Listen to Pusher events
  useEffect(() => {
    channel && initDownloadCLientListPusher();

    return () => {
      channelUnbind(PusherEventNames.DownloadHotelListMessage);
    };
  }, [channel]);

  async function handleExportClientList() {
    setIsLoadingDownload(true);
    await getExportHotelClientList();
  }

  return (
    <Table
      headerComponent={
        <Title size="h3" fw="normal" component="h2" mr="md">
          Hotel List
        </Title>
      }
      data={pickHotelListValues(hotels?.data)}
      columns={columns}
      isHover={true}
      isFetching={hotels.isFetching}
      onClickRow={(_row, cell) => {
        window.open(`/client/calendar/${cell.getValue('id')}`);
      }}
      next={handleNext}
      isNext={!!hotels?.data?.next}
      previous={handlePrevious}
      isPrevious={!!hotels?.data?.previous}
      count={`${startIndex}-${endIndex}`}
      totalCount={hotels?.data?.count}
      manualSorting={true}
      onSortingChange={handleSortingChange}
      sorting={sorting}
      manualPagination={true}
      isRowClickable={true}
      rowsPerPage={ROWS_PER_PAGE}
      actionComponents={
        <Group gap="md">
          <ActiveFiltersList />
          <ClientListSearch />
          <ClientListFiltersPopover />
          {isRpgAdmin ? (
            <ActionIcon
              p={3}
              loading={isLoadingDownload}
              title="Export CSV"
              onClick={handleExportClientList}
            >
              <Icon.DownloadFromCloud />
            </ActionIcon>
          ) : null}
        </Group>
      }
    />
  );
}
