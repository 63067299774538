import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useCalendarPageStore } from '../store/calendar';
import { useRoomPrices } from './useRoomPrices';
import { Feature, useFeaturesStore } from '@common/store/features';

export const useCalendarOccupancy = () => {
  const { roomPrices, pricingSettings } = useRoomPrices();
  const { liliusInstance } = useCalendarPageStore();
  const { features } = useFeaturesStore();

  const occupancy = useMemo(() => {
    const month = dayjs(liliusInstance?.viewing).format('YYYY-MM');
    const latestActualOccupancy = roomPrices?.prices?.latest_actual_occupancy;

    if (latestActualOccupancy?.[month]) return Math.round(+latestActualOccupancy[month]);
    return;
  }, [liliusInstance?.viewing, roomPrices]);

  const targetOccupancy = useMemo(() => {
    const month = dayjs(liliusInstance?.viewing).get('month');
    const targetOccupancyByMonth = pricingSettings?.monthly_target_occupancy?.[month];
    const targetOccupancy = pricingSettings?.hotel.target_occupancy;

    if (features?.includes(Feature.OccupancyStrategy) && targetOccupancyByMonth) {
      return Math.round(+targetOccupancyByMonth);
    }

    if (targetOccupancy) return Math.round(+targetOccupancy);
    return;
  }, [liliusInstance?.viewing, pricingSettings]);

  const futureOccupancy = useMemo(() => {
    if (!liliusInstance) return;
    const month = liliusInstance?.viewing.getMonth();
    const year = liliusInstance?.viewing.getFullYear();

    return roomPrices?.prices?.performance?.future?.find(
      (item) => item.month === month + 1 && item.year === year
    )?.expected_occupancy;
  }, [liliusInstance?.viewing, roomPrices]);

  return { occupancy, futureOccupancy, targetOccupancy };
};
