import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { LANGUAGES } from '@common/constants/language';
import { useAuthStore } from '@common/store/auth';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Language = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { control, reset } = useFormContext();

  useEffect(() => {
    reset({
      language: user?.language
    });
  }, [user, reset]);

  return (
    <div className="flex w-full ">
      <div className="flex  w-full flex-col justify-between md:flex-row lg:flex-row">
        <div>
          <Typography>{t('Edit Your Language')}</Typography>
        </div>
        <div className="w-full flex-col gap-5 md:w-1/2 ">
          <div className="pb-5">
            <Controller
              control={control}
              name="language"
              render={({ field: { value, name, onChange } }) => (
                <SelectDropdown
                  fullWidth
                  placeholder={t('Select language') as string}
                  name={name}
                  key={name}
                  value={value}
                  options={LANGUAGES}
                  onChange={(value) => {
                    onChange(value);
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
