import { savePricingSettings } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useQueryClient, useMutation } from '@tanstack/react-query';

export const useUpdatePricingSettings = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (settings: string) => {
      return savePricingSettings(settings);
    },
    onSuccess: () => {
      addNotification('success', 'Settings saved successfully');
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    },
    onError: () => {
      addNotification('fail', 'Failed to update settings');
    }
  });

  return {
    savePricingSettings: mutate,
    isLoading,
    isSuccess
  };
};
