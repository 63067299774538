import { createHotel } from '@common/api/hotel';
import { CreateHotelPayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useRegisterHotel = () => {
  const { addNotification } = useNotificationsStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: CreateHotelPayload) => {
      return createHotel(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Hotel created successfully');
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.HOTEL_CLIENT_LIST] });
      setTimeout(() => {
        navigate('/admin/clients');
      }, 1000);
    },
    onError: () => {
      addNotification('fail', 'Failed to create hotel');
    }
  });

  return {
    createHotel: mutate,
    isLoading,
    isSuccess
  };
};
