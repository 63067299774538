import { useState } from 'react';
import { format, startOfMonth } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { cn } from '@common/utils/cn';
import { Button } from '@common/components/atoms/Button';
import { Popover, Text } from '@mantine/core';
import { Calendar } from '@common/components/molecules/Calendar/Calendar';
import { Icon } from '@common/components/foundations/icons';
import { useTranslation } from 'react-i18next';
import { Matcher } from 'react-day-picker';
import { useDisclosure } from '@mantine/hooks';

interface Props {
  ISOWeek?: boolean;
  onDateChange: (date: Date | undefined) => void;
  timezone?: string;
  selectedDate?: Date;
  disabledDays?: Matcher | Matcher[];
  numberOfMonths?: number;
  closeOnSelect?: boolean;
  description?: string;
}

export function DatePicker({
  ISOWeek,
  onDateChange,
  timezone,
  selectedDate,
  disabledDays,
  numberOfMonths = 1,
  closeOnSelect,
  description
}: Props) {
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();
  // Get the current date in the desired timezone
  const zonedDate = utcToZonedTime(
    new Date(),
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Determine the current month in the desired timezone
  const zonedMonth = startOfMonth(zonedDate);

  // Use a state to hold the current month for navigation
  const [currentMonth, setCurrentMonth] = useState(zonedMonth);
  const [date, setDate] = useState<Date | undefined>(zonedDate);
  const displayDate = selectedDate ?? date;

  return (
    <Popover opened={opened}>
      <Popover.Target>
        <Button
          type="button"
          intent={'outline'}
          className={cn(
            'w-48 justify-start text-left font-normal',
            !displayDate && 'text-darkGrey'
          )}
          onClick={() => (opened ? close() : open())}>
          <Icon.Calendar className="mr-2 h-4 w-4" />
          {displayDate ? format(displayDate, 'E, dd MMM yyyy') : <span>{t('Pick a date')}</span>}
        </Button>
      </Popover.Target>
      <Popover.Dropdown bg="dark.7" c="white" p="0" className="border-0">
        <Calendar
          ISOWeek={ISOWeek}
          mode="single"
          numberOfMonths={numberOfMonths}
          today={zonedDate}
          defaultMonth={selectedDate ? startOfMonth(selectedDate) : currentMonth}
          onMonthChange={setCurrentMonth}
          selected={displayDate}
          onSelect={(date) => {
            setDate(date);
            onDateChange(date);
            closeOnSelect && close();
          }}
          initialFocus
          disabled={disabledDays}
        />
        {description ? (
          <Text px="lg" py="xs" size="xs">
            {description}
          </Text>
        ) : null}
      </Popover.Dropdown>
    </Popover>
  );
}
