import { useFeaturesStore } from '@common/store/features';
import { useTranslation } from 'react-i18next';
import {
  ADJUSTMENT,
  AdjustmentOptionType
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { AdjustmentType } from '@pages/Client/Calendar/constants';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';

export const usePricingAdjustmentOptions = () => {
  const { t } = useTranslation();
  const { features, hasMinStayFeature } = useFeaturesStore();

  const adjustmentOptions: AdjustmentOptionType[] = [
    {
      id: ADJUSTMENT.PERCENT,
      label: t('Percent Adjustment'),
      value: AdjustmentType.Percent,
      userflowId: PriceDrawerUserflowIds.PERCENT_ADJUSTMENT_TAB_ITEM
    },
    ...(features?.includes(1)
      ? [
          {
            id: ADJUSTMENT.MIN_MAX,
            label: t('Min/Max Prices'),
            value: AdjustmentType.MinMax,
            userflowId: PriceDrawerUserflowIds.MIN_MAX_PRICES_TAB_ITEM
          }
        ]
      : []),
    {
      id: ADJUSTMENT.FIX_PRICES,
      label: t('Fix Prices'),
      value: AdjustmentType.FixPrices,
      userflowId: PriceDrawerUserflowIds.FIX_PRICES_TAB_ITEM
    },
    ...(hasMinStayFeature()
      ? [
          {
            id: ADJUSTMENT.MIN_STAY,
            label: t('Min Stay'),
            value: AdjustmentType.MinStay,
            userflowId: PriceDrawerUserflowIds.MIN_STAY_TAB_ITEM
          }
        ]
      : [])
  ];

  return { adjustmentOptions };
};
