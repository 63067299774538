import { getSabeePropertyList } from '@common/api/hotel';
import { SabeeAppPayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useSabeePropertyList = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: SabeeAppPayload) => {
      return getSabeePropertyList(payload).then((res) => {
        if (res.data.error) {
          addNotification('fail', `${res.data}`);
        } else {
          addNotification('success', `${res.data}`);
        }
      });
    },
    onError: () => {
      addNotification('fail', 'An error occured while getting property list.');
    }
  });

  return {
    sabeePropertyList: mutate,
    isLoading
  };
};
