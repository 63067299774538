import { setAuthToken } from '@common/api/axiosInstance';
import {
  addScheduleReports,
  deleteScheduleReports,
  generateScheduleReports,
  getScheduleReports,
  updateScheduleReports
} from '@common/api/dashboard';
import { EditReportData, NewReportData } from '@common/api/dashboard/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { ReportsQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useScheduleReportsList = () => {
  const { hotelAuthToken } = useHotelStore();

  const { data, isLoading, error } = useQuery({
    queryKey: [ReportsQueryKeys.PERFORMANCE_DASHBOARD_SCHEDULE_REPORTS_LIST],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getScheduleReports();
    },
    retry: false
  });

  return {
    schedule_reports: data,
    isLoading,
    error
  };
};

export const useAddScheduleReports = ({ onComplete }: { onComplete?: () => void }) => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (newData: NewReportData) => {
      setAuthToken(hotelAuthToken);
      return addScheduleReports(newData);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully created Scheduled Reports');
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === ReportsQueryKeys.PERFORMANCE_DASHBOARD_SCHEDULE_REPORTS_LIST
      });
      onComplete?.();
    },
    onError: () => {
      addNotification('fail', 'Failed to create Scheduled Reports');
    }
  });

  return {
    addScheduleReports: mutate,
    isLoading
  };
};

export const useUpdateScheduleReports = ({ onComplete }: { onComplete?: () => void }) => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const updateScheduleReportsMutation = async (params: [number, EditReportData]) => {
    const [id, newData] = params;
    setAuthToken(hotelAuthToken);
    try {
      const updatedData = await updateScheduleReports(id, newData);
      return updatedData;
    } catch (error) {
      throw new Error('Failed to update Scheduled Reports');
    }
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: updateScheduleReportsMutation,
    onSuccess: () => {
      addNotification('success', 'Successfully updated Scheduled Reports');
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === ReportsQueryKeys.PERFORMANCE_DASHBOARD_SCHEDULE_REPORTS_LIST
      });
      onComplete?.();
    },
    onError: () => {
      addNotification('fail', 'Failed to update Scheduled Reports');
    }
  });

  return {
    updateScheduleReports: mutate,
    isLoading
  };
};

export const useGenerateScheduleReports = ({ onComplete }: { onComplete?: () => void }) => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const { t } = useTranslation();

  const generateScheduleReportsMutation = async (params: [number]) => {
    const [id] = params;
    setAuthToken(hotelAuthToken);
    try {
      return await generateScheduleReports(id);
    } catch (error) {
      throw new Error('Failed to generate Scheduled Reports');
    }
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: generateScheduleReportsMutation,
    onSuccess: ({ data }) => {
      addNotification('success', t(data?.message || 'The scheduled report generation started.'));
      onComplete?.();
    },
    onError: () => {
      addNotification('fail', t('Failed to generate Scheduled Reports'));
    }
  });

  return {
    generateScheduleReports: mutate,
    isLoading
  };
};

export const useDeleteScheduleReports = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (id: number) => {
      setAuthToken(hotelAuthToken);
      return deleteScheduleReports(id);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully deleted Scheduled Reports');
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === ReportsQueryKeys.PERFORMANCE_DASHBOARD_SCHEDULE_REPORTS_LIST
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete Scheduled Reports');
    }
  });

  return {
    deleteScheduleReports: mutate,
    isLoading
  };
};
