import { z } from 'zod';

const RoomConfigSchema = z.object({
  is_occupancy_based: z.boolean(),
  is_rate_derivation: z.boolean(),
  room_rate_has_relation: z.boolean(),
  room_type_only_mapping: z.boolean()
});

export const ConfigurationsSchema = z.object({
  is_channel_manager: z.boolean(),
  room_config: RoomConfigSchema.optional()
});

export const PartnerSchema = z.object({
  name: z.string().nonempty({ message: 'Please enter a name' }),
  email: z.string().email({ message: 'Please enter a valid email address' }),
  is_active: z.boolean().optional(),
  event_url: z.string().url({
    message: 'Please enter a valid URL'
  }),
  configurations: ConfigurationsSchema,
  api_key: z.string().optional()
});

const PartnerEntrySchema = z.object({
  id: z.number(),
  created_at: z.string(),
  last_updated_at: z.string(),
  name: z.string(),
  email: z.string().email(),
  event_url: z.string().url(),
  is_active: z.boolean(),
  configurations: ConfigurationsSchema,
  api_key: z.string().optional()
});

const MetadataSchema = z.object({
  count: z.number(),
  current: z.number(),
  next: z.string().url().optional()
});

const ApiResponseSchema = z.object({
  metadata: MetadataSchema,
  data: z.array(PartnerEntrySchema)
});

export const DetailPartnerSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  last_updated_at: z.string(),
  name: z.string(),
  email: z.string().email(),
  event_url: z.string().url(),
  is_active: z.boolean(),
  configurations: ConfigurationsSchema,
  api_key: z.string().optional()
});

const DetailPartnerApiResponseSchema = z.object({
  metadata: z.object({}).passthrough(),
  data: DetailPartnerSchema
});

export const ParamsIntegrationPartnerSchema = z.object({
  page: z.number().optional(),
  search: z.string().nullable().optional(),
  ordering: z.string().nullable().optional()
});

const DataProvider = z.object({
  provider: z.number(),
  name: z.string(),
  open_api_partner_id: z.number().nullable().optional()
});

const ProvidersSchema = z.object({
  metadata: z.object({}).passthrough(),
  data: z.array(DataProvider)
});

const IntegrationSetupSchema = z.object({
  has_room_type_table: z.boolean(),
  has_rate_plan_table: z.boolean()
});

const DisplaysSchema = z.object({
  integration_setup: IntegrationSetupSchema
});

const DataProviderConfigSchema = z.object({
  provider: z.number(),
  name: z.string(),
  open_api_partner_id: z.number().nullable().optional(),
  has_webhook: z.boolean(),
  is_pull_price_integration: z.boolean(),
  is_channel_manager: z.boolean(),
  has_get_latest_data: z.boolean(),
  has_upload_price_button: z.boolean(),
  room_config: RoomConfigSchema,
  displays: DisplaysSchema
});

const DetailProviderConfigSchema = z.object({
  metadata: z.object({}).passthrough(),
  data: DataProviderConfigSchema
});

// Exporting TypeScript types inferred from Zod schemas
export type RoomConfig = z.infer<typeof RoomConfigSchema>;
export type Configurations = z.infer<typeof ConfigurationsSchema>;
export type PartnerEntry = z.infer<typeof PartnerEntrySchema>;
export type Metadata = z.infer<typeof MetadataSchema>;

export type IntegrationPartnerApiResponse = z.infer<typeof ApiResponseSchema>;
export type DetailPartnerApiResponse = z.infer<typeof DetailPartnerApiResponseSchema>;

export type CreatePartnerPayload = z.infer<typeof PartnerSchema>;
export type UpdatePartnerPayload = Partial<CreatePartnerPayload>;
export type ParamsIntegrationPartner = z.infer<typeof ParamsIntegrationPartnerSchema>;

export type ProvidersResponse = z.infer<typeof ProvidersSchema>;
export type DetailProviderConfigResponse = z.infer<typeof DetailProviderConfigSchema>;
