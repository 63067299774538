import { getHotelPricePerOccupancy, getHotelPricePerOccupancyV2 } from '@common/api/hotel';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useHotelPricePerOccupancy = (id: number | undefined) => {
  const query = useQuery({
    queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCY, id],
    queryFn: () => getHotelPricePerOccupancy(id),
    enabled: !!id
  });
  const hotelPricePerOccupancy = useMemo(() => {
    return query.data;
  }, [query.data]);

  return {
    query,
    hotelPricePerOccupancy
  };
};

export const useHotelPricePerOccupancyV2 = (id: number | undefined) => {
  const query = useQuery({
    queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_OCCUPANCYV2, id],
    queryFn: () => getHotelPricePerOccupancyV2(id),
    enabled: !!id
  });
  const hotelPricePerOccupancyV2 = useMemo(() => {
    return query.data;
  }, [query.data]);

  return {
    query,
    hotelPricePerOccupancyV2
  };
};
