export function formatHealthLabel(label: string, withParentheses = true): string {
  const trimmedLabel = label?.trim();
  if (!trimmedLabel) {
    return '';
  }

  let convertedLabel = label
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (l) => l.toUpperCase());

  const wordsToRemove = ['BUT'];

  wordsToRemove.forEach((word) => {
    const regex = new RegExp(`\\b${word}\\b`, 'gi');
    convertedLabel = convertedLabel.replace(regex, '');
  });

  convertedLabel = convertedLabel.trim().replace(/\s+/g, ' ');

  return withParentheses ? `(${convertedLabel})` : convertedLabel;
}
