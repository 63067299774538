import {
  getPmsRoomRateList,
  createPmsRoomRateList,
  updatePmsRoomRateList,
  deletePmsRoomRateList
} from '@common/api/hotel';
import {
  CreateRatePlanPayload,
  GuestLineUpdatePayload,
  RoomRatePmsList
} from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { RoomsQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const usePmsRoomRate = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST],
    queryFn: () => {
      return getPmsRoomRateList();
    },
    retry: true
  });

  return {
    pmsRoomRateList: data as RoomRatePmsList[],
    isLoading,
    error
  };
};

export const useCreatePmsRoomRate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: CreateRatePlanPayload) => {
      return createPmsRoomRateList(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Create Room Rate Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Create Room Rate');
    }
  });

  return {
    createRoomRate: mutate,
    isLoading,
    isSuccess
  };
};

export const useUpdatePmsRoomRateList = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: GuestLineUpdatePayload) => {
      return updatePmsRoomRateList(payload);
    },

    onSuccess: () => {
      addNotification('success', 'Room Rate Updated Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Room Rate');
    }
  });

  return {
    updateRoomRateList: mutate,
    isLoading,
    isSuccess
  };
};

export const useDeletePmsRoomRate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (id: number | number[]) => {
      return deletePmsRoomRateList(id);
    },
    onSuccess: () => {
      addNotification('success', 'Delete  Room Rate Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_RATE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Delete Room Rate');
    }
  });

  return {
    deleteRoomRate: mutate,
    isLoading,
    isSuccess
  };
};
