import { Feature, useFeaturesStore } from '@common/store/features';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Tooltip, Table } from '@mantine/core';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { IconInfoCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export function RoomPriceDataTooltipTable({ date }: { date: string }) {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { sortedHotelRooms } = useHotelRoomsList();
  const { pricingSettings } = usePricingSettings();
  const {
    cachePriceQuery: { data: cachePrice }
  } = useRoomPrices();

  return (
    <Tooltip
      position="bottom"
      label={
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th c="white">{t('Room')}</Table.Th>
              <Table.Th c="white">{t('Recommended Price')}</Table.Th>
              {features?.includes(Feature.SurgeProtectionPro) ? (
                <Table.Th c="white">{t('Protection Price')}</Table.Th>
              ) : null}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {sortedHotelRooms.map((room) => (
              <Table.Tr key={room.id}>
                <Table.Td>{room.name}</Table.Td>
                <Table.Td align="right">
                  {CurrencyFormatter.format(
                    cachePrice?.data?.prices?.data?.[date]?.[room.id]?.suggested_price
                  )}
                </Table.Td>
                {features?.includes(Feature.SurgeProtectionPro) ? (
                  <Table.Td align="right">
                    {CurrencyFormatter.format(
                      pricingSettings?.dates?.[date]?.[room.id]?.surge_protection
                    )}
                  </Table.Td>
                ) : null}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      }>
      <IconInfoCircle size={16} color="var(--mantine-primary-color-filled)" />
    </Tooltip>
  );
}
