import React, { useRef } from 'react';
import { startOfMonth, startOfToday } from 'date-fns';
import { DateRange, Matcher, SelectRangeEventHandler } from 'react-day-picker';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { isAfter, isWithinInterval, isSameDay } from 'date-fns';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useController, useFormContext } from 'react-hook-form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@common/components/molecules/Popoverv2/Popover';
import { cn } from '@common/utils/cn';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import { getPredefinedRanges } from '@common/utils/getPredefinedRanges';
import { Typography } from '@common/components/foundations/Typography';
import { Calendar } from '@common/components/molecules/Calendar/Calendar';
import dayjs from 'dayjs';

interface Props {
  ISOWeek?: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;
  onDateChange: (date: DateRange | undefined) => void;
  timezone?: string;
  allowPastDates?: boolean;
  allowFutureDates?: boolean;
  helperMessage?: React.ReactNode;
  isClearable?: boolean;
  fullWidth?: boolean;
  enablePredefinedRanges?: boolean;
}

export function DateRangePicker({
  ISOWeek,
  className,
  startDate,
  endDate,
  onDateChange,
  timezone,
  allowPastDates,
  allowFutureDates = true,
  helperMessage,
  isClearable,
  fullWidth,
  enablePredefinedRanges = false
}: React.HTMLAttributes<HTMLDivElement> & Props) {
  const { t } = useTranslation();
  const { liliusInstance } = useCalendarPageStore();
  const indigo = useTailwindColor('indigo');
  const indigoOpacity = 'rgb(91, 72, 238, 0.6)';

  // Get the current date in the desired timezone
  const zonedDate = utcToZonedTime(
    new Date(),
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Determine the current month in the desired timezone
  const zonedMonth = startOfMonth(zonedDate);

  // Use a state to hold the current month for navigation
  const [displayMonth, setDisplayMonth] = useState({
    zonedMonth,
    currentMonth: liliusInstance?.viewing,
    liliusMonth: liliusInstance?.viewing
  });

  const [date, setDate] = useState<DateRange | undefined>({ from: startDate, to: endDate });
  const [hoveredDate, setHoveredDate] = useState<Date | undefined>(undefined);
  const selectedDayRef = useRef<Date | undefined>(undefined);

  const handlePredefinedRangeSelect = (range: DateRange) => {
    setDate(range);
    onDateChange(range);
    setDisplayMonth((prev) => ({
      ...prev,
      currentMonth: range.from
    }));
  };

  const handleCurrentMonthChange = (month: Date) => {
    setDisplayMonth((prev) => ({ ...prev, currentMonth: month }));
  };

  useEffect(() => {
    setDate({ from: startDate, to: endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    setDisplayMonth((prev) => ({
      ...prev,
      currentMonth: liliusInstance?.viewing,
      liliusMonth: liliusInstance?.viewing
    }));
  }, [liliusInstance]);

  const handleDateChange: SelectRangeEventHandler = (range) => {
    const selectedDay = selectedDayRef.current as Date;

    if (date?.from && date?.to && selectedDay) {
      setDate({ from: selectedDay, to: undefined });
      onDateChange({ from: selectedDay, to: undefined });
    } else {
      setDate(range);
      onDateChange(range);
    }
  };

  const handleDayClick = (day: Date) => {
    selectedDayRef.current = day;
  };

  const handleDayMouseEnter = (day: Date) => {
    if (date?.from && !date?.to) {
      setHoveredDate(day);
    }
  };

  const handleDayMouseLeave = () => {
    setHoveredDate(undefined);
  };

  const inHoveredRange = (day: Date) => {
    if (date?.from && hoveredDate) {
      if (isAfter(hoveredDate, date.from)) {
        return isWithinInterval(day, { start: date.from, end: hoveredDate });
      } else {
        return isWithinInterval(day, { start: hoveredDate, end: date.from });
      }
    }
    return false;
  };

  const isStartOrEndDate = (day: Date): boolean => {
    return !!(date?.from && isSameDay(day, date.from)) || !!(date?.to && isSameDay(day, date.to));
  };

  const modifiersStyles = {
    hoveredRange: {
      backgroundColor: indigoOpacity
    },
    startOrEndDate: {
      backgroundColor: indigo
    }
  };

  const getDisabledDates = (): Matcher[] => [
    ...(!allowPastDates ? [{ before: zonedDate }] : []),
    ...(!allowFutureDates ? [{ after: zonedDate }] : [])
  ];

  const clear = () => {
    setDate({ from: undefined, to: undefined });
    onDateChange({ from: undefined, to: undefined });
  };

  // Helper function to compare if two dates are the same day, ignoring time
  const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            intent={'outline'}
            fullWidth
            className={cn(
              'w-80 justify-start text-left font-normal',
              !date && 'text-darkGrey',
              fullWidth && 'w-full'
            )}>
            <div className="flex w-full items-center justify-between">
              <Icon.Calendar className="min-fit mr-2 h-4 w-4" />

              <div className="flex-grow">
                {date?.from ? (
                  date.to ? (
                    <>
                      {dayjs(date.from).format('ddd, L')} - {dayjs(date.to).format('ddd, L')}
                    </>
                  ) : (
                    dayjs(date.from).format('ddd, L')
                  )
                ) : (
                  <span>{t('Pick a date range')}</span>
                )}
              </div>

              {isClearable && date?.from ? (
                <div className="min-fit w-5">
                  <Icon.Clear
                    className="ml-2 h-4 w-4 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      clear();
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-60 w-auto p-0" align="start" side="top">
          <div className="flex">
            {enablePredefinedRanges ? (
              <div className="mt-1 p-2">
                {getPredefinedRanges({
                  startDate: startOfToday(),
                  allowFutureDates,
                  allowPastDates
                })?.map((rangeObj, index) => {
                  // Check if the current range matches the selected range
                  const isSelected =
                    date?.from &&
                    date?.to &&
                    rangeObj.range.from &&
                    rangeObj.range.to &&
                    isSameDate(date.from, rangeObj.range.from) &&
                    isSameDate(date.to, rangeObj.range.to);

                  return (
                    <div key={index}>
                      <button
                        className={cn(
                          'w-full rounded-sm p-1.5 text-left hover:opacity-90',
                          isSelected ? 'bg-indigo bg-opacity-80' : ''
                        )}
                        onClick={() => handlePredefinedRangeSelect(rangeObj.range)}>
                        <Typography color="lightGrey">{t(rangeObj.title)}</Typography>
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <Calendar
              initialFocus
              today={zonedDate}
              month={displayMonth.currentMonth}
              onMonthChange={handleCurrentMonthChange}
              ISOWeek={ISOWeek}
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              disabled={getDisabledDates()}
              onSelect={handleDateChange}
              onDayClick={handleDayClick}
              numberOfMonths={2}
              onDayMouseEnter={handleDayMouseEnter}
              onDayMouseLeave={handleDayMouseLeave}
              modifiers={{
                hoveredRange: inHoveredRange,
                startOrEndDate: isStartOrEndDate
              }}
              fixedWeeks={true}
              modifiersStyles={modifiersStyles}
            />
          </div>
        </PopoverContent>
      </Popover>
      {helperMessage ? <>{helperMessage}</> : null}
    </div>
  );
}

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function DateRangeInput({
  ISOWeek,
  className,
  startDate,
  endDate,
  timezone,
  allowPastDates,
  allowFutureDates = true,
  helperMessage,
  name,
  userflowId
}: any) {
  const { t } = useTranslation();
  const { liliusInstance } = useCalendarPageStore();
  const indigo = useTailwindColor('indigo');
  const indigoOpacity = 'rgb(91, 72, 238, 0.6)';
  const { control } = useFormContext();
  const { field } = useController({ name, control });

  const zonedDate = utcToZonedTime(
    new Date(),
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const zonedMonth = startOfMonth(zonedDate);

  const [displayMonth, setDisplayMonth] = useState({
    zonedMonth,
    currentMonth: liliusInstance?.viewing,
    liliusMonth: liliusInstance?.viewing
  });

  const [date, setDate] = useState<DateRange | undefined>({ from: startDate, to: endDate });
  const [hoveredDate, setHoveredDate] = useState<Date | undefined>(undefined);
  const selectedDayRef = useRef<Date | undefined>(undefined);

  const handleCurrentMonthChange = (month: Date) => {
    setDisplayMonth((prev) => ({ ...prev, currentMonth: month }));
  };

  useEffect(() => {
    setDate({ from: startDate, to: endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    setDisplayMonth((prev) => ({
      ...prev,
      currentMonth: liliusInstance?.viewing,
      liliusMonth: liliusInstance?.viewing
    }));
  }, [liliusInstance]);

  const handleDateChange: SelectRangeEventHandler = (range) => {
    const selectedDay = selectedDayRef.current as Date;

    if (date?.from && date?.to && selectedDay) {
      setDate({ from: selectedDay, to: undefined });
      field.onChange({ from: selectedDay, to: undefined });
    } else {
      setDate(range);
      field.onChange(range);
    }
  };

  const handleDayClick = (day: Date) => {
    selectedDayRef.current = day;
  };

  const handleDayMouseEnter = (day: Date) => {
    if (date?.from && !date?.to) {
      setHoveredDate(day);
    }
  };

  const handleDayMouseLeave = () => {
    setHoveredDate(undefined);
  };

  const inHoveredRange = (day: Date) => {
    if (date?.from && hoveredDate) {
      if (isAfter(hoveredDate, date.from)) {
        return isWithinInterval(day, { start: date.from, end: hoveredDate });
      } else {
        return isWithinInterval(day, { start: hoveredDate, end: date.from });
      }
    }
    return false;
  };

  const isStartOrEndDate = (day: Date): boolean => {
    return !!(date?.from && isSameDay(day, date.from)) || !!(date?.to && isSameDay(day, date.to));
  };

  const modifiersStyles = {
    hoveredRange: {
      backgroundColor: indigoOpacity
    },
    startOrEndDate: {
      backgroundColor: indigo
    }
  };

  const getDisabledDates = (): Matcher[] => [
    ...(!allowPastDates ? [{ before: zonedDate }] : []),
    ...(!allowFutureDates ? [{ after: zonedDate }] : [])
  ];

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild data-userflow-id={userflowId}>
          <Button
            id="date"
            intent={'outline'}
            className={cn('w-80 justify-start text-left font-normal', !date && 'text-darkGrey')}>
            <Icon.Calendar className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {dayjs(date.from).format('ddd, L')} - {dayjs(date.to).format('ddd, L')}
                </>
              ) : (
                dayjs(date.from).format('ddd, L')
              )
            ) : (
              <span>{t('Pick a date range')}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            today={zonedDate}
            month={displayMonth.currentMonth}
            onMonthChange={handleCurrentMonthChange}
            ISOWeek={ISOWeek}
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            disabled={getDisabledDates()}
            onSelect={handleDateChange}
            onDayClick={handleDayClick}
            numberOfMonths={2}
            onDayMouseEnter={handleDayMouseEnter}
            onDayMouseLeave={handleDayMouseLeave}
            modifiers={{
              hoveredRange: inHoveredRange,
              startOrEndDate: isStartOrEndDate
            }}
            modifiersStyles={modifiersStyles}
          />
        </PopoverContent>
      </Popover>
      {helperMessage ? <>{helperMessage}</> : null}
    </div>
  );
}
