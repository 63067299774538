export const CommentOutline = (props: any) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3H20C21.1046 3 22 3.89543 22 5V17C22 18.1046 21.1046 19 20 19H14.91C14.6477 19.0011 14.3963 19.1053 14.21 19.29L12.72 20.78C12.5795 20.9207 12.3888 20.9998 12.19 21H11.81C11.6112 20.9998 11.4205 20.9207 11.28 20.78L9.79 19.29C9.60374 19.1053 9.35234 19.0011 9.09 19H4C2.89543 19 2 18.1046 2 17V5C2 3.89543 2.89543 3 4 3ZM14.91 17H20V5H4V17H9.09C9.88538 17.0007 10.6479 17.3172 11.21 17.88L12 18.67L12.79 17.88C13.3521 17.3172 14.1146 17.0007 14.91 17ZM6.5 14H13.5C13.7761 14 14 13.7761 14 13.5V12.5C14 12.2239 13.7761 12 13.5 12H6.5C6.22386 12 6 12.2239 6 12.5V13.5C6 13.7761 6.22386 14 6.5 14ZM17.5 10H6.5C6.22386 10 6 9.77614 6 9.5V8.5C6 8.22386 6.22386 8 6.5 8H17.5C17.7761 8 18 8.22386 18 8.5V9.5C18 9.77614 17.7761 10 17.5 10Z"
        fill="currentColor"
      />
    </svg>
  );
};
