import { Button } from '@common/components/atoms/Button';
import { Genie } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Modal } from '@common/components/molecules/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '@pages/Client/Account/Subscription/components/Downgrade.module.css';

import clsx from 'clsx';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  plan?: string;
};

export const DownGradeModal = ({ isOpen, onClose, plan }: Props) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <Modal showFooter={false} open={isOpen} onClose={onClose} size="custom">
      <div className="relative min-w-[600px] max-w-[800px] rounded-xl bg-white">
        <Button
          type="button"
          icon
          className="absolute right-6 top-6 z-30 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={onClose}>
          <Icon.Clear className="h-5 w-5" />
        </Button>
        <div className="min-h-[300px] p-5 pt-2 ">
          <div className="flex flex-col gap-2 py-2">
            <Typography variant="h2" color="darkGrey">
              {t(`Moving to ${plan}`)}
            </Typography>
            <Typography>
              {t(
                `Moving to a lower plan may change your price recommendations because certain features won't be available anymore. We'll have a look and let you know as soon as possible. Please fill out the form below. Thank you very much!`
              )}
            </Typography>
          </div>
          <div className="h-full w-full overflow-scroll">
            {loading && (
              <div className="my-2 flex w-full justify-center">
                <div className="flex flex-col items-center gap-x-4" title="RoomPriceGenie">
                  <div className="h-28 w-20 animate-rpg-bounce duration-700">
                    <Genie className="fill-indigo" />
                  </div>
                  <Typography color="indigo">{t('Loading Form')} ...</Typography>
                </div>
              </div>
            )}
            <iframe
              className={clsx(styles.iframe_form, 'min-h-[500px] w-full border-none')}
              onLoad={handleIframeLoad}
              src="https://share.hsforms.com/1FXVa88nASfG-CTB5kuub4A3cx7u"></iframe>
          </div>
        </div>
      </div>
    </Modal>
  );
};
