export const getPlanType = (state: number) => {
  switch (state) {
    case 0:
      return 'free trial';
    case 1:
      return 'subscribed';
    case 2:
      return 'cancelled';
    case 3:
      return 'free trial expired';
    case 4:
    case 5:
      return 'suspended';
    case 6:
      return 'subscribed(P)';
    case 7:
      return 'unsubscribed(P)';
    case 8:
      return 'Demo Hotel';
    case 9:
      return 'subscription paused';
    case 10:
      return 'sandbox';
    default:
      return '';
  }
};
