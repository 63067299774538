import axios, { AxiosError } from 'axios';
import { axiosInstance } from './axiosInstance';
import { HotelList } from './account/types';

export interface ErrorResponse {
  non_field_errors?: string[];
  user?: { message: string };
  authorization?: { message: string };
  detail?: string;
}

export interface ErrorMessage {
  success: boolean;
  message: string;
}

export type AccountError = ErrorResponse | AxiosError<ErrorResponse>;

export interface LoginInput {
  email: string;
  password: string;
}

export interface SSOInput {
  code: string;
}

export interface SSOAuthInput {
  token: string;
  refresh_token?: string;
  property_id: string;
}

export interface ConnectionAuthInput {
  code: string;
  token: string;
}

export interface LoginResponse {
  token: string;
  user: User;
  hotel_list: HotelList[];
}

export interface PropertiesResponse {
  token: string;
  property_list: Propertylist[];
  refresh_token: string;
}

interface Propertylist {
  property_code: string;
  property_name: string;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  do_not_call: boolean;
  notes?: any;
  payment_account_id?: any;
  is_staff: boolean;
  sub_account_type?: any;
  is_superuser: boolean;
  language?: any;
  is_authorize_in_moodle: boolean;
  is_collapsed_sidebar_enable: boolean;
  is_read_only: boolean;
}

export interface OTPResponse {
  success: boolean;
  message: boolean;
  non_field_errors?: string[];
}

export interface VerifyOTPResponse {
  success: boolean;
  message: string;
  user_data: AdminUserData;
}

export interface AdminUserData {
  user: AdminUser;
  hotel_list: any[];
}

export interface AdminUser extends User {
  password: string;
  last_login?: any;
  username: string;
  is_active: boolean;
  date_joined: string;
  state: number;
  free_trial_end?: any;
  belongs_id?: any;
  created: string;
  otp: string;
}

export interface RecoveryPasswordInput {
  email: string;
}

export interface RecoveryPasswordResponse {
  message: string;
}

export interface IframeURLPayload {
  email: string;
  password: string;
  hotel_id: string;
  language: string;
}

export type RecoveryPasswordErrorResponse = {
  email: string[];
};

export const recoveryPassword = async (input: RecoveryPasswordInput) => {
  const { data } = await axiosInstance.post<RecoveryPasswordResponse>(
    '/account/password/recovery/',
    input
  );
  return data;
};

export const loginUser = async (input: LoginInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/auth/', input);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

// TODO: Add the commented out code below to the updateProfile page
// export const useResetPassword = () => {
//   const addNotification = useNotificationsStore((state) => state.addNotification);

//   return useMutation({resetPassword}, {
//     onSuccess: () => {
//       addNotification('success', 'Reset Password Success!');
//     },
//     onError: (error: AxiosError<ErrorResponse>) => {
//       const errorData = error.response?.data;

//       if (errorData?.non_field_errors) {
//         addNotification('fail', errorData.non_field_errors[0]);
//       } else if (errorData?.authorization) {
//         addNotification('fail', errorData.authorization.message);
//       } else if (errorData?.detail) {
//         addNotification('fail', errorData.detail);
//       }
//     }
//   });
// };

// interface UpdateProfileInput {
//   first_name: string;
//   last_name: string;
//   email: string;
//   language: string;
// }

// export const updateProfile = async (input: UpdateProfileInput) => {
//   try {
//     const response = await axiosInstance.put('/account/update/', input);
//     return response;
//   } catch (err) {
//     if (axios.isAxiosError(err)) {
//       throw err as AxiosError<ErrorResponse>;
//     }
//     throw err as ErrorResponse;
//   }
// };

// export const useUpdateProfile = () => {
//   const addNotification = useNotificationsStore((state) => state.addNotification);

//   return useMutation({updateProfile}, {
//     onSuccess: () => {
//       addNotification('success', 'Change Language Success!');
//     },
//     onError: (error: AxiosError<ErrorResponse>) => {
//       const errorData = error.response?.data;

//       if (errorData?.non_field_errors) {
//         addNotification('fail', errorData.non_field_errors[0]);
//       } else if (errorData?.authorization) {
//         addNotification('fail', errorData.authorization.message);
//       } else if (errorData?.detail) {
//         addNotification('fail', errorData.detail);
//       }
//     }
//   });
// };

export const getOTP = async () => {
  try {
    const { data } = await axiosInstance.post<OTPResponse>('/account/two-factor-auth/resend_otp/');
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const verifyOTP = async (otp: string) => {
  try {
    const { data } = await axiosInstance.post<VerifyOTPResponse>(
      '/account/two-factor-auth/verify_otp/',
      {
        otp
      }
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoApaleo = async ({ code }: SSOInput) => {
  try {
    const { data } = await axiosInstance.post<PropertiesResponse>('/account/apaleo/properties/', {
      code
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoApaleoAuth = async ({ property_id, token, refresh_token }: SSOAuthInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/apaleo/auth/', {
      property_id,
      token,
      refresh_token
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoSeekom = async ({ code }: SSOInput) => {
  try {
    const { data } = await axiosInstance.post<PropertiesResponse>('/account/seekom/properties/', {
      code
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoSeekomAuth = async ({ property_id, token, refresh_token }: SSOAuthInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/seekom/auth/', {
      property_id,
      token,
      refresh_token
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const connectSeekom = async ({ code, token }: ConnectionAuthInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/seekom/auth/connection/', {
      code,
      token
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoFreetobook = async ({ code }: SSOInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/freetobook/auth/', {
      code
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const connectFreetobook = async ({ code, token }: ConnectionAuthInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>(
      '/account/freetobook/auth/connection/',
      {
        code,
        token
      }
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoCloudbeds = async ({ code }: SSOInput) => {
  try {
    const { data } = await axiosInstance.post<PropertiesResponse>('/account/integrations/oauth2/', {
      code,
      provider: 70
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorMessage>;
    }
    throw err as ErrorResponse;
  }
};

export const ssoCloudbedsAuth = async ({ property_id, token, refresh_token }: SSOAuthInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/integrations/oauth2/', {
      property_id,
      token,
      provider: 70,
      refresh_token
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const connectCloudbeds = async ({ code, token }: ConnectionAuthInput) => {
  try {
    const { data } = await axiosInstance.post<LoginResponse>('/account/integrations/oauth2/', {
      code,
      token,
      provider: 70
    });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const iframeLogin = async () => {
  try {
    const { data } = await axiosInstance.post<Omit<LoginResponse, 'token'>>(
      '/account/roompricegenie/iframe/user_data/'
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorResponse>;
    }
    throw err as ErrorResponse;
  }
};

export const iframeUrl = async ({ email, password, hotel_id, language }: IframeURLPayload) => {
  try {
    const { data } = await axiosInstance.post<{ url: string; timestamp: string }>(
      '/account/roompricegenie/iframe/',
      {
        email,
        password,
        hotel_id,
        language
      }
    );
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err as AxiosError<ErrorMessage>;
    }
    throw err as ErrorResponse;
  }
};
