import React, { useEffect, useState } from 'react';
import { trim, debounce } from 'lodash-es';
import { Modal } from '@common/components/molecules/Modal';
import { useFutureDaily } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useFutureDaily';
import { useSaveMarketData } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useSaveMarketData';
import { useSearchMarketCode } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useSearchMarketCode';
import { useManualSearchAirdnaStore } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Airbnb/store/manualSearch';
import { SaveMarketData } from '@common/api/hotel/types';
import { Typography } from '@common/components/foundations/Typography';
import AutoCompleteInput from '@common/components/atoms/AutoCompleteInput';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  onSaveSuccess?: () => void;
  children?: React.ReactNode;
};

export const ManualSearchModal = ({ isOpen, onClose, onSaveSuccess }: ModalProps) => {
  const { isLoading, searchMarketCode, marketCodeData } = useSearchMarketCode();
  const { futureDaily } = useFutureDaily();
  const { saveMarketData, isLoading: isSaveMarketDataLoading } = useSaveMarketData({
    onSuccess: onSaveSuccess
  });
  const { setLoading } = useManualSearchAirdnaStore();

  const [selectedOption, setSelectedOption] = useState({
    value: futureDaily?.city_name,
    label: futureDaily?.city_name
  });
  const [query, setQuery] = useState('');

  const debouncedSearch = debounce((query) => setQuery(query), 1000);

  const MARKET_CODE_OPTIONS = marketCodeData?.map((item: any) => ({
    label: `${item.name} (${item.city.id})`,
    value: item.name
  }));

  useEffect(() => {
    if (trim(query).length >= 5) {
      searchMarketCode({
        term: query
      });
    } else {
      searchMarketCode();
    }
  }, [query]);

  const handleSearch = (query: string) => {
    debouncedSearch(query);
    setSelectedOption({
      value: query,
      label: query
    });
  };

  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (futureDaily?.city_name) {
      setSelectedOption({
        value: futureDaily?.city_name,
        label: futureDaily?.city_name
      });
    }
  }, [futureDaily?.city_name]);

  useEffect(() => {
    if (isSaveMarketDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isSaveMarketDataLoading]);

  const targetData = marketCodeData?.find(
    (item: SaveMarketData) => item.name === selectedOption?.value
  );

  const handleSaveMarketData = () => {
    if (!targetData) return;
    saveMarketData(targetData);
  };

  return (
    <Modal
      okText="Save"
      continueIntent="primary"
      size="xl"
      open={isOpen}
      isLoading={isSaveMarketDataLoading}
      overFlowHidden={false}
      onClick={handleSaveMarketData}
      onClose={() => {
        onClose();
        setQuery('');
      }}>
      <div className="max-w-[560px]">
        <div className="min-w-[510px]">
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            Manual Search Airbnb City/Region
          </Typography>
          <Typography element="p" variant="meta-1" className="mb-1 text-grey">
            {'Airbnb city or region'}
          </Typography>

          <AutoCompleteInput
            options={MARKET_CODE_OPTIONS}
            loadingOptions={isLoading}
            value={selectedOption}
            showOnclick={false}
            truncateLength={80}
            showOptions={marketCodeData?.length > 0}
            onChange={handleOptionChange}
            hint="Please enter at least 5 characters"
            placeholder="Search ..."
            query={query}
            onQueryChange={handleSearch}
          />
        </div>
      </div>
    </Modal>
  );
};
