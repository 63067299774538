import { range } from 'lodash-es';
import { DOMAttributes, useEffect, useRef, useState } from 'react';

type OTPInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  length: number;
  value: string;
  onComplete: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export function OTPInput({ length, value = '', onComplete, onChange, ...props }: OTPInputProps) {
  const [otp, setOTP] = useState<string[]>(
    value.split('').concat(range(0, length - value.length).map(() => ''))
  );
  const refs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    if (value.length !== length) return;
    if (value.split('').every((char) => char !== '')) {
      onComplete();
    }
  }, [value]);

  useEffect(() => {
    setOTP(value.split('').concat(range(0, length - value.length).map(() => '')));
  }, [value]);

  useEffect(() => {
    if (refs.current[0]) refs.current[0].focus();
  }, []);

  const handlePaste: DOMAttributes<HTMLInputElement>['onPaste'] = (event) => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('text');
    const newOTP = [...otp].map((_, i) => pastedValue[i] ?? _);

    setOTP(newOTP);

    onChange?.({
      target: {
        value: newOTP.join('')
      }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleOTPChange = (index: number, newValue: string) => {
    const newOTP = [...otp];
    newOTP[index] = newValue;
    setOTP(newOTP);

    if (newValue !== '' && newValue !== ' ') {
      if (refs.current[index + 1]) {
        refs.current[index + 1].focus();
      }
    } else if (newValue === '' || newValue === ' ') {
      if (refs.current[index - 1]) {
        refs.current[index - 1].focus();
      }
    }
    if (typeof onChange === 'function') {
      const event = {
        target: {
          value: newOTP.join('')
        }
      };
      onChange(event as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      if (refs.current[index - 1]) {
        refs.current[index - 1].focus();
      }
    } else if (event.key === 'Delete') {
      event.preventDefault();
    }
  };

  return (
    <>
      {otp.map((otpSingleValue, index) => (
        <div key={index} className="flex h-16 w-16 items-center justify-center">
          <input
            className="h-full w-full rounded-lg border border-grey-reduced bg-white px-5 text-center text-lg outline-none focus:bg-lightGrey-reduced focus:ring-1 focus:ring-focusNative active:border-indigo"
            type="text"
            value={otp[index]}
            onPaste={handlePaste}
            onChange={(e) => handleOTPChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            maxLength={1}
            ref={(el) => {
              refs.current[index] = el as HTMLInputElement;
            }}
            {...props}
          />
        </div>
      ))}
    </>
  );
}
