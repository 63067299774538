import { Typography } from '@common/components/foundations/Typography';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { cn } from '@common/utils/cn';
import { useTranslation } from 'react-i18next';
import { OccupancyTooltip } from '@pages/Client/Calendar/pages/Occupancy/components/OccupancyTooltip';

export const OccupancyLegend = () => {
  const { t } = useTranslation();
  return (
    <div className="mr-4">
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger>
            <div className="mr-4 inline-flex items-center">
              <div className={cn('mr-1 h-4 w-4 shrink-0 rounded-full bg-orange bg-opacity-80')} />
              <Typography variant="meta-1">{t('Not Enough Bookings')}</Typography>
            </div>
          </TooltipTrigger>
          <TooltipContent
            side="bottom"
            className="max-w-screen w-[400px] whitespace-normal bg-darkGrey leading-relaxed ">
            <OccupancyTooltip />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger>
            <div className="inline-flex items-center">
              <div className={cn('mr-1 h-4 w-4 shrink-0 rounded-full bg-indigo bg-opacity-80')} />
              <Typography variant="meta-1">{t('Too Many Bookings')}</Typography>
            </div>
          </TooltipTrigger>
          <TooltipContent
            side="bottom"
            className="max-w-screen w-[400px] whitespace-normal bg-darkGrey leading-relaxed">
            <OccupancyTooltip />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
