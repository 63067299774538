import { Modal } from '@common/components/molecules/Modal';
import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useReportIssue } from '../hooks/useHelp';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const atLeastOneCategorySelected = (value: string[]) => {
  return value.length > 0;
};

const schema = z.object({
  subject: z.string().nonempty({ message: 'Please enter subject' }),
  content: z.string().nonempty({ message: 'Please enter content' }),
  hs_ticket_category: z
    .array(z.string())
    .min(1, 'Please select at least one category')
    .refine(atLeastOneCategorySelected, {
      message: 'Please select at least one category',
      path: ['hs_ticket_category']
    }),
  hs_ticket_priority: z.string().nonempty({ message: 'Please enter priority' }),

  firstname: z.string().nonempty({ message: 'Please enter firstname' }),
  lastname: z.string().nonempty({ message: 'Please enter lastname' }),
  email: z.string().nonempty({ message: 'Please enter email' })
});

export const ReportIssueModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const checkboxOptions = [
    { value: 'PRODUCT_ISSUE', label: t('Error Notification') },
    { value: 'Price Calculation', label: t('Price Calculation') },
    { value: 'GENERAL_INQUIRY', label: t('Account/Room Setup') },
    { value: 'Price Adjustments', label: t('Price Adjustments') },
    { value: 'BILLING_ISSUE', label: t('Subscription / Billing') },
    { value: 'FEATURE_REQUEST', label: t('Feature Request') }
  ];
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { reportIssue } = useReportIssue();

  const onSubmit = (data: any) => {
    reportIssue({
      contact: {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname
      },
      ticket: {
        content: data.content,
        hs_ticket_category: data.hs_ticket_category?.join(';'),
        hs_ticket_priority: data.hs_ticket_priority,
        subject: data.subject
      }
    });
  };

  return (
    <Modal
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!isValid}
      overFlowHidden={true}
      open={isOpen}
      okText={`${t('Report Issue')}`}
      onClose={onClose}
      size={'full'}
      continueIntent="primary"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <Typography element="h6" color="darkGrey" variant="h6">
              {t('Report an Issue')}
            </Typography>
            <Typography>
              {t('Please use this form to send technical errors to RoomPriceGenie Support')}
            </Typography>

            <div className="mt-4 grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
              <div>
                <Typography>{t('Report Information')}</Typography>
                <div className="my-4 flex flex-col gap-3">
                  <div className="flex flex-col gap-2">
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t('How would you categorize your request?')}*
                    </Typography>
                    <div className="mb-2 flex flex-col gap-2">
                      {checkboxOptions.map((option) => (
                        <div className="flex items-center gap-2" key={option.value}>
                          <Controller
                            name="hs_ticket_category"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Checkbox
                                name="hs_ticket_category"
                                value={option.value}
                                checked={value?.includes(option.value) ?? false}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  const updatedValue = value ? [...value] : []; // Copy the current value array or initialize with an empty array
                                  const index = updatedValue.indexOf(option.value);
                                  if (isChecked) {
                                    if (index === -1) {
                                      updatedValue.push(option.value); // Add the value if it doesn't exist
                                    }
                                  } else {
                                    if (index !== -1) {
                                      updatedValue.splice(index, 1); // Remove the value if it exists
                                    }
                                  }
                                  onChange(updatedValue);
                                }}
                              />
                            )}
                          />
                          <Typography element="p" variant="meta-1" className="text-grey">
                            {option.label}
                          </Typography>
                        </div>
                      ))}
                      {errors && (
                        <div className="flex items-center gap-2 text-error">
                          {errors.hs_ticket_category ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {errors.hs_ticket_category
                              ? errors?.hs_ticket_category?.message?.toString()
                              : ''}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>

                  <Controller
                    name="subject"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        name="subject"
                        type="text"
                        background="grey"
                        value={value}
                        label={`${t('Property Name')}*`}
                        placeholder={`${t('Please enter Property name')}`}
                        onChange={onChange}
                        onClear={() => setValue('subject', '')}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                  <div>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t('What issue are you experiencing?')}*
                    </Typography>
                    <Controller
                      name="content"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <textarea
                          rows={2}
                          name="content"
                          onChange={onChange}
                          value={value}
                          className={`
                          ${error ? 'ring-error ' : 'ring-grey ring-opacity-30'}
                          text-gray-900 placeholder:text-gray-400 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1   focus:ring-[2px] focus:ring-indigo  focus:ring-opacity-30 sm:text-sm sm:leading-6
                          `}
                        />
                      )}
                    />
                    <div className="mt-2">
                      {errors && (
                        <div className="flex items-center gap-2 text-error">
                          {errors.content ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {errors.content ? errors?.content?.message?.toString() : ''}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t('Indicate the urgency of the issue you are facing')}*
                    </Typography>
                    <Controller
                      control={control}
                      name="hs_ticket_priority"
                      render={({ field: { value, name, onChange } }) => (
                        <SelectDropdown
                          fullWidth
                          placeholder="Select urgency"
                          name={name}
                          key={name}
                          value={value || ''}
                          options={[
                            { value: 'LOW', label: t('Low') },
                            { value: 'MEDIUM', label: t('Medium') },
                            { value: 'HIGH', label: t('High') }
                          ]}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                    <div className="mt-1">
                      {errors && (
                        <div className="flex items-center gap-2 text-error">
                          {errors.hs_ticket_priority ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {errors.hs_ticket_priority
                              ? errors?.hs_ticket_priority?.message?.toString()
                              : ''}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Typography>{t('Personal Information')}</Typography>
                <div className="my-4 flex flex-col gap-3">
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        name="firstname"
                        background="grey"
                        type="text"
                        value={value}
                        label={`${t('First Name')}*`}
                        placeholder={`${t('Please enter first name')}`}
                        onChange={onChange}
                        onClear={() => setValue('firstname', '')}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                  <Controller
                    name="lastname"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        name="lastname"
                        type="text"
                        value={value}
                        label={`${t('Last Name')}*`}
                        background="grey"
                        placeholder={`${t('Please enter last name')}`}
                        onChange={onChange}
                        onClear={() => setValue('lastname', '')}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        name="email"
                        type="text"
                        value={value}
                        background="grey"
                        label={`${t('Email')}*`}
                        placeholder={`${t('Please enter email')}`}
                        onChange={onChange}
                        onClear={() => setValue('email', '')}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};
