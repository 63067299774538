import { FormInput } from '@common/components/atoms/Input';
import { useEffect, useState } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { isEmpty, isNil, map, result } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Typography } from '@common/components/foundations/Typography';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useBuildAdjustmentInputsArray } from '@pages/Client/Calendar/components/BulkEdit/hooks/useBuildAdjustmentInputsArray';
import { Derivation } from '@pages/Client/Calendar/components/BulkEdit/types/schema/derivationSchema';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function DerivationAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.DERIVATION;
  const { hotelDetails } = useHotelDetails();
  const { pricingSettings } = useRoomPrices();
  const [prefillDerivedRooms] = useState(true);
  const { displayHotelRooms } = useHotelRoomsList();
  const { control, formState, watch, trigger } = useFormContext();
  const adjustmentFields: Derivation[] = useBuildAdjustmentInputsArray<Derivation>(formName);
  const { fields, replace } = useFieldArray({ name: formName, control });
  const isPercentage = !result(pricingSettings, 'hotel.adjustment_to_reference_room_is_absolute');

  function getAdjustmentToRefRoomText(roomId: number) {
    const adj =
      Number(result(pricingSettings, `default[${roomId}].adjustment_to_reference_room`)) ?? 0;
    const sign = adj > 0 ? '+' : '';
    return (
      sign + (isPercentage ? Math.round(adj) + '%' : CurrencyFormatter.format(Math.round(adj)))
    );
  }

  useEffect(() => {
    if (isEmpty(fields) && !isEmpty(pricingSettings)) replace(adjustmentFields);
  }, [adjustmentFields, pricingSettings]);

  useEffect(() => {
    const subscription = watch((_) => {
      trigger(formName);
    });
    return () => subscription.unsubscribe();
  }, [watch, prefillDerivedRooms, fields]);

  return (
    <LineTable>
      <thead>
        <tr>
          <th>{t(`${hotelDetails?.room_apartment_space_name} Types`)}</th>
          <th>
            {t('Derivation from Ref.')} {hotelDetails?.room_apartment_space_name}
          </th>
        </tr>
      </thead>

      <tbody>
        {map(fields, (field, index) => {
          const room = displayHotelRooms[index];
          return !isNil(room) ? (
            <tr key={field.id}>
              <td>
                {room.name} {`${import.meta.env.DEV ? room.id : ''}`}
              </td>
              <td>
                <div className="w-full">
                  {room?.is_reference_room ? (
                    <span>
                      {t('Base Price')}{' '}
                      {CurrencyFormatter.format(pricingSettings?.default[room.id].avg_price)}
                    </span>
                  ) : (
                    <FormInput
                      type="number"
                      nameKey={`${formName}.${index}.id` as const}
                      valueKey={`${formName}.${index}.value` as const}
                      background="grey"
                      leadingAddon={isPercentage ? '%' : CurrencyFormatter.currencySymbol()}
                      trailingAddon={
                        <span className="flex flex-col items-end">
                          <Typography variant="meta-3" color="grey">
                            {t('Default')}
                          </Typography>
                          <Typography variant="meta-2" color="grey">
                            {getAdjustmentToRefRoomText(room.id)}
                          </Typography>
                        </span>
                      }
                      showClearButton={false}
                      helperMessage={result(formState, `errors.${formName}[${index}].message`)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ) : null;
        })}
      </tbody>
    </LineTable>
  );
}
