import { Navigate, RouteObject } from 'react-router-dom';
import { Feature } from '@common/store/features';

import { SecureRoute } from '@pages/Client/components/SecureRoute';

import PerformancePage from './pages/Performance';
import AnalyticsPage from './pages/Analytics';
import { AirbnbMarketInsights } from './pages/Market/AirbnbInsights/AirbnbInsights';

export const dashboardRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="performance" replace /> },
  {
    path: 'performance',
    element: (
      <SecureRoute
        feature={[Feature.ProfessionalDashboard, Feature.LimitedDashboard, Feature.DashboardOnly]}>
        <PerformancePage />
      </SecureRoute>
    )
  },
  {
    path: 'analytics',
    element: (
      <SecureRoute feature={[Feature.ProfessionalDashboard]}>
        <AnalyticsPage />
      </SecureRoute>
    )
  },
  {
    path: 'market',
    element: (
      <SecureRoute feature={[Feature.ProMarketInsights, Feature.LimitedMarketInsights]}>
        <AirbnbMarketInsights />
      </SecureRoute>
    )
  }
];
