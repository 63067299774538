import {
  CreatePartnerPayload,
  DetailPartnerApiResponse,
  ParamsIntegrationPartner,
  UpdatePartnerPayload,
  createIntegrationPartner,
  deleteIntegrationPartner,
  getIntegrationPartner,
  getIntegrationPartners,
  updateIntegrationPartner
} from '@common/api/integration';
import { useNotificationsStore } from '@common/store/notifications';
import { IntegrationPartnerQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';

interface GetDetailIntPartnerParams {
  id: number | null;
  mode: 'add' | 'edit';
}

export const useCreateIntPartner = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isSuccess,
    isPending: isLoading
  } = useMutation({
    mutationFn: (payload: CreatePartnerPayload) => {
      return createIntegrationPartner(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Create Integration Partner Successfully');
      queryClient.invalidateQueries({
        queryKey: [IntegrationPartnerQueryKeys.GET_INTEGRATION_PARTNERS]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to Create Integration Partner');
    }
  });

  return {
    createIntPartner: mutate,
    isSuccess,
    isLoading
  };
};

export const useGetIntPartners = ({ ordering, page, search }: ParamsIntegrationPartner) => {
  const query = useQuery({
    queryKey: [IntegrationPartnerQueryKeys.GET_INTEGRATION_PARTNERS, ordering, page, search],
    queryFn: () => getIntegrationPartners({ ordering, page, search })
  });

  const intPartnerList = useMemo(() => query.data, [query.data]);

  return { intPartnerList, query };
};

export const useGetDetailIntPartner = ({ id, mode }: GetDetailIntPartnerParams) => {
  const query = useQuery({
    queryKey: [IntegrationPartnerQueryKeys.GET_INTEGRATION_PARTNER],
    queryFn: () => {
      if (id === null) {
        return null;
      }
      return getIntegrationPartner(id);
    },
    enabled: !isNil(id) && mode === 'edit'
  });

  const intPartnerDetail = query.data as DetailPartnerApiResponse;

  return { intPartnerDetail, query };
};

export const useUpdateIntPartner = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: UpdatePartnerPayload & { id: number }) =>
      updateIntegrationPartner(payload.id, payload),
    onSuccess: () => {
      addNotification('success', 'Update Integration Partner Successfully');
      queryClient.invalidateQueries({
        queryKey: [IntegrationPartnerQueryKeys.GET_INTEGRATION_PARTNERS]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Integration Partner');
    }
  });

  return {
    updateIntPartner: mutate,
    isLoading
  };
};

export const useDeleteIntPartner = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (id: number) => deleteIntegrationPartner(id),
    onSuccess: () => {
      addNotification('success', 'Delete Integration Partner Successfully');
      queryClient.invalidateQueries({
        queryKey: [IntegrationPartnerQueryKeys.GET_INTEGRATION_PARTNERS]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to Delete Integration Partner');
    }
  });

  return {
    deleteIntPartner: mutate,
    isLoading
  };
};
