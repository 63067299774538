import { resetPickupBoostPrice } from '@common/api/pricingAlgorithm';
import { ResetPickupBoostPrice } from '@common/api/pricingAlgorithm/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useMutation } from '@tanstack/react-query';

export const useResetPickupBoostPrice = () => {
  const { addNotification } = useNotificationsStore();
  const { cachePriceQuery } = useRoomPrices();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: ResetPickupBoostPrice) => {
      return resetPickupBoostPrice(data);
    },
    onSuccess: () => {
      addNotification('success', 'Success reset prices');
      cachePriceQuery.refetch();
    },
    onError: () => {
      addNotification('fail', 'Failed to reset prices');
    }
  });

  return {
    resetPickupBoostPrice: mutate,
    isLoading
  };
};
