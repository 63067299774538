import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import CompetitorListPage from '@pages/Client/PricingStrategy/MarketIntelligence/pages/CompetitorList';
import AnalyticsPage from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Analytics';
import MapPage from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Map';
import PickupBoostPricePage from '@pages/Client/PricingStrategy/MarketIntelligence/pages/PickupBoostPrices';
import AirbnbPage from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Airbnb';

export const marketIntelligenceRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="competitor-list" /> },
  {
    path: 'competitor-list',
    element: (
      <SecureRoute>
        <CompetitorListPage />
      </SecureRoute>
    )
  },
  {
    path: 'analytics',
    element: (
      <SecureRoute>
        <AnalyticsPage />
      </SecureRoute>
    )
  },
  {
    path: 'map',
    element: (
      <SecureRoute>
        <MapPage />
      </SecureRoute>
    )
  },
  {
    path: 'pickup-boost-price',
    element: (
      <SecureRoute>
        <PickupBoostPricePage />
      </SecureRoute>
    )
  },
  {
    path: 'airbnb',
    element: (
      <SecureRoute>
        <AirbnbPage />
      </SecureRoute>
    )
  }
];
