import { getMarketCode } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useMarketCode = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return getMarketCode();
    },
    onSuccess: (response) => {
      const { success, message } = response.data;
      if (!success) {
        const errorMessage = message ?? 'Failed to to fetch AirDNA Data';
        addNotification('fail', errorMessage);
      }
    },
    onError: () => {
      addNotification('fail', 'Failed to to fetch AirDNA Data');
    }
  });

  return {
    getMarketCode: mutate,
    isLoading
  };
};
