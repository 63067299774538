export const TICKET_STATUS = [
  {
    label: 'Open',
    value: '1'
  },
  {
    label: 'Closed',
    value: '2'
  },
  {
    label: 'Blocked',
    value: '3'
  }
];
