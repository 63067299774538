import { create } from 'zustand';
interface LoadingStore {
  isCheckBookingLoadingState: boolean;
  setCheckBookingLoading: (newLoading: boolean) => void;
}

export const useBookingCheckLoadingStore = create<LoadingStore>((set) => ({
  isCheckBookingLoadingState: false,
  setCheckBookingLoading: (newLoading: boolean) => set({ isCheckBookingLoadingState: newLoading })
}));
