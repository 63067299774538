import { Button } from '@common/components/atoms/Button';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { useRegisterProperties } from '@pages/Auth/SSO/hooks/useRegisterProperties';
import { useGetSSOToken } from '@pages/Auth/SSO/store/ssoToken';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function RegisterConfirmation() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const propertiesName = searchParams.get('property-name');
  const propertiesCode = searchParams.get('property-code');
  const propertiesDesc = searchParams.get('property-desc');
  const { setSsoToken } = useGetSSOToken();
  const { isRegisterPropertiesLoading, registerProperties } = useRegisterProperties();

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setSsoToken(token);
    }
  }, [token, setSsoToken]);

  const handleRegisterProperties = async () => {
    if (propertiesCode) {
      await registerProperties(propertiesCode);
    }
  };

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="mt-6">
            <div className="flex flex-col space-y-2">
              <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
                Hi, there!
              </Typography>
              <Typography color="darkGrey" variant="h5" element="h5" className="font-bold">
                {propertiesName}
              </Typography>
              <Typography color="darkGrey" variant="h5" element="h5" className="font-medium">
                {propertiesDesc}
              </Typography>
              <Typography element="h5" variant="paragraph-1" className="mt-2" color="copyTextGrey">
                We&apos;ve noticed the properties do not exist in the app. Please go ahead and
                create them. If you need to create more properties, please use the SSO button and
                set them up individually. Thank you very much!
              </Typography>
            </div>
            <div className="mt-4 flex max-h-screen flex-col space-y-3">
              <Button
                onClick={handleRegisterProperties}
                isLoading={isRegisterPropertiesLoading}
                disabled={!propertiesCode}
                size="large">
                Create
              </Button>
              <Button intent="outline" onClick={() => navigate('/sso')} size="large">
                Back to Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
