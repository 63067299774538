import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Page } from '@common/components/organisms/Page';
import { browserTimezone } from '@common/utils/browserTimezone';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDocumentTitle } from '@mantine/hooks';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useGetReservationApiLogs } from '@pages/Client/ReservationApiLog/hooks/useReservationApiLog';
dayjs.extend(utc);
dayjs.extend(timezone);

const schema = z.object({
  page: z.number(),
  request_type: z.number(),
  search: z.string().optional(),
  date: z.string().optional(),
  ordering: z.string().optional()
});

interface HeaderProps {
  id: string;
  desc: boolean;
}
type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== '' && value !== undefined && value !== null
    )
  ) as Params;
};

export const ReservationApiLog = () => {
  useDocumentTitle('Reservation Log');
  const defaultValues = {
    page: 1,
    request_type: 1,
    search: undefined,
    date: undefined,
    ordering: '-created'
  };

  const { hotelDetails } = useHotelDetails();

  const { watch, reset, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const {
    hotelReservationApiLogs,
    query: { isLoading },
    refetchLogs,
    isRefetchLoading
  } = useGetReservationApiLogs(
    filterEmptyValues({
      page: watch('page'),
      request_type: watch('request_type'),
      search: getValues('search'),
      date: watch('date') ? dayjs(watch('date')).format('YYYY-MM-DD') : undefined,
      ordering: watch('ordering')
    })
  );

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, defaultValues, filterEmptyValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
    setValue('page', 1);
  };

  const ROWS_PER_PAGE = 20;

  const startIndex = hotelReservationApiLogs?.current
    ? (hotelReservationApiLogs.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    hotelReservationApiLogs?.current && hotelReservationApiLogs.results
      ? startIndex + (hotelReservationApiLogs?.results?.length || 0) - 1
      : 0;

  const handleNext = () => {
    if (!hotelReservationApiLogs?.next) return;
    const params = new URLSearchParams(hotelReservationApiLogs.next.split('?')[1]);
    const page = params.get('page');
    const date = params.get('date');
    const search = params.get('search');
    const ordering = params.get('ordering');
    if (!page) return;
    reset(
      filterEmptyValues({
        page: Number(page),
        date: date || undefined,
        search: search || undefined,
        request_type: 1,
        ordering: ordering || undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  const columns = [
    {
      header: 'Received at',
      accessorKey: 'created',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-col">
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dayjs(row?.getValue()).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}
                    </Typography>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Hotel Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(hotelDetails?.timezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Browser Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(browserTimezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Messages Type',
      accessorKey: 'request_type',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Badge background="indigo">{row?.getValue()}</Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Corelation Id',
      accessorKey: 'correlation_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" variant="paragraph-3" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },

    {
      header: 'Download',
      accessorKey: 'url',
      cell: (row: any) => {
        const downloadFile = (url: string) => {
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.download = fileName;
          link.click();
        };
        return (
          <div className="flex items-center gap-3">
            <Button
              size="small"
              intent="text"
              className="text-indigo text-opacity-90"
              onClick={() => {
                downloadFile(row?.getValue() as string);
              }}>
              <Icon.Download className="h-4 w-4" />
              Download
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <Page header={<Header title="Reservation Api Logs" />}>
      <Table
        isHover
        skeletonCount={20}
        isFetching={isLoading}
        data={!isLoading ? (hotelReservationApiLogs?.results as any) : []}
        columns={columns}
        next={handleNext}
        isNext={!!hotelReservationApiLogs?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={hotelReservationApiLogs?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        manualSorting={true}
        onSortingChange={(value) => {
          const values = value as HeaderProps[];
          if (values[0].id === 'url') return;
          if (watch('ordering') === values[0].id) {
            setValue('ordering', `-${values[0].id}`);
          } else {
            setValue('ordering', values[0].id);
          }
        }}
        sorting={[
          {
            id: 'created',
            desc: watch('ordering') === 'created'
          },
          {
            id: 'request_type',
            desc: watch('ordering') === 'request_type'
          },
          {
            id: 'correlation_id',
            desc: watch('ordering') === 'correlation_id'
          }
        ]}
        actionComponents={
          <div className="flex items-center gap-6">
            <div>
              <Controller
                name="search"
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    background="grey"
                    placeholder="Search"
                    name={name}
                    id={name}
                    type="text"
                    value={value}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      onChange(e);
                    }}
                    onClear={() => {
                      reset(defaultValues);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange } }) => (
                  <DatePicker
                    ISOWeek={hotelDetails?.starts_monday}
                    onDateChange={(date) => {
                      onChange(date);
                      setValue('page', 1);
                    }}
                    timezone={hotelDetails?.timezone}
                    closeOnSelect
                  />
                )}
              />
            </div>
            <div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button icon onClick={refetchLogs} className="group relative flex items-center">
                      {isRefetchLoading ? (
                        <Icon.LoadingCircle className="mr-0 h-6 w-6" />
                      ) : (
                        <Icon.Refresh />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="top" className="bg-white text-darkGrey">
                    Reload
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        }
      />
    </Page>
  );
};
