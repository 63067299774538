import { getEnvVar } from '@common/utils/env';
import { Button } from '@mantine/core';
import { PMS_NAME_SSO } from '@pages/Client/Account/Integrations/types/types';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useTranslation } from 'react-i18next';

const UpdateConnection = () => {
  const { hotelDetails } = useHotelDetails();
  const { t } = useTranslation();
  return (
    <>
      {hotelDetails?.pms_provider === PmsProvider.APALEO ? (
        <Button
          variant="transparent"
          c="gray.9"
          fw={500}
          onClick={() => {
            window.open(
              `${getEnvVar('VITE_API')}account/sso/${PMS_NAME_SSO.APALEO}/redirect?hotel_id=${hotelDetails.id}`,
              '_blank'
            );
          }}
        >
          {t('Update Connection')}
        </Button>
      ) : null}
    </>
  );
};

export default UpdateConnection;
