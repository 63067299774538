import { EditSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { create } from 'zustand';

export enum Tabs {
  Overview,
  EditPrices,
  AllRoomTypes,
  RateEvolution
}
interface PriceDrawerStore {
  drawerState: boolean;
  setDrawerState: (open: boolean) => void;

  adjustmentsData: Partial<EditSchema>;
  setAdjustmentsData: (data: PriceDrawerStore['adjustmentsData']) => void;

  defaultRoomId: number | undefined;
  setDefaultRoomId: (defaultRoomId: PriceDrawerStore['defaultRoomId']) => void;

  activeTab: Tabs;
  setActiveTab: (tab: PriceDrawerStore['activeTab'], shouldOpenPricingStrategy?: boolean) => void;
  shouldOpenPricingStrategy?: boolean;

  viewingDate?: Date;
  setViewingDate: (date: Date) => void;
}

export const usePriceDrawerStore = create<PriceDrawerStore>()((set) => ({
  drawerState: false,
  setDrawerState: (open) => set({ drawerState: open }),

  adjustmentsData: {},
  setAdjustmentsData: (data) => set({ adjustmentsData: data }),

  defaultRoomId: undefined,
  setDefaultRoomId: (defaultRoomId) => set({ defaultRoomId }),

  activeTab: Tabs.Overview,
  setActiveTab: (tab, shouldOpenPricingStrategy = false) =>
    set({ activeTab: tab, shouldOpenPricingStrategy }),

  setViewingDate(date) {
    set({
      viewingDate: date
    });
  }
}));
