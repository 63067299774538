import { Navigate, RouteObject } from 'react-router-dom';
import DashboardErrorTickets from '@pages/Admin/DashboardErrorTickets/pages/DashboardErrorTickets';
import DashboardDebugger from '@pages/Admin/DashboardErrorTickets/pages/DashboardDebugger';

export const dashboardErrorTicketsRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="dashboard-tickets" /> },
  {
    path: 'dashboard-tickets',
    element: <DashboardErrorTickets />
  },
  {
    path: 'dashboard-debugger',
    element: <DashboardDebugger />
  }
];
