export const DASHBOARD_TICKET_STATUS = [
  {
    label: 'Open',
    value: '1'
  },
  {
    label: 'Closed',
    value: '2'
  }
];
