import { updatePms } from '@common/api/hotel';
import { RegisterPmsPayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys, PmsConfigQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface UseUpdatePms {
  id: number | undefined;
  payload: RegisterPmsPayload;
}

export const useUpdatePms = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (data: UseUpdatePms) => {
      return updatePms(data.id, data.payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PmsConfigQueryKeys.GET_PMS_CONFIG_DETAIL] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_LIST] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', `Failed to update ${channelManagerOrPms} connection`);
    }
  });

  return {
    updatePms: mutate,
    isLoading,
    isSuccess
  };
};
