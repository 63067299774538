import { useTranslation } from 'react-i18next';
import { Modal } from '@common/components/molecules/Modal';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useAuthStore } from '@common/store/auth';
import { useDeletePartnerUser } from '@pages/Admin/PartnerUsers/hooks/useDeletePartnerUser';
import { SelectedPartnerUser } from '@pages/Admin/PartnerUsers/types/selectedPartnerUser';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  partner?: SelectedPartnerUser;
};

export const DeletePartnerUserModal = ({ partner, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { deletePartnerUser } = useDeletePartnerUser();
  const { token } = useAuthStore();
  const handleDeletePartner = () => {
    if (partner?.id && partner?.reseller) {
      const dataPayload = {
        member: partner.id,
        reseller: partner.reseller,
        token
      };
      deletePartnerUser(dataPayload);
    }
  };
  return (
    <Modal
      onClick={handleDeletePartner}
      open={isOpen}
      okText="Delete"
      onClose={onClose}
      size={'sm'}
      continueIntent="danger"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-error p-3">
                <Icon.Delete color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t('Delete Partner User: ') + ' '}
              {partner?.first_name + ' ' + partner?.last_name}
            </Typography>
            <Typography>
              {t('Are you sure you want to delete') + ' '}
              {partner?.first_name + ' ' + partner?.last_name}
            </Typography>
          </div>
        </>
      }
    />
  );
};
