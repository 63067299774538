import { HotelRoomEvent, PricingSettings } from '@common/api/hotel/types';
import { Icon } from '@common/components/foundations/icons';
import { useAuthStore } from '@common/store/auth';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { formatLocaleNumber } from '@common/utils/formatNumber';
import {
  Card,
  Divider,
  Group,
  Stack,
  Text,
  Title,
  Badge,
  ActionIcon,
  Tooltip,
  Button,
  Box,
  UnstyledButton
} from '@mantine/core';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { usePriceKpiContentHome } from '@pages/Client/Home/hooks/usePriceKpiContentHome';
import { RoomPriceDataTooltipTable } from '@pages/Client/PricingStrategy/SurgeProtection/components/RoomPriceDataTooltipTable';
import { IconEye, IconStar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EventCardProps = {
  date: string;
  events: HotelRoomEvent[];
  referenceRoom: PricingSettings['rooms']['reference'];
};

export const EventCard = ({ date, events: unsortedEvents, referenceRoom }: EventCardProps) => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { roomPrices } = useRoomPrices();
  const priceKpi = usePriceKpiContentHome(date);
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();

  const occupancy = roomPrices?.prices.data?.[date]?.property?.occupancy;

  const [isShowAll, setIsShowAll] = useState(false);

  const events = useMemo(() => {
    return unsortedEvents?.sort((a, b) => +b.local_rank - +a.local_rank) ?? [];
  }, [unsortedEvents]);

  const trimmedEvents = useMemo(() => {
    return events.slice(0, 2);
  }, [events]);

  const displayEvents = useMemo(() => {
    return isShowAll ? events : trimmedEvents;
  }, [isShowAll, events]);

  if (!referenceRoom?.id) return null;

  const referenceRoomPrice = roomPrices?.prices.data?.[date]?.[referenceRoom.id]?.price;

  return (
    <Card withBorder key={date} shadow="none">
      <Group wrap="nowrap" align="flex-start">
        <Stack gap="1" align="center" w="50px">
          <Title order={6}>{dayjs(date).format('ddd')}</Title>
          <Button
            component={Title}
            order={5}
            variant="subtle"
            size="xs"
            radius="xl"
            onClick={() => {
              setDrawerState(true);
              setViewingDate(dayjs(date).toDate());
            }}>
            {dayjs(date).format('DD MMM')}
          </Button>
        </Stack>
        <Divider orientation="vertical" />
        <Stack flex={1}>
          {displayEvents.map((event) => (
            <Group
              key={event.title}
              gap="xs"
              justify="space-between"
              align="flex-start"
              wrap="nowrap">
              <Group wrap="nowrap" align="flex-start">
                <Group gap="xs" wrap="nowrap">
                  <Tooltip label="Mark and Show in Calendar">
                    <ActionIcon>
                      <IconStar className="h-4 w-4" />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Hide from Events List">
                    <ActionIcon>
                      <IconEye className="h-4 w-4" />
                    </ActionIcon>
                  </Tooltip>
                </Group>
                <Stack gap={0}>
                  <Text c="dark.9" size="sm" truncate>
                    {event.title}
                  </Text>
                  {!isEmpty(event.predicted_event_spend_industries) ? (
                    <Stack gap="1">
                      <Group gap="4">
                        <Text size="xs" c="dimmed">
                          {t('Estimated Attendance')}:{' '}
                        </Text>
                        <Text size="xs" c="dark" fw={600}>
                          {formatLocaleNumber(event.attendances, user?.language)}
                        </Text>
                      </Group>
                      <Group gap="4">
                        <Text size="xs" c="dimmed">
                          {t('Predicted Accommodation Spend')}:{' '}
                        </Text>
                        <Text size="xs" c="dark" fw={600}>
                          {CurrencyFormatter.format(
                            event.predicted_event_spend_industries?.accommodation
                          )}
                        </Text>
                      </Group>
                    </Stack>
                  ) : null}
                </Stack>
              </Group>
              <Badge radius="xl" color="gold">
                {event.category}
              </Badge>
            </Group>
          ))}
          <Box>
            {events.length > 2 ? (
              <Button
                leftSection={isShowAll ? <b>—</b> : <Icon.Add className="h-4 w-4" />}
                variant="subtle"
                size="xs"
                onClick={() => setIsShowAll(!isShowAll)}>
                {isShowAll ? 'Show less events' : `Show all events (${events.length})`}
              </Button>
            ) : null}
          </Box>
        </Stack>
        <Divider orientation="vertical" />
        <UnstyledButton
          onClick={() => {
            setDrawerState(true);
            setViewingDate(dayjs(date).toDate());
          }}>
          <Stack gap="xs">
            <Group gap="xs" justify="space-between" wrap="nowrap">
              <Text size="sm">{t('Reference Room Price')}</Text>
              <Group gap="2">
                <Text fw={500} size="sm" c="dark">
                  {CurrencyFormatter.format(referenceRoomPrice)}
                </Text>
                <RoomPriceDataTooltipTable date={date} />
              </Group>
            </Group>

            <Group gap="xs" justify="space-between" wrap="nowrap">
              <Text size="sm">{t('Occupancy')}</Text>
              <Text fw={500} size="sm" c="dark">
                {occupancy}%
              </Text>
            </Group>

            <Group gap="xs" justify="space-between" wrap="nowrap">
              <Text size="sm">{t('Market Factor')}</Text>
              <Text fw={500} size="sm" c="dark">
                {priceKpi?.breakdownPrice?.find((item) => item.id === 'market-factor')?.percentage}
              </Text>
            </Group>

            <Group gap="xs" justify="space-between" wrap="nowrap">
              <Text size="sm">{t('Your Adjustments')}</Text>
              <Text fw={500} size="sm" c="dark">
                {
                  priceKpi?.breakdownPrice?.find((item) => item.id === 'your-adjustments')
                    ?.percentage
                }
              </Text>
            </Group>
          </Stack>
        </UnstyledButton>
      </Group>
    </Card>
  );
};
