import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';

export const NoAccess = () => {
  return (
    <Page header={<Header title="We're almost there" />}>
      <Card backgroundColor="white" className="p-20">
        <div className="mx-auto flex max-w-md flex-col items-center justify-center gap-10">
          <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
            We&apos;re almost there
          </Typography>
          <Typography element="p" variant="paragraph-1" className="text-center" color="darkGrey">
            We&apos;re just putting the finishing touches to your account and can&apos;t wait to
            show it to you on the setup call. Please use the chat if you have any questions in the
            mean time.
          </Typography>

          <div className="flex items-center gap-x-2">
            <div className="h-auto w-72">
              <img src="/genie-working.gif" />
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
};
