import { SelectDropdownInput } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { MEDIAN_OPTIONS } from '@common/constants/pricing';
import { useTranslation } from 'react-i18next';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { MedianOptions } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export const medianOptions: MedianOptions[] = [...MEDIAN_OPTIONS].map((median) => ({
  label: median.label,
  value: median.value
}));

export function MedianBookingAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.MEDIAN;
  const { hotelDetails } = useHotelDetails();

  return (
    <div>
      <Typography variant="paragraph-1" className="text-paragraph-1-medium" color="darkGrey">
        Median Booking Window (All {hotelDetails?.room_apartment_space_name} Types)
      </Typography>
      <div className="mt-2">
        <SelectDropdownInput<MedianOptions>
          background="grey"
          fullWidth
          options={medianOptions}
          name={formName}
          fallbackLabel={t('Select Median Booking Window')}
        />
      </div>
    </div>
  );
}
