import { ModalFooter } from '@common/mantine/components/modal-footer';
import { useHotelStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, NumberInput, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEffect } from 'react';

import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { pmsHasInvTypeCode } from '@common/constants/pmsList';
import { useUpdatePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';

interface SelectedRoomType {
  id: string;
  name: string;
  max_occupancy: string;
  min_occupancy: string;
  room_id: string;
  extra: string;
  default_occupancy: number | undefined;
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  selectedRoomType: SelectedRoomType;
};

const baseSchema = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  room_id: z.string().trim().min(1, { message: 'Required' })
});

const siteMinderNewSchema = z.object({
  extra: z.string().optional(),
  ...baseSchema.shape
});

const guestlineSchema = z.object({
  default_occupancy: z.coerce
    .string()
    .or(z.number())
    .refine((val) => Number(val) > 0, {
      message: 'Value should be greater than 0'
    })
    .optional(),
  ...baseSchema.shape
});

const schema = z.object({
  max_occupancy: z.coerce
    .string()
    .or(z.number())
    .refine((val) => Number(val) > 0, {
      message: 'Value should be greater than 0'
    })
    .optional(),
  min_occupancy: z.coerce
    .string()
    .or(z.number())
    .refine((val) => Number(val) > 0, {
      message: 'Value should be greater than 0'
    })
    .optional(),
  ...baseSchema.shape
});

const mergedSchema = baseSchema.merge(siteMinderNewSchema).merge(schema).merge(guestlineSchema);

type FormValues = z.infer<typeof mergedSchema>;

export const UpdateRoomTypeModal = ({ isOpen, onClose, selectedRoomType }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { hotelAuthToken } = useHotelStore();
  const { updatePmsRoomType, isLoading } = useUpdatePmsRoomType();
  const { hideMinMaxOccupancyOnRoomTypeTable } = usePmsProvider();

  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;
  const roomName = pmsHasInvTypeCode.includes(pmsProviderId)
    ? t('InvTypeCode')
    : t(`${roomApartmentSpaceName} Id`);
  const asteriskSymbol = pmsProviderId === PmsProvider.CASABLANCA;

  const getSchema = (pmsProviderId: number) => {
    switch (pmsProviderId) {
      case PmsProvider.SITEMINDER_NEW:
        return siteMinderNewSchema;
      case PmsProvider.GUESTLINE:
        return guestlineSchema;
      default:
        return schema.refine(
          (data) => {
            if (data.min_occupancy && data.max_occupancy) {
              return Number(data.min_occupancy) <= Number(data.max_occupancy);
            }
            return true;
          },
          {
            message: 'Cannot higher than max_occupancy',
            path: ['min_occupancy']
          }
        );
    }
  };

  const { handleSubmit, control, setValue, reset } = useForm<FormValues>({
    resolver: zodResolver(getSchema(pmsProviderId))
  });

  useEffect(() => {
    if (selectedRoomType?.id) {
      setValue('name', selectedRoomType.name);
      setValue('max_occupancy', selectedRoomType.max_occupancy);
      setValue('min_occupancy', selectedRoomType.min_occupancy);
      setValue('room_id', selectedRoomType.room_id);
      setValue('extra', selectedRoomType.extra);
      setValue('default_occupancy', selectedRoomType.default_occupancy);
    }
  }, [selectedRoomType]);

  const onSubmit = async (data: FormValues) => {
    if (!selectedRoomType?.id) {
      return;
    }

    const roomTypeId = Number(selectedRoomType.id);
    const basePayload = {
      name: data.name,
      room_id: data.room_id,
      token: hotelAuthToken
    };

    let payload;
    switch (pmsProviderId) {
      case PmsProvider.SITEMINDER_NEW:
        payload = {
          ...basePayload,
          extra: data.extra
        };
        break;

      case PmsProvider.GUESTLINE:
        payload = {
          ...basePayload,
          isOccupancyChanged: true,
          default_occupancy: Number(data.default_occupancy)
        };
        break;

      default:
        payload = {
          ...basePayload,
          isOccupancyChanged: true,
          max_occupancy: Number(data.max_occupancy),
          min_occupancy: Number(data.min_occupancy)
        };
    }
    await updatePmsRoomType([roomTypeId, payload]);
    onClose();
    reset();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={t(`Edit ${selectedRoomType?.name} Type`)}
      size="auto"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack px="md" pb="lg" gap="md">
          <SimpleGrid cols={pmsProviderId === PmsProvider.SITEMINDER_NEW ? 1 : 2}>
            {pmsProviderId === PmsProvider.SITEMINDER_NEW ? (
              <Controller
                name="extra"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextInput
                    placeholder={`${t('Please enter type code')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Rate Type Code')}
                    value={value}
                    onChange={onChange}
                    withAsterisk
                  />
                )}
              />
            ) : (
              <>
                <Controller
                  name="room_id"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextInput
                      placeholder={t(`Please enter ${roomName}`) as string}
                      error={error?.message}
                      variant="filled"
                      label={t(`${roomName}`)}
                      value={value}
                      onChange={onChange}
                      withAsterisk
                    />
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <TextInput
                      placeholder={`${t('Please enter name')}`}
                      error={error?.message}
                      variant="filled"
                      label={t('Room Type Name')}
                      value={value}
                      onChange={onChange}
                      withAsterisk
                    />
                  )}
                />
              </>
            )}
          </SimpleGrid>
          {hideMinMaxOccupancyOnRoomTypeTable() ||
          pmsProviderId === PmsProvider.SITEMINDER_NEW ? null : (
            <SimpleGrid cols={2}>
              <Controller
                name="min_occupancy"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    placeholder={`${t('Please enter min occupancy')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Min Occupancy')}
                    value={value}
                    onChange={onChange}
                    withAsterisk={asteriskSymbol}
                    hideControls
                  />
                )}
              />
              <Controller
                name="max_occupancy"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    placeholder={`${t('Please enter max occupancy')}`}
                    error={error?.message}
                    variant="filled"
                    label={t('Max Occupancy')}
                    value={value}
                    onChange={onChange}
                    withAsterisk={asteriskSymbol}
                    hideControls
                  />
                )}
              />
            </SimpleGrid>
          )}
          {pmsProviderId === PmsProvider.GUESTLINE ? (
            <Controller
              name="default_occupancy"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberInput
                  placeholder={`${t('Please enter default occupancy')}`}
                  error={error?.message}
                  variant="filled"
                  label={t('Default Occupancy')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                  hideControls
                />
              )}
            />
          ) : null}
        </Stack>
        <ModalFooter>
          <Button onClick={handleClose} variant="subtle">
            {t('Cancel')}
          </Button>
          <Button type="submit" loading={isLoading}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
