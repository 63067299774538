export enum PusherEventNames {
  WebhookMessage = 'webhook.message',
  UploadPriceMessage = 'uploadprice.message',
  InventoryMessage = 'inventory.message',
  RunPricingMessage = 'runpricing.message',
  ConnectionUpdateMessage = 'connection_update.message',
  SabeepropertyMessage = 'sabeeproperty.message',
  CompetitorMessage = 'competitor.message',
  CalculateAverageMessage = 'calculate_average.message',
  DownloadHotelListMessage = 'downloadhotellist.message',
  InitPmsSyncMessage = 'init_pms_sync.message',
  InitPmsConfigMessage = 'init_pms_config.message',
  InitPmsRoomsMessage = 'initialize_room_units.message',
  RetrieveBlockedRooms = 'retrieve_blocked_rooms.message'
}

export type BasePusherEventResponse = {
  error: boolean;
  message?: string | { detail: string };
};

export type PusherEventResponse = {
  // Note: implement the accurate type of events if needed.
  UploadPriceMessage: BasePusherEventResponse;
  RunPricingMessage: BasePusherEventResponse;
};
