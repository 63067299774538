import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useDocumentTitle } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const DashboardErrorTicketsLayout = () => {
  const { t } = useTranslation();
  useDocumentTitle('Dashboard Error Tickets');
  const subMenuLink = [
    {
      id: 'dashboard-tickets',
      label: t('Dashboard Tickets'),
      to: 'dashboard-tickets'
    },
    {
      id: 'dashboard-debugger',
      label: t('Dashboard Debugger'),
      to: 'dashboard-debugger'
    }
  ];
  return (
    <Page header={<Header title="Dashboard Error Ticket List" tabs={subMenuLink} />}>
      <Outlet />
    </Page>
  );
};
