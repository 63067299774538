import { addResetPassword } from '@common/api/account';
import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useResetPassword = () => {
  const { addNotification } = useNotificationsStore();
  const { token } = useAuthStore();

  const {
    mutate,
    isPending: isLoading,
    isError,
    error
  } = useMutation({
    mutationFn: (data: { token: string | null | undefined; new_password: string }) => {
      setAuthToken(token);
      return addResetPassword(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully reset password');
    },
    onError: () => {
      addNotification('fail', 'Failed to reset password');
    }
  });

  return {
    resetPassword: mutate,
    isLoading,
    isError,
    error
  };
};
