import { getSubscriptionInformation } from '@common/api/hotel';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const usePaymentInformation = (isEnabled?: boolean) => {
  const { hotelDetails } = useHotelDetails(Boolean(isEnabled));
  const query = useQuery({
    queryKey: [PricingQueryKeys.GET_PAYMENT_INFORMATION],
    queryFn: getSubscriptionInformation,
    enabled: hotelDetails?.state === 1
  });

  const paymentInfo = useMemo(() => {
    return query.data?.data;
  }, [query]);

  return {
    query,
    paymentInfo
  };
};
