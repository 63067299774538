import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface PropertyHeaderProps {
  onClick?: (data: any) => void;
  disabled?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
}

export const ProfileHeader = ({ onClick, disabled, isLoading, isSuccess }: PropertyHeaderProps) => {
  const { t } = useTranslation();

  const tabs = [
    { name: t('Language'), href: 'language' },
    { name: t('Personal Information'), href: 'personal-information' },
    { name: t('Password'), href: 'password' }
  ];

  return (
    <div className="w-full">
      <div className="pb-5">
        <div className="flex items-center justify-between">
          <div>
            <Typography element="h1" variant="h3" color="darkGrey">
              {t('My Profile')}
            </Typography>
            <div className="mt-3">
              <Typography element="p" variant="paragraph-3" color="copyTextGrey">
                {t('Manage your profile preferences.')}
              </Typography>
            </div>
          </div>
          <div>
            <Button
              size="large"
              isLoading={isLoading}
              isSuccess={isSuccess}
              disabled={disabled}
              intent="primary"
              onClick={onClick}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex h-12 gap-4 border-b-2 border-darkGrey border-opacity-10">
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            className={({ isActive }) =>
              isActive
                ? 'border-b-[3px] border-indigo py-1 font-medium text-indigo'
                : 'inline-block py-1 text-grey'
            }
            to={tab.href}>
            {tab.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
