import { PricingSettings } from '@common/api/hotel/types';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { map, isNaN } from 'lodash-es';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { MinMax } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minMaxSchema';

/**
 * Rules for setting and deleting min_price and max_price keys:
 *
 * - Delete Max Price Key:
 *   - Condition:
 *     - If isBulkEdit is false, then maxPrice should be 0, null, NaN, or equal to defaultMaxPrice
 *     - Or, if isBulkEdit is true, then maxPrice should be equal to defaultMaxPrice
 *     - Or, if isPerRoomType (Min/Max Per Room Type) is false, and isReferenceRoom is false
 *   - Action:
 *     - Delete the max_price key (as specified by the `deleteMaxPriceKey` event)
 *
 * - Delete Min Price Key:
 *   - Condition:
 *     - If isBulkEdit is false, then minPrice should be 0, null, NaN, or equal to defaultMinPrice (rounded)
 *     - Or, if isBulkEdit is true, then minPrice should be equal to defaultMinPrice (rounded)
 *     - Or, if isPerRoomType (Min/Max Per Room Type) is false, and isReferenceRoom is false
 *     - Additionally, if isPerRoomType (Min/Max Per Room Type) is true, then minPrice should be set as per the formDataById
 *     - Or, if isPerRoomType (Min/Max Per Room Type) is false, and isReferenceRoom is true, then minPrice should be set as per the formDataById
 *   - Action:
 *     - Delete the min_price key (as specified by the `deleteMinPriceKey` event)
 *
 * - Set Max Price Key:
 *   - Condition:
 *     - maxPrice should not be equal (rounded comparison) to defaultMaxPrice, not null, and not NaN
 *   - Action:
 *     - Set the max_price key (as specified by the `setMaxPriceKey` event)
 *
 * - Set Min Price Key:
 *   - Condition:
 *     - minPrice should not be equal (rounded comparison) to defaultMinPrice, not null, and not NaN
 *     - Additionally, if isPerRoomType (Min/Max Per Room Type) is true, then minPrice should be set as per the formDataById
 *     - Or, if isPerRoomType (Min/Max Per Room Type) is false, and isReferenceRoom is true, then minPrice should be set as per the formDataById
 *   - Action:
 *     - Set the min_price key (as specified by the `setMinPriceKey` event)
 *
 * Note: The "roundedEqual" and "roundedNotEqual" operators are used for comparing minPrice and maxPrice to their default values,
 * allowing for rounding in the comparison.
 */

const actions = {
  setMaxPriceKey: 'setMaxPriceKey',
  deleteMaxPriceKey: 'deleteMaxPriceKey',
  setMinPriceKey: 'setMinPriceKey',
  deleteMinPriceKey: 'deleteMinPriceKey'
};

const deleteMaxPriceKey = new Rule({
  conditions: {
    any: [
      {
        all: [
          { fact: 'isBulkEdit', operator: 'equal', value: false },
          {
            any: [
              { fact: 'maxPrice', operator: 'equal', value: 0 },
              { fact: 'maxPrice', operator: 'equal', value: null },
              { fact: 'maxPrice', operator: 'isNaNOperator', value: true },
              { fact: 'maxPrice', operator: 'equal', value: { fact: 'defaultMaxPrice' } }
            ]
          }
        ]
      },
      {
        all: [
          { fact: 'isBulkEdit', operator: 'equal', value: true },
          { fact: 'maxPrice', operator: 'equal', value: { fact: 'defaultMaxPrice' } }
        ]
      },
      {
        all: [
          { fact: 'isPerRoomType', operator: 'equal', value: false },
          { fact: 'isReferenceRoom', operator: 'equal', value: false }
        ]
      }
    ]
  },
  event: { type: actions.deleteMaxPriceKey }
});

const deleteMinPriceKey = new Rule({
  conditions: {
    any: [
      {
        all: [
          { fact: 'isBulkEdit', operator: 'equal', value: false },
          {
            any: [
              { fact: 'minPrice', operator: 'equal', value: 0 },
              { fact: 'minPrice', operator: 'equal', value: null },
              { fact: 'minPrice', operator: 'isNaNOperator', value: true },
              { fact: 'minPrice', operator: 'roundedEqual', value: { fact: 'defaultMinPrice' } }
            ]
          }
        ]
      },
      {
        all: [
          { fact: 'isBulkEdit', operator: 'equal', value: true },
          { fact: 'minPrice', operator: 'roundedEqual', value: { fact: 'defaultMinPrice' } }
        ]
      },
      {
        all: [
          { fact: 'isPerRoomType', operator: 'equal', value: false },
          { fact: 'isReferenceRoom', operator: 'equal', value: false }
        ]
      }
    ]
  },
  event: { type: actions.deleteMinPriceKey }
});

const setMaxPriceKey = new Rule({
  conditions: {
    all: [
      { fact: 'maxPrice', operator: 'roundedNotEqual', value: { fact: 'defaultMaxPrice' } },
      { fact: 'maxPrice', operator: 'notEqual', value: null },
      { fact: 'maxPrice', operator: 'isNotNaNOperator', value: true },
      {
        any: [
          { fact: 'isPerRoomType', operator: 'equal', value: true },
          {
            all: [
              { fact: 'isPerRoomType', operator: 'equal', value: false },
              { fact: 'isReferenceRoom', operator: 'equal', value: true }
            ]
          }
        ]
      }
    ]
  },
  event: { type: actions.setMaxPriceKey }
});

const setMinPriceKey = new Rule({
  conditions: {
    all: [
      { fact: 'minPrice', operator: 'roundedNotEqual', value: { fact: 'defaultMinPrice' } },
      { fact: 'minPrice', operator: 'notEqual', value: null },
      { fact: 'minPrice', operator: 'isNotNaNOperator', value: true },
      {
        any: [
          { fact: 'isPerRoomType', operator: 'equal', value: true },
          {
            all: [
              { fact: 'isPerRoomType', operator: 'equal', value: false },
              { fact: 'isReferenceRoom', operator: 'equal', value: true }
            ]
          }
        ]
      }
    ]
  },
  event: { type: actions.setMinPriceKey }
});

export async function transformMinMax(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Record<string, MinMax>,
  date: string
) {
  const engine = new Engine();
  engine.addOperator('isNaNOperator', (factValue) => isNaN(factValue));
  engine.addOperator('isNotNaNOperator', (factValue) => !isNaN(factValue));
  engine.addOperator('roundedEqual', (f, j) => Math.round(Number(j)) === f);
  engine.addOperator('roundedNotEqual', (f, j) => Math.round(Number(j)) !== f);
  engine.addRule(setMaxPriceKey);
  engine.addRule(deleteMaxPriceKey);
  engine.addRule(setMinPriceKey);
  engine.addRule(deleteMinPriceKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('item') as Promise<MinMax>).then((item: MinMax) => {
      if (event.type === actions.setMinPriceKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][item.id])
          currentPricingDraft.dates[date][item.id] = {};
        currentPricingDraft.dates[date][item.id][ADJUSTMENT_DB.MIN_PRICE_KEY] = formDataById[
          item.id
        ].minPrice as number;
      }
      if (event.type === actions.setMaxPriceKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][item.id])
          currentPricingDraft.dates[date][item.id] = {};
        currentPricingDraft.dates[date][item.id][ADJUSTMENT_DB.MAX_PRICE_KEY] = formDataById[
          item.id
        ].maxPrice as number;
      }
      if (event.type === actions.deleteMinPriceKey) {
        delete currentPricingDraft.dates[date]?.[item.id]?.[ADJUSTMENT_DB.MIN_PRICE_KEY];
      }
      if (event.type === actions.deleteMaxPriceKey) {
        delete currentPricingDraft.dates[date]?.[item.id]?.[ADJUSTMENT_DB.MAX_PRICE_KEY];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (item: MinMax) => {
      await engine.run({ item, ...item });
    })
  );

  return;
}
