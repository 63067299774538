import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useAddFreeTrial } from '@pages/Client/Account/Subscription/hooks/usePayment';

const schema = z
  .object({
    free_trial_end: z.date()
  })
  .refine(
    (data) => {
      const currentDate = new Date();
      const freeTrialEndDate = data.free_trial_end || currentDate;
      return freeTrialEndDate >= currentDate;
    },
    {
      message: 'Free trial end date cannot be in the past',
      path: ['free_trial_end']
    }
  );

export const FreeTrialForm = () => {
  const { t } = useTranslation();
  const { hotelDetails: hotel } = useHotelDetails();
  const { mutate: addFreeTrialTime, isLoading: addFreeTrialLoading } = useAddFreeTrial(true);

  const { control, handleSubmit, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      free_trial_end: dayjs(hotel?.free_trial_end).toDate()
    }
  });

  const onSubmit = () => {
    addFreeTrialTime({
      free_trial_end: dayjs(watch('free_trial_end')).tz().format('YYYY-MM-DD')
    });
  };

  return (
    <>
      <div className="flex items-end gap-4">
        <Controller
          control={control}
          name="free_trial_end"
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <div className="flex flex-col">
              <DatePicker
                ISOWeek={hotel?.starts_monday}
                selectedDate={value || dayjs().add(7, 'day').toDate()}
                onDateChange={(date) => {
                  onChange(date);
                }}
                timezone={hotel?.timezone}
                closeOnSelect
              />
              <div>
                {errors && (
                  <div className="flex items-center gap-2 text-error">
                    {errors.free_trial_end ? <Icon.WarningOutline className="w-4" /> : ''}
                    <Typography element="span" color="error" variant="meta-2">
                      {errors.free_trial_end ? errors?.free_trial_end?.message?.toString() : ''}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
        />

        <Button size="large" onClick={handleSubmit(onSubmit)} isLoading={addFreeTrialLoading}>
          {t('Save')}
        </Button>
      </div>
    </>
  );
};
