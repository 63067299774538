import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { MarketIntelligenceQueryKeys } from '@common/types/query-keys';
import { getHotelCompetitorList } from '@common/api/hotel';
import { HotelCompetitorListResponse } from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';

export const useHotelCompetitor = () => {
  const { hotelAuthToken } = useHotelStore();
  const query = useQuery({
    queryKey: [MarketIntelligenceQueryKeys.GET_HOTEL_COMPETITOR],
    queryFn: () => getHotelCompetitorList(),
    retry: false,
    enabled: !!hotelAuthToken
  });

  const hotelCompetitor = useMemo(() => {
    return (query.data?.data as HotelCompetitorListResponse) || [];
  }, [query.data]);

  return {
    query,
    hotelCompetitor
  };
};
