import { getBookingScrapeList } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { ScrapeQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useBookingScrapeList = (page?: number) => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, error, isError } = useQuery({
    queryKey: [ScrapeQueryKeys.GET_HOTEL_SCRAPE_LIST, page],
    queryFn: () => {
      return getBookingScrapeList(page);
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get hotel booking scrape list');

  return {
    bookingScrapeList: data?.data,
    isLoading,
    error
  };
};
