import { AppPlanName, HotelSubscriptionConfig, SubscriptionLabel } from '@common/api/hotel/types';
import { map, startCase } from 'lodash-es';

export const subscriptionStatus: HotelSubscriptionConfig = {
  0: { label: SubscriptionLabel.FREE_TRIAL, color: 'indigo', border: 'border-gold' },
  1: { label: SubscriptionLabel.SUBSCRIBED, color: 'indigo', border: 'border-indigo' },
  2: { label: SubscriptionLabel.CANCELLED, color: 'uiRed', border: 'border-uiRed' },
  3: { label: SubscriptionLabel.FREE_TRIAL_EXPIRED, color: 'uiRed', border: 'border-uiRed' },
  4: { label: SubscriptionLabel.SUSPENDED, color: 'uiRed', border: 'border-uiRed' },
  5: { label: SubscriptionLabel.NOT_ACTIVE, color: 'uiRed', border: 'border-uiRed' },
  6: { label: SubscriptionLabel.SUBSCRIBED_P, color: 'uiGreen', border: 'border-uiGreen' },
  7: { label: SubscriptionLabel.UNSUBSCRIBED_P, color: 'uiRed', border: 'border-uiRed' },
  8: { label: SubscriptionLabel.DEMO_HOTEL, color: 'darkGrey', border: 'border-darkGrey' },
  9: { label: SubscriptionLabel.SUBSCRIPTION_PAUSED, color: 'indigo', border: 'border-indigo' },
  10: { label: SubscriptionLabel.SANDBOX, color: 'orange', border: 'border-orange' },
  11: { label: SubscriptionLabel.NON_RENEWING, color: 'indigo', border: 'border-indigo' }
};

export const subscriptionStatusSelect = map(subscriptionStatus, (value, key) => ({
  value: String(key),
  label: startCase(value.label)
}));

export const planLookup: Record<number, string> = {
  2: AppPlanName.STARTER,
  3: AppPlanName.ADVANCED,
  4: AppPlanName.PROFESSIONAL,
  5: AppPlanName.SMALL_BUSINESS,
  6: AppPlanName.PRO_LITE
};

export const planSelectLookup = map(planLookup, (value, key) => ({
  value: String(key),
  label: startCase(value)
}));

export const scrapeScheduleConfig: { label: string; value: number }[] = [
  { label: 'Done', value: 3 },
  { label: 'In Progress', value: 2 },
  { label: 'Scheduled', value: 1 }
];

export const errorTicketsHeaders: { [key: string]: string } = {
  '1': 'Support Critical Error Tickets',
  '2': 'Support Other Error Tickets',
  '3': 'Dev Critical Error Tickets',
  '4': 'Dashboard Critical Error Tickets',
  '5': 'Dashboard Other Error Tickets'
};

export const ticketStatusConfig: { label: string; value: number }[] = [
  { label: 'Open', value: 1 },
  { label: 'Closed', value: 2 },
  { label: 'Blocked', value: 3 }
];

export const dashTicketStatusConfig: { label: string; value: number }[] = [
  { ...ticketStatusConfig[0] },
  { ...ticketStatusConfig[1] }
];

export const dashboardErrorTags: { label: string; value: string }[] = [
  { label: 'Process All Reservation', value: 'Process All Reservation' },
  { label: 'Reservation', value: 'Reservation' },
  { label: 'Update Reservation KPI', value: 'Update Reservation KPI' },
  {
    label: 'Dashboard: No Reservations for Mapped Room Types',
    value: 'Dashboard: No Reservations for Mapped Room Types'
  },
  { label: 'Transformation failed', value: 'Transformation failed' },
  { label: 'Dashboard cache calculation failed', value: 'Dashboard cache calculation failed' },
  { label: 'Analytics cache calculation failed', value: 'Analytics cache calculation failed' },
  {
    label: 'Segmentation cache calculation failed',
    value: 'Segmentation cache calculation failed'
  },
  { label: 'Forecasting cache calculation failed', value: 'Forecasting cache calculation failed' }
];

export const languagesConfig = [
  { value: 'en-US', label: 'American English' },
  { value: 'en-GB', label: 'British English' },
  { value: 'de', label: 'German' },
  { value: 'fr-FR', label: 'French' },
  { value: 'es-ES', label: 'Spanish' },
  { value: 'it-IT', label: 'Italian' }
];

export const uploadOptionsConfig = [
  { value: 1, label: '1 Week', comparisonValue: 7 },
  { value: 2, label: '2 Weeks', comparisonValue: 14 },
  { value: 3, label: '1 Month', comparisonValue: 30 },
  { value: 4, label: '3 Months', comparisonValue: 90 },
  { value: 5, label: '6 Months', comparisonValue: 180 },
  { value: 6, label: '9 Months', comparisonValue: 270 },
  { value: 7, label: '12 Months', comparisonValue: 360 },
  { value: 8, label: '18 Months', comparisonValue: 540 }
];

export const skipUploadOptionsConfig = [
  { value: 1, label: '1 Week', comparisonValue: 7 },
  { value: 2, label: '2 Weeks', comparisonValue: 14 },
  { value: 3, label: '1 Month', comparisonValue: 30 },
  { value: 4, label: '2 Months', comparisonValue: 60 },
  { value: 5, label: '3 Months', comparisonValue: 90 },
  { value: 0, label: 'Upload All', comparisonValue: 0 }
];
