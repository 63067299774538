import { useState } from 'react';
import { getSuccessReportData } from '@common/api/hotel';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isDevelopment } from '@common/utils/env';
import { useViewStore } from '@common/store/view';
import { useSubscription } from '@pages/Client/hooks/useSubscription';
import { UtilQueryKeys } from '@common/types/query-keys';

interface ValueReportType {
  revenue: { after_rpg_revenue: number; stly_rpg_revenue: number; before_rpg_revenue: number };
  adr: { after_rpg_adr: number; stly_rpg_adr: number; before_rpg_adr: number };
  nights_sold: {
    after_rpg_nights_sold: number;
    stly_rpg_nights_sold: number;
    before_rpg_nights_sold: number;
  };
  median_lead_time: {
    after_rpg_median_lead_time: number;
    stly_rpg_median_lead_time: number;
    before_rpg_median_lead_time: number;
  };
  auto_upload_active_since_num_days: number;
  activation_date_str: string;
  room_types_count: number;
  monthly_subscription_value: number;
  created_on: string;
}

export const useValueReport = ({
  disableSuccessNotification
}: {
  disableSuccessNotification?: boolean;
} = {}) => {
  const { addNotification } = useNotificationsStore();
  const { features } = useFeaturesStore();
  const { isAutoUploadOn } = useHotelDetails();
  const queryClient = useQueryClient();
  const { isAdmin } = useViewStore();
  const { isFreeTrial, subscriptionPriceFreeTrial } = useSubscription();

  const [isForceRefetchSuccess, setIsForceRefetchSuccess] = useState(false);

  const { data, isLoading, isError, error, isFetching } = useQuery({
    queryKey: [UtilQueryKeys.VALUE_REPORT],
    queryFn: () => getSuccessReportData(),
    enabled:
      (isDevelopment() && isAdmin && features?.includes(Feature.LimitedDashboard)) ||
      (features?.includes(Feature.LimitedDashboard) && isAutoUploadOn && isAdmin)
  });

  if (isError) {
    addNotification('fail', error?.message || 'Failed to get value report');
  }

  const { mutate: forceRefetch, isPending: forceRefetchLoading } = useMutation({
    mutationFn: () => getSuccessReportData(true),
    onSuccess: () => {
      setIsForceRefetchSuccess(true);
      setTimeout(() => setIsForceRefetchSuccess(false), 2000);
      queryClient.invalidateQueries({ queryKey: [UtilQueryKeys.VALUE_REPORT] });
      !disableSuccessNotification &&
        addNotification('success', 'Value report regenerated successfully');
    },
    onError: (error: any) => {
      addNotification(
        'fail',
        error?.response?.data?.data?.message || 'Failed to regenerate value report'
      );
    }
  });

  const getFormattedData = (
    valueReport: ValueReportType,
    isFreeTrial: boolean,
    subscriptionPriceFreeTrial: number | undefined
  ) => {
    const data = {
      after_revenue: valueReport?.revenue?.after_rpg_revenue,
      after_adr: valueReport?.adr?.after_rpg_adr,
      after_nights_sold: valueReport?.nights_sold?.after_rpg_nights_sold,
      after_median_lead_time: valueReport?.median_lead_time?.after_rpg_median_lead_time,
      stly_revenue: valueReport?.revenue?.stly_rpg_revenue,
      stly_adr: valueReport?.adr?.stly_rpg_adr,
      stly_nights_sold: valueReport?.nights_sold?.stly_rpg_nights_sold,
      stly_median_lead_time: valueReport?.median_lead_time?.stly_rpg_median_lead_time,
      before_revenue: valueReport?.revenue?.before_rpg_revenue,
      before_adr: valueReport?.adr?.before_rpg_adr,
      before_nights_sold: valueReport?.nights_sold?.before_rpg_nights_sold,
      before_median_lead_time: valueReport?.median_lead_time?.before_rpg_median_lead_time,
      auto_upload_active_since_num_days: valueReport?.auto_upload_active_since_num_days,
      activation_date_str: valueReport?.activation_date_str,
      room_types_count: valueReport?.room_types_count,
      monthly_subscription_value: isFreeTrial
        ? subscriptionPriceFreeTrial
        : valueReport?.monthly_subscription_value,
      created_on: valueReport?.created_on
    };
    const calculations = {
      average_increase:
        (2 * data.after_revenue - data.stly_revenue - data.before_revenue) /
        (2 * data.auto_upload_active_since_num_days),
      monthly_extra_revenue:
        (2 * data.after_revenue - data.stly_revenue - data.before_revenue) /
        ((2 * data.auto_upload_active_since_num_days) / 30.5),
      optimised_prices_count: data.room_types_count * 12 * data.auto_upload_active_since_num_days
    };
    return {
      ...data,
      ...calculations
    };
  };

  return {
    valueReport: data?.data?.data,
    data: getFormattedData(data?.data?.data, isFreeTrial, subscriptionPriceFreeTrial),
    isLoading: isLoading || isFetching,
    error,
    forceRefetch,
    forceRefetchLoading,
    forceRefetchSuccess: isForceRefetchSuccess
  };
};
