import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Icon } from '@common/components/foundations/icons';
import styles from '@common/components/molecules/TabsBar/tabsBar.module.css';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { NativeSelect } from '@mantine/core';

interface TabOptions {
  id?: string;
  label: string;
  value: number;
  icon?: React.ReactNode;
  disabled?: boolean;
  tooltip?: string | undefined | null;
  renderAdjacent?: () => React.ReactElement | null;
  userflowId?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface TabBarProps {
  type?: 'linear';
  small?: boolean;
  onDark?: boolean;
  withIcons?: boolean;
  options: TabOptions[];
  activeTab: number;
  fitsContent?: boolean;
  onTabClick: (value: number) => void;
  fitBorder?: boolean;
  action?: () => void;
  actionLabel?: string;
  actionLabelTooltipIcon?: ReactNode;
  renderAction?: () => JSX.Element;
  disabled?: boolean;
  renderActionAdjacent?: () => React.ReactElement | null;
  onActionMouseEnter?: () => void;
  onActionMouseLeave?: () => void;
}

const DefaultTabBar = ({
  fitsContent = false,
  activeTab,
  options,
  onTabClick,
  fitBorder = true,
  withIcons = false,
  action,
  actionLabel,
  actionLabelTooltipIcon,
  renderAction,
  disabled = false,
  renderActionAdjacent,
  onActionMouseEnter,
  onActionMouseLeave
}: TabBarProps) => {
  return (
    <div>
      <div className="flex items-center justify-between gap-1 md:hidden">
        <div className="w-full">
          <NativeSelect value={activeTab} onChange={(e) => onTabClick(+e.target.value)}>
            {options.map((option) => (
              <option key={option?.label} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </NativeSelect>
        </div>
        {renderAction ? renderAction() : null}
      </div>
      <div
        className={classNames(
          styles.wrapper,
          {
            'w-max': fitsContent
          },
          'hidden md:block'
        )}>
        <nav
          className={classNames(
            styles.tabs,
            fitBorder && styles.fitBorder,
            disabled ? 'cursor-not-allowed' : 'cursor-default'
          )}
          aria-label="Tabs">
          <div className={classNames(styles.tabs, fitBorder && styles.fitBorder)}>
            {options.map((tab) => (
              <TooltipProvider key={tab?.label}>
                <Tooltip>
                  <TooltipTrigger
                    type="button"
                    data-userflow-id={tab?.userflowId}
                    data-testid={tab?.id || tab?.label}
                    onClick={() => {
                      if (!tab?.disabled) {
                        onTabClick(tab.value);
                      }
                    }}
                    aria-label={`${tab?.label}-tab`}
                    className={classNames(styles.tab, {
                      [styles.active_tab]: activeTab === tab?.value,
                      [styles.inactive_tab]: activeTab !== tab?.value,
                      [styles.disabled_tab]: tab?.disabled
                    })}
                    onMouseEnter={tab?.onMouseEnter}
                    onMouseLeave={tab?.onMouseLeave}>
                    {withIcons ? (
                      tab?.icon ? (
                        tab?.icon
                      ) : (
                        <Icon.Star className="h-4 w-4 fill-copyTextGrey" />
                      )
                    ) : (
                      <></>
                    )}
                    {tab?.label}
                    {tab?.renderAdjacent ? (
                      <div className="-ml-1">{tab.renderAdjacent()}</div>
                    ) : null}
                  </TooltipTrigger>
                  {tab?.tooltip ? (
                    <TooltipContent
                      side="bottom"
                      className="ml-16 bg-darkGrey text-white"
                      sideOffset={20}>
                      {tab?.tooltip}
                    </TooltipContent>
                  ) : null}
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
          <div className="hidden sm:flex sm:flex-1" />
          {action && actionLabel ? (
            <button
              type="button"
              onClick={action}
              className={classNames(styles.tab, styles.inactive_tab)}
              data-html2canvas-ignore="true"
              onMouseEnter={onActionMouseEnter}
              onMouseLeave={onActionMouseLeave}>
              {actionLabel}
              {actionLabelTooltipIcon}
              {renderActionAdjacent ? <div className="-ml-1">{renderActionAdjacent()}</div> : null}
              <Icon.ArrowForward className="h-4 w-4 fill-copyTextGrey" />
            </button>
          ) : null}
          {renderAction ? <div className="flex items-center">{renderAction()}</div> : null}
        </nav>
      </div>
    </div>
  );
};

export function TabBar(props: TabBarProps) {
  const tabBar = {
    linear: DefaultTabBar
  };

  const TabBarComponent = tabBar['linear'];
  return TabBarComponent(props);
}
