import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';

export const ExportPDFHeader = ({ title, className }: { title?: string; className?: string }) => {
  return (
    <div className="pdf-export-header hidden">
      <div className={className}>
        {/* Inline style is necessary to fix some export render issues */}
        <div className="flex items-center" style={{ marginBottom: -75 }}>
          <div className="mb-4 flex items-center gap-x-2">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          {title ? (
            <div style={{ marginLeft: 20, marginTop: -40 }}>
              <Typography variant="h5" element="h1" className="font-medium" color="darkGrey">
                <span style={{ fontSize: 28 }}>{title}</span>
              </Typography>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
