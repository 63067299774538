import { create } from 'zustand';
interface LoadingStore {
  isRunPricingRunning: boolean;
  setLoading: (newLoading: boolean) => void;
}

export const useGetRunPricingRunningStore = create<LoadingStore>((set) => ({
  isRunPricingRunning: false,
  setLoading: (newLoading: boolean) => set({ isRunPricingRunning: newLoading })
}));
