import {
  getPmsRoomTypeList,
  createPmsRoomType,
  updatePmsRoomTypeList,
  deletePmsRoomType
} from '@common/api/hotel';
import {
  CreateRoomTypePayload,
  RoomTypePmsList,
  UpdateRoomTypePayload
} from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const usePmsRoomType = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST],
    queryFn: () => {
      return getPmsRoomTypeList();
    },
    retry: true
  });

  return {
    pmsRoomTypeList: data as RoomTypePmsList[],
    isLoading,
    error
  };
};

export const useCreatePmsRoomType = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: CreateRoomTypePayload) => {
      return createPmsRoomType(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Create Room Type Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Create Room Type');
    }
  });

  return {
    createRoomType: mutate,
    isLoading,
    isSuccess
  };
};

export const useUpdatePmsRoomType = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const updateScheduleReportsMutation = async (params: [number, UpdateRoomTypePayload]) => {
    const [id, newData] = params;
    try {
      const updatedData = await updatePmsRoomTypeList(id, newData);
      return updatedData;
    } catch (error) {
      addNotification('fail', `Failed to update ${hotelDetails?.room_apartment_space_name} Type`);
    }
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: updateScheduleReportsMutation,
    onSuccess: () => {
      addNotification(
        'success',
        `${hotelDetails?.room_apartment_space_name} Type Updated Successfully`
      );
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
    },
    onError: () => {
      addNotification('fail', `Failed to update ${hotelDetails?.room_apartment_space_name} Type`);
    }
  });

  return {
    updatePmsRoomType: mutate,
    isLoading
  };
};

export const useDeletePmsRoomType = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (id: number) => {
      return deletePmsRoomType(id);
    },
    onSuccess: () => {
      addNotification('success', 'Delete Room Type Successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.PMS_ROOM_TYPE_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Delete Room Type');
    }
  });

  return {
    deleteRoomType: mutate,
    isLoading,
    isSuccess
  };
};
