import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HotelQueryKeys, PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';
import { StepComponentProps } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/AdminSetupRoomsCard';
import { Button } from '@common/components/atoms/Button';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelStore } from '@common/store/auth';
import { updateHotelPmsRoomMap } from '@common/api/hotel';

export const SetAllRoomsSetupStep = ({ index }: StepComponentProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { hotelAuthToken } = useHotelStore();
  const { isApartment } = useRoomSetupStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const { addNotification } = useNotificationsStore();
  const apartmentsOrRoom = isApartment ? 'Apartment' : 'Room';
  const [selectReferenceRoomModalOpen, setSelectReferenceRoomModalOpen] = useState(false);
  const [selectReferenceRoomModalValue, setSelectReferenceRoomModalValue] = useState<number | null>(
    null
  );
  const [selectReferenceRoomRateModalValue, setSelectReferenceRoomRateModalValue] = useState<
    number | null
  >(null);

  const {
    hotelRooms,
    query: { isLoading: isHotelRoomsLoading }
  } = useHotelRoomsList();

  const {
    hotelDetails,
    query: { isLoading: isHotelDetailsLoading }
  } = useHotelDetails();

  const {
    hotelPmsDataMap,
    query: { isLoading: isHotelPmsDataMapLoading }
  } = useHotelPmsDataMap();

  const { mutateAsync: updateHotelPmsRoomMapMutation, isPending: isUpdateHotelPmsRoomMap } =
    useMutation({
      mutationKey: [RoomsQueryKeys.UPDATE_HOTEL_PMS_ROOM_MAP],
      mutationFn: updateHotelPmsRoomMap,
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
        await queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
        await queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_ROOMS_LIST] });
        setIsSuccess(true);
        addNotification('success', t(`Rooms setup saved!`));
        setTimeout(() => {
          setIsSuccess(false);
        }, 1000);
      },
      onError: () => {
        addNotification('fail', t(`Error setting up rooms`));
      }
    });

  const handleSetRooms = async () => {
    await updateHotelPmsRoomMapMutation({
      selected_pms_ref_room_id: hotelPmsDataMap?.mapped_data.find(
        (room) => room.id === selectReferenceRoomModalValue
      )?.id,
      selected_pms_ref_room_name: hotelPmsDataMap?.mapped_data.find(
        (room) => room.id === selectReferenceRoomModalValue
      )?.name,
      selected_pms_ref_rate_id: selectReferenceRoomRateModalValue ?? undefined,
      token: hotelAuthToken
    });
  };

  const handleModalShow = async () => {
    const referenceRoom = hotelRooms.find((room) => room.is_reference_room);
    setSelectReferenceRoomModalValue(referenceRoom?.pms_room ?? null);
    setSelectReferenceRoomRateModalValue(referenceRoom?.pms_rate ?? null);
    if (!referenceRoom?.pms_room || !referenceRoom?.pms_rate) {
      setSelectReferenceRoomModalOpen(true);
    } else {
      await handleSetRooms();
    }
  };

  const formatRoomRates = (selectReferenceRoomModalValue: number | null) => {
    if (!selectReferenceRoomModalValue) return [];
    const rates =
      hotelPmsDataMap?.mapped_data
        ?.filter((room) => room.id === selectReferenceRoomModalValue)?.[0]
        ?.rates?.map((rate) => ({
          label: `${rate.name} (${rate.rate_id})`,
          value: rate.id
        })) || [];

    return rates;
  };

  const isLoading =
    isHotelRoomsLoading ||
    isHotelDetailsLoading ||
    isHotelPmsDataMapLoading ||
    isUpdateHotelPmsRoomMap;

  return (
    <div className="flex items-center justify-start align-middle">
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        isSuccess={isSuccess}
        type="button"
        onClick={handleModalShow}
        intent="outline">
        <span>{index}.</span>
        {t(`Set Up All ${apartmentsOrRoom}s`)}
      </Button>
      <Modal
        size="lg"
        open={selectReferenceRoomModalOpen}
        onClose={async (ok) => {
          if (!ok) {
            setSelectReferenceRoomModalOpen(false);
            return;
          }
          await handleSetRooms();
          setSelectReferenceRoomModalOpen(false);
        }}
        okText="Save"
        isLoading={isUpdateHotelPmsRoomMap}>
        <div className="flex flex-col gap-y-4 pt-4">
          <Typography element="span" variant="h6" color="darkGrey">
            {t(`Please select your Reference ${hotelDetails?.room_apartment_space_name} Type`)}
          </Typography>

          <SelectDropdown
            hint={t(`${hotelDetails?.room_apartment_space_name} in PMS*`) as string}
            fullWidth
            background="grey"
            name="reference-room-modal-select"
            options={hotelPmsDataMap?.mapped_data.map((room) => ({
              label: room.name,
              value: room.id
            }))}
            value={selectReferenceRoomModalValue}
            onChange={(value) => setSelectReferenceRoomModalValue(value)}
          />

          <SelectDropdown
            hint={`${t(
              `Base Rate in ${hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'}`
            )} *`}
            fullWidth
            background="grey"
            name="reference-room-rate-modal-select"
            value={selectReferenceRoomRateModalValue}
            options={[
              {
                label: t('Not Priced'),
                value: null
              },
              ...formatRoomRates(selectReferenceRoomModalValue)
            ]}
            onChange={(value) => setSelectReferenceRoomRateModalValue(value)}
          />
        </div>
      </Modal>
    </div>
  );
};
