import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { saveLastPricingUpdate } from '@common/api/hotel';
import {
  getInventoryOrRunPrice,
  runPricingAndFetchInventoryStatus
} from '@common/api/pricingAlgorithm';
import { PricingOptionValue } from '@common/api/pricingAlgorithm/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { PriceCalculationLoadingModal } from '@pages/Client/Calendar/components/PriceCalculationLoadingModal';

interface RunPricingOptions {
  getLatestPMS: boolean;
  startDate: string;
  endDate: string;
  pricingType?: PricingOptionValue;
}

/**
 * We extract this into a separate hook to re-use the run pricing logic (The modal logic IS NOT included)
 */
const useRunPricingValue = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationsStore();
  const [showRunPricingLoadingModal, setShowRunPricingLoadingModal] = useState(false);
  const [progressMessage, setProgressMessage] = useState(t('Optimizing...') as string);
  const { mutateAsync: getInventoryOrRunPriceMutate } = useMutation({
    mutationFn: getInventoryOrRunPrice
  });
  const { hotelAuthToken } = useHotelStore();

  const runPricing = async ({
    getLatestPMS,
    startDate,
    endDate,
    pricingType
  }: RunPricingOptions) => {
    setShowRunPricingLoadingModal(true);

    try {
      if (getLatestPMS) {
        setProgressMessage(
          t('Fetching your latest availabilities and rates, this may take a while...') as string
        );
        await getInventoryOrRunPriceMutate({
          command: 'run_pricing_with_get_inventory',
          start_date: startDate,
          end_date: endDate,
          get_pms_data: true
        });
      } else {
        setProgressMessage(t('Optimizing...') as string);
        await getInventoryOrRunPriceMutate({
          command: 'run_pricing_on_demand',
          start_date: startDate,
          end_date: endDate,
          get_pms_data: false,
          return_full_dates: false,
          pricing_type: pricingType
        });
      }

      await runPricingAndFetchInventoryStatus();
      if (pricingType && hotelAuthToken) {
        await saveLastPricingUpdate({
          last_run_pricing_type: `${pricingType}`,
          token: hotelAuthToken
        });
      }
    } catch (e) {
      setShowRunPricingLoadingModal(false);
      addNotification(
        'fail',
        isAxiosError(e) ? t(e.response?.data?.MESSAGE) : t('Failed to run pricing')
      );
    }
  };

  return {
    runPricing,
    showRunPricingLoadingModal,
    setShowRunPricingLoadingModal,
    progressMessage,
    setProgressMessage
  };
};

export const RunPricingContext = createContext<ReturnType<typeof useRunPricingValue> | null>(null);

export const useRunPricingContext = () => useContext(RunPricingContext);

export const RunPricingProvider = ({ children }: PropsWithChildren) => {
  const value = useRunPricingValue();
  const { progressMessage, showRunPricingLoadingModal } = value;

  return (
    <RunPricingContext.Provider value={value}>
      <PriceCalculationLoadingModal
        progressMessage={progressMessage}
        open={showRunPricingLoadingModal}
      />
      {children}
    </RunPricingContext.Provider>
  );
};
