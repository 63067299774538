export function SyncOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38334 3.14957L20.8459 19.6196C20.9439 19.7089 21.0005 19.8348 21.0024 19.9674C21.0043 20.1 20.9513 20.2275 20.8559 20.3196L20.3262 20.8496C20.2355 20.9462 20.1088 21.0011 19.9763 21.0011C19.8438 21.0011 19.7172 20.9462 19.6265 20.8496L16.9977 18.2296C16.0042 19.0094 14.8391 19.5407 13.5992 19.7796C13.4514 19.8074 13.2988 19.771 13.1794 19.6796C13.0671 19.5808 13.0017 19.4391 12.9995 19.2896V18.2896C13.0009 18.0591 13.1566 17.8583 13.3793 17.7996C14.1662 17.6185 14.9078 17.2781 15.5583 16.7996L7.20207 8.42957C5.24625 11.0232 5.7361 14.7076 8.30157 16.6996L9.85087 15.1496C9.94491 15.0519 10.0752 14.9976 10.2107 14.9996H10.5006C10.7766 14.9996 11.0004 15.2234 11.0004 15.4996V20.4996C11.0004 20.7757 10.7766 20.9996 10.5006 20.9996H5.50283C5.22682 20.9996 5.00306 20.7757 5.00306 20.4996V20.2096C5.00109 20.074 5.05538 19.9436 5.15299 19.8496L6.87222 18.1196C3.55016 15.3256 3.0622 10.3906 5.77271 6.99957L3.15389 4.37957C3.05726 4.28883 3.00244 4.16215 3.00244 4.02957C3.00244 3.89698 3.05726 3.7703 3.15389 3.67957L3.68365 3.14957C3.77435 3.05289 3.90097 2.99805 4.0335 2.99805C4.16602 2.99805 4.29265 3.05289 4.38334 3.14957ZM13.4992 8.99957H13.7891C13.9246 9.00153 14.0549 8.94722 14.1489 8.84957L15.6982 7.29957C17.7136 8.86756 18.5159 11.5402 17.6973 13.9596L19.2267 15.4896C20.8229 12.1875 19.9701 8.22253 17.1576 5.86957L18.8768 4.14957C18.9635 4.05055 19.0067 3.92081 18.9968 3.78957V3.49957C18.9968 3.22342 18.773 2.99957 18.497 2.99957H13.4992C13.2232 2.99957 12.9995 3.22342 12.9995 3.49957V8.49957C12.9995 8.77571 13.2232 8.99957 13.4992 8.99957ZM10.0508 6.33957L8.52147 4.80957C9.11714 4.51481 9.74893 4.29964 10.4006 4.16957C10.5484 4.14178 10.701 4.17814 10.8204 4.26957C10.9327 4.36834 10.9981 4.50999 11.0004 4.65957V5.65957C10.9989 5.88999 10.8432 6.09083 10.6205 6.14957C10.5256 6.18456 10.4306 6.21456 10.3357 6.24456L10.3357 6.24457C10.2407 6.27457 10.1457 6.30457 10.0508 6.33957Z"
      />
    </svg>
  );
}
