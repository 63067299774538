import axios, { AxiosError } from 'axios';
import { axiosInstance } from '@common/api/axiosInstance';
import {
  GetCachedRoomPricesResponse,
  GetPriceDevelopmentResponse
} from '@common/api/roomPrices/types';
import { ErrorResponse } from '@common/api/auth';
import { PricingSettings } from '@common/api/hotel/types';

export const getPriceCacheData = () => {
  return axiosInstance.get<GetCachedRoomPricesResponse>(`/pricingalgorithm/cache-price/`);
};

export const getPriceDevelopmentData = () => {
  return axiosInstance.get<GetPriceDevelopmentResponse>(`/pricingalgorithm/price-development/`);
};

export const getPricingSettings = async () => {
  try {
    const { data } = await axiosInstance.get<PricingSettings>('/hotel/pricing-settings/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
