export function Error(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM13 15.5C13 15.7761 12.7761 16 12.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14.5C11 14.2239 11.2239 14 11.5 14H12.5C12.7761 14 13 14.2239 13 14.5V15.5ZM12.53 12C12.6572 12.0009 12.7647 11.9062 12.78 11.78L13.18 8.56C13.1972 8.41735 13.1523 8.27417 13.0566 8.16698C12.9609 8.05979 12.8237 7.99896 12.68 8H11.32C11.1763 7.99896 11.0391 8.05979 10.9434 8.16698C10.8477 8.27417 10.8028 8.41735 10.82 8.56L11.22 11.78C11.2353 11.9062 11.3428 12.0009 11.47 12H12.53Z"
      />
    </svg>
  );
}
