import {
  cond,
  entries,
  find,
  forEach,
  isArray,
  isObject,
  keys,
  map,
  some,
  startCase,
  stubTrue,
  toLower,
  values
} from 'lodash-es';
import { useHotelRoomsList } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelRoomList';
import { useFeatureDescription } from '@common/hooks/useFeatureDescription';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Month } from 'use-lilius';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';

export const useTransformLog = () => {
  const { hotelRooms } = useHotelRoomsList();
  const { pricingSettings } = usePricingSettings();
  const yieldingTags = pricingSettings?.room_type_tags ?? [];
  const { featureDescriptions } = useFeatureDescription();

  const transformKey = (key: string, path: string): string => {
    const evaluateCondition = cond([
      // If the key is a room ID, return the room name
      [
        () => some(hotelRooms, (room) => room.id === Number(key)),
        () => find(hotelRooms, (room) => room.id === Number(key))?.name ?? key
      ],
      // If the key is a yielding tag ID, return the tag name
      [
        () => some(keys(yieldingTags), (tag) => tag === key),
        () => find(values(yieldingTags), (tag) => tag.id === key)?.tag_name ?? key
      ],
      // If the key is a feature, return the feature name
      [() => key === 'PELL', () => 'Aggressiveness'],
      [() => key === 'speed', () => 'Aggressiveness'],
      [() => key === 'tag_name', () => 'Tag Name'],
      [() => key === 'color', () => 'Color'],
      [() => key === 'min_adjustment', () => 'Min Adjustment'],
      [() => key === 'max_adjustment', () => 'Max Adjustment'],
      [() => key === 'room_type_tags', () => 'Yielding Tags'],
      [() => key === 'room_types', () => 'Room Types'],
      [() => key === 'id', () => 'ID'],
      [() => key === 'median_lead_time', () => 'Median Booking Window'],
      [() => key === 'surge_protection', () => 'Surge Protection'],
      [() => key === 'threshold', () => 'Number of bookings to trigger surge protection'],
      [() => key === 'days_ahead', () => 'Only check after this many days to go'],
      [() => key === 'duration_in_days', () => 'Keep fix price for this many days'],
      [() => key === 'fix_price', () => 'Fix price to set for reference room'],
      [() => key === 'predefined_rates', () => 'BAR Levels'],
      [() => key === 'avg_price', () => 'Base Price'],
      [() => key === 'min_price', () => 'Min Price'],
      [() => key === 'max_price', () => 'Max Price'],
      [() => key === 'fix_price', () => 'Fix Price'],
      [() => key === 'dates', () => 'Bulk Edit'],
      [() => key === 'number_of_rooms', () => 'Number of Rooms'],
      [() => key === 'adjustment_to_reference_room_is_absolute', () => 'Absolute Room Derivation'],
      [() => key === 'default', () => 'Room Type Setup Adjustment to Reference Room'],
      [() => key === 'adjustment_to_reference_room', () => 'Room Derivation'],
      [() => key === 'adjustment', () => 'Adjustment'],
      [() => key === 'lead_time', () => 'Lead Time'],
      [() => key === 'last_day', () => 'Last Day'],
      [() => key === 'rpg_arguments', () => 'Occupancy Strategy'],
      [() => key === 'PELL_weekday', () => 'Aggressiveness Weekdays'],
      [() => key === 'PELL_weekend', () => 'Aggressiveness Weekends'],
      [() => key === 'monthly', () => 'Monthly Adjustments'],
      [() => key === 'weekday', () => 'Day-of-the-week Adjustments'],
      [() => key === 'standard', () => 'Adjustment'],
      [() => key === 'after_365_daily_averages', () => 'After 365 Days'],
      [() => key === 'monthly_averages', () => 'Monthly Averages'],
      [() => key === 'features', () => 'Features'],
      [() => key === 'min_stay', () => 'Min Stay Restriction'],
      [() => key === 'rooms', () => 'Rooms'],
      [() => key === 'derived', () => 'Derived Rooms'],
      [() => key === 'reference', () => 'Reference Rooms'],
      [() => key === 'hotel', () => 'Hotel'],
      [
        () => path.includes('after_365_daily_averages.monthly_averages'),
        () => startCase(toLower(Month[Number(key) - 1]))
      ],
      [stubTrue, () => key]
    ]);

    return evaluateCondition();
  };

  const transformValue = (value: any, path = ''): any => {
    const evaluateCondition = cond([
      [
        () => path.includes('feature'),
        () =>
          isArray(value)
            ? map(
                value,
                (feature) => featureDescriptions[feature] ?? `Unknown feature (${feature})`
              ).join(', ')
            : value
      ],
      // If the value is a room ID, return the room name
      [
        () => path.includes('room_types'),
        () =>
          isArray(value)
            ? map(
                value,
                (room) => find(hotelRooms, (hotelRoom) => hotelRoom.id === room)?.name
              ).join(', ')
            : value
      ],
      // If the value is a feature, return the feature name
      [
        () => path.includes('predefined_rates'),
        () =>
          isArray(value)
            ? map(value, (barLevel) => CurrencyFormatter.format(barLevel)).join(', ')
            : value
      ],
      [
        () => path.includes('after_365_daily_averages.monthly_averages'),
        () => CurrencyFormatter.format(value)
      ],
      [stubTrue, () => value]
    ]);

    return evaluateCondition();
  };

  const transformLog = (log: any, path = ''): any => {
    if (isArray(log)) {
      return transformValue(log, path);
    } else if (isObject(log) && log !== null) {
      const transformed: Record<string, any> = {};
      forEach(entries(log), ([key, value]) => {
        // Update the path for key transformation
        const newPath = path ? `${path}.${key}` : key;
        const transformedKey = transformKey(key, newPath);
        transformed[transformedKey] = isObject(value)
          ? transformLog(value, newPath)
          : transformValue(value, newPath);
      });
      return transformed;
    }
    return log; // Non-object, non-array values are returned as-is
  };

  return { transformLog };
};
