import { getDetailsScheduleHistory } from '@common/api/amalgamation';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

interface QueryParameters {
  page?: number;
  id?: string | null;
  search?: string;
  status?: number;
}

export const useDetailsScheduleHistory = ({ page = 1, id, search, status }: QueryParameters) => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, error, isError } = useQuery({
    queryKey: [UtilQueryKeys.GET_DETAILS_SCHEDULE_HISTORY_LIST, page, id, search, status],
    queryFn: () => {
      if (!id) return;
      return getDetailsScheduleHistory(id, page, search, status);
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get details schedule history list');

  return {
    detailScheduleHistoryList: data?.data,
    isLoading,
    error
  };
};
