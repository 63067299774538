import { resyncEviivoPms, resyncFreetobookPms, initDataPmsSync } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useRequestFullSync = () => {
  const { addNotification } = useNotificationsStore();
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (provider: number | undefined) => {
      if (provider === 46) {
        return resyncEviivoPms();
      } else if (provider === 52) {
        return resyncFreetobookPms();
      } else {
        return initDataPmsSync();
      }
    },
    onSuccess: () => {
      addNotification('success', 'Inventory and Rates Reset Started');
    },
    onError: () => {
      addNotification('fail', 'Failed to start Inventory and Rates Reset');
    }
  });

  return {
    requestFullSync: mutate,
    isLoading
  };
};
