import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import dayjs from 'dayjs';
import { Input } from '@common/components/atoms/Input';
import { Typography } from '@common/components/foundations/Typography';
import { DateRangePicker } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { API_DATE_FORMAT } from '@common/constants/date';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDashboardDailyReports } from '@pages/Admin/DashboardErrorTickets/hooks/useDashboardDailyReports';
import { DashboardDailyReports } from '@common/api/explorer/types';
import { Table } from '@common/components/molecules/Table';
import { Icon } from '@common/components/foundations/icons';
import { useNotificationsStore } from '@common/store/notifications';
import { ActionIcon, Button } from '@mantine/core';

export const DashboardDebugger = () => {
  const { addNotification } = useNotificationsStore();

  const schema = z.object({
    hotel_id: z.string(),
    date_from: z.string(),
    date_to: z.string()
  });
  const { control, setValue, getValues, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const payload = {
    hotel_id: watch('hotel_id') ? Number(watch('hotel_id')) : undefined,
    date_from: watch('date_from')
      ? dayjs(watch('date_from')).startOf('day').format(API_DATE_FORMAT)
      : undefined,
    date_to: watch('date_to')
      ? dayjs(watch('date_to')).endOf('day').format(API_DATE_FORMAT)
      : undefined
  };

  const {
    dashboardDailyReports,
    // TODO: Check why isLoading = true all the time, isFetching works for the moment
    query: { isFetching, refetch }
  } = useDashboardDailyReports(payload);

  const handleSubmit = () => {
    if (!payload.hotel_id || !payload.date_from || !payload.date_to) {
      return;
    }
    refetch();
  };

  const titleMap: DashboardDailyReports = {
    report_analytics: 'Daily Report: Analytics',
    report_calendar: 'Daily Report: Calendar',
    rooms_mapping: 'Rooms Mapping'
  };

  const TableHeader = ({ id }: { id: string }) => {
    return (
      <div className="flex w-full justify-between py-1">
        <Typography variant="h6" element="h6" className="font-medium" color="darkGrey">
          {titleMap[id as keyof DashboardDailyReports]}
        </Typography>
      </div>
    );
  };
  // TODO: Type data properly later, time concern.
  const convertToCSV = (objArray: any) => {
    const rows = [];
    const indexes = Object.keys(objArray[Object.keys(objArray)[0]]);

    const headers = Object.keys(objArray).join(',');
    rows.push(headers);
    indexes.forEach((index) => {
      const row: any = [];
      Object.keys(objArray).forEach((key) => {
        row.push(`"${objArray[key][index]}"`); // Wrap each value in quotes to handle commas, etc.
      });
      rows.push(row.join(','));
    });
    return rows.join('\n');
  };
  // TODO: Type data properly later, time concern.
  const downloadCSV = (data: any, filename = 'data.csv') => {
    if (!data) return;
    try {
      const csvData = convertToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (error) {
      addNotification('fail', 'Error with downloading CSV');
    }
  };

  const isPayloadValid = Object.values(payload).every(
    (value) => value !== undefined && value !== null
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="rounded-lg border border-grey-reduced border-opacity-40 bg-white">
        <div className="flex w-full justify-between py-3">
          <div className="flex items-center gap-x-4 border-[rgb(212,212,212)] border-opacity-50 px-3 py-3 md:px-6">
            <Typography variant="h6" element="h6" className="font-medium" color="darkGrey">
              Dashboard Debugger
            </Typography>
          </div>
        </div>
        <div className="flex gap-5 px-3 pb-3 md:px-6">
          <div>
            <Controller
              name="hotel_id"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  label="Hotel ID"
                  background="grey"
                  placeholder="Please enter hotel ID"
                  value={value}
                  onChange={onChange}
                  onClear={() => setValue('hotel_id', '')}
                  error={!!error}
                />
              )}
            />
          </div>
          <div>
            <Typography element="p" variant="meta-1" color="copyTextGrey">
              Date
            </Typography>
            <DateRangePicker
              enablePredefinedRanges
              className="mt-1"
              allowPastDates
              isClearable
              startDate={
                getValues('date_from') ? dayjs(getValues('date_from')).toDate() : undefined
              }
              endDate={getValues('date_to') ? dayjs(getValues('date_to')).toDate() : undefined}
              onDateChange={(date) => {
                setValue('date_from', date?.from ? dayjs(date.from).format(API_DATE_FORMAT) : '');
                setValue('date_to', date?.to ? dayjs(date.to).format(API_DATE_FORMAT) : '');
              }}
            />
          </div>
          <div className="mt-4 flex items-center">
            <Button onClick={handleSubmit} disabled={!isPayloadValid} loading={isFetching}>
              Calculate
            </Button>
          </div>
        </div>
        <div className="mt-5 px-3 pb-3 md:px-6">
          {dashboardDailyReports ? (
            <>
              {Object.entries(dashboardDailyReports)?.map(([key, data], index) => {
                const columns = Object.keys(data).map((key) => {
                  return {
                    header: key.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()), // Convert snake_case to Title Case
                    accessorKey: key,
                    cell: (row: any) => {
                      return (
                        <div className="flex h-[40px] items-center gap-3 overflow-hidden overflow-ellipsis whitespace-nowrap">
                          <Typography element="span" color="copyTextGrey" className="truncate">
                            {row?.getValue() as string}
                          </Typography>
                        </div>
                      );
                    },
                    meta: {
                      showOnMobile: true
                    }
                  };
                });
                // TODO: Type data properly later, time concern.
                const transformDataToTableRows = (data: any) => {
                  const numberOfRows = Object.values(data[Object.keys(data)[0]]).length;
                  const rows = [];

                  for (let i = 0; i < numberOfRows; i++) {
                    const row: any = {};
                    for (const key of Object.keys(data)) {
                      row[key] = data[key][i]?.toString();
                    }
                    rows.push(row);
                  }
                  return rows;
                };
                const tableRows = transformDataToTableRows(data);

                return (
                  <div key={index} className="mt-5">
                    <Table
                      isHover
                      isScrollable
                      columns={columns}
                      skeletonCount={20}
                      isFetching={isFetching}
                      data={tableRows}
                      headerComponent={<TableHeader id={key} />}
                      actionComponents={
                        <div className="flex flex-1 items-center">
                          <div className="flex items-center gap-x-3">
                            <ActionIcon
                              title="Export CSV"
                              disabled={isFetching}
                              onClick={() =>
                                downloadCSV(
                                  data,
                                  `${key}_${getValues('hotel_id')}_${getValues(
                                    'date_from'
                                  )}_${getValues('date_to')}.csv`
                                )
                              }>
                              <Icon.DownloadFromCloud />
                            </ActionIcon>
                          </div>
                        </div>
                      }
                    />
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
