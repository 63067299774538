import { useAuth, useClerk } from '@clerk/clerk-react';
import { User } from '@common/api/auth';
import { setAuthToken, setHotelClerkAxiosHeader } from '@common/api/axiosInstance';
import { UtilQueryKeys } from '@common/types/query-keys';
import { queryClient } from '@common/setup/query-client';
import { uniq } from 'lodash-es';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SharedUserAuthStore = {
  isAuthenticated: boolean;
  hotelIframeId: string | null;
  clearAuth: () => void;
  clearAdminAuth: () => void;
  setUser: (user: UserAuthStore['user']) => void;
  hotel: number | null;
  setHotel: (hotel: UserAuthStore['hotel']) => void;
  userEmails: string[];
  setUserEmails: () => void;
};

type UserAuthStore = SharedUserAuthStore & {
  isOTPValidated: boolean;
  token: string | null;
  user: User | null;
  setAuth: (token: UserAuthStore['token'], user: UserAuthStore['user']) => void;
  setOTPValidation: (isOTPValidated: UserAuthStore['isOTPValidated']) => void;
  setHotelIframeId: (hotelIframeId: string) => void;
};

const initialState = {
  hotel: null,
  hotelIframeId: null,
  isAuthenticated: false,
  isOTPValidated: false,
  token: null,
  user: null
};

export const useAuthStore = create<UserAuthStore>()(
  persist(
    (set) => ({
      ...initialState,
      setAuth: (token, user) => {
        set({ isAuthenticated: true, user, token });
      },

      clearAuth: () => {
        set(initialState);
      },

      clearAdminAuth: () => {
        set(initialState);
      },

      setOTPValidation: (isOTPValidated: boolean) => {
        set({ isOTPValidated });
      },

      setHotelIframeId: (hotelIframeId: string) => {
        set({ hotelIframeId });
      },
      setUser(user) {
        set({ user });
      },
      hotel: null,
      setHotel(hotel) {
        set({ hotel });
      },
      userEmails: [],
      setUserEmails: () => {
        set((state) => {
          const userEmail = state.user?.email ? [state.user.email] : [];
          const uniqueEmails = uniq([...state.userEmails, ...userEmail]);
          return { userEmails: uniqueEmails };
        });
      }
    }),
    {
      name: 'authStore',
      onRehydrateStorage: () => {
        return (state) => {
          if (state && state.token) {
            setAuthToken(state.token);
          }
        };
      }
    }
  )
);

type HotelAuthStore = {
  hotelAuthToken: string | null;
  setHotelAuthToken: (hotelAuthToken: string) => void;
  clearHotelAuth: () => void;
  clerkHotelSession: null | string;
  setClerkHotelSession: (clerkHotelSession: string) => void;
};

export const useHotelStore = create<HotelAuthStore>()((set) => ({
  hotelAuthToken: null,
  setHotelAuthToken: (hotelAuthToken: string) => {
    set({ hotelAuthToken });
  },
  clearHotelAuth: () => {
    set({ hotelAuthToken: null });
  },
  clerkHotelSession: null,
  setClerkHotelSession(clerkHotelSession) {
    setHotelClerkAxiosHeader(clerkHotelSession);
    set({ clerkHotelSession });
  }
}));

export function useLogout() {
  const { clearAuth } = useAuthStore();
  const clerkAuth = useAuth();
  const clerk = useClerk();

  return async () => {
    await clerkAuth.signOut();
    await clerk.session?.end();
    queryClient.removeQueries({ queryKey: [UtilQueryKeys.CLERK_USER_DETAIL] });
    setAuthToken(null);
    clearAuth();
  };
}
