import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLilius } from 'use-lilius';
import { useBanners } from '@common/store/banner';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { DashboardHeader } from '@pages/Client/Dashboard/components/DashboardHeader';
import { Page } from '@common/components/organisms/Page';
import { SetBudgetForm } from '@pages/Client/Dashboard/components/SetBudgetForm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flags } from '@common/constants/flags';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useBudget } from '@pages/Client/Dashboard/hooks/useBudget';
import { useAppFlags } from '@common/hooks/useAppFlags';

type DashboardType = 'performance' | 'analytics' | 'market';

const typeMapping: Record<string, DashboardType | undefined> = {
  performance: 'performance',
  analytics: 'analytics',
  market: 'market'
};

function getType(param: string | undefined): DashboardType | undefined {
  if (!param) return undefined;
  return typeMapping[param];
}

export function DashboardLayout() {
  const captureRef = useRef<HTMLDivElement>(null);
  const match = useMatch('/client/dashboard/:id/*');
  const paramsMatch = match?.params['*'];
  const { id } = useParams();
  const navigate = useNavigate();
  const { createBanner, removeBanner } = useBanners();
  const { pmsProviderId } = usePmsProvider();
  const { t } = useTranslation();
  const flags = useFlags();
  const { features, hasDashboardFeature, hasMarketInsightsFeature } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const { propertyIds } = useDashboardPageStore();
  const {
    query: { isFetching: isBudgetFetching },
    isNext12MonthsBudgetFull
  } = useBudget();
  const { is12MonthsBudgetBannerEnabled } = useAppFlags();

  const liliusInstance = useLilius();
  const { setLiliusInstance } = useDashboardPageStore();

  const [showSetBudget, setShowSetBudget] = useState(false);

  useEffect(() => {
    const pmsIssueExists = [PmsProvider.CLOUDBEDS];
    const isReportingDashboardV2 = flags[Flags.ReportingDashboardV2];

    // Temporary issue announcement for some PMS, will be removed later
    if (pmsIssueExists.includes(pmsProviderId) && !isReportingDashboardV2) {
      createBanner({
        id: 'issue-announcement',
        description: (
          <div>
            {t(
              'We have identified an issue that may affect the accuracy of some reports for certain clients. Please be advised that the numbers displayed might not reflect the most current data. Our team is actively working on a resolution to ensure the integrity and reliability of our reporting features. We apologize for any inconvenience this may cause and appreciate your patience and understanding.'
            )}{' '}
            <span className="font-bold text-darkGrey">
              {t('Your pricing recommendations are not affected.')}
            </span>{' '}
            {t('For any concerns or immediate assistance, please contact our support team.')}
          </div>
        ),
        type: 'warning',
        showAction: false
      });
      return () => removeBanner('issue-announcement');
    }
  }, [pmsProviderId]);

  const isMultipleView = hotelDetails?.id && propertyIds?.[hotelDetails?.id]?.length > 1;

  const shouldShowBudgetBanner =
    is12MonthsBudgetBannerEnabled &&
    features?.includes(Feature.ProfessionalDashboard) &&
    !isMultipleView &&
    !isBudgetFetching &&
    !isNext12MonthsBudgetFull();

  useEffect(() => {
    if (shouldShowBudgetBanner) {
      createBanner({
        id: 'budget-announcement',
        description: (
          <div className="flex gap-1 flex-col">
            <div>
              {t(
                'Budget Not Set: Please set your budget for the next 12 months to enable accurate revenue forecasting.'
              )}
            </div>
            <div
              onClick={() => handleOpenSetBudget?.()}
              className="font-bold text-darkGrey cursor-pointer"
            >
              {t('Set Budget now ->')}
            </div>
          </div>
        ),
        type: 'warning',
        showAction: false
      });
      return () => removeBanner('budget-announcement');
    }
  }, [shouldShowBudgetBanner]);

  /**
   * Sync lilius instance with Zustand store.
   */
  useEffect(() => {
    setLiliusInstance(liliusInstance);
  }, [liliusInstance.viewing]);

  const type = getType(paramsMatch);

  // Redirect to '/client/dashboard/:id/performance' if the type is not valid
  useEffect(() => {
    if (!type) {
      navigate(`/client/dashboard/${id}/performance`, { replace: true });
    }

    if (!hasDashboardFeature() && hasMarketInsightsFeature()) {
      navigate(`/client/dashboard/${id}/market`, { replace: true });
    }
  }, [type, id, navigate, features]);

  if (!type) return null;

  const handleScheduleReportClick = () => {
    navigate(`/client/account/${id}/schedule-reports`);
  };

  const handleOpenSetBudget = () => {
    setShowSetBudget(true);
  };

  const handleCloseSetBudget = () => {
    setShowSetBudget(false);
  };

  const renderHeader = () => {
    return (
      <DashboardHeader
        exportRef={captureRef}
        type={type}
        onScheduleReportClick={handleScheduleReportClick}
        onSetBudgetClick={handleOpenSetBudget}
      />
    );
  };

  return (
    <Page ref={captureRef} header={renderHeader()}>
      <Outlet />
      <SetBudgetForm open={showSetBudget} onClose={handleCloseSetBudget} />
    </Page>
  );
}
