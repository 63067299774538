import { Stack, Group, Badge, Title } from '@mantine/core';

export const DataDisplay = () => {
  return (
    <Stack py="xs">
      <Stack>
        <Title order={2} size="h3">
          Badge
        </Title>
        <Group>
          <Badge>Default</Badge>
          <Badge variant="dot">Dot</Badge>
          <Badge variant="outline">Outline</Badge>
          <Badge variant="light">Light</Badge>
          <Badge variant="white">White</Badge>
          <Badge variant="transparent">Transparent</Badge>
          <Badge color="#f23344">Custom Color</Badge>
        </Group>
      </Stack>
    </Stack>
  );
};
