export const Support = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM20 12H17C17 10.2 16 8.6 14.5 7.7L16 5.1C18.5 6.5 20 9.1 20 12ZM12 9C10.3 9 9 10.3 9 12C9 13.7 10.3 15 12 15C13.7 15 15 13.7 15 12C15 10.3 13.7 9 12 9ZM8 5.1L9.5 7.7C8 8.6 7 10.2 7 12H4C4 9.1 5.5 6.5 8 5.1ZM9.5 16.3L8 18.9C10.5 20.4 13.5 20.4 16 18.9L14.5 16.3C13 17.2 11 17.2 9.5 16.3Z"
      />
    </svg>
  );
};
