import { create } from 'zustand';

interface SubscriptionStore {
  isMonthly: boolean;
  setIsMonthly: (newIsMonthly: boolean) => void;
}

export const usePaymentStore = create<SubscriptionStore>((set) => ({
  isMonthly: false,
  setIsMonthly: (newIsMonthly: boolean) => set({ isMonthly: newIsMonthly })
}));
