import { getUsersLog } from '@common/api/account';
import { setAuthToken } from '@common/api/axiosInstance';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useUsersLog = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [UtilQueryKeys.USER_LOGS],
    queryFn: () => {
      setAuthToken(hotelAuthToken);
      return getUsersLog();
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get users log');

  return {
    usersLog: data,
    isLoading,
    error
  };
};
