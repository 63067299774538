import React, { useMemo, useState } from 'react';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  Line,
  CartesianGrid,
  ReferenceLine
} from 'recharts';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';

type DataKey = 'stly' | 'total';

interface Props {
  total: Partial<Record<string, number>> | undefined | null;
  stly: Partial<Record<string, number>> | undefined | null;
  currentLeadTime: number | null;
}

export const MonthlyBookingCurve: React.FC<Props> = ({ total, stly, currentLeadTime }) => {
  const { t } = useTranslation();
  const indigo = useTailwindColor('indigo');
  const gold = useTailwindColor('gold');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const uiGreen = useTailwindColor('uiGreen');

  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    stly: true,
    total: true
  });

  const { allKeys, totalData, stlyData } = useMemo(() => {
    const keysSet = new Set<string>();

    if (total) {
      for (const key in total) {
        keysSet.add(key);
      }
    }
    if (stly) {
      for (const key in stly) {
        keysSet.add(key);
      }
    }
    const allKeys = Array.from(keysSet).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    const totalData = [];
    const stlyData = [];

    for (const key of allKeys) {
      totalData.push({ key, value: total ? total[key] ?? 0 : 0 });
      stlyData.push({ key, value: stly ? stly[key] ?? 0 : 0 });
    }
    return { allKeys, totalData, stlyData };
  }, [total, stly]);

  const data = allKeys.map((key, index) => ({
    key,
    total: totalData[index]?.value,
    stly: stlyData[index]?.value
  }));

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    return (
      <ul className="mb-8 flex list-none justify-center">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => (
            <li
              key={`item-${index}`}
              className="mr-2 flex cursor-pointer items-center"
              onClick={() => handleLegendClick(entry.dataKey)}>
              <div
                className="mr-1 h-3 w-3 rounded-full"
                style={{
                  backgroundColor: entry.color,
                  opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                }}
              />
              <Typography
                color="copyTextGrey"
                variant="meta-2"
                className={clsx(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                {t(entry.value)}
              </Typography>
            </li>
          )
        )}
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-2" className="text-meta-2-semibold" color="grey">
          {dataPoint.key} {t('Days Lead Time')}
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <Typography variant="meta-2" color="darkGrey">
            {t('On the Books')}
          </Typography>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            {formattedPercentage(dataPoint.total)}
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <Typography variant="meta-2" color="darkGrey">
            {t('Same Time Last Year')}
          </Typography>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            {formattedPercentage(dataPoint.stly)}
          </Typography>
        </div>
      </Card>
    );
  };

  return (
    <div className="relative h-full w-full">
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0
          }}>
          <defs>
            <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={indigo} stopOpacity={0.5} />
              <stop offset="100%" stopColor={indigo} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
          <XAxis
            axisLine={false}
            height={40}
            tick={{ fill: grey, stroke: 'none' }}
            tickLine={false}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            dataKey="key"
            label={{
              value: t('Lead Time in Days'),
              position: 'insideBottom',
              offset: 3,
              fontSize,
              fontFamily,
              fontWeight
            }}
          />
          <YAxis
            domain={[0, 100]}
            axisLine={false}
            tick={{ fill: grey, stroke: 'none' }}
            tickLine={false}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            tickFormatter={(value: number) => formattedPercentage(value)}
            label={{
              value: t('Occupancy in %'),
              angle: -90,
              position: 'insideLeft',
              fontSize,
              fontFamily,
              fontWeight
            }}
          />
          {currentLeadTime ? (
            <ReferenceLine
              x={`${currentLeadTime}`}
              stroke={uiGreen}
              strokeWidth={2}
              label={{
                position: 'top',
                value: 'Today',
                fill: uiGreen,
                fontWeight: 600
              }}
            />
          ) : null}
          <Tooltip content={(props) => <CustomTooltip {...props} />} />
          <Legend verticalAlign="top" content={(props) => <CustomLegend {...props} />} />
          {total !== null && (
            <Area
              visibility={seriesVisibility.total ? 'visible' : 'hidden'}
              dataKey="total"
              name="On the Books"
              stroke={indigo}
              strokeWidth={2}
              fill="url(#totalGradient)"
              fillOpacity={0.2}
            />
          )}
          {stly !== null && (
            <Line
              visibility={seriesVisibility.stly ? 'visible' : 'hidden'}
              dataKey="stly"
              name="Same Time Last Year"
              stroke={gold}
              fill={gold}
              dot={false}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
