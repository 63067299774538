import axios, { AxiosError } from 'axios';
import { axiosInstance } from '../axiosInstance';
import { Country, Currency } from './types';
import { ErrorResponse } from '../auth';

export const getCurrencyList = async () => {
  try {
    const { data } = await axiosInstance.get<Currency[]>('/system/currency/list/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getCountryList = async () => {
  try {
    const { data } = await axiosInstance.get<Country[]>('/system/country/list/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
