import { useClientListStore } from '@common/store/hotel-list-store';
import { ComboboxItem, Select } from '@mantine/core';
import { useDealOwnerList } from '@pages/Admin/Clients/hooks/useDealOwnerList';
import { isNil, find } from 'lodash-es';

export function FiltersDealOwnerSelect() {
  const paramKey = 'deal_owner_filter';
  const paramKey2 = 'deal_owner_type';
  const { params, setParams, setFilters } = useClientListStore();
  const { dealOwnerList, dealOwnerSelectList } = useDealOwnerList();

  function handleChange(option: ComboboxItem) {
    if (!option) {
      setFilters({ [paramKey]: undefined, [paramKey2]: undefined });
      setParams({ [paramKey]: undefined, [paramKey2]: undefined });
      return;
    } else {
      const reseller = find(dealOwnerList, { id: Number(option.value) });
      if (isNil(reseller)) return;
      setFilters({
        [paramKey]: reseller.name,
        [paramKey2]: reseller.type as 'reseller' | 'sales_team'
      });
      setParams({
        [paramKey]: Number(reseller.id),
        [paramKey2]: reseller.type as 'reseller' | 'sales_team'
      });
    }
  }

  return (
    <Select
      searchable
      clearable
      label="Deal Owner"
      variant="filled"
      placeholder="All"
      value={find(dealOwnerSelectList, { value: String(params[paramKey]) })?.value || null}
      data={dealOwnerSelectList}
      onChange={(_value, option) => handleChange(option)}
      comboboxProps={{ withinPortal: false }}
    />
  );
}
