import { isNumber } from 'lodash-es';

interface CurrencyFormatterOptions {
  locale?: string;
  currency?: string;
  currencySymbol?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const CurrencyFormatter = (function () {
  let formatter: Intl.NumberFormat;
  let currencySymbol: string;

  const defaultOptions: CurrencyFormatterOptions = {
    currency: 'CHF',
    currencySymbol: 'CHF',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  function init(options: CurrencyFormatterOptions = {}) {
    const mergedOptions = { ...defaultOptions, ...options };

    formatter = new Intl.NumberFormat(mergedOptions.locale, {
      style: 'currency',
      currency: mergedOptions.currency === 'KR' ? 'ISK' : mergedOptions.currency,
      minimumFractionDigits: mergedOptions.minimumFractionDigits,
      maximumFractionDigits: mergedOptions.maximumFractionDigits,
      currencyDisplay: 'narrowSymbol'
    });

    currencySymbol = mergedOptions.currencySymbol ?? '';
  }

  function format(amount?: number, fallback = 'n.A.'): string {
    if (!isNumber(amount) || isNaN(amount)) return fallback;

    const isNegative = amount < 0;
    let formatted = formatter.format(Math.abs(amount));

    // Remove the currency symbol added by Intl.NumberFormat
    formatted = formatted.replace(/[^\d.,\s]/g, '').trim();

    // Add the currency symbol based on its length
    if (currencySymbol.length === 3) {
      formatted = `${currencySymbol} ${formatted}`;
    } else if (currencySymbol.length === 1) {
      formatted = `${currencySymbol}${formatted}`;
    } else if (currencySymbol === '€') {
      formatted = `${formatted} ${currencySymbol}`;
    } else {
      // For any other case, prepend the symbol
      formatted = `${currencySymbol} ${formatted}`;
    }

    // Add the negative sign if the original amount was negative
    if (isNegative) {
      formatted = '-' + formatted;
    }

    return formatted;
  }

  // Initialize with default options
  init();

  return {
    init,
    format,
    currencySymbol: () => currencySymbol
  };
})();
