import { Outlet } from 'react-router-dom';
import { SubscriptionHeader } from './components/SubscriptionHeader';

export function SubscriptionLayout() {
  return (
    <>
      <SubscriptionHeader />
      <Outlet />
    </>
  );
}
