export function ChevronDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M12.7201 15.7802C12.5796 15.9209 12.3889 16 12.1901 16.0002H11.8101C11.6117 15.9979 11.4218 15.919 11.2801 15.7802L6.1501 10.6402C6.05544 10.5463 6.0022 10.4185 6.0022 10.2852C6.0022 10.1518 6.05544 10.024 6.1501 9.93016L6.8601 9.22016C6.95226 9.1261 7.07841 9.07309 7.2101 9.07309C7.34179 9.07309 7.46793 9.1261 7.5601 9.22016L12.0001 13.6702L16.4401 9.22016C16.534 9.12551 16.6618 9.07227 16.7951 9.07227C16.9284 9.07227 17.0562 9.12551 17.1501 9.22016L17.8501 9.93016C17.9448 10.024 17.998 10.1518 17.998 10.2852C17.998 10.4185 17.9448 10.5463 17.8501 10.6402L12.7201 15.7802Z" />
    </svg>
  );
}
