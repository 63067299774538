import {
  RouteObject,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { clientRoutes } from '@pages/Client/routes';
import { authRoutes } from '@pages/Auth/routes';
import { adminRoutes } from '@pages/Admin/routes';
import { ReactNode, useEffect } from 'react';
import { getEnvVar } from '@common/utils/env';
import { SnapError } from '@common/components/molecules/SnapError/SnapError';
import { designSystemRoutes } from '@pages/DesignSystem/routes';
import { clerkRoutes } from '@pages/Auth/Clerk/routes/clerk-routes';

Sentry.init({
  environment: getEnvVar('VITE_SENTRY_ENVIRONMENT'),
  allowUrls: ['localhost:3000', /https?:\/\/((app|staging|devreapp)\.)?roompricegenie\.com/],
  ignoreErrors: [
    'Error: A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator. To fix, updates that suspend should be wrapped with startTransition.',
    /**
     * Thrown when firefox prevents an add-on from referencing a DOM element that has been removed.
     * This can also be filtered by enabling the browser extension inbound filter
     */
    "TypeError: can't access dead object",
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node."
  ],
  dsn: getEnvVar('VITE_SENTRY_DSN'),
  tunnel: '/tunnel/',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  enabled: !import.meta.env.DEV,
  tracesSampleRate: 0.2
});

type RouteObjectWithErrorElement = RouteObject & { errorElement?: ReactNode };

const addErrorElement = (routes: RouteObject[]): RouteObjectWithErrorElement[] => {
  return routes.map((route) => {
    // if route has children, recursively call addErrorElement
    if (route.children) {
      return {
        ...route,
        errorElement: <SnapError isLogout />,
        children: addErrorElement(route.children)
      };
    }

    // if route doesn't have children, add errorElement
    return {
      ...route,
      errorElement: <SnapError />
    };
  });
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter([
  ...addErrorElement(authRoutes),
  ...addErrorElement(clerkRoutes),
  ...addErrorElement(clientRoutes),
  ...addErrorElement(adminRoutes),
  ...addErrorElement(designSystemRoutes)
]);
