import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useCallback, useRef, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { ChartValue } from '../../../types/chart';
import { formattedCurrency } from '../../../utils/formatedCurrency';

type DataKey = 'value';

interface DataProps {
  data: ChartValue[];
  height?: number;
  isMonthly?: boolean;
}

export const AirbnbChart = ({ data, isMonthly }: DataProps) => {
  const grey = useTailwindColor('grey');
  const green = useTailwindColor('uiGreen');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '10px';
  const fontWeight = 400;

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    value: true
  });

  const lastLabelDisplayedRef = useRef('');

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white" className="bg-opacity-90 backdrop-blur">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dayjs(dataPoint.date).format('ddd, DD MMM YYYY')}
        </Typography>
        <div className="flex items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-uiGreen" />
          <Typography variant="meta-2" color="darkGrey">
            Airbnb Price: <b>{formattedCurrency(dataPoint.value)}</b>
          </Typography>
        </div>
      </Card>
    );
  };

  const customLegendPayload = [
    {
      dataKey: 'value',
      value: 'Airbnb Market Price',
      color: green
    }
  ];

  const values = data?.map((item) => item.value as number) ?? [];
  const dataMax = Math.floor(Math.max(...values) * 1.1);
  const dataMin = Math.floor(Math.min(...values) * 0.9);

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;
    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    return (
      <ul className="mb-8 flex list-none justify-center">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => (
            <li
              key={`item-${index}`}
              className="mr-2 flex cursor-pointer items-center"
              onClick={() => handleLegendClick(entry.dataKey)}>
              <div
                className="mr-1 h-3 w-3 rounded-full"
                style={{
                  backgroundColor: entry.color,
                  opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                }}
              />
              <Typography
                color="copyTextGrey"
                variant="meta-2"
                className={clsx(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                {entry.value}
              </Typography>
            </li>
          )
        )}
      </ul>
    );
  };

  const xAxisTickFormatter = useCallback((value: any) => {
    let currentLabel;

    if (isMonthly) {
      currentLabel = dayjs(value).tz().format('DD');
    } else {
      currentLabel = dayjs(value).tz().format('MMM YYYY');
    }

    if (currentLabel === lastLabelDisplayedRef.current) {
      return '';
    }

    lastLabelDisplayedRef.current = currentLabel;
    return currentLabel;
  }, []);

  return (
    <Card backgroundColor="white" className="p-0 shadow-none">
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          data={data}
          style={{
            hover: {
              cursor: 'pointer'
            }
          }}>
          <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
          <Tooltip content={(props) => <CustomTooltip {...props} />} />
          <Legend
            verticalAlign="top"
            content={(props) => <CustomLegend {...props} payload={customLegendPayload} />}
          />
          <XAxis
            axisLine={false}
            tick={{ fill: grey }}
            tickLine={false}
            fontFamily={fontFamily}
            tickFormatter={xAxisTickFormatter}
            interval={isMonthly ? 0 : 'preserveEnd'}
            fontSize={fontSize}
            fontWeight={fontWeight}
            dataKey={'date'}
          />
          <YAxis
            axisLine={false}
            tick={{ fill: grey }}
            tickCount={isMonthly ? 10 : 12}
            tickLine={false}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            domain={[dataMin, dataMax]}
            dataKey={'value'}
            allowDataOverflow={true}
            tickFormatter={(value) => formattedCurrency(value)}
          />

          <Line
            type="monotone"
            dataKey="value"
            strokeWidth={2}
            stroke={green}
            dot={false}
            visibility={seriesVisibility.value ? 'visible' : 'hidden'}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};
