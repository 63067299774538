import { ErrorResponse, getOTP, OTPResponse, verifyOTP, VerifyOTPResponse } from '@common/api/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useGetOTP = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);

  return useMutation<OTPResponse, ErrorResponse>({
    mutationFn: getOTP,
    onSuccess: (data) => {
      if (data.success) {
        addNotification('success', 'Send OTP Success!');
      } else {
        addNotification('fail', 'Send OTP Failed!');
      }
    },
    onError: (error) => {
      addNotification('fail', 'Send OTP Failed!');
      if (error?.non_field_errors) {
        addNotification('fail', error?.non_field_errors[0]);
      }
    }
  });
};

export const useVerifyOTP = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);

  return useMutation<VerifyOTPResponse, ErrorResponse, string>({
    mutationFn: verifyOTP,
    onSuccess: (data) => {
      if (data.success) {
        addNotification('success', 'Verify OTP Success!');
      } else {
        addNotification('fail', 'Verify OTP Failed!');
      }
    },
    onError: (error) => {
      addNotification('fail', 'Verify OTP Failed!');
      if (error?.non_field_errors) {
        addNotification('fail', error?.non_field_errors[0]);
      }
    }
  });
};
