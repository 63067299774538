import { AssignedPayload, UpdateUserPayload } from '@common/api/account/types';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { LANGUAGES } from '@common/constants/language';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { getRoleString } from '@pages/Client/Account/User/utils/getRole';
import {
  useAddAdminAssign,
  useAddManagerAssign,
  useAddStaffAssign,
  useDeleteAdminAssign,
  useDeleteManagerAssign,
  useDeleteStaffAssign,
  useUpdateAdmin,
  useUpdateManager,
  useUpdateStaff
} from '@pages/Client/Account/User/hooks/useUser';
import { Button } from '@common/components/atoms/Button';
import { map } from 'lodash-es';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';
import { Checkbox } from '@mantine/core';

interface Hotel {
  id: number;
  hotel: {
    id: number;
    name: string;
    state: number;
    is_client_access_disabled: boolean;
    address: string;
    lat: string;
    lng: string;
  };
  level: number;
}

interface SelectedUser {
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  id: number;
  type: string;
  is_read_only: boolean;
  assigned_hotels: string;
  hotel_list: Hotel[];
  pending: boolean;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  selectedUser?: SelectedUser | undefined;
};

interface HotelAccessObject {
  [key: string]: string;
}
interface HotelList {
  id: number;
  name: string;
  state: number;
  is_client_access_disabled: boolean;
}

export const EditUserModal = ({ isOpen, onClose, selectedUser }: Props) => {
  const { t } = useTranslation();
  const { data: hotelData } = useGetAccountHotel();

  const [addedItems, setAddedItems] = useState<number[]>([]);
  const [removedItems, setRemovedItems] = useState<number[]>([]);

  const schema = z.object({
    email: z.string().email({ message: `${t('Please enter a valid email address')}` }),
    first_name: z.string().nonempty({ message: `${t('Please enter  first name')}` }),
    last_name: z.string().nonempty({ message: `${t('Please enter last name')}` }),
    language: z.string().nonempty({ message: `${t('Please enter a valid email address')}` }),
    property_list: z.array(z.number()),
    is_read_only: z.boolean(),
    type: z.string().nonempty()
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isValid },
    clearErrors,
    reset
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { mutate: updateAdminUser } = useUpdateAdmin();
  const { mutate: updateStaffUser } = useUpdateStaff();
  const { mutate: updateManagerUser } = useUpdateManager();

  const { mutate: addAdminAssign } = useAddAdminAssign(addedItems);
  const { mutate: addStaffAssign } = useAddStaffAssign(addedItems);
  const { mutate: addManagerAssign } = useAddManagerAssign(addedItems);

  const { mutate: deleteAdminAssign } = useDeleteAdminAssign(removedItems);
  const { mutate: deleteStaffAssign } = useDeleteStaffAssign(removedItems);
  const { mutate: deleteManagerAssign } = useDeleteManagerAssign(removedItems);

  const hotel_list = hotelData?.hotel_list ?? [];

  const hotelList = map(hotel_list, (item: HotelList) => ({
    label: item.name,
    value: item.id
  }));

  const sortHotelListAlphabetically = (hotelList: { label: string; value: number }[]) => {
    return hotelList.sort((a, b) => a.label.localeCompare(b.label));
  };

  const sortedHotelList = sortHotelListAlphabetically(hotelList);

  const ROLE_OPTIONS = [
    {
      label: t('Admin'),
      value: '3'
    },
    {
      label: t('Manager'),
      value: '1'
    },
    {
      label: t('Staff'),
      value: '2'
    }
  ];

  const reMapHotelAccess = (arr: number[]): HotelAccessObject => {
    const result = arr.reduce((acc, id) => {
      const { label } = sortedHotelList.find((item) => item.value === id) || {};
      return label ? { ...acc, [id.toString()]: label } : acc;
    }, {} as HotelAccessObject);
    return result;
  };

  const defaultHotelSelected =
    selectedUser?.hotel_list && Array.isArray(selectedUser.hotel_list)
      ? selectedUser.hotel_list.map((item) => item.hotel.id)
      : [];

  const findAssignIds = (hotelIds: number[]) => {
    const assignedHotels = selectedUser?.hotel_list ?? [];
    if (!Array.isArray(assignedHotels)) {
      return [];
    }
    const assignedIds = assignedHotels
      ?.filter((assignedHotel: Hotel) => hotelIds.includes(assignedHotel.hotel.id))
      .map((assignedHotel: Hotel) => assignedHotel.id);
    return assignedIds;
  };

  useEffect(() => {
    setValue('property_list', defaultHotelSelected);
    setValue('type', getRoleString(selectedUser?.type as string));
    setValue('language', selectedUser?.language || 'en-GB');
    setValue('email', selectedUser?.email as string);
    setValue('first_name', selectedUser?.first_name as string);
    setValue('last_name', selectedUser?.last_name as string);
    setValue('is_read_only', selectedUser?.is_read_only || false);
  }, [selectedUser]);

  useEffect(() => {
    const addedItemsData = watch('property_list')?.filter(
      (id) => !defaultHotelSelected?.includes(id)
    );
    const removedItemsData = defaultHotelSelected?.filter(
      (id) => !watch('property_list')?.includes(id)
    );
    setAddedItems(addedItemsData);
    setRemovedItems(removedItemsData);
  }, [watch('property_list')]);

  const updateUser = (id: number | undefined, user: UpdateUserPayload, userId: AssignedPayload) => {
    if (selectedUser?.type === 'Admin') {
      updateAdminUser([id, user]);
      if (addedItems.length > 0) {
        addAdminAssign(userId);
      }
      if (removedItems.length > 0) {
        deleteAdminAssign(findAssignIds(removedItems));
      }
    }
    if (selectedUser?.type === 'Manager') {
      updateManagerUser([id, user]);
      if (addedItems.length > 0) {
        addManagerAssign(userId);
      }
      if (removedItems.length > 0) {
        deleteManagerAssign(findAssignIds(removedItems));
      }
    }
    if (selectedUser?.type === 'Staff') {
      updateStaffUser([id, user]);
      if (addedItems.length > 0) {
        addStaffAssign(userId);
      }
      if (removedItems.length > 0) {
        deleteStaffAssign(findAssignIds(removedItems));
      }
    }
  };

  const onUpdateUser = (data: any) => {
    const updateUserData = {
      ...data,
      property_list: reMapHotelAccess(data.property_list)
    };
    if (selectedUser?.id) {
      updateUser(selectedUser?.id, updateUserData, { user: selectedUser?.id });
    }
    onClose();
  };

  const handleCloseModal = () => {
    onClose();
    clearErrors();
    reset();
  };

  return (
    <Modal
      open={isOpen}
      disableCloseOnOk={!isValid}
      showFooter={false}
      onClose={handleCloseModal}
      size="custom"
    >
      <div className="min-w-[480px] max-w-[480px] rounded-xl bg-white">
        <div className="relative flex flex-col items-start gap-4 p-5">
          <Button
            type="button"
            icon
            className="absolute right-6 top-6 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
            onClick={() => handleCloseModal()}
          >
            <Icon.Clear className="h-5 w-5" />
          </Button>
          <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
            {`${t('Edit')}: ${selectedUser?.first_name} ${selectedUser?.last_name}`}
          </Typography>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                optionsWrapperClassName="grid grid-cols-3 gap-x-4 gap-y-2"
                value={value}
                disabled
                onChange={onChange}
                options={ROLE_OPTIONS}
              />
            )}
          />
          <div className="flex w-full flex-col items-start justify-between gap-4">
            <div className="w-full items-start">
              <div>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                    <Input
                      disabled={false}
                      type="email"
                      background="grey"
                      tabIndex={1}
                      label={`${t('User Email')}*`}
                      placeholder={`${t('Please enter Email')}`}
                      value={value || ''}
                      error={invalid}
                      helperMessage={
                        invalid && (
                          <InputHelperMessage
                            icon={
                              error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                            }
                            message={error?.message}
                          />
                        )
                      }
                      onChange={onChange}
                      showClearButton={false}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full items-start justify-between gap-4">
            <div className="w-1/2 flex-1 items-start">
              <div>
                <Controller
                  control={control}
                  name="first_name"
                  render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                    <Input
                      disabled={false}
                      type="text"
                      background="grey"
                      tabIndex={1}
                      label={`${t('First Name')}*`}
                      placeholder={`${t('Please enter First Name')}`}
                      value={value || ''}
                      error={invalid}
                      helperMessage={
                        invalid && (
                          <InputHelperMessage
                            icon={
                              error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                            }
                            message={error?.message}
                          />
                        )
                      }
                      onChange={onChange}
                      showClearButton={false}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-1/2 flex-1 items-start">
              <div>
                <Controller
                  control={control}
                  name="last_name"
                  render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                    <Input
                      disabled={false}
                      type="text"
                      background="grey"
                      tabIndex={1}
                      label={`${t('Last Name')}*`}
                      placeholder={`${t('Please enter Last Name')}`}
                      value={value || ''}
                      error={invalid}
                      helperMessage={
                        invalid && (
                          <InputHelperMessage
                            icon={
                              error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                            }
                            message={error?.message}
                          />
                        )
                      }
                      onChange={onChange}
                      showClearButton={false}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex-1 items-start">
            <Typography element="p" variant="meta-1" className="mb-1 text-grey">
              {t('Language')} *
            </Typography>
            <Controller
              name="language"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    fullWidth
                    name="language"
                    placeholder="Select Language"
                    options={LANGUAGES}
                    background="grey"
                    value={value}
                    onChange={onChange}
                  />
                  <div className="mt-1">
                    {error && (
                      <div className="ml-2 flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="property_list"
              control={control}
              render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                <>
                  <Typography element="p" variant="meta-1" className="text-grey">
                    {t('Property Access')}*
                  </Typography>
                  <MultiSelect
                    fullWidth
                    isInitialValue
                    background="grey"
                    inputClassName="py-[14px]"
                    name={name}
                    placeholder={`${t('Select Property Access')}`}
                    value={value}
                    onChange={onChange}
                    options={sortedHotelList}
                  />
                  <div className="mt-1">
                    {error && (
                      <div className="ml-2 flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
          {watch('type') === '2' ? (
            <div className="w-full">
              <Controller
                name="is_read_only"
                control={control}
                render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                  <Checkbox
                    name={name}
                    checked={value}
                    onChange={onChange}
                    label={t('Read Only')}
                    error={error?.message}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => handleCloseModal()}
              className="text-copyTextGrey"
            >
              {t('Cancel')}
            </Button>
            <Button type="button" intent="primary" onClick={handleSubmit(onUpdateUser)}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
