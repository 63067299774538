import { cva, VariantProps } from 'class-variance-authority';
import { Icon } from '@common/components/foundations/icons';
import styles from '@common/components/atoms/Input/Input.module.css';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { isNil } from 'lodash-es';
import { Button } from '@common/components/atoms/Button';
import { cn } from '@common/utils/cn';
import clsx from 'clsx';
import { Typography } from '@common/components/foundations/Typography';

export const inputStyles = cva(styles.inputBase, {
  variants: {
    background: {
      white: 'bg-white',
      grey: 'bg-lightGrey'
    },
    active: {
      true: '',
      false: ''
    },
    error: {
      true: ''
    }
  },
  compoundVariants: [
    {
      active: true,
      error: true,
      className:
        'border-error border hover:ring-error hover:ring-opacity-20 hover:ring-offset-0 hover:ring active:border-error'
    },
    {
      active: false,
      error: true,
      className:
        'border-error border hover:ring-error hover:ring-opacity-20 hover:ring-offset-0 hover:ring active:border-error'
    },
    {
      active: true,
      error: false,
      className:
        'border-mediumGrey border hover:ring-indigo hover:ring-opacity-20 hover:ring-offset-0 hover:ring active:border-indigo'
    },
    {
      active: false,
      error: false,
      className:
        'border-transparent border hover:ring-indigo hover:ring-opacity-20 hover:ring-offset-0 hover:ring active:border-indigo'
    }
  ],
  defaultVariants: {
    background: 'white',
    active: false,
    error: false
  }
});

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  type?: 'text' | 'password' | 'email' | 'number' | 'date';
  background?: 'white' | 'grey';
  showClearButton?: boolean;
  onClear?: () => void;
  leadingAddon?: React.ReactNode;
  trailingAddon?: React.ReactNode;
  label?: string;
  subLabel?: string;
  helperMessage?: React.ReactNode;
  error?: boolean;

  wrapperClassName?: string;
} & VariantProps<typeof inputStyles>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      id,
      value = '',
      name,
      leadingAddon,
      trailingAddon,
      label,
      subLabel,
      helperMessage,
      error,
      disabled,
      showClearButton = true,
      onClear,
      onChange,
      background,
      className,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(disabled ? 'opacity-40' : null, error && styles.hasError, wrapperClassName)}>
        {label ? (
          <label htmlFor={id} className={clsx(label ? 'block text-meta-1 text-grey' : 'hidden')}>
            {label}
            <Typography element="div" variant="meta-2" color="grey">
              {subLabel ? subLabel : null}
            </Typography>
          </label>
        ) : null}

        <div className={cn('relative rounded-md', label && 'mt-1')}>
          {leadingAddon ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5 text-meta-2 text-copyTextGrey">
              {leadingAddon}
            </div>
          ) : null}
          <input
            ref={ref}
            type={type}
            value={value}
            name={name}
            id={id}
            onChange={onChange}
            className={cn(
              inputStyles({ active: !!value || value === 0, error, background }),
              trailingAddon && styles.hasTrailingAddon,
              leadingAddon && styles.hasLeadingAddon,
              className
            )}
            aria-describedby={id}
            disabled={disabled}
            {...props}
          />
          {showClearButton && (value === 0 || value) ? (
            <div
              className={cn(
                'absolute inset-y-0 right-0 flex items-center',
                trailingAddon ? 'pr-8' : 'pr-3'
              )}>
              <Button icon title="Clear Button" onClick={onClear} type="button">
                <Icon.Clear className="h-3 w-3" />
              </Button>
            </div>
          ) : null}
          {trailingAddon ? (
            <>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-meta-2 text-copyTextGrey">
                {trailingAddon}
              </div>
            </>
          ) : null}
        </div>
        {helperMessage ? <div className={styles.helperMessage}>{helperMessage}</div> : null}
      </div>
    );
  }
);

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function FormInput({
  type,
  valueKey,
  nameKey,
  leadingAddon,
  trailingAddon,
  label,
  helperMessage,
  disabled,
  background,
  className,
  wrapperClassName
}: any) {
  const { register } = useFormContext();
  const error = !isNil(helperMessage);
  return (
    <div className={cn(disabled ? 'opacity-40' : null, error && styles.hasError, wrapperClassName)}>
      {label ? (
        <label htmlFor={nameKey} className={cn(label ? 'block text-meta-1 text-grey' : 'hidden')}>
          {label}
        </label>
      ) : null}
      <div className={cn('relative rounded-md', label && 'mt-1')}>
        {leadingAddon ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5 text-meta-2 text-copyTextGrey">
            {leadingAddon}
          </div>
        ) : null}
        <input
          id={nameKey}
          type={type}
          {...register(valueKey, { valueAsNumber: type === 'number' })}
          className={cn(
            inputStyles({ active: true, error, background }),
            trailingAddon && styles.hasTrailingAddon,
            leadingAddon && styles.hasLeadingAddon,
            className
          )}
        />
        {trailingAddon ? (
          <>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-meta-2 text-copyTextGrey">
              {trailingAddon}
            </div>
          </>
        ) : null}
      </div>
      {helperMessage ? <div className={styles.helperMessage}>{helperMessage}</div> : null}
    </div>
  );
}
