import { Modal } from '@common/components/molecules/Modal';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { useTranslation } from 'react-i18next';
import { useDisconnectSubscription } from '@pages/Client/Account/Subscription/hooks/useDisconnectSubscription';
import { Button } from '@common/components/atoms/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DisconnectSubscriptionModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { mutate: disconnectSubscription } = useDisconnectSubscription();

  const handleDisconnect = () => {
    disconnectSubscription();
    onClose();
  };

  return (
    <Modal
      showFooter={false}
      size="custom"
      open={isOpen}
      okText={`${t('Disconnect')}`}
      onClose={onClose}>
      <div className="relative min-w-[400px] max-w-[400px] rounded-xl bg-white">
        <Button
          type="button"
          icon
          className="absolute right-6 top-6 z-30 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={onClose}>
          <Icon.Clear className="h-5 w-5" />
        </Button>
        <div className="p-5">
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-error p-3">
                <Icon.Delete color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t(
                'Are you sure you want to disconnect your subscription? This action cannot be undone.'
              )}
            </Typography>
          </div>
        </div>

        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => onClose()}
              className="text-copyTextGrey">
              {t('Cancel')}
            </Button>
            <Button type="button" intent="danger" onClick={handleDisconnect}>
              {t('Disconnect')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
