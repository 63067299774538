import { scrapeCompetitor } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useScrapeTopTwenty = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (competitor: boolean) => {
      return scrapeCompetitor({
        top_20: competitor
      });
    },
    onSuccess: (_result, variables) => {
      variables
        ? addNotification('success', 'Top 20 scrape started. This will take 15-20 minutes.')
        : addNotification('success', 'Scrape started for newly added competitors');
    }
  });

  return {
    scrapeTopTwenty: mutate,
    isLoading
  };
};
