import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useUpdateAdminUser } from '@pages/Admin/RpgUsers/hooks/useUpdateAdminUser';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  selectedUser?: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    is_sales: boolean;
    sub_account_type: number | null;
  };
};

const schema = z.object({
  token: z.string().or(z.undefined()).nullable(),
  id: z.number().or(z.string()).nullable().optional(),
  username: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
  is_sales: z.boolean().optional(),
  sub_account_type: z.number().or(z.string()).nullable().optional()
});

export const UpdateAdminUserModal = ({ isOpen, onClose, selectedUser }: ModalProps) => {
  const { token } = useAuthStore();
  const { updateAdminUser } = useUpdateAdminUser();

  const { control, reset, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (selectedUser) {
      reset({
        username: selectedUser?.email,
        ...selectedUser,
        is_sales: selectedUser?.sub_account_type === 1 ? true : false
      });
    }
  }, [selectedUser]);

  const onSubmit = (data: any) => {
    if (selectedUser?.id) {
      const payloadData = {
        payload: {
          token,
          ...data
        },
        id: selectedUser?.id
      };
      updateAdminUser(payloadData);
    }
  };

  return (
    <Modal
      okText="Save"
      continueIntent="primary"
      size="xl"
      open={isOpen}
      disableCloseOnOk={!formState.isValid}
      onClick={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        // reset();
      }}
      children={
        <div>
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            Edit Admin User : {selectedUser?.first_name} {selectedUser?.last_name}
          </Typography>
          <div className="mb-4 flex w-full justify-between gap-4 ">
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="text"
                    value={value}
                    label="First Name *"
                    placeholder="Please enter first name"
                    onChange={onChange}
                    onClear={() => reset({ first_name: '' })}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
            </div>
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <Input
                    name={name}
                    background="grey"
                    type="text"
                    value={value}
                    label="Last Name *"
                    placeholder="Please enter last name"
                    onChange={onChange}
                    onClear={() => reset({ last_name: '' })}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Controller
              name="username"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  name="subject"
                  background="grey"
                  type="email"
                  value={value}
                  label="Email *"
                  placeholder="Please enter email"
                  onChange={onChange}
                  onClear={() => reset({ username: '' })}
                  error={!!error}
                  helperMessage={
                    error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )
                  }
                />
              )}
            />
          </div>
          <div className="mt-5 flex items-center gap-2">
            <Controller
              name="sub_account_type"
              control={control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    name={name}
                    fullWidth
                    value={value}
                    placeholder="Select Team"
                    hint="Team"
                    options={[
                      { label: 'Not Selected', value: null },
                      { label: 'Sales ', value: 1 },
                      { label: 'Support ', value: 3 }
                    ]}
                    onChange={(value) => onChange(value)}
                  />
                  <div className="mt-1">
                    {error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error?.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </div>
      }
    />
  );
};
