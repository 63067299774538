import { ErrorResponse } from '@common/api/auth';
import { getBudget } from '@common/api/dashboard';
import { BudgetResult } from '@common/api/dashboard/types';
import { useDate } from '@common/hooks/useDate';
import { useNotificationsStore } from '@common/store/notifications';
import { BudgetQueryKeys } from '@common/types/query-keys';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useBudget = (supportMultipleHotels = false) => {
  const { hotelDetails } = useHotelDetails();
  const { propertyIds } = useDashboardPageStore();
  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();
  const { next12Months } = useDate();

  const query = useQuery<BudgetResult, ErrorResponse>({
    queryKey: [BudgetQueryKeys.GET_BUDGET, propertyIds, supportMultipleHotels],
    queryFn: () =>
      hotelDetails
        ? getBudget({
            ...(supportMultipleHotels && !isEmpty(propertyIds?.[hotelDetails.id])
              ? {
                  filter_hotels: propertyIds?.[hotelDetails.id]
                }
              : {})
          })
        : Promise.reject('No hotel ID'),
    enabled: !!hotelDetails?.id
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', t('Failed to fetch budget data'));
    }
  }, [query.isError]);

  const budget = useMemo(() => {
    return query.data;
  }, [query.data]);

  const isNext12MonthsBudgetFull = () => {
    if (!budget) return false;

    const keysToCheck = ['revenue_budget_gross_excl_services', 'revenue_budget_net_excl_services'];

    for (const key of keysToCheck) {
      const hasFullValues = next12Months.every((month) => {
        const value = budget[key as keyof BudgetResult]?.[month];
        return !!value;
      });
      // If all 12 months have values for the current key, return true
      if (hasFullValues) return true;
    }
    // If neither key has complete values for all months, return false
    return false;
  };

  return { budget, query, isNext12MonthsBudgetFull };
};
