import { useClientListStore } from '@common/store/hotel-list-store';
import { ComboboxItem, Select } from '@mantine/core';
import { useGetProviderList } from '@pages/Client/hooks/useProviderConfig';
import { isNil, find } from 'lodash-es';

export function FiltersPMSSelect() {
  const paramKey = 'pms_provider';
  const openApiParamKey = 'open_api_partner_id';
  const { params, setParams, setFilters } = useClientListStore();
  const { PROVIDER_OPTION } = useGetProviderList();

  function handleChange(option: ComboboxItem) {
    if (isNil(option)) {
      setFilters({ [paramKey]: undefined, [openApiParamKey]: undefined });
      setParams({ [paramKey]: undefined, [openApiParamKey]: undefined });
      return;
    }
    if (option.value.startsWith('O-')) {
      setFilters({ [openApiParamKey]: isNil(option) ? undefined : option.label });
      setParams({ [openApiParamKey]: isNil(option) ? undefined : Number(option.value.slice(2)) });
    } else {
      setFilters({ [paramKey]: isNil(option) ? undefined : option.label });
      setParams({ [paramKey]: isNil(option) ? undefined : Number(option.value) });
    }
  }

  const selectedValue =
    find(PROVIDER_OPTION, {
      value: String(params[openApiParamKey] ? `O-${params[openApiParamKey]}` : params[paramKey])
    })?.value || null;

  return (
    <Select
      searchable
      clearable
      variant="filled"
      label="PMS/CM"
      placeholder="All"
      value={selectedValue}
      data={PROVIDER_OPTION}
      onChange={(_value, option) => handleChange(option)}
      comboboxProps={{ withinPortal: false }}
    />
  );
}
