import { PricingSettings } from '@common/api/hotel/types';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { map, isNaN } from 'lodash-es';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { PercentAdjustment } from '@pages/Client/Calendar/components/BulkEdit/types/schema/percentageAdjustmentSchema';

/**
 * Rules for setting and deleting the percentage_adjustment key:
 *
 * - Set percentage_adjustment Rule:
 *   - Condition:
 *     - The value is greater or less than 0
 *   - Action:
 *     - Set the percentage_adjustment key (as specified by the `actions.setPercentKey` event)
 *
 * - Delete percentage_adjustment Rule:
 *   - Condition:
 *     - `isBulkEdit` is true
 *     - The value is exactly 0
 *   - Action:
 *     - Delete the percentage_adjustment key (as specified by the `actions.deletePercentKey` event)
 *
 *   - Condition:
 *     - `isBulkEdit` is false
 *     - The value is 0, null, or NaN
 *   - Action:
 *     - Delete the percentage_adjustment key (as specified by the `actions.deletePercentKey` event)
 */

const actions = { setPercentKey: 'setPercentKey', deletePercentKey: 'deletePercentKey' };
const deletePercentKey = new Rule({
  conditions: {
    any: [
      {
        all: [
          { fact: 'isBulkEdit', operator: 'equal', value: true },
          { fact: 'value', operator: 'equal', value: 0 }
        ]
      },
      {
        all: [
          { fact: 'isBulkEdit', operator: 'equal', value: false },
          {
            any: [
              { fact: 'value', operator: 'equal', value: 0 },
              { fact: 'value', operator: 'equal', value: null },
              { fact: 'value', operator: 'isNaNOperator', value: true }
            ]
          }
        ]
      }
    ]
  },
  event: { type: actions.deletePercentKey }
});

const setPercentKey = new Rule({
  conditions: {
    any: [
      { fact: 'value', operator: 'greaterThan', value: 0 },
      { fact: 'value', operator: 'lessThan', value: 0 }
    ]
  },
  event: { type: actions.setPercentKey }
});

export async function transformPercentAdjustment(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Record<string, PercentAdjustment>,
  date: string
) {
  const engine = new Engine();
  engine.addOperator('isNaNOperator', (factValue) => isNaN(factValue));
  engine.addRule(setPercentKey);
  engine.addRule(deletePercentKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('id') as Promise<string>).then((id: string) => {
      if (event.type === actions.setPercentKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][id]) currentPricingDraft.dates[date][id] = {};
        currentPricingDraft.dates[date][id][ADJUSTMENT_DB.PERCENT_KEY] = formDataById[id]
          .value as number;
      }
      if (event.type === actions.deletePercentKey) {
        delete currentPricingDraft.dates[date]?.[id]?.[ADJUSTMENT_DB.PERCENT_KEY];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (item: PercentAdjustment) => {
      await engine.run(item);
    })
  );

  return;
}
