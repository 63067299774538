export const CALENDAR_PAGES = {
  OCCUPANCY: 'occupancy',
  PRICING: 'pricing',
  PICKUP: 'pickup',
  REVENUE: 'revenue'
};

export const TABLE_VIEW_ROOM_FIELDS = {
  ROOMS_LEFT: 'roomsLeft',
  RECOMMENDED_PRICE: 'recommendedPrice',
  PRICE_IN_PMS: 'priceInPMS'
};

export const TABLE_VIEW_PROPERTY_FIELDS = {
  OCCUPANCY: 'occupancy',
  ROOMS_LEFT: 'roomsLeft',
  CLOSED_ROOMS: 'closedRooms'
};

export const NO_MARKET_DATA = 'No Market Data';

export const PRICE_COMPARE_OPTIONS = [
  {
    value: 'pms-price',
    label: 'Price in PMS'
  },
  {
    value: 'yesterday-price',
    label: "Yesterday's Price"
  },
  {
    value: 'last-week-price',
    label: "Last Week's Price"
  }
] as const;

export const ANALYTICS_COMPARE_OPTIONS = [
  {
    value: 'ly',
    label: 'Last Year'
  },
  {
    value: 'contribution-in-pct',
    label: 'Contribution in %'
  }
] as const;

export const OCCUPANCY_TYPE_OPTIONS = [
  {
    value: 'rooms_sold',
    label: 'Rooms Sold'
  },
  {
    value: 'pct',
    label: '%'
  }
] as const;

export type PRICE_COMPARE_TYPE = (typeof PRICE_COMPARE_OPTIONS)[number]['value'] | undefined;

export const PICKUP_COMPARE_OPTIONS = [
  {
    value: 'yesterday',
    label: 'Yesterday'
  },
  {
    value: 'last-week',
    label: 'Last Week'
  },
  {
    value: 'last-month',
    label: 'Last Month'
  }
] as const;

export type PICKUP_COMPARE_TYPE = (typeof PICKUP_COMPARE_OPTIONS)[number]['value'];

export const ADJUSTMENT_OPTIONS = (spaceName: string | undefined = 'Room', isAllRoom = true) => {
  const adjustmentOptions = [
    {
      value: AdjustmentType.Percent,
      label: 'Percent Adjustments'
    },
    {
      value: AdjustmentType.MinMax,
      label: 'Minimum and Maximum Prices'
    },
    {
      value: AdjustmentType.FixPrices,
      label: 'Fix Prices'
    },
    {
      value: AdjustmentType.Closed,
      label: `Closed ${spaceName}s`
    },
    {
      value: AdjustmentType.Occupancy,
      label: 'Occupancy Pricing'
    },
    {
      value: AdjustmentType.Aggressiveness,
      label: 'Aggressiveness'
    },
    {
      value: AdjustmentType.Median,
      label: 'Median Booking Window'
    },
    {
      value: AdjustmentType.MinStay,
      label: 'Min Stay Restrictions'
    }
  ];

  if (isAllRoom) {
    adjustmentOptions.splice(2, 0, {
      value: AdjustmentType.Derivation,
      label: `${spaceName} Derivation`
    });
  }

  return adjustmentOptions;
};

export enum AdjustmentType {
  Percent = 1,
  MinMax = 2,
  Derivation = 3,
  FixPrices = 4,
  Closed = 5,
  Occupancy = 6,
  Aggressiveness = 7,
  Median = 8,
  MinStay = 9,
  MinStaySkipRule = 10
}

export const MedianOptions = [
  {
    id: 1,
    label: 'Short',
    value: 14
  },
  {
    id: 2,
    label: 'Standard',
    value: 21
  },
  {
    id: 3,
    label: 'Long',
    value: 30
  }
];

export const ELASTICITY_LIST = [
  { id: 1, label: 'Ignore Occupancy Changes', value: -1000 },
  { id: 2, label: 'Very Low', value: -12 },
  { id: 3, label: 'Low', value: -8 },
  { id: 4, label: 'Standard', value: -5.5 },
  { id: 5, label: 'High', value: -4.25 },
  { id: 6, label: 'Very High', value: -3.25 }
];

export const shorterOccupancyList = ['Occupancy', 'Occ.'];

export const DISABLED_MAX_MARKET_LEVEL = 999999999;
