import { GetCachedRoomPricesResponse, Property } from '@common/api/roomPrices/types';
import { API_DATE_FORMAT } from '@common/constants/date';
import dayjs from 'dayjs';
import { get } from 'lodash-es';
import { max } from 'lodash-es';
import styles from '@pages/Client/Calendar/pages/Occupancy/Occupancy.module.css';

export const getOccupancyColor = (
  day: Date,
  roomPrices: GetCachedRoomPricesResponse | undefined
) => {
  const propertyData = getPropertyData(day, roomPrices);

  if (!propertyData) return { cellColorStyles: '', color: undefined };

  const roomsSold = max([0, propertyData.number_of_rooms - propertyData.inventory_remaining]);

  if (propertyData.exproomssold_u3 !== undefined && roomsSold !== undefined) {
    return {
      cellColorStyles: getOccupancyColorStyles(roomsSold, propertyData).styles,
      color: getOccupancyColorStyles(roomsSold, propertyData).color
    };
  }

  return {
    cellColorStyles: '',
    color: undefined
  };
};

const getPropertyData = (day: Date, roomPrices: GetCachedRoomPricesResponse | undefined) => {
  const dateKey = dayjs(day).startOf('day').format(API_DATE_FORMAT);
  return get(roomPrices, `prices.data[${dateKey}].property`, null);
};

const getOccupancyColorStyles = (
  roomsSold: number,
  propertyData: Property
): { styles: string; color: string } => {
  if (roomsSold > propertyData.exproomssold_u3 || roomsSold > propertyData.exproomssold_u2) {
    return {
      styles: styles.deepPurple,
      color: 'bg-indigo'
    };
  }
  if (roomsSold > propertyData.exproomssold_u1) {
    return { styles: styles.purple, color: 'bg-indigo bg-opacity-70' };
  }
  if (roomsSold < propertyData.exproomssold_l3 || roomsSold < propertyData.exproomssold_l2) {
    return { styles: styles.deepOrange, color: 'bg-orange' };
  }
  if (roomsSold < propertyData.exproomssold_l1) {
    return { styles: styles.orange, color: 'bg-orange bg-opacity-70' };
  }

  return {
    styles: '',
    color: 'bg-darkGreen'
  };
};
