export function CheckList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M19.85 7.24999L9.25 17.85C9.15611 17.9447 9.02832 17.9979 8.895 17.9979C8.76168 17.9979 8.63388 17.9447 8.54 17.85L3.15 12.46C3.05534 12.3661 3.0021 12.2383 3.0021 12.105C3.0021 11.9717 3.05534 11.8439 3.15 11.75L3.85 11.05C3.94388 10.9553 4.07168 10.9021 4.205 10.9021C4.33832 10.9021 4.46611 10.9553 4.56 11.05L8.89 15.38L18.44 5.82999C18.6378 5.63821 18.9522 5.63821 19.15 5.82999L19.85 6.53999C19.9447 6.63388 19.9979 6.76168 19.9979 6.89499C19.9979 7.02831 19.9447 7.15611 19.85 7.24999Z" />
    </svg>
  );
}
