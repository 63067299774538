import { ChangePassword, UpdateProfile } from '@common/api/account/types';
import { useAuthStore } from '@common/store/auth';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useLocation } from 'react-router-dom';
import { ProfileHeader } from '@pages/Client/Account/Profile/components/ProfileHeader';
import { useChangePassword, useUpdateProfile } from './hooks/useProfile';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  numberRegex,
  specialCharRegex,
  PasswordValidationMessage
} from '@common/constants/password';
import { useUserDetail } from '@pages/Client/Account/Profile/hooks/useUserDetail';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@mantine/hooks';

const userSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email()
});
const languageSchema = z.object({
  language: z.string()
});

const passwordSchema = z.object({
  old_password: z.string(),
  new_password: z
    .string()
    .min(8, PasswordValidationMessage.Length)
    .max(50)
    .refine((value) => /[A-Z]/.test(value), {
      message: PasswordValidationMessage.UppercaseLetter,
      path: ['new_password']
    })
    .refine((value) => numberRegex.test(value), {
      message: PasswordValidationMessage.Number,
      path: ['new_password']
    })
    .refine((value) => specialCharRegex.test(value), {
      message: PasswordValidationMessage.SpecialChar,
      path: ['new_password']
    })
});

const mergedSchema = z.object({
  ...userSchema.shape,
  ...languageSchema.shape,
  ...passwordSchema.shape
});

type FormValues =
  | z.infer<typeof passwordSchema>
  | z.infer<typeof languageSchema>
  | z.infer<typeof userSchema>
  | z.infer<typeof mergedSchema>;

export const ProfileLayout = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Profile'));
  const { trackEvent } = useEventTracking();
  const location = useLocation();
  const { token, user } = useAuthStore();
  const { detailUser } = useUserDetail(!user);
  const _detailUserData = detailUser;

  const {
    updateProfile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess
  } = useUpdateProfile();
  const {
    changePassword,
    isLoading: isChangePasswordLoading,
    isSuccess: isChangePasswordSuccess
  } = useChangePassword();
  const isPasswordPath = location.pathname.includes('password');
  const isPersonalInfoPath = location.pathname.includes('personal-information');
  const isLanguagePath = location.pathname.includes('language');
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  useEffect(() => {
    setIsSuccessRef({ current: isChangePasswordSuccess || isProfileSuccess });
    if (isChangePasswordSuccess || isProfileSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isChangePasswordSuccess, isProfileSuccess]);

  const getSchema = () => {
    if (isPasswordPath) {
      return passwordSchema;
    } else if (isPersonalInfoPath) {
      return userSchema;
    } else if (isLanguagePath) {
      return languageSchema;
    } else {
      return userSchema;
    }
  };

  const methods = useForm<FormValues>({
    resolver: zodResolver(getSchema())
  });

  return (
    <FormProvider {...methods}>
      <ProfileHeader
        disabled={isProfileLoading || isChangePasswordLoading}
        isLoading={isProfileLoading || isChangePasswordLoading}
        isSuccess={isSuccessRef.current}
        onClick={methods.handleSubmit((data: any) => {
          if (isPasswordPath) {
            const dataPassword = {
              token,
              old_password: methods.watch('old_password'),
              new_password: methods.watch('new_password')
            };
            changePassword(dataPassword as ChangePassword);
          } else {
            const dataProfile = data;
            updateProfile(dataProfile as UpdateProfile);
            trackEvent('ProfileUpdated');
          }
        })}
      />
      <div className="mt-4 max-w-6xl">
        <Outlet />
      </div>
    </FormProvider>
  );
};
