export const ArrowLongRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M1.99683 12.493V11.493C1.99683 11.2168 2.22075 10.993 2.49698 10.993H18.0018V8.99299C18.0046 8.79295 18.1264 8.61385 18.3114 8.53767C18.4965 8.4615 18.7091 8.50293 18.852 8.64299L21.8529 11.643C22.0448 11.8408 22.0448 12.1552 21.8529 12.353L18.852 15.353C18.7078 15.4943 18.4928 15.5351 18.3068 15.4563C18.1209 15.3776 18.0006 15.1948 18.0018 14.993V12.993H2.49698C2.22075 12.993 1.99683 12.7691 1.99683 12.493Z" />
    </svg>
  );
};
