import { getFutureDaily } from '@common/api/hotel';
import { FutureDailyResponse } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useFutureDaily = () => {
  const { addNotification } = useNotificationsStore();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: [UtilQueryKeys.GET_FUTURE_DAILY],
    queryFn: () => {
      return getFutureDaily();
    },
    refetchInterval
  });

  if (isError) addNotification('fail', 'Failed to get competitor score');

  useEffect(() => {
    const dataTotalRooms = data?.data as FutureDailyResponse;
    const interval = dataTotalRooms?.todays_total_rooms === 0 ? 12000 : false;
    setRefetchInterval(interval);
  }, [data]);

  return {
    futureDaily: data?.data as FutureDailyResponse,
    isLoading,
    refetch,
    error
  };
};
