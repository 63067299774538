import { Modal } from '@common/components/molecules/Modal';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { useDeletePmsRoomRate } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

interface SelectedRoomRate {
  id: number | string;
  rate_id: string;
  name: string;
  description: string | null;
  room_types?: any[];
  other_rate?: string | null;
  room_rate?: any[];
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedRoomRate: SelectedRoomRate;
};

export const DeleteRoomRatesModal = ({ selectedRoomRate, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { deleteRoomRate } = useDeletePmsRoomRate();
  const { hotelDetails } = useHotelDetails();
  const defaultRateId = selectedRoomRate?.room_rate?.map((item) => item.id);
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const onDelete = () => {
    if (!selectedRoomRate?.id) return;
    if (pmsProviderId === 15 || pmsProviderId === 22) {
      deleteRoomRate(defaultRateId as number[]);
    } else {
      deleteRoomRate([Number(selectedRoomRate?.id)]);
    }
    onClose();
  };

  return (
    <Modal
      onClick={onDelete}
      open={isOpen}
      okText={`${t('Delete')}`}
      onClose={onClose}
      size={'lg'}
      continueIntent="danger"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-error p-3">
                <Icon.Delete color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t(
                `Delete: ${
                  pmsProviderId === 15 || pmsProviderId === 22
                    ? selectedRoomRate?.rate_id
                    : selectedRoomRate?.name
                }`
              )}
            </Typography>
            <Typography>
              {t(`Are you sure you want to delete ${selectedRoomRate?.name} ?`)}
            </Typography>
          </div>
        </>
      }
    />
  );
};
