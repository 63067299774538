export const SettingsVs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.22 5.06L21 10.06C21.6692 11.2668 21.6692 12.7332 21 13.94L18.22 18.94C17.5146 20.2119 16.1744 21.0007 14.72 21H9.27999C7.82557 21.0007 6.48538 20.2119 5.77999 18.94L2.99999 13.94C2.33073 12.7332 2.33073 11.2668 2.99999 10.06L5.77999 5.06C6.48538 3.78809 7.82557 2.99929 9.27999 3H14.72C16.1744 2.99929 17.5146 3.78809 18.22 5.06ZM8.99999 12C8.99999 13.6569 10.3431 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 10.3431 13.6568 9 12 9C10.3431 9 8.99999 10.3431 8.99999 12Z"
      />
    </svg>
  );
};
