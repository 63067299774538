export const DEVELOPER_OPTIONS = [
  // Backend Developers
  { label: 'Bagas', value: 'bagas' },
  { label: 'Chirag', value: 'chirag' },
  { label: 'Hendrik', value: 'hendrik' },
  { label: 'Wahid', value: 'wahid' },
  { label: 'Paras', value: 'paras' },
  { label: 'Sari', value: 'sari' },
  // Support team
  { label: 'Nikhil', value: 'nikhil' },
  { label: 'Esther', value: 'esther' },
  { label: 'Melissa', value: 'melissa' },
  { label: 'Huda', value: 'huda' },
  { label: 'Bebeto', value: 'bebeto' }
];

// For the legacy endpoint, it will be replaced later
export const DASHBOARD_TICKET_DEVELOPER_OPTIONS = [
  { label: 'Bagas', value: 'bagas' },
  { label: 'Chirag', value: 'chirag' },
  { label: 'Hendrik', value: 'hendrik' },
  { label: 'Wahid', value: 'wahid' },
  { label: 'Mumtoz', value: 'mumtoz' },
  { label: 'Aji', value: 'aji' },
  { label: 'Dipak', value: 'dipak' },
  { label: 'Nilay', value: 'nilay' },
  { label: 'Rajesh', value: 'rajesh' },
  { label: 'Parth', value: 'parth' }
];
