export const ArrowLongDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M12.5 1.99683C12.7762 1.99683 13 2.22075 13 2.49698V18.0018H15C15.2001 18.0046 15.3792 18.1264 15.4553 18.3114C15.5315 18.4965 15.4901 18.7091 15.35 18.852L12.35 21.8529C12.1522 22.0448 11.8378 22.0448 11.64 21.8529L8.64002 18.852C8.49872 18.7078 8.45795 18.4928 8.53667 18.3068C8.6154 18.1209 8.79817 18.0006 9.00002 18.0018H11V2.49698C11 2.22075 11.2239 1.99683 11.5 1.99683H12.5Z" />
    </svg>
  );
};
