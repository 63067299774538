export const readOnlyRoutes: string[] = [
  '/client/dashboard/:id',
  '/client/dashboard/:id/performance',
  '/client/dashboard/:id/analytics',
  '/client/dashboard/:id/market',
  '/client/calendar/:id',
  '/client/calendar/:id/pricing',
  '/client/calendar/:id/occupancy',
  '/client/calendar/:id/pickup',
  '/client/calendar/:id/revenue',
  '/client/chart/:id',
  '/client/chart/:id/price',
  '/client/account/:id',
  '/client/account/:id/profile-settings',
  '/client/account/:id/profile-settings/language',
  '/client/account/:id/profile-settings/password',
  '/client/account/:id/profile-settings/personal-information',
  '/client/help/:id'
];
