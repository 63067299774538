import { create } from 'zustand';

interface SaveState {
  isSave: boolean;
  isSaveLoading: boolean;
  setIsSave: (value: boolean) => void;
  setIsSaveLoading: (value: boolean) => void;
}

const useSaveStayTypeStore = create<SaveState>((set) => ({
  isSave: false,
  isSaveLoading: false,
  setIsSave: (value: boolean) => set({ isSave: value }),
  setIsSaveLoading: (value: boolean) => set({ isSaveLoading: value })
}));

export default useSaveStayTypeStore;
