import { useCountryList } from '@common/hooks/useSystem';
import { useClientListStore } from '@common/store/hotel-list-store';
import { ComboboxItem, Select, Loader } from '@mantine/core';
import { isNil, find } from 'lodash-es';

export function FiltersCountrySelect() {
  const paramKey = 'country_filter';
  const { params, setParams, setFilters } = useClientListStore();
  const { selectCountryList, query } = useCountryList();

  function handleChange(option: ComboboxItem) {
    setFilters({ [paramKey]: isNil(option) ? undefined : option.label });
    setParams({ [paramKey]: isNil(option) ? undefined : Number(option.value) });
  }

  return (
    <Select
      searchable
      clearable
      label="Country"
      variant="filled"
      placeholder="All Countries"
      value={find(selectCountryList, { value: String(params[paramKey]) })?.value || null}
      data={selectCountryList}
      disabled={query.isLoading}
      onChange={(_value, option) => handleChange(option)}
      comboboxProps={{ withinPortal: false }}
      rightSection={query.isLoading ? <Loader size={18} /> : null}
    />
  );
}
