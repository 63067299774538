export function ChartLineDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M2.14985 6.5601C2.0552 6.46621 2.00195 6.33842 2.00195 6.2051C2.00195 6.07178 2.0552 5.94398 2.14985 5.8501L2.84985 5.1501C2.94374 5.05544 3.07153 5.0022 3.20485 5.0022C3.33817 5.0022 3.46597 5.05544 3.55985 5.1501L10.4999 12.0901L13.1499 9.4401C13.2406 9.34342 13.3673 9.28858 13.4999 9.28858C13.6324 9.28858 13.7591 9.34342 13.8499 9.4401L21.8499 17.4401C21.9445 17.534 21.9978 17.6618 21.9978 17.7951C21.9978 17.9284 21.9445 18.0562 21.8499 18.1501L21.1499 18.8501C21.056 18.9448 20.9282 18.998 20.7949 18.998C20.6615 18.998 20.5337 18.9448 20.4399 18.8501L13.4999 11.9101L10.8499 14.5601C10.7591 14.6568 10.6324 14.7116 10.4999 14.7116C10.3673 14.7116 10.2406 14.6568 10.1499 14.5601L2.14985 6.5601Z" />
    </svg>
  );
}
