export function Flash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M18.09 10.2199L11.09 21.6399C10.9526 21.8618 10.711 21.9977 10.45 21.9999H10.25C10.1099 21.9996 9.97614 21.9418 9.88001 21.8399C9.78437 21.7376 9.73706 21.5993 9.75001 21.4599L10.31 14.9999H6.74001C6.47142 14.997 6.2243 14.8525 6.09001 14.6199L5.90001 14.2899C5.80457 14.1333 5.80457 13.9365 5.90001 13.7799L12.9 2.35988C13.0411 2.13185 13.2919 1.99504 13.56 1.99988H13.76C13.9001 2.00014 14.0339 2.058 14.13 2.15988C14.2256 2.26215 14.273 2.40046 14.26 2.53988L13.69 8.99988H17.26C17.5286 9.00278 17.7757 9.14725 17.91 9.37988L18.1 9.70988C18.1924 9.86835 18.1885 10.0652 18.09 10.2199Z" />
    </svg>
  );
}
