import { Button } from '@common/components/atoms/Button';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { getEnvVar } from '@common/utils/env';
import { PMS_NAME_SSO } from '@pages/Client/Account/Integrations/types/types';
import { useNavigate } from 'react-router-dom';

export function SSO() {
  const navigate = useNavigate();

  const handleGeneralSSO = (pmsName: string) => {
    window.location.href = `${getEnvVar('VITE_API')}account/sso/${pmsName}/redirect`;
  };

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
            Connect with SSO
          </Typography>
        </div>

        <div className="mt-8">
          <div className="mt-6">
            <div className="space-y-6">
              <div className="mt-2">
                <div className="flex flex-col space-y-2">
                  <a href={getEnvVar('VITE_SSO_FREETOBOOK')}>
                    <Button intent="freetobook" size="large" className="w-full">
                      Connect with freetobook
                    </Button>
                  </a>
                  <a href={getEnvVar('VITE_SSO_SEEKOM')}>
                    <Button intent="seekom" size="large" className="w-full">
                      Connect with Seekom
                    </Button>
                  </a>

                  <Button
                    intent="apaleo"
                    size="large"
                    className="w-full"
                    onClick={() => handleGeneralSSO(PMS_NAME_SSO.APALEO)}
                  >
                    Connect with apaleo
                  </Button>

                  <a href={getEnvVar('VITE_SSO_CLOUDBEDS')}>
                    <Button intent="cloudbeds" size="large" className="w-full">
                      Connect with Cloudbeds
                    </Button>
                  </a>
                  <Button
                    intent="lobbi"
                    size="large"
                    className="w-full"
                    onClick={() => handleGeneralSSO(PMS_NAME_SSO.LOBBI)}
                  >
                    Connect with Lobbi
                  </Button>
                </div>
              </div>
            </div>

            <div className="mt-8 flex flex-col space-y-4">
              <Button intent="outline" size="large" onClick={() => navigate('/login')}>
                Go back to Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
