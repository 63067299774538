import { Checkbox } from '@mantine/core';
import { Divider } from '@common/components/atoms/Divider';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useViewStore } from '@common/store/view';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ELASTICITY_LIST } from '@pages/Client/Calendar/constants';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useDocumentTitle } from '@mantine/hooks';

export const Aggressiveness = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Aggressiveness'));
  const { setValue, control, watch } = useFormContext();

  const [isCustomWeekdays, setIsCustomWeekdays] = useState(0);
  const [isCustomWeekends, setIsCustomWeekends] = useState(0);
  const { view } = useViewStore();
  const { hotelDetails } = useHotelDetails();

  useEffect(() => {
    setIsCustomWeekdays(
      ELASTICITY_LIST.some((item) => item.value === watch('PELL_weekday')) ? 0 : 1
    );
    setIsCustomWeekends(
      ELASTICITY_LIST.some((item) => item.value === watch('PELL_weekend')) ? 0 : 1
    );
  }, [watch('PELL_weekday'), watch('PELL_weekend')]);

  return (
    <div>
      <div className="mb-6 mt-4 grid grid-cols-1 items-start gap-20 md:grid-cols-12">
        <div className="col-span-5 mb-8 flex  flex-col gap-4">
          <Typography element="h5" variant="h5" color="darkGrey">
            {t('Aggressiveness')}
          </Typography>
          <Typography variant="paragraph-1" element="p" className="font-light">
            {t(
              'The aggressiveness allows you to define how strongly the system should react in case of unusual demand changes. When you receive many bookings for an individual day, a High aggressiveness causes prices to increase more than a Low aggressiveness.'
            )}
          </Typography>
        </div>
        <div className=" col-span-7 w-full">
          <div>
            <div className="mb-5 w-full flex-col justify-between gap-3 md:flex">
              <div className="my-3 w-1/2 md:my-0">
                {isCustomWeekdays === 1 ? (
                  <Controller
                    name={'PELL_weekday'}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <Input
                        name={name}
                        type="number"
                        value={value}
                        label={`${t('Aggressiveness Weekdays')}*`}
                        placeholder={`${t('Please enter aggressiveness weekdays')}`}
                        onChange={onChange}
                        onClear={() => setValue('PELL_weekday', -3)}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              <Icon.WarningOutline className="w-4" />
                              <Typography element="span" color="error" variant="meta-2">
                                {error.message}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                ) : (
                  <>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t('Aggressiveness Weekdays')}*
                    </Typography>
                    <Controller
                      control={control}
                      name="PELL_weekday"
                      render={({ field: { value, name, onChange } }) => (
                        <SelectDropdown
                          fullWidth
                          placeholder={`${t('Select Aggressiveness Weekdays')}`}
                          name={name}
                          key={name}
                          value={value || ''}
                          options={ELASTICITY_LIST.map((item) => ({
                            ...item,
                            label: t(item.label)
                          }))}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {view === 'admin' ? (
                  <div className="mt-6 flex items-center gap-2">
                    <Checkbox
                      label={t('Custom Value')}
                      id="customWeekdays"
                      value={1}
                      onChange={() => {
                        isCustomWeekdays === 1 ? setIsCustomWeekdays(0) : setIsCustomWeekdays(1);
                      }}
                      checked={isCustomWeekdays === 1}
                    />
                  </div>
                ) : null}
              </div>
              <div className="w-1/2">
                {isCustomWeekends === 1 ? (
                  <Controller
                    name={'PELL_weekend'}
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <Input
                        name={name}
                        type="number"
                        value={value}
                        label={`${t('Aggressiveness Weekends')}*`}
                        placeholder={`${t('Please enter aggressiveness weekends')}`}
                        onChange={onChange}
                        onClear={() => setValue('PELL_weekend', -3)}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              <Icon.WarningOutline className="w-4" />
                              <Typography element="span" color="error" variant="meta-2">
                                {error.message}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                ) : (
                  <>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t('Aggressiveness Weekends')}*
                    </Typography>
                    <Controller
                      control={control}
                      name="PELL_weekend"
                      render={({ field: { value, name, onChange } }) => (
                        <SelectDropdown
                          fullWidth
                          placeholder={`${t('Select Aggressiveness Weekends')}`}
                          name={name}
                          key={name}
                          value={value || ''}
                          options={ELASTICITY_LIST.map((item) => ({
                            ...item,
                            label: t(item.label)
                          }))}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {view === 'admin' ? (
                  <div className="mt-6 flex items-center gap-2">
                    <Checkbox
                      label={t('Custom Value')}
                      id="customWeekends"
                      checked={isCustomWeekends === 1}
                      value={1}
                      onChange={() => {
                        isCustomWeekends === 1 ? setIsCustomWeekends(0) : setIsCustomWeekends(1);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="my-10">
              <Divider />
            </div>
            <div className="my-4 w-full">
              <Typography variant="paragraph-2" element="p" className="font-light">
                {t(
                  `As default setting, RoomPriceGenie will be reducing prices on the last day as the hours count down. The closer it gets to end-of-day the more we will reduce as the time is shorter. The idea is to try to sell those last ${hotelDetails?.room_apartment_space_name?.toLowerCase()}s to last minute bookers. Some clients do not wish to do this, and if you turn it off, we will stop the reduction with one day remaining.`
                )}
              </Typography>
              <div className="mt-4 flex items-center gap-2">
                <Controller
                  name="do_not_reduce_last_day"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      label={t('Do not keep reducing prices on the last day')}
                      id="do_not_reduce_last_day"
                      name="do_not_reduce_last_day"
                      value={value}
                      checked={value}
                      onChange={(e) => {
                        const updatedValue = e.target.checked;
                        onChange(updatedValue);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
