import { Feature } from '@common/store/features';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ADJUSTMENT_OPTIONS,
  AdjustmentType,
  CALENDAR_PAGES,
  PICKUP_COMPARE_OPTIONS,
  PRICE_COMPARE_OPTIONS
} from '@pages/Client/Calendar/constants';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { snakeCase } from 'lodash-es';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { CalendarUserflowIds } from '@common/types/userflow-ids';

interface CalendarOptionsProps {
  optionType?: 'pricing' | 'occupancy' | 'pickup';
}

export const CalendarOptions = ({ optionType = 'pricing' }: CalendarOptionsProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { trackEvent } = useEventTracking();
  const { sortedHotelRooms, selectedHotelRoom } = useHotelRoomsList();
  const {
    selectedHotelRoomId,
    setSelectedHotelRoomId,
    setComparePriceMode,
    setComparePickupMode,
    setAdjustmentMode,
    adjustment,
    comparePriceTo,
    comparePickupTo,
    setInitialRoomId
  } = useCalendarPageStore();
  const { pricingSettings, refRoomOnly } = useRoomPrices();
  const { hasMinStayEnabled } = usePmsProvider();
  const { isOccupancyBased } = useGetDetailProviderConfig();

  const roomTypeOptions = useMemo(() => {
    return sortedHotelRooms.map((room) => ({
      value: room.id.toString(),
      label: room.name
    }));
  }, [sortedHotelRooms]);

  const adjustmentOptions = (() => {
    if (!hotelDetails || !pricingSettings) return [];
    const spaceName = hotelDetails?.room_apartment_space_name ?? 'Rooms';
    const displayOptions = [];

    displayOptions.push({
      value: AdjustmentType.Percent,
      label: t('Percent Adjustments')
    });

    if (pricingSettings.features?.includes(Feature.BulkEdits)) {
      displayOptions.push(
        {
          value: AdjustmentType.MinMax,
          label: t('Minimum and Maximum Prices')
        },
        {
          value: AdjustmentType.Derivation,
          label: t(`${spaceName} Derivation`),
          disabled: selectedHotelRoom?.is_reference_room
        }
      );
    }

    displayOptions.push({
      value: AdjustmentType.FixPrices,
      label: t('Fix Prices')
    });

    !pricingSettings.features?.includes(Feature.ClosedRoomDetection) &&
      displayOptions.push({
        value: AdjustmentType.Closed,
        label: t(`Closed ${spaceName}s`)
      });

    pricingSettings.features?.includes(Feature.BulkEdits) &&
    ([PmsProvider.VERTICAL_BOOKING, PmsProvider.SEMPER, PmsProvider.SABEE_APP].includes(
      hotelDetails?.pms_provider
    ) ||
      isOccupancyBased)
      ? displayOptions.push({
          value: AdjustmentType.Occupancy,
          label: t('Occupancy Pricing')
        })
      : null;

    pricingSettings.features?.includes(Feature.ProBulkEdits) &&
      displayOptions.push(
        {
          value: AdjustmentType.Aggressiveness,
          label: t('Aggressiveness')
        },
        {
          value: AdjustmentType.Median,
          label: t(`Median Booking Window`)
        }
      );

    hasMinStayEnabled() &&
      (pricingSettings.features?.includes(Feature.MinimumStayRestrictions) ||
        pricingSettings.features?.includes(Feature.LimitedMinimumStayRestrictions)) &&
      displayOptions.push({
        value: AdjustmentType.MinStay,
        label: t('Min Stay Restrictions')
      });

    const filteredOptions =
      refRoomOnly && !selectedHotelRoom?.is_reference_room
        ? [
            {
              value: AdjustmentType.Closed,
              label: t(`Closed ${spaceName}s`)
            }
          ]
        : displayOptions;

    return filteredOptions;
  })();

  const pickupCompareOptions = PICKUP_COMPARE_OPTIONS.map((option) => ({
    ...option,
    label: t(option.label)
  }));

  const comparePriceOptions = PRICE_COMPARE_OPTIONS.map((option) => ({
    ...option,
    label: t(option.label)
  }));

  useEffect(() => {
    // Handle a case where selected adjustment is not included in displayed options
    if (adjustment) {
      if (!adjustmentOptions.map((item) => item.value).includes(adjustment)) {
        setAdjustmentMode(adjustmentOptions[0].value);
      }
    }
  }, [selectedHotelRoom]);

  useEffect(() => {
    if ([CALENDAR_PAGES.PICKUP, CALENDAR_PAGES.OCCUPANCY].includes(optionType)) {
      setComparePriceMode(comparePriceTo);
    }
  }, [optionType]);

  const onComparePriceOptionChange = (compareTo: any) => {
    setComparePriceMode(compareTo);
    setAdjustmentMode(undefined);
    trackEvent('ComparePriceToSelected', {
      tags: {
        'compare-price-to':
          PRICE_COMPARE_OPTIONS.find((option) => option.value === compareTo)?.value ?? ''
      }
    });
  };

  const onComparePickupOptionChange = (compareTo: any) => {
    setComparePickupMode(compareTo);
    trackEvent('ComparePickupToSelected', {
      tags: {
        'compare-pickup-to':
          PICKUP_COMPARE_OPTIONS.find((option) => option.value === compareTo)?.value ?? ''
      }
    });
  };

  const onAdjustmentOptionChange = (option: AdjustmentType) => {
    setAdjustmentMode(option);
    setComparePriceMode(undefined);
    trackEvent('AdjustmentSelected', {
      tags: {
        adjustment: snakeCase(
          ADJUSTMENT_OPTIONS('Room').find((item) => item.value === option)?.label
        )
      }
    });
  };

  return (
    <div className="flex items-center gap-x-4 xl:justify-end">
      {optionType === 'pricing' ? (
        <div className="w-full">
          <Dropdown
            label={t(`${hotelDetails?.room_apartment_space_name} Type`) as string}
            selectedOption={roomTypeOptions.find(
              (option) => option.value === selectedHotelRoomId?.toString()
            )}
            onOptionClick={(option) => {
              setSelectedHotelRoomId(Number(option.value));
              setInitialRoomId(Number(option.value));
              trackEvent('RoomTypeSelected', {
                tags: {
                  'room-type': sortedHotelRooms.find((room) => room.id === Number(option.value))
                    ?.is_reference_room
                    ? 'reference'
                    : 'derived'
                }
              });
            }}
            options={roomTypeOptions}
          />
        </div>
      ) : null}

      {optionType === 'pricing' ? (
        <div className="w-full">
          <Dropdown
            label={t('Compare Price to') as string}
            onOptionClick={(option) => onComparePriceOptionChange(option.value)}
            selectedOption={
              comparePriceOptions.find((option) => option.value === comparePriceTo) ?? {
                label: t('Select'),
                value: undefined
              }
            }
            options={comparePriceOptions}
          />
        </div>
      ) : null}

      {[CALENDAR_PAGES.PICKUP, CALENDAR_PAGES.OCCUPANCY].includes(optionType) ? (
        <div className="w-56">
          <Dropdown
            label={t('Show Pickup since') as string}
            onOptionClick={(option) => onComparePickupOptionChange(option.value)}
            selectedOption={pickupCompareOptions.find((option) => option.value === comparePickupTo)}
            options={pickupCompareOptions}
          />
        </div>
      ) : null}

      {optionType === 'pricing' ? (
        <div className="w-full">
          <Dropdown
            userflowId={CalendarUserflowIds.ADJUSTMENT_SELECT_ITEM}
            label={t('Adjustments') as string}
            options={adjustmentOptions.map((option) => ({
              ...option,
              label: t(option.label)
            }))}
            selectedOption={
              adjustmentOptions.find((option) => option.value === adjustment) ?? {
                label: t('Select'),
                value: undefined
              }
            }
            disableSelector={(option) => !!option.disabled}
            onOptionClick={(option) => onAdjustmentOptionChange(Number(option.value))}
          />
        </div>
      ) : null}
    </div>
  );
};
