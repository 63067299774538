import { useEffect } from 'react';

export const useChatBot = () => {
  useEffect(() => {
    if (import.meta.env.PROD) {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/5641770.js?v=15072022';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
};
