import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { truncate } from 'lodash-es';
import { Button } from '@common/components/atoms/Button';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useValueReport } from '@pages/Client/HealthReport/hooks/useValueReport';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { isDevelopment } from '@common/utils/env';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { exportPDF } from '@common/utils/exportPDF';
import { useViewStore } from '@common/store/view';
import { DATE_FORMAT_DATE_FNS } from '@common/constants/date';

export const HealthReportLayout = () => {
  const captureRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const {
    data: valueReportData,
    isLoading,
    forceRefetch,
    forceRefetchLoading,
    forceRefetchSuccess
  } = useValueReport();
  const { features } = useFeaturesStore();
  const { hotelDetails, isAutoUploadOn } = useHotelDetails();
  const { isAdmin } = useViewStore();

  const isValueReportAvailable =
    (isDevelopment() && isAdmin && features?.includes(Feature.LimitedDashboard)) ||
    (features?.includes(Feature.LimitedDashboard) && isAutoUploadOn && isAdmin);

  const subMenuLink = [
    {
      id: 'health-report',
      label: t('Customer Health Report'),
      to: 'health-report'
    },
    {
      id: 'value-report',
      label: t('Value Report'),
      to: 'value-report',
      tooltip: 'Only available if auto-upload is active AND reporting data is available',
      disabled: !isValueReportAvailable
    }
  ];

  const activeLink = subMenuLink.find((link) => location.pathname.includes(link.to));

  const isExportDisabled =
    valueReportData?.monthly_extra_revenue && valueReportData.monthly_extra_revenue < 0;

  const handleExportData = () => {
    if (isExportDisabled) {
      return null;
    }
    exportPDF({
      exportRef: captureRef,
      fileName: `${dayjs().tz().format('YYYY-MM-DD')}-${
        hotelDetails?.name
      }-RoomPriceGenie-Value-Report.pdf`
    });
  };

  const renderActions = () => {
    if (activeLink?.id === 'value-report') {
      return (
        <div
          className="flex w-full items-center justify-end gap-x-4"
          data-html2canvas-ignore="true">
          <div className="flex items-center">
            {!isLoading ? (
              <RPGPopover className="relative items-center justify-self-end md:flex">
                <RPGPopover.Button as={Button} icon>
                  <Icon.MoreVertical />
                </RPGPopover.Button>
                <RPGPopover.Panel className="max-w-xs px-0 pb-3 pt-2">
                  <div
                    className="mt-1.5 flex cursor-pointer items-center gap-x-3 px-6 py-0.5 hover:bg-lightGrey"
                    style={{ opacity: isExportDisabled ? 0.5 : 1 }}
                    onClick={() => handleExportData()}>
                    <Icon.Publish className="h-5 w-5" />
                    <Typography color="darkGrey">{t('Export Data as PDF')}</Typography>
                  </div>
                </RPGPopover.Panel>
              </RPGPopover>
            ) : null}
          </div>
          <Button
            disabled={isLoading}
            isLoading={forceRefetchLoading}
            isSuccess={forceRefetchSuccess}
            onClick={() => forceRefetch()}>
            <Icon.Refresh className="h-4 w-4" />
            {t('Regenerate')}
          </Button>
        </div>
      );
    }
    return <div />;
  };

  let preferredDateFormat = hotelDetails?.prefered_date_format;
  preferredDateFormat = preferredDateFormat?.replace(/([-./]?Y{2,4})/g, '').trim(); // remove year and possible separator before it
  preferredDateFormat = preferredDateFormat?.replace(/([-./]Y{2,4})/g, '').trim(); // remove year and possible separator after it

  const renderTabsRightInfo = () => {
    if (activeLink?.id === 'value-report') {
      return (
        <div className="hidden py-3 sm:block">
          {isLoading ? (
            <Skeleton className="h-5 w-16" />
          ) : (
            <>
              <Typography variant="nav" color="copyTextGrey" className="text-opacity-60">
                {t('Gross excl. Services')}
                {', '}
                {t('Auto-Upload since:')}{' '}
                {valueReportData?.activation_date_str
                  ? format(parseISO(valueReportData.activation_date_str), DATE_FORMAT_DATE_FNS)
                  : null}
                {', '}
              </Typography>
              <Typography variant="nav" color="copyTextGrey" className="text-opacity-60">
                {t('Last Update:')}{' '}
                {valueReportData?.created_on
                  ? dayjs(parseISO(valueReportData?.created_on)).tz().format(preferredDateFormat)
                  : 'n.A.'}
              </Typography>
            </>
          )}
        </div>
      );
    }
    return <div />;
  };

  return (
    <Page
      ref={captureRef}
      header={
        <Header
          title={t('Customer Health')}
          actions={<div style={{ minHeight: 32 }}>{renderActions()}</div>}
          tabs={subMenuLink}
          tabsRightInfo={renderTabsRightInfo()}
          exportTitle={`RoomPriceGenie Value Report - ${truncate(hotelDetails?.name, {
            length: 50
          })}`}
        />
      }>
      <Outlet />
    </Page>
  );
};
