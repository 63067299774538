import { cva, type VariantProps } from 'class-variance-authority';
import { clsx } from 'clsx';
import styles from '@common/components/atoms/Button/Button.module.css';
import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { Icon } from '@common/components/foundations/icons';
import { cn } from '@common/utils/cn';

export const buttonStyles = cva(styles.base, {
  variants: {
    intent: {
      primary: styles.primary,
      outline: styles.outline,
      text: styles.text,
      danger: styles.danger,
      freetobook: styles.freetobook,
      seekom: styles.seekom,
      apaleo: styles.apaleo,
      cloudbeds: styles.cloudbeds,
      lobbi: styles.lobbi
    },
    size: {
      small: styles.small,
      large: styles.large
    },
    icon: {
      true: styles.icon
    },
    fullWidth: {
      true: styles.fullWidth
    }
  },
  defaultVariants: {
    intent: 'primary',
    size: 'small',
    icon: false
  }
});

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonStyles> & {
    isLoading?: boolean;
    isSuccess?: boolean;
  };

const loadingVariants = {
  start: { rotate: 0 },
  end: { rotate: 360 }
};

const successVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 }
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ intent, size, icon, fullWidth, className, isSuccess, isLoading, children, ...props }, ref) => {
    const buttonIcon = isLoading ? (
      <motion.div
        animate="end"
        variants={loadingVariants}
        transition={{ loop: Infinity, ease: 'linear', duration: 1 }}>
        <Icon.LoadingCircle
          className={cn(
            intent === 'outline' || intent === 'text' || icon ? 'text-darkGrey' : 'text-white',
            'h-4 w-4'
          )}
        />
      </motion.div>
    ) : isSuccess ? (
      <motion.div initial="hidden" animate="visible" variants={successVariants}>
        <Icon.CheckList
          className={cn(
            intent === 'outline' || intent === 'text' || icon ? 'text-darkGrey' : 'text-white',
            'h-5 w-5'
          )}
        />
      </motion.div>
    ) : null;
    const buttonText = children;

    return (
      <button
        ref={ref}
        className={clsx(buttonStyles({ intent, size, icon, fullWidth }), className)}
        {...props}>
        <div className={clsx(isLoading || isSuccess ? 'absolute z-50' : 'hidden')}>
          {buttonIcon}
        </div>
        <div
          className={clsx(
            'inline-flex items-center justify-between gap-x-1',
            isLoading || isSuccess ? 'invisible' : 'visible',
            fullWidth && 'w-full'
          )}>
          {buttonText}
        </div>
      </button>
    );
  }
);
