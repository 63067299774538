import {
  ColorProperties,
  Colors,
  StrictPropertyKeys,
  colors
} from '@common/components/foundations/colorVariants';
import { useMemo } from 'react';

/**
 * Returns the color value from the Tailwind CSS configuration, based on the given color name and optionally, a specific property.
 *
 * @function
 * @param {Colors} colorName - The color name to look up in the Tailwind CSS configuration.
 * @param {string} [property='DEFAULT' | 'contrast' | 'reduced' | 'light'] - The optional property of the color value to be accessed (e.g., 'contrast', 'reduced', 'light'). Defaults to 'DEFAULT'.
 * @returns {string} The color value (in hexadecimal format) corresponding to the color name and property, or an empty string if the color is not found.
 * @throws {console.warn} If the color name is not found in the Tailwind CSS configuration, a warning will be logged in the console.
 * @example
 * const color = useTailwindColor('indigo', 'contrast');
 */
export const useTailwindColor = <T extends Colors>(
  colorName: T,
  property: StrictPropertyKeys<T> | 'DEFAULT' = 'DEFAULT'
) => {
  const color = useMemo(() => {
    const colorValue = colors[colorName];
    if (colorValue) {
      const colorObj = colorValue as ColorProperties<T>[T];
      return colorObj[property] || (colorObj.DEFAULT as string);
    } else {
      console.warn(`Color "${colorName}" not found in Tailwind CSS configuration.`);
    }
  }, [colorName, property]);

  return color;
};
