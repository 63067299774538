export const VisibleOutlined = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9878 11.7001C20.3582 7.61654 16.3881 4.95492 11.9939 5.00008C7.629 4.97483 3.69114 7.61885 2.05997 11.6701C2.02156 11.7759 2.00129 11.8875 2.00001 12.0001C1.99948 12.1063 2.01986 12.2117 2.05997 12.3101C3.68479 16.3692 7.62406 19.0221 11.9939 19.0001C16.3588 19.0253 20.2967 16.3813 21.9279 12.3301C21.9706 12.2255 21.9911 12.1131 21.9878 12.0001C22.0041 11.9007 22.0041 11.7994 21.9878 11.7001ZM11.9939 17.0001C8.6124 16.9925 5.5333 15.0499 4.06875 12.0001C5.52824 8.94606 8.61076 7.00207 11.9939 7.00207C15.3771 7.00207 18.4596 8.94606 19.9191 12.0001C18.4545 15.0499 15.3754 16.9925 11.9939 17.0001ZM8.99575 12.0001C8.99575 10.3432 10.3381 9.00008 11.9939 9.00008C13.6498 9.00008 14.9921 10.3432 14.9921 12.0001C14.9921 13.6569 13.6498 15.0001 11.9939 15.0001C10.3381 15.0001 8.99575 13.6569 8.99575 12.0001Z"
        fill="currentColor"
      />
    </svg>
  );
};
