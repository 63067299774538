import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useBannerStore } from '@common/store/banner';
import { cn } from '@common/utils/cn';
import { Transition } from '@headlessui/react';
import { Flex } from '@mantine/core';

interface BannerMessagesProps {
  fullWidth?: boolean;
}

export const BannerMessages = ({ fullWidth }: BannerMessagesProps) => {
  const { banners, removeBanner } = useBannerStore();

  if (!banners || banners.length === 0) return null; // No banners to display.

  // Determine colors and icon based on banner type
  const bannerColors = {
    surge: 'bg-darkGreen',
    info: 'bg-uiGreen',
    warning: 'bg-orange-darker',
    error: 'bg-uiRed'
  };
  const textBannerColor = {
    surge: 'text-darkGreen',
    info: 'text-uiGreen',
    warning: 'text-orange-darker',
    error: 'text-uiRed'
  };

  const bannerBorderColors = {
    surge: 'border-darkGreen',
    info: 'border-uiGreen',
    warning: 'border-orange-darker',
    error: 'border-uiRed'
  };

  const icons = {
    surge: <Icon.Ripple className="h-6 w-6 text-darkGreen" aria-hidden="true" />,
    info: <Icon.NotificationOutline className="text-uiGreen" aria-hidden="true" />,
    warning: <Icon.WarningOutline className="h-6 w-6 text-orange" aria-hidden="true" />,
    error: <Icon.ErrorOutlined className="h-6 w-6 text-uiRed" aria-hidden="true" />
  };

  return (
    <div
      aria-live="assertive"
      className="mb-2 flex max-h-screen w-full flex-col-reverse sm:flex-col">
      <div className="space-y-2">
        {banners.map((banner) => (
          <Transition
            appear={true}
            show={true}
            key={banner.id}
            className="w-full"
            enter="transform transition ease-out duration-150"
            enterFrom="translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transform transition ease-in duration-100"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-full opacity-0">
            <div
              className={cn(
                'pl-4 pr-4 md:pl-8 md:pr-8 min-[2305px]:pr-0',
                fullWidth ? 'min-[2305px]:pr-0' : 'min-[1755px]:pr-0'
              )}>
              <div
                className={cn(
                  'flex items-start justify-between gap-2 rounded-md border-[1.5px] bg-white px-4 py-2',
                  bannerBorderColors[banner.type]
                )}>
                <div
                  className={cn(
                    'flex items-center rounded-full bg-opacity-10 p-3',
                    bannerColors[banner.type]
                  )}>
                  {icons[banner.type]}
                </div>
                <div className="w-full">
                  <Typography
                    element="p"
                    variant="paragraph-3"
                    className={cn('ml-2 font-semibold', textBannerColor[banner.type])}>
                    {banner.title}
                  </Typography>
                  <Typography element="p" variant="meta-1" className="ml-2 mr-2">
                    {banner.description}{' '}
                    {banner.showAction && (
                      <span>
                        <button
                          type="button"
                          onClick={banner.onAction}
                          className="group font-medium text-indigo hover:underline">
                          {banner.actionTitle}
                        </button>
                      </span>
                    )}
                  </Typography>
                  {banner.components}
                </div>
                {banner.showClose && banner.type !== 'surge' ? (
                  <Flex gap="sm" align="center">
                    <Button
                      type="button"
                      size="small"
                      icon
                      className="outline-none"
                      onClick={() => {
                        removeBanner(banner.id);
                      }}>
                      <Icon.Clear className="h-4 w-4" aria-hidden="true" />
                    </Button>
                  </Flex>
                ) : null}
                {banner.showClose && banner.type === 'surge' ? (
                  <Flex gap="sm" align="center">
                    {banner?.extraAction ? <banner.extraAction /> : null}
                    <Button
                      type="button"
                      size="small"
                      icon
                      className="outline-none"
                      onClick={() => {
                        removeBanner(banner.id);
                      }}>
                      <Icon.Clear className="h-4 w-4" aria-hidden="true" />
                    </Button>
                  </Flex>
                ) : null}
              </div>
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
};
