import { Outlet } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useLilius } from 'use-lilius';
import { Page } from '@common/components/organisms/Page';
import { usePriceChartStore } from '@pages/Client/PriceChart/store/priceChart';
import { ChartHeader } from '@pages/Client/PriceChart/components/ChartHeader';
import { RunPricingProvider } from '@pages/Client/Calendar/hooks/useRunPricing';

export function PriceChartLayout() {
  const captureRef = useRef<HTMLDivElement>(null);

  const liliusInstance = useLilius();
  const { setLiliusInstance } = usePriceChartStore();

  /**
   * Sync lilius instance with Zustand store.
   */
  useEffect(() => {
    setLiliusInstance(liliusInstance);
  }, [liliusInstance.viewing]);

  const renderHeader = () => {
    return <ChartHeader />;
  };

  return (
    <RunPricingProvider>
      <Page ref={captureRef} header={renderHeader()}>
        <Outlet />
      </Page>
    </RunPricingProvider>
  );
}
