import { disconnectChargebee } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation } from '@tanstack/react-query';

export const useDisconnectSubscription = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const {
    query: { refetch: hotelDetailsRefetch }
  } = useHotelDetails();
  return useMutation({
    mutationFn: async () => disconnectChargebee(),
    onSuccess: () => {
      addNotification('success', 'Successfully disconnected from Chargebee');
      hotelDetailsRefetch();
    },
    onError: () => {
      addNotification('fail', 'Failed to disconnect from Chargebee');
    }
  });
};
