export function Clear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill={props?.color || 'currentColor'}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M17.8501 16.4399C17.9448 16.5337 17.998 16.6615 17.998 16.7949C17.998 16.9282 17.9448 17.056 17.8501 17.1499L17.1501 17.8499C17.0562 17.9445 16.9284 17.9978 16.7951 17.9978C16.6618 17.9978 16.534 17.9445 16.4401 17.8499L12.0001 13.4099L7.5601 17.8499C7.46621 17.9445 7.33842 17.9978 7.2051 17.9978C7.07178 17.9978 6.94398 17.9445 6.8501 17.8499L6.1501 17.1499C6.05544 17.056 6.0022 16.9282 6.0022 16.7949C6.0022 16.6615 6.05544 16.5337 6.1501 16.4399L10.5901 11.9999L6.1501 7.55985C6.05544 7.46597 6.0022 7.33817 6.0022 7.20485C6.0022 7.07153 6.05544 6.94374 6.1501 6.84985L6.8501 6.14985C6.94398 6.0552 7.07178 6.00195 7.2051 6.00195C7.33842 6.00195 7.46621 6.0552 7.5601 6.14985L12.0001 10.5899L16.4401 6.14985C16.534 6.0552 16.6618 6.00195 16.7951 6.00195C16.9284 6.00195 17.0562 6.0552 17.1501 6.14985L17.8501 6.84985C17.9448 6.94374 17.998 7.07153 17.998 7.20485C17.998 7.33817 17.9448 7.46597 17.8501 7.55985L13.4101 11.9999L17.8501 16.4399Z" />
    </svg>
  );
}
