interface Data {
  month: string;
  value: number;
}

export function calculateAdjustment(data1: Data[], data2: Data[]): Data[] {
  const result: Data[] = [];
  for (let i = 0; i < Math.max(data1.length, data2.length); i++) {
    const currentData1 = data1[i];
    const currentData2 = data2[i];

    if (!currentData1 || !currentData2) {
      continue; // Skip iteration when either data1[i] or data2[i] is undefined
    }

    const month1 = currentData1.month;
    const value1 = currentData1.value;
    const month2 = currentData2.month;
    const value2 = currentData2.value;

    if (month1 !== month2) {
      throw new Error('Month names do not match');
    }

    const calculatedValue = (value1 * value2) / 100 + value2;
    result.push({ month: month1, value: Math.round(calculatedValue) });
  }

  return result;
}
