import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import React, { useEffect } from 'react';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useScoreCompetitor } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useCompetitorScore';
import { useScoreCompetitorLoading } from '@pages/Client/PricingStrategy/MarketIntelligence/store/scoreCompetitor';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const ScoreCompetitorModal = ({ isOpen, onClose }: ModalProps) => {
  const { setLoading } = useScoreCompetitorLoading();
  const { isLoading, scoredCompetitor } = useScoreCompetitor();

  const { hotelRooms } = useHotelRoomsList();

  const referenceRooms = hotelRooms.filter((room) => room.is_reference_room === true);
  const referenceRoomIds = referenceRooms.map((room) => room.id);

  const handleSortAndScore = async () => {
    const data = {
      room_id: referenceRoomIds.toString(),
      do_sorting: true
    };
    await scoredCompetitor(data);
    onClose();
  };
  const handleScoreOnly = async () => {
    const data = {
      room_id: referenceRoomIds.toString(),
      do_sorting: false
    };
    await scoredCompetitor(data);
    onClose();
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <Modal overFlowHidden showFooter={false} onClose={onClose} size="custom" open={isOpen}>
      <div className=" p-8">
        <div className="flex w-[480px] flex-col  gap-4 rounded-t-lg bg-white px-8 py-10">
          <Button
            type="button"
            icon
            className="absolute right-14 top-14 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
            onClick={onClose}>
            <Icon.Clear className="h-5 w-5" />
          </Button>
          <Typography variant="h5" element="h5" color="darkGrey">
            Score Competitor
          </Typography>
          <Typography variant="h6" element="h6" color="darkGrey">
            Would you like the competitors to be sorted after scoring?
          </Typography>
        </div>
        <div className="flex h-[60px] w-full items-center justify-end rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex justify-end gap-4">
            <Button intent="text" onClick={handleScoreOnly}>
              Score Only
            </Button>
            <Button onClick={handleSortAndScore}>Score and Sort</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
