import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Colors } from '@common/components/foundations/colorVariants';
import { Card } from '@common/components/molecules/Card';
import { cn } from '@common/utils/cn';
import { DataIcon } from '@pages/Client/HealthReport/components/DataIcon';

interface DataCellProps {
  title: string | number;
  subtitle: string | JSX.Element;
  descriptionTitle?: string | number;
  descriptionSubtitle?: string;
  variant?: 'default' | 'centered';
  cardClassName?: string;
  icon?: JSX.Element;
  bgColor?: Colors;
  iconColor?: Colors;
  isLoading?: boolean;
  additionalInfoTitle?: string | number;
  additionalInfoSubtitle?: string;
  isVersus?: boolean;
  isHighlight?: boolean;
}
export const DataCell = ({
  title,
  subtitle,
  descriptionTitle,
  descriptionSubtitle,
  variant = 'default',
  cardClassName,
  icon,
  bgColor,
  iconColor = 'indigo',
  isLoading,
  additionalInfoTitle,
  additionalInfoSubtitle,
  isHighlight = false
}: DataCellProps) => {
  if (variant === 'centered') {
    return (
      <Card
        backgroundColor={bgColor ? bgColor : 'white'}
        className={cn(
          'flex h-full flex-col items-center justify-center px-2 py-2 shadow',
          isLoading ? 'animate-pulse bg-grey-reduced' : null,
          cardClassName,
          'min-h-[150px]'
        )}>
        {icon ? (
          <div className="flex w-full justify-center">
            <DataIcon
              icon={icon}
              color={'white'}
              bgColor={iconColor ? iconColor : 'white'}
              className="mb-4"
            />
          </div>
        ) : null}
        <div className="text-center">
          {isLoading ? (
            <div className="flex justify-center">
              <Skeleton className="h-7 w-16" />
            </div>
          ) : (
            <Typography variant="h4" color={bgColor ? 'white' : 'indigo'}>
              {title}
            </Typography>
          )}
        </div>
        <div className="mt-1 text-center">
          <Typography element="span" variant="meta-2" color={bgColor ? 'white' : 'copyTextGrey'}>
            {subtitle}
          </Typography>
        </div>
      </Card>
    );
  }

  return (
    <div>
      <Card
        backgroundColor={bgColor ? bgColor : 'white'}
        className={cn(isLoading ? 'animate-pulse bg-grey-reduced' : null, cardClassName, 'shadow')}>
        <div className={cn(isHighlight ? 'flex flex-col gap-8' : 'flex items-center')}>
          {icon ? (
            <>
              {isLoading ? (
                <Skeleton className="mr-2 h-10 w-10" />
              ) : (
                <DataIcon
                  icon={icon}
                  color={'white'}
                  bgColor={iconColor ? iconColor : 'indigo'}
                  className="mr-4"
                />
              )}
            </>
          ) : null}
          <div className="flex flex-col text-left">
            {isLoading ? (
              <Skeleton className="h-5 w-16" />
            ) : (
              <Typography element="span" variant="h6" color={isHighlight ? 'indigo' : 'darkGrey'}>
                {title ?? 'n.A.'}
              </Typography>
            )}
            <Typography element="span" variant="meta-2" color="copyTextGrey">
              {subtitle ?? 'n.A.'}
            </Typography>
          </div>
        </div>
        <div
          className={cn('flex flex-row items-center justify-between', !isHighlight ? 'mt-5' : '')}>
          {descriptionTitle || descriptionSubtitle ? (
            <div className="mt-3 flex flex-col">
              {isLoading ? (
                <Skeleton className="h-5 w-16" />
              ) : (
                <Typography element="span" variant="paragraph-2" color={'darkGrey'}>
                  {descriptionTitle}
                </Typography>
              )}
              <Typography element="span" variant="paragraph-2" className={'truncate'}>
                {descriptionSubtitle}
              </Typography>
            </div>
          ) : null}
          {additionalInfoTitle || additionalInfoSubtitle ? (
            <div className="mt-3 flex flex-col">
              {isLoading ? (
                <Skeleton className="h-5 w-16" />
              ) : (
                <Typography element="span" variant="paragraph-2" color={'darkGrey'}>
                  {additionalInfoTitle}
                </Typography>
              )}
              <Typography element="span" variant="paragraph-2" className={'truncate'}>
                {additionalInfoSubtitle}
              </Typography>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};
