import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  DialogTrigger
} from '@common/components/shadcn/ui/dialog';
import { Typography } from '@common/components/foundations/Typography';
import styles from '@common/components/molecules/Modal/modal.module.css';
import { cn } from '@common/utils/cn';
import { ScrollArea } from '@common/components/shadcn/ui/scroll-area';
import { map } from 'lodash-es';

export function ShadDialog({
  children,
  config
}: {
  children: JSX.Element;
  config: {
    open?: boolean;
    title: string;
    openChange: (open: boolean) => void;
    content: () => JSX.Element;
    actionButtons?: (() => JSX.Element)[];
    closeButton?: () => JSX.Element;
  };
}) {
  return (
    <Dialog open={config.open} onOpenChange={config.openChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogOverlay className="bg-darkGrey bg-opacity-70">
        <DialogContent className={cn(styles.baseDialog, 'min-w-[550px] border-none p-0')}>
          <DialogHeader className="px-6 pt-6">
            <DialogTitle asChild>
              <Typography variant="h5" element="h1" className="font-medium" color="darkGrey">
                {config.title}
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="max-h-[60vh] min-w-[550px] px-6">
            <config.content />
          </ScrollArea>
          <DialogFooter className={cn(styles.modalFooter, 'justify-end')}>
            {config?.closeButton ? <config.closeButton /> : null}
            {config?.actionButtons
              ? map(config.actionButtons, (ActionButton, index) => <ActionButton key={index} />)
              : null}
          </DialogFooter>
        </DialogContent>
      </DialogOverlay>
    </Dialog>
  );
}
