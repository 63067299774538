import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useDocumentTitle } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { AddNewTagModal } from '@pages/Client/PricingStrategy/YieldingTags/components/AddNewTagModal';
import { DeleteTagModal } from '@pages/Client/PricingStrategy/YieldingTags/components/DeleteTagModal';
import { EditTagModal } from '@pages/Client/PricingStrategy/YieldingTags/components/EditTagModal';

interface RoomTypeTag {
  tag_name: string;
  color: string;
  min_adjustment: number;
  max_adjustment: number;
  room_types: number[];
  speed: number;
  id: string;
}

const speedBadgeColor: { [key: number]: string } = {
  0.5: 'mediumGrey',
  1: 'gold',
  1.5: 'orange',
  2: 'uiRed'
};

// Replace Old Color With New Color
const colorMap: { [key: string]: string } = {
  // Old Color
  '#00BFFF': 'bg-gold',
  '#FFA500': 'bg-orange',
  '#0000CD': 'bg-indigo',
  '#32CD32': 'bg-uiRed',
  '#FF69B4': 'bg-grey',
  '#006400': 'bg-darkGreen',
  // New Color,
  '#ECBA59': 'bg-gold',
  '#FE764B': 'bg-orange',
  '#5B48EE': 'bg-indigo',
  '#D62057': 'bg-uiRed',
  '#949494': 'bg-grey',
  '#06815B': 'bg-darkGreen'
};

interface Item {
  value: number;
  label: string;
}

function getRoomTypeNameFromIds(items: Item[], ids: number[], separator = ', '): string {
  const filteredItems = items?.filter((item) => ids?.includes(item.value));
  const names = filteredItems?.map((item) => item.label);
  if (names?.length > 1) {
    return names?.join(separator);
  }
  return names[0] || '';
}

// Main Func
export const YieldingTags = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Yielding Tags'));

  const speedLabels: { [key: number]: string } = {
    0.5: t('Low'),
    1: t('Medium'),
    1.5: t('High'),
    2: t('Super High')
  };

  const aggressivenessData = Object.entries(speedLabels).map(([value, label]) => ({
    value: parseFloat(value),
    label: label
  }));

  const sortedDataAggressiveness = aggressivenessData?.sort((a, b) => a.value - b.value);

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings, isLoading: isPricingSettingLoading } = pricingSettingsQuery;
  const { savePricingSettings } = useUpdatePricingSettings();
  const [selectedTags, setSelectedTags] = useState<RoomTypeTag>();
  const { hotelDetails } = useHotelDetails();
  const spaceName = hotelDetails?.room_apartment_space_name ?? 'Room';

  // room_type_tags

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { hotelRooms } = useHotelRoomsList();

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const roomTypesData = hotelRooms?.map(({ id, name }) => ({ value: id, label: name }));

  const columns = [
    {
      header: t('Tag Name'),
      accessorKey: 'tag_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Room Type'),
      accessorKey: 'room_types',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {getRoomTypeNameFromIds(roomTypesData, row?.getValue() as number[])}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Colour'),
      accessorKey: 'color',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <div className={`h-9 w-9 rounded-full ${colorMap[row?.getValue()] as string}`} />
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Min Adjustment'),
      accessorKey: 'min_adjustment',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}%
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Max Adjustment'),
      accessorKey: 'max_adjustment',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              +{row?.getValue() as string}%
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Aggressiveness'),
      accessorKey: 'speed',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Badge background={speedBadgeColor[row?.getValue()] as string}>
              {speedLabels[row?.getValue()] as string}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Delete'),
      accessorKey: 'id',
      cell: (row: any) => {
        const selected = row.row.original;
        return (
          <div className="flex items-center gap-3">
            <Button
              icon
              onClick={() => {
                setSelectedTags(selected);
                deleteClick();
              }}
            >
              <Icon.Delete className="text-copyTextGrey" />
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const HeaderYieldingTags = () => {
    return (
      <div className="flex w-full justify-between py-2">
        <Typography element="h6" variant="h6" color="darkGrey">
          {t('Yielding Tags')}
        </Typography>
        <Button onClick={addClick}>{t('Add New Yielding Tag')}</Button>
      </div>
    );
  };

  const handleDeleteTags = async (id: string | number | undefined) => {
    const roomTypeTags = pricingSettings?.room_type_tags;

    if (id === undefined || roomTypeTags === undefined) {
      return;
    }
    const updatedRoomTypeTags = Object.fromEntries(
      Object.entries(roomTypeTags).filter(([key]) => key !== id.toString())
    );

    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const settings = JSON.stringify({
      ...latestPricingSettings,
      room_type_tags: {
        ...updatedRoomTypeTags
      }
    });
    if (id) {
      await savePricingSettings(settings);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  return (
    <Page
      header={
        <Header
          title={`${t('Yielding Tags')}`}
          description={`${t(
            `Yielding Tags is an advanced feature, that enables you to set up different ${spaceName.toLowerCase()} groups separately. For example, your Doubles may sell very differently from your Singles as they are more used for leisure rather than business. By setting up different groups, we can shift prices around relative to each other. So, if your doubles are selling faster, they will go up in price and your singles will reduce. Please see more at the Help Centre.`
          )}`}
        />
      }
    >
      <Table
        isFetching={isPricingSettingLoading}
        skeletonCount={10}
        headerComponent={<HeaderYieldingTags />}
        columns={columns}
        onClickRow={(row: any) => {
          setSelectedTags(row.row.original);
          editClick();
        }}
        data={
          pricingSettings
            ? Object.entries(pricingSettings.room_type_tags).map(([id, value]) => ({
                ...value,
                id
              }))
            : []
        }
        isHover
      />
      <AddNewTagModal
        isOpen={addOpen}
        onClose={addClose}
        aggressivenessData={sortedDataAggressiveness}
        roomTypesData={roomTypesData}
      />
      <EditTagModal
        isOpen={editOpen}
        onClose={editClose}
        selectedTag={selectedTags}
        aggressivenessData={sortedDataAggressiveness}
        roomTypesData={roomTypesData}
      />
      <DeleteTagModal
        isOpen={deleteOpen}
        onClose={deleteClose}
        name={selectedTags?.tag_name}
        onDelete={() => handleDeleteTags(selectedTags?.id)}
      />
    </Page>
  );
};
