import { startOfMonth, subMonths, subDays, endOfMonth } from 'date-fns';

export const getPredefinedRanges = ({
  startDate,
  allowFutureDates,
  allowPastDates
}: {
  startDate: Date;
  allowFutureDates?: boolean;
  allowPastDates?: boolean;
}) => {
  const startOfCurrentMonth = startOfMonth(startDate);
  const endOfCurrentMonth = endOfMonth(startDate);
  const startOfLastMonth = subMonths(startOfCurrentMonth, 1);
  const endOfLastMonth = subDays(startOfCurrentMonth, 1);
  const startOfLastThreeMonths = subMonths(startDate, 3);

  let thisMonthRange = { from: startOfCurrentMonth, to: endOfCurrentMonth };

  if (!allowFutureDates && allowPastDates) {
    thisMonthRange = { from: startOfCurrentMonth, to: startDate };
  }
  if (!allowPastDates && allowFutureDates) {
    thisMonthRange = { from: startDate, to: endOfCurrentMonth };
  }
  if (!allowPastDates && !allowFutureDates) {
    thisMonthRange = { from: startDate, to: startDate };
  }

  return [
    { title: 'Today', range: { from: startDate, to: startDate } },
    { title: 'Yesterday', range: { from: subDays(startDate, 1), to: subDays(startDate, 1) } },
    { title: 'Last 7 Days', range: { from: subDays(startDate, 7), to: startDate } },
    { title: 'Last 30 Days', range: { from: subDays(startDate, 30), to: startDate } },
    { title: 'This Month', range: thisMonthRange },
    { title: 'Last Month', range: { from: startOfLastMonth, to: endOfLastMonth } },
    { title: 'Last 3 Months', range: { from: startOfLastThreeMonths, to: startDate } }
  ];
};
