import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { truncate } from 'lodash-es';
import { useDocumentTitle } from '@mantine/hooks';
import { Skeleton } from '@mantine/core';
import { useViewStore } from '@common/store/view';
import { useUserRole } from '@common/hooks/useUserRole';
import { MarketFactorChart } from '@pages/Client/PriceChart/components/MarketFactorChart';
import { usePriceChartStore } from '@pages/Client/PriceChart/store/priceChart';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { Typography } from '@common/components/foundations/Typography';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { DISABLED_MAX_MARKET_LEVEL } from '@pages/Client/Calendar/constants';

export const MarketFactor = () => {
  const { t } = useTranslation();
  const { isAdmin } = useViewStore();
  const { isPartner } = useUserRole();
  const { view, liliusInstance } = usePriceChartStore();
  const { features } = useFeaturesStore();
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay, surgeEvents } = useSurgeEvents();
  useDocumentTitle(t('Market Factor'));

  const {
    hotelDetails,
    query: { isLoading: isLoadingHotel }
  } = useHotelDetails();

  const {
    sortedHotelRooms,
    query: { isFetching: isLoadingSortedHotelRooms }
  } = useHotelRoomsList();

  const roomTypeOptions = sortedHotelRooms?.map((room) => ({
    label: `${truncate(room.name, { length: 30 })} (${
      room.is_reference_room ? t('Reference') : t('Derived')
    })`,
    value: room.id.toString()
  }));

  const { cachePriceQuery, pricingSettings } = useRoomPrices();
  const { data: cachePriceData, isLoading: isCachePriceLoading } = cachePriceQuery;

  const referenceRoomId = pricingSettings?.rooms?.reference?.id;

  const isSurgeEvents =
    surgeEvents?.results.some((surgeEvent) => surgeEvent.active) &&
    features?.includes(Feature.SurgeProtection);

  const isMarketFactorVisible = isAdmin || isPartner;

  if (!isMarketFactorVisible || !referenceRoomId) return null;

  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="flex items-end justify-between">
        <div className="flex items-end">
          <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
            {t('Market Factor')}
          </Typography>
        </div>

        <div className="flex items-end justify-end gap-1">
          {/* Skeleton wrapper is not working properly, this way was needed to prevent a crash.
            Will further check later. */}
          {isLoadingSortedHotelRooms || isLoadingHotel ? (
            <div className="flex flex-col gap-2">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="h-10 w-40" />
            </div>
          ) : (
            <Dropdown
              disabled
              label={t(`${hotelDetails?.room_apartment_space_name} Type`) as string}
              selectedOption={roomTypeOptions?.find(
                (option) => option.value === referenceRoomId.toString()
              )}
              options={
                pricingSettings?.hotel.all_room_pricing ? roomTypeOptions : [roomTypeOptions[0]]
              }
              onOptionClick={() => null}
            />
          )}
        </div>
      </div>
      <MarketFactorChart
        isLoading={isCachePriceLoading}
        referenceRoomId={referenceRoomId}
        priceRecommendation={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data)?.map(([key, value]) => {
                const { surgePrice, isSurgePrice } = getSurgePriceForDay(key, referenceRoomId);
                const isSurgeEvent = getSurgeEventByDay(dayjs(key).toDate())?.active ?? false;
                return {
                  date: key,
                  value:
                    isSurgePrice && isSurgeEvent && surgePrice
                      ? surgePrice
                      : value[referenceRoomId]?.price
                };
              })
            : []
        }
        marketPriceIndex={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data)?.map(([key, value]) => {
                let weightedAveragePrice: number | null =
                  value[referenceRoomId]?.weighted_average_price;

                if (weightedAveragePrice === DISABLED_MAX_MARKET_LEVEL) {
                  weightedAveragePrice = null;
                }
                return {
                  date: key,
                  value: weightedAveragePrice
                };
              })
            : []
        }
        hotelPriceIndex={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data)?.map(([key, value]) => {
                return {
                  date: key,
                  value: value['property']?.weighted_competitor_price
                };
              })
            : []
        }
        airbnbPriceIndex={
          cachePriceData?.data?.prices?.data
            ? Object.entries(cachePriceData?.data.prices.data)?.map(([key, value]) => {
                return {
                  date: key,
                  value: value['property']?.weighted_airdna_price
                };
              })
            : []
        }
        surgeEvent={
          isSurgeEvents
            ? surgeEvents?.results?.map((surgeEvent) => {
                return {
                  date: surgeEvent.date,
                  value: surgeEvent.active
                };
              }) ?? []
            : []
        }
        isMonthly={view === 'monthly'}
        currentMonth={dayjs(liliusInstance?.viewing).tz().format('YYYY-MM')}
      />
    </div>
  );
};
