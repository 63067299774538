import * as z from 'zod';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';

export const baseOccupancySchema = z.object({
  id: z.number(),
  basePrice: z.number(),
  baseValue: z.union([z.number(), z.nan(), z.null()]).optional(),
  derivation: z.number().or(z.string()),
  isBulkEdit: z.boolean().default(true),
  occupancy: z.number().or(z.string()),
  room: z.any(),
  value: z.union([z.number(), z.nan(), z.null()]).optional()
});

/**
 * Validation Logic for Occupancy Schema:
 *
 * - Ensures that the 'value' does not have decimal places.
 *   - If it has decimals, the message 'Value is not allowed.' is shown.
 *
 */

export const baseSchemaOccupancyAdjustmentSchema = baseOccupancySchema
  .transform((data) => {
    return {
      ...data,
      value: data.value ? Math.abs(Number(data.value)) : data.value
    };
  })
  .refine((val) => validateNoDecimals(val?.value), {
    message: 'Value is not allowed.'
  });

export type Occupancy = z.infer<typeof baseSchemaOccupancyAdjustmentSchema>;
