import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { useLogout } from '@common/store/auth';
import { useNavigate } from 'react-router-dom';

export const FullscreenLoading = ({ withLogout = false }) => {
  const navigate = useNavigate();
  const logoutUser = useLogout();

  function handleSignOut() {
    logoutUser();
    navigate('/login');
  }
  return (
    <div className="bg-black fixed left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-opacity-50">
      <button
        className="flex items-center gap-x-4"
        title="RoomPriceGenie"
        onClick={withLogout ? handleSignOut : undefined}>
        <div className="h-auto w-10 animate-rpg-bounce duration-700 xl:w-20">
          <Genie className="fill-indigo" />
        </div>
        <div className="h:16 w-16 xl:h-28 xl:w-28">
          <RoomPriceGenieText className="fill-indigo" />
        </div>
      </button>
    </div>
  );
};
