import { Typography } from '@common/components/foundations/Typography';
import { Disclosure, DisclosureProps } from '@headlessui/react';
import { Icon } from '@common/components/foundations/icons';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import styles from '@common/components/molecules/Accordion/Accordion.module.css';

interface AccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;

  borderless?: boolean;

  className?: string;
  toggleButtonClassName?: string;
  bodyClassName?: string;

  disclosureProps?: DisclosureProps<any>;
}

export function Accordion({
  title,
  children,
  borderless = false,
  className,
  toggleButtonClassName,
  bodyClassName,
  disclosureProps
}: AccordionProps) {
  const [parent] = useAutoAnimate({
    duration: 150,
    easing: 'ease-out'
  });

  return (
    <Disclosure {...disclosureProps}>
      {({ open }) => (
        <div
          className={clsx(styles.accordionBase, borderless && styles.borderless, className)}
          ref={parent}>
          <Disclosure.Button className={clsx(styles.toggleButton, toggleButtonClassName)}>
            <Typography element="span" variant="nav" color="copyTextGrey">
              {title}
            </Typography>
            {open ? (
              <Icon.ChevronUp className="h-5 w-5 fill-copyTextGrey" />
            ) : (
              <Icon.ChevronDown className="h-5 w-5 fill-copyTextGrey" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className={clsx(styles.body, bodyClassName)}>
            {children}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}
