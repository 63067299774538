import { ChangePassword, UpdateProfile } from '@common/api/account/types';
import { useLogout, useAuthStore } from '@common/store/auth';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useLocation } from 'react-router-dom';
import { useChangePassword, useUpdateProfile } from '@pages/Admin/Profile/hooks/useProfile';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Page } from '@common/components/organisms/Page';
import { Header } from '@common/components/molecules/Header/Header';
import { Icon } from '@common/components/foundations/icons';
import { specialCharRegex } from '@common/constants/password';
import { Button } from '@mantine/core';

const userSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email()
});
const languageSchema = z.object({
  language: z.string()
});
const numberRegex = /^(?=.*\d).{8,50}$/;
const passwordSchema = z.object({
  old_password: z.string(),
  new_password: z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(50)
    .refine((value) => /[A-Z]/.test(value), {
      message: 'Password must contain at least one uppercase letter',
      path: ['new_password']
    })
    .refine((value) => numberRegex.test(value), {
      message: 'Password must contain at least one number',
      path: ['new_password']
    })
    .refine((value) => specialCharRegex.test(value), {
      message: 'Password must contain at least one special character',
      path: ['new_password']
    })
});

const mergedSchema = z.object({
  ...userSchema.shape,
  ...languageSchema.shape,
  ...passwordSchema.shape
});

type FormValues =
  | z.infer<typeof passwordSchema>
  | z.infer<typeof languageSchema>
  | z.infer<typeof userSchema>
  | z.infer<typeof mergedSchema>;

export const ProfileLayout = () => {
  const location = useLocation();
  const { token } = useAuthStore();
  const logoutUser = useLogout();
  const {
    updateProfile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess
  } = useUpdateProfile();
  const {
    changePassword,
    isLoading: isChangePasswordLoading,
    isSuccess: isChangePasswordSuccess
  } = useChangePassword();
  const isPasswordPath = location.pathname.includes('password');
  const isPersonalInfoPath = location.pathname.includes('personal-information');
  const isLanguagePath = location.pathname.includes('language');
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  useEffect(() => {
    setIsSuccessRef({ current: isChangePasswordSuccess || isProfileSuccess });
    if (isChangePasswordSuccess || isProfileSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isChangePasswordSuccess, isProfileSuccess]);

  const getSchema = () => {
    if (isPasswordPath) {
      return passwordSchema;
    } else if (isPersonalInfoPath) {
      return userSchema;
    } else if (isLanguagePath) {
      return languageSchema;
    } else {
      return userSchema;
    }
  };

  const methods = useForm<FormValues>({
    resolver: zodResolver(getSchema())
  });

  return (
    <FormProvider {...methods}>
      <Page
        header={
          <Header
            title="My Profile"
            tabs={[
              {
                id: 'profile-personal-information',
                label: 'Personal Information',
                to: 'personal-information'
              },
              {
                id: 'profile-password',
                label: 'Password',
                to: 'password'
              },
              {
                id: 'profile-language',
                label: 'Language',
                to: 'language'
              }
            ]}
            description="Manage your profile preferences."
            actions={
              <div className="flex gap-x-2">
                <Button
                  loading={isProfileLoading || isChangePasswordLoading}
                  loaderProps={{ type: isSuccessRef.current ? 'success' : 'ring' }}
                  disabled={isProfileLoading || isChangePasswordLoading}
                  onClick={methods.handleSubmit((data: any) => {
                    if (isPasswordPath) {
                      const dataPassword = {
                        token,
                        old_password: methods.watch('old_password'),
                        new_password: methods.watch('new_password')
                      };
                      changePassword(dataPassword as ChangePassword);
                    } else {
                      const dataProfile = data;
                      updateProfile(dataProfile as UpdateProfile);
                    }
                  })}>
                  Save
                </Button>
                <Button variant="outline" onClick={logoutUser}>
                  <Icon.SignOut className="h-5 w-5" />
                  Logout
                </Button>
              </div>
            }
          />
        }>
        <Outlet />
      </Page>
    </FormProvider>
  );
};
