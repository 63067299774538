import { hlsPmsInitRooms } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useInitRoomLoading } from '@pages/Client/Account/Integrations/store/initRoom';
import { useMutation } from '@tanstack/react-query';

export const useHlsPmsInitRooms = () => {
  const { addNotification } = useNotificationsStore();
  const { setLoading } = useInitRoomLoading();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return hlsPmsInitRooms();
    },
    onSuccess: (response) => {
      addNotification(
        'success',
        `${response.data.message ?? `Please wait.. Rooms are being initialized.`}`
      );
      setLoading(true);
    },
    onError: () => {
      addNotification('fail', `Failed to initialize rooms`);
    }
  });

  return {
    hlsPmsInitRooms: mutate,
    isLoading
  };
};
