import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import CalendarPricingPage from '@pages/Client/Calendar/pages/Pricing';
import CalendarOccupancyPage from '@pages/Client/Calendar/pages/Occupancy';
import CalendarPickupPage from '@pages/Client/Calendar/pages/Pickup';
import CalendarRevenuePage from '@pages/Client/Calendar/pages/Revenue';
import { Feature } from '@common/store/features';

export const calendarRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="pricing" replace /> },
  {
    path: 'pricing',
    element: (
      <SecureRoute>
        <CalendarPricingPage />
      </SecureRoute>
    )
  },
  {
    path: 'occupancy',
    element: (
      <SecureRoute>
        <CalendarOccupancyPage />
      </SecureRoute>
    )
  },
  {
    path: 'pickup',
    element: (
      <SecureRoute feature={Feature.PickupCalendar}>
        <CalendarPickupPage />
      </SecureRoute>
    )
  },
  {
    path: 'revenue',
    element: (
      <SecureRoute feature={Feature.ProfessionalDashboard}>
        <CalendarRevenuePage />
      </SecureRoute>
    )
  }
];
