import { Input } from '@common/components/atoms/Input';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAnalyticsDashboardFilters } from '@pages/Client/Dashboard/hooks/useAnalyticsDashboardCache';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import {
  convertToOptionsArray,
  SegmentationData
} from '@pages/Client/PricingStrategy/Segmentation/Segmentation';
import dayjs from 'dayjs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const schema = z.object({
  name: z.string().nonempty({ message: 'Segment name is required' }),
  segment_tags: z.array(z.string())
});

export const AddNewSegmentModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { hasKeyValueSegmentation } = usePmsProvider();

  const { control, reset, handleSubmit, formState, setValue } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { pricingSettingsQuery } = useRoomPrices();
  const { analyticsDashboardFilters } = useAnalyticsDashboardFilters();
  const { savePricingSettings } = useUpdatePricingSettings();

  const segmentationData = (analyticsDashboardFilters?.segments as SegmentationData) || {};
  const availableTags = convertToOptionsArray(segmentationData);

  const defaultAdditional = {
    segments_settings_corrections: false,
    segments_price_estimate: false,
    segments_pickupboost: false
  };

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const keyName = dayjs().format('YYYYMMDDHHmmss') + id;

    const getSegmentValue = (keyOrValue: string | number) => {
      return segmentationData[keyOrValue];
    };

    const segmentTags = [...data.segment_tags, ...data.segment_tags.map(getSegmentValue)];

    const newSegment = {
      [keyName]: {
        ...data,
        ...defaultAdditional,
        segment_tags: hasKeyValueSegmentation() ? segmentTags : data.segment_tags
      }
    };

    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
    const newSegmentation = {
      ...latestPricingSettings?.segmentation,
      ...newSegment
    };

    const newSettings = {
      ...latestPricingSettings,
      segmentation: newSegmentation
    };
    await savePricingSettings(JSON.stringify(newSettings));

    reset();
  };

  return (
    <Modal
      okText={`${t('Save')}`}
      continueIntent="primary"
      size="xl"
      open={isOpen}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      onClose={onClose}
      children={
        <div className="max-w-[400px]">
          <div className="min-w-[380px]">
            <Typography className="mb-6" element="h6" color="darkGrey" variant="h6">
              {t('Add New Segment')}
            </Typography>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  name="subject"
                  background="grey"
                  className="w-full"
                  type="text"
                  value={value}
                  label={`${t('Segment Name')}*`}
                  placeholder={`${t('Please enter segment name')}`}
                  onChange={onChange}
                  onClear={() => setValue('name', '')}
                  error={!!error}
                  helperMessage={
                    error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )
                  }
                />
              )}
            />
            <div className="my-4">
              <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                {t('Tags')}*
              </Typography>
              <Controller
                control={control}
                name="segment_tags"
                render={({ field: { value, name, onChange } }) => (
                  <MultiSelect
                    fullWidth
                    inputClassName="py-[14px]"
                    placeholder={`${t('Select tags')}`}
                    hint={availableTags.length > 0 ? 'Available Tags' : 'No tags available'}
                    name={name}
                    options={availableTags}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
