import { setAuthToken } from '@common/api/axiosInstance';
import { Icon } from '@common/components/foundations/icons';
import ApplicationShell from '@common/components/organisms/ApplicationShell';
import { ProtectedLayout } from '@common/layouts/ProtectedLayout';
import { useAuthStore } from '@common/store/auth';
import { FC, Suspense, SVGProps, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { FullscreenLoading } from '@pages/Admin/components/FullscreenLoading';
import { IconManualGearboxFilled } from '@tabler/icons-react';

export const AdminLayout = () => {
  const { user: userData, token } = useAuthStore();

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  const userEmail = userData?.email || 'Loading...';
  const isPartnerUser = userData?.sub_account_type === 2;
  const adminInSales = userData?.sub_account_type === 1 && userData?.is_staff;

  const navigation = [
    {
      title: 'Client Overview',
      path: '/admin/clients',
      icon: Icon.AccountCircle
    },
    { title: 'Create Property', path: '/admin/client-registration/', icon: Icon.GroupAdd },
    {
      title: 'Scrape',
      icon: Icon.SettingsVs,
      subNavigation: [
        {
          title: 'Scrape List',
          path: '/admin/scrape-list/'
        },
        {
          title: 'New Hotel',
          path: '/admin/scraper/'
        },
        {
          title: 'On Demand Schedule',
          path: '/admin/on-demand/'
        },
        {
          title: 'Schedule History',
          path: '/admin/schedule-history'
        }
      ]
    },
    { title: 'Partner Users', path: '/admin/resellers', icon: Icon.GroupJunior },
    ...(adminInSales
      ? []
      : [
          {
            title: 'Integration Partners',
            path: '/admin/integration-partners',
            icon: IconManualGearboxFilled as FC<SVGProps<SVGSVGElement>>
          }
        ]),
    { title: 'RPG Users', path: '/admin/admin-users', icon: Icon.AccountSquare },
    {
      title: 'Error Tickets',
      icon: Icon.Error,
      path: '/admin/error-tickets'
    },
    {
      title: 'Dashboard Error Tickets',
      icon: Icon.Error,
      path: '/admin/dashboard-error-tickets'
    }
  ];

  const userProfile = {
    email: userEmail,
    path: '/admin/profile'
  };

  const filterNavigation = isPartnerUser
    ? navigation.filter(
        (item) =>
          item.title === 'Login to Client' ||
          item.title === 'Partner Users' ||
          item.title === 'Create Property'
      )
    : navigation;

  return (
    <Suspense fallback={<FullscreenLoading />}>
      <ProtectedLayout type="admin">
        <ApplicationShell
          isAdmin={true}
          navigation={filterNavigation}
          userProfile={userProfile}
          isLoading={false}>
          <Outlet />
        </ApplicationShell>
      </ProtectedLayout>
    </Suspense>
  );
};
