import { Paper, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const AccessDeniedPage = () => {
  const { t } = useTranslation();

  return (
    <Stack h="100vh" p="md" align="center" justify="center">
      <Paper
        withBorder
        p="xl"
        style={{ borderStyle: 'dashed', borderColor: 'var(--mantine-color-red-9)' }}
        bg="transparent"
        maw="400px"
      >
        <Stack align="center" gap="xs">
          <ThemeIcon size="xl" c="red.9" variant="transparent">
            <IconAlertTriangle />
          </ThemeIcon>
          <Stack align="center" gap="xs">
            <Title size="20px" c="red.9">
              {t('Access Denied')}
            </Title>
            <Text size="sm" c="red.9" ta="center">
              {t('You do not have access to this page.')}
            </Text>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};
