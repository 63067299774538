export function ArrowCircleRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM14.85 11.64L11.85 8.64C11.7058 8.4987 11.4909 8.45793 11.305 8.53665C11.1191 8.61538 10.9988 8.79815 11 9V15C11.0028 15.2 11.1246 15.3791 11.3096 15.4553C11.4946 15.5315 11.7071 15.4901 11.85 15.35L14.85 12.35C15.0418 12.1522 15.0418 11.8378 14.85 11.64Z"
      />
    </svg>
  );
}
