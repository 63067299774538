import { create } from 'zustand';
interface DiagnosticStore {
  showButton: boolean;
  setShowButton: (newShowButton: boolean) => void;
  downloadUrl: string | null;
  setDownloadUrl: (newUrl: string) => void;
  isDiagnosticsEnabled: boolean;
  setIsDiagnosticsEnabled: (newIsDiagnosticsEnabled: boolean) => void;
}

export const useDiagnosticsStore = create<DiagnosticStore>((set) => ({
  showButton: false,
  setShowButton: (newShowButton: boolean) => set({ showButton: newShowButton }),
  downloadUrl: null,
  setDownloadUrl: (newUrl: string) => set({ downloadUrl: newUrl }),
  isDiagnosticsEnabled: false,
  setIsDiagnosticsEnabled: (newIsDiagnosticsEnabled: boolean) =>
    set({ isDiagnosticsEnabled: newIsDiagnosticsEnabled })
}));
