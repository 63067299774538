import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { Header } from '@common/components/molecules/Header/Header';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { Table } from '@common/components/molecules/Table';
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Tooltip } from '@radix-ui/react-tooltip';

import { Page } from '@common/components/organisms/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';

import { browserTimezone } from '@common/utils/browserTimezone';
import { cn } from '@common/utils/cn';
import { useDocumentTitle } from '@mantine/hooks';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import {
  useDownloadOutboundApiLogs,
  useOutboundApiLogs
} from '@pages/Client/OutboundApiLog/hooks/useOutboundApiLogs';
import { useGetHasMetadataStore } from '@pages/Client/OutboundApiLog/store/download';
import { IconUserCircle } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  id: string;
  desc: boolean;
}
dayjs.extend(utc);
dayjs.extend(timezone);

const REQUEST_TYPE = [
  { value: 1, label: 'Upload Price', background: 'indigo' },
  { value: 2, label: 'Inventory', background: 'darkGreen' },
  { value: 3, label: 'Price', background: 'orange' },
  { value: 4, label: 'Room Rate', background: 'darkGrey' },
  { value: 5, label: 'Reservation', background: 'uiGreen' },
  { value: 6, label: 'Authentication', background: 'gold' },
  { value: 8, label: 'Pull Price ', background: 'uiRed' }
];

const schema = z.object({
  page: z.number(),
  search: z.string().optional(),
  request_type: z.number().optional(),
  date: z.string().optional(),
  ordering: z.string().optional(),
  is_success: z.string().optional()
});

type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== '' && value !== undefined && value !== null
    )
  ) as Params;
};

export const OutboundApiLog = () => {
  useDocumentTitle('Outbound API Log');
  const defaultValues = {
    page: 1,
    search: undefined,
    request_type: undefined,
    date: undefined,
    ordering: '-created',
    is_success: undefined
  };

  const { hotelDetails } = useHotelDetails();

  const [clickedButtons, setClickedButtons] = useState<string[]>([]);

  const { watch, reset, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const {
    // hotelOutboundApiLogs,
    query: { isLoading, data: hotelOutboundApiLogs },
    refetchLogs,
    isRefetchLoading
  } = useOutboundApiLogs(
    filterEmptyValues({
      page: watch('page'),
      search: getValues('search'),
      request_type: watch('request_type'),
      date: watch('date') ? dayjs(watch('date')).format('YYYY-MM-DD') : undefined,
      ordering: watch('ordering'),
      is_success: watch('is_success')
    })
  );

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...watch(),
          ordering: watch('ordering'),
          [field]: value
        })
      );
    },
    [reset, watch, filterEmptyValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const { getOutboundApiLogs, isLoading: isDownloadLoading } = useDownloadOutboundApiLogs();
  const { isHotelUseIntegrationV2 } = usePmsProvider();

  const { t } = useTranslation();

  const [indexLoading, setIndexLoading] = useState(null);
  const [requestType, setRequestType] = useState(0);
  const { setHasMetadata, setIsAllLogs } = useGetHasMetadataStore();

  const ROWS_PER_PAGE = 20;

  const startIndex = hotelOutboundApiLogs?.current
    ? (hotelOutboundApiLogs.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    hotelOutboundApiLogs?.current && hotelOutboundApiLogs.results
      ? startIndex + hotelOutboundApiLogs.results.length - 1
      : 0;

  const handleNext = () => {
    if (!hotelOutboundApiLogs?.next) return;
    const params = new URLSearchParams(hotelOutboundApiLogs.next.split('?')[1]);
    const page = params.get('page');
    const date = params.get('date');
    const search = params.get('search');
    const ordering = params.get('ordering');
    const status = params.get('is_success');
    const request_type = params.get('request_type');
    if (!page) return;
    reset(
      filterEmptyValues({
        page: Number(page),
        date: date || undefined,
        search: search || undefined,
        request_type: (request_type as unknown as number) || undefined,
        ordering: (ordering as string) || undefined,
        is_success: status || undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...watch(),
        page: previousPage
      })
    );
  };

  const columns = [
    {
      header: 'Received at',
      accessorKey: 'created',
      cell: (row: any) => {
        const isManualProgram = !row?.row?.original?.is_auto_program;
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div className="flex flex-col items-center space-y-1">
                    <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                      {dayjs(row?.getValue()).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}
                    </Typography>
                    {isManualProgram && isHotelUseIntegrationV2 ? <IconUserCircle /> : null}
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Hotel Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(hotelDetails?.timezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className="pr-2">
                          <Typography element="span" variant="paragraph-3" color="white">
                            Browser Time:
                          </Typography>
                        </td>
                        <td>
                          <Typography element="span" variant="paragraph-3" color="white">
                            {dayjs(row?.getValue())
                              .tz(browserTimezone)
                              .format('YYYY-MM-DD HH:mm:ss')}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Request Type',
      accessorKey: 'request_type',
      cell: (row: any) => {
        const requestType = row?.getValue() as string;
        const requestTypeInfo = REQUEST_TYPE.find((type) => type.label === requestType);
        return (
          <div className="flex items-center gap-3">
            <Badge background={requestTypeInfo?.background || 'grey'}>{requestType}</Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Unique Log',
      accessorKey: 'unique_log_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" variant="paragraph-3" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Dates',
      accessorKey: 'date_list',
      cell: (row: any) => {
        const dateList = row?.getValue() as string[];
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                    {dateList?.slice(0, 3)?.join(',')}
                    {dateList?.length > 3 && '...'}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <span className="max-w-sm">
                    {dateList?.map((value, index, arr) => (
                      <React.Fragment key={index}>
                        {value}
                        {index !== arr.length - 1 && ','}
                        {(index + 1) % 4 === 0 && <br />}
                      </React.Fragment>
                    ))}
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Room Types',
      accessorKey: 'room_type_list',
      cell: (row: any) => {
        const roomTypeList = row?.getValue() as string[];
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                    {roomTypeList?.slice(0, 3)?.join(',')}
                    {roomTypeList?.length > 3 && '...'}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <span className="max-w-sm">
                    {roomTypeList?.map((value, index, arr) => (
                      <React.Fragment key={index}>
                        {value}
                        {index !== arr.length - 1 && ','}
                        {(index + 1) % 4 === 0 && <br />}
                      </React.Fragment>
                    ))}
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Rate Plans',
      accessorKey: 'rate_plan_list',
      cell: (row: any) => {
        const ratePlanList = row?.getValue() as string[];
        return (
          <div className="flex items-center gap-3">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Typography element="span" variant="paragraph-3" color="copyTextGrey">
                    {ratePlanList.slice(0, 3)?.join(',')}
                    {ratePlanList.length > 3 && '...'}
                  </Typography>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10} className="w-full">
                  <span className="max-w-sm">
                    {ratePlanList?.map((value, index, arr) => (
                      <React.Fragment key={index}>
                        {value}
                        {index !== arr.length - 1 && ','}
                        {(index + 1) % 4 === 0 && <br />}
                      </React.Fragment>
                    ))}
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Status',
      accessorKey: 'is_success',
      cell: (row: any) => {
        const statusCode = row?.row?.original?.response_status_code;
        return (
          <div className="flex items-center gap-3">
            {(statusCode as number) ? (
              <Badge background={(row?.getValue() as boolean) ? 'darkGreen' : 'uiRed'}>
                {' '}
                {statusCode as number}
              </Badge>
            ) : (
              ''
            )}
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Download',
      accessorKey: 'in_datetime',
      cell: (row: any) => {
        const { original, index } = row.row;
        const hasMetadata = original?.has_metadata;
        return (
          <div className="flex flex-col justify-start gap-2">
            <div className="flex  items-start gap-3 ">
              <Button
                size="small"
                intent="text"
                className={cn(
                  'text-indigo text-opacity-90',
                  clickedButtons.includes(`request-${original?.unique_log_id}`)
                    ? 'text-opacity-40'
                    : ''
                )}
                onClick={() => {
                  setHasMetadata(false);
                  setIsAllLogs(false);
                  setRequestType(1);
                  setIndexLoading(index);
                  setClickedButtons([...clickedButtons, `request-${original?.unique_log_id}`]);
                  getOutboundApiLogs({
                    id: original?.id,
                    unique_log_id: original?.unique_log_id,
                    request_type: 1
                  });
                }}
                isLoading={isDownloadLoading && indexLoading === index && requestType === 1}>
                <Icon.Download className="h-4 w-4" />
                Request
              </Button>
              <Button
                size="small"
                intent="text"
                className={cn(
                  'text-darkGreen',
                  clickedButtons.includes(`response-${original?.unique_log_id}`)
                    ? 'text-opacity-40'
                    : ''
                )}
                onClick={() => {
                  setHasMetadata(false);
                  setIsAllLogs(false);
                  setRequestType(2);
                  setIndexLoading(index);
                  setClickedButtons([...clickedButtons, `response-${original?.unique_log_id}`]);
                  getOutboundApiLogs({
                    id: original?.id,
                    unique_log_id: original?.unique_log_id,
                    request_type: 2
                  });
                }}
                isLoading={isDownloadLoading && indexLoading === index && requestType === 2}>
                <Icon.Download className="h-4 w-4" />
                Response
              </Button>
            </div>
            <div className="flex items-start gap-3">
              {hasMetadata ? (
                <Button
                  size="small"
                  intent="text"
                  className={cn(
                    'text-indigo-darker',
                    clickedButtons.includes(`metadata-${original?.unique_log_id}`)
                      ? 'text-opacity-40'
                      : ''
                  )}
                  onClick={() => {
                    setHasMetadata(true);
                    setRequestType(3);
                    setIndexLoading(index);
                    setClickedButtons([...clickedButtons, `metadata-${original?.unique_log_id}`]);
                    getOutboundApiLogs({
                      id: original?.id,
                      unique_log_id: original?.unique_log_id,
                      request_type: 3
                    });
                  }}
                  isLoading={isDownloadLoading && indexLoading === index && requestType === 3}>
                  <Icon.Download className="h-4 w-4" />
                  Metadata
                </Button>
              ) : null}
              <Button
                size="small"
                intent="text"
                className={cn(
                  'text-darkGrey',
                  clickedButtons.includes(`all-${original?.unique_log_id}`) ? 'text-opacity-40' : ''
                )}
                onClick={() => {
                  setIsAllLogs(true);
                  setRequestType(0);
                  setIndexLoading(index);
                  setClickedButtons([...clickedButtons, `all-${original?.unique_log_id}`]);
                  getOutboundApiLogs({
                    id: original?.id,
                    unique_log_id: original?.unique_log_id,
                    request_type: 0
                  });
                }}
                isLoading={isDownloadLoading && indexLoading === index && requestType === 0}>
                <Icon.Download className="h-4 w-4" />
                All Logs
              </Button>
            </div>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <Page header={<Header title="Outbound Api Log" />}>
      <div>
        <Table
          isHover
          isFetching={isLoading}
          skeletonCount={20}
          next={handleNext}
          isNext={!!hotelOutboundApiLogs?.next}
          previous={handlePrevious}
          isPrevious={watch('page') > 1}
          columns={columns}
          data={!isLoading ? (hotelOutboundApiLogs?.results as any) : []}
          count={`${startIndex}-${endIndex}`}
          totalCount={hotelOutboundApiLogs?.count}
          manualPagination={true}
          rowsPerPage={ROWS_PER_PAGE}
          manualSorting={true}
          onSortingChange={(value) => {
            const values = value as HeaderProps[];
            if (values[0].id === 'in_datetime') return;
            if (watch('ordering') === values[0].id) {
              setValue('ordering', `-${values[0].id}`);
            } else {
              setValue('ordering', values[0].id);
            }
          }}
          sorting={[
            {
              id: 'created',
              desc: watch('ordering') === 'created'
            },
            {
              id: 'request_type',
              desc: watch('ordering') === 'request_type'
            },
            {
              id: 'unique_log_id',
              desc: watch('ordering') === 'unique_log_id'
            },
            {
              id: 'date_list',
              desc: watch('ordering') === 'date_list'
            },
            {
              id: 'room_type_list',
              desc: watch('ordering') === 'room_type_list'
            },
            {
              id: 'rate_plan_list',
              desc: watch('ordering') === 'rate_plan_list'
            },
            {
              id: 'is_success',
              desc: watch('ordering') === 'is_success'
            }
          ]}
          actionComponents={
            <div className="flex items-center gap-6">
              <div>
                <Controller
                  name="search"
                  control={control}
                  render={({ field: { value, name, onChange } }) => (
                    <Input
                      background="grey"
                      placeholder="Search"
                      name={name}
                      id={name}
                      type="text"
                      value={value}
                      onChange={(e) => {
                        handleSearch(e.target.value);
                        setValue('page', 1);
                        onChange(e);
                      }}
                      onClear={() => {
                        reset(defaultValues);
                      }}
                    />
                  )}
                />
              </div>
              <div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button icon onClick={refetchLogs} className="group relative">
                        {isRefetchLoading ? (
                          <Icon.LoadingCircle className="mr-0 h-6 w-6" />
                        ) : (
                          <Icon.Refresh />
                        )}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="top" className="bg-white text-darkGrey">
                      Reload
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div>
                <RPGPopover>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <RPGPopover.Button
                          as={Button}
                          type="button"
                          icon
                          className="group relative">
                          <Icon.FilterList />
                        </RPGPopover.Button>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="bg-white text-darkGrey">
                        Filter
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <RPGPopover.Panel>
                    <div className="mb-2 flex items-center gap-3">
                      <Typography className="text-meta-1-medium" color="darkGrey">
                        {t('Filters')}
                      </Typography>
                      <Button type="button" intent="text" onClick={() => reset(defaultValues)}>
                        {t('Reset')}
                      </Button>
                    </div>
                    <div className="grid grid-cols-1 gap-2">
                      <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                        Filter Request Type
                      </Typography>
                      <Controller
                        control={control}
                        name="request_type"
                        render={({ field: { value, name, onChange } }) => (
                          <SelectDropdown
                            fullWidth
                            placeholder="Select Request Type"
                            name={name}
                            key={name}
                            value={value || ''}
                            options={REQUEST_TYPE}
                            onChange={(value) => {
                              onChange(value);
                              onResetAndSet('request_type', value);
                              setValue('page', 1);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="mt-3 grid grid-cols-1 gap-2">
                      <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                        Filter Status Request
                      </Typography>
                      <Controller
                        control={control}
                        name="is_success"
                        render={({ field: { value, name, onChange } }) => (
                          <SelectDropdown
                            fullWidth
                            placeholder="Select Status Request"
                            name={name}
                            key={name}
                            value={value}
                            options={[
                              { value: 'True' as string, label: 'Success' },
                              { value: 'False', label: 'Failed' }
                            ]}
                            onChange={(value) => {
                              onChange(value);
                              onResetAndSet('is_success', value);
                              setValue('page', 1);
                            }}
                          />
                        )}
                      />
                    </div>
                  </RPGPopover.Panel>
                </RPGPopover>
              </div>
              <div>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      ISOWeek={hotelDetails?.starts_monday}
                      onDateChange={(date) => {
                        onChange(date);
                        setValue('page', 1);
                      }}
                      timezone={hotelDetails?.timezone}
                      closeOnSelect
                    />
                  )}
                />
              </div>
            </div>
          }
        />
      </div>
    </Page>
  );
};
