import { resetPassword, updateProfile } from '@common/api/account';
import { ChangePassword, UpdateProfile } from '@common/api/account/types';
import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useErrorProfileAdminChangePasswordStore } from '@pages/Admin/Profile/store/profile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useUpdateProfile = () => {
  const { addNotification } = useNotificationsStore();
  const { token, user, setUser } = useAuthStore();
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: async (data: UpdateProfile) => {
      setAuthToken(token);
      const response = await updateProfile(data);
      return response;
    },
    onSuccess: (data) => {
      setUser({ ...user, ...data.data });
      i18n.changeLanguage(data.data.language);
      queryClient.invalidateQueries({
        queryKey: [UtilQueryKeys.USER_DETAIL]
      });
      addNotification('success', 'Successfully updated profile');
    },
    onError: () => {
      addNotification('fail', 'Failed to update profile');
    }
  });

  return {
    updateProfile: mutate,
    isLoading,
    isSuccess
  };
};

export const useChangePassword = () => {
  const { addNotification } = useNotificationsStore();
  const { token } = useAuthStore();
  const { setError, setMessage, setOldPasswordError, setOldPasswordMessage } =
    useErrorProfileAdminChangePasswordStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (data: ChangePassword) => {
      setAuthToken(token);
      return resetPassword(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully change password');
    },
    onError: (error: any) => {
      if (error.response.data.password) {
        setError(true);
        setMessage(error.response.data.password.join(','));
      }
      if (error.response.data.authorization) {
        setOldPasswordError(true);
        setOldPasswordMessage(error.response.data.authorization.message);
      }
      addNotification('fail', 'Failed to change password');
    }
  });

  return {
    changePassword: mutate,
    isLoading,
    isSuccess
  };
};
