import { Typography } from '@common/components/foundations/Typography';
import React from 'react';

export function InputHelperMessage({ icon, message }: { icon: React.ReactNode; message?: string }) {
  return (
    <div className="flex-start flex flex-row items-center justify-start space-x-1">
      {icon}
      {message && (
        <Typography color="error" variant="meta-2">
          {message}
        </Typography>
      )}
    </div>
  );
}
