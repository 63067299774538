import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface PMSUploadModal {
  modalState: boolean;
  isInterMediate: boolean;
  intermediateFunction: () => void;
  setModalState: (open: boolean) => void;
  setIntermediateFunction: (func: () => void) => void;
  toggleIsInterMediate: (intermediate: boolean) => void;
}

export const useUploadPMSPriceModalState = create<PMSUploadModal>()(
  immer((set) => ({
    modalState: false,
    isInterMediate: false,
    intermediateFunction: () => {},
    setModalState: (open) => set({ modalState: open }),
    setIntermediateFunction: (func) => set({ intermediateFunction: func }),
    toggleIsInterMediate: (intermediate) => set({ isInterMediate: intermediate })
  }))
);
