import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAddNewPartner } from '@pages/Admin/PartnerUsers/hooks/useAddPartner';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const schema = z.object({
  token: z.string().or(z.undefined()).nullable(),
  email: z.string().email(),
  name: z.string()
});

export const AddNewPartnerModal = ({ isOpen, onClose }: ModalProps) => {
  const { token } = useAuthStore();
  const { createPartner } = useAddNewPartner();

  const { control, reset, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      token
    }
  });

  const onSubmit = (data: any) => {
    createPartner(data);
  };

  return (
    <Modal
      okText="Save"
      continueIntent="primary"
      size="lg"
      open={isOpen}
      disableCloseOnOk={!formState.isValid}
      onClick={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        reset();
      }}
      children={
        <div>
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            Add New Partner
          </Typography>
          <div className="flex w-full flex-col justify-between gap-4 ">
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  name="subject"
                  background="grey"
                  type="text"
                  value={value}
                  label="Name *"
                  placeholder="Please enter name"
                  onChange={onChange}
                  onClear={() => reset({ name: '' })}
                  error={!!error}
                  helperMessage={
                    error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )
                  }
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  name="subject"
                  background="grey"
                  type="email"
                  value={value}
                  label="Email *"
                  placeholder="Please enter email"
                  onChange={onChange}
                  onClear={() => reset({ email: '' })}
                  error={!!error}
                  helperMessage={
                    error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )
                  }
                />
              )}
            />
          </div>
        </div>
      }
    />
  );
};
