export function ArrowForward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M2 12.4999V11.4999C2 11.2237 2.22386 10.9999 2.5 10.9999H18.67L14.22 6.55985C14.1253 6.46597 14.0721 6.33817 14.0721 6.20485C14.0721 6.07153 14.1253 5.94374 14.22 5.84985L14.93 5.14985C15.0239 5.0552 15.1517 5.00195 15.285 5.00195C15.4183 5.00195 15.5461 5.0552 15.64 5.14985L21.78 11.2799C21.9207 11.4204 21.9998 11.611 22 11.8099V12.1899C21.9977 12.3883 21.9189 12.5781 21.78 12.7199L15.64 18.8499C15.5461 18.9445 15.4183 18.9978 15.285 18.9978C15.1517 18.9978 15.0239 18.9445 14.93 18.8499L14.22 18.1399C14.1259 18.0477 14.0729 17.9215 14.0729 17.7899C14.0729 17.6582 14.1259 17.532 14.22 17.4399L18.67 12.9999H2.5C2.22386 12.9999 2 12.776 2 12.4999Z" />
    </svg>
  );
}
