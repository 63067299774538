import { Navigate, RouteObject } from 'react-router-dom';
import { Feature } from '@common/store/features';
import SurgeProtectionSettingsPage from '@pages/Client/PricingStrategy/SurgeProtection/pages/Settings';
import SurgeProtectionPriceSettingsPage from '@pages/Client/PricingStrategy/SurgeProtection/pages/PriceSettings';
import SurgeProtectionLogsPage from '@pages/Client/PricingStrategy/SurgeProtection/pages/Logs';
import { SecureRoute } from '@pages/Client/components/SecureRoute';

export const surgeProtectionRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="settings" replace /> },
  {
    path: 'settings',
    element: <SurgeProtectionSettingsPage />
  },
  {
    path: 'price-settings',
    element: (
      <SecureRoute feature={Feature.SurgeProtectionPro}>
        <SurgeProtectionPriceSettingsPage />
      </SecureRoute>
    )
  },
  {
    path: 'logs',
    element: (
      <SecureRoute feature={Feature.SurgeProtection}>
        <SurgeProtectionLogsPage />
      </SecureRoute>
    )
  }
];
