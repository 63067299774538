import { AdminAccessFeatures, Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface SecureNavLinkProps {
  feature?: number | number[];
}

export const SecureNavLink: React.FC<SecureNavLinkProps & NavLinkProps> = ({
  feature,
  ...props
}) => {
  const { features } = useFeaturesStore();
  const { view } = useViewStore();

  // Special condition for client view and feature 19.
  // If user is client and has feature 19, don't render the NavLink
  if (view === 'client' && features?.includes(19)) {
    return null;
  }

  // If there are no required features, return the NavLink.
  if (!feature) return <NavLink {...props} />;

  // Make sure feature is an array.
  const featureArray = Array.isArray(feature) ? feature : [feature];

  // Check if any required feature is in the features array.
  const hasAccess = featureArray.some((feat) => features?.includes(feat));

  // Check if any feature has admin access
  const hasAdminAccess = featureArray.some((feature) =>
    AdminAccessFeatures.has(feature as Feature)
  );

  if (view === 'admin' && (hasAccess || hasAdminAccess)) {
    return <NavLink {...props} />;
  }

  // If user has access, return the NavLink. Otherwise, return null.
  return hasAccess ? <NavLink {...props} /> : null;
};
