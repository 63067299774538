import React, { useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ScatterProps
} from 'recharts';
import dayjs from 'dayjs';
import { Card } from '@common/components/molecules/Card';
import { Typography } from '@common/components/foundations/Typography';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import clsx from 'clsx';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { useTranslation } from 'react-i18next';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';

type DataKey = 'tly' | 'stly' | 'total' | 'budget' | 'forecast';

interface Props {
  tly: { date: string; value: number }[];
  stly: { date: string; value: number }[];
  total: { date: string; value: number }[];
  budget?: { date: string; value: number }[];
  forecast?: { date: string; value: number }[];
  isCurrency?: boolean;
  onMonthDetailClick: (date: string) => void;
}

interface CustomBarProps {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  budget?: number;
  maxY: number;
  dataKey: DataKey;
  showMarker?: boolean;
  seriesVisibility: {
    [key: string]: boolean;
  };
  borderWidth?: number;
  borderRadius?: number;
}

export const MonthlyKPIChart: React.FC<Props> = ({
  tly,
  stly,
  total,
  budget,
  forecast,
  isCurrency,
  onMonthDetailClick
}) => {
  const { t } = useTranslation();
  const indigo = useTailwindColor('indigo');
  const gold = useTailwindColor('gold');
  const orange = useTailwindColor('orange');
  const darkGrey = useTailwindColor('darkGrey');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const indigoReduced = useTailwindColor('indigo', 'reduced');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    tly: true,
    stly: true,
    total: true,
    budget: !!budget,
    forecast: !!forecast
  });

  const data = useMemo(() => {
    const mergedData = total.map((item) => {
      const currentDate = dayjs(item.date);
      const previousYearDate = currentDate.subtract(1, 'year').format('YYYY-MM');
      return {
        date: item.date,
        total: item.value,
        tly: tly.find((x) => x.date === previousYearDate)?.value ?? 0,
        stly: stly.find((x) => x.date === previousYearDate)?.value ?? 0,
        budget: budget?.find((x) => x.date === item.date)?.value ?? 0,
        forecast: forecast?.find((x) => x.date === item.date)?.value ?? 0
      };
    });
    return mergedData;
  }, [tly, stly, total, budget, forecast]);

  const maxY = useMemo(() => {
    const allValues = [
      ...tly.map((item) => item.value),
      ...stly.map((item) => item.value),
      ...total.map((item) => item.value),
      ...(budget ? budget.map((item) => item.value) : []),
      ...(forecast ? forecast.map((item) => item.value) : [])
    ];

    return Math.max(...allValues);
  }, [tly, stly, total, budget, forecast]);

  const CustomBar: React.FC<CustomBarProps> = ({
    fill,
    x,
    y,
    width,
    height,
    dataKey,
    seriesVisibility,
    borderWidth = 1,
    borderRadius = 4
  }) => {
    const barVisible = seriesVisibility[dataKey];

    const adjustedHeight = Math.max(0, height);
    const adjustedY = height < 0 ? y + height : y;

    return (
      <g>
        {barVisible ? (
          <>
            {adjustedHeight > 0 ? (
              <rect
                x={x}
                y={adjustedY}
                width={width}
                height={adjustedHeight}
                fill={fill}
                rx={borderRadius}
                ry={borderRadius}
                className="cursor-pointer"
              />
            ) : null}
            {adjustedHeight > borderWidth * 2 ? (
              <rect
                x={x + borderWidth}
                y={adjustedY + borderWidth}
                width={width - borderWidth * 2}
                height={adjustedHeight - borderWidth * 2}
                fill={fill}
                rx={borderRadius - borderWidth}
                ry={borderRadius - borderWidth}
                className="cursor-pointer"
              />
            ) : null}
          </>
        ) : null}
      </g>
    );
  };

  const customLegendPayload = [
    ...(budget
      ? [
          {
            dataKey: 'budget',
            value: t('Budget'),
            color: darkGrey
          }
        ]
      : []),
    {
      dataKey: 'tly',
      value: t('Total Last Year'),
      color: orange
    },
    {
      dataKey: 'stly',
      value: t('Same Time Last Year'),
      color: gold
    },
    {
      dataKey: 'total',
      value: t('On the Books'),
      color: indigo
    },
    ...(forecast
      ? [
          {
            dataKey: 'forecast',
            value: t('Forecast excl. Services'),
            color: indigoReduced
          }
        ]
      : [])
  ];

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    return (
      <ul className="mb-8 flex list-none justify-center">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => (
            <li
              key={`item-${index}`}
              className="mr-2 flex cursor-pointer items-center"
              onClick={() => handleLegendClick(entry.dataKey)}>
              <div
                className="mr-1 h-3 w-3 rounded-full"
                style={{
                  backgroundColor: entry.color,
                  opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                }}
              />
              <Typography
                color="copyTextGrey"
                variant="meta-2"
                className={clsx(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                {entry.value}
              </Typography>
            </li>
          )
        )}
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dayjs(dataPoint.date).format('MMM YYYY')}
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-orange" />
            <Typography variant="meta-2" color="darkGrey">
              {t('Total Last Year')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>
              {isCurrency ? formattedCurrency(dataPoint.tly) : formattedPercentage(dataPoint.tly)}
            </b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-gold" />
            <Typography variant="meta-2" color="darkGrey">
              {t('Same Time Last Year')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>
              {isCurrency ? formattedCurrency(dataPoint.stly) : formattedPercentage(dataPoint.stly)}
            </b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-indigo" />
            <Typography variant="meta-2" color="darkGrey">
              {t('On the Books')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>
              {isCurrency
                ? formattedCurrency(dataPoint.total)
                : formattedPercentage(dataPoint.total)}
            </b>
          </Typography>
        </div>
        {budget ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-darkGrey" />
              <Typography variant="meta-2" color="darkGrey">
                {t('Budget')}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{formattedCurrency(dataPoint.budget)}</b>
            </Typography>
          </div>
        ) : null}
        {forecast ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-indigo-reduced" />
              <Typography variant="meta-2" color="darkGrey">
                {t('Forecast excl. Services')}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{formattedCurrency(dataPoint.forecast)}</b>
            </Typography>
          </div>
        ) : null}
      </Card>
    );
  };

  const CustomScatterPoint = (props: ScatterProps & { seriesVisibility: boolean }) => {
    const { cx, cy, width, seriesVisibility } = props;

    const adjustedWidth = (width || 0) as number;
    const adjustedCx = (cx || 0) as number;
    const halfWidth = adjustedWidth / 2.5;

    if (!seriesVisibility) return null;

    return (
      <line
        x1={adjustedCx - halfWidth}
        y1={cy}
        x2={adjustedCx + halfWidth}
        y2={cy}
        strokeWidth={2}
        stroke={darkGrey}
      />
    );
  };

  type CustomTickProps = {
    x: number;
    y: number;
    payload: {
      value: string;
    };
    onClick: (value: string) => void;
  };

  const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload, onClick }) => (
    <text
      x={x}
      y={y + 15}
      fill="grey"
      onClick={() => onClick(payload.value)}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      textAnchor="middle"
      className="cursor-pointer">
      {dayjs(payload.value).format('MMM YYYY')}
    </text>
  );

  return (
    <Card backgroundColor="white">
      <ScrollArea>
        <ResponsiveContainer minWidth={1000} width="100%" height={500}>
          <ComposedChart
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            barGap={-32}
            barCategoryGap={10}>
            <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
            <XAxis
              axisLine={false}
              tickLine={false}
              dataKey="date"
              tick={(props) => (
                <CustomTick {...props} onClick={(date) => onMonthDetailClick(date)} />
              )}
            />
            <YAxis
              domain={!isCurrency ? [0, 100] : undefined}
              axisLine={false}
              tick={{ fill: grey }}
              width={100}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              tickFormatter={(value) =>
                isCurrency ? formattedCurrency(value) : formattedPercentage(value)
              }
            />
            <Tooltip
              content={(props) => <CustomTooltip {...props} />}
              wrapperStyle={{ outline: 'none' }}
            />
            <Legend
              verticalAlign="top"
              content={(props) => <CustomLegend {...props} payload={customLegendPayload} />}
            />
            {forecast ? (
              <Bar
                dataKey="forecast"
                name={t('Forecast excl. Services') as string}
                fill={indigoReduced}
                barSize={32}
                onClick={(props) => onMonthDetailClick(props.date)}
                shape={(props) => (
                  <CustomBar {...props} seriesVisibility={seriesVisibility} dataKey="forecast" />
                )}
              />
            ) : null}
            <Bar
              dataKey="total"
              name={t('On the Books') as string}
              fill={indigo}
              barSize={32}
              onClick={(props) => onMonthDetailClick(props.date)}
              shape={(props) => (
                <CustomBar
                  {...props}
                  maxY={maxY}
                  chartHeight={500}
                  seriesVisibility={seriesVisibility}
                  dataKey="total"
                />
              )}
            />

            <Line
              isAnimationActive={false}
              visibility={seriesVisibility.stly ? 'visible' : 'hidden'}
              dataKey="stly"
              name={t('Same Time Last Year') as string}
              stroke={gold}
              fill={gold}
            />

            <Line
              isAnimationActive={false}
              visibility={seriesVisibility.tly ? 'visible' : 'hidden'}
              dataKey="tly"
              name={t('Total Last Year') as string}
              stroke={orange}
              fill={orange}
            />

            <Scatter
              dataKey="budget"
              name={t('Budget') as string}
              shape={(props) => (
                <CustomScatterPoint
                  {...props}
                  seriesVisibility={seriesVisibility.budget}
                  width={32}
                />
              )}
              xAxisId={0}
              yAxisId={0}
              fill={darkGrey}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </Card>
  );
};
