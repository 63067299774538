interface PickupDateCellContentProps {
  content: {
    pu: string;
    recommendedPrice: string;
    occupancy: string;
  };
}

export const PickupDateCellContent = ({ content }: PickupDateCellContentProps) => {
  const { pu, recommendedPrice, occupancy } = content;

  return (
    <>
      <div className="bg-red flex items-end justify-between text-meta-2">
        <span>{recommendedPrice}</span>

        <div className="text-right">
          <div>{occupancy}</div>
          <div className="pu-text -mr-1 rounded-full px-1 py-0.5 text-calendar font-medium">
            {pu}
          </div>
        </div>
      </div>
    </>
  );
};
