import { Button } from '@common/components/atoms/Button';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useAuthStore } from '@common/store/auth';
import { getEnvVar } from '@common/utils/env';
import { useDocumentTitle } from '@mantine/hooks';
import { Editor } from '@tinymce/tinymce-react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateNoteModal } from '@pages/Client/Notes/components/CreateNoteModal';
import { DeleteNotesModal } from '@pages/Client/Notes/components/DeleteNoteModal';
import { EditNoteModal } from '@pages/Client/Notes/components/EditNoteModal';
import { useDeleteNote, useHotelNotes } from '@pages/Client/Notes/hooks/useNotes';
import { EditNote } from '@pages/Client/Notes/types/note';

export const Notes = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Notes'));
  const { user } = useAuthStore();
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
  const [selectedNote, setSelectedNote] = useState<EditNote>({} as EditNote);
  const { hotelNotes, isLoading } = useHotelNotes();
  const { deleteNote } = useDeleteNote();
  const { isOpen: createOpen, openModal: createClick, closeModal: createClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();

  const handleDeleteNote = () => {
    if (deleteId) {
      deleteNote(deleteId);
    }
    deleteClose();
  };

  return (
    <Page
      header={
        <Header
          title={t('Notes')}
          actions={<Button onClick={createClick}>{t('Add Notes')}</Button>}
        />
      }>
      <div className="grid grid-cols-12 gap-4">
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <Card backgroundColor="white" className="col-span-4 shadow-none" key={index}>
              <Skeleton className="h-[350px]" />
            </Card>
          ))
        ) : hotelNotes?.results?.length === 0 ? (
          <Typography className="col-span-12 mt-10 text-center">Not Data Found</Typography>
        ) : (
          hotelNotes?.results?.map((note) => {
            const isShowActionButtons = user?.email === note.author.email;
            return (
              <Card backgroundColor="white" className="col-span-4 shadow-none" key={note.id}>
                <div className="relative">
                  {isShowActionButtons ? (
                    <div className="absolute right-4 top-4 flex translate-x-[50%] translate-y-[-50%] gap-3">
                      <Button
                        type="button"
                        icon
                        className="cursor-pointer bg-lightGrey disabled:cursor-not-allowed"
                        onClick={() => {
                          setDeleteId(note.id);
                          deleteClick();
                        }}>
                        <Icon.Delete className="h-5 w-5 text-uiRed" />
                      </Button>
                    </div>
                  ) : null}
                  <div className="space-y-4">
                    <Typography color="darkGrey" variant="meta-2">
                      {note.author.first_name} {note.author.last_name},{' '}
                      {dayjs(note.created_at).format('DD MMM YYYY HH:mm')}
                    </Typography>
                    <Editor
                      apiKey={`${getEnvVar('VITE_TINYMCE')}`}
                      initialValue={note?.note}
                      value={note?.note}
                      disabled
                      init={{
                        content_style: 'body { hover: cursor-pointer;}',
                        height: 320,
                        branding: false,
                        menubar: false,
                        statusbar: false,
                        resize: false,
                        plugins: '',
                        toolbar: false,
                        setup: (ed) => {
                          ed.on('click', () => {
                            ed.focus();
                            setSelectedNote({
                              id: note.id,
                              note: note.note
                            });
                            editClick();
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </Card>
            );
          })
        )}
      </div>
      <CreateNoteModal isOpen={createOpen} onClose={createClose} />
      <DeleteNotesModal
        isOpen={deleteOpen}
        onClose={() => {
          setDeleteId(undefined);
          deleteClose();
        }}
        onDelete={handleDeleteNote}
      />
      <EditNoteModal isOpen={editOpen} onClose={editClose} note={selectedNote} />
    </Page>
  );
};
