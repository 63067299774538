export const TrendingDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M21.4999 18.9999H16.4999C16.2237 18.9999 15.9999 18.776 15.9999 18.4999V18.2099C15.9979 18.0743 16.0522 17.9439 16.1499 17.8499L17.7899 16.2099L13.4999 11.9099L10.8499 14.5599C10.7591 14.6565 10.6324 14.7114 10.4999 14.7114C10.3673 14.7114 10.2406 14.6565 10.1499 14.5599L2.14985 6.55985C2.0552 6.46597 2.00195 6.33817 2.00195 6.20485C2.00195 6.07153 2.0552 5.94374 2.14985 5.84985L2.84985 5.14985C2.94374 5.0552 3.07153 5.00195 3.20485 5.00195C3.33817 5.00195 3.46597 5.0552 3.55985 5.14985L10.4999 12.0899L13.1499 9.43985C13.2406 9.34318 13.3673 9.28833 13.4999 9.28833C13.6324 9.28833 13.7591 9.34318 13.8499 9.43985L19.2099 14.7899L20.8499 13.1499C20.9439 13.0522 21.0743 12.9979 21.2099 12.9999H21.4999C21.776 12.9999 21.9999 13.2237 21.9999 13.4999V18.4999C21.9999 18.776 21.776 18.9999 21.4999 18.9999Z" />
    </svg>
  );
};
