export function ChartLineUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M10.1499 9.43985C10.2406 9.34317 10.3673 9.28833 10.4999 9.28833C10.6324 9.28833 10.7591 9.34317 10.8499 9.43985L13.4999 12.0899L20.4399 5.14985C20.5337 5.0552 20.6615 5.00195 20.7949 5.00195C20.9282 5.00195 21.056 5.0552 21.1499 5.14985L21.8499 5.84985C21.9445 5.94374 21.9978 6.07153 21.9978 6.20485C21.9978 6.33817 21.9445 6.46597 21.8499 6.55985L13.8499 14.5599C13.7591 14.6565 13.6324 14.7114 13.4999 14.7114C13.3673 14.7114 13.2406 14.6565 13.1499 14.5599L10.4999 11.9099L3.55985 18.8499C3.46597 18.9445 3.33817 18.9978 3.20485 18.9978C3.07153 18.9978 2.94374 18.9445 2.84985 18.8499L2.14985 18.1499C2.0552 18.056 2.00195 17.9282 2.00195 17.7949C2.00195 17.6615 2.0552 17.5337 2.14985 17.4399L10.1499 9.43985Z" />
    </svg>
  );
}
