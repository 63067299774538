import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAnalyticsDashboardFilters } from '@pages/Client/Dashboard/hooks/useAnalyticsDashboardCache';
import {
  convertToOptionsArray,
  SegmentationData
} from '@pages/Client/PricingStrategy/Segmentation/Segmentation';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { has, uniq } from 'lodash-es';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useRecalculation } from '@pages/Client/PricingStrategy/Segmentation/hooks/useSegmentation';

interface SegmentData {
  id: string;
  name: string;
  segment_tags: string[];
  segments_settings_corrections: boolean;
  segments_price_estimate: boolean;
  segments_pickupboost: boolean;
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  selectedSegment?: SegmentData;
};

const schema = z.object({
  name: z.string().nonempty({ message: 'Segment name is required' }),
  segment_tags: z.array(z.string())
});

export const EditSegmentModal = ({ isOpen, onClose, selectedSegment }: ModalProps) => {
  const { pricingSettingsQuery } = useRoomPrices();
  const { t } = useTranslation();
  const { data: pricingSettings } = pricingSettingsQuery;
  const { query: recalCulateQuery } = useRecalculation();
  const { mutateAsync: resetPickupboostpriceSegmentationEffect } = recalCulateQuery;
  const { analyticsDashboardFilters } = useAnalyticsDashboardFilters();
  const { hasKeyValueSegmentation } = usePmsProvider();

  const [isExcludeFromDemand, setIsExcludeFromDemand] = useState(0);
  const [isExcludeFromSupply, setIsExcludeFromSupply] = useState(0);

  const { control, reset, handleSubmit, setValue, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const segmentationData = (analyticsDashboardFilters?.segments as SegmentationData) || {};
  const availableTags = convertToOptionsArray(segmentationData);

  useEffect(() => {
    setValue('name', selectedSegment?.name as string);
    setValue('segment_tags', selectedSegment?.segment_tags as string[]);
    if (selectedSegment?.segments_settings_corrections && selectedSegment.segments_pickupboost) {
      setIsExcludeFromDemand(1);
    }
    if (selectedSegment?.segments_price_estimate) {
      setIsExcludeFromSupply(1);
    }
  }, [selectedSegment, setValue]);

  const defaultAdditional = {
    segments_settings_corrections: isExcludeFromDemand === 1 ? true : false,
    segments_price_estimate: isExcludeFromSupply === 1 ? true : false,
    segments_pickupboost: isExcludeFromDemand === 1 ? true : false
  };

  function getCurrentValidIds(
    segmentTags: string[],
    segmentsDictionary: SegmentationData
  ): string[] {
    return segmentTags.filter((id) => has(segmentsDictionary, id));
  }

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const segmentation = pricingSettings?.segmentation;

    if (segmentation && selectedSegment && segmentation[selectedSegment.id]) {
      const getSegmentValue = (keyOrValue: any) => {
        return segmentationData[keyOrValue];
      };
      const segmentTags = [
        ...getCurrentValidIds(data.segment_tags, segmentationData),
        ...getCurrentValidIds(data.segment_tags, segmentationData).map(getSegmentValue)
      ].filter((item) => item !== undefined);
      const updatedSegmentation = {
        ...pricingSettings?.segmentation,
        [selectedSegment.id]: {
          ...data,
          ...defaultAdditional,
          segment_tags: hasKeyValueSegmentation() ? uniq(segmentTags) : data.segment_tags
        }
      };

      if (selectedSegment) {
        await resetPickupboostpriceSegmentationEffect(updatedSegmentation);
        reset();
      } else {
        return;
      }
    }
  };

  return (
    <Modal
      okText={`${t('Save')}`}
      continueIntent="primary"
      size="xl"
      open={isOpen}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      onClose={() => {
        onClose();
        reset();
        setIsExcludeFromDemand(0);
        setIsExcludeFromSupply(0);
      }}>
      <div className="max-w-[480px]">
        <div className="min-w-[460px]">
          <Typography className="mb-6" element="h6" color="darkGrey" variant="h6">
            {t('Edit')} {t('Segmentation')}
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                name="subject"
                background="grey"
                className="w-full"
                type="text"
                value={value}
                label={`${t('Segment Name')}*`}
                placeholder={`${t('Please enter segment name')}`}
                onChange={onChange}
                onClear={() => setValue('name', '')}
                error={!!error}
                helperMessage={
                  error && (
                    <div className="flex items-center gap-2 text-error">
                      {error ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {error ? error.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
          <div className="my-4">
            <Typography element="p" variant="meta-1" className="mb-1 text-grey">
              {t('Tags')}*
            </Typography>
            <Controller
              control={control}
              name="segment_tags"
              render={({ field: { value, name, onChange } }) => {
                return (
                  <MultiSelect
                    fullWidth
                    inputClassName="py-[14px]"
                    placeholder={`${t('Select tags')}`}
                    hint={availableTags.length > 0 ? 'Available Tags' : 'No tags available'}
                    name={name}
                    options={availableTags}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </div>
          <div className="mt-6 flex items-center gap-2">
            <Checkbox
              label={t('Not representing demand')}
              value={1}
              id="isExcludeFromDemand"
              onChange={() => {
                isExcludeFromDemand === 1 ? setIsExcludeFromDemand(0) : setIsExcludeFromDemand(1);
              }}
              checked={isExcludeFromDemand === 1}
            />
          </div>
          <div className="mt-6 flex items-center gap-2">
            <Checkbox
              label={t('Treat like out of order')}
              value={1}
              id="isExcludeFromSupply"
              onChange={() => {
                isExcludeFromSupply === 1 ? setIsExcludeFromSupply(0) : setIsExcludeFromSupply(1);
              }}
              checked={isExcludeFromSupply === 1}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
