import { HealthInfo, HealthStatus } from '@common/api/pricingAlgorithm/types';
import { Badge } from '@common/components/atoms/Badge';
import { Icon } from '@common/components/foundations/icons';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { HealthLabel, HealthLabelStatus } from '@pages/Client/HealthReport/types/health';
import { formatHealthLabel } from '@pages/Client/HealthReport/utils/formatLabel';
import { formatNumberScore } from '@pages/Client/HealthReport/utils/formatScore';

export const t = (key: string) => key;

const getHealthStatus = (label: string) => {
  const formattedLabel = formatHealthLabel(label || '', false);
  switch (label) {
    case HealthLabel.HighPriceLowOccupancy:
      return (
        <Badge background="orange">
          <span className="font-medium text-white">{formattedLabel}</span>
        </Badge>
      );
    case HealthLabel.OccupancyToLow:
      return (
        <Badge background="orange">
          <span className="font-medium text-white">{formattedLabel}</span>
        </Badge>
      );
    case HealthLabel.LowPriceHighOccupancy:
      return (
        <Badge background="indigo">
          <span className="font-medium text-white">{formattedLabel}</span>
        </Badge>
      );
    case HealthLabel.OccupancyToHigh:
      return (
        <Badge background="indigo">
          <span className="font-medium text-white">{formattedLabel}</span>
        </Badge>
      );
    case HealthLabel.Undefined:
      return '-';
    case HealthLabel.LooksGood:
    default:
      return formattedLabel;
  }
};
const getHealthIcon = (
  label: string,
  data: string | number,
  type: 'occupancy' | 'price' | 'default'
) => {
  let badgeColor = 'indigo'; // Default color

  if (type === 'price') {
    badgeColor = label === HealthLabelStatus.ToLow ? 'uiRed' : 'uiGreen';
  } else if (type === 'occupancy') {
    badgeColor = label === HealthLabelStatus.ToLow ? 'orange' : 'indigo';
  } else {
    // Default case for 'default' type
    badgeColor = label === HealthLabelStatus.ToLow ? 'orange' : 'indigo';
  }

  const arrowIcon =
    label === HealthLabelStatus.ToLow ? (
      <Icon.ArrowDownWard className={`h-3 w-3 ${type === 'price' ? 'text-uiRed' : 'text-white'}`} />
    ) : (
      <Icon.ArrowUpWard className={`h-3 w-3 ${type === 'price' ? 'text-uiGreen' : 'text-white'}`} />
    );

  if (label === HealthLabelStatus.Good) {
    return `${data}% `;
  }

  return (
    <Badge background={badgeColor} className={type === 'price' ? 'bg-opacity-10' : ''}>
      <span className={type === 'price' ? `text-${badgeColor} font-medium` : 'text-white'}>
        {data}
        {'% '}
      </span>
      {arrowIcon}
    </Badge>
  );
};

const getRecommendationMinMax = (count: number, type: 'min' | 'max') => {
  const arrowIcon = <Icon.ArrowDownWard className="h-3 w-3 text-white" />;
  if (count > 5) {
    return (
      <Badge background={type === 'min' ? 'orange' : 'indigo'}>
        <span className="font-medium text-white">{count}</span>
        {arrowIcon}
      </Badge>
    );
  }
  return count;
};

const countPriceObjectKeys = (priceData?: any): number => {
  if (!priceData) {
    return 0;
  }
  return Object.keys(priceData).length;
};

export const monthlyPricesColumns: any[] = [
  {
    header: t('Month'),
    accessorKey: 'month',
    cell: (row: any) => {
      const month = row?.getValue();
      const year = row?.row.original.year;
      const formattedMonth = month.toString().padStart(2, '0');
      return <div className="flex items-center">{`${year}-${formattedMonth}`}</div>;
    },
    meta: {
      showOnMobile: true
    }
  },
  {
    header: t('Health'),
    accessorKey: 'health',
    cell: (row: any) => {
      const health = row?.getValue() as HealthStatus;
      return <div className="flex items-center">{getHealthStatus(health?.label || '')}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Price Health'),
    accessorKey: 'price_health',
    cell: (row: any) => {
      const priceHealth = row?.getValue() as HealthInfo;
      const priceHealthFormatted = Math.round(
        +formatNumberScore((priceHealth?.score as number) * 100 - 100)
      );
      const data = priceHealthFormatted > 0 ? `+${priceHealthFormatted}` : priceHealthFormatted;
      return (
        <div className="flex items-center gap-2">
          {getHealthIcon(priceHealth?.label || '', data, 'price')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Avg Price'),
    accessorKey: 'avg_price',
    cell: (row: any) => {
      return <div className="flex items-center ">{CurrencyFormatter.format(row?.getValue())}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Avg Pickup model price'),
    accessorKey: 'avg_pickup_boost_price',
    cell: (row: any) => {
      return <div className="flex items-center">{CurrencyFormatter.format(row?.getValue())}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Occupancy Health'),
    accessorKey: 'occupancy_health',
    cell: (row: any) => {
      const occupancyHealth = row?.getValue() as HealthInfo;
      const occupancyHealthFormatted = Math.round(
        +formatNumberScore((occupancyHealth?.score as number) * 100 - 100)
      );
      const data =
        occupancyHealthFormatted > 0 ? `+${occupancyHealthFormatted}` : occupancyHealthFormatted;
      return (
        <div className="flex items-center">
          {getHealthIcon(occupancyHealth?.label || '', data, 'occupancy')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span>{t('Rooms Sold')}</span>
      </div>
    ),
    accessorKey: 'rooms_sold',
    cell: (row: any) => {
      const roomsSold = row?.getValue() as number;
      return <div className="flex items-center">{formatNumberScore(roomsSold)}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span> {t('Expected Rooms Sold')}</span>
      </div>
    ),
    accessorKey: 'expected_rooms_sold',
    cell: (row: any) => {
      const expectedRoomsSold = row?.getValue() as number;
      return <div className="flex items-center">{formatNumberScore(expectedRoomsSold)}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span>{`Dates where`}</span>
        <span>{`recommendation`}</span>
        <span>{`> max price`}</span>
      </div>
    ),
    accessorKey: 'max_price_too_low',
    cell: (row: any) => {
      return (
        <div className="flex items-center">
          {getRecommendationMinMax(countPriceObjectKeys(row?.getValue()), 'max')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  }
];

export const weekPricesColumns: any[] = [
  {
    header: t('Day'),
    accessorKey: 'name',
    cell: (row: any) => {
      const day = row?.getValue() as string;
      return <div className="flex items-center">{day?.slice(0, 3)}</div>;
    },
    meta: {
      showOnMobile: true
    }
  },
  {
    header: t('Health'),
    accessorKey: 'health',
    cell: (row: any) => {
      const health = row?.getValue() as HealthStatus;
      return <div className="flex items-center">{getHealthStatus(health?.label || '')}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Price Health'),
    accessorKey: 'price_health',
    cell: (row: any) => {
      const priceHealth = row?.getValue() as HealthInfo;
      const priceHealthFormatted = Math.round(
        +formatNumberScore((priceHealth?.score as number) * 100 - 100)
      );
      const data = priceHealthFormatted > 0 ? `+${priceHealthFormatted}` : priceHealthFormatted;
      return (
        <div className="flex items-center gap-2">
          {getHealthIcon(priceHealth?.label || '', data, 'price')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Avg Price'),
    accessorKey: 'avg_price',
    cell: (row: any) => {
      return <div className="flex items-center">{CurrencyFormatter.format(row?.getValue())}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Avg Pickup model price'),
    accessorKey: 'avg_pickup_boost_price',
    cell: (row: any) => {
      return <div className="flex items-center">{CurrencyFormatter.format(row?.getValue())}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Occupancy Health'),
    accessorKey: 'occupancy_health',
    cell: (row: any) => {
      const occupancyHealth = row?.getValue() as HealthInfo;
      const occupancyHealthFormatted = Math.round(
        +formatNumberScore((occupancyHealth?.score as number) * 100 - 100)
      );
      const data =
        occupancyHealthFormatted > 0 ? `+${occupancyHealthFormatted}` : occupancyHealthFormatted;
      return (
        <div className="flex items-center gap-2">
          {getHealthIcon(occupancyHealth?.label || '', data, 'occupancy')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span>{t('Rooms Sold')}</span>
        <span className="text-meta-3-medium">({t('next 6 months')})</span>
      </div>
    ),
    accessorKey: 'rooms_sold',
    cell: (row: any) => {
      const roomsSold = row?.getValue() as number;
      return <div className="flex items-center">{formatNumberScore(roomsSold)}</div>;
    },
    meta: {
      cellAlignment: 'right',
      width: 200,
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span> {t('Expected Rooms Sold')}</span>
        <span className="text-meta-3-medium">({t('next 6 months')})</span>
      </div>
    ),
    accessorKey: 'expected_rooms_sold',
    cell: (row: any) => {
      const expectedRoomsSold = row?.getValue() as number;
      return <div className="flex items-center">{formatNumberScore(expectedRoomsSold)}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span>{`Dates where`}</span>
        <span>{`recommendation`}</span>
        <span>{`> max price`}</span>
      </div>
    ),
    accessorKey: 'max_price_too_low',
    cell: (row: any) => {
      return (
        <div className="flex items-center">
          {getRecommendationMinMax(countPriceObjectKeys(row?.getValue()), 'max')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  }
];

export const roomTypeDerivationColumns: any[] = [
  {
    header: t('Room Type Name'),
    accessorKey: 'name',
    cell: (row: any) => {
      return <div className="flex items-center">{row?.getValue()}</div>;
    },
    meta: {
      showOnMobile: true
    }
  },
  {
    header: t('Base Price'),
    accessorKey: 'base_price',
    cell: (row: any) => {
      const basePrice = (row?.getValue() as number) ?? 0;
      return (
        <div className="flex items-center">{CurrencyFormatter.format(Math.round(basePrice))}</div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: t('Is Reference Room'),
    accessorKey: 'is_reference_room',
    cell: (row: any) => {
      const isReferenceRoom = (row?.getValue() as boolean) ? 'Reference' : 'Derived';
      return <div className="flex items-center">{isReferenceRoom}</div>;
    },
    meta: {
      showOnMobile: true
    }
  },
  {
    header: t('Room Sold Health'),
    accessorKey: 'rooms_sold_health',
    cell: (row: any) => {
      const roomSoldHealth = row?.getValue() as HealthInfo;
      const roomSoldHealthFormatted = Math.round(
        +formatNumberScore((roomSoldHealth?.score as number) * 100 - 100)
      );
      const data =
        roomSoldHealthFormatted > 0 ? `+${roomSoldHealthFormatted}` : roomSoldHealthFormatted;
      return (
        <div className="flex items-center">
          {getHealthIcon(roomSoldHealth?.label || '', data, 'default')}
        </div>
      );
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span>{t('Rooms Sold')}</span>
        <span className="text-meta-3-medium">({t('next 6 months')})</span>
      </div>
    ),
    accessorKey: 'sum_rooms_sold',
    cell: (row: any) => {
      const sumRoomsSold = row?.getValue() as number;
      return <div className="flex items-center">{formatNumberScore(sumRoomsSold)}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  },
  {
    header: (
      <div className="flex flex-col">
        <span> {t('Expected Rooms Sold')}</span>
        <span className="text-meta-3-medium">({t('next 6 months')})</span>
      </div>
    ),
    accessorKey: 'expected_sum_room_sold',
    cell: (row: any) => {
      const expectedSumRoomSold = row?.getValue() as number;
      return <div className="flex items-center">{formatNumberScore(expectedSumRoomSold)}</div>;
    },
    meta: {
      cellAlignment: 'right',
      showOnMobile: true
    }
  }
];
