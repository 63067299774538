import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ErrorResponse } from '@common/api/auth';
import { getAirbnbMarketInsightsCache } from '@common/api/dashboard';
import {
  AirbnbMarketInsightsData,
  AirbnbMarketInsightsTemplate
} from '@common/api/dashboard/types';
import { useNotificationsStore } from '@common/store/notifications';
import { MarketIntelligenceQueryKeys } from '@common/types/query-keys';
import { useFeaturesStore } from '@common/store/features';

export const useAirbnbInsightsCache = () => {
  const { addNotification } = useNotificationsStore();
  const { t } = useTranslation();
  const { hasMarketInsightsFeature } = useFeaturesStore();

  const query = useQuery<AirbnbMarketInsightsData, ErrorResponse>({
    queryKey: [MarketIntelligenceQueryKeys.GET_AIRBNB_INSIGHTS_CACHE],
    queryFn: () => getAirbnbMarketInsightsCache(),
    enabled: hasMarketInsightsFeature()
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', t('Failed to fetch vacation rental insights data'));
    }
  }, [query.isError]);

  const airbnbInsightsCache = useMemo(() => {
    return query.data;
  }, [query.data]);

  const getAllYears = () => {
    const data = query.data?.yearly;
    const yearsSet = new Set<number>();

    if (!data) return [];

    for (const category in data) {
      for (const year in data[category as keyof AirbnbMarketInsightsTemplate]) {
        if (isNaN(parseInt(year))) continue;
        yearsSet.add(parseInt(year));
      }
    }

    return Array.from(yearsSet).sort((a, b) => a - b);
  };

  getAllYears();

  return {
    airbnbInsightsCache,
    query,
    getAllYears
  };
};
