import { create } from 'zustand';
interface LoadingStore {
  isCreateLoading: boolean;
  setCreateLoading: (newLoading: boolean) => void;
}

export const useCreateRoomTypeStore = create<LoadingStore>((set) => ({
  isCreateLoading: false,
  setCreateLoading: (newLoading: boolean) => set({ isCreateLoading: newLoading })
}));
