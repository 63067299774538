import { useState, useMemo, useEffect } from 'react';
import { isEmpty, isNil, set, debounce } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import {
  useRecommendedMinStay,
  useRecommendedPrice
} from '@pages/Client/Calendar/components/Tables/hooks';
import dayjs from 'dayjs';
import { applyIndividualAdjustment } from '@common/api/hotel';
import { useTransformData } from '@pages/Client/Calendar/components/BulkEdit/hooks/useTransformFormData';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';

export function useFormPreflight() {
  const { transformData } = useTransformData();
  const { getValues, formState } = useFormContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const { setRecommendedPriceData } = useRecommendedPrice(dayjs(getValues('editDate')));
  const { pricingSettingsQuery } = usePricingSettings();
  const { originalMinStayData, recommendedMinStayData, setRecommendedMinStayData } =
    useRecommendedMinStay(dayjs(getValues('editDate')));

  const runPreflight = async (priceSettings: string, currentDateStr: string) => {
    setIsUpdating(true);
    try {
      if (isNil(priceSettings)) throw new Error('Price settings are empty');
      const { data } = await applyIndividualAdjustment({
        start_date: currentDateStr,
        end_date: currentDateStr,
        json_settings: priceSettings,
        is_write_to_cache: false
      });
      const newSuggestedPrice = {};
      const newSuggestedMinStay = { ...originalMinStayData, ...recommendedMinStayData } as Record<
        number,
        number
      >;

      for (const roomId in data[currentDateStr]) {
        set(newSuggestedPrice, roomId, data[currentDateStr][roomId]?.price);
        set(newSuggestedMinStay, roomId, data[currentDateStr][roomId]?.suggested_min_stay);
      }

      setRecommendedPriceData(newSuggestedPrice);
      setRecommendedMinStayData(newSuggestedMinStay);
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const debouncedPreflight = useMemo(
    () =>
      debounce(async () => {
        const isBulkEdit = getValues('isBulkEdit');
        if (isBulkEdit) return;

        const errors = formState?.errors;
        if (isEmpty(errors)) {
          const formValues = getValues();
          const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
          const { newPricing, dates } = await transformData(latestPricingSettings, formValues);
          await runPreflight(JSON.stringify(newPricing), dates[0]);
        }
      }, 700),
    [getValues, formState, transformData, runPreflight]
  );

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedPreflight.cancel();
    };
  }, []);

  return {
    preflightCallback: debouncedPreflight,
    isUpdating
  };
}
