export function ArrowDownWard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M12.5 2C12.7761 2 13 2.22386 13 2.5V18.67L17.44 14.22C17.5339 14.1253 17.6617 14.0721 17.795 14.0721C17.9283 14.0721 18.0561 14.1253 18.15 14.22L18.85 14.93C18.9446 15.0239 18.9979 15.1517 18.9979 15.285C18.9979 15.4183 18.9446 15.5461 18.85 15.64L12.72 21.78C12.5795 21.9207 12.3888 21.9998 12.19 22H11.81C11.6116 21.9977 11.4217 21.9189 11.28 21.78L5.14997 15.64C5.05532 15.5461 5.00208 15.4183 5.00208 15.285C5.00208 15.1517 5.05532 15.0239 5.14997 14.93L5.85997 14.22C5.95214 14.1259 6.07828 14.0729 6.20997 14.0729C6.34166 14.0729 6.46781 14.1259 6.55997 14.22L11 18.67V2.5C11 2.22386 11.2238 2 11.5 2H12.5Z" />
    </svg>
  );
}
