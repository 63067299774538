import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { Outlet } from 'react-router-dom';

export function AuthLayout() {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className="hidden w-0 p-4 lg:block lg:w-1/2">
        <div className="flex h-full justify-center rounded-lg bg-indigo">
          <div className="flex items-center gap-x-4">
            <div className="h-auto w-24">
              <Genie className="fill-white" />
            </div>
            <div className="h-32 w-32">
              <RoomPriceGenieText className="fill-white" />
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
