import { create } from 'zustand';
interface LoadingStore {
  isInitRoomLoading: boolean;
  setLoading: (newLoading: boolean) => void;
}

export const useInitRoomLoading = create<LoadingStore>((set) => ({
  isInitRoomLoading: false,
  setLoading: (newLoading: boolean) => set({ isInitRoomLoading: newLoading })
}));
