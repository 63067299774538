import { Modal } from '@common/components/molecules/Modal';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title?: string;
};

export const DeleteScheduleReportsModal = ({ onDelete, title, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClick={onDelete}
      open={isOpen}
      okText="Delete"
      onClose={onClose}
      size={'lg'}
      continueIntent="danger"
      children={
        <>
          <div className="flex  flex-col items-start gap-3">
            <div className="my-3">
              <div className=" rounded-full bg-error p-3">
                <Icon.Delete color="white" />
              </div>
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t('Delete Scheduled Reports Name:') + ' '}
              {title}
            </Typography>
            <Typography>
              {t('Are you sure you want to delete') + ' '}
              {title}?
            </Typography>
          </div>
        </>
      }
    />
  );
};
