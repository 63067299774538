import {
  updateDashboardErrorTicket,
  updateMultipleDashboardErrorTickets
} from '@common/api/errors';
import {
  UpdateDashboardTicketPayload,
  UpdateMultipleDashboardTicketsPayload
} from '@common/api/errors/types';
import { useNotificationsStore } from '@common/store/notifications';
import { DashboardErrorTicketsQueryKeys } from '@common/types/query-keys';
import { useUpdateMultipleTicketStore } from '@pages/Admin/ErrorTickets/store/updateMultiple';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateDashboardErrorTicket = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: ([payload, id]: [UpdateDashboardTicketPayload, string | undefined]) => {
      return updateDashboardErrorTicket(payload, id);
    },
    onSuccess: () => {
      addNotification('success', 'Update Error Ticket Successfully');
      queryClient.invalidateQueries({
        queryKey: [DashboardErrorTicketsQueryKeys.DASHBOARD_ERROR_TICKET_LIST]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Error Ticket');
    }
  });

  return {
    updateDashboardErrorTicket: mutate,
    isLoading
  };
};

export const useUpdateMultipleDashboardTickets = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { setUpdateMultipleTicketSuccess } = useUpdateMultipleTicketStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: UpdateMultipleDashboardTicketsPayload) => {
      return updateMultipleDashboardErrorTickets(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Update Dashboard Error Tickets Successfully');
      queryClient.invalidateQueries({
        queryKey: [DashboardErrorTicketsQueryKeys.DASHBOARD_ERROR_TICKET_LIST]
      });
      setUpdateMultipleTicketSuccess(true);
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Dashboard Error Tickets');
    }
  });

  return {
    updateMultipleDashboardErrorTickets: mutate,
    isLoading,
    isSuccess
  };
};
