export function GroupJunior(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0029 8C12.0029 9.65685 10.6594 11 9.00202 11C7.34467 11 6.00111 9.65685 6.00111 8C6.00111 6.34315 7.34467 5 9.00202 5C10.6594 5 12.0029 6.34315 12.0029 8ZM19.005 9.5C19.005 10.8807 17.8854 12 16.5043 12C15.8402 12.0027 15.2026 11.7401 14.733 11.2707C14.2635 10.8013 14.0009 10.1639 14.0035 9.5C14.0035 8.11929 15.1232 7 16.5043 7C17.8854 7 19.005 8.11929 19.005 9.5ZM11.7629 13C11.1333 13.4601 10.6224 14.0634 10.2724 14.76L9.32212 16.65C8.9571 17.3815 8.90652 18.2303 9.18208 19H3.00021C2.65351 18.999 2.33208 18.8185 2.15071 18.5231C1.96935 18.2277 1.95392 17.8595 2.10994 17.55L3.55037 14.66C4.05932 13.6408 5.10174 12.9978 6.24119 13H11.7629ZM11.1534 18.5231C11.3348 18.8185 11.6562 18.999 12.0029 19H21.0057C21.3524 18.999 21.6738 18.8185 21.8551 18.5231C22.0365 18.2277 22.0519 17.8595 21.8959 17.55L20.9456 15.65C20.4355 14.638 19.3984 13.9997 18.2648 14H14.7438C13.6282 14.0183 12.6149 14.6539 12.113 15.65L11.1127 17.55C10.9566 17.8595 10.9721 18.2277 11.1534 18.5231Z"
      />
    </svg>
  );
}
