import { Icon } from '@common/components/foundations/icons';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useUpsellingStore } from '@common/store/upselling';
import { useViewStore } from '@common/store/view';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useSubscription } from '@pages/Client/hooks/useSubscription';
import classNames from 'classnames';

export const useUpselling = (isEnabled?: boolean) => {
  const orange = useTailwindColor('orange');
  const { features, hasMarketInsightsFeature, hasDashboardFeature } = useFeaturesStore();
  const { upsellingModalOpen, setUpsellingModalOpen } = useUpsellingStore();
  const { view: clientViewMode } = useViewStore();
  const { hasUpgradeEnabled } = useSubscription();
  const { hasDashboardEnabled } = usePmsProvider(Boolean(isEnabled));

  // General Rule: Only show any upselling topics, if:
  // - client is on advanced plan (not starter, small business, pro lite or professional)
  // - hotel state in (subscribed, subscribed partner, demo)
  // - if “limited dashboard” ff is enabled (does not have to be true)
  // - if clientViewMode !== ‘admin’
  const isDefaultUpselling =
    hasUpgradeEnabled() && hasDashboardEnabled() && clientViewMode !== 'admin';

  // The individual feature flags can then have additional rules as follows:
  const isProDashboardUpselling =
    isDefaultUpselling &&
    features?.includes(Feature.LimitedDashboard) &&
    !features?.includes(Feature.ProfessionalDashboard);

  const isDashboardUpselling =
    isDefaultUpselling && hasMarketInsightsFeature() && !hasDashboardFeature();

  const isPickupCalendarUpselling =
    isDefaultUpselling && !features?.includes(Feature.PickupCalendar);

  const isAutoSetPricesUpselling =
    isDefaultUpselling && !features?.includes(Feature.SurgeProtectionPro);

  const isRateEvolutionUpselling =
    isDefaultUpselling && !features?.includes(Feature.PriceEvolution);

  const isMarketInsightsUpselling =
    isDefaultUpselling &&
    features?.includes(Feature.LimitedMarketInsights) &&
    !features?.includes(Feature.ProMarketInsights);

  const ProEntryPoints = {
    isProDashboardEntryPoint:
      features?.includes(Feature.ProfessionalDashboard) ||
      isProDashboardUpselling ||
      clientViewMode === 'admin',
    isPickupCalendarEntryPoint:
      features?.includes(Feature.PickupCalendar) ||
      isPickupCalendarUpselling ||
      clientViewMode === 'admin',
    isAutoSetPricesEntryPoint:
      features?.includes(Feature.SurgeProtectionPro) ||
      isAutoSetPricesUpselling ||
      clientViewMode === 'admin',
    isRateEvolutionEntryPoint:
      features?.includes(Feature.PriceEvolution) ||
      isRateEvolutionUpselling ||
      clientViewMode === 'admin',
    isMarketInsightsEntryPoint:
      features?.includes(Feature.ProMarketInsights) ||
      isMarketInsightsUpselling ||
      clientViewMode === 'admin'
  };

  const SparkIcon = ({ className }: { className?: string }) => {
    return (
      <div
        className={classNames('ml-0.5 flex items-center justify-center rounded p-0.5', className)}
        style={{ backgroundColor: orange, height: 18, width: 18 }}>
        <Icon.Spark className="h-3.5 w-3.5" />
      </div>
    );
  };
  return {
    isDefaultUpselling,
    isProDashboardUpselling,
    isDashboardUpselling,
    isPickupCalendarUpselling,
    isAutoSetPricesUpselling,
    isRateEvolutionUpselling,
    isMarketInsightsUpselling,
    ProEntryPoints,
    SparkIcon,
    upsellingModalOpen,
    setUpsellingModalOpen
  };
};
