import { getDataInvitationUser } from '@common/api/account';
import { DataInvitationUser } from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { UserListQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Params = {
  email_token: string;
};

function capitalizeText(text: string): string {
  if (!text) {
    return '';
  }

  const firstChar = text.charAt(0).toUpperCase();
  const remainingChars = text.slice(1);

  return firstChar + remainingChars;
}

export const useGetDataInvitation = ({ email_token = '' }: Params) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery({
    queryKey: [UserListQueryKeys.GET_USER_DATA_INVITATION, email_token],
    queryFn: async () => {
      if (email_token) {
        const response = await getDataInvitationUser(email_token);
        if (response?.success === false) {
          addNotification('fail', capitalizeText(response?.message) || 'Failed to get user data');
        }
        return response?.data || []; // Return an empty array if response.data is undefined
      } else {
        return [];
      }
    },
    retry: false
  });

  const userDataInvitation = useMemo(() => {
    return query.data as DataInvitationUser;
  }, [query.data]);

  return { userDataInvitation, query };
};
