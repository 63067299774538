import { getReservationStatus } from '@common/api/hotel';
import { ReservationQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetReservationsStatus = () => {
  const { hotelDetails } = useHotelDetails();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [ReservationQueryKeys.GET_RESERVATION_STATUS],
    queryFn: async () => getReservationStatus(),
    enabled: hotelDetails?.pms_provider === PmsProvider.PROTEL,
    retry: false
  });

  return {
    reservationStatus: data?.data.reservation_status,
    pairStatus: data?.data.pair_status,
    isLoading,
    refetch,
    error
  };
};
