import { getDealOwnerList } from '@common/api/account';
import { DealOwner } from '@common/api/account/types';
import { ErrorResponse } from '@common/api/auth';
import { UserListQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { map, uniqBy, startCase } from 'lodash-es';
import { useMemo } from 'react';

export const useDealOwnerList = () => {
  const query = useQuery<DealOwner[], ErrorResponse>({
    queryKey: [UserListQueryKeys.GET_DEAL_OWNER_LIST],
    queryFn: getDealOwnerList
  });

  const dealOwnerList = useMemo(() => {
    return query.data;
  }, [query.data]);

  const dealOwnerSelectList = useMemo(() => {
    const output = map(dealOwnerList, (owner) => ({
      value: String(owner.id),
      label: startCase(owner.name)
    }));
    return uniqBy(output, 'value');
  }, [dealOwnerList]);

  return { dealOwnerList, dealOwnerSelectList, query };
};
