import { simulateSurgeEvents } from '@common/api/pricingAlgorithm';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { API_DATE_FORMAT } from '@common/constants/date';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { SurgeEventQueryKeys } from '@common/types/query-keys';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal, TextInput, Button, ActionIcon, Stack, Flex, Box } from '@mantine/core';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  events: z.array(
    z.object({
      reservation_count: z
        .number()
        .or(z.string().transform(Number))
        .refine((val) => validateNoDecimals(val), {
          message: 'Value must be a whole number'
        })
        .refine((val) => Number(val) > 0, {
          message: 'Value should be greater than 0'
        }),
      date: z.date().refine((val) => !dayjs(val).isBefore(dayjs().startOf('day')), {
        message: 'Date cannot be in the past'
      })
    })
  )
});

const EMPTY_EVENT: any = { reservation_count: '', date: new Date() };

type Props = {
  opened: boolean;
  close: () => void;
};

export const SimulateSurgeEventsModal: React.FC<Props> = ({ opened, close }) => {
  const { hotelDetails } = useHotelDetails();
  const queryClient = useQueryClient();
  const { handleSubmit, control, formState, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      events: [EMPTY_EVENT]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'events'
  });

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationKey: [SurgeEventQueryKeys.SIMULATE_SURGE_EVENTS],
    mutationFn: simulateSurgeEvents,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SurgeEventQueryKeys.GET_SURGE_EVENTS] });
    }
  });

  const onSubmit = (data: z.infer<typeof schema>) => {
    const formattedData = data.events.map((event) => ({
      ...event,
      date: dayjs(event.date).format(API_DATE_FORMAT)
    }));
    mutateAsync({ events: formattedData });
    reset();
  };

  return (
    <Modal.Root opened={opened} onClose={close}>
      <Modal.Overlay />
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header bg="white">
            <Modal.Title>Simulate Surge Events</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Stack>
              {fields.map((field, index) => (
                <Stack
                  pos="relative"
                  p="xs"
                  key={field.id}
                  className="rounded-sm border border-mediumGrey">
                  {index > 0 ? (
                    <Box pos="absolute" right={10}>
                      <ActionIcon
                        size="sm"
                        onClick={() => {
                          remove(index);
                        }}>
                        <Icon.Remove className="h-4 w-4" />
                      </ActionIcon>
                    </Box>
                  ) : null}
                  <Box>
                    <Controller
                      control={control}
                      name={`events.${index}.date`}
                      render={({ field: { onChange, value } }) => (
                        <div className="flex flex-col gap-2">
                          <DatePicker
                            onDateChange={onChange}
                            selectedDate={value}
                            ISOWeek={hotelDetails?.starts_monday}
                            timezone={hotelDetails?.timezone}
                            closeOnSelect
                          />
                          {formState.errors?.events?.[index]?.date?.message ? (
                            <div className="pl-2">
                              <InputHelperMessage
                                icon={<Icon.WarningOutline className="h-3 w-3 text-error" />}
                                message={formState.errors?.events?.[index]?.date?.message}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name={`events.${index}.reservation_count`}
                      render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                        <TextInput
                          type="text"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          label="Reservation Count"
                          name={name}
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              ))}
              <Flex justify="flex-end">
                <Button
                  variant="outline"
                  onClick={() => {
                    append(EMPTY_EVENT);
                  }}>
                  Add Event
                </Button>
              </Flex>
            </Stack>
          </Modal.Body>
          <ModalFooter>
            <Button type="submit" loading={isLoading}>
              Simulate
            </Button>
          </ModalFooter>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
