export function Send(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20 12L4 4L6 12M20 12L4 20L6 12M20 12H6"
        stroke={props.stroke || 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
