export function NewEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7L21.15 7.77C21.6839 8.14525 22.0012 8.7574 22 9.41V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V9.41C2.01167 8.77156 2.32749 8.17706 2.85 7.81L4 7V6C4 4.89543 4.89543 4 6 4H8.24L10.35 2.52C11.341 1.82732 12.659 1.82732 13.65 2.52L15.76 4H18C19.1046 4 20 4.89543 20 6V7ZM18 6H6V10.81L11.5 14.65C11.6456 14.7548 11.8206 14.8108 12 14.81C12.1777 14.8099 12.3516 14.7578 12.5 14.66L18 10.81V6Z"
        fill="currentColor"
      />
    </svg>
  );
}
