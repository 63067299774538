import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { formattedCurrency } from '@pages/Client/PricingStrategy/DayOfTheWeekAdjustments/utils/formattedCurrency';
import { Feature, useFeaturesStore } from '@common/store/features';

type DataKey = 'llm' | 'abpnm' | 'pmsprice';

// abpnm = Average Base Price Next 3 Months
// llm = Levels of Local Market
// pmsprice = PMS Price
interface Props {
  llm: { day: string; value: number }[];
  abpnm: { day: string; value: number }[];
  pmsprice: { day: string; value: number }[];
  startMonday?: boolean;
}

interface CustomBarProps {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
  maxY: number;
  dataKey: DataKey;
  seriesVisibility: {
    [key: string]: boolean;
  };
  borderWidth?: number;
  borderRadius?: number;
}

export const DayOfTheWeekAdjustmentsChart: React.FC<Props> = ({
  llm,
  abpnm,
  pmsprice,
  startMonday
}) => {
  const indigo = useTailwindColor('indigo');
  const orange = useTailwindColor('orange');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const isPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const { features } = useFeaturesStore();

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    llm: true,
    abpnm: true,
    pmsprice: true
  });

  const maxY = useMemo(() => {
    const allValues = [
      ...llm.map((item) => item.value),
      ...abpnm.map((item) => item.value),
      ...pmsprice.map((item) => item.value)
    ];

    return Math.max(...allValues);
  }, [llm, abpnm, pmsprice]);

  const CustomBar: React.FC<CustomBarProps> = ({
    fill,
    x,
    y,
    width,
    height,
    dataKey,
    seriesVisibility,
    borderWidth = 1,
    borderRadius = 4
  }) => {
    const barVisible = seriesVisibility[dataKey];

    const adjustedHeight = Math.max(0, height);
    const adjustedY = height < 0 ? y + height : y;

    return (
      <g>
        {barVisible ? (
          <>
            {adjustedHeight > 0 ? (
              <rect
                x={x}
                y={adjustedY}
                width={width}
                height={adjustedHeight}
                fill={fill}
                rx={borderRadius}
                ry={borderRadius}
              />
            ) : null}
            {adjustedHeight > borderWidth * 2 ? (
              <rect
                x={x + borderWidth}
                y={adjustedY + borderWidth}
                width={width - borderWidth * 2}
                height={adjustedHeight - borderWidth * 2}
                fill={fill}
                rx={borderRadius - borderWidth}
                ry={borderRadius - borderWidth}
              />
            ) : null}
          </>
        ) : null}
      </g>
    );
  };

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;
    // Add onClick handler to toggle series visibility
    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    return (
      <ul className="mb-8 flex list-none justify-center">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => (
            <li
              key={`item-${index}`}
              className="mr-2 flex cursor-pointer items-center"
              onClick={() => handleLegendClick(entry.dataKey)}>
              <div
                className="mr-1 h-3 w-3 rounded-full"
                style={{
                  backgroundColor: entry.color,
                  opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                }}
              />
              <Typography
                color="copyTextGrey"
                variant="meta-2"
                className={clsx(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                {entry.value}
              </Typography>
            </li>
          )
        )}
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white" className="bg-opacity-90 backdrop-blur">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dataPoint.day}
        </Typography>
        {features?.includes(Feature.NoMarketData) ? null : (
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-orange" />
            <Typography variant="meta-2" color="darkGrey">
              {t('Level of Local Market')}: <b>{formattedCurrency(dataPoint.llm)}</b>
            </Typography>
          </div>
        )}

        <div className="flex items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-indigo" />
          <Typography variant="meta-2" color="darkGrey">
            {t('Average Base Price Next 3 Months')}: <b>{formattedCurrency(dataPoint.abpnm)}</b>
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-mediumGrey" />
          <Typography variant="meta-2" color="darkGrey">
            {t(`${isPms} Price`)}: <b>{formattedCurrency(dataPoint.pmsprice)}</b>
          </Typography>
        </div>
      </Card>
    );
  };

  const customLegendPayload = [
    ...(features?.includes(Feature.NoMarketData)
      ? []
      : [
          {
            dataKey: 'llm',
            value: t('Level of Local Market'),
            color: orange
          }
        ]),
    {
      dataKey: 'abpnm',
      value: t('Average Base Price Next 3 Months'),
      color: indigo
    },
    {
      dataKey: 'pmsprice',
      value: t(`${isPms} Price`),
      color: mediumGrey
    }
  ];

  const daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // Define the custom comparator function
  const dayComparator = (day1: string, day2: string): number => {
    const dayIndex1: number = (daysOfWeek.indexOf(day1) + (startMonday ? 6 : 0)) % 7;
    const dayIndex2: number = (daysOfWeek.indexOf(day2) + (startMonday ? 6 : 0)) % 7;
    return dayIndex1 - dayIndex2;
  };

  return (
    <Card backgroundColor="white">
      <ResponsiveContainer width="100%" height={360}>
        <ComposedChart
          data={daysOfWeek.sort(dayComparator).map((day) => ({
            day: `${t(`${day}`)}`,
            llm: llm.find((x) => x.day === day)?.value ?? 0,
            abpnm: abpnm.find((x) => x.day === day)?.value ?? 0,
            pmsprice: pmsprice.find((x) => x.day === day)?.value ?? 0
          }))}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          barCategoryGap={10}>
          <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
          <XAxis
            axisLine={false}
            tick={{ fill: grey }}
            tickLine={false}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            dataKey="day"
          />
          <YAxis
            axisLine={false}
            tick={{ fill: grey }}
            tickLine={false}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            tickFormatter={(value) => formattedCurrency(value)}
          />
          <Tooltip content={(props) => <CustomTooltip {...props} />} />
          <Legend
            verticalAlign="top"
            content={(props) => <CustomLegend {...props} payload={customLegendPayload} />}
          />
          <Bar
            dataKey="abpnm"
            visibility={seriesVisibility.abpnm ? 'visible' : 'hidden'}
            name="Average Base Price Next 3 Months"
            fill={indigo}
            barSize={32}
            shape={(props) => (
              <CustomBar
                {...props}
                maxY={maxY}
                seriesVisibility={seriesVisibility}
                dataKey="abpnm"
              />
            )}
          />
          <Bar
            dataKey="pmsprice"
            visibility={seriesVisibility.pmsprice ? 'visible' : 'hidden'}
            name="Pms Price"
            fill={mediumGrey}
            barSize={32}
            shape={(props) => (
              <CustomBar
                {...props}
                maxY={maxY}
                seriesVisibility={seriesVisibility}
                dataKey="pmsprice"
              />
            )}
          />

          {features?.includes(Feature.NoMarketData) ? null : (
            <Line
              isAnimationActive={false}
              visibility={seriesVisibility.llm ? 'visible' : 'hidden'}
              dataKey="llm"
              name="Levels of Local Market"
              strokeWidth={2}
              stroke={orange}
              fill={orange}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
};
