export const MEDIAN_OPTIONS = [
  { label: 'Short', value: 14 },
  { label: 'Standard', value: 21 },
  { label: 'Long', value: 30 }
] as const;

/**
 * Duplicated from ELASTICITY_LIST in
 * src/pages/Client/Calendar/constants.ts
 */
export const AGGRESSIVENESS_OPTIONS = [
  { id: 1, label: 'Ignore Occupancy Changes', value: -1000 },
  { id: 2, label: 'Very Low', value: -12 },
  { id: 3, label: 'Low', value: -8 },
  { id: 4, label: 'Standard', value: -5.5 },
  { id: 5, label: 'High', value: -4.25 },
  { id: 6, label: 'Very High', value: -3.25 }
] as const;
