import { create } from 'zustand';

type SidebarState = {
  sidebarOpen: boolean;
  mobileNavOpen: boolean;
  openDisclosure: string | null;
  closingDisclosure: string | null;
  nextDisclosure: string | null;
  setSidebarOpen: (value: boolean) => void;
  setMobileNavOpen: (value: boolean) => void;
  setOpenDisclosure: (value: string | null) => void;
  setClosingDisclosure: (value: string | null) => void;
  setNextDisclosure: (value: string | null) => void;
};

export const useSidebarStore = create<SidebarState>((set) => ({
  sidebarOpen: true,
  mobileNavOpen: false,
  openDisclosure: null,
  closingDisclosure: null,
  nextDisclosure: null,
  setSidebarOpen: (value) => set({ sidebarOpen: value }),
  setMobileNavOpen: (value) => set({ mobileNavOpen: value }),
  setOpenDisclosure: (value) => set({ openDisclosure: value }),
  setClosingDisclosure: (value) => set({ closingDisclosure: value }),
  setNextDisclosure: (value) => set({ nextDisclosure: value })
}));
