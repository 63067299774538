export function Home(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M21 12.24V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 20.5523 15 20V14.5C15 14.2239 14.7761 14 14.5 14H9.5C9.22386 14 9 14.2239 9 14.5V20C9 20.5523 8.55228 21 8 21H4C3.44772 21 3 20.5523 3 20V12.24C3.0007 11.4446 3.31723 10.6821 3.88 10.12L10.71 3.29C10.8963 3.10526 11.1477 3.0011 11.41 3H12.59C12.8523 3.0011 13.1037 3.10526 13.29 3.29L20.12 10.12C20.6828 10.6821 20.9993 11.4446 21 12.24Z" />
    </svg>
  );
}
