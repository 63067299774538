import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { useModal } from '@common/hooks/useModal';
import { useCallback, useEffect, useState } from 'react';
import { AddNewPartnerUserModal } from '@pages/Admin/PartnerUsers/components/AddNewPartnerUserModal';
import { DeletePartnerUserModal } from '@pages/Admin/PartnerUsers/components/DeletePartnerUserModal';
import { UpdatePartnerUserModal } from '@pages/Admin/PartnerUsers/components/UpdatePartnerUserModal';
import { useGetPartnersUserList } from '@pages/Admin/PartnerUsers/hooks/usePartnerUserList';
import { SelectedPartnerUser } from '@pages/Admin/PartnerUsers/types/selectedPartnerUser';
import * as z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce } from 'lodash-es';
import { ActionIcon, Button } from '@mantine/core';

const schema = z.object({
  page: z.number(),
  search: z.string().optional()
});

type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== '' && value !== undefined && value !== null
    )
  ) as Params;
};

export const PartnersUsersList = () => {
  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();

  const [selectedPartnerUser, setSelectedPartnerUser] = useState<SelectedPartnerUser>();

  const defaultValues = {
    page: 1,
    search: undefined
  };

  const { watch, reset, control, getValues } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const {
    partnerUserList,
    query: { isLoading }
  } = useGetPartnersUserList(
    filterEmptyValues({
      page: watch('page'),
      search: getValues('search')
    })
  );

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, defaultValues, filterEmptyValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const ROWS_PER_PAGE = 20;

  const startIndex = partnerUserList?.current
    ? (partnerUserList.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    partnerUserList?.current && partnerUserList.results
      ? startIndex + partnerUserList.results.length - 1
      : 0;

  const handleNext = () => {
    if (!partnerUserList?.next) return;
    const params = new URLSearchParams(partnerUserList.next.split('?')[1]);
    const page = params.get('page');
    if (!page) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: parseInt(page)
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Partner',
      accessorKey: 'partner',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Name',
      accessorKey: 'first_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) + ' ' + row?.row.original?.last_name}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },

    {
      header: 'Delete',
      accessorKey: 'delete',
      cell: (row: any) => {
        return (
          <ActionIcon
            onClick={() => {
              setSelectedPartnerUser(row?.row?.original);
              deleteClick();
            }}>
            <Icon.Delete className="text-copyTextGrey" />
          </ActionIcon>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const PartnersUsersListHeader = () => {
    return (
      <div className="flex w-full items-center justify-between py-2">
        <Typography>Partner User List</Typography>
      </div>
    );
  };

  return (
    <div>
      <Table
        isHover
        isRowClickable
        isFetching={isLoading}
        headerComponent={<PartnersUsersListHeader />}
        skeletonCount={20}
        onClickRow={(row) => {
          setSelectedPartnerUser(row?.row?.original);
          editClick();
        }}
        columns={columns}
        data={
          partnerUserList?.results?.map((partnerUser) => ({
            id: partnerUser?.user?.id,
            memberId: partnerUser.id,
            first_name: partnerUser?.user.first_name,
            last_name: partnerUser?.user?.last_name,
            partner: partnerUser?.reseller?.name,
            reseller: partnerUser?.reseller?.id,
            sub_account_type: partnerUser?.user?.sub_account_type,
            email: partnerUser?.user?.email
          })) || []
        }
        next={handleNext}
        isNext={!!partnerUserList?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={partnerUserList?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        actionComponents={
          <div className="flex items-center gap-4">
            <Controller
              name="search"
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <Input
                  background="grey"
                  placeholder="Search ..."
                  name={name}
                  id={name}
                  type="text"
                  value={value}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    onChange(e);
                  }}
                  onClear={() => {
                    reset(defaultValues);
                  }}
                />
              )}
            />
            <div>
              <Button onClick={addClick}>Add New Partner User</Button>
            </div>
          </div>
        }
      />
      <AddNewPartnerUserModal isOpen={addOpen} onClose={addClose} />
      <UpdatePartnerUserModal
        isOpen={editOpen}
        onClose={editClose}
        selected={selectedPartnerUser}
      />
      <DeletePartnerUserModal
        isOpen={deleteOpen}
        onClose={deleteClose}
        partner={selectedPartnerUser}
      />
    </div>
  );
};
