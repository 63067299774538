export function Filter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      {...props}>
      <path
        d="M10 13.83C10.0004 13.297 9.78804 12.7858 9.41 12.41L3.59 6.59C3.21196 6.2142 2.99958 5.70304 3 5.17V4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V5.17C21.0004 5.70304 20.788 6.2142 20.41 6.59L14.59 12.41C14.212 12.7858 13.9996 13.297 14 13.83V17.5C14 18.1295 13.7036 18.7223 13.2 19.1L10.8 20.9C10.6485 21.0136 10.4458 21.0319 10.2764 20.9472C10.107 20.8625 10 20.6894 10 20.5V13.83Z"
        fill="currentColor"
      />
    </svg>
  );
}
