import { create } from 'zustand';
interface BookingUrl {
  isValidUrl: boolean;
  isSuccessUrl: boolean;
  setValidBookingUrl: (newLoading: boolean) => void;
  suggestBookingUrl: string;
  setSuggestBookingUrl: (newSuggestBookingUrl: string) => void;
  setIsSuccessUrl: (newIsSuccess: boolean) => void;
}

export const useCheckBookingUrlStore = create<BookingUrl>((set) => ({
  isValidUrl: true,
  setValidBookingUrl: (newLoading: boolean) => set({ isValidUrl: newLoading }),
  suggestBookingUrl: '',
  setSuggestBookingUrl: (newSuggestBookingUrl: string) =>
    set({ suggestBookingUrl: newSuggestBookingUrl }),
  isSuccessUrl: false,
  setIsSuccessUrl: (newIsSuccess: boolean) => set({ isSuccessUrl: newIsSuccess })
}));
