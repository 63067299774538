export const WarningOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8126 2.37L22.905 19.63C23.0349 19.8667 23.0349 20.1533 22.905 20.39L22.765 20.62C22.6354 20.8573 22.3852 21.0035 22.1148 21H1.89005C1.61967 21.0035 1.36952 20.8573 1.23989 20.62L1.09986 20.39C0.969968 20.1533 0.969968 19.8667 1.09986 19.63L11.2123 2.37C11.3491 2.14121 11.5958 2.00081 11.8624 2H12.1625C12.4291 2.00081 12.6758 2.14121 12.8126 2.37ZM12.0024 5L3.7905 19H20.2044L12.0024 5ZM11.5023 15C11.2261 15 11.0022 15.2239 11.0022 15.5V16.5C11.0022 16.7761 11.2261 17 11.5023 17H12.5026C12.7788 17 13.0027 16.7761 13.0027 16.5V15.5C13.0027 15.2239 12.7788 15 12.5026 15H11.5023ZM11.2223 12.78L10.8222 9.56C10.8049 9.41735 10.8499 9.27417 10.9456 9.16698C11.0413 9.05979 11.1786 8.99896 11.3223 9H12.6826C12.8263 8.99896 12.9635 9.05979 13.0593 9.16698C13.155 9.27417 13.2 9.41735 13.1827 9.56L12.7826 12.78C12.7674 12.9062 12.6598 13.0009 12.5326 13H11.4723C11.3451 13.0009 11.2375 12.9062 11.2223 12.78Z"
      />
    </svg>
  );
};
