export const GroupEqual = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00195 5C6.34464 5 5.00113 6.34315 5.00113 8C5.00113 9.65685 6.34464 11 8.00195 11C9.65926 11 11.0028 9.65685 11.0028 8C11.0028 6.34315 9.65926 5 8.00195 5ZM19.005 8C19.005 9.65685 17.6615 11 16.0042 11C14.3468 11 13.0033 9.65685 13.0033 8C13.0033 6.34315 14.3468 5 16.0042 5C17.6615 5 19.005 6.34315 19.005 8ZM22.896 17.55L21.4557 14.66C20.9483 13.6441 19.9107 13.0016 18.7749 13H14.7738C14.9569 13.2381 15.1176 13.4925 15.2539 13.76L16.7043 16.66C17.0633 17.3901 17.1102 18.2346 16.8344 19H22.0058C22.3525 18.999 22.6739 18.8185 22.8553 18.5231C23.0366 18.2277 23.0521 17.8595 22.896 17.55ZM10.7627 13C11.9021 12.9978 12.9445 13.6408 13.4535 14.66L14.8938 17.55C15.0499 17.8595 15.0344 18.2277 14.8531 18.5231C14.6717 18.8185 14.3503 18.999 14.0036 19H2.0003C1.65361 18.999 1.33219 18.8185 1.15083 18.5231C0.969472 18.2277 0.954044 17.8595 1.11006 17.55L2.55045 14.66C3.05939 13.6408 4.10178 12.9978 5.24119 13H10.7627Z"
      />{' '}
    </svg>
  );
};
