import { FormInput } from '@common/components/atoms/Input';
import { useEffect, useMemo } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { cn } from '@common/utils/cn';
import { find, isEmpty, map, orderBy, result } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useHotelPricePerOccupancy } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPricePerOccupancy';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Typography } from '@common/components/foundations/Typography';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { Occupancy } from '@pages/Client/Calendar/components/BulkEdit/types/schema/occupancySchema';

export function OccupancyAdjustmentForm({ nestIndex, roomId }: { nestIndex: number; roomId: any }) {
  const { t } = useTranslation();
  const formName = `${ADJUSTMENT.OCCUPANCY}[${nestIndex}]`;
  const { pmsList } = useGetHotelPmsList();
  const { pricingSettings } = usePricingSettings();
  const { control } = useFormContext();
  const { selectedHotelRoom } = useHotelRoomsList();
  const { hotelPricePerOccupancy } = useHotelPricePerOccupancy(roomId);

  const { fields, replace } = useFieldArray({ name: formName, control });

  const isOccupancyPercentDerivation = find(pmsList, {
    primary: true
  })?.occupancy_percent_derivation;

  const newFields = useMemo(() => {
    const pricing = result(pricingSettings, 'default');
    return map(orderBy(hotelPricePerOccupancy, 'occupancy'), (room) => {
      const occupancy: Occupancy = {
        id: room.id,
        basePrice: result(pricing, `${room.room}.${ADJUSTMENT_DB.AVG_PRICE_KEY}`),
        baseValue: selectedHotelRoom?.default_occupancy,
        derivation: room.derivation,
        isBulkEdit: true,
        occupancy: room.occupancy,
        room: room.room,
        value: undefined
      };
      return occupancy;
    });
  }, [hotelPricePerOccupancy]);

  useEffect(() => {
    if (isEmpty(fields)) replace(newFields);
  }, [newFields]);

  return (
    <LineTable>
      <thead>
        <tr>
          <th className="w-1/4">{t('Occupancy (Persons)')}</th>
          <th>{t('Price Difference from Default Occupancy Price')}</th>
        </tr>
      </thead>

      <tbody>
        {map(fields, (field, index) => {
          const occupancy = Number(result(field, 'occupancy'));
          const derivation = Number(result(field, 'derivation'));
          const baseOccupancy = Number(result(field, 'baseValue'));
          const basePrice = Math.round(Number(result(field, 'basePrice')));
          const isLowerBound = (baseOccupancy || 0) < occupancy;
          return (
            <tr
              key={field.id}
              className={cn(occupancy === baseOccupancy ? 'bg-indigo bg-opacity-[0.06]' : '')}>
              <td className="pr-5 text-right">{occupancy}</td>
              <td>
                <div className="w-full">
                  {occupancy === baseOccupancy ? (
                    <Typography color="darkGrey">
                      {t('Base Price')}: {CurrencyFormatter.currencySymbol()}
                      {basePrice}
                    </Typography>
                  ) : (
                    <FormInput
                      type="number"
                      nameKey={`${formName}.${index}.id` as const}
                      valueKey={`${formName}.${index}.value` as const}
                      background="grey"
                      showClearButton={false}
                      leadingAddon={
                        isLowerBound
                          ? `${
                              isOccupancyPercentDerivation
                                ? '%'
                                : CurrencyFormatter.currencySymbol()
                            }+`
                          : `${
                              isOccupancyPercentDerivation
                                ? '%'
                                : CurrencyFormatter.currencySymbol()
                            }-`
                      }
                      trailingAddon={
                        <span className="flex flex-col items-end">
                          <Typography variant="meta-3" color="grey">
                            {t('Default')}
                          </Typography>
                          <Typography variant="meta-2" color="grey">
                            {isOccupancyPercentDerivation
                              ? `${isLowerBound ? '' : '-'}${Math.round(derivation || 0) + ' %'}`
                              : `${isLowerBound ? '' : '-'}${CurrencyFormatter.format(
                                  derivation || 0
                                )}`}
                          </Typography>
                        </span>
                      }
                    />
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </LineTable>
  );
}
