import { searchHotelList } from '@common/api/hotel';
import { SearchHotelResponse } from '@common/api/hotel/types';
import { ClientQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Params = {
  search: string;
  country: number;
};

export const useSearchHotelList = ({ search = '', country }: Params) => {
  const query = useQuery({
    queryKey: [ClientQueryKeys.SEARCH_ASSIGN_CLIENT, search, country],
    queryFn: async () => {
      if (search.length >= 5) {
        const response = await searchHotelList(search, country);
        return response?.data || []; // Return an empty array if response.data is undefined
      } else {
        return [];
      }
    },
    retry: false
  });

  const hotelList = useMemo(() => {
    return query.data as SearchHotelResponse;
  }, [query.data]);

  return { hotelList, query };
};
