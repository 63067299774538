import { ErrorResponse } from '@common/api/auth';
import { exportDashboardExcelReport } from '@common/api/dashboard';
import { TaxMode } from '@common/api/dashboard/types';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { revenueLookup } from '@pages/Client/Dashboard/pages/Analytics/Analytics';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMultiPropertyFilters } from '@pages/Client/hooks/useMultiPropertyFilters';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

export const useDashboardExportExcel = ({
  type
}: {
  type: 'performance' | 'analytics' | 'market';
}) => {
  const { addNotification } = useNotificationsStore();
  const { user: userData } = useAuthStore();
  const { hotelDetails } = useHotelDetails();
  const { propertyIds, dashboardHotels } = useMultiPropertyFilters();
  const { performanceFilter } = useDashboardPageStore();
  const { t } = useTranslation();

  const taxModeKey = revenueLookup[performanceFilter].toLowerCase() as TaxMode;

  const selectedPropertyIds = hotelDetails?.id
    ? propertyIds?.[hotelDetails.id] || [hotelDetails.id]
    : [];

  const allPropertyIds = dashboardHotels.map((hotel) => hotel.id);

  const hotelIds = type === 'performance' ? selectedPropertyIds : allPropertyIds;

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return exportDashboardExcelReport({
        recipients: userData?.email ? [userData?.email] : [],
        hotels: hotelIds,
        tax_mode: taxModeKey
      });
    },
    onSuccess: (data) => {
      const successMessage = data?.data?.message || 'Excel report successfuly exported';
      addNotification('success', t(successMessage));
    },
    onError: (error: AxiosError<ErrorResponse> | unknown) => {
      let errorMessage = 'Failed to export excel data';
      if (error instanceof AxiosError) {
        errorMessage = error.response?.data?.error?.message ?? errorMessage;
      }
      addNotification('fail', t(errorMessage));
    }
  });

  return {
    exportExcel: mutate,
    isLoading
  };
};
