export const Labels = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0001 11.07V3.12C18.9964 2.85523 18.8928 2.60164 18.7101 2.41L18.5901 2.29C18.3984 2.10727 18.1448 2.00368 17.8801 2H9.93006C9.39702 1.99958 8.88586 2.21196 8.51006 2.59L1.29006 9.81C0.902337 10.2 0.902337 10.83 1.29006 11.22L9.78006 19.71C10.1701 20.0977 10.8 20.0977 11.1901 19.71L18.4101 12.49C18.7881 12.1142 19.0005 11.603 19.0001 11.07ZM14.0001 9C12.8955 9 12.0001 8.10457 12.0001 7C12.0001 5.89543 12.8955 5 14.0001 5C15.1046 5 16.0001 5.89543 16.0001 7C16.0001 7.53043 15.7893 8.03914 15.4143 8.41421C15.0392 8.78929 14.5305 9 14.0001 9ZM22.5901 6.29L22.7101 6.41C22.8928 6.60163 22.9964 6.85523 23.0001 7.12V11.9C23.0011 12.9858 22.5608 14.0254 21.7801 14.78L14.7801 21.78C14.4872 22.0725 14.0129 22.0725 13.7201 21.78L12.8301 20.89L20.4101 13.32C20.7881 12.9442 21.0005 12.433 21.0001 11.9V6H21.8801C22.1448 6.00368 22.3984 6.10727 22.5901 6.29Z"
      />
    </svg>
  );
};
