import {
  ADJUSTMENT,
  ADJUSTMENT_DB
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { Draft } from 'immer';
import { Day } from 'use-lilius';
import {
  PropertyDefaultsSchema,
  TransformSchema
} from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { Engine, Rule } from 'json-rules-engine';
import { PricingSettings } from '@common/api/hotel/types';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';

/**
 * Rules for setting and deleting the aggressiveness key:
 *
 * - Set Aggressiveness Key on Weekends:
 *   - Condition:
 *     - The day of the week is either Friday or Saturday
 *     - The formData (aggressiveness adjustment) is not equal to the weekend default
 *   - Action:
 *     - Set the aggressiveness key (as specified by the `setAggressivenessKey` event)
 *
 * - Delete Aggressiveness Key on Weekends:
 *   - Condition:
 *     - The day of the week is either Friday or Saturday
 *     - The formData (aggressiveness adjustment) is equal to the weekend default
 *   - Action:
 *     - Delete the aggressiveness key (as specified by the `deleteAggressivenessKey` event)
 *
 * - Set Aggressiveness Key on Weekdays:
 *   - Condition:
 *     - The day of the week is not Friday or Saturday
 *     - The formData (aggressiveness adjustment) is not equal to the week default
 *   - Action:
 *     - Set the aggressiveness key (as specified by the `setAggressivenessKey` event)
 *
 * - Delete Aggressiveness Key on Weekdays:
 *   - Condition:
 *     - The day of the week is not Friday or Saturday
 *     - The formData (aggressiveness adjustment) is equal to the week default
 *   - Action:
 *     - Delete the aggressiveness key (as specified by the `deleteAggressivenessKey` event)
 */

const actions = {
  setAggressivenessKey: 'setAggressivenessKey',
  deleteAggressivenessKey: 'deleteAggressivenessKey'
};

const isWeekendValue = { fact: 'dayOfWeek', operator: 'in', value: [Day.FRIDAY, Day.SATURDAY] };
const isWeekDayValue = { fact: 'dayOfWeek', operator: 'notIn', value: [Day.FRIDAY, Day.SATURDAY] };

const setKeyOnWeekends = new Rule({
  conditions: {
    all: [
      isWeekendValue,
      { fact: 'formData', operator: 'notEqual', value: { fact: 'weekendDefault' } }
    ]
  },
  event: { type: actions.setAggressivenessKey }
});

const deleteKeyOnWeekends = new Rule({
  conditions: {
    all: [
      isWeekendValue,
      { fact: 'formData', operator: 'equal', value: { fact: 'weekendDefault' } }
    ]
  },
  event: { type: actions.deleteAggressivenessKey }
});

const setKeyOnWeekdays = new Rule({
  conditions: {
    all: [
      isWeekDayValue,
      { fact: 'formData', operator: 'notEqual', value: { fact: 'weekDefault' } }
    ]
  },
  event: { type: actions.setAggressivenessKey }
});

const deleteKeyOnWeekdays = new Rule({
  conditions: {
    all: [isWeekDayValue, { fact: 'formData', operator: 'equal', value: { fact: 'weekDefault' } }]
  },
  event: { type: actions.deleteAggressivenessKey }
});

export async function transformAggressiveness(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  rpgDefaults: PropertyDefaultsSchema,
  formData: Partial<TransformSchema>,
  date: string
) {
  if (isEmpty(formData) || !formData[ADJUSTMENT.AGGRESSIVENESS]) return;
  const engine = new Engine();
  engine.addRule(setKeyOnWeekends);
  engine.addRule(deleteKeyOnWeekends);
  engine.addRule(setKeyOnWeekdays);
  engine.addRule(deleteKeyOnWeekdays);

  engine.on('success', (event) => {
    if (event.type === actions.setAggressivenessKey) {
      if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
      currentPricingDraft.dates[date][ADJUSTMENT_DB.AGGRESSIVENESS_KEY] = formData[
        ADJUSTMENT.AGGRESSIVENESS
      ] as number;
    }
    if (event.type === actions.deleteAggressivenessKey) {
      delete currentPricingDraft.dates[date]?.[ADJUSTMENT_DB.AGGRESSIVENESS_KEY];
    }
  });

  await engine.run({
    dayOfWeek: dayjs(date).day(),
    formData: formData[ADJUSTMENT.AGGRESSIVENESS],
    weekendDefault: rpgDefaults[ADJUSTMENT_DB.PELL_WEEKEND_KEY],
    weekDefault: rpgDefaults[ADJUSTMENT_DB.PELL_WEEKDAY_KEY]
  });

  return;
}
