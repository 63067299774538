import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@common/components/foundations/Typography';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { chunk, isEmpty } from 'lodash-es';
import { cn } from '@common/utils/cn';
import { Icon } from '@common/components/foundations/icons';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Button } from '@common/components/atoms/Button';
import { RadioGroup } from '@headlessui/react';
import { useAuthStore } from '@common/store/auth';

import { motion, AnimatePresence } from 'framer-motion';
import {
  useSSOApaleo,
  useSSOApaleoAuth,
  useSSOSeekom,
  useSSOSeekomAuth,
  useSSOCloudbeds,
  useSSOCloudbedsAuth,
  useSSOFreetobook
} from '@pages/Auth/SSO/hooks/useSSO';

const variants = {
  hidden: { opacity: 0, x: -100 }, // Always start from the left
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 100 } // Always move to the right
};

export const Init = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setHotel } = useAuthStore();
  const { control, handleSubmit, getValues } = useForm();
  const [setIndex, setSetIndex] = useState(0);
  const { pms, code } = useParams();
  const {
    mutateAsync: ssoApaleoMutation,
    isPending: isApaleoLoading,
    data: apaleoData,
    isError: isApaleoError,
    error: apaleoError
  } = useSSOApaleo();
  const {
    mutateAsync: ssoApaleoAuthMutation,
    data: apaleoAuthData,
    isPending: isCreatePropertyApaleo
  } = useSSOApaleoAuth();
  const {
    mutateAsync: ssoSeekomMutation,
    isPending: isSeekomLoading,
    data: seekomData,
    isError: isSeekomError,
    error: seekomError
  } = useSSOSeekom();
  const {
    mutateAsync: ssoSeekomAuthMutation,
    data: seekomAuthData,
    isPending: isCreatePropertySeekom
  } = useSSOSeekomAuth();
  const {
    mutateAsync: ssoCloudbedsMutation,
    isPending: isCloudbedsLoading,
    data: cloudbedsData,
    isError: isCloudbedsError,
    error: cloudbedsError
  } = useSSOCloudbeds();
  const {
    mutateAsync: ssoCloudbedsAuthMutation,
    data: cloudbedsAuthData,
    isPending: isCreatePropertyCloudbeds
  } = useSSOCloudbedsAuth();
  const {
    mutateAsync: ssoFreetobookMutation,
    isPending: isFreetobookLoading,
    data: freetobookData
  } = useSSOFreetobook();

  useEffect(() => {
    if (code && pms === 'apaleo') {
      ssoApaleoMutation({ code });
    }

    if (code && pms === 'seekom') {
      ssoSeekomMutation({ code });
    }

    if (code && pms == 'freetobook') {
      ssoFreetobookMutation({ code });
    }

    if (code && pms === 'cloudbeds') {
      ssoCloudbedsMutation({ code });
    }
  }, [code, pms]);

  useEffect(() => {
    if (!freetobookData) return;

    setHotel(freetobookData.hotel_list[0].id);
    navigate(`/client/calendar/${freetobookData.hotel_list[0].id}`);
  }, [freetobookData]);

  useEffect(() => {
    if (!seekomAuthData) return;

    setHotel(seekomAuthData.hotel_list[0].id);
    navigate(`/client/calendar/${seekomAuthData.hotel_list[0].id}`);
  }, [seekomAuthData]);

  useEffect(() => {
    if (!apaleoAuthData) return;

    setHotel(apaleoAuthData.hotel_list[0].id);
    navigate(`/client/calendar/${apaleoAuthData.hotel_list[0].id}`);
  }, [apaleoAuthData]);

  useEffect(() => {
    if (!cloudbedsAuthData) return;

    setHotel(cloudbedsAuthData.hotel_list[0].id);
    navigate(`/client/calendar/${cloudbedsAuthData.hotel_list[0].id}`);
  }, [cloudbedsAuthData]);

  const isLoading = isApaleoLoading || isSeekomLoading || isCloudbedsLoading || isFreetobookLoading;
  const isError = isApaleoError || isSeekomError || isCloudbedsError;

  const isCreateProperty =
    isCreatePropertyApaleo || isCreatePropertySeekom || isCreatePropertyCloudbeds;
  const properties = apaleoData || seekomData || cloudbedsData;
  const error = cloudbedsError || apaleoError || seekomError;

  const onSubmit = async () => {
    const { 'property-selection': propertyCode } = getValues();

    if (!properties?.token) return;

    if (propertyCode && pms === 'apaleo') {
      await ssoApaleoAuthMutation({
        property_id: propertyCode,
        token: properties.token,
        refresh_token: properties.refresh_token
      });
      return;
    }

    if (propertyCode && pms === 'seekom') {
      await ssoSeekomAuthMutation({
        property_id: propertyCode,
        token: properties.token,
        refresh_token: properties.refresh_token
      });
      return;
    }

    if (propertyCode && pms === 'cloudbeds') {
      await ssoCloudbedsAuthMutation({
        property_id: propertyCode,
        token: properties.token,
        refresh_token: properties.refresh_token
      });
      return;
    }
  };

  const propertySets = chunk(properties?.property_list, 3);

  // Function to cycle to the next set of properties
  const cycleProperties = (direction: number) => {
    setSetIndex((prevIndex) => (prevIndex + direction + propertySets.length) % propertySets.length);
  };

  return (
    <div className="relative flex w-full flex-1 flex-col justify-center overflow-y-auto px-4 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className=" mx-auto max-h-[600px] w-full 2xl:w-3/4">
        <div className="sticky">
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
              {t('Hi, there!')}
            </Typography>
            <Typography element="p" variant="paragraph-1" color="copyTextGrey">
              {t(
                'We noticed that you have multiple properties. Please select the property you want to set up now. If you want to set up more properties, please use the SSO button again and set them up one by one. Thank you very much!'
              )}
            </Typography>
          </div>
        </div>

        <div className="mt-8">
          <div className="mt-6">
            <div className="space-y-6">
              <div className="mt-2">
                <div className="flex flex-col space-y-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      control={control}
                      name="property-selection"
                      render={({ field: { value, onChange } }) => (
                        <RadioGroup value={value} onChange={onChange}>
                          <RadioGroup.Label className="sr-only">Property List</RadioGroup.Label>

                          {isError ? (
                            <Typography variant="meta-1" color="error">
                              {error?.response?.data.message}
                            </Typography>
                          ) : null}
                          {isLoading
                            ? Array.from({ length: 1 }).map((_, i) => (
                                <div
                                  key={`skeleton-item-${i}`}
                                  className="focus:ring-3 relative flex cursor-pointer rounded-lg py-5 focus:ring-indigo-reduced">
                                  <div className="flex w-full items-center justify-between gap-2">
                                    <div className="flex items-center">
                                      <div className="max-w-[600px] text-sm ">
                                        <Skeleton className="h-6 w-72" />
                                      </div>
                                    </div>
                                    <div className="shrink-0 text-white">
                                      <Skeleton className="h-6 w-6 rounded-full" />
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}

                          <AnimatePresence initial={false}>
                            <div className="relative h-64 max-h-80">
                              <motion.div
                                key={setIndex}
                                variants={variants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{
                                  ease: 'anticipate',
                                  duration: 0.6
                                }}
                                className="space-y-4">
                                {propertySets?.[setIndex]?.map((property) => (
                                  <RadioGroup.Option
                                    key={property.property_code}
                                    value={property.property_code}
                                    className={({ active, checked }) =>
                                      cn(
                                        active ? 'ring-1 ring-indigo-reduced' : '',
                                        checked ? 'border border-indigo bg-white' : 'bg-white',
                                        'focus:ring-3 relative flex cursor-pointer rounded-lg px-5 py-5 focus:ring-indigo-reduced'
                                      )
                                    }>
                                    {({ checked }) => (
                                      <>
                                        <div className="flex w-full items-center justify-between">
                                          <div className="flex items-center">
                                            <div className="max-w-[600px] text-sm">
                                              <RadioGroup.Label as="span" className="mb-2">
                                                <Typography
                                                  variant="paragraph-1"
                                                  element="p"
                                                  color="darkGrey">
                                                  {property.property_name}
                                                </Typography>
                                              </RadioGroup.Label>
                                            </div>
                                          </div>

                                          <div
                                            className={cn(
                                              'shrink-0 rounded-full p-1',
                                              checked
                                                ? ' bg-indigo-reduced'
                                                : 'border border-indigo-reduced'
                                            )}>
                                            <Icon.CheckList
                                              className={cn(
                                                'h-6 w-6',
                                                checked ? 'fill-indigo' : 'fill-transparent'
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </RadioGroup.Option>
                                ))}
                              </motion.div>
                            </div>
                          </AnimatePresence>

                          {propertySets?.length > 1 ? (
                            <div className="mt-2 flex items-center justify-end gap-1">
                              <Button
                                type="button"
                                icon
                                onClick={() => cycleProperties(-1)}
                                className="bg-white">
                                <Icon.ChevronUp />
                              </Button>
                              <Button
                                type="button"
                                icon
                                onClick={() => cycleProperties(1)}
                                className="bg-white">
                                <Icon.ChevronDown />
                              </Button>
                            </div>
                          ) : null}
                        </RadioGroup>
                      )}
                    />
                    <div className="mb-20 mt-4 flex justify-end">
                      <Button
                        disabled={isLoading || isEmpty(properties) || isError}
                        isLoading={isLoading || isCreateProperty}
                        type="submit">
                        {t('Create Property')}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
