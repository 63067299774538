import { updateSyncPms } from '@common/api/hotel';
import { SyncPmsUpdatePayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useSyncPmsUpdate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: SyncPmsUpdatePayload) => {
      return updateSyncPms(data);
    },
    onSuccess: () => {
      // addNotification('success', 'Successfully updated PMS connection');
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', `Failed to update ${channelManagerOrPms} connection`);
    }
  });

  return {
    syncPmsUpdate: mutate,
    isLoading
  };
};
