import { priceChangeTrendThresholdPercentage } from '@pages/Client/Calendar/utils/priceChangeTrendThresholdPercentage';

type PriceChangeTrend = {
  isLocked: boolean;
  priceChange: number;
  rawPrice: number;
};

export const priceTrendStatus = ({
  isLocked,
  priceChange,
  rawPrice
}: PriceChangeTrend): 'lock' | 'up' | 'down' | undefined => {
  if (isLocked) {
    return 'lock';
  }
  if (Math.abs(priceChange) > priceChangeTrendThresholdPercentage(rawPrice)) {
    return priceChange > 0 ? 'up' : 'down';
  }
  return undefined;
};
