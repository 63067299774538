import React from 'react';
import { ClerkProvider } from '@clerk/clerk-react';

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) throw new Error('Missing Auth Key');

export function ClerkAppProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <ClerkProvider
      appearance={{ variables: { colorPrimary: '#5A47EF' } }}
      signUpUrl="https://roompricegenie.com/sign-up/"
      publishableKey={PUBLISHABLE_KEY}>
      {children}
    </ClerkProvider>
  );
}
