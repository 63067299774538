export const getStatus = (status: number): string => {
  const statusCodes: { [key: number]: string } = {
    1: 'Open',
    2: 'Closed',
    3: 'Blocked'
  };

  return statusCodes[status] || 'Unknown Status';
};
export const getBadgeColor = (status: number): string => {
  const statusCodes: { [key: number]: string } = {
    1: 'darkGreen',
    2: 'error',
    3: 'darkGrey'
  };

  return statusCodes[status] || 'Unknown Status';
};
