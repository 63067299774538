import { useEffect } from 'react';
import { useLogout, useHotelStore } from '@common/store/auth';
import { useViewStore } from '@common/store/view';
import { useEventTracking } from '@common/hooks/useEventTracking';

export const Logout = () => {
  const { view } = useViewStore();
  const { trackEvent } = useEventTracking();
  const logoutUser = useLogout();
  const { clearHotelAuth } = useHotelStore();

  useEffect(() => {
    logoutUser();
    clearHotelAuth();
    trackEvent('Logout');
  }, [view, clearHotelAuth, trackEvent]);

  // Return an empty fragment
  return <></>;
};
