import { ChipAsCheckbox } from '@common/components/atoms/Chip';
import { WEEK_DAYS } from '@common/constants/date';
import { useTranslation } from 'react-i18next';
import { isEmpty, map } from 'lodash-es';
import { Fragment, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BulkEditSchema, WeekDay } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

export function WeekDayChips() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { hotelDetails } = useHotelDetails();
  const { fields, replace } = useFieldArray({ name: 'weekDays', control: control });

  const weekDaysArray = [...WEEK_DAYS(!!hotelDetails?.starts_monday)];

  useEffect(() => {
    const weekDays = map(weekDaysArray, (day) => ({
      name: day,
      value: true
    })) as WeekDay[];
    if (!isEmpty(weekDays)) replace(weekDays);
  }, [hotelDetails]);

  return (
    <Fragment>
      {fields.map((field, index) => (
        <ChipAsCheckbox<BulkEditSchema>
          key={field.id}
          nameKey={`weekDays.${index}.name` as const}
          valueKey={`weekDays.${index}.value` as const}
          className="flex-1 justify-center px-4 py-2 uppercase">
          {t(weekDaysArray?.[index])}
        </ChipAsCheckbox>
      ))}
    </Fragment>
  );
}
