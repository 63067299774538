import { Icon } from '@common/components/foundations/icons';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '@common/components/atoms/MultiSelect/MultiSelect.module.css';
import { Checkbox } from '@mantine/core';
import { Badge } from '@common/components/atoms/Badge';

interface SelectDropdownProps<TValue = unknown> {
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;

  value?: TValue | TValue[];

  onChange?: (value: TValue | TValue[]) => void;
  hint?: string;
  fullWidth?: boolean;
  options?: {
    label: string;
    value: TValue;
  }[];
  global?: boolean;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  inputClassName?: string;
  background?: 'grey' | 'white';
  isInitialValue?: boolean;
}

export const MultiSelect = <T,>({
  prefixIcon,
  suffixIcon,
  value: initialValue = [],
  options,
  fullWidth,
  hint,
  onChange,
  global,
  disabled = false,
  name,
  inputClassName,
  isInitialValue = false,
  placeholder = 'Select options',
  background = 'white'
}: SelectDropdownProps<T>) => {
  const [selectedValues, setSelectedValues] = useState(
    initialValue instanceof Array ? initialValue : [initialValue]
  );
  const form = useFormContext();

  const selectedOptions = useMemo(() => {
    return options?.filter((option) => selectedValues.includes(option.value)) || [];
  }, [options, selectedValues]);

  const handleOnChange: SelectDropdownProps<T>['onChange'] = (value) => {
    if (value instanceof Array) {
      setSelectedValues(value);
    } else {
      setSelectedValues([value]);
    }

    if (global) {
      form.setValue(name, value);
    }

    onChange?.(value);
  };

  useEffect(() => {
    if (isInitialValue) {
      setSelectedValues(initialValue instanceof Array ? initialValue : [initialValue]);
    }
  }, [initialValue, isInitialValue]);

  return (
    <Listbox value={selectedValues} onChange={handleOnChange} multiple disabled={disabled}>
      {({ open }) => (
        <div className={`relative mt-1 ${fullWidth ? 'w-full' : 'max-w-[260px]'}`}>
          <Listbox.Button
            className={classNames(
              styles.input,
              open && styles.dropdownOpened,
              background === 'grey' && styles.inputGrey,
              disabled && 'cursor-not-allowed',
              inputClassName
            )}>
            <span className="flex flex-wrap items-center gap-x-2">
              {prefixIcon}
              {selectedOptions.length === 0 ? (
                <span className="text-grey">{placeholder}</span>
              ) : (
                <span className="flex max-w-full flex-wrap gap-2 text-grey">
                  {selectedOptions.map((option, index) => (
                    <div key={index}>
                      <Badge
                        variant="rounded"
                        textTransform="capitalize"
                        background="indigo"
                        className="p-1 px-2 !text-white">
                        {option.label}
                        <Icon.Clear
                          className="ml-1 h-3 w-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOnChange(selectedValues.filter((v) => v !== option.value));
                          }}
                        />
                      </Badge>
                    </div>
                  ))}
                </span>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5">
              <Icon.ChevronDown className="h-4 w-4" aria-hidden="true" />
              {suffixIcon && <span className="ml-3">{suffixIcon}</span>}
            </span>
          </Listbox.Button>

          {hint && <div className="hint mt-1 pl-3 text-xs text-grey">{hint}</div>}

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className={styles.dropdownOptionsWrapper}>
              <Listbox.Options className={styles.dropdownOptions}>
                {options?.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={
                      'relative cursor-pointer select-none py-2 pl-8 pr-8 text-grey hover:bg-lightGrey'
                    }
                    value={option.value}>
                    {({ selected }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate leading-normal'
                          )}>
                          {option.label}
                        </span>
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-0 flex items-center pl-2'
                          )}>
                          <Checkbox checked={selected} />
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
