import { useAuthStore, useHotelStore } from '@common/store/auth';
import { setAuthToken } from '@common/api/axiosInstance';
import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { FullscreenLoading } from '@pages/Client/components/FullscreenLoading';
import { Flags } from '@common/constants/flags';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  type: 'admin' | 'client';
  children: React.ReactNode;
};

export const ProtectedLayout = ({ type, children }: Props) => {
  const clerk = useUser();
  const flags = useFlags();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { setHotelAuthToken } = useHotelStore();
  const subdomain = window.location.hostname.split('.')[0];
  const isIframeSubdomain = subdomain.includes('iframe');
  const { isAuthenticated, user, setAuth, isOTPValidated, setUserEmails } = useAuthStore();

  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const performAuth = async () => {
      if (token && !isIframeSubdomain) {
        setAuthToken(token);
        setAuth(token, null);
        setHotelAuthToken(token);
      }
      setAuthInitialized(true);
      searchParams.delete('token');
    };

    performAuth();
  }, [token, setHotelAuthToken, setAuthToken]);

  const isStaff = user?.is_staff;
  const isPartnerUser = user?.sub_account_type === 2;
  const loginRedirect = flags[Flags.ShowClerkComponent] ? '/login' : '/login-legacy';

  useEffect(() => {
    setUserEmails();
  }, []);

  switch (true) {
    case !clerk.isLoaded && !authInitialized:
      return <FullscreenLoading />;

    case !isAuthenticated && authInitialized:
      return <Navigate to={loginRedirect} replace />;

    case type === 'admin' && !isStaff && !isPartnerUser:
      return <Navigate to={loginRedirect} replace />;

    case !clerk.isSignedIn && type === 'admin' && isStaff && !isOTPValidated:
      return <Navigate to="/otp" replace />;

    default:
      return <>{children}</>;
  }
};
