export const Publish = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42 10.0001C6.28441 10.002 6.15408 9.94771 6.06 9.85005L5.86 9.65005C5.76534 9.55617 5.7121 9.42837 5.7121 9.29505C5.7121 9.16174 5.76534 9.03394 5.86 8.94005L11.65 3.15005C11.7407 3.05338 11.8674 2.99854 12 2.99854C12.1326 2.99854 12.2593 3.05338 12.35 3.15005L18.14 8.94005C18.2347 9.03394 18.2879 9.16174 18.2879 9.29505C18.2879 9.42837 18.2347 9.55617 18.14 9.65005L17.94 9.85005C17.8459 9.94771 17.7156 10.002 17.58 10.0001H15V15.5001C15 15.7762 14.7761 16.0001 14.5 16.0001H9.5C9.22386 16.0001 9 15.7762 9 15.5001V10.0001H6.42ZM19 19.5001V18.5001C19 18.2239 18.7761 18.0001 18.5 18.0001H5.5C5.22386 18.0001 5 18.2239 5 18.5001V19.5001C5 19.7762 5.22386 20.0001 5.5 20.0001H18.5C18.7761 20.0001 19 19.7762 19 19.5001Z"
      />
    </svg>
  );
};
