import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Divider } from '@common/components/atoms/Divider';
import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Icon } from '@common/components/foundations/icons';
import { LoadingCircle } from '@common/components/foundations/icons/icons/LoadingCircle';
import { Typography } from '@common/components/foundations/Typography';
import { isBookingUrl } from '@pages/Client/Account/Property/utils/validate-booking-url';
import { useCheckBookingUrl } from '@pages/Client/Account/Property/hooks/useCheckBookingUrl';
import { useSaveEvent } from '@pages/Client/Account/Property/hooks/useHotel';
import { useBookingCheckLoadingStore } from '@pages/Client/Account/Property/store/bookingCheckLoading';
import { useCheckBookingUrlStore } from '@pages/Client/Account/Property/store/bookingUrl';
import { useLoadingStore } from '@pages/Client/Account/Property/store/loading';
import { getEventRadiusKilometers } from '@pages/Client/Account/Property/utils/event-radius';
import { Button } from '@common/components/atoms/Button';
import { useViewStore } from '@common/store/view';

export function PropertyType() {
  const { t } = useTranslation();
  const {
    control,
    getValues,
    reset,
    formState: { errors }
  } = useFormContext();
  const { isLoading } = useLoadingStore();
  const { setCheckBookingLoading } = useBookingCheckLoadingStore();
  const { isValidUrl, suggestBookingUrl, isSuccessUrl, setValidBookingUrl } =
    useCheckBookingUrlStore();
  const { checkBookingUrl, isLoading: isCheckBookingLoading } = useCheckBookingUrl();
  const { view } = useViewStore();

  const { property_type, business_leisure_ratio, event_types, booking_ref_url, token } =
    getValues();

  const { mutate: handleFetchEvent, isPending: isFetchLoading } = useSaveEvent(token);

  const eventRadiusOptions = getEventRadiusKilometers().map(({ label, value }) => ({
    label: t(label),
    value
  }));

  const debounceCheckBookingUrl = debounce((value) => checkBookingUrl(value), 500);

  useEffect(() => {
    if (isCheckBookingLoading) {
      setCheckBookingLoading(true);
    } else {
      setCheckBookingLoading(false);
    }
  }, [isCheckBookingLoading]);

  const BUSINESS_LEISURE_RATIO = [
    {
      value: '0',
      label: t('All Leisure')
    },
    {
      value: '0.25',
      label: t('Mostly Leisure')
    },
    {
      value: '0.5',
      label: t('Mixed')
    },
    {
      value: '0.75',
      label: t('Mostly Business')
    },
    {
      value: '1',
      label: t('All Business')
    }
  ];

  const EVENT_TYPE = [
    {
      value: 1,
      label: t('Resort/Ski')
    },
    {
      value: 2,
      label: t('City Hotels')
    }
  ];

  const PROPERTY_TYPE = [
    {
      value: '0',
      label: t('City')
    },
    {
      value: 1,
      label: t('Countryside')
    },
    {
      value: 2,
      label: t('Ski')
    },
    {
      value: 3,
      label: t('Seaside')
    },
    {
      value: 4,
      label: t('Other')
    }
  ];

  return (
    <div>
      <div className="flex py-7 sm:flex-col md:flex-row lg:flex-row">
        <div className="flex flex-1 flex-col sm:w-full md:w-1/2 lg:w-1/2">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Property Type')} *
          </Typography>
        </div>
        <div className="flex-1 sm:w-full  md:w-1/2  lg:w-1/2">
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <>
              <Controller
                control={control}
                name="property_type"
                defaultValue={property_type}
                render={({ field: { value, name, onChange } }) => (
                  <SelectDropdown
                    fullWidth
                    name={name}
                    placeholder={`${t('Please Select Property Type')}`}
                    key={name}
                    value={value}
                    options={PROPERTY_TYPE}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              <div className="mt-1">
                {errors && (
                  <div className="flex items-center gap-2 text-error">
                    {errors.property_type ? <Icon.WarningOutline className="w-4" /> : ''}
                    <Typography element="span" color="error" variant="meta-2">
                      {errors.property_type &&
                        (errors.property_type as any).type === 'invalid_type' && (
                          <span>{t('Required')}</span>
                        )}
                    </Typography>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Divider />

      <div className="flex py-7 sm:flex-col md:flex-row lg:flex-row">
        <div className="flex flex-1 flex-col sm:w-full md:w-1/2 lg:w-1/2">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Business / Leisure Ratio')} *
          </Typography>
        </div>
        <div className="flex-1 sm:w-full  md:w-1/2  lg:w-1/2">
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <>
              <Controller
                control={control}
                name="business_leisure_ratio"
                defaultValue={business_leisure_ratio}
                render={({ field: { value, name, onChange } }) => (
                  <SelectDropdown
                    fullWidth
                    name={name}
                    placeholder={`${t('Please Select Business / Leisure Ratio')}`}
                    key={name}
                    value={value}
                    options={BUSINESS_LEISURE_RATIO}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              <div className="mt-1">
                {errors && (
                  <div className="flex items-center gap-2 text-error">
                    {errors.business_leisure_ratio ? <Icon.WarningOutline className="w-4" /> : ''}
                    <Typography element="span" color="error" variant="meta-2">
                      {errors.business_leisure_ratio &&
                        (errors.business_leisure_ratio as any).type === 'invalid_type' && (
                          <span>{t('Required')}</span>
                        )}
                    </Typography>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Divider />

      <div className="flex py-7 sm:flex-col md:flex-row lg:flex-row">
        <div className="flex flex-1 flex-col sm:w-full md:w-1/2 lg:w-1/2">
          <Typography element="p" variant="meta-1" color="copyTextGrey">
            {t('Event Type')} *
          </Typography>
        </div>
        <div className="flex-1 sm:w-full  md:w-1/2  lg:w-1/2">
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            <>
              <Controller
                control={control}
                name="event_types"
                defaultValue={event_types}
                render={({ field: { value, name, onChange } }) => (
                  <SelectDropdown
                    fullWidth
                    name={name}
                    placeholder={`${t('Please Select Event Type')}`}
                    key={name}
                    value={value}
                    options={EVENT_TYPE}
                    onChange={(value) => {
                      onChange(value);
                    }}
                  />
                )}
              />
              <div className="mt-1">
                {errors && (
                  <div className="flex items-center gap-2 text-error">
                    {errors.event_types ? <Icon.WarningOutline className="w-4" /> : ''}
                    <Typography element="span" color="error" variant="meta-2">
                      {errors.event_types ? errors?.event_types?.message?.toString() : ''}
                    </Typography>
                  </div>
                )}
              </div>
            </>
          )}

          {view === 'admin' ? (
            <Button
              disabled={!token}
              onClick={() => handleFetchEvent()}
              intent="outline"
              size="large"
              className="mt-8 w-full"
              isLoading={isFetchLoading}>
              {t('Fetch Latest Event Data')}
            </Button>
          ) : null}
        </div>
      </div>
      {view === 'admin' ? <Divider /> : null}
      {view === 'admin' ? (
        <>
          <div className="flex flex-col py-7 md:flex-row lg:flex-row">
            <div className="w-1/3 flex-1">
              <Typography element="p" variant="meta-1" color="copyTextGrey">
                {t('Booking URL')}
              </Typography>
            </div>
            <div className="w-full flex-1 md:w-1/2">
              {isLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <Controller
                  name="booking_ref_url"
                  control={control}
                  defaultValue={booking_ref_url}
                  render={({ field: { onChange, onBlur, value }, formState: { errors } }) => (
                    <Input
                      placeholder={`${t('Please Enter Booking Url')}`}
                      onBlur={onBlur}
                      showClearButton={false}
                      onChange={(e) => {
                        const { value } = e.target;
                        onChange(value);
                        if (!value) {
                          setValidBookingUrl(true);
                        }
                        if (isBookingUrl(value)) {
                          debounceCheckBookingUrl({
                            booking_ref_url: value,
                            is_booking_ref_url: true
                          });
                        }
                      }}
                      value={value}
                      trailingAddon={
                        isCheckBookingLoading ? <LoadingCircle className="ml-2" /> : null
                      }
                      type={'text'}
                      error={!isValidUrl || !!errors?.booking_ref_url}
                      onClear={() => {
                        reset({ booking_ref_url: '' });
                      }}
                      helperMessage={
                        (!isValidUrl && (
                          <div className="flex items-center gap-2 text-error">
                            <Icon.WarningOutline className="w-4" />
                            <Typography element="span" color="error" variant="meta-2">
                              {isSuccessUrl ? (
                                <>
                                  {t('Suggested: ')}
                                  {suggestBookingUrl}
                                </>
                              ) : (
                                <>{suggestBookingUrl}</>
                              )}
                            </Typography>
                          </div>
                        )) ||
                        (errors && errors.booking_ref_url && (
                          <div className="flex items-center gap-2 text-error">
                            <Icon.WarningOutline className="w-4" />
                            <Typography element="span" color="error" variant="meta-2">
                              {errors?.booking_ref_url?.message?.toString()}
                            </Typography>
                          </div>
                        ))
                      }
                    />
                  )}
                />
              )}
            </div>
          </div>
          <Divider />
          <div className="flex py-7 sm:flex-col md:flex-row lg:flex-row">
            <div className="flex flex-1 flex-col sm:w-full md:w-1/2 lg:w-1/2">
              <Typography element="p" variant="meta-1" color="copyTextGrey">
                {t('Event Radius in Kilometers')}
              </Typography>
            </div>
            <div className="flex-1 sm:w-full  md:w-1/2  lg:w-1/2">
              {isLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <>
                  <Controller
                    control={control}
                    name="event_radius"
                    render={({ field: { value, name, onChange } }) => (
                      <SelectDropdown
                        fullWidth
                        name={name}
                        placeholder={`${t('Please Select Event Radius')}`}
                        key={name}
                        value={value}
                        options={eventRadiusOptions}
                        onChange={(value) => {
                          onChange(value);
                        }}
                      />
                    )}
                  />
                  <div className="mt-1">
                    {errors && (
                      <div className="flex items-center gap-2 text-error">
                        {errors.event_radius ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {errors.event_radius ? errors?.event_radius?.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
