import { Typography } from '@common/components/foundations/Typography';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@common/components/molecules/Popoverv2/Popover';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { SegmentedControl } from '@mantine/core';

interface Props {
  label: string;
  previousYear: number;
  currentYear: number;
  nextYear: number;
  currentDate: Date | undefined;
  setDate: Dispatch<SetStateAction<Date>> | undefined;
  additionalInfo?: React.ReactNode;
  textElement?: 'h5' | 'h6' | 'paragraph-1';
}

export const QuickMonthNavigator: React.FC<Props> = ({
  label,
  previousYear,
  currentYear,
  nextYear,
  currentDate,
  setDate,
  additionalInfo,
  textElement = 'h5'
}) => {
  const { trackEvent } = useEventTracking();
  const [selectedYear, setSelectedYear] = useState(
    currentDate?.getFullYear() ?? new Date().getFullYear().toString()
  );
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(
    currentDate?.getMonth() ?? new Date().getMonth()
  );

  useEffect(() => {
    setSelectedYear(currentDate?.getFullYear() ?? new Date().getFullYear());
    setSelectedMonthIndex(currentDate?.getMonth() ?? new Date().getMonth());
  }, [currentDate]);

  useEffect(() => {
    if (selectedYear === undefined || selectedMonthIndex === undefined) return;
    setDate?.(new Date(Number(selectedYear), selectedMonthIndex));
  }, [selectedYear, selectedMonthIndex]);

  return (
    <Popover onOpenChange={(open) => (open ? trackEvent('QuickMonthNavigatorOpened') : undefined)}>
      <PopoverTrigger asChild>
        <div className="min-w-[80px] cursor-pointer md:min-w-[130px]">
          <Typography variant={textElement} className="font-medium" color="darkGrey">
            {dayjs(currentDate).format('MMM YYYY')}
          </Typography>
        </div>
      </PopoverTrigger>
      <PopoverContent className="min-w-[330px]">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <Typography variant="meta-2" className="text-meta-2-semibold" color="grey">
              {label}
            </Typography>
            <SegmentedControl
              onChange={(value) => setSelectedYear(value)}
              size="xs"
              value={selectedYear.toString()}
              data={[
                { label: previousYear.toString(), value: previousYear.toString() },
                { label: currentYear.toString(), value: currentYear.toString() },
                { label: nextYear.toString(), value: nextYear.toString() }
              ]}
            />
          </div>

          <div className="grid grid-cols-3 gap-1">
            {dayjs.months().map((month, index) => (
              <button
                key={month}
                type="button"
                className={`w-full rounded-md px-3 py-0.5 text-left hover:bg-grey hover:bg-opacity-40 ${
                  index === selectedMonthIndex ? 'bg-grey bg-opacity-40' : ''
                }`}
                onClick={() => {
                  setSelectedMonthIndex(index);
                }}>
                <Typography variant="meta-1" color="white">
                  {month}
                </Typography>
              </button>
            ))}
          </div>

          {additionalInfo}
        </div>
      </PopoverContent>
    </Popover>
  );
};
