import { PropsWithChildren } from 'react';
import {
  textColors,
  borderColors,
  Colors,
  getBrightness
} from '@common/components/foundations/colorVariants';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { cn } from '@common/utils/cn';

type BadgeVariant = 'contained' | 'outlined' | 'rounded';
type textTransform = 'uppercase' | 'capitalize' | 'lowercase';

interface BadgeProps extends PropsWithChildren {
  variant?: BadgeVariant;
  background?: Colors | string;
  className?: string;
  textTransform?: textTransform;
}

export function Badge({
  variant = 'contained',
  children,
  background = 'gold',
  textTransform = 'uppercase',
  className = ''
}: BadgeProps) {
  const baseClasses =
    'inline-flex select-none items-center justify-center rounded px-1.5 py-0.5 text-[10px] 2xl:text-badge font-medium truncate whitespace-nowrap max-w-fit min-w-fit';

  const decideTextColor = (bgColor: string) => {
    const color = bgColor.startsWith('#') ? bgColor : useTailwindColor(bgColor as Colors);
    // Define a default color for the brightness calculation
    const defaultColorForBrightness = '#FFFFFF';

    // Use color if it's defined, otherwise use the default color
    const brightness = getBrightness(color ? (color as Colors) : defaultColorForBrightness);
    return brightness < 0.5 ? 'white' : 'darkGrey';
  };

  const textColor = decideTextColor(background);

  const backgroundColorStyle = background.startsWith('#') ? { backgroundColor: background } : {};
  const borderColorStyle =
    background.startsWith('#') && variant === 'outlined' ? { borderColor: background } : {};

  const finalClassName = cn(baseClasses, className, textColors[textColor], {
    [`border ${borderColors}`]: variant === 'outlined' && !background.startsWith('#'),
    [`rounded-full py-1 px-2.5 text-meta-3 font-bold relative overflow-hidden ${textTransform}`]:
      variant === 'rounded',
    [`bg-${background}`]: variant !== 'outlined' && !background.startsWith('#'),
    [`border-${background} text-${background}`]:
      variant === 'outlined' && !background.startsWith('#')
  });

  return (
    <span className={finalClassName} style={{ ...backgroundColorStyle, ...borderColorStyle }}>
      {children}
    </span>
  );
}
