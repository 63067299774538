import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';

export const OccupancyTooltip = () => {
  const { t } = useTranslation();

  return (
    <Typography className="block max-w-sm p-3 text-meta-2 text-white">
      <b className="text-meta-2-medium text-mediumGrey">{t('Not enough bookings')} </b>
      <span className="inline-block h-3 w-3 rounded-full bg-orange bg-opacity-80 align-middle">
        {' '}
      </span>
      :{' '}
      {t(
        'Your bookings are coming in slower than we would expect given your Occupancy Targets and a lead time.'
      )}
      <br />
      <b className="text-meta-2-medium text-mediumGrey">{t('Too many bookings')} </b>
      <span className="inline-block h-3 w-3 rounded-full bg-indigo bg-opacity-80 align-middle"></span>
      :{' '}
      {t(
        'Your bookings are coming in faster than we would expect given your Occupancy Targets and a lead time.'
      )}
      <br />
      <br />
      <span className="text-meta-1-medium text-mediumGrey">{t('How do I use this?')}</span> <br />
      {t(
        'This page is simply a check on how you are doing against your Target Occupancy you have set. You can use it to get a basic overview of performance.'
      )}
      <br />
      <br />
      <span className="text-meta-1-medium text-mediumGrey">
        {t('What is RoomPriceGenie doing if these are all orange or all blue?')}
      </span>{' '}
      <br />
      {t(
        'From 9 months out we look at your whole month occupancy and see if it is on target. We adjust our demand forecast with this in mind. For example, if you were charging too much and the bookings are not coming in, we will adjust your prices downwards, in line with the realized demand.'
      )}
      <br />
      <br />
      <span className="text-meta-1-medium text-mediumGrey">{t('What should I do?')}</span> <br />
      {t(
        'If you are consistently seeing orange or blue prices, or if you are consistently seeing them for certain weekdays or certain seasons, we may need to adjust your original setup.  Please contact us if you would like help here.'
      )}
    </Typography>
  );
};
