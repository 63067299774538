import {
  Title,
  Text,
  List,
  ThemeIcon,
  Image,
  rem,
  SimpleGrid,
  Mark,
  Group,
  Divider,
  Space
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { IconCheck, IconSparkles } from '@tabler/icons-react';
import { Trans, useTranslation } from 'react-i18next';
import image from '@common/assets/images/RPG_illustrations-33.png';

export const MinStayNewVersionPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('New Version of Minimum Stay Restrictions'));

  return (
    <SimpleGrid
      cols={{
        base: 1,
        md: 2
      }}
      spacing="xl">
      <div>
        <Group gap="xs" wrap="nowrap">
          <IconSparkles color="var(--mantine-color-gold-5)" className="shrink-0" />
          <Title size="h3" component="h2">
            <Trans
              i18nKey={'Update Coming: <b>Enhanced Minimum Stay Restrictions</b>'}
              components={{
                b: <Mark fw={500} color="indigo.1" />
              }}
            />
          </Title>
        </Group>

        <Text mt="md">
          {t(
            'In the next few weeks, you will automatically receive an upgraded version of the Minimum Stay Restrictions settings. This update includes:'
          )}
        </Text>

        <List
          mt="xl"
          spacing="xs"
          icon={
            <ThemeIcon size="xs" color="indigo.1">
              <IconCheck style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
            </ThemeIcon>
          }>
          <List.Item>
            {t(
              'Fully customisable criteria for removing and correcting Minimum Stay Restrictions per room type'
            )}
          </List.Item>
          <List.Item>
            {t(
              'An intuitive interface that makes it easy to keep track of the Automation Criteria you have set'
            )}
          </List.Item>
          <List.Item>
            {t(
              'The option to correct impossible restrictions, or automatically remove all restrictions based on days to go or occupancy criteria'
            )}
          </List.Item>
        </List>

        <Space h="lg" />
        <Divider />

        <Text mt="md">
          <Trans
            i18nKey="<b>No action required</b> - we'll notify you when the update is live!"
            components={{
              b: <Mark fw={500} color="transparent" />
            }}
          />
        </Text>
      </div>

      <Image src={image} w="50%" h="auto" visibleFrom="md" />
    </SimpleGrid>
  );
};

export default MinStayNewVersionPage;
