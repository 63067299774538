import { DateRangeInput } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { useTranslation } from 'react-i18next';
import { useBulkEditsStore } from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { BulkEditUserflowIds } from '@common/types/userflow-ids';

export function EditDateRangeSelect() {
  const { t } = useTranslation();
  const { formState, setValue } = useFormContext();
  const { hotelDetails } = useHotelDetails();
  const { viewingDates } = useBulkEditsStore();

  useEffect(() => {
    if (!isEmpty(viewingDates) && formState?.defaultValues?.dateRange !== viewingDates) {
      setValue('dateRange', viewingDates);
    }
  }, [viewingDates]);

  return (
    <DateRangeInput
      name="dateRange"
      helperMessage={
        formState?.errors?.dateRange ? (
          <div className="ml-1 text-xs text-error">{t('Please select a valid date range')}</div>
        ) : null
      }
      ISOWeek={hotelDetails?.starts_monday}
      timezone={hotelDetails?.timezone}
      startDate={viewingDates?.from}
      endDate={viewingDates?.to}
      userflowId={BulkEditUserflowIds.EDIT_DATE_RANGE_SELECT}
    />
  );
}
