import dayjs from 'dayjs';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useBulkEditsStore } from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { useEventTracking } from '@common/hooks/useEventTracking';

export const useCalendarInteractions = () => {
  const { trackEvent } = useEventTracking();
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();
  const { setBulkEditDrawerState, setBulkEditViewingDates } = useBulkEditsStore();

  const onCellClick = (day: Date) => {
    setDrawerState(true);
    setViewingDate(day);
  };

  const onMultipleCellsSelect = (startDate: Date, endDate: Date) => {
    setBulkEditDrawerState(true);
    setBulkEditViewingDates({
      from: dayjs(startDate).startOf('d').toDate(),
      to: dayjs(endDate).endOf('d').toDate()
    });
    trackEvent('BulkEditDragAndDrop');
  };

  return { onCellClick, onMultipleCellsSelect };
};
