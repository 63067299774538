import { Feature, useFeaturesStore } from '@common/store/features';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import {
  ADJUSTMENT,
  AdjustmentOptionType
} from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { AdjustmentType } from '@pages/Client/Calendar/constants';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';

export const useBulkAdjustmentOptions = (): {
  adjustmentOptions: AdjustmentOptionType[];
  adjustmentOptionsWithMinStaySkipRule: AdjustmentOptionType[];
} => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { features, hasMinStayFeature } = useFeaturesStore();
  const { pricingSettings } = usePricingSettings();
  const { hasMinStayEnabled } = usePmsProvider();
  const { isOccupancyBased } = useGetDetailProviderConfig();

  const spaceName = hotelDetails?.room_apartment_space_name ?? 'Rooms';
  const isAllRoom = !!pricingSettings?.hotel.all_room_pricing;
  const pmsProvider = hotelDetails?.pms_provider;

  const adjustmentOptions: AdjustmentOptionType[] = [];
  let adjustmentOptionsWithMinStaySkipRule: AdjustmentOptionType[];

  const addAdjustmentOption = (id: ADJUSTMENT, type: AdjustmentType, label: string) => {
    adjustmentOptions.push({ id, value: type, label: t(label) });
  };

  if (features?.includes(Feature.BulkEdits)) {
    addAdjustmentOption(ADJUSTMENT.PERCENT, AdjustmentType.Percent, 'Percent Adjustments');
    addAdjustmentOption(ADJUSTMENT.MIN_MAX, AdjustmentType.MinMax, 'Minimum and Maximum Prices');
    addAdjustmentOption(ADJUSTMENT.FIX_PRICES, AdjustmentType.FixPrices, 'Fix Prices');

    if (isAllRoom) {
      addAdjustmentOption(
        ADJUSTMENT.DERIVATION,
        AdjustmentType.Derivation,
        `${spaceName} Derivation`
      );
    }

    if (!features?.includes(Feature.ClosedRoomDetection)) {
      addAdjustmentOption(ADJUSTMENT.CLOSED, AdjustmentType.Closed, `Closed ${spaceName}s`);
    }

    const isOccupancyPricingAllowed =
      (pmsProvider &&
        ![PmsProvider.VERTICAL_BOOKING, PmsProvider.SEMPER, PmsProvider.SABEE_APP].includes(
          pmsProvider
        )) ||
      isOccupancyBased;

    if (isOccupancyPricingAllowed) {
      addAdjustmentOption(ADJUSTMENT.OCCUPANCY, AdjustmentType.Occupancy, 'Occupancy Pricing');
    }
  } else if (!features?.includes(Feature.ClosedRoomDetection)) {
    addAdjustmentOption(ADJUSTMENT.CLOSED, AdjustmentType.Closed, `Closed ${spaceName}s`);
  }

  if (features?.includes(Feature.ProBulkEdits)) {
    addAdjustmentOption(ADJUSTMENT.AGGRESSIVENESS, AdjustmentType.Aggressiveness, 'Aggressiveness');
    addAdjustmentOption(ADJUSTMENT.MEDIAN, AdjustmentType.Median, 'Median Booking Window');
  }

  if (hasMinStayEnabled() && hasMinStayFeature()) {
    addAdjustmentOption(ADJUSTMENT.MIN_STAY, AdjustmentType.MinStay, 'Minimum Stay Restrictions');

    adjustmentOptionsWithMinStaySkipRule = [
      ...adjustmentOptions,
      {
        id: ADJUSTMENT.MIN_STAY_SKIP_RULE,
        value: AdjustmentType.MinStaySkipRule,
        label: 'Ignore Correction'
      }
    ];
  } else {
    adjustmentOptionsWithMinStaySkipRule = [...adjustmentOptions];
  }

  return { adjustmentOptions, adjustmentOptionsWithMinStaySkipRule };
};
