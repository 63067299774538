import { ErrorResponse } from '@common/api/auth';
import { getDashboardErrorTicketList } from '@common/api/errors';
import { ParamsDashboardErrorTickets, DashboardErrorTicketList } from '@common/api/errors/types';
import { useNotificationsStore } from '@common/store/notifications';
import { DashboardErrorTicketsQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

export const useDashboardErrorTickets = ({
  page = 1,
  developer,
  search,
  tags,
  tags_type = 'dashboard',
  ticket_status = 1
}: ParamsDashboardErrorTickets) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery<DashboardErrorTicketList, ErrorResponse>({
    queryKey: [
      DashboardErrorTicketsQueryKeys.DASHBOARD_ERROR_TICKET_LIST,
      page,
      developer,
      search,
      tags,
      tags_type,
      ticket_status
    ],
    queryFn: () =>
      getDashboardErrorTicketList({
        developer,
        search,
        page,
        tags,
        tags_type,
        ticket_status
      })
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to fetch dashboard error ticket list');
    }
  }, [query.isError]);

  const dashboardErrorTicket = useMemo(() => {
    return query.data;
  }, [query.data]);

  return { dashboardErrorTicket, query };
};
