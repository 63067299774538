export const Hidden = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37856 3.14957C4.28788 3.05289 4.16128 2.99805 4.02877 2.99805C3.89627 2.99805 3.76967 3.05289 3.67899 3.14957L3.14931 3.67957C3.05269 3.7703 2.99788 3.89698 2.99788 4.02957C2.99788 4.16215 3.05269 4.28883 3.14931 4.37957L5.74773 6.99957C4.05625 8.13167 2.76555 9.76965 2.05997 11.6796C2.02265 11.7822 2.00239 11.8903 2.00001 11.9996C1.99948 12.1058 2.01986 12.2112 2.05997 12.3096C3.68479 16.3687 7.62406 19.0215 11.9939 18.9996C13.6108 18.9841 15.2044 18.6119 16.6611 17.9096L19.6093 20.8496C19.7 20.9462 19.8266 21.0011 19.9591 21.0011C20.0916 21.0011 20.2182 20.9462 20.3089 20.8496L20.8385 20.3196C20.9352 20.2288 20.99 20.1022 20.99 19.9696C20.99 19.837 20.9352 19.7103 20.8385 19.6196L4.37856 3.14957ZM11.9939 14.9996C10.3381 14.9996 8.99575 13.6564 8.99575 11.9996C8.99827 11.5091 9.12552 11.0273 9.36552 10.5996L13.3631 14.5996C12.9473 14.8453 12.4766 14.9829 11.9939 14.9996ZM9.11567 5.40957C14.3846 3.88099 19.9529 6.60194 21.9878 11.6996C22.0041 11.7989 22.0041 11.9002 21.9878 11.9996C21.9911 12.1125 21.9706 12.225 21.9279 12.3296C21.4348 13.6821 20.6382 14.9035 19.5993 15.8996L9.11567 5.40957Z"
      />
    </svg>
  );
};
