export function TurnOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.4994V13.4875C11 13.7633 11.2238 13.9869 11.5 13.9869H12.5C12.7761 13.9869 13 13.7633 13 13.4875V3.4994C13 3.22359 12.7761 3 12.5 3H11.5C11.2238 3 11 3.22359 11 3.4994ZM17.64 7.55456C17.7775 7.56228 17.9066 7.62318 18 7.72436C20.8364 10.9368 20.6324 15.8117 17.5374 18.7764C14.4425 21.7412 9.55748 21.7412 6.46251 18.7764C3.36754 15.8117 3.16352 10.9368 5.99998 7.72436C6.09575 7.62081 6.22893 7.55969 6.36998 7.55456C6.50857 7.5522 6.64215 7.60629 6.73998 7.70438L7.44998 8.41353C7.62314 8.6069 7.62314 8.89936 7.44998 9.09272C6.51067 10.1732 5.99549 11.5572 5.99998 12.9881C5.99998 16.2978 8.68627 18.9809 12 18.9809C15.3137 18.9809 18 16.2978 18 12.9881C18.0072 11.5584 17.4957 10.1746 16.56 9.09272C16.3868 8.89936 16.3868 8.6069 16.56 8.41353L17.27 7.70438C17.3678 7.60629 17.5014 7.5522 17.64 7.55456Z"
      />
    </svg>
  );
}
