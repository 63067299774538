import React from 'react';
import { Feature } from '@common/store/features';
import { List } from '@mantine/core';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { mapValues, keyBy, values } from 'lodash-es';

export type FeatureType = {
  id: number;
  name: string;
  tooltip?: string | React.ReactNode;
  flagged?: boolean;
  flaggedLabel?: string;
};

type NestedFeatureGroups = {
  [key: string]: FeatureType[];
};

type FeatureGroups = {
  [key: string]: NestedFeatureGroups;
  general: NestedFeatureGroups;
  starter: NestedFeatureGroups;
  advanced: NestedFeatureGroups;
  professional: NestedFeatureGroups;
};

export const useFeatureDescription = (isEnabled?: boolean) => {
  const { hotelDetails } = useHotelDetails(isEnabled);

  const allFeatures: { [key in keyof typeof Feature]: FeatureType } = {
    DisableClientAccess: { id: Feature.DisableClientAccess, name: 'Disable Client Access' },
    PartnerSubscriptionPage: {
      id: Feature.PartnerSubscriptionPage,
      name: 'Partner Subscription Page'
    },
    SmallBusiness: { id: Feature.SmallBusiness, name: 'Small Business Subscription Page' },
    DashboardOnly: { id: Feature.DashboardOnly, name: 'Reporting Only' },
    StarterUpload: { id: Feature.StarterUpload, name: '1 Upload per Day' },
    PickupBoostPrice: { id: Feature.PickupBoostPrice, name: 'Pricing - Pickup Model' },
    AirBNB: { id: Feature.AirBNB, name: 'Pricing - Vacation Rental Model' },
    ClosedRoomDetection: {
      id: Feature.ClosedRoomDetection,
      name: 'Closed Rooms Detection',
      tooltip: (
        <List size="xs">
          <List.Item c="white">Activated by default</List.Item>
          <List.Item c="white">Not yet available for all integrations</List.Item>
        </List>
      )
    },
    BarLevels: { id: Feature.BarLevels, name: 'BAR Levels' },
    SettingsCorrection: {
      id: Feature.SettingsCorrection,
      name: 'Monthly Actual vs. Expected Occupancy Adjustment',
      tooltip: (
        <List size="xs">
          <List.Item c="white">{`Former "Settings Correction"`}</List.Item>
          <List.Item c="white">
            Moves the prices of the whole month up or down based on the comparison of actual vs
            expected occupancy by today. Days that follow an opposite trend, for example, Christmas
            in low season December, will be excluded.
          </List.Item>
        </List>
      )
    },
    AdvancedUpload: {
      id: Feature.AdvancedUpload,
      name: '4 Uploads per Day (Default)',
      tooltip: (
        <List size="xs" listStyleType="none">
          <List.Item c="white">
            Other Advanced Features:
            <List size="xs">
              <List.Item c="white">Week View</List.Item>
            </List>
          </List.Item>
        </List>
      )
    },
    BulkEdits: { id: Feature.BulkEdits, name: 'Bulk Edits' },
    IndividualMinMax: {
      id: Feature.IndividualMinMax,
      name: `Min/Max Per ${hotelDetails?.room_apartment_space_name} Type`
    },
    LeadTimeAdjustment: { id: Feature.LeadTimeAdjustment, name: 'Lead Time Adjustment' },
    OccupancyStrategy: {
      id: Feature.OccupancyStrategy,
      name: 'Occupancy Strategy - Access to Aggressiveness'
    },
    MonthlyGroupBookings: {
      id: Feature.MonthlyGroupBookings,
      name: 'Group Bookings - Set Manually',
      tooltip: 'Can only be activated if "Group Bookings - Segmentation" is deactivated'
    },
    SurgeProtection: {
      id: Feature.SurgeProtection,
      name: 'Surge Protection - Email Only',
      tooltip: (
        <List size="xs">
          <List.Item c="white">Can be only activated if property has &gt;3 rooms</List.Item>
          <List.Item c="white">
            Depending on the integration, surges are triggered in real time (Protel, Guestline,
            RoomRaccoon & Little Hotelier), every 10 minutes (other premium integrations) or with
            every pricing run depending on the plan (no premium integration)
          </List.Item>
        </List>
      )
    },
    LimitedDashboard: {
      id: Feature.LimitedDashboard,
      name: 'Limited Performance Reporting',
      tooltip: (
        <List size="xs">
          <List.Item c="white">Shows only current month +/-1</List.Item>
          <List.Item c="white">No analytics tab</List.Item>
          <List.Item c="white">No budget & forecasting</List.Item>
          <List.Item c="white">No cancelled pickup and no 90 days back</List.Item>
        </List>
      )
    },
    LimitedMarketInsights: {
      id: Feature.LimitedMarketInsights,
      name: 'Vacation Rental Benchmarking',
      tooltip: (
        <List size="xs">
          <List.Item c="white">No premium integration needed</List.Item>
          <List.Item c="white">Shows only current month +/-1</List.Item>
          <List.Item c="white">Pickup data based on availability data</List.Item>
          <List.Item c="white">No pickup 90 days back</List.Item>
          <List.Item c="white">No filters</List.Item>
        </List>
      )
    },
    PickupCalendar: {
      id: Feature.PickupCalendar,
      name: 'Limited Pickup Calendar',
      tooltip: (
        <List size="xs">
          <List.Item c="white">No premium integration needed</List.Item>
          <List.Item c="white">Pickup data based on availability data</List.Item>
        </List>
      )
    },
    UploadAllRuns: {
      id: Feature.UploadAllRuns,
      name: 'Hourly Uploads'
    },
    EighteenMonthsPricing: { id: Feature.EighteenMonthsPricing, name: '18-Months Pricing' },
    ProBulkEdits: {
      id: Feature.ProBulkEdits,
      name: 'Bulk Edits - Aggressiveness & Booking Window'
    },
    YieldTag: { id: Feature.YieldTag, name: 'Yielding Tags' },
    LimitedMinimumStayRestrictions: {
      id: Feature.LimitedMinimumStayRestrictions,
      name: 'Min Stay Restrictions - Edit & Orphan Gap Correction',
      tooltip: (
        <List size="xs">
          <List.Item c="white">(Bulk-) Edit restrictions in the calendar</List.Item>
          <List.Item c="white">Activate Orphan Gap Corrections for selected room types</List.Item>
        </List>
      )
    },
    MinimumStayRestrictions: {
      id: Feature.MinimumStayRestrictions,
      name: 'Min Stay Restrictions - Automation',
      tooltip: (
        <List size="xs">
          <List.Item c="white">
            Define criteria to remove Min Stay restrictions based on Days to Go or Occupancy
          </List.Item>
          <List.Item c="white">Coming Soon: Automation to set Min Stay Restrictions</List.Item>
          <List.Item c="white">Coming Soon: Min Stay Recommendations</List.Item>
        </List>
      )
    },
    Segmentation: {
      id: Feature.Segmentation,
      name: 'Group Bookings - Segmentation',
      tooltip: 'Can only be activated if "Group Bookings - Set Manually" is deactivated'
    },
    SurgeProtectionPro: {
      id: Feature.SurgeProtectionPro,
      name: 'Surge Protection - Set Prices & Decay',
      tooltip: (
        <List size="xs">
          <List.Item c="white">Group Reservations</List.Item>
          <List.Item c="white">Set Prices</List.Item>
          <List.Item c="white">Protection Price Decay</List.Item>
        </List>
      )
    },
    ProfessionalDashboard: {
      id: Feature.ProfessionalDashboard,
      name: 'Business Insights - Performance & Analytics Reporting',
      tooltip: (
        <List size="xs">
          <List.Item c="white">All performance reporting functionalities</List.Item>
          <List.Item c="white">All analytics reporting functionalities</List.Item>
        </List>
      )
    },
    ProMarketInsights: {
      id: Feature.ProMarketInsights,
      name: 'Market Insights - Vacation Rental Benchmarking',
      tooltip: (
        <List size="xs">
          <List.Item c="white">Only available for premium integrations</List.Item>
          <List.Item c="white">Pickup data based on reservations data</List.Item>
          <List.Item c="white">Filters available</List.Item>
        </List>
      )
    },
    PriceEvolution: { id: Feature.PriceEvolution, name: 'Pricing Insights - Pricing Evolution' },
    ReservationPipelineV2: { id: Feature.ReservationPipelineV2, name: 'Reservation Pipeline V2' },
    NoMarketData: { id: Feature.NoMarketData, name: 'No Market Data' }
  };

  const featuresByPlan: FeatureGroups = {
    general: {
      generalSettings: [
        allFeatures.DisableClientAccess,
        allFeatures.PartnerSubscriptionPage,
        allFeatures.SmallBusiness
      ],
      leadGeneration: [
        allFeatures.DashboardOnly
        // { id: TBD, name: 'Market Intelligence Only' }
      ]
    },
    starter: {
      pricingFeatures: [
        allFeatures.StarterUpload,
        allFeatures.PickupBoostPrice,
        allFeatures.AirBNB,
        allFeatures.ClosedRoomDetection,
        allFeatures.BarLevels,
        allFeatures.SettingsCorrection,
        allFeatures.NoMarketData
      ],
      dataInsights: [allFeatures.LimitedMarketInsights]
    },
    advanced: {
      pricingFeatures: [
        allFeatures.AdvancedUpload,
        allFeatures.BulkEdits,
        allFeatures.IndividualMinMax,
        allFeatures.LeadTimeAdjustment,
        allFeatures.LimitedMinimumStayRestrictions,
        allFeatures.OccupancyStrategy,
        allFeatures.MonthlyGroupBookings,
        allFeatures.SurgeProtection
      ],
      dataInsights: [allFeatures.LimitedDashboard, allFeatures.PickupCalendar]
    },
    professional: {
      pricingFeatures: [
        allFeatures.UploadAllRuns,
        allFeatures.EighteenMonthsPricing,
        allFeatures.ProBulkEdits,
        allFeatures.YieldTag,
        allFeatures.MinimumStayRestrictions,
        allFeatures.Segmentation,
        allFeatures.SurgeProtectionPro
      ],
      dataInsights: [
        allFeatures.ProfessionalDashboard,
        // { id: TBD, name: 'Business Insights - Pickup Calendar' },
        allFeatures.ProMarketInsights,
        // { id: TBD, name: 'Market Insights - RoomPriceGenie Benchmarking' },
        allFeatures.PriceEvolution
        // { id: TBD, name: 'Pricing Insights - Market Demand Chart' }
      ]
      // service: [{ id: TBD, name: 'RM Support Priority Inbox & Meeting Calendar' }]
    }
  };

  const featureDescriptions = mapValues(keyBy(values(allFeatures), 'id'), 'name');

  return {
    allFeatures,
    featuresByPlan,
    featureDescriptions
  };
};
