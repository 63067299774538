import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Cell,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
  SortingState,
  Updater
} from '@tanstack/react-table';
import { getSortedRowModel, RowData, VisibilityState } from '@tanstack/table-core';
import { Typography } from '@common/components/foundations/Typography';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { useTranslation } from 'react-i18next';
import { InputLabel, NativeSelect } from '@mantine/core';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    showOnMobile?: boolean;
    cellAlignment?: 'left' | 'center' | 'right';
  }
}

const alignment = {
  left: 'justify-start text-left',
  center: 'justify-center text-center',
  right: 'justify-end text-right'
};

interface TableProps {
  data: any[];
  columns: ColumnDef<any>[];
  isHover: boolean;
  isStriped?: boolean;
  isFetching?: boolean;
  skeletonCount?: number;
  rowsPerPage?: number;
  headerComponent?: React.ReactNode;
  actionComponents?: React.ReactNode;
  search?: (search: string) => void;
  onClickRow?: (cell: Cell<any, unknown>, row: Row<any>) => void;
  searchLabel?: string;
  count?: string;
  totalCount?: number;
  next?: () => void;
  previous?: () => void;
  manualSorting?: boolean;
  manualPagination?: boolean;
  onSortingChange?: (updater: Updater<SortingState>) => void;
  isRowClickable?: boolean;
  isPrevious?: boolean;
  isNext?: boolean;
  sorting?: { id: string; desc: boolean }[];
  isScrollable?: boolean;
  scrollClassName?: string;
}

export function Table({
  data,
  columns,
  isFetching,
  skeletonCount,
  rowsPerPage: rowsPerPageFromProp,
  headerComponent,
  onClickRow,
  isHover,
  isStriped,
  actionComponents,
  count,
  totalCount,
  next,
  previous,
  manualSorting,
  manualPagination,
  onSortingChange,
  isRowClickable,
  isPrevious,
  isNext,
  sorting: sortingFromProp,
  isScrollable,
  scrollClassName
}: TableProps) {
  const { t } = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageFromProp || 10);
  const [sorting, setSorting] = useState<{ id: string; desc: boolean }[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [displayData, setDisplayData] = useState(data);

  useEffect(() => {
    if (!isFetching) {
      setDisplayData(data);
    }
  }, [data, isFetching]);

  const handleSortingChange = (updater: Updater<SortingState>) => {
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;

    if (isEmpty(newSorting)) {
      setSorting([]);
      return;
    }

    const columnIndex = sorting.findIndex((s) => s.id === newSorting[0].id);

    if (columnIndex >= 0) {
      newSorting[0].desc = !sorting[columnIndex].desc;
    }

    if (manualSorting && onSortingChange) {
      onSortingChange(newSorting);
    } else {
      setSorting(newSorting);
    }
  };

  const memoizedData = useMemo(() => displayData, [displayData]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoisedHeaderComponent = useMemo(() => headerComponent, [headerComponent]);
  const memoisedActionComponents = useMemo(() => actionComponents, [actionComponents]);
  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting,
    onSortingChange: handleSortingChange,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination,
    state: {
      sorting: sortingFromProp !== undefined ? sortingFromProp : sorting,
      rowSelection,
      columnVisibility
    }
  });

  const skeletons = Array(skeletonCount || 0)
    .fill(null)
    .map((_, i) => i);

  const noDataFound = !isFetching ? !memoizedData || memoizedData?.length === 0 : false;

  const endIndex = Math.min(startIndex + rowsPerPage, getRowModel()?.rows?.length ?? 0); // added Math.min to ensure endIndex does not exceed the number of rows

  const slicedRows = getRowModel().rows.slice(startIndex, endIndex);
  const totalPages = Math.ceil((getRowModel()?.rows?.length ?? 0) / rowsPerPage);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.floor(startIndex / newRowsPerPage) + 1; // calculate new page based on new rowsPerPage
    const newStartIndex = (newPage - 1) * newRowsPerPage; // calculate new startIndex based on new page and rowsPerPage
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    setStartIndex(newStartIndex); // update startIndex with new page and rowsPerPage
  };

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) {
      return;
    }

    const newStartIndex = (newPage - 1) * rowsPerPage;
    setPage(newPage);
    setStartIndex(newStartIndex);
  };

  const isShowOnMobile = slicedRows.some((row) =>
    row.getAllCells().some((cell) => cell.column.columnDef.meta?.showOnMobile)
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="rounded-lg border border-grey-reduced border-opacity-40 bg-white">
        <div className="flex items-center gap-x-4 border-b border-[rgb(212,212,212)] border-opacity-50 py-3 pl-3 pr-3 md:pl-3 md:pr-6">
          {memoisedHeaderComponent ? (
            <div className="flex flex-1">{memoisedHeaderComponent}</div>
          ) : (
            <div className="flex flex-1">{<Fragment />}</div>
          )}
          {memoisedActionComponents ? (
            <div className="flex items-center justify-end overflow-auto">
              <ScrollArea>
                <div className="p-1">{memoisedActionComponents}</div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </div>
          ) : null}
        </div>
        <div
          className={clsx(
            isShowOnMobile ? 'overflow-x-visible md:overflow-x-auto' : 'overflow-x-auto',
            'relative rounded-b-lg'
          )}>
          {isFetching ? <div className="absolute h-1 w-full animate-pulse bg-indigo" /> : null}
          <ScrollArea
            className={clsx(
              isShowOnMobile ? 'overflow-x-visible md:overflow-x-auto' : 'overflow-x-auto'
            )}>
            <div
              className={clsx(
                isScrollable ? 'max-h-[500px] w-full overflow-y-auto' : null,
                scrollClassName ? scrollClassName : null
              )}>
              <table className="w-full">
                <thead
                  className={clsx(
                    // bg-[#EEE] is equivalent to opacity 40 medium grey
                    // to cover sticky header need quickly
                    isScrollable ? 'sticky top-0 bg-[#EEE] shadow-sm' : null,
                    !isStriped && !isScrollable ? 'bg-opacity-40' : null,
                    !isStriped && !isScrollable ? 'bg-mediumGrey shadow-sm' : null,
                    isShowOnMobile ? 'hidden md:table-header-group' : 'table-header-group'
                  )}>
                  {getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          style={{
                            width: header.getSize() !== 150 ? header.getSize() : undefined
                          }}
                          scope="col"
                          key={header.id}
                          className="text-meta-2-medium text-darkGrey">
                          {header.column.getCanSort() ? (
                            <div
                              className={clsx(
                                'py-2',
                                header.column.columnDef.meta?.cellAlignment
                                  ? alignment[header.column.columnDef.meta?.cellAlignment]
                                  : 'text-left'
                              )}>
                              <Button
                                intent="text"
                                onClick={header.column.getToggleSortingHandler()}
                                className={clsx(
                                  'rounded px-3 text-meta-2-medium text-darkGrey',
                                  isStriped ? 'hover:bg-lightGrey' : 'hover:bg-grey-reduced'
                                )}>
                                <div className="flex items-center gap-x-0.5 whitespace-normal rounded-sm text-left">
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}

                                  <div>
                                    <Icon.ChevronDown
                                      className={clsx(
                                        'ml-1 h-4 w-4',
                                        (manualSorting ? sortingFromProp : sorting)?.find(
                                          (s) => s.id === header.column.id
                                        )?.desc
                                          ? 'rotate-180 transform'
                                          : ''
                                      )}
                                    />
                                  </div>
                                </div>
                              </Button>
                            </div>
                          ) : (
                            <div className="px-3">
                              <div
                                className={clsx(
                                  'flex items-center gap-x-2 whitespace-normal rounded-sm',
                                  header.column.columnDef.meta?.cellAlignment
                                    ? alignment[header.column.columnDef.meta?.cellAlignment]
                                    : 'text-left'
                                )}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </div>
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody className={clsx(isStriped ? null : 'divide-y divide-lightGrey')}>
                  {!isFetching || (isFetching && skeletons.length === 0)
                    ? slicedRows.map((row) => (
                        <tr
                          role="row"
                          key={row.id}
                          className={clsx(
                            isShowOnMobile ? 'block md:table-row' : 'table-row',
                            'py-2',
                            isHover ? 'lg:hover:bg-indigo lg:hover:bg-opacity-[0.06]' : null,
                            isStriped
                              ? `odd:bg-indigo odd:bg-opacity-[0.06] ${
                                  isHover ? `lg:hover:bg-opacity-[0.10]` : null
                                }`
                              : null
                          )}>
                          {row.getVisibleCells().map((cell) => (
                            <td
                              style={{
                                width:
                                  cell.column.getSize() !== 150 ? cell.column.getSize() : undefined
                              }}
                              onClick={() => onClickRow?.(cell, row)}
                              key={cell.id}
                              className={clsx(
                                isRowClickable ? 'cursor-pointer' : null,
                                isShowOnMobile ? 'hidden md:table-cell' : 'table-cell',
                                'px-3 py-4 text-[12px] text-copyTextGrey xl:text-table-cell'
                              )}>
                              <div
                                className={clsx(
                                  'flex items-center gap-x-2',
                                  cell.column.columnDef.meta?.cellAlignment
                                    ? alignment[cell.column.columnDef.meta?.cellAlignment]
                                    : 'text-left'
                                )}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </div>
                            </td>
                          ))}
                          {isShowOnMobile
                            ? row.getAllCells().map((cell) =>
                                cell.column.columnDef.meta?.showOnMobile ? (
                                  <td
                                    style={{ width: cell.column.getSize() }}
                                    key={`dl-${cell.id}`}
                                    onClick={() => onClickRow?.(cell, row)}
                                    className="mt-2 flex gap-x-3 whitespace-nowrap px-3 text-[13px] text-copyTextGrey md:hidden xl:text-table-cell">
                                    <div className="flex w-1/3">
                                      <Typography className="truncate text-meta-2-medium">
                                        {cell.column.columnDef.header?.toString()}
                                      </Typography>
                                    </div>
                                    <div className="flex w-1/2">
                                      <Typography variant="meta-2" className="truncate">
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                      </Typography>
                                    </div>
                                  </td>
                                ) : null
                              )
                            : null}
                        </tr>
                      ))
                    : skeletons.map((skeleton) => (
                        <tr key={skeleton}>
                          {getAllColumns().map((column) => (
                            <td
                              key={column.id}
                              className={clsx('px-3 py-4 first:table-cell md:table-cell')}>
                              <div className="h-3 animate-pulse rounded-full bg-grey-reduced" />
                            </td>
                          ))}
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>

            {noDataFound ? (
              <div className="flex h-32 items-center justify-center">
                <Typography variant="meta-2" color="darkGrey">
                  {t('No data found')}
                </Typography>
              </div>
            ) : null}
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      </div>
      {manualPagination ? (
        <div className="flex items-center justify-end gap-x-3">
          <div className="flex select-none items-center gap-x-2 pr-10">
            <Button
              disabled={!isPrevious}
              tabIndex={3}
              icon
              onClick={() => (previous ? previous() : null)}>
              <Icon.ArrowLongLeft className="h-4 w-4 stroke-darkGrey" />
            </Button>
            {isFetching ? (
              <Typography className="text-meta-2-semibold" color="darkGrey">
                {t('Loading...')}
              </Typography>
            ) : (
              <div className="flex gap-x-1">
                <Typography className="text-meta-2-semibold" color="darkGrey">
                  {count}
                </Typography>
                <Typography className="text-meta-2-medium" color="copyTextGrey">
                  {t('of')}
                </Typography>
                <Typography className="text-meta-2-semibold" color="darkGrey">
                  {totalCount}
                </Typography>
              </div>
            )}
            <Button disabled={!isNext} tabIndex={4} icon onClick={() => (next ? next() : null)}>
              <Icon.ArrowLongRight className="h-4 w-4 stroke-darkGrey" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-end gap-x-3">
          <div className="flex items-center gap-x-2">
            <InputLabel htmlFor="rowsPerPage">{t('Rows per page')}</InputLabel>
            <NativeSelect
              tabIndex={2}
              title={t('Rows per page') as string}
              id="rowsPerPage"
              name="rowsPerPage"
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}>
              {[5, 10, 20, 50, 100].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </NativeSelect>
          </div>
          <div className="flex select-none items-center gap-x-2 pr-10">
            <Button tabIndex={3} icon>
              <Icon.ArrowLongLeft
                className="h-4 w-4 stroke-darkGrey"
                onClick={() => handlePageChange(page - 1)}
              />
            </Button>
            <div className="flex gap-x-1">
              <Typography className="text-meta-2-semibold" color="darkGrey">
                {`${(page - 1) * rowsPerPage + 1}-${Math.min(page * rowsPerPage, data?.length)}`}
              </Typography>
              <Typography className="text-meta-2-medium" color="copyTextGrey">
                {t('of')}
              </Typography>
              <Typography className="text-meta-2-semibold" color="darkGrey">
                {data?.length}
              </Typography>
            </div>
            <Button tabIndex={4} icon>
              <Icon.ArrowLongRight
                className="h-4 w-4 stroke-darkGrey"
                onClick={() => handlePageChange(page + 1)}
              />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
