import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PricingQueryKeys, RoomsQueryKeys } from '@common/types/query-keys';
import { StepComponentProps } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/AdminSetupRoomsCard';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useNotificationsStore } from '@common/store/notifications';
import { Button } from '@common/components/atoms/Button';
import { updateHotelCalculateNoOfRooms } from '@common/api/hotel';

export const SetNumberOfRoomsSetupStep = ({ index }: StepComponentProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isApartment } = useRoomSetupStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const { addNotification } = useNotificationsStore();
  const apartmentsOrRooms = isApartment ? 'Apartments' : 'Rooms';

  const {
    query: { isLoading: isLoadingQuery }
  } = useHotelRoomsList();

  const { mutateAsync, isPending: isLoadingMutation } = useMutation({
    mutationKey: [RoomsQueryKeys.UPDATE_HOTEL_CALCULATE_NO_OF_ROOMS],
    mutationFn: updateHotelCalculateNoOfRooms,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
      await queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.GET_HOTEL_ROOMS_LIST] });
      setIsSuccess(true);
      addNotification('success', t(`Number of ${apartmentsOrRooms} set!`));
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000);
    },
    onError: () => {
      addNotification('fail', t(`Error setting number of ${apartmentsOrRooms}`));
    }
  });

  const isLoading = isLoadingQuery || isLoadingMutation;
  return (
    <div className="flex items-center justify-start align-middle">
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        isSuccess={isSuccess}
        type="button"
        onClick={() => mutateAsync(true)}
        intent="outline">
        <span>{index}.</span>
        {t(`Set Number of ${apartmentsOrRooms}`)}
      </Button>
    </div>
  );
};
