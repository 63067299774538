import { create } from 'zustand';

interface UpdateMultipleTicketStore {
  IsUpdateMultipleTicketSuccess: boolean;
  setUpdateMultipleTicketSuccess: (success: boolean) => void;
}

export const useUpdateMultipleTicketStore = create<UpdateMultipleTicketStore>((set) => ({
  IsUpdateMultipleTicketSuccess: false,
  setUpdateMultipleTicketSuccess: (success: boolean) =>
    set({ IsUpdateMultipleTicketSuccess: success })
}));
