import tailwindConfig from '@/tailwindConfig';

export type Colors =
  | 'indigo'
  | 'darkGreen'
  | 'uiGreen'
  | 'uiRed'
  | 'orange'
  | 'gold'
  | 'error'
  | 'beigeLight'
  | 'darkGrey'
  | 'grey'
  | 'mediumGrey'
  | 'lightGrey'
  | 'copyTextGrey'
  | 'white';
export type TypographyColors =
  | 'indigo'
  | 'darkGrey'
  | 'white'
  | 'copyTextGrey'
  | 'uiGreen'
  | 'uiRed'
  | 'error'
  | 'lightGrey'
  | 'grey';

export const colors = tailwindConfig.theme.colors;

export type ColorProperties<T extends Colors> = {
  [key in T]: {
    [_P in keyof (typeof colors)[key]]: string;
  } & Record<'DEFAULT', string>;
};
export type StrictPropertyKeys<T extends Colors> = keyof ColorProperties<T>[T];

type TextColor = `text-${Colors}`;
type BackgroundColor = `bg-${Colors}`;
type BorderColor = `border-${Colors}`;
type FillColor = `fill-${Colors}`;
type StrokeColor = `stroke-${Colors}`;

export const backgroundColors: {
  [_K in Colors]: BackgroundColor;
} = {
  indigo: 'bg-indigo',
  darkGreen: 'bg-darkGreen',
  uiGreen: 'bg-uiGreen',
  uiRed: 'bg-uiRed',
  orange: 'bg-orange',
  gold: 'bg-gold',
  error: 'bg-error',
  beigeLight: 'bg-beigeLight',
  darkGrey: 'bg-darkGrey',
  grey: 'bg-grey',
  mediumGrey: 'bg-mediumGrey',
  lightGrey: 'bg-lightGrey',
  copyTextGrey: 'bg-copyTextGrey',
  white: 'bg-white'
};

export const textColors: { [_K in TypographyColors | Colors]: TextColor } = {
  indigo: 'text-indigo',
  darkGreen: 'text-darkGreen',
  uiGreen: 'text-uiGreen',
  uiRed: 'text-uiRed',
  orange: 'text-orange',
  gold: 'text-gold',
  error: 'text-error',
  beigeLight: 'text-beigeLight',
  darkGrey: 'text-darkGrey',
  grey: 'text-grey',
  mediumGrey: 'text-mediumGrey',
  lightGrey: 'text-lightGrey',
  copyTextGrey: 'text-copyTextGrey',
  white: 'text-white'
};

export const borderColors: { [_K in Colors]: BorderColor } = {
  indigo: 'border-indigo',
  darkGreen: 'border-darkGreen',
  uiGreen: 'border-uiGreen',
  uiRed: 'border-uiRed',
  orange: 'border-orange',
  gold: 'border-gold',
  error: 'border-error',
  beigeLight: 'border-beigeLight',
  darkGrey: 'border-darkGrey',
  grey: 'border-grey',
  mediumGrey: 'border-mediumGrey',
  lightGrey: 'border-lightGrey',
  copyTextGrey: 'border-copyTextGrey',
  white: 'border-white'
};

export const fillColors: { [_K in Colors]: FillColor } = {
  indigo: 'fill-indigo',
  darkGreen: 'fill-darkGreen',
  uiGreen: 'fill-uiGreen',
  uiRed: 'fill-uiRed',
  orange: 'fill-orange',
  gold: 'fill-gold',
  error: 'fill-error',
  beigeLight: 'fill-beigeLight',
  darkGrey: 'fill-darkGrey',
  grey: 'fill-grey',
  mediumGrey: 'fill-mediumGrey',
  lightGrey: 'fill-lightGrey',
  copyTextGrey: 'fill-copyTextGrey',
  white: 'fill-white'
};

export const strokeColors: { [_K in Colors]: StrokeColor } = {
  indigo: 'stroke-indigo',
  darkGreen: 'stroke-darkGreen',
  uiGreen: 'stroke-uiGreen',
  uiRed: 'stroke-uiRed',
  orange: 'stroke-orange',
  gold: 'stroke-gold',
  error: 'stroke-error',
  beigeLight: 'stroke-beigeLight',
  darkGrey: 'stroke-darkGrey',
  grey: 'stroke-grey',
  mediumGrey: 'stroke-mediumGrey',
  lightGrey: 'stroke-lightGrey',
  copyTextGrey: 'stroke-copyTextGrey',
  white: 'stroke-white'
};

export const getBrightness = (color: string): number => {
  // Define a default brightness value
  const defaultBrightness = 1;
  if (!color || color.length < 6) {
    return defaultBrightness;
  }
  // Remove hash if it exists
  color = color?.replace('#', '');
  // If the color string is still too short, return the default brightness
  if (color?.length < 6) {
    return defaultBrightness;
  }

  // Convert hex color to RGB
  const r = parseInt(color.substring(0, 2), 16) / 255;
  const g = parseInt(color.substring(2, 4), 16) / 255;
  const b = parseInt(color.substring(4, 6), 16) / 255;

  // Calculate brightness
  const brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return brightness;
};
