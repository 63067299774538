import { staahPushEnable } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useStaahEnablePush = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: boolean) => {
      return staahPushEnable(payload);
    },
    onError: () => {
      addNotification('fail', 'An error occurred while enabling staah push data.');
    }
  });

  return {
    staahEnablePush: mutate,
    isLoading
  };
};
