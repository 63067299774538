interface OriginalData {
  [key: string]: string | null;
}

interface TransformedData {
  month: string;
  value: number;
}

export function transformPriceCacheData(data: OriginalData): TransformedData[] {
  const transformedData: TransformedData[] = [];

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const month = getMonthFromKey(parseInt(key));
      const value = data[key] !== null ? parseFloat(data[key] as string) : 0;

      transformedData.push({ month, value });
    }
  }

  return transformedData;
}

function getMonthFromKey(key: number): string {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (key >= 1 && key <= 12) {
    return monthNames[key - 1];
  }

  return '';
}
