export function calculateDifference(
  currentValue?: number,
  previousValue?: number
): {
  difference?: number;
  percentageChange?: number;
} {
  if ((!currentValue && currentValue !== 0) || (!previousValue && previousValue !== 0)) return {};

  const difference = currentValue - previousValue;
  const percentageChange = previousValue !== 0 ? (difference / previousValue) * 100 : 0;

  return {
    difference,
    percentageChange
  };
}
