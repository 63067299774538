import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

export type WarningMessage = {
  id: string;
  message: string;
  ignore: boolean;
  ignoreLabel?: string;
  dismissLabel?: string;
  title?: string;
  type?: 'warning' | 'error' | 'success';
  showIgnoreLabel?: boolean;
  customHeaderTitle?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

type WarningStore = {
  warnings: WarningMessage[];
  addWarning: (msg: WarningMessage) => void;
  removeWarning: (id: string) => void;
  ignoreWarning: (id: string) => void;
};

export const useWarningStore = create<WarningStore>((set) => ({
  warnings: [],
  addWarning: (msg) => set((state) => ({ warnings: [...state.warnings, msg] })),
  removeWarning: (id) =>
    set((state) => ({ warnings: state.warnings.filter((msg) => msg.id !== id) })),
  ignoreWarning: (id) =>
    set((state) => ({
      warnings: state.warnings.map((msg) => (msg.id === id ? { ...msg, ignore: true } : msg))
    }))
}));

// Updated useWarnings hook
export function useWarnings() {
  const { warnings, addWarning, removeWarning, ignoreWarning } = useWarningStore();

  const createWarning = ({
    message,
    ignore = false,
    ignoreLabel = 'Cancel',
    dismissLabel = 'Confirm',
    title,
    type = 'warning',
    showIgnoreLabel = true,
    customHeaderTitle
  }: {
    message: string;
    ignore?: boolean;
    ignoreLabel?: string;
    dismissLabel?: string;
    title?: string;
    showIgnoreLabel?: boolean;
    type?: 'warning' | 'error' | 'success';
    customHeaderTitle?: string;
  }) => {
    const id = uuidv4();
    return new Promise<void>((resolve, reject) => {
      addWarning({
        id,
        message,
        ignore,
        ignoreLabel,
        dismissLabel,
        title,
        type,
        showIgnoreLabel,
        customHeaderTitle,
        onConfirm: () => {
          resolve();
        },
        onCancel: () => {
          reject();
        }
      });
    });
  };

  return { warnings, createWarning, removeWarning, ignoreWarning };
}
