import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { AddNewBarModal } from '@pages/Client/PricingStrategy/BarLevels//components/AddNewBarModal';
import { DeleteBarLevelModal } from '@pages/Client/PricingStrategy/BarLevels//components/DeleteBarLevelModal';
import { EditBarModal } from '@pages/Client/PricingStrategy/BarLevels/components/EditBarModal';
import { ColumnDef } from '@tanstack/table-core';
import { useDocumentTitle } from '@mantine/hooks';

export const BarLevels = () => {
  const { t } = useTranslation();
  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings, isLoading: isPricingSettingLoading } = pricingSettingsQuery;
  const { savePricingSettings } = useUpdatePricingSettings();
  useDocumentTitle(t('BAR Levels'));

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();

  const [selectedPrice, setSelectedPrice] = React.useState<any>(null);

  const valuesData: number[] = pricingSettings ? pricingSettings.predefined_rates : [];

  const barLevelData =
    Array.isArray(valuesData) && valuesData.length > 0
      ? valuesData
          .sort((a, b) => a - b)
          .map((value) => ({
            price: value,
            id: value
          }))
      : [];

  const handleDelete = async () => {
    const deletedPrice = selectedPrice.price;
    const newPrices = pricingSettings?.predefined_rates?.filter(
      (price: number) => price !== deletedPrice
    );
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
    const settings = {
      ...latestPricingSettings,
      predefined_rates: newPrices
    };
    await savePricingSettings(JSON.stringify(settings));
    deleteClose();
  };

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const columns: ColumnDef<any>[] = [
    {
      header: t('Price') as string,
      accessorKey: 'price',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {CurrencyFormatter.currencySymbol()} {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true,
        cellAlignment: 'right'
      }
    },
    {
      header: t('Delete') as string,
      accessorKey: 'id',
      cell: (row: any) => {
        const selected = row.row.original;
        return (
          <div className="flex items-center gap-3">
            <Button
              icon
              onClick={() => {
                setSelectedPrice(selected);
                deleteClick();
              }}
            >
              <Icon.Delete className="text-copyTextGrey" />
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const HeaderBarLevel = () => {
    return (
      <div className="flex w-full justify-between py-2">
        <Typography element="h6" variant="h6" color="darkGrey">
          {t('Bar Levels')}
        </Typography>
        <Button onClick={addClick}>{t('Add New BAR Level')}</Button>
      </div>
    );
  };

  return (
    <Page
      header={
        <Header
          title={`${t('BAR Levels')}`}
          description={`${t(
            'Use BAR Levels to pre-define price levels for the reference room at certain set price-points. For example, you may always want your prices to end with a 9. If you wish to use this feature, we recommend setting at least 10 levels stretching from minimum to maximum price.We will round the price-recommendation to the nearest BAR Level.'
          )}
          `}
        />
      }
    >
      <div className="max-w-2xl">
        <Table
          isFetching={isPricingSettingLoading}
          headerComponent={<HeaderBarLevel />}
          columns={columns}
          data={barLevelData ? barLevelData : []}
          onClickRow={(row: any) => {
            setSelectedPrice(row.row.original);
            editClick();
          }}
          isHover
        />
      </div>
      <AddNewBarModal isOpen={addOpen} onClose={addClose} />
      <DeleteBarLevelModal
        isOpen={deleteOpen}
        onClose={deleteClose}
        price={selectedPrice?.price}
        onDelete={() => handleDelete()}
      />
      <EditBarModal isOpen={editOpen} onClose={editClose} defaultPrice={selectedPrice?.price} />
    </Page>
  );
};
