export function HomeVs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M21.71 10.71L14.29 3.29C14.1037 3.10526 13.8523 3.0011 13.59 3H10.41C10.1477 3.0011 9.89626 3.10526 9.71 3.29L2.29 10.71C2.10526 10.8963 2.0011 11.1477 2 11.41V12.5C2 12.7761 2.22386 13 2.5 13H4V20C4 20.5523 4.44772 21 5 21H8C8.55228 21 9 20.5523 9 20V14.5C9 14.2239 9.22386 14 9.5 14H14.5C14.7761 14 15 14.2239 15 14.5V20C15 20.5523 15.4477 21 16 21H19C19.5523 21 20 20.5523 20 20V13H21.5C21.7761 13 22 12.7761 22 12.5V11.41C21.9989 11.1477 21.8947 10.8963 21.71 10.71Z" />
      <path d="M21.71 10.71L14.29 3.29C14.1037 3.10526 13.8523 3.0011 13.59 3H10.41C10.1477 3.0011 9.89626 3.10526 9.71 3.29L2.29 10.71C2.10526 10.8963 2.0011 11.1477 2 11.41V12.5C2 12.7761 2.22386 13 2.5 13H4V20C4 20.5523 4.44772 21 5 21H8C8.55228 21 9 20.5523 9 20V14.5C9 14.2239 9.22386 14 9.5 14H14.5C14.7761 14 15 14.2239 15 14.5V20C15 20.5523 15.4477 21 16 21H19C19.5523 21 20 20.5523 20 20V13H21.5C21.7761 13 22 12.7761 22 12.5V11.41C21.9989 11.1477 21.8947 10.8963 21.71 10.71Z" />
    </svg>
  );
}
