export enum HealthLabel {
  LowPriceHighOccupancy = 'LOW_PRICE_HIGH_OCCUPANCY',
  HighPriceLowOccupancy = 'HIGH_PRICE_LOW_OCCUPANCY',
  OccupancyToLow = 'OCCUPANCY_TOO_LOW',
  OccupancyToHigh = 'OCCUPANCY_TOO_HIGH',
  LooksGood = 'LOOKS_GOOD',
  Undefined = 'UNDEFINED'
}
export enum HealthLabelStatus {
  ToLow = 'TOO_LOW',
  Good = 'GOOD',
  ToHigh = 'TOO_HIGH'
}
