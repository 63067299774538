import { getCurrentUtcTime } from '@common/api/account';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useGetCurrentUTCTime = () => {
  return useQuery({
    queryKey: [UtilQueryKeys.GET_CURRENT_UTC_TIME],
    queryFn: () => getCurrentUtcTime()
  });
};
