import { HotelState } from '@pages/Client/hooks/useSubscription';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type FilterGroup = 'country' | 'pms' | 'subscription_state' | 'subscription_plan';
export type FilterGroups = Record<FilterGroup, FilterOptions>;
export type FilterOptions = Record<string, boolean>;
export type FilterValue = number[];

interface ClientMapParams {
  filters: FilterGroups;
  setFilters: (filters: FilterGroups) => void;
  updateFilters: (filterGroup: FilterGroup, updatedFilters: FilterOptions) => void;
  reset: () => void;
}

export const initialFilters = {
  country: {},
  pms: {},
  subscription_state: {
    [HotelState.FREE_TRIAL]: true,
    [HotelState.SUBSCRIBED]: true,
    [HotelState.SUBSCRIBED_PARTNER]: true
  },
  subscription_plan: {}
};

export const useClientMapStore = create<ClientMapParams>()(
  persist(
    (set, get) => ({
      filters: initialFilters,
      setFilters: (filters: FilterGroups) => set({ filters }),
      updateFilters: (filterGroup: FilterGroup, updatedFilters: FilterOptions) => {
        const currentFilters = get().filters;
        const newFilters: FilterGroups = {
          ...currentFilters,
          [filterGroup]: updatedFilters
        };
        set({ filters: newFilters });
      },
      reset: () =>
        set({
          filters: {
            country: {},
            pms: {},
            subscription_state: {},
            subscription_plan: {}
          }
        })
    }),
    {
      name: 'clientMapStore',
      onRehydrateStorage: () => {
        return (state) => {
          return state;
        };
      }
    }
  )
);
