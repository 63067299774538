export const getPlanTier = (plan: number) => {
  switch (plan) {
    case 2:
      return 'Starter';
    case 3:
      return 'Advanced';
    case 4:
      return 'Professional';
    case 5:
      return 'Small Business';
    default:
      return '';
  }
};
