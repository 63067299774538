import { ScheduleHistoryProgram } from '@common/api/amalgamation/types';
import { Badge } from '@common/components/atoms/Badge';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { Table } from '@common/components/molecules/Table';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { ActionIcon, Button } from '@mantine/core';
import { useDetailsScheduleHistory } from '@pages/Admin/Scrape/ScheduleHistory/hooks/useDetailsScheduleHistory';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Input } from '@common/components/atoms/Input';
type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  scheduleId: string | null;
};

const schema = z.object({
  page: z.number(),
  id: z.string().nullish(),
  status: z.number().optional(),
  search: z.string().optional()
});

type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== null && value !== undefined && value !== null
    )
  ) as Params;
};

const SCHEDULE_STATUS = [
  {
    label: 'Done',
    value: 3
  },
  {
    label: 'In Progress',
    value: 2
  },
  {
    label: 'Scheduled',
    value: 1
  }
];

export const ScheduleHistoryDetailsModal = ({ isOpen, onClose, scheduleId }: ModalProps) => {
  const defaultValues = {
    id: scheduleId,
    page: 1,
    search: undefined,
    status: undefined
  };
  const { watch, reset, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, filterEmptyValues, defaultValues]
  );

  const { detailScheduleHistoryList, isLoading } = useDetailsScheduleHistory(
    filterEmptyValues({
      id: scheduleId,
      page: watch('page'),
      search: getValues('search'),
      status: watch('status')
    })
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const ROWS_PER_PAGE = 20;

  const startIndex = detailScheduleHistoryList?.current
    ? (detailScheduleHistoryList.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    detailScheduleHistoryList?.current && detailScheduleHistoryList.results
      ? startIndex + detailScheduleHistoryList.results.length - 1
      : 0;

  const handleNext = () => {
    if (!detailScheduleHistoryList?.next) return;
    const params = new URLSearchParams(detailScheduleHistoryList.next.split('?')[1]);
    const page = params.get('page');
    const search = params.get('search');
    const status = params.get('status');

    if (!page) return;

    reset(
      filterEmptyValues({
        page: parseInt(page),
        search: search ? search : undefined,
        status: status ? parseInt(status) : undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  const columns: any[] = [
    {
      header: 'Name',
      accessorKey: 'program',
      cell: (row: any) => {
        const program = row?.getValue() as ScheduleHistoryProgram;
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {program?.name}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Hotel List',
      accessorKey: 'hotel_list',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Start Time',
      accessorKey: 'start_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'End Time',
      accessorKey: 'end_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Part',
      accessorKey: 'part',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Scraper Version',
      accessorKey: 'scraper_version',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Record Scraped',
      accessorKey: 'record_scraped',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },

    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row: any) => {
        const statusMapping: Record<number, string> = {
          1: 'Scheduled',
          2: 'In Progress',
          3: 'Done'
        };
        const statusBgMapping: Record<number, string> = {
          1: 'darkGrey',
          2: 'orange',
          3: 'darkGreen'
        };
        return (
          <div className="flex items-center gap-3">
            <Badge className="text-center" background={statusBgMapping[row?.getValue() as number]}>
              {statusMapping[row?.getValue() as number]}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const TableHeader = () => {
    return (
      <div className="flex w-full justify-between py-3">
        <Typography variant="h6" element="h6" className="font-medium" color="darkGrey">
          Details Schedule History
        </Typography>
      </div>
    );
  };

  return (
    <Modal
      open={isOpen}
      okText={'Delete'}
      showFooter={false}
      onClose={onClose}
      size={'custom'}
      continueIntent="danger">
      <div className=" overflow-hidden rounded-xl bg-white p-4">
        <ActionIcon
          className="absolute right-6 top-6 z-20 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={onClose}>
          <Icon.Clear className="h-5 w-5" />
        </ActionIcon>
        <div className="relative  min-w-[70vw] max-w-[70vw] overflow-x-scroll pt-8">
          <div className="max-h-[85vh] min-h-[85vh]">
            <Table
              isHover
              columns={columns}
              skeletonCount={20}
              isFetching={isLoading}
              data={!isLoading ? (detailScheduleHistoryList?.results as any) : []}
              next={handleNext}
              isNext={!!detailScheduleHistoryList?.next}
              previous={handlePrevious}
              isPrevious={watch('page') > 1}
              count={`${startIndex}-${endIndex}`}
              totalCount={detailScheduleHistoryList?.count}
              manualPagination={true}
              rowsPerPage={ROWS_PER_PAGE}
              headerComponent={<TableHeader />}
              actionComponents={
                <div className="flex items-center justify-center gap-5">
                  <div>
                    <Controller
                      name="search"
                      control={control}
                      render={({ field: { value, name, onChange } }) => (
                        <Input
                          placeholder="Search"
                          background="grey"
                          name={name}
                          id={name}
                          type="text"
                          value={value}
                          onChange={(e) => {
                            handleSearch(e.target.value);
                            onChange(e);
                          }}
                          onClear={() => {
                            reset(defaultValues);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <RPGPopover>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <RPGPopover.Button as={ActionIcon}>
                              <Icon.FilterList />
                            </RPGPopover.Button>
                          </TooltipTrigger>
                          <TooltipContent side="top" className="bg-white text-darkGrey">
                            Filter
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <RPGPopover.Panel>
                        <div className="mb-2 flex items-center gap-3">
                          <Typography className="text-meta-1-medium" color="darkGrey">
                            Filters
                          </Typography>
                          <Button size="compact-xs" onClick={() => reset(defaultValues)}>
                            Reset
                          </Button>
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                          <div>
                            <Controller
                              control={control}
                              name="status"
                              render={({ field: { value, name, onChange } }) => (
                                <SelectDropdown
                                  fullWidth
                                  hint="Schedule Status"
                                  placeholder="Select Schedule Status"
                                  name={name}
                                  key={name}
                                  value={value || ''}
                                  options={SCHEDULE_STATUS}
                                  onChange={(value) => {
                                    onChange(value);
                                    onResetAndSet('status', value);
                                    setValue('page', 1);
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </RPGPopover.Panel>
                    </RPGPopover>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
