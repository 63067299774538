export function GroupAdd(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 11.0001C12.1827 11.0024 13.2565 10.3094 13.7417 9.23063C14.2268 8.15186 14.0328 6.88871 13.2463 6.00526C12.4598 5.12181 11.2276 4.78299 10.0999 5.14011C11.2982 6.8585 11.2982 9.14172 10.0999 10.8601C10.391 10.9523 10.6945 10.9995 10.9999 11.0001ZM5.9999 11.0001C7.65676 11.0001 8.9999 9.65696 8.9999 8.00011C8.9999 6.34325 7.65676 5.00011 5.9999 5.00011C4.34305 5.00011 2.9999 6.34325 2.9999 8.00011C2.9999 9.65696 4.34305 11.0001 5.9999 11.0001ZM11.8899 16.5501C12.0459 16.8596 12.0304 17.2278 11.8491 17.5232C11.6678 17.8186 11.3465 17.9991 10.9999 18.0001H0.999904C0.653311 17.9991 0.331976 17.8186 0.150667 17.5232C-0.0306409 17.2278 -0.0460649 16.8596 0.109904 16.5501L1.1099 14.6501C1.61168 13.654 2.62467 13.0184 3.7399 13.0001H8.2599C9.39318 12.9998 10.4299 13.6381 10.9399 14.6501L11.8899 16.5501ZM20.9999 10.0001H23.4999C23.776 10.0001 23.9999 10.224 23.9999 10.5001V11.5001C23.9999 11.7763 23.776 12.0001 23.4999 12.0001H20.9999V14.5001C20.9999 14.7763 20.776 15.0001 20.4999 15.0001H19.4999C19.2238 15.0001 18.9999 14.7763 18.9999 14.5001V12.0001H16.4999C16.2238 12.0001 15.9999 11.7763 15.9999 11.5001V10.5001C15.9999 10.224 16.2238 10.0001 16.4999 10.0001H18.9999V7.50011C18.9999 7.22397 19.2238 7.00011 19.4999 7.00011H20.4999C20.776 7.00011 20.9999 7.22397 20.9999 7.50011V10.0001ZM13.2599 13.0001C14.3932 12.9998 15.4299 13.6381 15.9399 14.6501L16.8899 16.5501C17.0459 16.8596 17.0304 17.2278 16.8491 17.5232C16.6678 17.8186 16.3465 17.9991 15.9999 18.0001H13.8199C14.0985 17.2318 14.0515 16.383 13.6899 15.6501L12.7399 13.7601C12.6036 13.4926 12.4429 13.2382 12.2599 13.0001H13.2599Z"
      />
    </svg>
  );
}
