export const Ripple = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 100 100"
      className="h-6 w-6"
      {...props}>
      <circle cx="50" cy="50" r="0" fill="none" stroke="currentColor" strokeWidth="5">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="3.3333333333333335s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="0s"></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="3.3333333333333335s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="0s"></animate>
      </circle>
      <circle cx="50" cy="50" r="0" fill="none" stroke="currentColor" strokeWidth="5">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="3.3333333333333335s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-1.6666666666666667s"></animate>
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="3.3333333333333335s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-1.6666666666666667s"></animate>
      </circle>
    </svg>
  );
};
