import { Navigate, RouteObject } from 'react-router-dom';
import { SecureRoute } from '@pages/Client/components/SecureRoute';
import ProfileLayout from '@pages/Client/Account/Profile';
import PropertyLayout from '@pages/Client/Account/Property';
import ScheduleReportsLayout from '@pages/Client/Account/ScheduleReports';
import SubscriptionLayout from '@pages/Client/Account/Subscription';
import UserLayout from '@pages/Client/Account/User';
import AccountPropertyTypePage from '@pages/Client/Account/Property/pages/PropertyType';
import AccountNameAndLocationPage from '@pages/Client/Account/Property/pages/NameAndLocation';
import AccountUserSettingsPage from '@pages/Client/Account/User/pages';
import PersonalInfoPage from '@pages/Client/Account/Profile/pages/PersonalInfo';
import PasswordPage from '@pages/Client/Account/Profile/pages/Password';
import LanguagePage from '@pages/Client/Account/Profile/pages/Language';
import SubscriptionPage from '@pages/Client/Account/Subscription/pages';
import ScheduleReportsPage from '@pages/Client/Account/ScheduleReports/pages';
import IntegrationsPage from '@pages/Client/Account/Integrations';
import { Feature } from '@common/store/features';

export const propertyRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="name-and-location" /> },
  { path: 'name-and-location', element: <AccountNameAndLocationPage /> },
  { path: 'property-type', element: <AccountPropertyTypePage /> }
];

export const userRoutes: RouteObject[] = [{ path: '', element: <AccountUserSettingsPage /> }];

export const subscriptionRoutes: RouteObject[] = [{ path: '', element: <SubscriptionPage /> }];

export const scheduleReportsRoutes: RouteObject[] = [
  { path: '', element: <ScheduleReportsPage /> }
];

export const profileRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="language" /> },
  { path: 'personal-information', element: <PersonalInfoPage /> },
  { path: 'password', element: <PasswordPage /> },
  { path: 'language', element: <LanguagePage /> }
];

export const accountRoutes: RouteObject[] = [
  {
    path: 'profile-settings/',
    element: (
      <SecureRoute>
        <ProfileLayout />
      </SecureRoute>
    ),
    children: profileRoutes
  },
  {
    path: 'hotel-settings/',
    element: (
      <SecureRoute>
        <PropertyLayout />
      </SecureRoute>
    ),
    children: propertyRoutes
  },
  {
    path: 'user-settings/',
    element: (
      <SecureRoute>
        <UserLayout />
      </SecureRoute>
    ),
    children: userRoutes
  },
  {
    path: 'payment-settings',
    element: (
      <SecureRoute>
        <SubscriptionLayout />
      </SecureRoute>
    ),
    children: subscriptionRoutes
  },
  {
    path: 'integrations',
    element: (
      <SecureRoute>
        <IntegrationsPage />
      </SecureRoute>
    )
  },
  {
    path: 'schedule-reports',
    element: (
      <SecureRoute feature={Feature.ProfessionalDashboard}>
        <ScheduleReportsLayout />
      </SecureRoute>
    ),
    children: scheduleReportsRoutes
  }
];
