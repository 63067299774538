import { create } from 'zustand';

interface UnitOptions {
  value: number | string | null;
  label: string;
}

interface UnitStoreState {
  unitRooms: UnitOptions[];
  setUnitRooms: (newUnitRooms: UnitOptions[]) => void;
}

export const useUnitStore = create<UnitStoreState>((set) => ({
  unitRooms: [],
  setUnitRooms: (newUnitRooms: UnitOptions[]) => set({ unitRooms: newUnitRooms })
}));
