import { ProtectedLayout } from '@common/layouts/ProtectedLayout';
import { ClientLayout } from '@pages/Client/ClientLayout';

export const ProtectedClientLayout = () => {
  return (
    <ProtectedLayout type="client">
      <ClientLayout />
    </ProtectedLayout>
  );
};
