import React, { useRef, useCallback } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { getEnvVar } from '@common/utils/env';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Input } from '@common/components/atoms/Input';

export enum GoogleMapsLibrary {
  Places = 'places',
  Drawing = 'drawing',
  Geometry = 'geometry',
  LocalContext = 'localContext',
  Visualization = 'visualization'
}

export const GoogleAutoCompleteInput = () => {
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const onPlacesChanged = useCallback(() => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        const { geometry, formatted_address } = selectedPlace;
        if (geometry) {
          const { location } = geometry;
          const latitude = location?.lat();
          const longitude = location?.lng();
          setValue('lat', latitude);
          setValue('lng', longitude);
          setValue('address', formatted_address);
          const timeZoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(
            new Date().getTime() / 1000
          )}&key=${getEnvVar('VITE_GOOGLEAPIKEY')}`;
          fetch(timeZoneApiUrl)
            .then((response) => response.json())
            .then((data) => {
              const timeZone = data.timeZoneId;
              setValue('timezone', timeZone);
            });
        }
      }
    }
  }, []);

  return (
    <StandaloneSearchBox
      onLoad={(ref) => (searchBoxRef.current = ref)}
      onPlacesChanged={onPlacesChanged}>
      <Controller
        name="address"
        control={control}
        rules={{
          required: `${t('Please enter your address')}`
        }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <Input
            placeholder={`${t('Please enter your address')}`}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            type="text"
            error={error ? true : false}
            onClear={() => {
              setValue('address', '');
            }}
            helperMessage={
              error ? (
                <>
                  <div className="flex items-center gap-2 text-error">
                    <Icon.WarningOutline className="w-4" />
                    <Typography element="span" color="error" variant="meta-2">
                      {t(`${error?.message || ''}`)}
                    </Typography>
                  </div>
                </>
              ) : null
            }
          />
        )}
      />
    </StandaloneSearchBox>
  );
};
