import { Hotel } from '@common/api/hotel/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ViewingDates } from '@pages/Client/Calendar/components/BulkEdit/types/schema';

export enum BulkEditTab {
  EDIT = 0,
  REMOVE = 1,
  SURGES = 2
}

interface BulkEditsStore {
  dateRangeError?: string;
  drawerState: boolean;
  editDate?: Date;
  hotelDetails?: Hotel;
  bulkEditTab: number;
  setBulkEditTab: (tab: number) => void;
  setBulkEditDrawerState: (open: boolean) => void;
  setBulkEditViewingDates: (dates: ViewingDates | undefined) => void;
  setDateRangeError: (error?: string) => void;
  setEditDate: (date: Date) => void;
  setHotelDetails: (hotelDetails: Hotel) => void;
  viewingDates?: ViewingDates;
}

export const useBulkEditsStore = create<BulkEditsStore>()(
  immer((set) => ({
    drawerState: false,
    bulkEditTab: BulkEditTab.EDIT,
    setBulkEditTab: (tab: number) => set({ bulkEditTab: tab }),
    setEditDate: (date) => set({ editDate: date }),
    setBulkEditDrawerState: (open) => set({ drawerState: open }),
    setBulkEditViewingDates: (dates) => set({ viewingDates: dates }),
    setDateRangeError: (error) => set({ dateRangeError: error }),
    setHotelDetails: (hotelDetails) => set({ hotelDetails })
  }))
);
