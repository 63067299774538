import { Popover, PopoverProps, Transition, TransitionClasses } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Button as UIButton } from '@common/components/atoms/Button';
import { debounce } from 'lodash-es';

import styles from '@common/components/molecules/Popover/Popover.module.css';

interface RpgPopoverContextProps {
  referenceElement: any;
  setReferenceElement: any;
  open: boolean;
  setOpen: any;
  actionOnMouseOver?: boolean;
  handleOnMouseLeave: any;
  handleOnMouseEnter: any;
}

type ButtonProps = React.ComponentProps<(typeof Popover)['Button'] | typeof UIButton> & {
  onClickAction?: () => void;
};

type ExtendedPopoverProps<T extends React.ElementType = React.ElementType> = PopoverProps<T> & {
  actionOnMouseOver?: boolean;
};

type PanelProps = React.ComponentProps<(typeof Popover)['Panel']> & {
  unstyled?: boolean;
  transitionProps?: TransitionClasses & {
    show?: boolean;
    appear?: boolean;
  };
};

const RpgPopoverContext = React.createContext<RpgPopoverContextProps | null>(null);

const RpgPopoverRoot = ({ actionOnMouseOver, ...props }: ExtendedPopoverProps<any>) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [open, setOpen] = useState(false);

  const handleOnMouseLeave = debounce(() => {
    if (actionOnMouseOver) {
      setOpen(false);
    }
  }, 250);

  const handleOnMouseEnter = () => {
    handleOnMouseLeave.cancel();
    if (actionOnMouseOver) {
      setOpen(true);
    }
  };

  return (
    <RpgPopoverContext.Provider
      value={{
        referenceElement,
        setReferenceElement,
        open,
        setOpen,
        actionOnMouseOver,
        handleOnMouseLeave,
        handleOnMouseEnter
      }}>
      <Popover {...props} onClose={() => setOpen(false)} />
    </RpgPopoverContext.Provider>
  );
};

export const Panel = ({ className, unstyled = false, transitionProps, ...props }: PanelProps) => {
  const [popperElement, setPopperElement] = useState<any>();
  const {
    referenceElement,
    open,
    setOpen,
    actionOnMouseOver,
    handleOnMouseEnter,
    handleOnMouseLeave
  } = useContext(RpgPopoverContext)!;
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 15]
          }
        },
        {
          name: 'computeStyles',
          options: {
            adaptive: false // true by default
          }
        }
      ],
      strategy: 'fixed'
    }
  );

  return createPortal(
    <Transition
      as={Fragment}
      show={actionOnMouseOver ? open : transitionProps?.show}
      {...(transitionProps ?? {})}>
      <Popover.Panel
        ref={setPopperElement}
        style={popperStyles.popper}
        className={clsx(styles.panelBase, unstyled && styles.panelUnstyled, className)}
        {...popperAttributes.popper}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={() => setOpen(false)}
        {...props}
      />
    </Transition>,
    document.body
  );
};

const Button = ({ onClickAction, ...props }: ButtonProps) => {
  const {
    setReferenceElement,
    handleOnMouseEnter,
    handleOnMouseLeave,
    setOpen,
    actionOnMouseOver
  } = useContext(RpgPopoverContext)!;

  function handleClick() {
    if (actionOnMouseOver) {
      onClickAction && onClickAction();
      onClickAction && setOpen(!open);
    } else {
      setOpen(!open);
    }
  }

  return (
    <Popover.Button
      ref={setReferenceElement}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onClick={handleClick}
      {...props}
    />
  );
};

export const RPGPopover = Object.assign(RpgPopoverRoot, { Button, Panel });
