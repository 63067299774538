import { getHotelRoomsList } from '@common/api/hotel';
import { RoomsQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

/**
 * duplicates src/pages/Client/Calendar/hooks/useHotelRoomsList.ts
 * To be removed in https://roompricegenie.atlassian.net/browse/RMD-402
 * */

export const useHotelRoomsList = () => {
  const query = useQuery({
    queryKey: [RoomsQueryKeys.GET_HOTEL_ROOMS_LIST],
    queryFn: () => getHotelRoomsList()
  });
  const hotelRooms = useMemo(() => {
    return query.data?.data || [];
  }, [query.data]);

  return {
    query,
    hotelRooms
  };
};
