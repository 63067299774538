export const TrendingUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M21.9999 5.5V10.5C21.9999 10.7761 21.776 11 21.4999 11H21.2099C21.0743 11.002 20.9439 10.9477 20.8499 10.85L19.2099 9.21L13.8499 14.56C13.7591 14.6567 13.6324 14.7115 13.4999 14.7115C13.3673 14.7115 13.2406 14.6567 13.1499 14.56L10.4999 11.91L3.55985 18.85C3.46597 18.9447 3.33817 18.9979 3.20485 18.9979C3.07153 18.9979 2.94374 18.9447 2.84985 18.85L2.14985 18.15C2.0552 18.0561 2.00195 17.9283 2.00195 17.795C2.00195 17.6617 2.0552 17.5339 2.14985 17.44L10.1499 9.44C10.2406 9.34332 10.3673 9.28848 10.4999 9.28848C10.6324 9.28848 10.7591 9.34332 10.8499 9.44L13.4999 12.09L17.7899 7.79L16.1499 6.15C16.0522 6.05592 15.9979 5.92559 15.9999 5.79V5.5C15.9999 5.22386 16.2237 5 16.4999 5H21.4999C21.776 5 21.9999 5.22386 21.9999 5.5Z" />
    </svg>
  );
};
