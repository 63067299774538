import { API_DATE_FORMAT } from '@common/constants/date';
import dayjs from 'dayjs';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useFeaturesStore } from '@common/store/features';
import { useMinStayValue } from '@pages/Client/Calendar/hooks/useMinStayValue';
import { useMemo } from 'react';
import { produce } from 'immer';
import { filter, reduce, entries, values, some, isObject } from 'lodash-es';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';

type MinStayInfo = {
  min_stay: number;
  dates: {
    date: string;
  }[];
};

export type MinStaysVisualiser = {
  [key: string]: MinStayInfo;
};

export const useMinStayVisualiser = () => {
  const { roomPrices, isFull, isClosedRooms } = useRoomPrices();
  const { pricingSettings } = usePricingSettings();
  const { hasMinStayEnabled, isArrivalBased } = usePmsProvider();
  const { getMinStayValue } = useMinStayValue();
  const { hasMinStayFeature } = useFeaturesStore();

  const dates = useMemo(() => {
    const pricingDates = entries(pricingSettings?.dates ?? {});
    const roomPricesDates = entries(roomPrices?.prices.data ?? {});
    const dates = [...pricingDates, ...roomPricesDates];
    const filteredDates = filter(dates, ([_, value]) => {
      return some(values(value), (roomDateData) => {
        if (isObject(roomDateData) && roomDateData !== null) {
          return (
            'min_stay' in roomDateData ||
            'suggested_min_stay' in roomDateData ||
            'original_min_stay' in roomDateData
          );
        }
        return false;
      });
    });

    return filteredDates;
  }, [pricingSettings, roomPrices]);

  const minStaysVisualiser = (roomId: number | undefined) => {
    if (!dates || !hasMinStayEnabled || !hasMinStayFeature() || !roomId) return {};

    const currentDate = dayjs().startOf('day');

    return reduce(
      dates,
      (acc, curr) => {
        const [sourceDate, value] = curr;

        if (!value || !value[roomId]) return acc;

        const minStayInfo = getMinStayValue(sourceDate, roomId);
        if (!minStayInfo) return acc;

        const { minStayValueToUse } = minStayInfo;
        const sourceDateObj = dayjs(sourceDate);

        if (sourceDateObj.isBefore(currentDate)) return acc;
        if (!minStayValueToUse) return acc;

        const datesArray: MinStayInfo['dates'] = [];

        if (isArrivalBased()) {
          // Arrival-based min-stay
          datesArray.push({ date: sourceDateObj.format(API_DATE_FORMAT) });

          for (let i = 1; i < minStayValueToUse; i++) {
            const date = sourceDateObj.add(i, 'day');
            if (isFull(date.toDate()) || isClosedRooms(date.toDate())) {
              break;
            }
            datesArray.push({ date: date.format(API_DATE_FORMAT) });
          }
        } else {
          // Stay-through min-stay
          if (isFull(sourceDateObj.toDate()) || isClosedRooms(sourceDateObj.toDate())) {
            return acc; // Skip this date entirely
          }

          let validDates = [];
          for (let i = minStayValueToUse - 1; i >= 0; i--) {
            const date = sourceDateObj.subtract(i, 'day');
            if (date.isBefore(dayjs().startOf('day'))) {
              continue; // Skip past dates
            }
            if (isFull(date.toDate()) || isClosedRooms(date.toDate())) {
              validDates = []; // Reset valid dates if we encounter a full or closed date
              continue;
            }
            validDates.push({ date: date.format(API_DATE_FORMAT) });
          }

          datesArray.push(...validDates);

          if (sourceDateObj.isSameOrAfter(currentDate)) {
            datesArray.push({ date: sourceDateObj.format(API_DATE_FORMAT) });
          }

          for (let i = 1; i < minStayValueToUse; i++) {
            const date = sourceDateObj.add(i, 'day');
            if (isFull(date.toDate()) || isClosedRooms(date.toDate())) {
              break; // Stop adding dates when we hit a full or closed date
            }
            datesArray.push({ date: date.format(API_DATE_FORMAT) });
          }
        }

        // Use immer's produce to update the accumulator
        return produce(acc, (draft) => {
          if (draft[sourceDate]) {
            draft[sourceDate].dates = [...draft[sourceDate].dates, ...datesArray];
          } else {
            draft[sourceDate] = { min_stay: minStayValueToUse, dates: datesArray };
          }
        });
      },
      {} as MinStaysVisualiser
    );
  };

  return {
    minStaysVisualiser
  };
};
