import { Icon } from '@common/components/foundations/icons';
import styles from './PricingCellContent.module.css';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';

interface PricingCellContentProps {
  className?: string;
  day?: Date;
  isAdj?: boolean;

  content: {
    trending?: string;
    secondaryLabel?: string | React.ReactNode;
    primaryLabel?: string;
    isHasPercentAdj?: boolean;
    isHasFixPrice?: boolean;
    occupancy?: string;
    isFull?: boolean;
    isClosed?: boolean;
  };
}

export const PricingCellContent = ({ content, className }: PricingCellContentProps) => {
  const { t } = useTranslation();
  const IconComponent = content.trending
    ? content.trending === 'down'
      ? Icon.TrendingDown
      : content.trending === 'lock'
      ? Icon.Lock
      : Icon.TrendingUp
    : undefined;

  return (
    <div className={cn('flex items-end justify-between text-meta-2', className)}>
      <div className="font-medium">{content.occupancy}</div>
      <div className="text-right">
        {!content.isClosed && !content.isFull ? (
          <>
            <div>{content.secondaryLabel}</div>
            <div className="flex items-center justify-end gap-1">
              {content.isHasPercentAdj && !content.isHasFixPrice ? (
                <div className={cn(styles.percentageAdj)}>%</div>
              ) : null}
              <div
                className={cn(
                  'flex h-7 items-center',
                  styles.priceText,
                  content.trending
                    ? content.trending === 'down'
                      ? styles.priceTextDown
                      : content.trending === 'up'
                      ? styles.priceTextUp
                      : ''
                    : ''
                )}>
                {IconComponent ? (
                  <IconComponent fill="currentColor" className="inline-block h-auto w-4" />
                ) : null}
                <div>{content.primaryLabel}</div>
              </div>
            </div>
          </>
        ) : (
          <div className={cn(styles.priceText)}>
            {content.isClosed ? t('Closed') : content.isFull ? t('Full') : null}
          </div>
        )}
      </div>
    </div>
  );
};
