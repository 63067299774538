import { getCompetitorScore, scoreCompetitor } from '@common/api/hotel';
import { ScoreCompetitor } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { MarketIntelligenceQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useCompetitorScore = () => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [MarketIntelligenceQueryKeys.GET_COMPETITOR_SCORE],
    queryFn: () => {
      return getCompetitorScore();
    }
  });

  if (isError) addNotification('fail', 'Failed to get competitor score');

  return {
    competitorScore: data?.data.data,
    isLoading,
    error
  };
};

export const useScoreCompetitor = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (competitor: ScoreCompetitor) => {
      return scoreCompetitor(competitor);
    },
    onSuccess: (data: any) => {
      if (data?.data?.success) {
        addNotification('success', 'Successfully scored competitor');
        queryClient.invalidateQueries({
          queryKey: [MarketIntelligenceQueryKeys.GET_COMPETITOR_SCORE]
        });
        queryClient.invalidateQueries({
          queryKey: [MarketIntelligenceQueryKeys.GET_HOTEL_COMPETITOR]
        });
      } else {
        addNotification('fail', `${data?.data?.error ?? 'Failed to score competitor'} `);
      }
    }
  });

  return {
    scoredCompetitor: mutate,
    isLoading
  };
};
