import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Stack, Drawer, Modal, Title, Button, Text, Code, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

export const Overlays = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] = useDisclosure(false);
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [otherModalOpened, { open: openOtherModal, close: closeOtherModal }] = useDisclosure(false);

  return (
    <Stack py="xs">
      <Stack>
        <Title order={2} size="h3">
          Drawer
        </Title>
        <Button onClick={openDrawer}>Open Drawer</Button>
        <Drawer opened={drawerOpened} onClose={closeDrawer} title="Drawer">
          Content
        </Drawer>
      </Stack>
      <Stack>
        <Title order={2} size="h3">
          Modal
        </Title>
        <Button onClick={openModal}>Open Modal</Button>
        <Modal opened={modalOpened} onClose={closeModal} title="Modal">
          Content
        </Modal>

        <Title order={2} size="h3">
          Action Modal
        </Title>

        <Text>
          See <Code>src/pages/DesignSystem/components/overlays.tsx</Code> for implementation details
        </Text>

        <Button onClick={openOtherModal}>Open Action Modal</Button>

        <Modal.Root
          opened={otherModalOpened}
          onClose={closeOtherModal}
          scrollAreaComponent={ScrollArea.Autosize}>
          <Modal.Overlay />
          <Modal.Content>
            <form>
              <Modal.Header bg="white">
                <Modal.Title>Action Modal</Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>Content</Modal.Body>

              <ModalFooter>
                <Button variant="subtle" onClick={closeOtherModal}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </ModalFooter>
            </form>
          </Modal.Content>
        </Modal.Root>
      </Stack>
    </Stack>
  );
};
