export const Pen = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4887 3.14981L20.8312 9.508C20.9385 9.60289 21 9.73943 21 9.88289C21 10.0264 20.9385 10.1629 20.8312 10.2578L18.1386 12.957C18.045 13.0516 17.9176 13.1049 17.7846 13.1049C17.6517 13.1049 17.5242 13.0516 17.4306 12.957L16.8323 12.3672L14.6683 17.7956C14.4314 18.3923 13.9232 18.8385 13.302 18.9953L5.26429 20.9947C5.1848 21.0044 5.10444 21.0044 5.02495 20.9947C4.74958 21.0044 4.48247 20.8995 4.287 20.7048L3.28976 19.7051C3.04302 19.4561 2.94461 19.0956 3.03048 18.7554L5.02495 10.6977C5.18945 10.0662 5.65106 9.55489 6.26153 9.32805L11.6765 7.15867L11.0882 6.55884C10.9938 6.46498 10.9407 6.33722 10.9407 6.20394C10.9407 6.07066 10.9938 5.9429 11.0882 5.84904L13.7807 3.14981C13.8743 3.05518 14.0018 3.00195 14.1347 3.00195C14.2677 3.00195 14.3951 3.05518 14.4887 3.14981ZM6.71029 18.5454L12.8533 17.0159L15.3264 10.7876L13.2322 8.68823L7.01943 11.1775L5.47371 17.3058L8.32581 14.4466C8.44582 14.3276 8.51394 14.1659 8.51529 13.9967C8.51529 13.1685 9.18501 12.4971 10.0111 12.4971C10.8373 12.4971 11.507 13.1685 11.507 13.9967C11.507 14.8249 10.8373 15.4963 10.0111 15.4963C9.84239 15.4976 9.68104 15.5659 9.56239 15.6862L6.71029 18.5454Z"
        fill="currentColor"
      />
    </svg>
  );
};
