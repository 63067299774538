import { PricingSettings } from '@common/api/hotel/types';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { map, isNaN } from 'lodash-es';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { MinStay } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minStaySchema';

/**
 * Rules for setting and deleting the min_stay key:
 *
 * - Set min_stay Rule:
 *   - Condition:
 *     - The value is greater than 0
 *   - Action:
 *     - Set the min_stay key (as specified by the `actions.setMinStayKey` event)
 *
 * - Delete min_stay Rule:
 *   - Condition:
 *     - `isBulkEdit` is true
 *     - The value is exactly 0
 *   - Action:
 *     - Delete the min_stay key (as specified by the `actions.deleteMinStayKey` event)
 *
 *   - Condition:
 *     - `isBulkEdit` is false
 *     - The value is 0, null, or NaN
 *   - Action:
 *     - Delete the min_stay key (as specified by the `actions.deleteMinStayKey` event)
 */

const actions = {
  setMinStayKey: 'setMinStayKey',
  deleteMinStayKey: 'deleteMinStayKey',
  setIsSkipMinStayRulesKey: 'setIsSkipMinStayRulesKey',
  deleteIsSkipMinStayRulesKey: 'deleteIsSkipMinStayRulesKey'
};

const isBulkEdit = { fact: 'isBulkEdit', operator: 'equal', value: true };
const isNotBulkEdit = { fact: 'isBulkEdit', operator: 'equal', value: false };
const isZeroValue = { fact: 'value', operator: 'equal', value: 0 };
const isNullValue = { fact: 'value', operator: 'equal', value: null };
const isNaNValue = { fact: 'value', operator: 'isNaNOperator', value: true };
const isGreaterThanZeroValue = { fact: 'value', operator: 'greaterThan', value: 0 };
const isSkipMinStayRules = { fact: 'is_skip_min_stay_rule', operator: 'equal', value: true };
const isNotSkipMinStayRules = { fact: 'is_skip_min_stay_rule', operator: 'equal', value: false };

const deleteMinStayKey = new Rule({
  conditions: {
    any: [
      { all: [isBulkEdit, isZeroValue] },
      { all: [isNotBulkEdit, { any: [isZeroValue, isNullValue, isNaNValue] }] }
    ]
  },
  event: { type: actions.deleteMinStayKey }
});

const setMinStayKey = new Rule({
  conditions: { all: [isGreaterThanZeroValue] },
  event: { type: actions.setMinStayKey }
});

const setIsSkipMinStayRulesKey = new Rule({
  conditions: { all: [isSkipMinStayRules] },
  event: { type: actions.setIsSkipMinStayRulesKey }
});

const deleteIsSkipMinStayRulesKey = new Rule({
  conditions: { all: [isNotSkipMinStayRules] },
  event: { type: actions.deleteIsSkipMinStayRulesKey }
});

export async function transformMinStay(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Record<string, MinStay>,
  date: string
) {
  const engine = new Engine();
  engine.addOperator('isNaNOperator', (factValue) => isNaN(factValue));
  engine.addRule(setMinStayKey);
  engine.addRule(deleteMinStayKey);
  engine.addRule(setIsSkipMinStayRulesKey);
  engine.addRule(deleteIsSkipMinStayRulesKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('id') as Promise<string>).then((id: string) => {
      if (event.type === actions.setMinStayKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][id]) currentPricingDraft.dates[date][id] = {};
        currentPricingDraft.dates[date][id][ADJUSTMENT_DB.MIN_STAY_KEY] = formDataById[id]
          .value as number;
      }
      if (event.type === actions.deleteMinStayKey) {
        delete currentPricingDraft.dates[date]?.[id]?.[ADJUSTMENT_DB.MIN_STAY_KEY];
      }

      if (event.type === actions.setIsSkipMinStayRulesKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][id]) currentPricingDraft.dates[date][id] = {};
        currentPricingDraft.dates[date][id][ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE] = formDataById[
          id
        ]?.[ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE] as boolean;
      }

      if (event.type === actions.deleteIsSkipMinStayRulesKey) {
        delete currentPricingDraft.dates[date]?.[id]?.[ADJUSTMENT_DB.IS_SKIP_MIN_STAY_RULE];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (item: MinStay) => {
      await engine.run(item);
    })
  );

  return;
}
