import { create } from 'zustand';
interface LoadingStore {
  isEnableClientLoading: boolean;
  setEnableClientLoading: (newLoading: boolean) => void;
}

export const useEnableClientLoading = create<LoadingStore>((set) => ({
  isEnableClientLoading: false,
  setEnableClientLoading: (newLoading: boolean) => set({ isEnableClientLoading: newLoading })
}));
