import { enablePricing } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useEnablePricing = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (enable: boolean) => {
      return enablePricing(enable);
    },
    onSuccess: (data) => {
      const pricingState = data?.data?.is_pricing_turn_on;
      const message = pricingState
        ? 'Pricing is now turned ON successfully'
        : 'Pricing is now turned OFF successfully';

      addNotification('success', message);
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', 'Failed to turn on pricing');
    }
  });

  return {
    enablePricing: mutate,
    isLoading,
    isSuccess
  };
};
