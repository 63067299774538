import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@mantine/hooks';
import { isLeapYear } from 'date-fns';
import { find } from 'lodash-es';
import { Typography } from '@common/components/foundations/Typography';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import { TabBar } from '@common/components/molecules/TabsBar';
import { useAirbnbInsightsCache } from '@pages/Client/Dashboard/pages/Market/hooks/useAirbnbInsightsCache';
import { AirbnbChart } from '@pages/Client/Dashboard/pages/Market/AirbnbInsights/components/AirbnbChart';
import { AirbnbPickupChart } from '@pages/Client/Dashboard/pages/Market/AirbnbInsights/components/AirbnbPickupChart';
import { Dropdown } from '@common/components/molecules/Dropdown/Dropdown';
import {
  LAST_YEAR_OPTIONS,
  LastYearFilter,
  PERFORMANCE_OPTIONS,
  useDashboardPageStore
} from '@pages/Client/Dashboard/store/dashboard';
import { AirbnbDataCard } from '@pages/Client/Dashboard/pages/Market/AirbnbInsights/components/AirbnbDataCard';
import { Genie } from '@common/components/foundations/Logo';
import { Progress } from '@mantine/core';
import { useUserLocale } from '@common/hooks/useUserLocale';

export type AirbnbTabKey = 'occ' | 'adr' | 'revpar';
export type ViewKey = 'daily' | 'monthly' | 'yearly';

export const AirbnbMarketInsights = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Vacation Rental Market Intelligence'));
  const { view, liliusInstance, lastYearFilter, setLastYearFilter } = useDashboardPageStore();
  const { formatLocaleNumber } = useUserLocale();
  const [activeFinancialTab, setActiveFinancialTab] = useState(0);
  const [activePickupTab, setActivePickupTab] = useState(0);

  const {
    airbnbInsightsCache,
    getAllYears,
    query: { isLoading }
  } = useAirbnbInsightsCache();

  const tabLookup: Record<number, string> = {
    0: 'occ',
    1: 'adr',
    2: 'revpar'
  };

  const tabPickupLookup: Record<number, string> = {
    0: '1',
    1: '7',
    2: '30',
    3: '90'
  };

  const currentMonth = dayjs(liliusInstance?.viewing).tz().format('YYYY-MM');
  const currentMonthKey = dayjs(currentMonth).startOf('month').format('YYYY-MM-DD');

  const currentYear = dayjs(liliusInstance?.viewing).tz().format('YYYY');
  const currentYearKey = dayjs(currentYear).startOf('year').format('YYYY-MM-DD');

  const defaultViewKey = view === 'monthly' ? 'daily' : 'monthly';

  const selectedDateText = dayjs(liliusInstance?.viewing)
    .tz()
    .format(view === 'monthly' ? 'MMM YYYY' : 'YYYY');

  const selectedLastYearDateText = dayjs(liliusInstance?.viewing)
    .tz()
    .subtract(1, 'year')
    .format(view === 'monthly' ? 'MMM YYYY' : 'YYYY');

  const getAllDaysOfMonth = (month: string) => {
    const startDate = dayjs(month).startOf('month');
    const endDate = dayjs(month).endOf('month');
    const days = [];

    let currentDate = startDate;
    while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
      days.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
    return days;
  };

  const getAllMonthsOfYear = (year: string) => {
    const startDate = dayjs(year).startOf('year');
    const endDate = startDate.endOf('year');
    const months = [];

    let currentDate = startDate;
    while (currentDate.isSame(endDate) || currentDate.isBefore(endDate)) {
      months.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'month');
    }
    return months;
  };

  const calculateStlyDay = (day: string, type: LastYearFilter) => {
    const stlyDay = dayjs(day).subtract(1, 'year').format('YYYY-MM-DD');
    let addDays = 1;
    if (isLeapYear(dayjs(day).add(1, 'year').year()) && dayjs(day).month() > 1) {
      addDays = 2;
    }
    return type === 'DOW' ? dayjs(stlyDay).add(addDays, 'day').format('YYYY-MM-DD') : stlyDay;
  };

  type DateModifier = (date: string) => string;

  const getAirbnbInsightsData = (
    dates: string[],
    viewKey: ViewKey,
    modifyDateForStly: DateModifier
  ) => {
    return dates.map((date) => {
      const key = tabLookup?.[activeFinancialTab];
      const airbnbKey = `${tabLookup?.[activeFinancialTab]}_airdna`;
      const stlyKey = `${tabLookup?.[activeFinancialTab]}_stly`;
      const stlyDate = modifyDateForStly(date);

      return {
        date,
        value: airbnbInsightsCache?.[viewKey]?.[key]?.[date],
        airbnbValue: airbnbInsightsCache?.[viewKey]?.[airbnbKey]?.[date],
        stlyValue: airbnbInsightsCache?.[viewKey]?.[stlyKey]?.[stlyDate],
        demandScore: airbnbInsightsCache?.[viewKey]?.demScr?.[date],
        activeListings: airbnbInsightsCache?.[viewKey]?.['cntListings']?.[date],
        lastWeekActiveListings: airbnbInsightsCache?.[viewKey]?.['cntListings7']?.[date],
        listingsPctChange: airbnbInsightsCache?.[viewKey]?.['cntListingsPctChng']?.[date],
        nightsSold: airbnbInsightsCache?.[viewKey]?.['cntBooked_airdna']?.[date],
        lastWeekNightsSold: airbnbInsightsCache?.[viewKey]?.['cntBooked7_airdna']?.[date],
        nightsSoldPctChange: airbnbInsightsCache?.[viewKey]?.['cntBookedPctChng_airdna']?.[date],
        lengthOfStayLastYear: airbnbInsightsCache?.[viewKey]?.['los_airdna']?.[stlyDate],
        medianLeadTimeLastYear: airbnbInsightsCache?.[viewKey]?.['mlt_airdna']?.[stlyDate]
      };
    });
  };

  const getDailyAirbnbValues = (
    type: LastYearFilter = lastYearFilter,
    viewKey: ViewKey = defaultViewKey
  ) => {
    return getAirbnbInsightsData(getAllDaysOfMonth(currentMonth), viewKey, (date) =>
      calculateStlyDay(date, type)
    );
  };

  const getMonthlyAirbnbValues = (viewKey: ViewKey = defaultViewKey) => {
    return getAirbnbInsightsData(getAllMonthsOfYear(currentYear), viewKey, (date) =>
      dayjs(date).subtract(1, 'year').format('YYYY-MM-DD')
    );
  };

  const getYearlyAirbnbValues = (viewKey: ViewKey = defaultViewKey) => {
    return getAirbnbInsightsData(
      getAllYears().map((year) => dayjs(year.toString()).startOf('year').format('YYYY-MM-DD')),
      viewKey,
      (date) => dayjs(date).subtract(1, 'year').format('YYYY-MM-DD')
    );
  };

  const getDailyAirbnbPickupValues = (
    type: LastYearFilter = 'DTD',
    viewKey: ViewKey = defaultViewKey
  ) => {
    const getAirbnbPickupData = (dates: string[], viewKey: ViewKey) => {
      return dates.map((day) => {
        const key = `pickup_${tabPickupLookup?.[activePickupTab]}`;
        const airbnbKey = `pickup_${tabPickupLookup?.[activePickupTab]}_airdna`;
        const stlyKey = `pickup_${tabPickupLookup?.[activePickupTab]}_stly`;
        const pctKey = `pickup_${tabPickupLookup?.[activePickupTab]}_pct`;
        const airbnbPctKey = `pickup_${tabPickupLookup?.[activePickupTab]}_pct_airdna`;
        const stlyPctKey = `pickup_${tabPickupLookup?.[activePickupTab]}_pct_stly`;

        const stlyDay = calculateStlyDay(day, type);
        const today = dayjs().startOf('day');

        const disableOnTheBooks = () => {
          if (activePickupTab === 0) return dayjs(day).isBefore(today.subtract(1, 'day'), 'day');
          if (activePickupTab === 1) return dayjs(day).isBefore(today.subtract(7, 'day'), 'day');
          if (activePickupTab === 2) return dayjs(day).isBefore(today.subtract(30, 'day'), 'day');
          return false;
        };
        return {
          date: day,
          value: !disableOnTheBooks() ? airbnbInsightsCache?.[viewKey]?.[key]?.[day] : undefined,
          airbnbValue: airbnbInsightsCache?.[viewKey]?.[airbnbKey]?.[day],
          stlyValue: airbnbInsightsCache?.[viewKey]?.[stlyKey]?.[stlyDay],
          pctValue: !disableOnTheBooks()
            ? airbnbInsightsCache?.[viewKey]?.[pctKey]?.[day]
            : undefined,
          pctAirbnbValue: airbnbInsightsCache?.[viewKey]?.[airbnbPctKey]?.[day],
          pctStlyValue: airbnbInsightsCache?.[viewKey]?.[stlyPctKey]?.[stlyDay]
        };
      });
    };
    return getAirbnbPickupData(getAllDaysOfMonth(currentMonth), viewKey);
  };

  const getMonthlyAirbnbPickupValues = (viewKey: ViewKey = defaultViewKey) => {
    const getAirbnbPickupData = (dates: string[], viewKey: ViewKey) => {
      return dates.map((month) => {
        const key = `pickup_${tabPickupLookup?.[activePickupTab]}`;
        const airbnbKey = `pickup_${tabPickupLookup?.[activePickupTab]}_airdna`;
        const stlyKey = `pickup_${tabPickupLookup?.[activePickupTab]}_stly`;
        const pctKey = `pickup_${tabPickupLookup?.[activePickupTab]}_pct`;
        const pctAirbnbKey = `pickup_${tabPickupLookup?.[activePickupTab]}_pct_airdna`;
        const pctStlyKey = `pickup_${tabPickupLookup?.[activePickupTab]}_pct_stly`;

        const stlyMonth = dayjs(month).subtract(1, 'year').format('YYYY-MM-DD');

        return {
          date: month,
          value: airbnbInsightsCache?.[viewKey]?.[key]?.[month],
          airbnbValue: airbnbInsightsCache?.[viewKey]?.[airbnbKey]?.[month],
          stlyValue: airbnbInsightsCache?.[viewKey]?.[stlyKey]?.[stlyMonth],
          pctValue: airbnbInsightsCache?.[viewKey]?.[pctKey]?.[month],
          pctAirbnbValue: airbnbInsightsCache?.[viewKey]?.[pctAirbnbKey]?.[month],
          pctStlyValue: airbnbInsightsCache?.[viewKey]?.[pctStlyKey]?.[stlyMonth]
        };
      });
    };
    return getAirbnbPickupData(getAllMonthsOfYear(currentYear), viewKey);
  };

  const data = view === 'monthly' ? getDailyAirbnbValues() : getMonthlyAirbnbValues();
  const pickupData =
    view === 'monthly' ? getDailyAirbnbPickupValues() : getMonthlyAirbnbPickupValues();

  const cardData =
    view === 'monthly'
      ? find(getMonthlyAirbnbValues('monthly'), { date: currentMonthKey })
      : find(getYearlyAirbnbValues('yearly'), { date: currentYearKey });

  const cardInsights = [
    {
      key: 'airbnbScore',
      value: cardData?.demandScore ? `${Math.round(cardData.demandScore)}/10` : 'n.A.',
      label: t('Vacation Rental Demand Score'),
      tooltipDescription: (
        <TooltipIcon
          content={t(
            'Every market has a custom Demand Score ranging from 1-10, which is directly tied to the predicted occupancy rate on that day. A score of 1 represents low occupancy prediction (low demand), while a score of 10 represents high occupancy prediction.'
          )}
        />
      ),
      icon: <Genie className="h-16 fill-indigo" />,
      progressBar: (
        <Progress
          mt={5}
          value={cardData?.demandScore ? Math.round(cardData.demandScore) * 10 : 0}
        />
      )
    },
    {
      key: 'activeListings',
      value: cardData?.activeListings ? formatLocaleNumber(cardData.activeListings) : 'n.A.',
      label: t('Active Listings'),
      diff: cardData?.listingsPctChange,
      lastWeekValue: cardData?.lastWeekActiveListings
        ? formatLocaleNumber(cardData.lastWeekActiveListings)
        : 'n.A.',
      lastWeekLabel: t('Last Week'),
      tooltipDescription: (
        <TooltipIcon
          content={`${t(
            'Total number of active, unique properties on Airbnb & Vrbo in'
          )} ${selectedDateText}. ${t(
            "A property is considered active as long as there's at least 1 available unit for the month (if occupancy for the month <100%)."
          )}`}
        />
      )
    },
    {
      key: 'nightsSold',
      value: cardData?.nightsSold ? formatLocaleNumber(cardData.nightsSold) : 'n.A.',
      label: t('Nights Sold'),
      diff: cardData?.nightsSoldPctChange,
      lastWeekValue: cardData?.lastWeekNightsSold
        ? formatLocaleNumber(cardData.lastWeekNightsSold)
        : 'n.A.',
      lastWeekLabel: t('Last Week')
    },
    {
      key: 'bookingWindowLastYear',
      value: cardData?.medianLeadTimeLastYear
        ? formatLocaleNumber(Number(cardData.medianLeadTimeLastYear.toFixed(1)))
        : 'n.A.',
      label: `${t('Median Lead Time')} ${selectedLastYearDateText}`
    },
    {
      key: 'lengthOfStayLastYear',
      value: cardData?.lengthOfStayLastYear
        ? formatLocaleNumber(Number(cardData.lengthOfStayLastYear.toFixed(1)))
        : 'n.A.',
      label: `${t('Median Length of Stay')} ${selectedLastYearDateText}`
    }
  ];

  return (
    <div className="flex flex-col space-y-12">
      <div className="grid grid-flow-row gap-y-6">
        <div className="flex flex-col items-start justify-between md:flex-row md:items-end">
          <div className="flex items-end gap-1.5">
            <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
              {t('Vacation Rental Market')} {selectedDateText}
            </Typography>
            <TooltipIcon
              iconClassName="mb-1"
              content={t(
                `Our short-term rental intelligence leverages comprehensive data from Vrbo and Airbnb, encompassing over 10 million properties in 120,000 global markets. This allows you to understand your property's performance compared to the surrounding Vacation Rental market. Analyze crucial Vacation Rental market data, including occupancy, average daily rate (ADR), revenue per available room (RevPAR), and pickup, to detect and respond to market trends effectively.`
              )}
            />
          </div>
          <div className="flex items-end gap-2" style={{ minHeight: 60 }}>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                disabled
                label={t('Tax Mode') as string}
                selectedOption={PERFORMANCE_OPTIONS[0]}
                options={PERFORMANCE_OPTIONS.map((option) => ({
                  label: t(option.label) as string,
                  value: option.value
                }))}
                onOptionClick={() => null}
              />
            </div>
            {view === 'monthly' ? (
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  label={t('Last Year Comparison') as string}
                  selectedOption={find(LAST_YEAR_OPTIONS, { value: lastYearFilter })}
                  options={LAST_YEAR_OPTIONS.map((option) => ({
                    label: t(option.label) as string,
                    value: option.value
                  }))}
                  onOptionClick={(option) => setLastYearFilter(option.value)}
                  minWidth={180}
                  labelTooltipContent={
                    <div className="flex flex-col gap-y-4">
                      <div>
                        {t(
                          `Day-of-Week: How are your days of the week performing compared to last year? A comparison of the day of week performances (Saturday this year to Saturday last year).`
                        )}
                      </div>
                      <div>
                        {t(
                          `Date-to-Date: How is the 31st of December performing compared to last year? A comparison of the calendar date performances (New Year's Eve this year to New Year's Eve last year).`
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex snap-x items-center justify-start gap-x-4 overflow-x-auto pb-1">
          {cardInsights.map((item) => (
            <AirbnbDataCard
              key={item.key}
              isLoading={isLoading}
              topLeftValue={item.value}
              topLeftText={item.label}
              topLeftTooltipIcon={item.tooltipDescription}
              diff={item.diff}
              bottomLeftValue={item.lastWeekValue}
              bottomLeftText={item.lastWeekLabel}
              icon={item?.icon}
              progressBar={item?.progressBar}
            />
          ))}
        </div>

        <div className="flex items-center gap-1.5">
          <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
            {t('Daily KPI Benchmarking {{date}}', { date: selectedDateText })}
          </Typography>
        </div>
        <TabBar
          type="linear"
          options={[
            { label: t('Occupancy'), value: 0 },
            { label: t('ADR'), value: 1 },
            { label: t('RevPar'), value: 2 }
          ]}
          activeTab={activeFinancialTab}
          onTabClick={setActiveFinancialTab}
        />
        <AirbnbChart
          type={tabLookup?.[activeFinancialTab] as AirbnbTabKey}
          data={data}
          isCurrency={tabLookup?.[activeFinancialTab] === 'occ' ? false : true}
          view={view}
        />
      </div>

      <div className="mt-12 flex flex-col gap-y-6">
        <div className="flex items-center gap-1.5">
          <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
            {t('Vacation Rental Pickup for')} {selectedDateText}
          </Typography>
          <TooltipIcon
            content={t(
              'Pickup is a representation of how many room nights were sold in a given time period in the past. For example, we might look at Pickup in the time period ’Yesterday’. Every booking made yesterday for a date in the future is Pickup from yesterday. This Pickup can be further filtered for certain stay-dates in the future. For example we might look at Pickup from yesterday for stay-date in March. Pickup is net/gross of cancellations; meaning that if we have 2 nights reserved and one night cancelled, Pickup will be 1/2 room night/s.'
            )}
          />
        </div>
        <TabBar
          type="linear"
          options={[
            { label: t('Yesterday'), value: 0 },
            { label: t('Last 7 Days'), value: 1 },
            { label: t('Last 30 Days'), value: 2 }
            // { label: t('Last 90 Days'), value: 3 }
          ]}
          activeTab={activePickupTab}
          onTabClick={(value) => {
            return setActivePickupTab(value);
          }}
        />
        <AirbnbPickupChart data={pickupData} view={view} />
      </div>
    </div>
  );
};
