import { ScheduleHistoryScrapeResponse } from '@common/api/amalgamation/types';
import { axiosInstance } from '@common/api/axiosInstance';
import axios, { AxiosError } from 'axios';

export const getScheduleHistory = async (page?: number) => {
  try {
    const response = await axiosInstance.get<ScheduleHistoryScrapeResponse>(
      '/amalgamation/scrape/history/schedule/',
      {
        params: {
          page
        }
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getDetailsScheduleHistory = async (
  id?: string,
  page?: number,
  search?: string,
  status?: number
) => {
  try {
    const response = await axiosInstance.get<ScheduleHistoryScrapeResponse>(
      `/amalgamation/scrape/history/schedule/detail/${id}/`,
      {
        params: {
          page,
          search,
          status
        }
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};

export const getScheduleOnDemand = async (page?: number, search?: string, status?: number) => {
  try {
    const response = await axiosInstance.get<ScheduleHistoryScrapeResponse>(
      `/amalgamation/scrape/history/ondemand/`,
      {
        params: {
          page,
          search,
          status
        }
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError;
    }
    throw error;
  }
};
