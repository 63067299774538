import { useMutation } from '@tanstack/react-query';
import { useNotificationsStore } from '@common/store/notifications';
import {
  RecoveryPasswordResponse,
  RecoveryPasswordInput,
  recoveryPassword,
  RecoveryPasswordErrorResponse
} from '@common/api/auth';
import { AxiosError } from 'axios';

export const useRecoveryPassword = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);

  return useMutation<
    RecoveryPasswordResponse,
    AxiosError<RecoveryPasswordErrorResponse>,
    RecoveryPasswordInput
  >({
    mutationFn: recoveryPassword,
    onSuccess: async (data) => {
      addNotification('success', data.message);
    },
    onError: (error) => {
      addNotification('fail', error.response?.data?.email[0] ?? 'Something went wrong');
    }
  });
};
