import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ViewState = {
  view: 'admin' | 'client';
  isAdmin: boolean;
  setView: (value: ViewState['view']) => void;
  isReadOnly: boolean;
  setIsReadOnly: (value: boolean) => void;
};

export const useViewStore = create<ViewState>()(
  persist(
    (set) => ({
      view: 'client',
      isAdmin: false,
      setView: (value) => {
        set({ view: value, isAdmin: value === 'admin' });
      },
      isReadOnly: false,
      setIsReadOnly: (value) => {
        set({ isReadOnly: value });
      }
    }),
    {
      name: 'viewStore',
      onRehydrateStorage: () => {
        return (state) => {
          if (state && state.view) {
            return state;
          }
        };
      }
    }
  )
);
