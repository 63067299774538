import { axiosInstance, setAuthToken } from '@common/api/axiosInstance';
import { updateHotelProperty } from '@common/api/hotel';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useNotificationsStore } from '@common/store/notifications';
import { useEffect } from 'react';

export const useHotelAuth = (token: string, id: string, options?: UseQueryOptions) => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const query = useQuery({
    queryKey: [HotelQueryKeys.HOTEL_AUTH, id],
    queryFn: async () => {
      setAuthToken(token);

      let { data } = await axiosInstance.post(`account/auth/hotel/${id}/`);
      data = {
        name: data.hotel.name,
        address: data.hotel.address,
        postcode: data.hotel.postcode,
        lat: data.hotel.lat,
        lng: data.hotel.lng,
        starts_monday: data.hotel.starts_monday,
        prefered_date_format: data.hotel.prefered_date_format,
        timezone: data.hotel.timezone,
        pms: data.hotel.pms,
        is_send_email: data.hotel.is_send_email,
        room_apartment_space: data.hotel.room_apartment_space,
        country_id: data.hotel.country.id,
        currency_id: data.hotel.currency.id,
        enc_hotel_id: data.enc_hotel_id,
        property_type: parseInt(data.hotel.property_type),
        business_leisure_ratio: data.hotel.business_leisure_ratio,
        event_types: data.hotel.event_types,
        event_radius: data.hotel.event_radius,
        review: 0,
        notes: '',
        booking_ref_url: data.hotel.booking_ref_url,
        token: data.token
      };
      return data;
    },
    ...options
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', t('Something went wrong. Please try again later.'));
    }
  }, [query.isError]);

  return {
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    refetch: query.refetch
  };
};

export const useSaveEvent = (token: string) => {
  const addNotification = useNotificationsStore((state) => state.addNotification);
  return useMutation({
    mutationFn: async () => {
      setAuthToken(token);
      try {
        const data = await axiosInstance.post('hotel/save_events_per_demand/');
        addNotification('success', 'Event Fetched Successfully');
        return data;
      } catch {
        addNotification('fail', 'Error Fetching Event');
      }
    }
  });
};

export const useHotelUpdate = (token: string) => {
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { query } = useHotelDetails();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      setAuthToken(token);
      try {
        const response = await updateHotelProperty(data);
        addNotification('success', 'Hotel Updated Successfully');
        query.refetch();
        queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.HOTEL_AUTH] });
        return response.data;
      } catch {
        addNotification('fail', 'Error Updating Hotel');
      }
    }
  });

  return mutation;
};
