import { ErrorResponse } from '@common/api/auth';
import { axiosInstance } from '@common/api/axiosInstance';
import { savePricingSettings } from '@common/api/hotel';
import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Divider } from '@common/components/atoms/Divider';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Switcher } from '@common/components/atoms/Switcher';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@common/components/molecules/Popoverv2/Popover';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Integration, integrations } from '@common/constants/integrations';
import { pmsHasRegionField, pmsHasRequestFullSync } from '@common/constants/pmsList';
import { PusherEventNames } from '@common/constants/pusher';
import { useModal } from '@common/hooks/useModal';
import { useAuthStore, useHotelStore } from '@common/store/auth';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useNotificationsStore } from '@common/store/notifications';
import { usePusherStore } from '@common/store/pusher';
import { useViewStore } from '@common/store/view';
import { FeaturesQueryKeys, PricingQueryKeys } from '@common/types/query-keys';
import { cn } from '@common/utils/cn';
import { isDevelopment } from '@common/utils/env';
import { Checkbox } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import ContactSupport from '@pages/Client/Account/Integrations/components/actions/ContactSupport';
import UpdateConnection from '@pages/Client/Account/Integrations/components/actions/UpdateConnection';
import ManageIframe from '@pages/Client/Account/Integrations/components/iframe/ManageIframe';
import { NewbookRoomMappingTable } from '@pages/Client/Account/Integrations/components/NewbookRoomMappingTable';
import { ReservationStatusMappingTable } from '@pages/Client/Account/Integrations/components/ReservationsStatusMappingTable';
import { RoomMappingTable } from '@pages/Client/Account/Integrations/components/RoomMappingTable';
import { RoomRatesTable } from '@pages/Client/Account/Integrations/components/RoomRatesTable';
import { RoomTypeTable } from '@pages/Client/Account/Integrations/components/RoomTypeTable';
import { useDisconnectPms } from '@pages/Client/Account/Integrations/hooks/useDisconnectPms';
import { useEnablePushPms } from '@pages/Client/Account/Integrations/hooks/useEnablePmsPush';
import { useHlsPmsInitRooms } from '@pages/Client/Account/Integrations/hooks/useHlsPmsInitRooms';
import { ProtelConn, useMasterList } from '@pages/Client/Account/Integrations/hooks/useMasterList';
import { useRegisterPms } from '@pages/Client/Account/Integrations/hooks/useRegisterPms';
import { useReprocessData } from '@pages/Client/Account/Integrations/hooks/useReprocessData';
import { useRequestFullSync } from '@pages/Client/Account/Integrations/hooks/useRequestFullSync';
import { useRetrieveBlockedRooms } from '@pages/Client/Account/Integrations/hooks/useRetrieveBlockedRoom';
import { useSabeePropertyList } from '@pages/Client/Account/Integrations/hooks/useSabeeProperty';
import { useStaahEnablePush } from '@pages/Client/Account/Integrations/hooks/useStaahEnablePush';
import { useSyncPmsUpdate } from '@pages/Client/Account/Integrations/hooks/useSyncPmsUpdate';
import { useUpdatePms } from '@pages/Client/Account/Integrations/hooks/useUpdatePms';
import { useInitRoomLoading } from '@pages/Client/Account/Integrations/store/initRoom';
import { useUnitStore } from '@pages/Client/Account/Integrations/store/unitRoom';
import {
  CloudBedsOptions,
  GetOptionsParams,
  METHOD,
  Payload,
  Tag
} from '@pages/Client/Account/Integrations/types/types';
import {
  useSetHotelState,
  useSetToTestMode
} from '@pages/Client/Account/Subscription/hooks/usePayment';
import { PriceCalculationLoadingModal } from '@pages/Client/Calendar/components/PriceCalculationLoadingModal';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import {
  useGetDetailProviderConfig,
  useGetProviderList
} from '@pages/Client/hooks/useProviderConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { set } from 'date-fns';
import { isNil, sortBy } from 'lodash-es';
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Integrations = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Integration'));

  const { view } = useViewStore();
  const { user, token, hotelIframeId } = useAuthStore();
  const { hotelAuthToken } = useHotelStore();
  const [popOverOpen, setPopOverOpen] = useState(false);
  const { setUnitRooms } = useUnitStore();
  const { isInitRoomLoading, setLoading } = useInitRoomLoading();

  const {
    hasRetrieveBlockedRooms,
    hasSandboxEnv,
    hasReservationPipelineV2,
    hasUploadAllRates,
    pmsHasnotSupportV2,
    hasPushStatus
  } = usePmsProvider();
  const queryClient = useQueryClient();
  const { setFeatures } = useFeaturesStore();
  const { hasRoomTypeTable, hasRoomRateTable } = useGetDetailProviderConfig();

  const { channel, channelBind, channelUnbind } = usePusherStore();
  const { addNotification } = useNotificationsStore();
  const { disconnectPms, isSuccess: isDisconnectSuccess } = useDisconnectPms();
  const { hlsPmsInitRooms, isLoading: isHlsInitRoomsLoading } = useHlsPmsInitRooms();
  const { retrieveBlockedRoom, isLoading: isRetrieveBlockedRoomsLoading } =
    useRetrieveBlockedRooms();

  const {
    isOpen: reprocessOpen,
    openModal: reprocessClick,
    closeModal: reprocessClose
  } = useModal();
  const {
    isOpen: connectionChangeOpen,
    openModal: connectionChangeClick,
    closeModal: connectionChangeClose
  } = useModal();

  const [showPassword, setShowPassword] = useState(false);
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [copySuccessId, setCopySuccessId] = useState<string | null>(null);

  const [propertyList, setPropertyList] = useState<{ label: string; value: string }[]>([]);
  const [agentList, setAgentList] = useState<{ label: string; value: string }[]>([]);
  const [pmsIntegrationFields, setPmsIntegrationFields] = useState<Integration['fields'] | null>(
    null
  );
  const [multiselectAdd, setMultiSelectAdd] = useState<Tag[]>([]);

  const removeTag = (index: number) => {
    setMultiSelectAdd(multiselectAdd.filter((el) => el.id !== index));
  };

  const handleCopy = (getValue: (fieldName: string) => string, fieldName: string) => {
    const valueToCopy = getValue(fieldName);
    if (valueToCopy) {
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => {
          setCopySuccessId(fieldName);
          setIsCopySuccess(true);
          setTimeout(() => {
            setIsCopySuccess(false);
            setCopySuccessId(null);
          }, 2000);
        })
        .catch(() => {
          setCopySuccessId(null);
          setIsCopySuccess(false);
        });
    }
  };

  const handleCopyOpenApiCredentials = () => {
    const propertyId = watch('hotel_code');
    const username = watch('username');
    const password = watch('password');
    const valueToCopy = `
Property ID: ${propertyId || 'N/A'}
Client ID: ${username || 'N/A'}
Client Secret : ${password || 'N/A'}
  `.trim();
    navigator.clipboard
      .writeText(valueToCopy)
      .then(() => {
        setIsCopySuccess(true);
        setTimeout(() => {
          setIsCopySuccess(false);
        }, 2000);
      })
      .catch(() => {
        setIsCopySuccess(false);
      });
  };

  const { handleSubmit, formState, control, setValue, watch, getValues, reset } = useForm({
    mode: 'onSubmit'
  });

  const watchProviderValue = watch('provider');

  const getPropertyList = useMutation({
    mutationFn: async ({
      path,
      method,
      payload
    }: {
      path: string | undefined;
      method: METHOD;
      payload: Payload;
    }) => {
      if (!path) return;
      try {
        const { data } = await axiosInstance.request({
          url: path,
          method,
          data: payload
        });
        return data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          throw err as AxiosError<ErrorResponse>;
        }
        throw err as ErrorResponse;
      }
    },
    onSuccess: (data) => {
      const getOptions = ({ data, provider }: GetOptionsParams) => {
        switch (provider) {
          case 70: // CloudBeds
            return data?.hotel_list.map((hotel: CloudBedsOptions) => ({
              value: hotel.property_code,
              label: `${hotel.property_name} (${hotel.property_code})`
            }));
          case 46: // eviVoo
            return data?.map((value: any) => ({ value, label: value }));
          case 27: // RMS
            return data?.properties?.map((item: { code: any; name: any }) => ({
              value: item.code,
              label: item.name
            }));
          case 20: // Channex
            return data?.property.map((item: { id: any; name: any }) => ({
              value: item.id,
              label: item.name
            }));
          case 49: // HLS
            return data?.map((item: { id: any; name: any }) => ({
              value: item.id,
              label: item.name
            }));
          case 82:
          case 1: // Lobbi
          case 84: // Bed24 V2 API
            return data?.properties?.map((item: { code: any; name: any }) => ({
              value: item.code,
              label: item.name
            }));
          default:
            return data?.map((property: { name: any; id: any }) => ({
              label: `${property.name} ${provider === 2 ? `(${property.id})` : ''}`,
              value: property.id
            }));
        }
      };
      const provider = watch('provider') || hotelDetails?.pms_provider;
      const propertyOptions = getOptions({
        data,
        provider
      });
      setPropertyList(sortBy(propertyOptions, 'label'));
    },
    onError: () => {
      setPropertyList([]);
    }
  });
  const getAgentList = useMutation({
    mutationFn: async ({
      path,
      method,
      payload
    }: {
      path: string | undefined;
      method: METHOD;
      payload: Payload;
    }) => {
      if (!path) return;
      try {
        const { data } = await axiosInstance.request({
          url: path,
          method,
          data: payload
        });
        return data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          throw err as AxiosError<ErrorResponse>;
        }
        throw err as ErrorResponse;
      }
    },
    onSuccess: (data) => {
      const agentListData = data?.map((item: any) => ({
        label: item.agent_name,
        value: `${item.agent_id}`
      }));
      if (watch('provider') === 27 || hotelDetails?.pms_provider === 27) {
        setAgentList([agentListData, ...masterList.agentList]);
      } else {
        setAgentList(agentListData);
      }
    },
    onError: () => {
      if (watch('provider') === 27 || hotelDetails?.pms_provider === 27) {
        setAgentList([...masterList.agentList]);
      } else {
        setAgentList([]);
      }
    }
  });

  //  React Query Hooks
  const {
    pmsList,
    query: { isFetching: isPmsListLoading, refetch: refetchPmsList }
  } = useGetHotelPmsList();
  const {
    hotelDetails,
    query: { isFetching: isHotelDetailsLoading }
  } = useHotelDetails();
  const masterList = useMasterList();
  const { registerPms, isLoading: isRegisterPmsLoading } = useRegisterPms();
  const { updatePms, isLoading: isUpdatePmsLoading } = useUpdatePms();
  const { syncPmsUpdate, isLoading: isSyncPmsUpdateLoading } = useSyncPmsUpdate();
  const { requestFullSync, isLoading: isRequestFullSyncLoading } = useRequestFullSync();
  const { reprocessData, isLoading: isReprocessDataLoading } = useReprocessData();
  const { staahEnablePush, isLoading: isStaahEnablePushLoading } = useStaahEnablePush();
  const { enablePushPms, isLoading: isEnablePushPmsLoading } = useEnablePushPms();
  const { sabeePropertyList } = useSabeePropertyList();
  const { setHotelState } = useSetHotelState();
  const setToTestHotel = useSetToTestMode();
  const {
    PROVIDER_OPTION,
    query: { isLoading: isGetProviderListLoading }
  } = useGetProviderList();
  const {
    pricingSettings,
    pricingSettingsQuery: { refetch: refetchPricingSettings }
  } = usePricingSettings();

  const isProtelOnPremSelected =
    watchProviderValue === PmsProvider.PROTEL && watch('region') === ProtelConn.ON_PREM;

  const isReservationPipelineV2 =
    pricingSettings?.features?.includes(Feature.ReservationPipelineV2) || isProtelOnPremSelected;

  useEffect(() => {
    if (hasReservationPipelineV2()) {
      setValue('use_v2_reservation_pipeline', isReservationPipelineV2 || false);
    }
  }, [isReservationPipelineV2, hasReservationPipelineV2]);

  const { mutateAsync: updateFeatures } = useMutation({
    mutationKey: [FeaturesQueryKeys.UPDATE_FEATURES],
    mutationFn: savePricingSettings,
    onSuccess: (data) => {
      addNotification('success', 'Updated Reservation Pipeline V2 status successfully');
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
      setFeatures(data.data.features);
    }
  });

  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  const isOpenApiPms = useCallback(() => {
    return typeof watchProviderValue === 'string' && watchProviderValue.startsWith('O-');
  }, [watchProviderValue]);

  const isOpenApiUpdate = useCallback(() => {
    if (typeof watchProviderValue === 'string') {
      const openApiId = watchProviderValue.slice(2);
      return pmsList?.some((item) => item.open_api_partner_id === Number(openApiId));
    }
    return false;
  }, [watchProviderValue, pmsList]);

  const openPartnerId = typeof watchProviderValue === 'string' ? watchProviderValue.slice(2) : 0;
  const selectedPms =
    pmsList?.find((item) => item.provider === watchProviderValue) ||
    pmsList?.find((item) => item.open_api_partner_id === Number(openPartnerId));
  const pmsIntegration = integrations.find((item) => item.key === watchProviderValue);
  const activePmsProviderId = pmsList?.find((item) => item.primary)?.provider;
  const isUpdatePms =
    (hotelDetails?.pms_provider != null && hotelDetails.pms_provider === watchProviderValue) ||
    isOpenApiUpdate() ||
    pmsList?.some((item) => item.provider === watchProviderValue);

  const pmsId = pmsList?.find((obj) => obj.provider === watchProviderValue)?.id;

  const isProtelInpAir =
    activePmsProviderId === PmsProvider.PROTEL &&
    pmsList?.find((item) => item.primary)?.region === 2;

  const isNewbookPms = activePmsProviderId === PmsProvider.NEWBOOK;

  const isRpgAdmin = user?.is_staff && isNil(user?.sub_account_type);

  const isShowProcessData =
    isRpgAdmin && [22, 34, 41, 45, 48, 49, 50, 53, 54, 57, 58, 67].includes(watchProviderValue)
      ? true
      : false;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    const value = e.currentTarget.value;
    if (!value.trim()) return;
    // Check if the value already exists in the array
    const isDuplicate = multiselectAdd.some((item) => item.value === value);
    if (isDuplicate) {
      // Handle duplicate value, such as showing an error message
      return;
    }
    setMultiSelectAdd([...multiselectAdd, { id: multiselectAdd.length, value }]);
    e.currentTarget.value = '';
    setValue('extra', '');
  };

  // PusherIntegration
  const initIntegrationPusher = useCallback(() => {
    channelBind(PusherEventNames.ConnectionUpdateMessage, (data: any) => {
      if (data.success) {
        const message = `${channelManagerOrPms} Connection Updated Successfully`;
        addNotification('success', message);
      }
      if (!data.success) {
        const message = data.message ?? `${channelManagerOrPms} Connection Update Failed`;
        addNotification('fail', message);
      }
    });
    channelBind(PusherEventNames.InitPmsSyncMessage, (data: any) => {
      if (data.success) {
        const message = data.message ?? `Init ${channelManagerOrPms} Sync Successfully`;
        addNotification('success', message);
      }
      if (!data.success) {
        const message = data.message ?? `Init ${channelManagerOrPms} Sync Failed`;
        addNotification('fail', message);
      }
    });
    channelBind(PusherEventNames.InitPmsConfigMessage, (data: any) => {
      if (data.success) {
        const message = data.message ?? `Init ${channelManagerOrPms} Config Successfully`;
        addNotification('success', message);
        refetchPmsList();
      }
      if (!data.success) {
        const message = data.message ?? `Init ${channelManagerOrPms} Config Failed`;
        addNotification('fail', message);
      }
    });
    channelBind(PusherEventNames.InitPmsRoomsMessage, (data: any) => {
      if (data.success) {
        const unitNames = data?.data?.room_units?.map(
          ({ id, unit }: { id: number | string | null; unit: string }) => {
            return {
              value: id,
              label: unit
            };
          }
        );
        setUnitRooms(unitNames);
        const message = data.message ?? `Room units initialized successfully`;
        addNotification('success', message);
        setLoading(false);
      }
      if (!data.success) {
        const message = data.message ?? `Room units initialization failed`;
        addNotification('fail', message);
        setLoading(false);
      }
    });
    channelBind(PusherEventNames.SabeepropertyMessage, (data: any) => {
      if (data.success) {
        sabeePropertyList({
          username: watch('username')
        });
      }
    });
    channelBind(PusherEventNames.RetrieveBlockedRooms, (data: any) => {
      if (data.success) {
        const message = data.message ?? `Successfully processed blocked rooms`;
        addNotification('success', message);
        refetchPmsList();
      }
      if (!data.success) {
        const message = data.message ?? `Failed to process blocked rooms`;
        addNotification('fail', message);
      }
    });
  }, [channel]);

  useEffect(() => {
    channel && initIntegrationPusher();
    return () => {
      channelUnbind(PusherEventNames.ConnectionUpdateMessage);
      channelUnbind(PusherEventNames.InitPmsSyncMessage);
      channelUnbind(PusherEventNames.InitPmsConfigMessage);
      channelUnbind(PusherEventNames.InitPmsRoomsMessage);
      channelUnbind(PusherEventNames.SabeepropertyMessage);
      channelUnbind(PusherEventNames.RetrieveBlockedRooms);
    };
  }, [channel]);

  useEffect(() => {
    if (!pmsList || !hotelDetails) return;
    const getPrimary = pmsList?.find((item) => item.primary === true);
    const selectedPms = getPrimary;
    if (selectedPms?.provider === 0 || hotelDetails?.pms_provider === 0) {
      setValue('provider', `O-${selectedPms?.open_api_partner_id}`);
    } else {
      setValue('provider', selectedPms?.provider || hotelDetails?.pms_provider);
    }
    setValue('integration', hotelDetails.pms_sync || 1);
    setValue('primary', selectedPms?.primary || false);
    setValue('use_v2_integration', selectedPms?.use_v2_integration || false);
  }, [pmsList, hotelDetails]);

  useEffect(() => {
    if (isOpenApiPms()) {
      setPmsIntegrationFields(null);
    }
    if (!pmsIntegration) return;
    if (!isOpenApiPms()) {
      setPmsIntegrationFields(pmsIntegration?.fields);
    }
  }, [pmsIntegration, isOpenApiPms()]);

  useEffect(() => {
    if (isDisconnectSuccess) {
      setPmsIntegrationFields(null);
    }
  }, [isDisconnectSuccess]);

  useEffect(() => {
    if (!pmsList || !pmsIntegrationFields) return;

    pmsIntegrationFields?.map((field) => {
      if (!field.key) return;
      setValue(field.key, selectedPms ? selectedPms[field.key] : '');
      if (watchProviderValue === 71) {
        setValue('extra', selectedPms?.configurations?.price_type || '');
      }
      if (hasUploadAllRates(watch('provider'))) {
        setValue('upload_all_rates', selectedPms?.configurations?.upload_all_rates || 'disabled');
      }
      if ([25, 26].includes(watchProviderValue)) {
        setValue('extra', '');
        const multiTags: Tag[] = selectedPms?.extra
          ?.split(',')
          .map((value: any, index: any) => ({ id: index, value }));
        setMultiSelectAdd(multiTags);
      }
      if (watchProviderValue === 15) {
        setValue('extra', selectedPms?.extra || '1');
      }

      if (watchProviderValue === 61) {
        const operaCloudExtraData = JSON.parse(selectedPms?.extra || '{}');
        setValue('client_id', operaCloudExtraData?.client_id);
        setValue('availability_count_type', operaCloudExtraData?.availability_count_type);
      }
      setValue('integration', hotelDetails?.pms_sync || 1);
    });

    if (!pmsIntegrationFields?.find((field) => field.type === 'btn' && field.endpoint)) return;

    // Call get properties when pmd id 70, 46, 2
    if ([70, 46, 2, 27, 49, 82, 1].includes(watch('provider'))) {
      const endpoint = pmsIntegrationFields.find(
        (field) => field.type === 'btn' && field.property && field.endpoint
      )?.endpoint;

      if (endpoint) {
        getPropertyList.mutate({
          path: endpoint.path,
          method: endpoint.method as METHOD,
          payload: {
            ...endpoint.fields.reduce(
              (result, key) => {
                return {
                  ...result,
                  [key]: watch(key)
                };
              },
              {} as Record<string, string>
            ),
            token: endpoint.path.includes('/hotel/')
              ? selectedPms?.provider === 2
                ? selectedPms?.token
                : hotelAuthToken
              : token
          }
        });
      }
    }

    if (hotelDetails?.pms_provider === PmsProvider.BED_24_V2_API) {
      getPropertyList.mutate({
        path: pmsIntegrationFields.find(
          (field) => field.type === 'btn' && field.endpoint && !field.endpoint.connection
        )?.endpoint?.path,
        method: pmsIntegrationFields.find((field) => field.type === 'btn' && field.endpoint)
          ?.endpoint?.method as METHOD,
        payload: {
          ...pmsIntegrationFields
            .find((field) => field.type === 'btn' && field.endpoint)
            ?.endpoint?.fields.reduce(
              (result, key) => {
                return {
                  ...result,
                  [key]: watch(key)
                };
              },
              {} as Record<string, string>
            ),
          token: hotelAuthToken
        }
      });
    }
  }, [pmsIntegrationFields, selectedPms]);

  useEffect(() => {
    // Hotel With Multiple Property
    if ([70, 46, 2, 82, 84, 1].includes(watchProviderValue) && propertyList?.length > 0) {
      if (selectedPms?.hotel_code) {
        setValue('hotel_code', selectedPms?.hotel_code?.split(','));
      }
    }
    if (watchProviderValue === PmsProvider.RMS && propertyList?.length > 0) {
      setValue('hotel_code', selectedPms?.hotel_code?.split(','));
    }

    if ([49].includes(watchProviderValue) && propertyList?.length > 0) {
      if (selectedPms?.hotel_code) {
        setValue('hotel_code', selectedPms?.hotel_code);
      }
    }
  }, [propertyList, selectedPms?.hotel_code, watchProviderValue]);

  useEffect(() => {
    const isOpenApi = typeof watchProviderValue === 'string' && watchProviderValue.startsWith('O-');
    if (isOpenApi) {
      const openApiId = watchProviderValue.slice(2);
      const targetItem = pmsList?.find((item) => item.open_api_partner_id === Number(openApiId));

      const shouldUpdateFields = targetItem && isOpenApiUpdate();

      const fieldsToUpdate = shouldUpdateFields
        ? {
            hotel_code: targetItem.hotel_code,
            username: targetItem.username,
            password: targetItem.password
          }
        : { hotel_code: '', username: '', password: '' };

      if (shouldUpdateFields) {
        Object.entries(fieldsToUpdate).forEach(([key, value]) => setValue(key, value));
      }
    }
  }, [watchProviderValue, pmsList, setValue, isOpenApiUpdate]);

  const handleSubmitPms = (data: any) => {
    const { btnkey, btnkey_2, ...filteredPayload } = data;
    const { region, ...mewsPayload } = filteredPayload;

    const additionalPayload = {
      room_apartment_space_name: hotelDetails?.room_apartment_space_name,
      Authtoken: hotelAuthToken,
      primary: true
    };
    const sabeePms = {
      configurations: {
        price_type: data.extra
      },
      extra: ''
    };
    const multiSelectAddPayload = {
      extra: multiselectAdd ? multiselectAdd.map((item) => item.value).join(',') : ''
    };
    const checkboxWebhotelier = {
      extra: String(watch('extra'))
    };
    const operaCloud = {
      extra: JSON.stringify({
        client_id: watch('client_id'),
        availability_count_type: watch('availability_count_type')
      })
    };

    // Prevent duplicate property code
    function processHotelCode(data: any, propertyList: { label: string; value: string }[]) {
      if (!Array.isArray(data?.hotel_code)) {
        return null;
      }
      const uniqueHotelCodes = new Set<string>();
      data.hotel_code.forEach((value: string) => {
        if (propertyList.some((property) => value?.includes(property.value))) {
          uniqueHotelCodes.add(value);
        }
      });

      return [...uniqueHotelCodes].join(',');
    }

    const multiSelectPropertyPayload = {
      hotel_code: processHotelCode(data, propertyList) ?? (selectedPms?.hotel_code || null)
    };

    const dataPayload = {
      ...(watchProviderValue === 2 ? mewsPayload : filteredPayload),
      ...additionalPayload,
      ...(isUpdatePms && watchProviderValue === 71 ? sabeePms : null),
      ...([25, 26].includes(watchProviderValue) ? multiSelectAddPayload : null),
      ...([70, 46, 2, 82, 84, 1].includes(watchProviderValue) ||
      (watchProviderValue === 27 && watch('use_v2_integration'))
        ? multiSelectPropertyPayload
        : null),
      ...(watchProviderValue === 28 ? checkboxWebhotelier : null),
      ...(watchProviderValue === 61 ? operaCloud : null),
      use_v2_integration: watch('use_v2_integration') || false
    };

    const openApiPayload = {
      hotel_code: data?.hotel_code,
      provider: 0,
      open_api_partner_id: isOpenApiPms() ? watchProviderValue?.slice(2) : watchProviderValue
    };

    if (
      !pmsHasRegionField.includes(watchProviderValue) ||
      (watchProviderValue === PmsProvider.RMS && watch('use_v2_integration'))
    ) {
      delete dataPayload.region;
    }
    const dataUpdatePayload = {
      id: pmsId,
      payload: dataPayload
    };
    const openApiDataUpdatePayload = {
      id: selectedPms?.id,
      payload: {
        ...openApiPayload,
        ...data,
        provider: 0
      }
    };

    if (isUpdatePms) {
      if (isOpenApiPms()) {
        updatePms(openApiDataUpdatePayload);
      } else {
        updatePms(dataUpdatePayload);
      }
    } else {
      if (isOpenApiPms()) {
        registerPms(openApiPayload);
      } else {
        registerPms(dataPayload);
      }
    }

    syncPmsUpdate({
      Authtoken: hotelAuthToken,
      pms_sync: watch('integration')
    });
  };

  const handleConnectionChange = async (checked: boolean) => {
    if (!checked) {
      connectionChangeClick();
    }
    const data = watch();
    handleSubmitPms(data);
  };

  const onSubmit = (data: any) => {
    handleSubmitPms(data);
  };

  const handleUploadAllRates = async (enable: 'enabled' | 'disabled') => {
    await updatePms({
      id: pmsId,
      payload: {
        provider: watchProviderValue,
        room_apartment_space_name: hotelDetails?.room_apartment_space_name as string,
        Authtoken: hotelAuthToken as string,
        primary: true,
        configurations: {
          upload_all_rates: enable
        }
      }
    });
  };
  const handleEnablePushPms = (isEnabled: boolean) => {
    const providerId = watch('provider');
    const isStaah = providerId === PmsProvider.STAAH;
    if (isStaah) {
      staahEnablePush(isEnabled);
    } else {
      enablePushPms(isEnabled);
    }
  };

  const handleReservationPipelineV2Change = async (checked: boolean) => {
    const { data: latestPricingSettings } = await refetchPricingSettings();

    if (!latestPricingSettings?.features) {
      return addNotification('fail', 'Could not get features array');
    }

    let updatedFeatures = [...latestPricingSettings.features];

    if (checked) {
      if (!updatedFeatures.includes(Feature.ReservationPipelineV2)) {
        updatedFeatures.push(Feature.ReservationPipelineV2);
      }
    } else {
      updatedFeatures = updatedFeatures.filter(
        (feature) => feature !== Feature.ReservationPipelineV2
      );
    }

    const updatedSettings = {
      ...latestPricingSettings,
      features: updatedFeatures
    };

    await updateFeatures(JSON.stringify(updatedSettings));
  };

  const handleReset = () => {
    const allValues = getValues();
    const newValues = Object.keys(allValues).reduce<any>((acc, key) => {
      if (key === 'provider' || key === 'integration') {
        acc[key] = allValues[key];
      } else {
        acc[key] = '';
      }
      return acc;
    }, {});
    setPmsIntegrationFields(null);
    reset(newValues);
  };

  const isPmsConnected = useCallback(() => {
    const primaryValue = watch('primary');
    return !isNil(hotelDetails?.pms_provider) && Boolean(primaryValue);
  }, [hotelDetails, watch]);

  return (
    <>
      <div className="mb-2 flex items-center justify-between gap-4 md:mb-6">
        <Typography element="h2" variant="h5" color="darkGrey">
          {t('Integration')}
        </Typography>

        <div className="flex items-center justify-normal gap-4 md:justify-end">
          <Switcher
            size="small"
            checked={isPmsConnected()}
            onChange={(e) => {
              if (!e) {
                connectionChangeClick();
              } else {
                handleConnectionChange(e);
              }
            }}
            label={t('Connected') as string}
          />
          {view === 'admin' && hasUploadAllRates(watch('provider')) ? (
            <Controller
              control={control}
              name="upload_all_rates"
              render={({ field: { value } }) => (
                <Switcher
                  size="small"
                  checked={value === 'enabled'}
                  onChange={(e) => {
                    if (!e) {
                      handleUploadAllRates('disabled');
                    } else {
                      handleUploadAllRates('enabled');
                    }
                  }}
                  label={t('Upload All Rates') as string}
                />
              )}
            />
          ) : null}
          {isRpgAdmin && hasSandboxEnv(watchProviderValue) ? (
            <Switcher
              size="small"
              checked={hotelDetails?.state === 10}
              onChange={(e) => {
                if (!e) {
                  setToTestHotel();
                  setHotelState({
                    state: 8
                  });
                } else {
                  setHotelState({
                    state: 10
                  });
                }
              }}
              label={t('Sandbox Hotel') as string}
            />
          ) : null}
          {isRpgAdmin ? (
            <>
              {!isOpenApiPms() || isDevelopment() ? (
                <Controller
                  control={control}
                  name="use_v2_integration"
                  render={({ field: { value, onChange, name } }) => (
                    <Checkbox
                      label={<div className="flex gap-2">{t('ARI Integration V2')} </div>}
                      checked={value}
                      onChange={(e) => {
                        if (!e.target.checked && watch('provider') === 27) {
                          setValue('hotel_code', `${selectedPms?.hotel_code[0]}` || '');
                        }
                        onChange(!value);
                      }}
                      name={name}
                    />
                  )}
                />
              ) : null}
              {hasReservationPipelineV2() ? (
                <Controller
                  control={control}
                  name="use_v2_reservation_pipeline"
                  render={({ field: { value, onChange, name } }) => (
                    <Checkbox
                      label={<div className="flex gap-2">{t('Reservation Pipeline V2')} </div>}
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        handleReservationPipelineV2Change(e.target.checked);
                      }}
                      name={name}
                      disabled={isProtelOnPremSelected}
                    />
                  )}
                />
              ) : null}
            </>
          ) : null}
          {/* If Hotel PMS Apaleo */}
          <UpdateConnection />
          {/* Manage Iframe */}
          <ManageIframe />
          {/* TODO: Open Hubspot Chatbot */}
          <ContactSupport />
          {/* Request Full Sync */}
          {view === 'admin' && pmsHasRequestFullSync.includes(pmsIntegration?.key as number) ? (
            <Button
              type="button"
              isLoading={isRequestFullSyncLoading}
              intent="outline"
              disabled={!pmsHasRequestFullSync.includes(activePmsProviderId as number)}
              onClick={() => {
                requestFullSync(watchProviderValue);
              }}
            >
              {t(`Request Full Sync from ${channelManagerOrPms}`)}
            </Button>
          ) : null}
          {/* Push Status */}
          {(view === 'admin' && hasPushStatus().includes(watch('provider'))) ||
          (watch('provider') === 27 && watch('use_v2_integration')) ? (
            <>
              {isStaahEnablePushLoading || isPmsListLoading || isEnablePushPmsLoading ? (
                <div className="flex justify-center gap-2">
                  <Icon.LoadingCircle className="h-4 w-4" />
                  <Typography className="text-[14px] font-medium leading-[14px]">
                    Push Status
                  </Typography>
                </div>
              ) : (
                <Switcher
                  size="small"
                  disabled={isStaahEnablePushLoading}
                  checked={selectedPms?.configurations?.push_enabled === 'enabled' ? true : false}
                  onChange={handleEnablePushPms}
                  label={t('Push Status') as string}
                />
              )}
            </>
          ) : null}
          {/* TODO: Only for some PMS */}
          {isShowProcessData ? (
            <Button
              type="button"
              intent="outline"
              onClick={reprocessClick}
              isLoading={isReprocessDataLoading}
            >
              {t('Reprocess Data')}
            </Button>
          ) : null}
          {/* Action Button */}
          {view === 'admin' && hasRetrieveBlockedRooms() ? (
            <div>
              <Popover open={popOverOpen} onOpenChange={(open) => setPopOverOpen(open)}>
                <div>
                  <PopoverTrigger asChild>
                    <Button intent="outline">{t('Actions')}</Button>
                  </PopoverTrigger>
                </div>
                <PopoverContent side="bottom" align="end" className="w-fit bg-white p-4">
                  <div className="space-y-5">
                    {/* Hls Init Rooms */}
                    {[49].includes(pmsIntegration?.key as number) ? (
                      <Button
                        type="button"
                        isLoading={isHlsInitRoomsLoading}
                        intent="outline"
                        className="w-full text-darkGrey"
                        onClick={() => {
                          hlsPmsInitRooms();
                        }}
                      >
                        {t(`HLS Init Rooms`)}
                      </Button>
                    ) : null}
                    {hasRetrieveBlockedRooms() ? (
                      <Button
                        type="button"
                        intent="outline"
                        isLoading={isRetrieveBlockedRoomsLoading}
                        className="w-full text-darkGrey"
                        onClick={() => {
                          retrieveBlockedRoom();
                        }}
                      >
                        {t(`Retrieve Closed Rooms`)}
                      </Button>
                    ) : null}
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          ) : null}
          {view === 'admin' ? (
            <Button
              isLoading={isRegisterPmsLoading || isUpdatePmsLoading || isSyncPmsUpdateLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t('Update')}
            </Button>
          ) : null}
        </div>
      </div>
      <Divider />
      <div className="mt-6 max-w-4xl rounded-md bg-white p-6">
        {isHotelDetailsLoading || isPmsListLoading || isGetProviderListLoading ? (
          <div className="flex flex-col  gap-y-8">
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <Skeleton className="h-4 w-40" />
                <Skeleton className="h-8" />
              </div>
              <div className="flex flex-col gap-y-2">
                <Skeleton className="h-4 w-40" />
                <Skeleton className="h-8" />
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <Skeleton className="h-4 w-40" />
                <Skeleton className="h-8" />
              </div>
              <div className="flex flex-col gap-y-2">
                <Skeleton className="h-4 w-40" />
                <Skeleton className="h-8" />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col gap-y-8 ">
            <div className="flex flex-col gap-y-4">
              <Controller
                control={control}
                name="provider"
                rules={{ required: true }}
                render={({ field: { value, onChange, name } }) => (
                  <>
                    <SelectDropdown
                      fullWidth
                      hint={t('PMS *') as string}
                      background="grey"
                      name={name}
                      value={value?.toString()}
                      options={PROVIDER_OPTION}
                      disableOptionsValue={[
                        PmsProvider.BEDS24_NEW,
                        PmsProvider.HOTEL_SPIDER_DEPRECATED
                      ]}
                      onChange={(value) => {
                        if (pmsHasnotSupportV2(value)) {
                          setValue('use_v2_integration', false);
                        }
                        if (value?.startsWith('O-')) {
                          onChange(value);
                          setPmsIntegrationFields(null);
                        } else {
                          onChange(parseInt(value));
                        }
                        handleReset();
                      }}
                    />
                    <div>
                      {formState.errors['provider'] && (
                        <InputHelperMessage
                          icon={<Icon.WarningOutline className="h-4 w-4 text-error" />}
                          message={t('This field is required') as string}
                        />
                      )}
                    </div>
                  </>
                )}
              />

              <Controller
                control={control}
                name="integration"
                render={({ field: { value, onChange, name } }) => (
                  <SelectDropdown
                    fullWidth
                    hint={t('Integration *') as string}
                    background="grey"
                    name={name}
                    value={value}
                    options={masterList?.integration}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div className="flex  flex-col gap-y-4">
              {isOpenApiPms() ? (
                <>
                  <Controller
                    control={control}
                    name="hotel_code"
                    render={({ field: { value, onChange, name } }) => (
                      <Input
                        label={t('PropertyID') as string}
                        background="grey"
                        name={name}
                        value={value}
                        className={'read-only:cursor-text read-only:text-opacity-100'}
                        onChange={onChange}
                        type="text"
                        showClearButton={false}
                        placeholder={`${t('Click Update to auto-generate')}`}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="username"
                    render={({ field: { value, onChange, name } }) => (
                      <Input
                        label={t('Client ID') as string}
                        background="grey"
                        name={name}
                        readOnly={true}
                        value={value}
                        className={'read-only:cursor-text read-only:text-opacity-100'}
                        onChange={onChange}
                        type="text"
                        showClearButton={false}
                        placeholder={`${t('Click Update to auto-generate')}`}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="password"
                    render={({ field: { value, onChange, name } }) => (
                      <Input
                        label={t('Client Secret') as string}
                        background="grey"
                        name={name}
                        value={value}
                        placeholder={`${t('Click Update to auto-generate')}`}
                        className={`read-only:cursor-text read-only:text-opacity-100 ${
                          !showPassword ? 'text-security-disc' : ''
                        }`}
                        readOnly={true}
                        onChange={onChange}
                        type="text"
                        trailingAddon={
                          <button type="button" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                              <Icon.Visible className="h-5 w-5" />
                            ) : (
                              <Icon.Hidden className="h-5 w-5" />
                            )}
                          </button>
                        }
                        showClearButton={false}
                      />
                    )}
                  />
                  <Button
                    type="button"
                    intent="outline"
                    className="mt-3"
                    onClick={() => {
                      handleCopyOpenApiCredentials();
                    }}
                  >
                    {isCopySuccess ? 'Copied!' : 'Copy Credentials'}
                  </Button>
                </>
              ) : null}

              {pmsIntegrationFields?.map((field) => {
                const key = field.key || field.label;
                if (!field.key) return;
                let inputElement;
                if (field.type === 'text') {
                  inputElement = (
                    <Controller
                      control={control}
                      name={key}
                      rules={{ required: !field.noValidate, minLength: 1 }}
                      render={({ field: { value, onChange, name } }) => (
                        <div className="w-full ">
                          <div className="grid grid-cols-12 items-center gap-2">
                            <div className={cn(field?.copyable ? 'col-span-11' : 'col-span-12')}>
                              <Input
                                label={
                                  t(`${field.label} ${!field.noValidate ? '*' : ''}`) as string
                                }
                                background="grey"
                                name={name}
                                value={value}
                                placeholder={field?.placeholder ? field.placeholder : ''}
                                className={`read-only:cursor-text read-only:text-opacity-100 ${
                                  field.password && !showPassword ? 'text-security-disc' : ''
                                }`}
                                readOnly={field.password ? true : false || field.readonly}
                                onFocus={(e) => {
                                  if (field.readonly) return;
                                  if (field.password) {
                                    e.currentTarget.removeAttribute('readonly');
                                  }
                                }}
                                onChange={onChange}
                                type="text"
                                trailingAddon={
                                  field.password && (
                                    <button
                                      type="button"
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? (
                                        <Icon.Visible className="h-5 w-5" />
                                      ) : (
                                        <Icon.Hidden className="h-5 w-5" />
                                      )}
                                    </button>
                                  )
                                }
                                showClearButton={false}
                              />
                            </div>
                            <div
                              className={cn(
                                'group relative col-span-1 mt-6',
                                field?.copyable ? 'block' : 'hidden'
                              )}
                            >
                              <TooltipProvider>
                                <Tooltip delayDuration={200}>
                                  <TooltipTrigger>
                                    <Button
                                      type="button"
                                      icon
                                      intent="primary"
                                      size="small"
                                      onClick={() => handleCopy(watch, `${name}`)}
                                    >
                                      {isCopySuccess && copySuccessId === `${name}` ? (
                                        <Icon.CheckList className="h-5 w-5 text-uiGreen" />
                                      ) : (
                                        <Icon.Copy className="h-5 w-5 text-opacity-100" />
                                      )}
                                    </Button>
                                  </TooltipTrigger>
                                  <TooltipContent side="top" className="bg-white text-darkGrey">
                                    {isCopySuccess ? 'Copied!' : 'Copy'}
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  );
                }

                if (field.type === 'btn' && field.property) {
                  inputElement = (
                    <div className="my-3">
                      <Button
                        intent="outline"
                        type="button"
                        disabled={(() => {
                          const providerValue = hotelDetails?.pms_provider;
                          if ([84].includes(watchProviderValue)) {
                            if (![84].includes(providerValue as number)) {
                              return true;
                            }
                          }
                          return false;
                        })()}
                        isLoading={!field.endpoint?.connection ? getPropertyList.isPending : false}
                        onClick={() => {
                          const endpoint = pmsIntegrationFields.find(
                            (field) => field.type === 'btn' && field.property && field.endpoint
                          )?.endpoint;

                          getPropertyList.mutate(
                            {
                              path: endpoint?.path,
                              method: endpoint?.method as METHOD,
                              payload: {
                                ...field.endpoint?.fields.reduce(
                                  (result, key) => {
                                    return {
                                      ...result,
                                      ...(watch('provider') === 27 && !watch('use_v2_integration')
                                        ? {
                                            client_id: watch('username'),
                                            client_password: watch('password')
                                          }
                                        : {}),
                                      [key]: watch(key)
                                    };
                                  },
                                  {} as Record<string, string>
                                ),
                                token:
                                  watch('provider') === 2
                                    ? watch('token')
                                    : field?.endpoint?.path.includes('/hotel/')
                                      ? hotelAuthToken
                                      : token
                              }
                            },
                            {
                              onError: (error: any) => {
                                if (error?.response?.data?.message) {
                                  addNotification('fail', error?.response?.data?.message);
                                } else {
                                  addNotification(
                                    'fail',
                                    'An Error Occurred While Getting Property List.'
                                  );
                                }
                              }
                            }
                          );
                        }}
                      >
                        {t(field.label)}
                      </Button>
                    </div>
                  );
                }
                if (field.type === 'btn' && field.endpoint?.connection) {
                  inputElement = (
                    <div className="my-3">
                      <Button
                        intent="outline"
                        type="button"
                        disabled={!watch('hotel_code')}
                        isLoading={getAgentList.isPending}
                        onClick={() => {
                          if (!field.endpoint) return;
                          if (field.endpoint.new_sso) {
                            window.open(
                              `${field.endpoint?.path}?hotel_id=${hotelDetails?.id}`,
                              '_blank'
                            );
                          } else {
                            window.open(
                              `${field.endpoint?.path}${hotelDetails?.id}/integrations/?init=${field.pms}`,
                              '_blank'
                            );
                          }
                        }}
                      >
                        {t(field.label)}
                      </Button>
                    </div>
                  );
                }
                if (field.type === 'multiSelectAdd') {
                  inputElement = (
                    <Controller
                      name={key}
                      control={control}
                      rules={{ required: !field.noValidate }}
                      render={({ field: { value, onChange } }) => (
                        <div className="space-y-3">
                          <Input
                            label={t(`${field.label} ${!field.noValidate ? '*' : ''}`) as string}
                            placeholder="Add properties and press enter .."
                            background="grey"
                            value={value}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                            showClearButton={false}
                          />
                          <ul className="space-x-3">
                            {multiselectAdd?.map((tag) => (
                              <Badge
                                key={tag.id}
                                textTransform="capitalize"
                                variant="rounded"
                                background="grey"
                                className="capitalize"
                              >
                                {tag.value}
                                <Button icon onClick={() => removeTag(tag.id)} className="ml-2">
                                  <Icon.Clear className="h-2 w-2" />
                                </Button>
                              </Badge>
                            ))}
                          </ul>
                        </div>
                      )}
                    />
                  );
                }
                if (field.type === 'checkbox') {
                  inputElement = (
                    <Controller
                      control={control}
                      name={key}
                      rules={{ required: !field.noValidate }}
                      render={({ field: { value, onChange, name } }) => (
                        <Checkbox
                          label={t(`${field.label}`)}
                          checked={value ? JSON.parse(value) : false}
                          onChange={onChange}
                          name={name}
                        />
                      )}
                    />
                  );
                }
                if (field.type === 'select') {
                  if (
                    ([27].includes(watch('provider')) && !watch('use_v2_integration')) ||
                    ![27].includes(watch('provider')) ||
                    ([27].includes(watch('provider')) &&
                      !watch('use_v2_integration') &&
                      field.key === 'region')
                  ) {
                    inputElement = (
                      <Controller
                        control={control}
                        name={key}
                        rules={{ required: !field.noValidate }}
                        render={({ field: { value, onChange, name } }) => (
                          <SelectDropdown
                            hint={t(`${field.label} ${!field.noValidate ? '*' : ''}`) as string}
                            placeholder={`Select ${field.label}`}
                            fullWidth
                            background="grey"
                            name={name}
                            value={value === 0 ? '0' : value}
                            onChange={onChange}
                            options={
                              field.options === 'propertyList'
                                ? propertyList
                                : field.options
                                  ? masterList[field.options as keyof typeof masterList]
                                  : []
                            }
                          />
                        )}
                      />
                    );
                  }
                }
                if (field.type === 'select' && field.options === 'agentList') {
                  if (
                    ([27].includes(watch('provider')) && !watch('use_v2_integration')) ||
                    ![27].includes(watch('provider')) ||
                    ([27].includes(watch('provider')) &&
                      !watch('use_v2_integration') &&
                      field.key === 'agentList')
                  ) {
                    inputElement = (
                      <Controller
                        control={control}
                        name={key}
                        rules={{ required: !field.noValidate }}
                        render={({ field: { value, onChange, name } }) => (
                          <SelectDropdown
                            hint={t(`${field.label} ${!field.noValidate ? '*' : ''}`) as string}
                            placeholder={`Select ${field.label}`}
                            fullWidth
                            background="grey"
                            name={name}
                            value={value === 0 ? '0' : value}
                            onChange={onChange}
                            options={agentList ? agentList : []}
                          />
                        )}
                      />
                    );
                  }
                }

                if (field.type === 'multiSelectProperty' && field.options === 'propertyList') {
                  inputElement = (
                    <Controller
                      control={control}
                      name={key}
                      rules={{
                        required:
                          [PmsProvider.RMS].includes(watch('provider')) &&
                          watch('use_v2_integration')
                            ? false
                            : !field.noValidate
                      }}
                      render={({ field: { value, onChange, name } }) => (
                        <>
                          <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                            {
                              t(
                                `${field.label}${
                                  !field.noValidate &&
                                  ![PmsProvider.RMS].includes(watch('provider')) &&
                                  watch('use_v2_integration')
                                    ? '*'
                                    : ''
                                }`
                              ) as string
                            }
                          </Typography>
                          <MultiSelect
                            fullWidth
                            isInitialValue
                            background="grey"
                            inputClassName="py-4"
                            name={name}
                            placeholder={`Select ${field.label}`}
                            value={value}
                            onChange={onChange}
                            options={propertyList ? propertyList : []}
                          />
                        </>
                      )}
                    />
                  );
                }

                return (
                  <div key={key} className="space-y-1">
                    {inputElement}
                    {formState.errors[key] && (
                      <InputHelperMessage
                        icon={<Icon.WarningOutline className="h-4 w-4 text-error" />}
                        message={t('This field is required') as string}
                      />
                    )}
                  </div>
                );
              })}
              {[10, 24, 52].includes(watchProviderValue) ? (
                <div className="w-full ">
                  <div className="grid grid-cols-12 items-center gap-2">
                    <div className="col-span-11">
                      <Input
                        value={hotelIframeId as string}
                        label={`${t('ID for Iframe')}`}
                        showClearButton={false}
                        readOnly
                        background="grey"
                        type="text"
                      />
                    </div>
                    <div className="group relative col-span-1 mt-6">
                      <TooltipProvider>
                        <Tooltip delayDuration={200}>
                          <TooltipTrigger>
                            <Button
                              type="button"
                              icon
                              intent="primary"
                              size="small"
                              onClick={() => handleCopy(() => hotelIframeId as string, 'Iframe')}
                            >
                              {isCopySuccess && copySuccessId === 'Iframe' ? (
                                <Icon.CheckList className="h-5 w-5 text-uiGreen" />
                              ) : (
                                <Icon.Copy className="h-5 w-5 text-opacity-100" />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent side="top" className="bg-white text-darkGrey">
                            {isCopySuccess && copySuccessId === 'Iframe' ? 'Copied!' : 'Copy'}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {/* Table Room Type */}
      {hasRoomTypeTable ? <RoomTypeTable /> : null}
      {/* Table Room Rates */}
      {hasRoomRateTable ? <RoomRatesTable /> : null}
      {[PmsProvider.HLS].includes(activePmsProviderId as number) ? <RoomMappingTable /> : null}
      {isProtelInpAir ? <ReservationStatusMappingTable /> : null}
      {isNewbookPms ? <NewbookRoomMappingTable /> : null}
      <Modal
        open={connectionChangeOpen}
        onClose={connectionChangeClose}
        okText={`${t('Disconnect')}`}
        size="sm"
        onClick={disconnectPms}
      >
        <div className="flex flex-col gap-y-4">
          <Typography element="span" variant="h6" color="darkGrey">
            {t('Disconnect PMS')}
          </Typography>
          <Typography element="p" variant="paragraph-2" color="darkGrey">
            {t(
              ' RoomPriceGenie will get disconnected from your PMS, hence not be able to fetch your availabilities or upload new prices anymore.'
            )}
          </Typography>
        </div>
      </Modal>
      {/* Modal Reprocess Data */}
      <Modal
        open={reprocessOpen}
        size="sm"
        onClick={reprocessData}
        onClose={reprocessClose}
        okText={`${t('Confirm')}`}
      >
        <div className="flex flex-col gap-y-4">
          <Typography element="span" variant="h6" color="darkGrey">
            {t('Reprocess Data')}
          </Typography>
          <Typography element="p" variant="paragraph-2" color="darkGrey">
            {t(
              "This will reprocess all inventory and rate updates we received for this property. It's for setup purposes, for example after changing the mapping."
            )}
          </Typography>
        </div>
      </Modal>
      <PriceCalculationLoadingModal
        open={isInitRoomLoading}
        progressMessage={t('Rooms are being initialized…') as string}
      />
    </>
  );
};
