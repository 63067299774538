import { InputDataType } from '@common/api/roomPrices/types';

export function extractRoomIdsByType(data?: InputDataType | undefined) {
  if (!data) return {};
  const result = {
    reference: data.reference.id,
    derived: Object.keys(data.derived).map(Number)
  };

  return result;
}
