import {
  CachePriceDataType,
  MinMaxPriceData,
  MinMaxPriceOutput,
  OutputDataType
} from '@common/api/roomPrices/types';
import { Feature } from '@common/store/features';

export const extractPriceByRoomIdAndKey = (
  cachePriceData: CachePriceDataType | undefined,
  pricingSettings: MinMaxPriceData | undefined,
  roomId: number,
  key: string,
  defaultValue: number,
  featureFlags: number[],
  roomData: OutputDataType = { derived: [], reference: 0 }
): MinMaxPriceOutput => {
  const output: MinMaxPriceOutput = [];
  if (!cachePriceData || !pricingSettings) return output;

  const isBulkEditOn = featureFlags.includes(Feature.BulkEdits);
  const isMinMaxFeatureOn = featureFlags.includes(Feature.IndividualMinMax);
  const isDerivedRoomType = roomData && roomData?.derived?.includes(roomId);

  const dateRange = Object.keys(cachePriceData);
  dateRange.forEach((date) => {
    switch (true) {
      case !isMinMaxFeatureOn && isDerivedRoomType:
        break;

      case !isBulkEditOn:
        output.push({ date, value: defaultValue });
        break;

      case isBulkEditOn &&
        pricingSettings[date] &&
        Object.prototype.hasOwnProperty.call(pricingSettings[date], roomId):
        output.push({
          date,
          value: (pricingSettings[date][roomId] as any)[key] || defaultValue
        });
        break;

      default:
        output.push({ date, value: defaultValue });
        break;
    }
  });

  return output;
};
