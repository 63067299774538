import { Typography } from '@common/components/foundations/Typography';
import clsx from 'clsx';
import styles from '@pages/Client/Calendar/pages/Pricing/components/PricingCellContent.module.css';
import { useTranslation } from 'react-i18next';

interface PricingCellContentProps {
  content: {
    occupancy: string;
    primaryLabel: string;
    secondaryLabel: string;
    isClosed?: boolean;
  };
}

export const RevenueCellContent = ({ content }: PricingCellContentProps) => {
  const { t } = useTranslation();
  return (
    <div className={clsx('relative flex items-end justify-between text-meta-2')}>
      <div className="mb-1 font-medium">{content.occupancy}</div>

      <div className="text-right">
        {!content.isClosed ? (
          <>
            <span>{content.secondaryLabel}</span>
            <div className="-mr-1 px-1 py-0.5 text-[15px] text-paragraph-2-medium transition-colors">
              {content.primaryLabel}
            </div>
          </>
        ) : (
          <Typography variant="paragraph-1" className={clsx('font-sans', styles.priceText)}>
            {t('Closed')}
          </Typography>
        )}
      </div>
    </div>
  );
};
