import { Icon } from '@common/components/foundations/icons';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { cn } from '@common/utils/cn';
import { ReactNode } from 'react';

interface RemovableBadgeProps {
  children: ReactNode;
  onRemove: () => void;
  onClick?: () => void;
  className?: string;
}

export const RemovableBadge: React.FC<RemovableBadgeProps> = ({
  children,
  onClick,
  onRemove,
  className
}) => {
  const copyTextGrey = useTailwindColor('copyTextGrey');

  return (
    <span
      className={cn(
        'text-gray-600 inline-flex items-center gap-x-0.5 whitespace-nowrap rounded-sm border border-grey-reduced bg-white px-2 py-1 text-xs font-medium',
        className
      )}
      onClick={onClick}>
      {children}
      <div className="min-fit w-5">
        <Icon.Clear
          className="ml-1 h-4 w-4 cursor-pointer"
          color={copyTextGrey}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onRemove();
          }}
        />
      </div>
    </span>
  );
};
