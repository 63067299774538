import { saveMarketCode } from '@common/api/hotel';
import { SaveMarketData } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useManualSearchAirdnaStore } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/Airbnb/store/manualSearch';
import { useMutation } from '@tanstack/react-query';

export const useSaveMarketData = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const { addNotification } = useNotificationsStore();
  const { setSuccess, setLoading } = useManualSearchAirdnaStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: SaveMarketData) => {
      return saveMarketCode(data);
    },
    onSuccess: (response) => {
      const { success } = response.data;
      if (!success) {
        addNotification('fail', 'Failed to to fetch AirDNA Data');
      }
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      onSuccess?.();
    },
    onError: () => {
      addNotification('fail', 'Failed to to fetch AirDNA Data');
      setLoading(false);
    }
  });

  return {
    saveMarketData: mutate,
    isLoading
  };
};
