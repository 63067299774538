import { resetPickupBoostPriceAndOccupancy } from '@common/api/pricingAlgorithm';
import { ResetPickupBoostPriceAndOccupancy } from '@common/api/pricingAlgorithm/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useMutation } from '@tanstack/react-query';

export const useResetPickupBoostPriceAndOccupancy = () => {
  const { addNotification } = useNotificationsStore();
  const { cachePriceQuery } = useRoomPrices();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: ResetPickupBoostPriceAndOccupancy) => {
      return resetPickupBoostPriceAndOccupancy(data);
    },
    onSuccess: () => {
      addNotification('success', 'Success reset prices');
      cachePriceQuery.refetch();
    },
    onError: () => {
      addNotification('fail', 'Failed to reset prices');
    }
  });

  return {
    resetPickupBoostPriceAndOccupancy: mutate,
    isLoading
  };
};
