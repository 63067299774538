import { Navigate, RouteObject } from 'react-router-dom';
import { HealthReport } from '@pages/Client/HealthReport/pages/HealthReport';
import { ValueReport } from '@pages/Client/HealthReport/pages/ValueReport';

export const healthReportRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="health-report" /> },
  {
    path: 'health-report',
    element: <HealthReport />
  },
  {
    path: 'value-report',
    element: <ValueReport />
  }
];
