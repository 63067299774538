import { cva, VariantProps } from 'class-variance-authority';
import styles from '@common/components/atoms/Chip/Chip.module.css';
import { ReactNode } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { cn } from '@common/utils/cn';

const chipStyles = cva(styles.chipBase, {
  variants: {
    selected: {
      true: 'bg-opacity-5 bg-indigo border-indigo',
      false: 'bg-white border-mediumGrey'
    }
  }
});

type ChipProps<T> = Omit<React.BaseHTMLAttributes<HTMLSpanElement>, 'onChange'> &
  VariantProps<typeof chipStyles> & {
    value?: T;
    onChange?: (value: T) => void;
  };

export function Chip<T>({ selected, className, onChange, value, ...props }: ChipProps<T>) {
  return (
    <span
      className={cn(chipStyles({ selected }), className)}
      onClick={() => onChange?.(value!)}
      {...props}
    />
  );
}

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */
export function ChipAsCheckbox<T extends FieldValues>({
  children,
  className,
  nameKey,
  valueKey
}: {
  children: ReactNode;
  className?: string;
  nameKey: Path<T>;
  valueKey: Path<T>;
}) {
  const { register } = useFormContext();
  return (
    <div className="flex">
      <input type="checkbox" id={nameKey} className="peer hidden" {...register(valueKey)} />
      <label
        htmlFor={nameKey}
        className={cn(
          styles.chipBase,
          'cursor-pointer select-none',
          'border-mediumGrey bg-white',
          'transition-colors duration-200 ease-in-out peer-checked:border-indigo peer-checked:bg-indigo peer-checked:bg-opacity-5',
          className
        )}>
        {children}
      </label>
    </div>
  );
}
