import { ButtonDropdown } from '@common/mantine/components/button-dropdown';
import { Stack, Select, MultiSelect, Title } from '@mantine/core';

export const Combobox = () => {
  return (
    <Stack py="xs">
      <Title order={2} size="h3">
        Select
      </Title>
      <Stack>
        <Select
          label="Your favorite library"
          placeholder="Pick value"
          data={['React', 'Angular', 'Vue', 'Svelte']}
        />
        <Select
          label="Your favorite library"
          description="Use only on white backgrounds"
          variant="filled"
          placeholder="Pick value"
          data={['React', 'Angular', 'Vue', 'Svelte']}
        />
      </Stack>
      <Title order={2} size="h3">
        MultiSelect
      </Title>
      <Stack>
        <MultiSelect
          label="Your favorite library"
          placeholder="Pick value"
          data={['React', 'Angular', 'Vue', 'Svelte']}
        />
        <MultiSelect
          label="Your favorite library"
          description="Use only on white backgrounds"
          variant="filled"
          placeholder="Pick value"
          data={['React', 'Angular', 'Vue', 'Svelte']}
        />
      </Stack>
      <Title order={2} size="h3">
        ButtonDropdown
      </Title>
      <ButtonDropdown
        label="Your favorite library"
        defaultValue="React"
        data={['React', 'Angular', 'Vue', 'Svelte']}
      />
      <Title order={3} size="h4">
        ButtonDropdown Searchable
      </Title>
      <ButtonDropdown
        label="Your favorite library"
        searchable
        defaultValue="React"
        data={['React', 'Angular', 'Vue', 'Svelte']}
      />
    </Stack>
  );
};
