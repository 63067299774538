import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '@common/api/auth';
import { axiosInstance } from '@common/api/axiosInstance';
import { objectToURLParam } from '@common/utils/objectToURLParam';
import {
  AnalyticsDashboardCacheData,
  AnalyticsDashboardFilters,
  AnalyticsDashboardFiltersData,
  AnalyticsDashboardType,
  BudgetResult,
  DashboardExcelReportConfig,
  EditReportData,
  ForecastingCache,
  KPI,
  MultiPropertyFiltersData,
  NewReportData,
  Pickup,
  PropertyDashboardFilters
} from '@common/api/dashboard/types';

export const getPerformanceDashboardReservationKPI = async (filters?: PropertyDashboardFilters) => {
  const params = filters ? objectToURLParam(filters) : '';
  const endpoint = `/performancedashboard/reservation-kpi/?date_range=dashboard_kpis_full_date_range&is_calendar=true${
    params ? `&${params}` : ''
  }`;
  try {
    const { data } = await axiosInstance.get<KPI>(endpoint);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getPickup = async (filters?: PropertyDashboardFilters) => {
  const params = filters ? objectToURLParam(filters) : '';
  const endpoint = `/performancedashboard/pickup/${params ? `?${params}` : ''}`;
  try {
    const { data } = await axiosInstance.get<Pickup>(endpoint);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deletePostgresSnowflake = async () => {
  try {
    await axiosInstance.post('/performancedashboard/delete-snowflake-and-postgres-data/');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const resetPostgresSnowflake = async () => {
  try {
    await axiosInstance.post('/performancedashboard/reset-snowflake-and-postgres-data/');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getAnalyticsDashboardCache = async ({
  filters,
  type
}: {
  filters?: AnalyticsDashboardFilters;
  type: AnalyticsDashboardType;
}) => {
  const params = filters ? objectToURLParam(filters) : '';
  let endpoint;

  if (type === 'multi_property') {
    endpoint = `/performancedashboard/analytics-dashboard-cache/property/`;
  } else {
    endpoint = `/performancedashboard/analytics-dashboard-cache/${type}/${
      params ? `?${params}` : ''
    }`;
  }
  try {
    const { data } = await axiosInstance.get<AnalyticsDashboardCacheData>(endpoint);
    return data?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getAnalyticsDashboardFilters = async () => {
  try {
    const { data } = await axiosInstance.get<AnalyticsDashboardFiltersData>(
      '/performancedashboard/analytics-dashboard-filters/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getForecastingCache = async (filters?: PropertyDashboardFilters) => {
  const params = filters ? objectToURLParam(filters) : '';
  const endpoint = `/performancedashboard/get-forecasting-cache/${params ? `?${params}` : ''}`;
  try {
    const { data } = await axiosInstance.get<ForecastingCache>(endpoint);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateForecastingCache = async () => {
  try {
    const { data } = await axiosInstance.post('/performancedashboard/update-forecasting-cache/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getScheduleReports = async () => {
  try {
    const { data } = await axiosInstance.get('/performancedashboard/scheduled-reports/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const addScheduleReports = async (newData: NewReportData) => {
  try {
    const { data } = await axiosInstance.post('/performancedashboard/scheduled-reports/', newData);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const updateScheduleReports = async (id: number, editData: EditReportData) => {
  try {
    const { data } = await axiosInstance.patch(
      `/performancedashboard/scheduled-reports/${id}`,
      editData
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const generateScheduleReports = async (id: number) => {
  try {
    const { data } = await axiosInstance.post(
      `/performancedashboard/scheduled-reports/${id}/generate/`
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deleteScheduleReports = async (id: number) => {
  try {
    const { data } = await axiosInstance.delete(`/performancedashboard/scheduled-reports/${id}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getSegmentationCache = async () => {
  try {
    const { data } = await axiosInstance.get('/performancedashboard/segmentation-cache/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getMultiPropertyFilters = async () => {
  try {
    const { data } = await axiosInstance.get<MultiPropertyFiltersData>(
      '/performancedashboard/multiproperty-filters/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const exportDashboardExcelReport = async (config: DashboardExcelReportConfig) => {
  try {
    const { data } = await axiosInstance.post(
      'performancedashboard/dashboard-report-xlsx/',
      config
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getAirbnbMarketInsightsCache = async () => {
  try {
    const { data } = await axiosInstance.get(
      '/performancedashboard/market-insights/airbnb-kpi-collation-cache/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getBudget = async (filters?: PropertyDashboardFilters) => {
  const params = filters ? objectToURLParam(filters) : '';
  const endpoint = `/performancedashboard/budget/${params ? `?${params}` : ''}`;
  try {
    const { data } = await axiosInstance.get<BudgetResult>(endpoint);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
