import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Modal, Text, Button } from '@mantine/core';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  children?: React.ReactNode;
  name?: string;
};

export const DeleteHotelScrapeModal = ({ onDelete, name, isOpen, onClose }: ModalProps) => {
  return (
    <Modal.Root opened={isOpen} onClose={onClose} size="sm">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Delete {name}</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body>
          <Text size="sm">Are you sure you want to delete {name}?</Text>
        </Modal.Body>
        <ModalFooter>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onDelete}>Delete</Button>
        </ModalFooter>
      </Modal.Content>
    </Modal.Root>
  );
};
