export const Copy = (props: any) => {
  return (
    <svg
      width="24"
      {...props}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16V4C18 2.89543 17.1046 2 16 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16ZM4 4H16V16H4V4ZM20 18V6C21.1046 6 22 6.89543 22 8V18C22 20.2091 20.2091 22 18 22H8C6.89543 22 6 21.1046 6 20H18C19.1046 20 20 19.1046 20 18Z"
        fill="currentColor"
      />
    </svg>
  );
};
