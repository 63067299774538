import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useDeleteProperty } from '@pages/Client/DeleteProperty/hooks/useDeleteProperty';
import { useDocumentTitle } from '@mantine/hooks';

export const DeleteProperty = () => {
  useDocumentTitle('Delete Property');
  const schema = z.object({
    delete_hotel: z.string().refine((data) => data === 'DELETE PROPERTY', {
      message: 'Please type DELETE PROPERTY to confirm'
    })
  });
  const {
    watch,
    reset,
    control,
    formState: { errors }
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { deleteHotel, isLoading } = useDeleteProperty();

  const isButtonDisabled = watch('delete_hotel') !== 'DELETE PROPERTY';

  const handleDelete = () => {
    deleteHotel();
  };

  return (
    <Page
      header={
        <div className="max-w-4xl">
          <Header
            title="Delete Property"
            actions={
              <div className="pr-16">
                <Button
                  intent="danger"
                  size="large"
                  disabled={isButtonDisabled}
                  onClick={handleDelete}
                  isLoading={isLoading}>
                  Delete Property
                </Button>
              </div>
            }
          />
        </div>
      }>
      <div className="flex min-h-[200px] max-w-3xl flex-col items-center justify-center rounded-sm  border-[2px] border-uiRed bg-uiRed  bg-opacity-10 p-4 ">
        <div className="my-4 flex flex-col justify-center gap-2 text-center">
          <Typography variant="paragraph-1" element="p" color="darkGrey">
            This action <b>CANNOT</b> be undone. This will delete property permanently
          </Typography>
          <Typography color="darkGrey">
            Please Type &quot;DELETE PROPERTY&quot; to Confirm
          </Typography>
        </div>

        <div className="my-2 w-full max-w-xl">
          <Controller
            name="delete_hotel"
            control={control}
            render={({ field: { value, name, onChange } }) => (
              <Input
                placeholder='Please enter "DELETE PROPERTY"'
                error={errors.delete_hotel ? true : false}
                name={name}
                id={name}
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                onClear={() => {
                  reset({ delete_hotel: undefined });
                }}
                type={'text'}
                helperMessage={
                  errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.delete_hotel ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.delete_hotel ? errors?.delete_hotel?.message?.toString() : ''}
                      </Typography>
                    </div>
                  )
                }
              />
            )}
          />
        </div>
      </div>
    </Page>
  );
};
