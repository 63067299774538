import { Button } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const ContactSupport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Chat Bot
  const handleContactSupport = () => {
    const currentPathname = location.pathname;
    const currentSearch = location.search;

    navigate(`${currentPathname}?bot_integration_page=true`);

    if (currentSearch === '?bot_integration_page=true') {
      if (window.HubSpotConversations?.widget) {
        window.HubSpotConversations.widget.open();
      }
    } else {
      if (window.HubSpotConversations?.widget) {
        window.HubSpotConversations.widget.refresh({
          openToNewThread: true
        });
      }
    }
  };

  return (
    <Button variant="outline" onClick={handleContactSupport}>
      {t('Contact Support')}
    </Button>
  );
};

export default ContactSupport;
