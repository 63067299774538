import { API_DATE_FORMAT } from '@common/constants/date';
import dayjs from 'dayjs';

/**
 * Determines if the current UTC date and time is "yesterday" in the user's time zone.
 *
 * @function
 * @returns {boolean} True if the current UTC date and time is "yesterday"
 * in the user's time zone, or if the vew date is before the current UTC date; otherwise, false.
 */
export const isUTCYesterdayInUserTZ = (viewDate: string): boolean => {
  const userTZ = dayjs.tz.guess();
  const utcNow = dayjs().utc().format(API_DATE_FORMAT);
  const userDate = dayjs().tz(userTZ).format(API_DATE_FORMAT);
  const isYesterday = dayjs(utcNow).isBefore(userDate) || dayjs(viewDate).isBefore(utcNow);
  return isYesterday;
};
