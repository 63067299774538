import { AppliedFiltersSchema, ClientListSchema } from '@common/api/hotel/types';
import { omit } from 'lodash-es';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ClientSortItem = { id: string; desc: boolean };
export interface ClientListParams {
  defaultParams: ClientListSchema;
  params: ClientListSchema;
  sorting: ClientSortItem[];
  filters: AppliedFiltersSchema;
  defaultFilters: AppliedFiltersSchema;
  setFilters: (filter: Partial<AppliedFiltersSchema>) => void;
  setParams: (param: Partial<ClientListSchema>) => void;
  setSorting: (sorting: ClientSortItem[]) => void;
  resetParams: () => void;
}

const defaultValues = {
  page: 1,
  ordering: '-id',
  country_filter: undefined,
  plan: undefined,
  pms_provider: undefined,
  state: undefined,
  deal_owner_filter: undefined,
  deal_owner_type: undefined,
  search: undefined,
  open_api_partner_id: undefined
};

export const defaultFilters = omit(defaultValues, ['page', 'ordering']);

export const useClientListStore = create<ClientListParams>()(
  persist(
    (set) => ({
      defaultParams: defaultValues,
      defaultFilters,
      filters: defaultFilters,
      setFilters: (filter: Partial<AppliedFiltersSchema>) =>
        set((state) => {
          const newFilters = { ...state.filters, ...filter };
          const newParams = { ...state.params, page: 1 };
          return { filters: newFilters, params: newParams };
        }),
      params: defaultValues,
      setParams: (param: Partial<ClientListSchema>) =>
        set((state) => ({ params: { ...state.params, ...param } })),
      sorting: [{ id: 'id', desc: false }],
      setSorting: (sorting: ClientSortItem[]) => set({ sorting }),
      resetParams: () =>
        set({ params: defaultValues, filters: omit(defaultValues, ['page', 'ordering']) })
    }),
    {
      name: 'clientListStore',
      onRehydrateStorage: () => {
        return (state) => {
          return state;
        };
      }
    }
  )
);
