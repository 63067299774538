import { useTranslation } from 'react-i18next';
import { Button } from '@common/components/atoms/Button';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { autoSaveAveragePrice, savePricingSettings } from '@common/api/hotel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationsStore } from '@common/store/notifications';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { PricingSettings } from '@common/api/hotel/types';
import { useState } from 'react';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { StepComponentProps } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/AdminSetupRoomsCard';
import { find, includes, keys, map, orderBy, values } from 'lodash-es';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { ShadDialog } from '@common/components/molecules/ShadDialog/ShadDialog';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Typography } from '@common/components/foundations/Typography';
import { produce } from 'immer';
import { Feature, useFeaturesStore } from '@common/store/features';
import { HotelQueryKeys, PricingQueryKeys } from '@common/types/query-keys';

type MergedDataType = {
  id: number;
  average: {
    avg_price: number;
    min_price: number;
    max_price: number;
  };
  name: string | null;
  is_reference_room: boolean;
};

export const SetPMSAverageSetupStep = ({ index }: StepComponentProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isApartment } = useRoomSetupStore();
  const { features } = useFeaturesStore();
  const {
    hotelRooms,
    query: { isLoading: isHotelRoomsLoading }
  } = useHotelRoomsList();
  const { addNotification } = useNotificationsStore();
  const {
    pricingSettings,
    pricingSettingsQuery: { isLoading: isPricingSettingsLoading, refetch: refetchPricingSettings }
  } = usePricingSettings();
  const [runUpdate, setRunUpdate] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedAveragePrice, setUpdatedAveragePrice] = useState<MergedDataType[] | null>(null);
  const apartmentsOrRooms = isApartment ? 'Apartments' : 'Rooms';
  const baseAverages = pricingSettings?.default;
  const enabled = includes(features, Feature.IndividualMinMax);

  const { mutateAsync: updateAutoSaveAveragePrice, isPending: isAutoSaveAveragePriceLoading } =
    useMutation({
      mutationKey: [PricingQueryKeys.AUTO_SAVE_AVERAGE_PRICE],
      mutationFn: autoSaveAveragePrice,
      onError: (error: unknown) => {
        const axiosError = error as any;
        addNotification(
          'fail',
          t(`Error setting PMS average for all ${apartmentsOrRooms} - ${axiosError.message}`)
        );
      }
    });

  const { mutateAsync: updatePricingSettings, isPending: isUpdatePricingSettings } = useMutation({
    mutationKey: [PricingQueryKeys.UPDATE_PRICING_SETTINGS],
    mutationFn: savePricingSettings,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
      await queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_DATA_MAP] });
      if (runUpdate) setIsSuccess(true);
      addNotification('success', t(`PMS average for all ${apartmentsOrRooms} set!`));
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000);
    }
  });

  async function setUpdatedPricingSettings(onlyBasePrices: boolean) {
    const { data: latestPricingSettings } = await refetchPricingSettings();

    const pricingDraft = produce(latestPricingSettings, (draft: PricingSettings) => {
      const referenceRoomId = draft?.rooms?.reference?.id;
      const absoluteRoomDerivation = draft?.hotel.adjustment_to_reference_room_is_absolute;
      if (!referenceRoomId) return;
      map(updatedAveragePrice, (avg) => {
        const newAverage = avg.average.avg_price;
        const newReferenceAverage =
          find(updatedAveragePrice, { id: referenceRoomId })?.average.avg_price || 0;
        const newAdjustment = newAverage - newReferenceAverage;
        if (draft.default[avg.id]) {
          if (!onlyBasePrices) {
            draft.default[avg.id].min_price = avg.average.min_price;
            draft.default[avg.id].max_price = avg.average.max_price;
          }
          if (absoluteRoomDerivation) {
            draft.default[avg.id].avg_price = newAverage;
            draft.default[avg.id].adjustment_to_reference_room = newAdjustment;
          } else {
            draft.default[avg.id].avg_price = newAverage;
            draft.default[avg.id].adjustment_to_reference_room = Math.round(
              ((newAverage - newReferenceAverage) / newReferenceAverage) * 100
            );
          }
        }
      });
    });

    await updatePricingSettings(JSON.stringify(pricingDraft));
    setRunUpdate(false);
    setIsModalOpen(false);
  }

  async function handleClick() {
    setIsModalOpen(true);
    const newAverage = await updateAutoSaveAveragePrice();
    const mergedData = map(newAverage.data.data, (avg) => {
      const room = find(hotelRooms, { id: parseInt(keys(avg)[0]) });
      return {
        id: parseInt(keys(avg)[0]),
        average: values(avg)[0],
        name: room ? room.name : null,
        is_reference_room: room?.is_reference_room ?? false
      };
    });
    setUpdatedAveragePrice(mergedData);
  }

  function BasePriceActionButton() {
    return (
      <Button
        type="button"
        intent="primary"
        isLoading={isLoading}
        onClick={() => setUpdatedPricingSettings(true)}
      >
        {t('Save Base Prices Only')}
      </Button>
    );
  }
  function MinMaxActionButton() {
    return (
      <Button
        type="button"
        intent="outline"
        isLoading={isLoading}
        onClick={async () => await setUpdatedPricingSettings(false)}
      >
        {t('Save Base Prices and Min/Max')}
      </Button>
    );
  }

  function CloseButton() {
    return (
      <Button
        type="button"
        intent="text"
        disabled={isLoading}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        {t('Cancel')}
      </Button>
    );
  }

  function DataComponent({ isLoading }: { isLoading: boolean }) {
    return (
      <LineTable>
        <thead>
          <tr>
            <th>{`${t(apartmentsOrRooms)}: ${hotelRooms?.length}`}</th>
            <th>{t('Current Base Price')}</th>
            <th>{t('Proposed Base Price')}</th>
            <th>{t('Proposed Default Min/Max')}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? map(orderBy(hotelRooms, ['is_reference_room', 'name'], ['desc', 'asc']), (room) => {
                const showMinMax = (!enabled && room.is_reference_room) || enabled;
                return (
                  <tr key={room.id} className="w-full">
                    <td className="w-2/6">{room.name}</td>
                    <td>
                      <Typography>
                        {CurrencyFormatter.format(
                          Math.round(baseAverages?.[room.id].avg_price ?? 0)
                        )}
                      </Typography>
                    </td>
                    <td>
                      <Skeleton className="h-4 w-10" />
                    </td>
                    <td>
                      {showMinMax ? (
                        <div className="flex justify-start gap-2">
                          <Skeleton className="h-4 w-10" /> / <Skeleton className="h-4 w-10" />
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                );
              })
            : map(
                orderBy(updatedAveragePrice, ['is_reference_room', 'name'], ['desc', 'asc']),
                (room) => {
                  const showMinMax = (!enabled && room.is_reference_room) || enabled;
                  return (
                    <tr key={room.id} className="w-full">
                      <td className="w-2/6">{room.name}</td>
                      <td>
                        <Typography>
                          {CurrencyFormatter.format(
                            Math.round(baseAverages?.[room.id].avg_price ?? 0)
                          )}
                        </Typography>
                      </td>
                      <td>
                        <Typography variant="meta-1" className="font-semibold">
                          {CurrencyFormatter.format(Math.round(room.average.avg_price))}
                        </Typography>
                      </td>
                      <td>
                        {showMinMax ? (
                          <Typography variant="meta-1" className="font-semibold">
                            {CurrencyFormatter.format(Math.round(room?.average.min_price ?? 0))} /{' '}
                            {CurrencyFormatter.format(Math.round(room?.average.max_price ?? 0))}
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
        </tbody>
      </LineTable>
    );
  }

  const isLoading =
    runUpdate ||
    isHotelRoomsLoading ||
    isAutoSaveAveragePriceLoading ||
    isUpdatePricingSettings ||
    isPricingSettingsLoading;

  const baseDialogConfig = {
    open: isModalOpen,
    openChange: setIsModalOpen,
    actionButtons: [MinMaxActionButton, BasePriceActionButton],
    closeButton: CloseButton
  };

  return (
    <div className="flex items-center justify-start align-middle">
      <ShadDialog
        config={{
          ...baseDialogConfig,
          title: isLoading ? t('Please wait') : t('Please double-check the prices'),
          content: () => <DataComponent isLoading={isLoading} />
        }}
      >
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          isSuccess={isSuccess}
          type="button"
          onClick={handleClick}
          intent="outline"
        >
          <span>{index}.</span>
          {t(`Set PMS Average as Base Prices For All ${apartmentsOrRooms}`)}
        </Button>
      </ShadDialog>
    </div>
  );
};
