import {
  useOwnerDetails,
  useAdminList,
  useStaffList,
  useManagerList,
  usePendingListRaw,
  usePendingList
} from '@pages/Client/Account/User/hooks/useUser';
import { UserList } from '@pages/Client/Account/User/types/userList';
import { useMemo } from 'react';
import { useMounted } from '@mantine/hooks';

export const useUserTableData = () => {
  const isMounted = useMounted();

  const {
    query: { isFetching: ownerLoading },
    ownerDetails: ownerList
  } = useOwnerDetails();
  const {
    query: { isFetching: adminLoading },
    adminList
  } = useAdminList();
  const {
    query: { isFetching: staffLoading },
    staffList
  } = useStaffList();
  const {
    query: { isFetching: managerLoading },
    managerList
  } = useManagerList();
  const {
    query: { isFetching: pendingListLoadingRaw },
    pendingInvitesRaw: pendingListRaws
  } = usePendingListRaw();
  const {
    query: { isFetching: pendingListLoading },
    pendingInvites: pendingList
  } = usePendingList();

  const isUserLoading =
    !isMounted ||
    adminLoading ||
    staffLoading ||
    managerLoading ||
    ownerLoading ||
    pendingListLoading ||
    pendingListLoadingRaw;

  const mergedList = useMemo(() => {
    if (isUserLoading) return [];

    const filterUsersWithHotelAccess = (users: UserList[] | undefined) =>
      users?.filter((user) => user.assigned_hotels.length > 0) || [];

    return [
      ...(ownerList ? [ownerList] : []),
      ...filterUsersWithHotelAccess(adminList),
      ...filterUsersWithHotelAccess(staffList),
      ...filterUsersWithHotelAccess(managerList),
      ...(pendingList || [])
    ];
  }, [isUserLoading, ownerList, adminList, staffList, managerList, pendingList]);

  return {
    mergedList,
    isUserLoading,
    ownerList,
    adminList,
    staffList,
    managerList,
    pendingListRaws,
    pendingList
  };
};
