import { useTranslation } from 'react-i18next';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { useEffect, useMemo, useState } from 'react';
import { Genie } from '@common/components/foundations/Logo';
import { cn } from '@common/utils/cn';
import { Divider } from '@common/components/atoms/Divider';
import { useMemberShipPricing } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useSubscription } from '@pages/Client/hooks/useSubscription';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Icon } from '@common/components/foundations/icons';
import { useHSTicket } from '@pages/Client/hooks/useHSTicket';
import { useUpselling } from '@pages/Client/hooks/useUpselling';

type PageType = 'initial' | 'congrats' | 'calendar';

export const UpsellingModal = ({
  isModalOpen,
  onClose
}: {
  isModalOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const indigo = useTailwindColor('indigo');
  const { membershipPricing, isLoading } = useMemberShipPricing();
  const { isMonthlySubscription } = useSubscription();
  const { SparkIcon } = useUpselling();

  const [page, setPage] = useState<PageType>('initial');

  const { createHSTicket, isLoading: isCreateHSTicketLoading } = useHSTicket({
    onSuccess: () => setPage('congrats')
  });

  const features = [
    '18 Months Future Price Recommendations',
    '12 Price Uploads to Your PMS per Day',
    'Monthly Revenue Strategy Reviews',
    '12 Months Revenue Forecast',
    'Price Evolution Analysis',
    'Revenue Performance Reporting',
    'Advanced Analytics & Segmentation',
    'Scheduled Reporting',
    'Advanced Surge Protection',
    'Pickup Calendar'
  ];
  const pricing = isLoading ? {} : membershipPricing ? membershipPricing : {};
  const advancedPricing = pricing?.[3];
  const professionalPricing = pricing?.[4];

  const pricingDiff = useMemo(() => {
    if (!advancedPricing || !professionalPricing) {
      return;
    }
    if (isMonthlySubscription) {
      return ((professionalPricing.monthly.price - advancedPricing.monthly.price) * 12) / 365;
    }
    return (
      ((professionalPricing.yearly.price_per_month - advancedPricing.yearly.price_per_month) * 12) /
      365
    );
  }, [isMonthlySubscription, professionalPricing, advancedPricing]);

  const UpsellingContent = () => {
    const half = Math.ceil(features.length / 2);
    const firstHalf = features.slice(0, half);
    const secondHalf = features.slice(half);
    return (
      <div>
        <div className="mt-6 grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="space-y-3">
            <ul className="list-none space-y-2 pl-5">
              {firstHalf.map((feature, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <Icon.Verified color={indigo} className="h-5 w-5" />
                  <span>{t(feature)}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="space-y-3">
            <ul className="list-none space-y-2 pl-5">
              {secondHalf.map((feature, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <Icon.Verified color={indigo} className="h-5 w-5" />
                  <span>{t(feature)}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <Divider className="mt-5 w-11/12" />

        <div className="w-11/12 text-center hover:underline">
          <a
            href="https://roompricegenie.com/pricing/"
            target="_blank"
            rel="noreferrer"
            style={{ color: indigo }}
          >
            {t('Learn more')}
          </a>
        </div>

        <div className="mt-5">
          <p className="mb-5 text-lg">
            {t('For an additional')}{' '}
            <span className="text-xl font-medium">
              {CurrencyFormatter.format(pricingDiff, '-')}
            </span>{' '}
            {t('per day')} {t('you can get')}{' '}
            <span className="font-medium">{t("RoomPriceGenie's most valuable plan") + '.'}</span>
          </p>

          <div className="flex items-start space-x-2">
            <p className="text-md w-2/3">
              {t(
                'Our team will be delighted to talk to you about the Professional Plan and demonstrate the additional features in your account.'
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const CongratsContent = () => {
    return (
      <div>
        <p className="text-md my-5">
          {t('You activated your 2-week free trial of the professional plan.')}
        </p>
        <p className="text-md mt-5">
          {t(
            "We'll activate the features within the next 24 hours. Book your first revenue strategy call to go through the new features with a revenue manager at RoomPriceGenie."
          )}
        </p>
      </div>
    );
  };

  const actionText =
    page === 'initial'
      ? (t('Start free trial') as string)
      : page === 'congrats'
        ? (t('Book a meeting') as string)
        : '';

  const handleActionClick = () => {
    page === 'initial'
      ? createHSTicket({ ticket_type: 'upsell_request' })
      : page === 'congrats'
        ? setPage('calendar')
        : null;
  };

  const HubSpotMeeting = () => {
    const src = 'https://meetings.hubspot.com/gustavo-pena/revsessunsub?embed=true';
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, []);
    return <div className="meetings-iframe-container min-w-[800px]" data-src={src} />;
  };

  const handleClose = () => {
    onClose();
    setPage('initial');
  };

  const title = useMemo(() => {
    if (page === 'initial')
      return (
        <>
          {t('Upgrade to') + ' '} <span style={{ color: indigo }}>{t('Professional Plan')}</span>{' '}
          {t('and make more revenue')}
        </>
      );
    if (page === 'congrats') return t('Congratulations!');
    if (page === 'calendar') return t('Book a meeting');
  }, [page]);

  return (
    <Modal
      open={isModalOpen}
      size="xxl"
      onClose={handleClose}
      displayCancel={false}
      displayContinue={!!actionText}
      okText={actionText}
      onClick={handleActionClick}
      isLoading={isCreateHSTicketLoading}
      disableCloseOnOk={true}
      className="bg-white p-8"
    >
      <div className="flex">
        {page !== 'calendar' ? (
          <div className="w-1/12">
            <div className={cn('h-auto w-12', 'animate-none')}>
              <Genie className="fill-indigo" />
            </div>
          </div>
        ) : null}
        <div className="w-11/12">
          <div className="flex items-center">
            <Typography variant="h5" className="mr-2 text-meta-1 font-semibold" color="darkGrey">
              {title}
            </Typography>
            {page !== 'calendar' ? <SparkIcon className="-ml-0.5 mt-0.5" /> : null}
          </div>
          {page === 'initial' ? <UpsellingContent /> : null}
          {page === 'congrats' ? <CongratsContent /> : null}
          {page === 'calendar' ? <HubSpotMeeting /> : null}
        </div>
      </div>
    </Modal>
  );
};
