export function Download(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.0015C11.8674 16.0015 11.7407 15.9467 11.65 15.85L5.86 10.06C5.76534 9.96612 5.7121 9.83832 5.7121 9.705C5.7121 9.57168 5.76534 9.44388 5.86 9.35L6.06 9.15C6.15408 9.05234 6.28441 8.99803 6.42 9H9V3.5C9 3.22386 9.22386 3 9.5 3H14.5C14.7761 3 15 3.22386 15 3.5V9H17.58C17.7156 8.99803 17.8459 9.05234 17.94 9.15L18.14 9.35C18.2347 9.44388 18.2879 9.57168 18.2879 9.705C18.2879 9.83832 18.2347 9.96612 18.14 10.06L12.35 15.85C12.2593 15.9467 12.1326 16.0015 12 16.0015ZM19 19.5V18.5C19 18.2239 18.7761 18 18.5 18H5.5C5.22386 18 5 18.2239 5 18.5V19.5C5 19.7761 5.22386 20 5.5 20H18.5C18.7761 20 19 19.7761 19 19.5Z"
      />
    </svg>
  );
}
