import { setAuthToken } from '@common/api/axiosInstance';
import { updateHotelProperty } from '@common/api/hotel';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useHotelUpdate = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (hotel: any) => {
      setAuthToken(hotelAuthToken);
      return updateHotelProperty(hotel);
    },
    onSuccess: () => {
      addNotification('success', 'Hotel Update Successfully');
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', 'Failed to update hotel');
    }
  });

  return {
    updateHotel: mutate,
    isLoading,
    isSuccess
  };
};
