import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useDocumentTitle } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';

export const ClientsLayout = () => {
  useDocumentTitle('Client Overview');

  const subMenuLink = [
    {
      id: 'login-client',
      label: 'Login to Client',
      to: 'login'
    },
    {
      id: 'client-map',
      label: 'Client Map',
      to: 'map'
    }
  ];
  return (
    <Page header={<Header title="Client Overview" tabs={subMenuLink} />} fullWidth>
      <Outlet />
    </Page>
  );
};
