import { getEnvVar } from '@common/utils/env';
import { useEffect, useState } from 'react';
declare global {
  interface Window {
    Chargebee: any;
  }
}
type ChargebeeInstance = {
  setPortalSession: (getSession: () => Promise<any>) => void;
  createChargebeePortal: () => any;
  [key: string]: any;
};

export const useChargebee = () => {
  const [cbInstance, setCbInstance] = useState<ChargebeeInstance | null>(null);

  useEffect(() => {
    const nonce = 'random-nonce-value-generated-in-server';
    const script = document.createElement('script');
    script.src = 'https://js.chargebee.com/v2/chargebee.js';
    script.async = true;
    script.nonce = nonce;

    script.onload = () => {
      const chargebeeInstance = window.Chargebee.init({
        site: `${getEnvVar('VITE_CHARGEBEE')}`
      });
      window.Chargebee.registerAgain();
      setCbInstance(chargebeeInstance);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return cbInstance;
};
