import { Input } from '@common/components/atoms/Input';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { Button } from '@common/components/atoms/Button';
import { RadioGroup } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { schema } from '@pages/Client/PricingStrategy/YieldingTags/formSchema';

interface SelectDropdown {
  value: number;
  label: string;
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  aggressivenessData?: SelectDropdown[];
  roomTypesData?: SelectDropdown[];
};

export const AddNewTagModal = ({
  isOpen,
  onClose,
  aggressivenessData,
  roomTypesData
}: ModalProps) => {
  const { pricingSettingsQuery } = useRoomPrices();
  const { savePricingSettings } = useUpdatePricingSettings();
  const { hotelDetails } = useHotelDetails();
  const { t } = useTranslation();

  const gold = useTailwindColor('gold');
  const indigo = useTailwindColor('indigo');
  const orange = useTailwindColor('orange');
  const uiRed = useTailwindColor('uiRed');
  const darkGreen = useTailwindColor('darkGreen');
  const grey = useTailwindColor('grey');

  const ColorOptions = [
    {
      name: 'bg-orange',
      value: orange
    },
    {
      name: 'bg-indigo',
      value: indigo
    },
    {
      name: 'bg-gold',
      value: gold
    },
    {
      name: 'bg-grey',
      value: grey
    },
    {
      name: 'bg-uiRed',
      value: uiRed
    },
    {
      name: 'bg-darkGreen',
      value: darkGreen
    }
  ];

  const defaultValues = {
    tag_name: '',
    color: orange,
    min_adjustment: -10,
    max_adjustment: 10,
    room_types: [],
    speed: 0.5
  };

  const { control, reset, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues
  });
  const { id } = useParams();

  const keyName = dayjs().format('YYYYMMDDHHmmss') + id;

  const onSubmit = async (data: any) => {
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
    const oldRoomTypeTags = latestPricingSettings?.room_type_tags;
    const settings = JSON.stringify({
      ...latestPricingSettings,
      room_type_tags: {
        ...oldRoomTypeTags,
        [keyName]: {
          ...data,
          id: keyName
        }
      }
    });
    await savePricingSettings(settings);
    onClose();
    reset();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      open={isOpen}
      showFooter={false}
      onClose={handleClose}
      size="custom"
      disableCloseOnOk={!formState.isValid}
    >
      <div className="min-w-[480px] max-w-[480px] rounded-xl bg-white">
        <Button
          type="button"
          icon
          className="absolute right-6 top-6 z-30 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={handleClose}
        >
          <Icon.Clear className="h-5 w-5" />
        </Button>
        <div className="p-5">
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            {t('Add new Yielding Tag')}
          </Typography>
          <div className="flex w-full flex-col gap-4 ">
            <div className="flex w-full flex-col gap-4">
              <Controller
                name="tag_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="text"
                    value={value}
                    label={`${t('Tag Name')}*`}
                    placeholder={`${t('Please enter tag name')}`}
                    onChange={onChange}
                    onClear={() => reset({ tag_name: '' })}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <div>
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t(`${hotelDetails?.room_apartment_space_name} Type`)}*
                </Typography>
                <Controller
                  control={control}
                  name="room_types"
                  render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                    <>
                      <MultiSelect
                        placeholder="Select Room Types"
                        name={name}
                        options={roomTypesData}
                        onChange={onChange}
                        value={value}
                        inputClassName="py-[13.5px]"
                        fullWidth
                      />
                      {error && (
                        <div className="flex items-center gap-2 text-error">
                          <Icon.WarningOutline className="w-4" />
                          <Typography element="span" color="error" variant="meta-2">
                            {error.message?.toString()}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex w-full justify-between gap-4">
                <div className="w-1/2">
                  <Controller
                    name="min_adjustment"
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <Input
                        name={name}
                        background="grey"
                        type="number"
                        value={value}
                        label={`${t('Min Adjustment')}*`}
                        placeholder={`${t('Please enter min adjustment')}`}
                        trailingAddon="%"
                        onChange={(e) => onChange(parseInt(e.target.value))}
                        onClear={() => reset({ min_adjustment: 0 })}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                </div>
                <div className="w-1/2">
                  <Controller
                    name="max_adjustment"
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <Input
                        name={name}
                        background="grey"
                        type="number"
                        value={value}
                        label={`${t('Max Adjustment')}*`}
                        placeholder={`${t('Please enter max adjustment')}`}
                        trailingAddon="%"
                        onChange={onChange}
                        onClear={() => reset({ max_adjustment: 0 })}
                        error={!!error}
                        helperMessage={
                          error && (
                            <div className="flex items-center gap-2 text-error">
                              {error ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {error ? error.message?.toString() : ''}
                              </Typography>
                            </div>
                          )
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col gap-4 ">
                <div className="w-full">
                  <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                    {t('Aggressiveness')}*
                  </Typography>
                  <Controller
                    control={control}
                    name="speed"
                    render={({ field: { value, name, onChange } }) => (
                      <SelectDropdown
                        fullWidth
                        placeholder={`${t('Select Aggressiveness')}`}
                        name={name}
                        key={name}
                        value={value || ''}
                        options={aggressivenessData}
                        onChange={(value) => {
                          onChange(value);
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                    {t('Colour')}*
                  </Typography>
                  <div className="w-full">
                    <div className="mx-auto w-full max-w-md">
                      <Controller
                        name="color"
                        control={control}
                        defaultValue={orange}
                        render={({ field }) => (
                          <RadioGroup value={field.value} onChange={field.onChange}>
                            <RadioGroup.Label className="sr-only">Color</RadioGroup.Label>
                            <div className="flex flex-row items-center gap-2">
                              {ColorOptions.map((color) => (
                                <RadioGroup.Option
                                  key={color.name}
                                  value={color.value}
                                  className={`relative flex h-9 w-9 cursor-pointer rounded-full ${color.name}`}
                                >
                                  {({ checked }) => (
                                    <>
                                      <div className="flex w-full items-center justify-center">
                                        {checked && (
                                          <div className="shrink-0 text-white">
                                            <CheckIcon className="h-5 w-5" />
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => onClose()}
              className="text-copyTextGrey"
            >
              {t('Cancel')}
            </Button>
            <Button type="button" intent="primary" onClick={handleSubmit(onSubmit)}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
