import { Button } from '@common/components/atoms/Button';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Icon } from '@common/components/foundations/icons';
import { GenieAlt } from '@common/components/foundations/Logo/GenieAlt';
import { Typography } from '@common/components/foundations/Typography';
import { useFeaturesStore } from '@common/store/features';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

export const UPLOAD_NEXT_OPTIONS = [
  { value: 1, label: '1 Week', comparisonValue: 7 },
  { value: 2, label: '2 Weeks', comparisonValue: 14 },
  { value: 3, label: '1 Month', comparisonValue: 30 },
  { value: 4, label: '3 Months', comparisonValue: 90 },
  { value: 5, label: '6 Months', comparisonValue: 180 },
  { value: 6, label: '9 Months', comparisonValue: 270 },
  { value: 7, label: '12 Months', comparisonValue: 360 },
  { value: 8, label: '18 Months', comparisonValue: 540 }
];

const SKIP_OPTIONS = [
  { value: 1, label: '1 Week', comparisonValue: 7 },
  { value: 2, label: '2 Weeks', comparisonValue: 14 },
  { value: 3, label: '1 Month', comparisonValue: 30 },
  { value: 4, label: '2 Months', comparisonValue: 60 },
  { value: 5, label: '3 Months', comparisonValue: 90 },
  { value: 0, label: 'Upload All', comparisonValue: 0 }
];

export type OnOkArgument = {
  uploadNextType: number;
  skipUploadType: number;
};

export interface AutoUploadProps {
  onClose: (bool: false) => void;
  onOk: (arg: OnOkArgument) => void;
  isLoading?: boolean;
}

export const AutoUploadModal = ({ isLoading, onClose, onOk }: AutoUploadProps) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const [uploadNextType, setUploadNextType] = useState<number>();

  const [skipType, setSkipType] = useState<number>();

  // Set default upload skip type/next type effect
  useEffect(() => {
    if (!hotelDetails) return;

    hotelDetails.update_to_pms_skip_type
      ? setSkipType(hotelDetails.update_to_pms_skip_type)
      : setSkipType(SKIP_OPTIONS[5].value);

    hotelDetails.update_to_pms_type
      ? setUploadNextType(hotelDetails.update_to_pms_type)
      : setUploadNextType(UPLOAD_NEXT_OPTIONS[6].value);
  }, [hotelDetails]);

  const selectedUploadNextOption = useMemo(() => {
    return UPLOAD_NEXT_OPTIONS.find((o) => o.value === uploadNextType);
  }, [uploadNextType]);

  const selectedSkipOption = useMemo(() => {
    return SKIP_OPTIONS.find((o) => o.value === skipType);
  }, [skipType]);

  useEffect(() => {
    if (!selectedUploadNextOption) return;

    if (selectedUploadNextOption.comparisonValue <= selectedSkipOption!.comparisonValue) {
      setError('Sorry. this needs to be shorter than time period to upload!');
    } else {
      setError('');
    }
  }, [selectedSkipOption, selectedUploadNextOption]);

  return (
    <div className="flex flex-col items-center overflow-hidden rounded-xl bg-indigo md:flex-row">
      <div className="px-10 py-5 md:py-0">
        <GenieAlt width={91} height="100%" />
      </div>
      <div className="flex-1 bg-white">
        <Button
          type="button"
          icon
          className="absolute right-8 top-8 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={() => {
            onClose(false);
          }}>
          <Icon.Clear className="h-5 w-5" />
        </Button>
        {step === 0 && (
          <>
            <div className="py-10 pl-10 pr-20">
              <h2 className="max-w-[256px] text-h6 font-medium">
                {t('Auto-upload is a great decision!')}
              </h2>

              <div className="mt-3 max-w-[245px] text-paragraph-3 text-copyTextGrey">
                {t(
                  `With auto-upload, prices will be uploaded automatically to your ${
                    hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                  } several times a day. This unlocks your full potential and will further increase your revenue, while you get other things done.`
                )}
              </div>
            </div>
            <div className="border-t border-lightGrey bg-lightGrey-light py-5 pr-6 text-right">
              <Button
                onClick={() => onClose(false)}
                intent="text"
                className="mr-2 text-copyTextGrey">
                {t('Cancel')}
              </Button>
              <Button onClick={() => setStep(1)} intent="primary">
                {t('Start')}
              </Button>
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <div className="px-8 py-10">
              <Typography
                element="span"
                variant="paragraph-1"
                className="text-paragraph-1-medium"
                color="darkGrey">
                {t('What time period do you want to auto-upload?')}
              </Typography>

              <div className="mt-7 flex justify-between">
                <div className="flex-1">
                  <span className="text-paragraph-2-medium">{t('Upload Next:')}</span>

                  <RadioGroup
                    className="mt-3"
                    labelClassName="text-sm"
                    options={[
                      ...UPLOAD_NEXT_OPTIONS.filter(
                        (option) => !(option.value === 8 && !features?.includes(23))
                      ).map((o) => ({ ...o, label: t(o.label) }))
                    ]}
                    value={uploadNextType}
                    disableSelector={(o) =>
                      o.comparisonValue <= selectedSkipOption!.comparisonValue
                    }
                    onChange={setUploadNextType}></RadioGroup>
                </div>

                <div className="flex-1">
                  <span className="text-paragraph-2-medium">{t("Don't Upload Next:")}</span>
                  <RadioGroup
                    className="mt-3"
                    labelClassName="text-sm"
                    options={[...SKIP_OPTIONS.map((o) => ({ ...o, label: t(o.label) }))]}
                    value={skipType}
                    onChange={setSkipType}></RadioGroup>

                  {error && (
                    <span className="mt-2 block text-meta-1 leading-normal text-error">
                      {error}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="border-t border-lightGrey bg-lightGrey-light py-5 pr-6 text-right">
              <Button
                onClick={() => onClose(false)}
                intent="text"
                className="mr-2 text-copyTextGrey">
                {t('Cancel')}
              </Button>
              <Button
                disabled={!!error || isLoading}
                onClick={() =>
                  onOk({
                    skipUploadType: skipType!,
                    uploadNextType: uploadNextType!
                  })
                }
                intent="primary"
                isLoading={isLoading}>
                {t('Turn on Auto-Upload')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const EditAutoUploadModal = ({ isLoading, onClose, onOk }: AutoUploadProps) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { hotelDetails } = useHotelDetails();

  const [error, setError] = useState('');
  const [uploadNextType, setUploadNextType] = useState<number>(hotelDetails?.update_to_pms_type);
  const [skipType, setSkipType] = useState<number>(hotelDetails?.update_to_pms_skip_type);

  // Set default upload skip type/next type effect
  useEffect(() => {
    if (!hotelDetails) return;

    hotelDetails.update_to_pms_skip_type
      ? setSkipType(hotelDetails.update_to_pms_skip_type)
      : setSkipType(SKIP_OPTIONS[5].value);

    hotelDetails.update_to_pms_type
      ? setUploadNextType(hotelDetails.update_to_pms_type)
      : setUploadNextType(UPLOAD_NEXT_OPTIONS[6].value);
  }, [hotelDetails]);

  const selectedUploadNextOption = useMemo(() => {
    return UPLOAD_NEXT_OPTIONS.find((o) => o.value === uploadNextType);
  }, [uploadNextType]);

  const selectedSkipOption = useMemo(() => {
    return SKIP_OPTIONS.find((o) => o.value === skipType);
  }, [skipType]);

  useEffect(() => {
    if (!selectedUploadNextOption) return;

    if (selectedUploadNextOption.comparisonValue <= (selectedSkipOption?.comparisonValue || 0)) {
      setError('Sorry. this needs to be shorter than time period to upload!');
    } else {
      setError('');
    }
  }, [selectedSkipOption, selectedUploadNextOption]);

  return (
    <div className="flex w-[560px] items-center overflow-hidden rounded-xl bg-indigo">
      <div className="px-10">
        <GenieAlt width={91} height="100%" />
      </div>
      <div className="flex-1 bg-white">
        <Button
          type="button"
          icon
          className="absolute right-8 top-8 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={() => {
            onClose(false);
          }}>
          <Icon.Clear className="h-5 w-5" />
        </Button>

        <div className="px-8 py-10">
          <Typography
            element="span"
            variant="paragraph-1"
            className="text-paragraph-1-medium"
            color="darkGrey">
            {t('What time period do you want to auto-upload?')}
          </Typography>

          <div className="mt-7 flex justify-between">
            <div className="flex-1">
              <span className="text-paragraph-2-medium">{t('Upload Next:')}</span>

              <RadioGroup
                className="mt-3"
                labelClassName="text-sm"
                options={UPLOAD_NEXT_OPTIONS.filter(
                  (option) => !(option.value === 8 && !features?.includes(23))
                ).map((o) => ({ ...o, label: t(o.label) }))}
                value={uploadNextType}
                disableSelector={(o) =>
                  o.comparisonValue <= (selectedSkipOption?.comparisonValue || 0)
                }
                onChange={setUploadNextType}></RadioGroup>
            </div>

            <div className="flex-1">
              <span className="text-paragraph-2-medium">{t("Don't Upload Next:")}</span>
              <RadioGroup
                className="mt-3"
                labelClassName="text-sm"
                options={[...SKIP_OPTIONS.map((o) => ({ ...o, label: t(o.label) }))]}
                value={skipType}
                onChange={setSkipType}></RadioGroup>

              {error && (
                <span className="mt-2 block text-meta-1 leading-normal text-error">{error}</span>
              )}
            </div>
          </div>
        </div>
        <div className="border-t border-lightGrey bg-lightGrey-light py-5 pr-6 text-right">
          <Button onClick={() => onClose(false)} intent="text" className="mr-2 text-copyTextGrey">
            {t('Cancel')}
          </Button>
          <Button
            disabled={!!error || isLoading}
            onClick={() =>
              onOk({
                skipUploadType: skipType!,
                uploadNextType: uploadNextType!
              })
            }
            intent="primary"
            isLoading={isLoading}>
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
