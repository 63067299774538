export function RemoveFromTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4H19.5C19.7761 4 20 4.22386 20 4.5V5.5C20 5.77614 19.7761 6 19.5 6H4.5C4.22386 6 4 5.77614 4 5.5V4.5C4 4.22386 4.22386 4 4.5 4H9V3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V4ZM5 8L5.87 20.14C5.94365 21.1896 6.81787 22.0026 7.87 22H16.15C17.2021 22.0026 18.0764 21.1896 18.15 20.14L19 8H5ZM15.09 16.86C15.1867 16.9507 15.2415 17.0774 15.2415 17.21C15.2415 17.3426 15.1867 17.4693 15.09 17.56L14.56 18.09C14.4693 18.1867 14.3426 18.2415 14.21 18.2415C14.0774 18.2415 13.9507 18.1867 13.86 18.09L12 16.24L10.14 18.09C10.0493 18.1867 9.92259 18.2415 9.79 18.2415C9.65741 18.2415 9.53073 18.1867 9.44 18.09L8.91 17.56C8.81332 17.4693 8.75848 17.3426 8.75848 17.21C8.75848 17.0774 8.81332 16.9507 8.91 16.86L10.76 15L8.91 13.14C8.81332 13.0493 8.75848 12.9226 8.75848 12.79C8.75848 12.6574 8.81332 12.5307 8.91 12.44L9.44 11.91C9.53073 11.8133 9.65741 11.7585 9.79 11.7585C9.92259 11.7585 10.0493 11.8133 10.14 11.91L12 13.76L13.86 11.91C13.9507 11.8133 14.0774 11.7585 14.21 11.7585C14.3426 11.7585 14.4693 11.8133 14.56 11.91L15.09 12.44C15.1867 12.5307 15.2415 12.6574 15.2415 12.79C15.2415 12.9226 15.1867 13.0493 15.09 13.14L13.24 15L15.09 16.86Z"
      />{' '}
    </svg>
  );
}
