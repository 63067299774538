import { WarningMessage, useWarnings } from '@common/store/warnings';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';

export const WarningMessages = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  const { warnings, removeWarning, ignoreWarning } = useWarnings();

  useEffect(() => {
    if (warnings.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [warnings]);

  const removeWarningMessages = async () => {
    await Promise.all(
      warnings.map(async ({ id, onConfirm }) => {
        if (onConfirm) await onConfirm();
        await removeWarning(id);
      })
    );
    setOpen(false);
  };

  const ignoreWarningMessages = async () => {
    await Promise.all(
      warnings.map(async ({ id, onCancel }) => {
        if (onCancel) await onCancel();
        await ignoreWarning(id);
      })
    );
    setOpen(false);
  };

  const getWarningTitle = (warning?: WarningMessage): string | null => {
    if (warning?.customHeaderTitle) {
      return t(warning.customHeaderTitle);
    }
    switch (warning?.type) {
      case 'error':
        return t('Error');
      case 'success':
        return t('Success');
      case 'warning':
        return t('Warning');
      default:
        return null;
    }
  };

  return (
    <Transition.Root appear={true} show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          ignoreWarningMessages();
          setOpen(false);
        }}>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-out duration-150"
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in duration-100"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0">
          <div className="fixed inset-0 bg-darkGrey bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={cn(
                      'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10',
                      warnings[0]?.type === 'error' ? 'bg-error' : null,
                      warnings[0]?.type === 'success' ? 'bg-uiGreen' : null,
                      warnings[0]?.type === 'warning' ? 'bg-orange' : null
                    )}>
                    {warnings[0]?.type === 'error' ? (
                      <Icon.ErrorOutlined className="h-6 w-6 text-white" aria-hidden="true" />
                    ) : null}
                    {warnings[0]?.type === 'success' ? (
                      <Icon.CheckList className="h-6 w-6 text-white" aria-hidden="true" />
                    ) : null}
                    {warnings[0]?.type === 'warning' ? (
                      <Icon.WarningOutline className="h-6 w-6 text-orange" aria-hidden="true" />
                    ) : null}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as={Typography}
                      element="h3"
                      variant="h6"
                      className="font-semibold"
                      color="darkGrey">
                      {getWarningTitle(warnings[0])}
                    </Dialog.Title>
                    {warnings.map(
                      ({ id, message, title, ignore }) =>
                        !ignore && (
                          <div key={id} className="mt-4">
                            <Typography
                              element="h4"
                              variant="meta-1"
                              className="text-meta-1-medium"
                              color="darkGrey">
                              {title}
                            </Typography>
                            <Typography element="p" variant="paragraph-3">
                              {message}
                            </Typography>
                          </div>
                        )
                    )}
                  </div>
                </div>
                <div className="mt-5 gap-2 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button type="button" intent="primary" onClick={removeWarningMessages}>
                    {warnings.filter((warning) => !warning.ignore).length > 1
                      ? t('Confirm')
                      : warnings.filter((warning) => !warning.ignore)[0]?.dismissLabel ??
                        t('Confirm')}
                  </Button>
                  {warnings[0]?.showIgnoreLabel ? (
                    <Button type="button" intent="outline" onClick={ignoreWarningMessages}>
                      {warnings.filter((warning) => !warning.ignore).length > 1
                        ? t('Cancel')
                        : warnings.filter((warning) => !warning.ignore)[0]?.ignoreLabel ??
                          t('Cancel')}
                    </Button>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
