import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@common/components/foundations/Typography';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import {
  useConnectFreetobook,
  useConnectSeekom,
  useConnectCloudbeds
} from '@pages/Client/Account/Integrations/hooks/useSSOConnect';
import { Icon } from '@common/components/foundations/icons';
import { setAuthToken } from '@common/api/axiosInstance';
import { useNotificationsStore } from '@common/store/notifications';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';

export const Connect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetAccountHotel();
  const { addNotification } = useNotificationsStore();
  const { pms, code } = useParams();
  const url = new URL(window.location.href);
  const state = url.searchParams.get('state');
  const {
    mutate: connectFreetobook,
    isPending: isLoadingFreetobook,
    isSuccess: isSuccessFreetobook
  } = useConnectFreetobook();
  const {
    mutate: connectSeekom,
    isPending: isLoadingSeekom,
    isSuccess: isSuccessSeekom
  } = useConnectSeekom();
  const {
    mutate: connectCloudbeds,
    isPending: isLoadingCloudbeds,
    isSuccess: isSuccessCloudbeds
  } = useConnectCloudbeds();

  useEffect(() => {
    if (!code || !data?.token) return;

    setAuthToken(data?.token);

    if (pms === 'freetobook') {
      connectFreetobook({
        code,
        token: data?.token
      });
      return;
    }

    if (pms === 'seekom') {
      connectSeekom({
        code,
        token: data?.token
      });
      return;
    }

    if (pms === 'cloudbeds') {
      connectCloudbeds({
        code,
        token: data?.token
      });
      return;
    }
  }, [pms, code, data]);

  useEffect(() => {
    if (!state) return;

    const stateUrl = new URL(state);

    if (isSuccessFreetobook || isSuccessSeekom || isSuccessCloudbeds) {
      addNotification('success', t('Connection Successful'));
      navigate(stateUrl.pathname);
    }
  }, [isSuccessCloudbeds, isSuccessFreetobook, isSuccessSeekom]);

  const isLoadingConnect = isLoadingFreetobook || isLoadingSeekom || isLoadingCloudbeds;
  const isSuccessConnect = isSuccessFreetobook || isSuccessSeekom || isSuccessCloudbeds;

  const pmsName: { [key: string]: string } = {
    freetobook: 'freetobook',
    seekom: 'Seekom',
    cloudbeds: 'Cloudbeds'
  };

  return (
    <div className="relative flex w-full flex-1 flex-col justify-center overflow-y-auto px-4 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className=" mx-auto max-h-[600px] w-full 2xl:w-3/4">
        <div className="sticky">
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
              {t('Hi, there!')}
            </Typography>
            <div className="flex items-start justify-between gap-2">
              <div className="w-11/12">
                <Typography element="p" variant="paragraph-1" color="copyTextGrey">
                  We are just updating your connection with {pms ? pmsName[pms] : null}. This should
                  only take a few seconds.
                </Typography>
              </div>
              <div className="shrink-0">
                {isLoadingConnect ? <Icon.LoadingCircle className="text-darkGrey" /> : null}
                {isSuccessConnect ? <Icon.CheckList className="text-uiGreen" /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
