import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash-es';
import { ReactNode, useMemo } from 'react';
import { Colors, backgroundColors } from '@common/components/foundations/colorVariants';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Flex, SegmentedControl } from '@mantine/core';
import { ReportType } from '@common/api/dashboard/types';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { calculateDifference } from '@common/utils/calculateDifference';
import { ReportKey } from '@pages/Client/Dashboard/pages/Analytics/Analytics';
import { CompareToFilter, OccupancyTypeFilter } from '@pages/Client/Dashboard/store/dashboard';
import { OCCUPANCY_TYPE_OPTIONS } from '@pages/Client/Calendar/constants';

export function Card({
  children,
  backgroundColor,
  className
}: {
  children: React.ReactNode;
  backgroundColor: Colors;
  className?: string;
}) {
  return (
    <div className={clsx('h-full rounded-lg shadow', backgroundColors[backgroundColor], className)}>
      <div className="grid h-full grid-flow-row p-4">{children}</div>
    </div>
  );
}

export function PerformanceCard({
  topLeftValue,
  topLeftText,
  topLeftTooltipIcon,
  diff,
  sameTimeLastYear,
  sameTimeLastYearTooltipIcon,
  totalLastYear,
  totalLastYearTooltipIcon,
  negative,
  highlight,
  small,
  isLoading,
  forecast,
  forecastTooltipIcon,
  budget,
  budgetTooltipIcon,
  showNegativeBg,
  chart,
  showPercentagePoint
}: {
  topLeftValue: string | number;
  topLeftText: string;
  topLeftTooltipIcon?: ReactNode;
  sameTimeLastYear: string | number;
  sameTimeLastYearTooltipIcon?: ReactNode;
  totalLastYear?: string | number;
  totalLastYearTooltipIcon?: ReactNode;
  diff?: string;
  small?: boolean;
  isLoading?: boolean;
  negative?: boolean;
  highlight?: boolean;
  forecast?: string;
  forecastTooltipIcon?: ReactNode;
  budget?: string;
  budgetTooltipIcon?: ReactNode;
  showNegativeBg?: boolean;
  chart?: React.ReactNode;
  showPercentagePoint?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Card
      backgroundColor={
        showNegativeBg && negative && highlight
          ? 'uiRed'
          : !negative && highlight
            ? 'darkGreen'
            : 'white'
      }
      className={clsx(
        'relative grid min-h-[200px] w-full min-w-[230px] max-w-sm snap-start',
        isLoading ? 'animate-pulse bg-grey-reduced' : null
      )}
    >
      {chart ? (
        <div className="absolute left-0 right-0 top-16 w-full opacity-60">{chart}</div>
      ) : null}
      <div className="grid h-full">
        <div className="grid grid-flow-col justify-between">
          <div className="flex flex-col">
            {isLoading ? (
              <Skeleton className="h-7 w-16" />
            ) : (
              <Typography
                element="span"
                variant="h6"
                color={!showNegativeBg && highlight ? 'white' : 'indigo'}
              >
                {topLeftValue}
              </Typography>
            )}
            <div className="flex items-center gap-1">
              <Typography
                element="span"
                variant="meta-2"
                color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}
              >
                {topLeftText}
              </Typography>
              {topLeftTooltipIcon}
            </div>
          </div>
          {isLoading ? (
            <Skeleton className="h-5 w-14 rounded-full" />
          ) : diff ? (
            <div className="flex h-6 items-center gap-x-1 rounded-full bg-white px-2 py-1">
              {negative ? (
                <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
              ) : (
                <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
              )}
              <Typography element="span" variant="meta-2" color={negative ? 'uiRed' : 'uiGreen'}>
                {diff} {showPercentagePoint ? t('pt.') : null}
              </Typography>
            </div>
          ) : null}
        </div>

        <div
          className={clsx(
            'mt-8 grid place-content-end justify-stretch gap-y-2',
            forecast && budget ? 'grid-cols-2 ' : 'grid-cols-1'
          )}
        >
          <div className="flex flex-col justify-between gap-x-5">
            <div className="flex flex-col">
              {isLoading ? (
                <Skeleton className="h-5 w-12" />
              ) : (
                <Typography
                  element="span"
                  variant="paragraph-2"
                  color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}
                >
                  {sameTimeLastYear}
                </Typography>
              )}
              <div className="flex items-center gap-1">
                <Typography
                  element="span"
                  variant="meta-2"
                  color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}
                  className={clsx(forecast && budget ? 'truncate' : null)}
                >
                  {t('Same Time Last Year')}
                </Typography>
                {sameTimeLastYearTooltipIcon}
              </div>
            </div>

            {small ? null : (
              <div className="flex flex-col">
                {isLoading ? (
                  <Skeleton className="h-5 w-12" />
                ) : totalLastYear ? (
                  <Typography
                    element="span"
                    variant="paragraph-2"
                    color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}
                  >
                    {totalLastYear}
                  </Typography>
                ) : null}
                <div className="flex items-center gap-1">
                  <Typography
                    element="span"
                    variant="meta-2"
                    color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}
                  >
                    {t('Total Last Year')}
                  </Typography>
                  {totalLastYearTooltipIcon}
                </div>
              </div>
            )}
          </div>
          {forecast && budget ? (
            <>
              <div className="flex flex-col justify-between gap-x-5">
                <div className="flex flex-col items-end text-right">
                  {isLoading ? (
                    <div className="flex w-full justify-end">
                      <Skeleton className="h-5 w-12" />
                    </div>
                  ) : (
                    <Typography
                      element="span"
                      variant="paragraph-2"
                      color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}
                    >
                      {forecast}
                    </Typography>
                  )}
                  <div className="flex items-center gap-1">
                    <Typography
                      element="span"
                      variant="meta-2"
                      color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}
                    >
                      {t('Forecast')}
                    </Typography>
                    {forecastTooltipIcon}
                  </div>
                </div>

                <div className="flex flex-col items-end text-right">
                  {isLoading ? (
                    <div className="flex w-full justify-end">
                      <Skeleton className="h-5 w-12" />
                    </div>
                  ) : (
                    <Typography
                      element="span"
                      variant="paragraph-2"
                      color={!showNegativeBg && highlight ? 'white' : 'darkGrey'}
                    >
                      {budget}
                    </Typography>
                  )}
                  <div className="flex items-center gap-1">
                    <Typography
                      element="span"
                      variant="meta-2"
                      className="truncate"
                      color={!showNegativeBg && highlight ? 'white' : 'copyTextGrey'}
                    >
                      {t('Budget excl. Services')}
                    </Typography>
                    {budgetTooltipIcon}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Card>
  );
}

type SegmentItem = {
  id: number;
  rawValue?: number;
  segment: string;
  subtotalKey: string;
  value: string;
};

interface AdditionalDataItem {
  key: string;
  label: string;
  data: ReportType | null;
}

interface AdditionalData {
  ly_rooms_sold?: AdditionalDataItem;
  rooms_sold_contribution?: AdditionalDataItem;
  ly: AdditionalDataItem;
  revenue_contribution?: AdditionalDataItem;
}

export function AnalyticsCard({
  reportType,
  compareType,
  occupancyType,
  setOccupancyType,
  total,
  totalLabel,
  totalTooltipIcon,
  sortByIcon,
  segment,
  isLoading,
  formatter,
  previousYearData,
  rawTotal,
  numberOfStaysData,
  previousYearNumberOfStaysData,
  showCompareTo,
  isCustomDateRange
}: {
  reportType: ReportKey;
  compareType: CompareToFilter;
  occupancyType: OccupancyTypeFilter;
  setOccupancyType: (option: OccupancyTypeFilter) => void;
  rawTotal?: number;
  total: string;
  totalLabel: string;
  totalTooltipIcon?: ReactNode;
  sortByIcon?: ReactNode;
  segment: SegmentItem[];
  isLoading?: boolean;
  formatter: (value: number | undefined) => number | string | undefined | null;
  previousYearData: ReportType;
  numberOfStaysData: ReportType;
  previousYearNumberOfStaysData: ReportType;
  showCompareTo?: boolean;
  isCustomDateRange?: boolean;
}) {
  const { t } = useTranslation();

  const isContrAvailable = ['revenue', 'occupancy'].includes(reportType);
  const isLYAvailable = !isCustomDateRange;

  const showPercentagePoint =
    isLYAvailable &&
    reportType === 'occupancy' &&
    occupancyType === 'pct' &&
    compareType !== 'contribution-in-pct';

  const segmentData = segment.reduce(
    (acc, item) => {
      acc[item.subtotalKey as keyof ReportType] = item.rawValue;
      return acc;
    },
    { total: 0 } as ReportType
  );

  const additionalData: AdditionalData = {
    ...(reportType === 'occupancy'
      ? {
          ly_rooms_sold: {
            key: 'ly_rooms_sold',
            label: 'Rooms Sold Last Year',
            data: previousYearNumberOfStaysData
          }
        }
      : {}),
    ...(reportType === 'occupancy'
      ? {
          rooms_sold_contribution: {
            key: 'rooms_sold_contribution',
            label: `Rooms Sold Contr.`,
            data: null
          }
        }
      : {}),
    ly: {
      key: 'ly',
      label: `${t(totalLabel)} Last Year`,
      data: previousYearData
    },
    ...(reportType === 'revenue'
      ? {
          revenue_contribution: {
            key: 'revenue_contribution',
            label: `Revenue Contr.`,
            data: null
          }
        }
      : {})
  };

  const getContributionInPct = (value: number | undefined, total: number | undefined) => {
    // Ensure both value and total are valid numbers, and total is greater than zero
    if (value !== undefined && total !== undefined && total > 0) {
      return (value / total) * 100;
    }
  };

  const calculateDiffPrevYear = (currentValue?: number, previousYearValue?: number) => {
    if (!isLYAvailable || !currentValue || !previousYearValue) return null;

    if (showPercentagePoint) {
      return currentValue - previousYearValue;
    }
    const { percentageChange } = calculateDifference(currentValue, previousYearValue);
    return percentageChange || null;
  };

  const compareTitle = useMemo(() => {
    if (compareType === 'contribution-in-pct') {
      return 'Contr. in %';
    } else if (compareType === 'ly') {
      return 'vs. Last Year';
    } else {
      return '-';
    }
  }, [compareType, reportType]);

  const renderAdditionalData = (data: AdditionalDataItem, subtotalKey: keyof ReportType) => {
    const renderRoomsSoldData = () => data.data?.[subtotalKey] ?? 'n.A.';

    const renderRevenueContribution = () =>
      formattedPercentage(getContributionInPct(segmentData[subtotalKey], rawTotal));

    const renderRoomsSoldContribution = () =>
      formattedPercentage(
        getContributionInPct(numberOfStaysData[subtotalKey], numberOfStaysData.total)
      );

    if (data.data) {
      if (!isLYAvailable) return 'n.A.';
      return data.key === 'ly_rooms_sold'
        ? renderRoomsSoldData()
        : formatter(data.data?.[subtotalKey]);
    }

    if (data.key === 'revenue_contribution') return renderRevenueContribution();
    if (data.key === 'rooms_sold_contribution') return renderRoomsSoldContribution();

    return 'n.A.';
  };

  const totalDiffPrevYearCurrent = calculateDiffPrevYear(rawTotal, previousYearData.total);

  const totalDiffPrevYearNumberOfStays = isLYAvailable
    ? calculateDifference(numberOfStaysData.total, previousYearNumberOfStaysData.total)
        .percentageChange
    : null;

  const totalDiff =
    reportType === 'occupancy' && occupancyType === 'rooms_sold'
      ? totalDiffPrevYearNumberOfStays
      : totalDiffPrevYearCurrent;

  const renderSegmentTitle = () => {
    return (
      <div className="flex flex-row gap-1">
        <Typography element="span" variant="h6" color="indigo">
          {reportType === 'occupancy' && occupancyType === 'rooms_sold'
            ? numberOfStaysData.total || 'n.A.'
            : total}
        </Typography>
        {showCompareTo && compareType === 'ly' && totalDiff ? (
          <div className="flex h-6 justify-end items-center gap-x-1 min-w-[50px]">
            <div>
              {totalDiff < 0 ? (
                <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
              ) : (
                <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
              )}
            </div>
            <Typography element="span" variant="meta-2" color={totalDiff < 0 ? 'uiRed' : 'uiGreen'}>
              {formattedPercentage(totalDiff)} {showPercentagePoint ? t('pt.') : null}
            </Typography>
          </div>
        ) : null}
      </div>
    );
  };

  const renderSegmentRow = ({
    item,
    currentValue,
    diff
  }: { item: SegmentItem; currentValue: string; diff?: number | null }) => {
    return (
      <div key={item.id} className="flex max-w-full flex-1 items-center gap-x-2 overflow-hidden">
        <Typography
          element="span"
          variant="meta-2"
          color="copyTextGrey"
          className="truncate max-w-[10rem] sm:max-w-[12rem] md:max-w-[16rem]"
        >
          {truncate(t(item.segment) as string, { length: 30 })}
        </Typography>
        <div className="flex-grow" />
        <Typography
          element="span"
          variant="paragraph-2"
          className="shrink-0 text-right text-paragraph-1-medium"
          color="darkGrey"
        >
          {reportType === 'occupancy' && occupancyType === 'rooms_sold'
            ? numberOfStaysData[item.subtotalKey as keyof ReportType]
            : currentValue}
        </Typography>
        {showCompareTo ? (
          <div>
            {diff ? (
              <div className="flex h-6 justify-end items-center gap-x-1 min-w-[50px]">
                {compareType !== 'contribution-in-pct' ? (
                  <div>
                    {diff && diff < 0 ? (
                      <Icon.TrendingDown className="h-3 w-3 fill-uiRed" />
                    ) : (
                      <Icon.TrendingUp className="h-3 w-3 fill-uiGreen" />
                    )}
                  </div>
                ) : null}
                <Typography
                  element="span"
                  variant="meta-2"
                  color={
                    compareType === 'contribution-in-pct'
                      ? 'darkGrey'
                      : diff && diff < 0
                        ? 'uiRed'
                        : 'uiGreen'
                  }
                >
                  {formattedPercentage(diff)} {showPercentagePoint ? t('pt.') : null}
                </Typography>
              </div>
            ) : (
              <div className="min-w-[50px] text-right">
                <Typography
                  element="span"
                  variant="paragraph-2"
                  className="shrink-0 text-right text-paragraph-1-medium"
                  color="darkGrey"
                >
                  -
                </Typography>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Card
      backgroundColor="white"
      className={clsx('min-h-[180px]', isLoading ? 'animate-pulse bg-grey-reduced' : null)}
    >
      <div className="grid w-full space-y-3">
        <div className="flex flex-1 items-start justify-between">
          <div className="flex flex-col">
            {isLoading ? (
              <Skeleton className="h-5 w-12" />
            ) : (
              <TooltipProvider>
                <Tooltip>
                  {showCompareTo ? (
                    <TooltipTrigger>{renderSegmentTitle()}</TooltipTrigger>
                  ) : (
                    <>{renderSegmentTitle()}</>
                  )}
                  <TooltipContent side="bottom" align="start" className="max-w-xs">
                    <Flex direction="column" className="min-w-[200px]">
                      {reportType === 'occupancy' ? (
                        <Flex direction="row" justify="space-between" className="mb-1 w-full">
                          <Typography variant="meta-2" color="white">
                            {t('Rooms Sold Last Year')}
                          </Typography>
                          <Typography variant="meta-2" className="text-meta-2-medium" color="white">
                            {isLYAvailable ? previousYearNumberOfStaysData.total : 'n.A.'}
                          </Typography>
                        </Flex>
                      ) : null}
                      <Flex direction="row" justify="space-between" className="mb-1 w-full">
                        <Typography variant="meta-2" color="white">
                          {t(`${totalLabel} Last Year`)}
                        </Typography>
                        <Typography variant="meta-2" className="text-meta-2-medium" color="white">
                          {isLYAvailable ? formatter(previousYearData.total) : 'n.A.'}
                        </Typography>
                      </Flex>
                    </Flex>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            <div className="flex items-center gap-1">
              <Typography element="span" variant="meta-2" color="copyTextGrey">
                {truncate(totalLabel, { length: 30 })}
              </Typography>
              {totalTooltipIcon}
              {sortByIcon}
            </div>
          </div>
          {showCompareTo && reportType === 'occupancy' ? (
            <div className="flex flex-col">
              <SegmentedControl
                size="xs"
                radius="xl"
                value={occupancyType}
                onChange={(option) => setOccupancyType(option as OccupancyTypeFilter)}
                data={OCCUPANCY_TYPE_OPTIONS.map((occupancyType) => ({
                  label: <div className="min-w-[40px] px-1">{occupancyType.label}</div>,
                  value: occupancyType.value
                }))}
              />
            </div>
          ) : null}
        </div>

        {showCompareTo ? (
          <Typography color="copyTextGrey" className="text-right text-xs">
            {t(compareTitle)}
          </Typography>
        ) : null}

        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} className="h-5 w-full" />
            ))
          : segment.map((item, index) => {
              const currentValue = item.value;
              const currentRawValue = item.rawValue;
              const previousYearValue = previousYearData[item.subtotalKey as keyof ReportType];

              const diffPrevYearCurrent = calculateDiffPrevYear(currentRawValue, previousYearValue);

              const contrValue = isContrAvailable
                ? getContributionInPct(currentRawValue, rawTotal)
                : null;

              const diffCurrent =
                compareType === 'ly'
                  ? diffPrevYearCurrent
                  : isContrAvailable && contrValue
                    ? contrValue
                    : null;

              const currentNumberOfStaysRawValue =
                numberOfStaysData[item.subtotalKey as keyof ReportType];
              const previousYearNumberOfStaysRawValue =
                previousYearNumberOfStaysData[item.subtotalKey as keyof ReportType];

              const diffPrevYearNumberOfStays = isLYAvailable
                ? calculateDifference(
                    currentNumberOfStaysRawValue,
                    previousYearNumberOfStaysRawValue
                  ).percentageChange
                : null;

              const contrNumberOfStaysValue = isContrAvailable
                ? getContributionInPct(currentNumberOfStaysRawValue, numberOfStaysData.total)
                : null;

              const diffNumberOfStays =
                compareType === 'ly'
                  ? diffPrevYearNumberOfStays
                  : isContrAvailable && contrNumberOfStaysValue
                    ? contrNumberOfStaysValue
                    : null;

              let diff = diffCurrent;

              if (
                reportType === 'occupancy' &&
                (occupancyType === 'rooms_sold' ||
                  (occupancyType === 'pct' && compareType === 'contribution-in-pct'))
              ) {
                diff = diffNumberOfStays || null;
              }

              return (
                <TooltipProvider key={index}>
                  <Tooltip>
                    {showCompareTo ? (
                      <TooltipTrigger>
                        {renderSegmentRow({ item, currentValue, diff })}
                      </TooltipTrigger>
                    ) : (
                      <>{renderSegmentRow({ item, currentValue, diff })}</>
                    )}
                    <TooltipContent side="bottom" align="start" className="max-w-xs">
                      <Flex direction="column" className="min-w-[200px]">
                        <Typography color="white" variant="meta-1" className="font-semibold mb-2">
                          {item.segment}
                        </Typography>

                        {Object.values(additionalData).map((data, index) => {
                          return (
                            <Flex
                              key={index}
                              direction="row"
                              justify="space-between"
                              className="mb-1"
                            >
                              <Typography variant="meta-2" color="white">
                                {data.label}
                              </Typography>
                              <Typography
                                variant="meta-2"
                                className="text-meta-2-medium"
                                color="white"
                              >
                                {renderAdditionalData(data, item.subtotalKey as keyof ReportType)}
                              </Typography>
                            </Flex>
                          );
                        })}
                      </Flex>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              );
            })}
      </div>
    </Card>
  );
}
