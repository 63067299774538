import { useMemo } from 'react';
import { getHotelClientDetail } from '@common/api/hotel';
import { useQuery } from '@tanstack/react-query';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useHotelStore } from '@common/store/auth';

export const useHotelDetails = (enabled = true) => {
  const { hotelAuthToken } = useHotelStore();
  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL],
    queryFn: getHotelClientDetail,
    enabled: enabled && !!hotelAuthToken
  });

  const hotelDetails = useMemo(() => {
    return query.data;
  }, [query]);

  const pmsChannelManagerText = useMemo(() => {
    if (!hotelDetails) return '';

    return hotelDetails.is_channel_manager ? 'Channel Manager' : 'PMS';
  }, [hotelDetails]);

  return {
    query,
    hotelDetails,
    pmsChannelManagerText,
    isAutoUploadOn: query?.data?.is_update_to_pms || false
  };
};
