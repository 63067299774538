import { useAuthStore } from '@common/store/auth';
import { formatLocaleNumber } from '@common/utils/formatNumber';
import { de, enGB, enUS, es, fr, it } from 'date-fns/locale';

export const useUserLocale = () => {
  const { user } = useAuthStore();

  const localeMap: Record<string, Locale> = {
    de,
    'en-US': enUS,
    'en-GB': enGB,
    'fr-FR': fr,
    'es-ES': es,
    'it-IT': it
  };

  function getDateFnsLocale(userLanguage: string): Locale {
    return localeMap[userLanguage] || enUS;
  }

  const userLanguage = user?.language || 'en-US';
  const locale = getDateFnsLocale(userLanguage);

  return {
    locale,
    formatLocaleNumber: (num: number) => formatLocaleNumber(num, locale.code)
  };
};
