import { useAuth } from '@clerk/clerk-react';
import { UserData } from '@common/api/hotel/types';
import { Flags } from '@common/constants/flags';
import { ldIdentify } from '@common/helpers/ld-identify';
import { useAuthStore } from '@common/store/auth';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

/**
 * Checks and sets the emails for LaunchDarkly and returns the flags
 * by using the emails array from LocalStorage .
 * @returns {object} isLoaded - boolean, clerkSignedIn - boolean, clerkAvailable - boolean
 */

export function useCheckIfClerkIsAvailableForLogin(): {
  isLoaded: boolean;
  clerkSignedIn: boolean;
  clerkAvailable: boolean;
} {
  const flags = useFlags();
  const ldClient = useLDClient();
  const { userEmails } = useAuthStore();
  const [hookLoaded, setIsLoaded] = useState<boolean>(false);
  const [clerkAvailable, setAuthFlag] = useState<boolean>(false);
  const { isSignedIn, isLoaded: clerkLoaded } = useAuth();

  useEffect(() => {
    const user: Partial<UserData> = { email: userEmails[0] };
    async function checkEmails() {
      if (ldClient) {
        await ldIdentify(ldClient, { clerk: { key: 'clerk-user-emails', emails: userEmails } });
        const authKey = await flags[Flags.ShowClerkLogin];
        setAuthFlag(authKey);
        setIsLoaded(true);
      }
    }
    if (user) checkEmails();
  }, [userEmails, ldClient, flags]);

  const isLoaded = hookLoaded && clerkLoaded;
  const clerkSignedIn = isSignedIn || false;

  return { isLoaded, clerkSignedIn, clerkAvailable };
}
