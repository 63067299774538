import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { Button } from '@common/components/atoms/Button';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { DrawerTabProps } from '@pages/Client/Calendar/components/PriceDrawerContent/types';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash-es';
import { isUTCYesterdayInUserTZ } from '@common/utils/isUTCYesterdayInUserTZ';
import { useUploadPMSPriceModalState } from '@pages/Client/Calendar/components/BulkEdit/store/useUploadPMSPriceModalState';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { useViewStore } from '@common/store/view';

export const AllRoomTypes = ({ currentViewingDate, drawerProps }: DrawerTabProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const { hotelDetails } = useHotelDetails();
  const { pmsList } = useGetHotelPmsList();
  const { sortedHotelRooms } = useHotelRoomsList();
  const { roomPrices, pricingSettings } = useRoomPrices();
  const { setModalState } = useUploadPMSPriceModalState();
  const { isReadOnly } = useViewStore();

  const { hasUploadPriceButton, isOccupancyBased, hasNoUploadPricesButton } =
    useGetDetailProviderConfig();

  useEffect(() => {
    trackEvent('AllRoomTypesOpened');
  }, []);

  const isHideUploadPricesButton =
    !hasUploadPriceButton ||
    isUTCYesterdayInUserTZ(currentViewingDate.format(API_DATE_FORMAT)) ||
    hasNoUploadPricesButton ||
    isReadOnly;

  const isOccupancyPricingEnabled =
    (hotelDetails && isOccupancyBased) ||
    pmsList?.find((pms) => pms.primary)?.configurations?.price_type === 'occupancy_based';

  const isAllRoomsPricing = pricingSettings?.hotel?.all_room_pricing;

  const properties = useMemo(() => {
    const formattedDate = dayjs(currentViewingDate).startOf('day').format(API_DATE_FORMAT);

    return sortedHotelRooms.map((room) => {
      const allClosedRooms = roomPrices?.prices.data?.[formattedDate]?.property?.all_closed_rooms;
      const leftRooms = roomPrices?.prices.data?.[formattedDate]?.property?.all_rooms_inventories;
      return {
        id: room.id,
        name: room.name,
        isReferenceRoom: room.is_reference_room,
        closedRooms: allClosedRooms?.[room.id] ?? 0,
        leftRooms: `${leftRooms?.[room.id] ?? 0} ${t('out of')} ${
          room.is_reference_room
            ? pricingSettings?.rooms.reference?.number_of_rooms
            : pricingSettings?.rooms?.derived?.[room.id]?.number_of_rooms
        }`,
        occupancy: room.default_occupancy ?? room.max_occupancy,
        recommendedPrice: roomPrices?.prices.data?.[formattedDate]?.[room.id]?.price ?? 0
      };
    });
  }, [roomPrices, currentViewingDate, sortedHotelRooms]);

  function handleModal() {
    setModalState(true);
  }

  return (
    <div>
      <div className="flex flex-col gap-y-6 pb-16">
        <LineTable>
          <thead>
            <tr>
              <th>{t(`${hotelDetails?.room_apartment_space_name} Name`)}</th>
              <th>{t(`${hotelDetails?.room_apartment_space_name}s Left`)}</th>
              <th>{t(`Closed ${hotelDetails?.room_apartment_space_name}s`)}</th>
              {isOccupancyPricingEnabled ? <th>{t('Default Occupancy')}</th> : null}
              <th>{t('Recommended/Fix Price')}</th>
            </tr>
          </thead>

          <tbody>
            {properties.map((room, index) => (
              <tr key={index}>
                <td>{truncate(room.name, { length: 30 })}</td>
                <td align="right" className="shrink-0 truncate">
                  {room.leftRooms}
                </td>
                <td align="right">{room.closedRooms}</td>
                {isOccupancyPricingEnabled ? <td align="right">{room.occupancy}</td> : null}
                <td align="right">
                  {isAllRoomsPricing || room.isReferenceRoom
                    ? CurrencyFormatter.format(Math.round(room.recommendedPrice))
                    : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </LineTable>
      </div>
      <div className="absolute bottom-0 right-0 w-full rounded-bl-xl border-t border-grey-reduced bg-lightGrey p-5 text-right">
        <div className="flex justify-end gap-2">
          <Button intent="text" className="text-darkGrey" onClick={() => drawerProps.onClose?.()}>
            {t('Cancel')}
          </Button>
          {isHideUploadPricesButton ? null : (
            <Button intent="primary" onClick={handleModal}>
              {t('Upload Prices')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
