import { getHealthReport } from '@common/api/pricingAlgorithm';
import { HealthReportResponse } from '@common/api/pricingAlgorithm/types';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

type Params = {
  page?: number;
};

export const useHealthReport = ({ page = 1 }: Params) => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, isError, error, isFetching } = useQuery<HealthReportResponse>({
    queryKey: [UtilQueryKeys.HEALTH_REPORT, page],
    queryFn: () => {
      return getHealthReport(page);
    },
    retry: false,
    placeholderData: keepPreviousData
  });

  if (isError) addNotification('fail', 'Failed to get health report');

  return {
    healthReport: data,
    isLoading,
    isFetching,
    error
  };
};
