import { ResellerMember } from '@common/api/account/types';
import { Button } from '@common/components/atoms/Button';
import { Divider } from '@common/components/atoms/Divider';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import {
  useGetResellerList,
  useGetResellerMember,
  useGetSalesList
} from '@common/hooks/useResellerPartner';

import { useUserRole } from '@common/hooks/useUserRole';
import { useHotelStore } from '@common/store/auth';
import { useViewStore } from '@common/store/view';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelUpdate } from '@pages/Client/hooks/useHotelUpdate';
import { useDocumentTitle } from '@mantine/hooks';

interface DealPayload {
  reseller_id: number | null | undefined | string;
  responsible_person_id: number | null | undefined | string;
  token?: string | null;
}

export const Deal = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Deal'));
  const { reseller, isLoading: isResellerLoading } = useGetResellerList();
  const { reseller_member, isLoading: isResellerMemberLoading } = useGetResellerMember();
  const { sales, isLoading: isSalesLoading } = useGetSalesList();
  const { isAdminPartner } = useUserRole();
  const { view } = useViewStore();
  const { hotelAuthToken } = useHotelStore();
  const { hotelDetails } = useHotelDetails();
  const { updateHotel, isLoading: isUpdateLoading, isSuccess: isSuccessUpdate } = useHotelUpdate();
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });
  const [deal, setDeal] = useState<DealPayload>({
    reseller_id: null,
    responsible_person_id: null,
    token: hotelAuthToken
  });

  useEffect(() => {
    setDeal({
      responsible_person_id: hotelDetails?.responsible_person?.id,
      reseller_id: hotelDetails?.reseller?.id
    });
  }, [hotelDetails]);

  const resellerMember = reseller_member?.filter(
    (item: ResellerMember) => item.reseller.id === deal.reseller_id
  );

  useEffect(() => {
    setIsSuccessRef({ current: isSuccessUpdate });
    if (isSuccessUpdate) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSuccessUpdate]);

  return (
    <Page
      header={
        <div className="max-w-4xl">
          <Header
            title="Deal"
            actions={
              <div className="pr-16">
                <Button
                  disabled={isUpdateLoading}
                  isLoading={isUpdateLoading}
                  isSuccess={isSuccessRef.current}
                  onClick={() => {
                    updateHotel(deal);
                  }}>
                  {t('Save')}
                </Button>
              </div>
            }
          />
        </div>
      }>
      <div className="max-w-3xl rounded-sm bg-white p-4">
        <div className="mb-6 flex flex-col gap-6">
          {view === 'admin' ? (
            <>
              <div>
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Sales Team*')}
                </Typography>
                {isSalesLoading ? (
                  <Skeleton className="h-10 w-full" />
                ) : (
                  <SelectDropdown
                    name="sales_team"
                    background="grey"
                    placeholder="Select Sales Team"
                    value={deal.responsible_person_id}
                    options={sales?.map((item) => ({
                      label: `${item.first_name} ${item.last_name}`,
                      value: item.id
                    }))}
                    onChange={(value) => {
                      setDeal(() => ({
                        responsible_person_id: value,
                        reseller_id: ''
                      }));
                    }}
                    fullWidth
                  />
                )}
              </div>

              <div>
                <Divider />
                <Typography element="p" variant="meta-1" className="mb-1 mt-4 text-grey">
                  {t('Partner*')}
                </Typography>
                {isResellerLoading ? (
                  <Skeleton className="h-10 w-full" />
                ) : (
                  <SelectDropdown
                    background="grey"
                    name="select_partner"
                    placeholder="Select Partner"
                    value={deal.reseller_id}
                    options={reseller?.map((item) => ({
                      label: item.name,
                      value: item.id
                    }))}
                    onChange={(value) => {
                      setDeal(() => ({
                        reseller_id: value,
                        responsible_person_id: ''
                      }));
                    }}
                    fullWidth
                  />
                )}
              </div>
            </>
          ) : null}
          {isAdminPartner ? (
            <div>
              <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                {t('Partner User*')}
              </Typography>
              {isResellerMemberLoading ? (
                <Skeleton className="h-10 w-full" />
              ) : (
                <SelectDropdown
                  background="grey"
                  name="sales_team"
                  placeholder="Select Partner User"
                  value={deal.responsible_person_id}
                  onChange={(value) => {
                    setDeal((prevstate) => ({
                      ...prevstate,
                      responsible_person_id: value
                    }));
                  }}
                  options={resellerMember?.map((item: ResellerMember) => ({
                    label: `${item.user.first_name} ${item.user.last_name}`,
                    value: item.user.id
                  }))}
                  fullWidth
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </Page>
  );
};
