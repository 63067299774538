import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { pmsHasInvTypeCode, pmsHasOutExtraColumns } from '@common/constants/pmsList';
import { useModal } from '@common/hooks/useModal';
import { cn } from '@common/utils/cn';
import { AddNewRoomTypeModal } from '@pages/Client/Account/Integrations/components/AddNewRoomTypeModal';
import { DeleteRoomTypeModal } from '@pages/Client/Account/Integrations/components/DeleteRoomTypeModal';
import { UpdateRoomTypeModal } from '@pages/Client/Account/Integrations/components/UpdateRoomTypeModal';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomTypeStore } from '@pages/Client/Account/Integrations/store/createRoomType';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectedRoomType {
  id: string;
  name: string;
  max_occupancy: string;
  min_occupancy: string;
  room_id: string;
  extra: string;
  default_occupancy: number | undefined;
}

export const RoomTypeTable = () => {
  const { hotelDetails } = useHotelDetails();
  const { isCreateLoading } = useCreateRoomTypeStore();
  const { hideMinMaxOccupancyOnRoomTypeTable } = usePmsProvider();

  const { t } = useTranslation();
  const { pmsRoomTypeList, isLoading } = usePmsRoomType();
  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();

  const [selectedRoomType, setSelectedRoomType] = useState<SelectedRoomType>({
    id: '',
    name: '',
    max_occupancy: '',
    min_occupancy: '',
    room_id: '',
    extra: '',
    default_occupancy: undefined
  });

  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const getRoomTypeTitle = (
    pmsProviderId: number,
    roomApartmentSpaceName: string | undefined
  ): string => {
    const providerTitles: Record<number, string> = {
      [PmsProvider.PROTEL]: t(`Protel ${roomApartmentSpaceName} Types`),
      [PmsProvider.ROOMRACCOON]: t(`RoomRaccoon ${roomApartmentSpaceName} Types`),
      [PmsProvider.GUESTLINE]: t(`${roomApartmentSpaceName} Types`),
      [PmsProvider.ROOM_MASTER]: t(`${roomApartmentSpaceName} Types`)
    };

    return providerTitles[pmsProviderId] || t(`${roomApartmentSpaceName} Types`);
  };

  const providerTitles = getRoomTypeTitle(pmsProviderId, roomApartmentSpaceName);
  const roomIdNameColumn = pmsHasInvTypeCode.includes(pmsProviderId)
    ? t('InvTypeCode')
    : t(`${roomApartmentSpaceName} Id`);

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const deleteColumn = {
    header: 'Actions',
    size: 50,
    accessorKey: 'id',
    cell: (row: any) => {
      return (
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-3">
            <Button
              icon
              onClick={() => {
                setSelectedRoomType(row.row.original);
                deleteClick();
              }}>
              <Icon.Delete className="text-copyTextGrey" />
            </Button>
          </div>
        </div>
      );
    },
    meta: {
      showOnMobile: true
    }
  };

  const extraColumn = {
    header: `${pmsProviderId === PmsProvider.ROOMRACCOON ? 'Extra' : ''} `,
    accessorKey: 'extra',
    cell: (row: any) => {
      return (
        <div className="flex items-center gap-3">
          <Typography element="span" color="copyTextGrey">
            {row?.getValue() as string}
          </Typography>
        </div>
      );
    },
    meta: {
      showOnMobile: true
    }
  };

  const defaultOccupancy = {
    header: 'Default Occupancy',
    accessorKey: 'default_occupancy',
    cell: (row: any) => {
      return (
        <div className="flex items-center gap-3">
          <Typography element="span" color="copyTextGrey">
            {row?.getValue()}
          </Typography>
        </div>
      );
    },
    meta: {
      showOnMobile: true
    }
  };

  const siteMinderNewColumn = [
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Rate Type Code',
      accessorKey: 'extra',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const minMaxColumn = [
    {
      header: 'Min Occupancy',
      accessorKey: 'min_occupancy',
      size: 140,
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) ?? '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Max Occupancy',
      accessorKey: 'max_occupancy',
      size: 140,
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) ?? '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    }
  ];

  const columns = [
    {
      header: `${roomIdNameColumn}`,
      accessorKey: 'room_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    ...(hideMinMaxOccupancyOnRoomTypeTable() ? [] : minMaxColumn),
    ...(pmsProviderId === PmsProvider.GUESTLINE ? [defaultOccupancy] : []),
    ...(pmsHasOutExtraColumns.includes(pmsProviderId) ? [] : [extraColumn]),
    ...(pmsProviderId === PmsProvider.PROTEL ||
    (pmsProviderId !== PmsProvider.CULTSWITCH && pmsProviderId !== PmsProvider.PROFITROOM)
      ? [deleteColumn]
      : [])
  ];

  const tableColumn = pmsProviderId === PmsProvider.SITEMINDER_NEW ? siteMinderNewColumn : columns;

  return (
    <div className={cn('my-8', pmsProviderId === PmsProvider.CASABLANCA ? 'max-w-4xl' : '')}>
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          // if (pmsProviderId === 64) return;
          setSelectedRoomType(row?.row.original);
          editClick();
        }}
        headerComponent={
          <div className="my-3 flex items-center justify-between">
            <Typography variant="h6" element="h6" color="darkGrey">
              {providerTitles}
            </Typography>
          </div>
        }
        actionComponents={
          ![PmsProvider.PROFITROOM, PmsProvider.KOGNITIV, PmsProvider.SITEMINDER_NEW].includes(
            pmsProviderId
          ) ? (
            <Button isLoading={isCreateLoading} onClick={() => addClick()}>
              {t(`Add new ${roomApartmentSpaceName} Type`)}
            </Button>
          ) : null
        }
        skeletonCount={10}
        columns={tableColumn}
        data={pmsRoomTypeList ? pmsRoomTypeList : []}
        isHover
        isRowClickable
      />
      <AddNewRoomTypeModal isOpen={addOpen} onClose={addClose} />
      <DeleteRoomTypeModal
        selectedRoomType={selectedRoomType}
        isOpen={deleteOpen}
        onClose={deleteClose}
      />
      <UpdateRoomTypeModal
        selectedRoomType={selectedRoomType}
        isOpen={editOpen}
        onClose={editClose}
      />
    </div>
  );
};
