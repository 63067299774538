import { deletePartnerUser } from '@common/api/account';
import { DeletePartnerUserPayload } from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { PartnerQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeletePartnerUser = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: DeletePartnerUserPayload) => {
      return deletePartnerUser(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Partner user delete successfully');
      queryClient.invalidateQueries({ queryKey: [PartnerQueryKeys.GET_PARTNER_USER_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete partner user');
    }
  });

  return {
    deletePartnerUser: mutate,
    isLoading,
    isSuccess
  };
};
