import * as z from 'zod';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { minMaxZodOutputSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minMaxSchema';
import { percentageAdjustmentZodOutputSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/percentageAdjustmentSchema';
import { fixedPricesZodOutputSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/fixedPricesSchema';
import { closedSpacesSchemaZodOutputSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/closedSpacesSchema';
import { derivationAdjustmentZodOutputSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/derivationSchema';
import { minStayZodOutputSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minStaySchema';
import { baseSchemaOccupancyAdjustmentSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema/occupancySchema';
import {
  RPGArgumentsSchema,
  RoomConfigSchema
} from '@pages/Client/Calendar/utils/validateBaseSettings';

const weekDay = z.object({ name: z.string(), value: z.boolean().default(true) });
const selectOptions = z.object({ label: z.string(), value: z.number().or(z.string()) });
const adjustmentOptions = z.object({
  label: z.string(),
  value: z.boolean().default(true),
  meta: z.array(z.string())
});
export const viewingDates = z.object({ from: z.date(), to: z.date() });
export const defaultNumberZero = z.number().transform((val) => (val === 0 ? undefined : val));

export const bulkEditSchema = z.object({
  [ADJUSTMENT.AGGRESSIVENESS]: defaultNumberZero.optional(),
  [ADJUSTMENT.CLOSED]: z.array(closedSpacesSchemaZodOutputSchema).optional(),
  [ADJUSTMENT.DERIVATION]: z.array(derivationAdjustmentZodOutputSchema).optional(),
  [ADJUSTMENT.FIX_PRICES]: z.array(fixedPricesZodOutputSchema).optional(),
  [ADJUSTMENT.MEDIAN]: defaultNumberZero.optional(),
  [ADJUSTMENT.MIN_MAX]: z.array(minMaxZodOutputSchema).optional(),
  [ADJUSTMENT.PERCENT]: z.array(percentageAdjustmentZodOutputSchema).optional(),
  [ADJUSTMENT.OCCUPANCY]: z
    .array(z.array(baseSchemaOccupancyAdjustmentSchema).optional())
    .optional(),
  [ADJUSTMENT.MIN_STAY]: z.array(minStayZodOutputSchema).optional(),
  adjustmentType: z.number().nullable(),
  dateRange: viewingDates,
  isBulkEdit: z.boolean().default(true),
  selectedRoom: z.number().nullable(),
  weekDays: z.array(weekDay)
});

export const bulkRemoveSchema = z.object({
  dateRange: viewingDates,
  weekDays: z.array(weekDay),
  adjustments: z.array(adjustmentOptions).optional()
});

export const editSchema = z.object({
  [ADJUSTMENT.FIX_PRICES]: z.array(fixedPricesZodOutputSchema),
  [ADJUSTMENT.MIN_MAX]: z.array(minMaxZodOutputSchema),
  [ADJUSTMENT.PERCENT]: z.array(percentageAdjustmentZodOutputSchema),
  [ADJUSTMENT.MIN_STAY]: z.array(minStayZodOutputSchema),
  adjustmentType: z.number().nullable(),
  isBulkEdit: z.boolean().default(false),
  editDate: z.date()
});

const adjustmentSchema = z.object({
  min_price: z.number().optional(),
  percentage_adjustment: z.number().optional(),
  adjustment_to_reference_room: z.number().optional(),
  fix_price: z.number().optional(),
  max_price: z.number().optional()
});
const roomSchema = z.record(adjustmentSchema);
const dateSchema = z.record(roomSchema);

export type RoomTransformSchema = z.infer<typeof RoomConfigSchema>;
export type AdjustmentSchema = z.infer<typeof adjustmentSchema>;
export type AdjustmentSchemaKeys = keyof AdjustmentSchema;
export type AdjustmentCheckboxes = z.infer<typeof adjustmentOptions>;
export type AggressivenessOptions = z.infer<typeof selectOptions>;
export type BulkEditSchema = z.infer<typeof bulkEditSchema>;
export type BulkRemoveSchema = z.infer<typeof bulkRemoveSchema>;
export type EditSchema = z.infer<typeof editSchema>;
export type MedianOptions = z.infer<typeof selectOptions>;
export type NewPricingSchema = z.infer<typeof dateSchema>;
export type ViewingDates = z.infer<typeof viewingDates>;
export type WeekDay = z.infer<typeof weekDay>;
export type TransformSchema = EditSchema & BulkEditSchema;
export type PropertyDefaultsSchema = z.infer<typeof RPGArgumentsSchema>;
export type BaseAdjustmentSchema = { id: number; isBulkEdit: boolean };
