import { ResultDashboardErrorTicket } from '@common/api/errors/types';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { useAuthStore } from '@common/store/auth';
import { browserTimezone } from '@common/utils/browserTimezone';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Anchor,
  Button,
  Code,
  Grid,
  Modal,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
  rem
} from '@mantine/core';
import { DASHBOARD_TICKET_STATUS } from '@pages/Admin/DashboardErrorTickets/common/constants/ticket-status';
import { useUpdateDashboardErrorTicket } from '@pages/Admin/DashboardErrorTickets/hooks/useUpdateDashboardTicker';
import { useSupportTeamList } from '@pages/Admin/ErrorTickets/hooks/useSupportTeamList';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import * as z from 'zod';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  data?: ResultDashboardErrorTicket;
};

const schema = z.object({
  id: z.string().optional(),
  assignee: z.string().or(z.number()).nullish().optional(),
  ticket_status: z.string().optional(),
  token: z.string().optional().nullish()
});

export const UpdateDashboardErrorTicketModal = ({ isOpen, onClose, data }: Props) => {
  const { token } = useAuthStore();
  const { updateDashboardErrorTicket } = useUpdateDashboardErrorTicket();

  const { supportTeams } = useSupportTeamList();

  const defaultValues = {
    id: data?.id,
    assignee: undefined,
    status: undefined,
    ticket_status: '1',
    token
  };

  const { reset, control, handleSubmit } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (formData) => {
    if (data?.id) {
      const { assignee, ticket_status, token } = formData;
      await updateDashboardErrorTicket([
        {
          id: data.id,
          assignee: Number(assignee) !== 0 ? Number(assignee) : null,
          ticket_status: Number(ticket_status) ?? null,
          token: token ?? null
        },
        data?.id
      ]);
      reset(defaultValues);
    }
    onClose();
  };

  const jiraTicketUrl =
    'https://roompricegenie.atlassian.net/secure/CreateIssueDetails!init.jspa?issuetype=10009&pid=10015' +
    '&summary=Hotel+Id%3A+' +
    encodeURIComponent(data?.hotel as number) +
    '+-+' +
    encodeURIComponent(data?.pms_name as string) +
    '+-+' +
    '&description=' +
    'Hotel+ID%3A+' +
    encodeURIComponent(data?.hotel as number) +
    '%0AHotel+Name%3A+' +
    encodeURIComponent(data?.hotel_name as string) +
    '%0AResponsible+Person%3A+' +
    encodeURIComponent(data?.responsible_person as string) +
    '%0AReseller%3A+' +
    encodeURIComponent(data?.reseller as string) +
    '%0APMS%2FCM+Name%3A+' +
    encodeURIComponent(data?.pms_name as string) +
    `&labels=${(data?.pms_name as string)
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join('')}`;

  return (
    <Modal.Root opened={isOpen} onClose={onClose} size={rem('1280')}>
      <Modal.Overlay />
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header bg="white">
            <Modal.Title>Edit Error Ticket</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Stack>
              <SimpleGrid
                cols={4}
                w={{
                  xs: '100%',
                  md: '50%'
                }}
              >
                <Controller
                  control={control}
                  name="ticket_status"
                  render={({ field: { value, name, onChange } }) => (
                    <Select
                      label="Ticket Status"
                      placeholder="Select Ticket Status"
                      name={name}
                      key={name}
                      value={value || ''}
                      data={DASHBOARD_TICKET_STATUS}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="assignee"
                  render={({ field: { value, name, onChange } }) => (
                    <Select
                      label="Assignee"
                      placeholder="Select Assignee"
                      name={name}
                      key={name}
                      value={(value as string) || ''}
                      data={supportTeams?.map((team) => ({
                        label: `${team.first_name} ${team.last_name}`,
                        value: team.id.toString()
                      }))}
                      onChange={onChange}
                      clearable
                      onClear={() => onChange(null)}
                    />
                  )}
                />
              </SimpleGrid>

              <Stack gap="xs">
                <SimpleGrid cols={4}>
                  <Title order={5}>Property Name</Title>
                  <Anchor
                    href={`/client/account/${data?.hotel}/integrations`}
                    target="_blank"
                    c="indigo"
                    underline="always"
                  >
                    {data?.hotel_name} ({data?.hotel})
                  </Anchor>
                </SimpleGrid>

                <SimpleGrid cols={4}>
                  <Title order={5}>PMS/CM Name</Title>
                  <Text>{data?.pms_name}</Text>
                </SimpleGrid>

                <SimpleGrid cols={4}>
                  <Title order={5}>Tags</Title>
                  <Text>{data?.tags}</Text>
                </SimpleGrid>

                <SimpleGrid cols={4}>
                  <Title order={5}>Created Date</Title>
                  <Tooltip
                    label={
                      <table className="table-auto">
                        <tbody>
                          <tr>
                            <td className="pr-2">
                              <Text c="white">Browser Time:</Text>
                            </td>
                            <td>
                              <Text c="white">
                                {dayjs(data?.start_date)
                                  .tz(browserTimezone)
                                  .format('YYYY-MM-DD HH:mm:ss')}
                              </Text>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  >
                    <Text>{dayjs(data?.start_date).utc().format('YYYY-MM-DD HH:mm [UTC]')}</Text>
                  </Tooltip>
                </SimpleGrid>
                <SimpleGrid cols={4}>
                  <Title order={5}>Modified Date</Title>
                  <Tooltip
                    label={
                      <table className="table-auto">
                        <tbody>
                          <tr>
                            <td className="pr-2">
                              <Text c="white">Browser Time:</Text>
                            </td>
                            <td>
                              <Text c="white">
                                {dayjs(data?.modified_date)
                                  .tz(browserTimezone)
                                  .format('YYYY-MM-DD HH:mm:ss')}
                              </Text>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  >
                    <Text>{dayjs(data?.modified_date).utc().format('YYYY-MM-DD HH:mm [UTC]')}</Text>
                  </Tooltip>
                </SimpleGrid>
                <SimpleGrid cols={4}>
                  <Title order={5}>Responsible Person</Title>
                  <Text>
                    {data?.responsible_person === 'Not Assigned' ? '-' : data?.responsible_person}
                  </Text>
                </SimpleGrid>

                <SimpleGrid cols={4}>
                  <Title order={5}>Reseller</Title>
                  <Text>{data?.reseller === 'Not Assigned' ? '-' : data?.reseller}</Text>
                </SimpleGrid>

                <SimpleGrid cols={4}>
                  <Title order={5}>Hotel Name</Title>
                  <Text>{data?.hotel_name}</Text>
                </SimpleGrid>

                <SimpleGrid cols={4}>
                  <Title order={5}>Count</Title>
                  <Tooltip label="How many tickets ticket modified since it was last opened.">
                    <Text>{data?.count}</Text>
                  </Tooltip>
                </SimpleGrid>

                <Grid columns={4}>
                  <Grid.Col span={1}>
                    <Title order={5}>Error Message</Title>
                  </Grid.Col>

                  <Grid.Col span={3}>
                    <Code block fz="md">
                      <JsonView src={data?.error_message} />
                    </Code>
                  </Grid.Col>
                </Grid>

                <SimpleGrid cols={4}>
                  <Title order={5}>Webhook RS Error Info</Title>
                  <Text>{data?.extra_info}</Text>
                </SimpleGrid>
              </Stack>
            </Stack>
          </Modal.Body>
          <ModalFooter>
            <Button variant="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={() => {
                window.open(jiraTicketUrl, '_blank');
              }}
            >
              Create Jira Ticket
            </Button>
            <Button
              variant="default"
              component="a"
              href={`/client/calendar/${data?.hotel}`}
              target="_blank"
            >
              View Hotel
            </Button>

            <Button type="submit">Save</Button>
          </ModalFooter>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
