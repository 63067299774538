import { Icon } from '@common/components/foundations/icons';
import { useClientListStore } from '@common/store/hotel-list-store';
import { Popover, Indicator, Button, Flex, Stack, Text, ActionIcon } from '@mantine/core';
import { FiltersCountrySelect } from '@pages/Admin/components/filters/country-filter-select';
import { FiltersDealOwnerSelect } from '@pages/Admin/components/filters/deal-owner-filter-select';
import { FiltersPlanSelect } from '@pages/Admin/components/filters/plan-filter-select';
import { FiltersPMSSelect } from '@pages/Admin/components/filters/pms-filter-select';
import { FiltersStateSelect } from '@pages/Admin/components/filters/state-filter-select';
import { isEqual, replace, startCase } from 'lodash-es';

export function ClientListFiltersPopover() {
  const { params, defaultParams, resetParams } = useClientListStore();
  const hasChanges = !isEqual(JSON.stringify(params), JSON.stringify(defaultParams));

  return (
    <Popover width={300} position="bottom" shadow="md">
      <Popover.Target>
        <Indicator size={7} offset={3} disabled={!hasChanges} mt={3}>
          <ActionIcon p={3}>
            <Icon.FilterList />
          </ActionIcon>
        </Indicator>
      </Popover.Target>
      <Popover.Dropdown pb="lg">
        <Flex justify="space-between" align="center">
          <Flex align="center">
            <Text size="xs">Page: {params.page}</Text>
            <Text ml="xs" size="xs">
              Sort: {startCase(replace(params.ordering, '-', ''))}
            </Text>
          </Flex>
          <Button variant="subtle" size="xs" onClick={resetParams} disabled={!hasChanges}>
            Reset All
          </Button>
        </Flex>
        <Stack>
          <FiltersCountrySelect />
          <FiltersPlanSelect />
          <FiltersStateSelect />
          <FiltersPMSSelect />
          <FiltersDealOwnerSelect />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
