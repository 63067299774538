import {
  assignAdmin,
  assignManager,
  assignStaff,
  deleteAdmin,
  deleteAssignAdmin,
  deleteAssignManager,
  deleteAssignStaff,
  deleteManager,
  deleteStaff,
  getAccountHotel,
  getClientAdminList,
  getClientManagerList,
  getClientStaffList,
  getOwnerDetails,
  getPendingInvites,
  inviteUser,
  updateAdmin,
  updateManager,
  updateOwner,
  updateStaff
} from '@common/api/account';
import {
  AssignedPayload,
  InviteUser,
  OwnerUpdate,
  UpdateUserPayload
} from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { AuthQueryKeys } from '@common/types/query-keys';
import { useMounted } from '@mantine/hooks';
import {
  AssignedHotel,
  MappedAssigned,
  MappedUnassigned
} from '@pages/Client/Account/User/types/userAssignment';
import { MappedOwnerDetail, MappedPendingInvite } from '@pages/Client/Account/User/types/userList';
import { RoleKey, getRole } from '@pages/Client/Account/User/utils/getRole';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';

const transformAdminData = (admin: {
  assigned: MappedAssigned[];
  unassigned: MappedUnassigned[];
}) => {
  const assigned = admin.assigned.map((item: MappedAssigned) => ({
    name: `${item.user.first_name} ${item.user.last_name}`,
    first_name: item.user.first_name,
    last_name: item.user.last_name,
    email: item.user.email,
    language: item.user.language,
    id: item.user.id,
    type: 'Admin',
    hotel_list: item.user.assigned_hotels,
    assigned_hotels: item.user.assigned_hotels
      .map((hotel: AssignedHotel) => hotel.hotel.name)
      .join(', '),
    pending: false
  }));

  const unassigned = admin.unassigned.map((item: MappedUnassigned) => ({
    name: `${item.first_name} ${item.last_name}`,
    first_name: item.first_name,
    last_name: item.last_name,
    email: item.email,
    language: item.language,
    id: item.id,
    type:
      item.assigned_hotels.length > 0 ? getRole(item.assigned_hotels[0].level as RoleKey) : 'Staff',
    hotel_list: item.assigned_hotels,
    assigned_hotels: item.assigned_hotels
      .map((hotel: AssignedHotel) => hotel.hotel.name)
      .join(', '),
    pending: false
  }));

  return unassigned.concat(assigned);
};

export const useAdminList = () => {
  const isMounted = useMounted();

  const query = useQuery({
    queryKey: [AuthQueryKeys.ADMIN_LIST],
    queryFn: async () => {
      const admin = await getClientAdminList();
      return transformAdminData(admin);
    },
    enabled: isMounted
  });

  return {
    adminList: query.data ?? [],
    query
  };
};

export const useAddAdminAssign = (hotelIds: number[]) => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: AssignedPayload) => {
      const hotelPromises = hotelIds.map((id) => getAccountHotel(id));
      const hotelResponses = await Promise.all(hotelPromises);
      const tokens = hotelResponses.map((hotel) => hotel?.token);
      for (const token of tokens) {
        const admin = await assignAdmin(data, token);
        return admin;
      }
      return null;
    },
    onSuccess: () => {
      addNotification('success', `Add new ${hotelIds.length} hotel successfully`);
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.ADMIN_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to add hotel');
    }
  });
};

export const useDeleteAdminAssign = (hotelIds: number[]) => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignIds: number[]) => {
      const hotelPromises = hotelIds.map((id) => getAccountHotel(id));
      const hotelResponses = await Promise.all(hotelPromises);
      const tokens = hotelResponses.map((hotel) => hotel?.token);
      const deletePromises = assignIds.map((id) => {
        return Promise.all(tokens.map((token) => deleteAssignAdmin(id, token)));
      });

      const adminResponses = await Promise.all(deletePromises);
      const flattenedResponses = adminResponses.flat();
      return flattenedResponses.filter((admin) => admin != null);
    },
    onSuccess: () => {
      addNotification('success', `Delete ${hotelIds.length} hotel successfully`);
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.ADMIN_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete hotel');
    }
  });
};

export const useUpdateAdmin = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ([id, data]: [number | undefined, UpdateUserPayload]) => {
      const admin = await updateAdmin(id, data);
      return admin;
    },
    onSuccess: () => {
      addNotification('success', 'Admin updated successfully');
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.ADMIN_LIST] });
    },
    onError: (err: AxiosError) => {
      const data = err?.response?.data as { message: string };
      if (data && data.message) {
        addNotification('fail', data.message);
        return;
      } else {
        addNotification('fail', 'Failed to update admin');
      }
    }
  });
};

export const useDeleteAdmin = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const staff = await deleteAdmin(id);
      return staff;
    },
    onSuccess: () => {
      addNotification('success', 'Admin deleted successfully');
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.ADMIN_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete admin');
    }
  });
};

export const useStaffList = () => {
  const isMounted = useMounted();

  const query = useQuery({
    queryKey: [AuthQueryKeys.STAFF_LIST],
    queryFn: async () => {
      const staff = await getClientStaffList();

      const assigned = staff.assigned.map((item: MappedAssigned) => ({
        name: `${item.user.first_name} ${item.user.last_name}`,
        first_name: item.user.first_name,
        last_name: item.user.last_name,
        email: item.user.email,
        language: item.user.language,
        id: item.user.id,
        type: 'Staff',
        is_read_only: item.user.is_read_only,
        assigned_hotels:
          item.user.assigned_hotels !== null
            ? item.user.assigned_hotels.map((hotel: AssignedHotel) => hotel.hotel.name).join(', ')
            : 'No Hotel Assigned',
        hotel_list: item.user.assigned_hotels,
        pending: false
      }));

      const data = assigned;
      return data;
    },
    enabled: isMounted
  });

  return {
    staffList: query.data ?? [],
    query
  };
};

export const useAddStaffAssign = (hotelIds: number[]) => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: AssignedPayload) => {
      const hotelPromises = hotelIds.map((id) => getAccountHotel(id));
      const hotelResponses = await Promise.all(hotelPromises);
      const tokens = hotelResponses.map((hotel) => hotel?.token);
      for (const token of tokens) {
        const staff = await assignStaff(data, token);
        return staff;
      }
      return null;
    },
    onSuccess: () => {
      addNotification('success', `Add new ${hotelIds.length} hotel successfully`);
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.STAFF_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to add hotel');
    }
  });
};

export const useDeleteStaffAssign = (hotelIds: number[]) => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignIds: number[]) => {
      const hotelPromises = hotelIds.map((id) => getAccountHotel(id));
      const hotelResponses = await Promise.all(hotelPromises);
      const tokens = hotelResponses.map((hotel) => hotel?.token);

      const deletePromises = assignIds.map((id) => {
        return Promise.all(tokens.map((token) => deleteAssignStaff(id, token)));
      });

      const staffResponses = await Promise.all(deletePromises);
      const flattenedResponses = staffResponses.flat();
      return flattenedResponses.filter((staff) => staff != null);
    },
    onSuccess: () => {
      addNotification('success', `Delete ${hotelIds.length} hotel successfully`);
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.STAFF_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete hotel');
    }
  });
};

export const useUpdateStaff = () => {
  const { addNotification } = useNotificationsStore();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ([id, data]: [number | undefined, UpdateUserPayload]) => {
      const staff = await updateStaff(id, data);
      return staff;
    },
    onSuccess: () => {
      addNotification('success', 'Staff updated successfully');
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.STAFF_LIST] });
    },
    onError: (err: AxiosError) => {
      const data = err?.response?.data as { message: string };
      if (data && data.message) {
        addNotification('fail', data.message);
        return;
      } else {
        addNotification('fail', 'Failed to update staff');
      }
    }
  });
};

export const useDeleteStaff = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const staff = await deleteStaff(id);
      return staff;
    },
    onSuccess: () => {
      addNotification('success', 'Staff deleted successfully');
      queryClient.invalidateQueries({ queryKey: [AuthQueryKeys.STAFF_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete staff');
    }
  });
};

export const useManagerList = () => {
  const isMounted = useMounted();

  const query = useQuery({
    queryKey: [AuthQueryKeys.MANAGER_LIST],
    queryFn: async () => {
      const manager = await getClientManagerList();

      const assigned = manager.assigned.map((item: MappedAssigned) => ({
        name: `${item.user.first_name} ${item.user.last_name}`,
        first_name: item.user.first_name,
        last_name: item.user.last_name,
        email: item.user.email,
        language: item.user.language,
        id: item.user.id,
        type: 'Manager',
        assigned_hotels: item.user.assigned_hotels
          .map((hotel: AssignedHotel) => hotel.hotel.name)
          .join(', '),
        hotel_list: item.user.assigned_hotels,
        pending: false
      }));
      const data = assigned;
      return data;
    },
    enabled: isMounted
  });

  return {
    managerList: query.data ?? [],
    query
  };
};

export const useAddManagerAssign = (hotelIds: number[]) => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: AssignedPayload) => {
      const hotelPromises = hotelIds.map((id) => getAccountHotel(id));
      const hotelResponses = await Promise.all(hotelPromises);
      const tokens = hotelResponses.map((hotel) => hotel?.token);
      for (const token of tokens) {
        const manager = await assignManager(data, token);
        return manager;
      }
      return null;
    },
    onSuccess: () => {
      addNotification('success', `Add new ${hotelIds.length} hotel successfully`);
      queryClient.invalidateQueries({
        queryKey: [AuthQueryKeys.MANAGER_LIST]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to add hotel');
    }
  });
};

export const useDeleteManagerAssign = (hotelIds: number[]) => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignIds: number[]) => {
      const hotelPromises = hotelIds.map((id) => getAccountHotel(id));
      const hotelResponses = await Promise.all(hotelPromises);
      const tokens = hotelResponses.map((hotel) => hotel?.token);
      const deletePromises = assignIds.map((id) => {
        return Promise.all(tokens.map((token) => deleteAssignManager(id, token)));
      });

      const managerResponses = await Promise.all(deletePromises);
      const flattenedResponses = managerResponses.flat();
      return flattenedResponses.filter((manager) => manager != null);
    },
    onSuccess: () => {
      addNotification('success', `Delete ${hotelIds.length} hotel successfully`);
      queryClient.invalidateQueries({
        queryKey: [AuthQueryKeys.MANAGER_LIST]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete hotel');
    }
  });
};

export const useUpdateManager = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ([id, data]: [number | undefined, UpdateUserPayload]) => {
      const manager = await updateManager(id, data);
      return manager;
    },
    onSuccess: () => {
      addNotification('success', 'Manager updated successfully');
      queryClient.invalidateQueries({
        queryKey: [AuthQueryKeys.MANAGER_LIST]
      });
    },
    onError: (err: AxiosError) => {
      const data = err?.response?.data as { message: string };
      if (data && data.message) {
        addNotification('fail', data.message);
        return;
      } else {
        addNotification('fail', 'Failed to update manager');
      }
    }
  });
};

export const useDeleteManager = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const manager = await deleteManager(id);
      return manager;
    },
    onSuccess: () => {
      addNotification('success', 'Manager deleted successfully');
      queryClient.invalidateQueries({
        queryKey: [AuthQueryKeys.MANAGER_LIST]
      });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete manager');
    }
  });
};

export const useOwnerDetails = () => {
  const isMounted = useMounted();
  const { addNotification } = useNotificationsStore();

  const query = useQuery({
    queryKey: [AuthQueryKeys.OWNER_DETAILS],
    queryFn: async () => {
      const owner = await getOwnerDetails();
      const data: MappedOwnerDetail = {
        name: `${owner.first_name} ${owner.last_name}`,
        email: owner.email,
        id: owner.id,
        first_name: owner.first_name,
        last_name: owner.last_name,
        language: owner.language,
        type: 'Owner',
        assigned_hotels: 'All Group Hotels',
        pending: false
      };

      return data;
    },
    enabled: isMounted
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to fetch owner details');
    }
  }, [query.isError]);

  return {
    ownerDetails: query.data,
    query
  };
};

export const useOwnerUpdate = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: OwnerUpdate) => {
      const owner = await updateOwner(data);
      return owner;
    },
    onSuccess: () => {
      addNotification('success', 'Owner updated successfully');
      queryClient.invalidateQueries({
        queryKey: [AuthQueryKeys.OWNER_DETAILS]
      });
    },
    onError: (err: AxiosError) => {
      const data = err?.response?.data as { message: string };
      if (data && data.message) {
        addNotification('fail', data.message);
        return;
      } else {
        addNotification('fail', 'Failed to update owner');
      }
    }
  });
};

export const usePendingList = () => {
  const isMounted = useMounted();
  const { addNotification } = useNotificationsStore();
  const query = useQuery({
    queryKey: [AuthQueryKeys.PENDING_INVITES],
    queryFn: async () => {
      let { data } = await getPendingInvites();
      data = data.map((item: MappedPendingInvite) => ({
        name: '',
        email: item.email,
        id: '',
        type: getRole(item.user_type as RoleKey),
        assigned_hotels: Object.values(item.property_list).join(', '),
        pending: true,
        is_read_only: item.is_read_only
      }));
      return data;
    },
    enabled: isMounted
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to fetch pending invites');
    }
  }, [query.isError]);

  return {
    pendingInvites: query.data ?? [],
    query
  };
};

export const usePendingListRaw = () => {
  const isMounted = useMounted();
  const { addNotification } = useNotificationsStore();
  const query = useQuery({
    queryKey: [AuthQueryKeys.PENDING_INVITES_RAW],
    queryFn: async () => {
      const { data } = await getPendingInvites();
      const dataWithoutToken = data.map((item: MappedPendingInvite) => ({
        email: item.email,
        language: item.language,
        property_list: item.property_list,
        user_type: item.user_type,
        is_read_only: item.is_read_only
      }));
      return dataWithoutToken;
    },
    enabled: isMounted
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to fetch pending invites');
    }
  }, [query.isError]);

  return {
    pendingInvitesRaw: query.data ?? [],
    query
  };
};

export const useInviteUser = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user: InviteUser) => {
      const reinvite = await inviteUser(user);
      return reinvite;
    },
    onSuccess: (data) => {
      if (data.success) {
        addNotification('success', 'User invited successfully');
        queryClient.invalidateQueries({
          queryKey: [AuthQueryKeys.PENDING_INVITES]
        });
      } else {
        addNotification('fail', data.message);
      }
    },
    onError: () => {
      addNotification('fail', 'Failed to invited user');
    }
  });
};
