import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Icon } from '@common/components/foundations/icons';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useGetCurrentUTCTime } from '@pages/Client/hooks/useGetCurrentUTCTime';
import { downloadPriceCsv } from '@common/api/pricingAlgorithm';
import dayjs from 'dayjs';
import { useNotificationsStore } from '@common/store/notifications';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const DOWNLOAD_OPTIONS = [
  {
    label: 'For all room types',
    value: 1
  },
  {
    label: 'For selected room types only',
    value: 2
  }
];

interface RefHandler {
  submitDownloadCSV: () => void;
}

export const DownloadCSV = forwardRef<RefHandler>((props, ref) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { data } = useGetCurrentUTCTime();
  const { selectedHotelRoomId } = useHotelRoomsList();
  const { addNotification } = useNotificationsStore();

  const [downloadOption, setDownloadOption] = useState<(typeof DOWNLOAD_OPTIONS)[number]['value']>(
    DOWNLOAD_OPTIONS[0].value
  );

  const submitDownloadCSV = async () => {
    if (data && hotelDetails?.timezone && selectedHotelRoomId) {
      const requestData = {
        command: 'create_inngenius_csv',
        room_id: selectedHotelRoomId,
        start_date: dayjs.utc(data.current_utc_time).tz(hotelDetails.timezone).format('YYYY-MM-DD'),
        end_date: dayjs
          .utc(data.current_utc_time)
          .tz(hotelDetails.timezone)
          .add(1, 'y')
          .format('YYYY-MM-DD'),
        format_type: downloadOption
      };

      try {
        const response = await downloadPriceCsv(requestData);

        if (response?.data.url) {
          const url = new URL(response.data.url);
          const filename = url.pathname.split('/').pop() ?? 'roompricegenie.csv';

          const link = document.createElement('a');
          link.download = filename;
          link.href = response.data.url;
          link.click();
        }
      } catch (e) {
        if (isAxiosError(e)) {
          addNotification('fail', e.message);
        }
        console.log(e);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    submitDownloadCSV
  }));

  return (
    <div>
      <Icon.GenieCircle />
      <h1 className="mt-3 text-h6 font-medium">{t('Download CSV')}</h1>

      <div className="mt-5">
        <RadioGroup
          optionsWrapperClassName="grid grid-cols-1 gap-x-4 gap-y-2"
          type="block"
          options={DOWNLOAD_OPTIONS.map((option) => ({
            label: t(option.label),
            value: option.value
          }))}
          value={downloadOption}
          onChange={setDownloadOption}
        />
      </div>
    </div>
  );
});
