import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';

interface DownloadButtonProps {
  onDownloadPrice?: () => void;
  downloadPriceDisabled?: boolean;
  onDownloadInventory?: () => void;
  downloadInventoryDisabled?: boolean;
  onDownloadSettings?: () => void;
  downloadSettingsDisabled?: boolean;
}

export const UsersLogDownloadHeader = ({
  onDownloadPrice,
  downloadPriceDisabled,
  onDownloadInventory,
  downloadInventoryDisabled,
  onDownloadSettings,
  downloadSettingsDisabled
}: DownloadButtonProps) => {
  return (
    <div className="flex w-full justify-start py-3 md:justify-end">
      <div className="flex flex-wrap items-end gap-3 md:justify-end">
        <Button onClick={onDownloadPrice} disabled={downloadPriceDisabled}>
          <Icon.Download className="h-4 w-4" />
          Price Cache
        </Button>
        <Button onClick={onDownloadInventory} disabled={downloadInventoryDisabled}>
          <Icon.Download className="h-4 w-4" />
          Inventory JSON
        </Button>
        <Button onClick={onDownloadSettings} disabled={downloadSettingsDisabled}>
          <Icon.Download className="h-4 w-4" />
          Settings JSON
        </Button>
      </div>
    </div>
  );
};
