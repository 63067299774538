import { Icon } from '@common/components/foundations/icons';
import { KPILarge } from '@common/components/molecules/KPI';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { ComponentProps, useMemo } from 'react';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { NO_MARKET_DATA } from '@pages/Client/Calendar/constants';
import dayjs from 'dayjs';
import { useInflation } from '@pages/Client/Calendar/hooks/useInflation';
import { ActionIcon } from '@mantine/core';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';
import { useUpselling } from '@pages/Client/hooks/useUpselling';

export const usePriceKpiContentHome = (
  dateKey: string,
  options?: {
    onYourAdjustmentsNavigate?: () => void;
  }
) => {
  const { t } = useTranslation();
  const { roomPrices, referenceRoom } = useRoomPrices();
  const { features } = useFeaturesStore();
  const { pricingSettings } = usePricingSettings();
  const { selectedHotelRoomId: roomId } = useCalendarPageStore();
  const { view } = useViewStore();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const { SparkIcon, setUpsellingModalOpen } = useUpselling();

  const cachedPriceData = useMemo(() => {
    if (!referenceRoom?.id) return;

    return roomPrices?.prices.data?.[dateKey]?.[roomId ?? referenceRoom.id];
  }, [roomPrices, dateKey, roomId, referenceRoom?.id]);

  const defaultRoomPriceData = useMemo(() => {
    if (!referenceRoom?.id) return;

    return pricingSettings?.default?.[roomId ?? referenceRoom.id];
  }, [pricingSettings, roomId, referenceRoom?.id]);

  const { inflationByMonth, isAutomaticInflationIncrease } = useInflation(dayjs(dateKey).toDate());

  if (!defaultRoomPriceData || !cachedPriceData) return;

  const {
    weighted_average_price,
    suggested_price_without_restriction,
    weighted_average_price_with_pubp,
    suggested_price
  } = cachedPriceData;

  const avgPrice = Math.round(
    isAutomaticInflationIncrease && inflationByMonth
      ? inflationByMonth * defaultRoomPriceData?.avg_price
      : defaultRoomPriceData?.avg_price
  );

  const weightedAvgPrice = Math.round(
    isAutomaticInflationIncrease && inflationByMonth
      ? weighted_average_price * inflationByMonth
      : weighted_average_price
  );

  const weightedAvgPriceWithPubp = Math.round(
    isAutomaticInflationIncrease && inflationByMonth
      ? weighted_average_price_with_pubp * inflationByMonth
      : weighted_average_price_with_pubp
  );

  const suggestedPriceWithoutRestriction = Math.round(
    isAutomaticInflationIncrease && inflationByMonth
      ? suggested_price_without_restriction * inflationByMonth
      : suggested_price_without_restriction
  );

  let marketFactorPer = 0;
  let marketFactor = 0;
  let occupancyFectorPer = 0;
  let occupancyFactor = 0;
  let adjustment = 0;
  let adjustmentPer = 0;

  if (weightedAvgPrice != null && weightedAvgPrice) {
    marketFactorPer = (weightedAvgPrice / avgPrice - 1) * 100;
    marketFactor = weightedAvgPrice - avgPrice;
    occupancyFectorPer = (suggestedPriceWithoutRestriction / weightedAvgPrice - 1) * 100;
    occupancyFactor = suggestedPriceWithoutRestriction - weightedAvgPrice;
  } else {
    marketFactorPer = (weightedAvgPriceWithPubp / avgPrice - 1) * 100;
    marketFactor = weightedAvgPriceWithPubp - avgPrice;
    occupancyFectorPer = (suggestedPriceWithoutRestriction / weightedAvgPriceWithPubp - 1) * 100;
    occupancyFactor = suggestedPriceWithoutRestriction - weightedAvgPriceWithPubp;
  }
  adjustmentPer = (suggested_price / suggestedPriceWithoutRestriction - 1) * 100;
  adjustment = suggested_price - suggestedPriceWithoutRestriction;

  const isNoMarketData = roomPrices?.prices.data?.[dateKey]?.property?.note === NO_MARKET_DATA;

  const getBreakDownValue = (value: number) => {
    if (isNoMarketData) return 'n.A.';
    return CurrencyFormatter.format(Math.round(value));
  };

  const getBreakDownPercentage = (number: number) => {
    if (isNoMarketData) return '';
    const value = Math.round(number).toString();
    return `${value}%`;
  };

  const getBreakDownIcon = (value: number) => {
    if (isNoMarketData) return null;
    if (value > 0) return <Icon.ArrowUpWard className="h-4 w-4 fill-uiGreen" />;
    if (value < 0) return <Icon.ArrowDownWard className="h-4 w-4 fill-uiRed" />;
  };

  const content: ComponentProps<typeof KPILarge>['content'] = {
    basePrice: `${t('Base Price')}${
      isAutomaticInflationIncrease && inflationByMonth > 1 ? ` (${t('adj. for inflation')})` : ''
    }`,
    basePriceValue: `${CurrencyFormatter.format(avgPrice) ?? ''}`,
    userflowId: PriceDrawerUserflowIds.BASE_PRICE_TEXT,
    breakdownPrice: [
      {
        title: t('Market Factor'),
        id: 'market-factor',
        value: getBreakDownValue(marketFactor),
        percentage: getBreakDownPercentage(marketFactorPer),
        icon: getBreakDownIcon(marketFactor),
        userflowId: PriceDrawerUserflowIds.MARKET_FACTOR_TEXT,
        isUpselling: features?.includes(Feature.NoMarketData),
        upsellingIcon: features?.includes(Feature.NoMarketData) ? <SparkIcon /> : null,
        upsellingHandler: features?.includes(Feature.NoMarketData)
          ? () => {
              setUpsellingModalOpen(true);
            }
          : undefined
      },
      {
        title: t('Occupancy/Pickup Factor'),
        id: 'occupancy-pickup-factor',
        value: getBreakDownValue(occupancyFactor),
        percentage: getBreakDownPercentage(occupancyFectorPer),
        icon: getBreakDownIcon(occupancyFactor),
        userflowId: PriceDrawerUserflowIds.OCCUPANCY_PICKUP_TEXT
      },
      {
        title: options?.onYourAdjustmentsNavigate ? (
          <div className="flex items-center space-x-2" onClick={options.onYourAdjustmentsNavigate}>
            <Typography variant="meta-1">{t('Your Adjustments')}</Typography>
            <ActionIcon size="sm">
              <Icon.ArrowForward className="h-4 w-4" />
            </ActionIcon>
          </div>
        ) : (
          t('Your Adjustments')
        ),
        id: 'your-adjustments',
        value: getBreakDownValue(adjustment),
        percentage: getBreakDownPercentage(adjustmentPer),
        icon: getBreakDownIcon(adjustment),
        userflowId: PriceDrawerUserflowIds.YOUR_ADJUSTMENTS_TEXT
      }
    ],
    prices: [
      {
        title: t(`Price in ${channelManagerOrPms}`),
        id: 'price-in-pms',
        value: `${CurrencyFormatter.format(Math.round(cachedPriceData.original_price)) ?? 'n.A.'}`,
        userflowId: PriceDrawerUserflowIds.PRICE_IN_TEXT
      },
      {
        title: t('Price Yesterday'),
        id: 'price-yesterday',
        value: `${CurrencyFormatter.format(Math.round(cachedPriceData.yesterday_price)) ?? 'n.A.'}`,
        userflowId: PriceDrawerUserflowIds.PRICE_YESTERDAY_TEXT
      },
      {
        title: t('Price 7 Days Ago'),
        id: 'price-7-days-ago',
        value: `${CurrencyFormatter.format(Math.round(cachedPriceData.one_week_price)) ?? 'n.A.'}`,
        userflowId: PriceDrawerUserflowIds.PRICE_7DAYS_AGO_TEXT
      },
      ...(view === 'admin' && features?.includes(Feature.PickupBoostPrice)
        ? [
            {
              title: t('Pickup Model Price'),
              id: 'pickup-model-price',
              value: `${CurrencyFormatter.format(
                Math.round(roomPrices?.pickup_boost_prices?.[dateKey]?.pickup_boost_price ?? NaN)
              )}`,
              userflowId: PriceDrawerUserflowIds.PRICE_PICKUP_MODEL_TEXT
            }
          ]
        : [])
    ]
  };

  return content;
};
