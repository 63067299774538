// axiosInstance.ts
import { getEnvVar } from '@common/utils/env';
import { WindowWithClerk } from '@pages/Auth/Clerk/types/clerk-types';
import axios, { AxiosRequestConfig } from 'axios';

export const getSessionToken = async () => {
  if (!(window as WindowWithClerk).Clerk?.session) return null;
  return (await (window as WindowWithClerk)?.Clerk?.session?.getToken()) ?? null;
};

export const axiosInstance = axios.create({
  baseURL: getEnvVar('VITE_API'),
  headers: { 'Content-Type': 'application/json' }
});

export function setAuthToken(token: string | null, isJwt?: boolean) {
  if (token) {
    if (isJwt) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
}

export function setHotelClerkAxiosHeader(token: string) {
  axiosInstance.defaults.headers.common['X-Hotel-Session'] = `${token}`;
}

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const clerkToken = await getSessionToken();
    config.headers = config.headers || {};
    if (clerkToken) {
      config.headers['X-Token'] = `Bearer ${clerkToken}`;
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
    return config;
  },
  (error) => {
    console.error('🕸️ Axios request error', error);
    return Promise.reject(error);
  }
);
