import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getInternalRoomMapping, updateInternalRoomMapping } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { RoomsQueryKeys } from '@common/types/query-keys';

export const useInternalRoomMapping = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [RoomsQueryKeys.GET_INTERNAL_ROOM_MAPPING],
    queryFn: () => getInternalRoomMapping(),
  });

  return {
    data: data?.data?.data,
    isLoading,
    error,
  };
};

export const useUpdateInternalRoomMapping = ({ onSuccess }: { onSuccess?: () => void} = {}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationsStore();

  return useMutation({
    mutationFn: (payload: Record<string, string>) => updateInternalRoomMapping(payload),
    onSuccess: () => {
      onSuccess?.();
      addNotification('success', 'Room mapping updated successfully');
      queryClient.invalidateQueries({ queryKey: [RoomsQueryKeys.GET_INTERNAL_ROOM_MAPPING] });
    },
    onError: () => {
      addNotification('fail', 'Failed to update room mapping');
    },
  });
};
