import { useAuth } from '@clerk/clerk-react';
import { getClerkUserDetail, getUserDetail } from '@common/api/account';
import { useAuthStore } from '@common/store/auth';
import { UtilQueryKeys } from '@common/types/query-keys';
import { getClientHotelId } from '@pages/Auth/Clerk/utilities/get-client-hotel-id';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash-es/isNil';
import { useEffect } from 'react';

export type Hotel = {
  id: number;
  state: number;
  is_client_access_disabled: boolean;
};

export const useUserDetail = (enable: boolean) => {
  const { setUser } = useAuthStore();
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: [UtilQueryKeys.USER_DETAIL],
    queryFn: () => {
      return getUserDetail();
    },
    enabled: enable,
    retry: true
  });

  useEffect(() => {
    if (!isSuccess) return;
    setUser(data);
  }, [isSuccess]);

  return {
    detailUser: data,
    isLoading,
    error
  };
};

export function useClerkUserDetail() {
  const clerkAuth = useAuth();
  const { user, hotel, setAuth, setHotel } = useAuthStore();
  const query = useQuery({
    queryKey: [UtilQueryKeys.CLERK_USER_DETAIL],
    queryFn: () => {
      return getClerkUserDetail();
    },
    retry: 3,
    retryDelay: 1000
  });

  if (query.isSuccess) {
    if (clerkAuth.isSignedIn && isNil(user)) setAuth(null, query.data?.user);
    const clientHotel = getClientHotelId(query?.data.hotel_list);
    if (isNil(hotel) && !isNil(clientHotel)) setHotel(clientHotel);
  }

  return query;
}
