import { portalMembershipPricing } from '@common/api/hotel';
import { useModal } from '@common/hooks/useModal';
import { useHotelStore } from '@common/store/auth';
import { useViewStore } from '@common/store/view';
import { Icon } from '@common/components/foundations/icons';
import { ActionIcon, Button, Divider, Flex, Group, Popover, Stack } from '@mantine/core';
import { DisconnectSubscriptionModal } from '@pages/Client/Account/Subscription/components/DisconnectSubscriptionModal';
import { ReactivateSubscriptionModal } from '@pages/Client/Account/Subscription/components/ReactivateChargebeeModal';
import { HotelState } from '@pages/Client/hooks/useSubscription';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useChargebee } from '@pages/Client/Account/Subscription/hooks/useChargebee';
import { ConnectChargebeeModal } from '@pages/Client/Account/Subscription/components/ConnectChargebeeModal';
import { useState } from 'react';

export const ChargebeeAccountInformation = () => {
  const { t } = useTranslation();
  const cbInstance = useChargebee();
  const { hotelDetails: hotel } = useHotelDetails();
  const { isOpen: connectOpen, openModal: connectClick, closeModal: connectClose } = useModal();
  const {
    isOpen: disconnectOpen,
    openModal: disconnectClick,
    closeModal: disconnectClose
  } = useModal();
  const {
    isOpen: reactivateOpen,
    openModal: reactivateClick,
    closeModal: reactivateClose
  } = useModal();
  const [popOverOpened, setPopOverOpened] = useState(false);
  const { pricingSettings } = usePricingSettings();
  const { hotelAuthToken } = useHotelStore();
  const { view } = useViewStore();

  const features = pricingSettings?.features;

  const isCancelled = hotel?.state === HotelState.CANCELED;

  const handlePortal = async () => {
    if (hotel?.subscription_id) {
      cbInstance?.setPortalSession(() => {
        return new Promise((resolve, _reject) => {
          resolve(portalMembershipPricing(hotelAuthToken as string));
        });
      });
    }
    const cbPortal = cbInstance?.createChargebeePortal();
    cbPortal?.open({
      close() {}
    });
  };

  type PortalSectionType = 'AccountInfo' | 'Address' | 'Payment' | 'Billing';

  const handleAccountInformation = (type: PortalSectionType) => {
    if (hotel?.subscription_id) {
      cbInstance?.setPortalSession(() => {
        return new Promise((resolve, _reject) => {
          resolve(portalMembershipPricing(hotelAuthToken as string));
        });
      });
    }

    const cbPortal = cbInstance?.createChargebeePortal();
    const callbacks = {};

    switch (type) {
      case 'AccountInfo':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().ACCOUNT_DETAILS,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      case 'Address':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().ADDRESS,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      case 'Payment':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().PAYMENT_SOURCES,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      case 'Billing':
        cbPortal.openSection(
          {
            sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY,
            params: {
              subscriptionId: hotel?.subscription_id
            }
          },
          callbacks
        );
        break;
      default:
        break;
    }
  };
  const ButtonConnect = () => {
    return (
      <Button variant="outline" onClick={connectClick}>
        {t('Connect A Chargebee Subscription')}
      </Button>
    );
  };

  return (
    <>
      {features?.includes(10)
        ? null
        : view === 'admin' && hotel?.plan === null && <ButtonConnect />}
      {hotel?.plan !== null && (
        <>
          <Flex gap="xl" my="lg" justify="space-between">
            <Group>
              <Button onClick={handlePortal}>{t('Edit Subscription And Billing')}</Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('AccountInfo')}>
                {t('Account Information')}
              </Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('Address')}>
                {t('Billing Address')}
              </Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('Payment')}>
                {t('Payment Methods')}
              </Button>
              <Button
                variant="transparent"
                c="gray.9"
                onClick={() => handleAccountInformation('Billing')}>
                {t('Billing History')}
              </Button>
            </Group>
            {view === 'admin' ? (
              <Popover
                position="right-start"
                shadow="md"
                styles={{
                  dropdown: {
                    border: 'none'
                  }
                }}
                opened={popOverOpened}
                onChange={setPopOverOpened}>
                <Popover.Target>
                  <ActionIcon variant="white" onClick={() => setPopOverOpened((o) => !o)}>
                    <Icon.MoreVertical />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <Stack>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setPopOverOpened(false);
                        connectClick();
                      }}>
                      {t('Connect A Chargebee Subscription')}
                    </Button>
                    {isCancelled ? (
                      <Button
                        variant="filled"
                        onClick={() => {
                          setPopOverOpened(false);
                          reactivateClick();
                        }}>
                        {t('Reactivate Subscription')}
                      </Button>
                    ) : null}
                    {!isNil(hotel?.subscription_id) && !isCancelled ? (
                      <Button
                        variant="transparent"
                        c="red"
                        onClick={() => {
                          setPopOverOpened(false);
                          disconnectClick();
                        }}>
                        {t('Disconnect Subscription')}
                      </Button>
                    ) : null}
                  </Stack>
                </Popover.Dropdown>
              </Popover>
            ) : null}
          </Flex>

          <Divider />
        </>
      )}
      <ConnectChargebeeModal isOpen={connectOpen} onClose={connectClose} />
      <DisconnectSubscriptionModal isOpen={disconnectOpen} onClose={disconnectClose} />
      <ReactivateSubscriptionModal isOpen={reactivateOpen} onClose={reactivateClose} />
    </>
  );
};
