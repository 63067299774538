import {
  Stack,
  Group,
  SegmentedControl,
  TextInput,
  PasswordInput,
  NumberInput,
  Textarea,
  PinInput,
  Radio,
  Title,
  Checkbox,
  Switch,
  Box
} from '@mantine/core';

export const Inputs = () => {
  return (
    <Stack py="xs">
      <Stack>
        <Title order={2} size="h3">
          Radio
        </Title>
        <Radio.Group
          name="favoriteFramework"
          label="Select your favorite framework/library"
          description="This is anonymous"
          withAsterisk>
          <Group mt="xs">
            <Radio value="react" label="React" />
            <Radio value="svelte" label="Svelte" />
            <Radio value="ng" label="Angular" />
            <Radio value="vue" label="Vue" />
          </Group>
        </Radio.Group>
      </Stack>

      <Stack>
        <Title order={2} size="h3">
          Checkbox
        </Title>
        <Group>
          <Checkbox label="Checkbox" />
          <Checkbox label="Checkbox" />
        </Group>
      </Stack>
      <Stack>
        <Title order={2} size="h3">
          Switch
        </Title>
        <Switch defaultChecked label="I agree to sell my privacy" />
      </Stack>
      <Stack>
        <Title order={2} size="h3">
          SegmentedControl
        </Title>
        <Box>
          <SegmentedControl data={['Admin', 'Client']} />
        </Box>
      </Stack>

      <Stack>
        <Title order={2} size="h3">
          TextInput
        </Title>
        <TextInput placeholder="Default" label="Label" withAsterisk />
        <TextInput
          placeholder="Default"
          label="Filled input"
          description="Use only on white backgrounds"
          withAsterisk
          variant="filled"
        />
        <TextInput
          placeholder="With description"
          label="With description"
          description="Description"
        />
        <TextInput
          placeholder="With error message"
          label="With error message"
          error="Something went wrong"
        />
        <TextInput placeholder="With prefix" label="With prefix" leftSection="CHF" />
        <TextInput placeholder="With suffix" label="With suffix" rightSection="%" />
        <TextInput placeholder="Disabled" label="Disabled" disabled />
        <TextInput placeholder="Read only" label="Read only" readOnly />
        <Title order={2} size="h3">
          PasswordInput
        </Title>
        <PasswordInput
          label="Input label"
          description="Input description"
          placeholder="Input placeholder"
          leftSection="CHF"
        />
        <Title order={2} size="h3">
          NumberInput
        </Title>
        <NumberInput
          label="Input label"
          description="Input description"
          placeholder="Input placeholder"
          leftSection="CHF"
        />
        <Title order={2} size="h3">
          Textarea
        </Title>
        <Textarea
          label="Input label"
          description="Input description"
          placeholder="Input placeholder"
          autosize
        />
        <Title order={2} size="h3">
          PinInput
        </Title>
        <PinInput length={6} type="number" />
      </Stack>
    </Stack>
  );
};
