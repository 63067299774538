import { DetailPartnerApiResponse } from '@common/api/integration/schema';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { ActionIcon, Button, Modal, Stack, Text } from '@mantine/core';
import { useDeleteIntPartner } from '@pages/Admin/IntegrationPartner/hooks/useIntegrationPartner';
import { IconTrashFilled } from '@tabler/icons-react';
import { t } from 'i18next';

type DeleteIntegrationPartnerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedPartner?: DetailPartnerApiResponse['data'];
};

export const DeleteIntegrationPartnerModal = ({
  isOpen,
  onClose,
  selectedPartner
}: DeleteIntegrationPartnerModalProps) => {
  const { deleteIntPartner } = useDeleteIntPartner();

  const handleDelete = async () => {
    if (selectedPartner) {
      await deleteIntPartner(selectedPartner.id);
    }
    onClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size="sm"
      centered
      styles={{
        body: {
          padding: '0'
        },

        header: {
          display: 'none'
        }
      }}>
      <Stack p={20} pt={20}>
        <ActionIcon color="red" variant="filled" size="xl" radius="100">
          <IconTrashFilled color="white" />
        </ActionIcon>
        <Text>
          {t('Are you sure you want to delete') + ' '}
          <strong>{selectedPartner?.name}</strong> ?
        </Text>
      </Stack>
      <ModalFooter>
        <Button onClick={onClose} variant="subtle">
          {t('Cancel')}
        </Button>
        <Button onClick={handleDelete} color="red" loading={false}>
          {t('Delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
