import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { useDocumentTitle } from '@mantine/hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CloseOutSales = () => {
  const { t } = useTranslation();

  const CLOSE_OUT_SALES_LAST_DAY_AT = [
    { value: '0', label: t('No Close Out') },
    { value: '9', label: t('9am') },
    { value: '10', label: t('10am') },
    { value: '11', label: t('11am') },
    { value: '12', label: t('12pm') },
    { value: '13', label: t('1pm') },
    { value: '14', label: t('2pm') },
    { value: '15', label: t('3pm') },
    { value: '16', label: t('4pm') },
    { value: '17', label: t('5pm') },
    { value: '18', label: t('6pm') },
    { value: '19', label: t('7pm') },
    { value: '20', label: t('8pm') },
    { value: '21', label: t('9pm') },
    { value: '22', label: t('10pm') },
    { value: '23', label: t('11pm') }
  ];

  const CLOSE_OUT_SALES_LAST_DAY = [
    { value: '0', label: t('No Close Out') },
    { value: '1', label: t('Last day') },
    { value: '2', label: t('Last 2 days') }
  ];

  useDocumentTitle(t('Close Out Sales (Last Day)'));
  const { control, watch, setValue } = useFormContext();
  const isSetCloseOutHour =
    parseInt(watch('close_out_days')) === 2 || parseInt(watch('close_out_days')) === 1;

  return (
    <div>
      <div className="mb-6 mt-4 grid grid-cols-12 items-start gap-20">
        <div className="col-span-5 mb-8 flex  flex-col gap-4">
          <Typography element="h5" variant="h5" color="darkGrey">
            {t('Close Out Sales (Last Day)')}
          </Typography>
          <Typography variant="paragraph-1" element="p" className="font-light">
            {t(
              'If you close out sales on the last day(s) after a certain time, please let us know. Otherwise we might fetch wrong availabilities.'
            )}
          </Typography>
        </div>
        <div className="col-span-7">
          <div>
            <div className="mb-5 flex w-full justify-between gap-3">
              <div className="w-1/2">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Close out sales on last day at')}
                </Typography>
                <Controller
                  control={control}
                  name="close_out_hour"
                  render={({ field: { value, name, onChange } }) => (
                    <SelectDropdown
                      fullWidth
                      placeholder="Select Close out sales on last days at"
                      name={name}
                      key={name}
                      inputClassName="disabled:cursor-not-allowed disabled:bg-opacity-80 disabled:text-grey"
                      disabled={isSetCloseOutHour}
                      value={value?.toString() || ''}
                      options={CLOSE_OUT_SALES_LAST_DAY_AT}
                      onChange={(value) => {
                        onChange(value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="w-1/2">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Close out sales on last days')}
                </Typography>
                <Controller
                  control={control}
                  name="close_out_days"
                  render={({ field: { value, name, onChange } }) => (
                    <SelectDropdown
                      fullWidth
                      placeholder="Select Close out sales on last days"
                      name={name}
                      key={name}
                      value={value?.toString() || ''}
                      options={CLOSE_OUT_SALES_LAST_DAY}
                      onChange={(value) => {
                        if (value === '1' || value === '2') {
                          setValue('close_out_hour', '0');
                        }
                        onChange(value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
