import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Button, Modal, Stack, Text } from '@mantine/core';
import { useUpdatePms } from '@pages/Client/Account/Integrations/hooks/useUpdatePms';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import useSaveStayTypeStore from '@pages/Client/PricingStrategy/MinStay/store/changeTypeStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: 'stay_arrival' | 'stay_through';
};

const SetTypeofMinstayModal = ({ onClose, isOpen, mode }: ModalProps) => {
  const { t } = useTranslation();

  const { updatePms, isLoading: isUpdatePmsLoading } = useUpdatePms();
  const { pmsList } = useGetHotelPmsList();
  const { setIsSave, setIsSaveLoading } = useSaveStayTypeStore();

  const connectedPms = pmsList?.find((pms) => pms.primary);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isUpdatePmsLoading) {
      setIsSaveLoading(true);
    } else {
      setIsSaveLoading(false);
    }
  }, [isUpdatePmsLoading]);

  const handleUpdatePms = async () => {
    if (!connectedPms) return;
    await updatePms({
      id: connectedPms?.id,

      payload: {
        provider: connectedPms?.provider,
        configurations: {
          min_stay_type: mode.toString()
        }
      }
    });
    handleClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={t('Are you sure ?')}
      size="sm"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}
    >
      <Stack px="md" pb="md">
        {mode === 'stay_arrival' ? (
          <Text size="sm" ta="justify">
            {t('When Minimum Stay is set to')} <strong>{t('Arrival Based')}</strong>
            {t(
              ', all current adjustments will be sent as such. Only Arrival Based Minimum Stay restrictions will be shown in the calendar, and Orphan Gap correction will function accordingly.'
            )}{' '}
            <strong>{t('This configuration applies to all room types.')}</strong>
          </Text>
        ) : null}
        {mode === 'stay_through' ? (
          <Text size="sm" ta="justify">
            {t('When Minimum Stay is set to ')}
            <strong>{t('Stay Through Based')}</strong>
            {t(
              ', all current adjustments will be sent as such. Only Stay Through Based Minimum Stay restrictions will be shown in the calendar, and Orphan Gap correction will function accordingly. '
            )}
            <strong>{t('This configuration applies to all room types.')}</strong>
          </Text>
        ) : null}
      </Stack>
      <ModalFooter>
        <Button
          onClick={() => {
            handleClose();
            setIsSave(false);
          }}
          variant="subtle"
        >
          {t('Cancel')}
        </Button>
        <Button
          loading={isUpdatePmsLoading}
          onClick={() => {
            handleUpdatePms();
            setIsSave(true);
          }}
        >
          {t('Yes')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SetTypeofMinstayModal;
