import { getDetailProviderConfigs, getProviderConfigs } from '@common/api/integration';
import { Flags } from '@common/constants/flags';
import {
  pmsHasRoomRate,
  pmsHasRoomType,
  pushPmsList,
  pmsProviderListForDerivedRates,
  pmsProviderListForOccupancyBasedPricing
} from '@common/constants/pmsList';
import { PmsConfigQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { find, isNil, map } from 'lodash-es';
import { useMemo } from 'react';

export const useGetProviderList = () => {
  const query = useQuery({
    queryKey: [PmsConfigQueryKeys.GET_PMS_CONFIG_LIST],
    queryFn: getProviderConfigs
  });

  const providerList = useMemo(() => query.data?.data, [query.data]);

  const PROVIDER_OPTION = useMemo(
    () =>
      map(providerList, (provider) => {
        const isOpenApi = provider.provider === 0;
        const labelSuffix = isOpenApi ? ' (O-API)' : '';
        const value = isOpenApi ? `O-${provider.open_api_partner_id}` : `${provider.provider}`;
        const devVal = isOpenApi ? provider.open_api_partner_id : provider.provider;
        const devSuffix = import.meta.env.DEV ? ` - ${devVal}` : '';

        return {
          label: `${provider.name}${labelSuffix}${devSuffix}`,
          value: value
        };
      }),
    [providerList]
  );

  const isOpenApiProvider = (providerId: string | number) => {
    const id =
      typeof providerId === 'string' && providerId.startsWith('O-')
        ? Number(providerId.slice(2))
        : providerId;
    return !!find(
      providerList,
      (provider) => provider.provider === 0 && provider.open_api_partner_id === id
    );
  };

  return { providerList, query, PROVIDER_OPTION, isOpenApiProvider };
};

export const useGetDetailProviderConfig = () => {
  const flags = useFlags();
  const { hasRoomTypeOnly, hasNoUploadPricesButton: noUploadPricesButton } = usePmsProvider();
  const { hotelDetails } = useHotelDetails();

  const isOpenApi = hotelDetails?.pms_provider === PmsProvider.OPEN_API_PMS;

  const isPmsConfigFromBackend =
    flags[Flags.PmsConfigFromBackend] || hotelDetails?.pms_provider === PmsProvider.OPEN_API_PMS;

  const query = useQuery({
    queryKey: [PmsConfigQueryKeys.GET_PMS_CONFIG_DETAIL],
    queryFn: getDetailProviderConfigs,
    enabled: isPmsConfigFromBackend
  });

  const pmsConfig = useMemo(() => query.data?.data, [query.data]);
  const isPmsConfigLoading = query.isLoading;

  const isTwoWayIntegration = hotelDetails?.pms_sync === 2;

  // For Handle Pms Has Upload Price Button
  const hasUploadPriceButton = isPmsConfigFromBackend
    ? pmsConfig?.has_upload_price_button && isTwoWayIntegration
    : isTwoWayIntegration && !isNil(hotelDetails.pms_provider);

  // For Handle Special Pms Like Hotline
  const hasNoUploadPricesButton = isPmsConfigFromBackend
    ? !pmsConfig?.has_upload_price_button
    : noUploadPricesButton();

  // For Handle Pms Has Occupancy Based
  const isOccupancyBased = isPmsConfigFromBackend
    ? pmsConfig?.room_config?.is_occupancy_based
    : pmsProviderListForOccupancyBasedPricing.includes(hotelDetails?.pms_provider || 0);

  // For Handle Pms Has Derived Rates
  const isDerivedRates = isPmsConfigFromBackend
    ? pmsConfig?.room_config?.is_rate_derivation
    : pmsProviderListForDerivedRates.includes(hotelDetails?.pms_provider || 0);

  // For Handle Pms Room Only
  const isRoomOnly = isPmsConfigFromBackend
    ? pmsConfig?.room_config?.room_type_only_mapping
    : hasRoomTypeOnly();

  // For Handle Pms Has Get Latest Data
  const hasGetLatestData = isPmsConfigFromBackend
    ? pmsConfig?.has_get_latest_data
    : !pushPmsList.includes(hotelDetails?.pms_provider || 0);

  // For Handle Pms Has Room Type Table
  const hasRoomTypeTable = isPmsConfigFromBackend
    ? pmsConfig?.displays?.integration_setup?.has_room_type_table
    : pmsHasRoomType.includes(hotelDetails?.pms_provider || 0);

  // For Handle Pms Has Room Rate Table
  const hasRoomRateTable = isPmsConfigFromBackend
    ? pmsConfig?.displays?.integration_setup?.has_rate_plan_table
    : pmsHasRoomRate.includes(hotelDetails?.pms_provider || 0);

  return {
    pmsConfig,
    query,
    isPmsConfigLoading,
    hasUploadPriceButton,
    isOccupancyBased,
    isDerivedRates,
    hasNoUploadPricesButton,
    isRoomOnly,
    hasGetLatestData,
    hasRoomTypeTable,
    hasRoomRateTable,
    isOpenApi
  };
};
