import { Navigate, RouteObject } from 'react-router-dom';
import { ClientsAdminPage } from '@pages/Admin/Clients/pages/Clients';
import { ClientMap } from '@pages/Admin/Clients/pages/ClientMap';

export const clientsRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="login" /> },
  {
    path: 'login',
    element: <ClientsAdminPage />
  },
  {
    path: 'map',
    element: <ClientMap />
  }
];
