import { cond } from 'lodash-es';
import { inRange } from 'lodash-es';

export const priceChangeTrendThresholdPercentage = (currentPrice: number): number => {
  return cond([
    [(price: number) => inRange(price, 0, 200), () => 4],
    [(price) => inRange(price, 200, 400), () => 3],
    [(price) => inRange(price, 400, 2000), () => 2],
    [() => true, () => 4] // This covers the case where price >= 2000
  ])(currentPrice);
};
