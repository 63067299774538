function calculateDiff(a: number, b: number): number {
  const difference = a - b;
  return difference;
}

interface DifferenceResult {
  content: string;
  isNegative: boolean;
}

export function calculateDifferenceWithMessage(
  a: number | null | undefined,
  b: number | null | undefined
): DifferenceResult {
  if (a == null || b == null) {
    return { content: '', isNegative: false };
  }

  if (isNaN(a) || isNaN(b)) {
    return { content: '', isNegative: false };
  }

  if (!isFinite(a) || !isFinite(b)) {
    return { content: '∞', isNegative: false };
  }

  const difference = calculateDiff(a, b);
  const isNegative = difference < 0;

  return {
    content: `${difference.toFixed(0)}`,
    isNegative
  };
}

function calculatePercentageIncrease(newValue: number, oldValue: number): number {
  const difference = newValue - oldValue;
  const percentage = (difference / oldValue) * 100;
  return percentage;
}

interface PercentageResult {
  content: string;
  isNegative: boolean;
}

export function calculatePercentageIncreaseWithMessage(
  oldValue: number | null | undefined,
  newValue: number | null | undefined
): PercentageResult {
  if (oldValue == null || newValue == null) {
    return { content: '', isNegative: false };
  }

  if (isNaN(oldValue) || isNaN(newValue)) {
    return { content: '', isNegative: false };
  }

  if (!isFinite(oldValue) || !isFinite(newValue)) {
    // Hide infinite render on percentage change
    return { content: '', isNegative: false };
  }

  if (oldValue === 0) {
    return {
      content: '',
      isNegative: false
    };
  }

  const percentageIncrease = calculatePercentageIncrease(oldValue, newValue);
  const isNegative = percentageIncrease < 0;

  return {
    content: !isFinite(percentageIncrease) ? '' : `${percentageIncrease.toFixed(0)}%`,
    isNegative
  };
}
