import { axiosInstance } from '@common/api/axiosInstance';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export const useDownloadCSV = (url: string, fileName?: string) => {
  return useMutation({
    mutationFn: () =>
      axiosInstance.get<Blob>(url, {
        responseType: 'blob'
      }),
    onSuccess: (response: AxiosResponse<Blob>) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      if (!fileName) {
        const contentDisposition = response.headers['content-disposition'];

        // Default file name
        fileName = 'downloaded.csv';

        // Extract file name from content-disposition header
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, '');
          }
        }
      }

      link.setAttribute('download', fileName + '.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
};
