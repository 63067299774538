import { Box, List, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { MinStayRulesCard } from '@pages/Client/PricingStrategy/MinStay/components/MinStayRulesCard';
import { useDocumentTitle } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

export const MinStayRulesPage = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Automate Minimum Stay Restrictions'));
  const { hotelDetails } = useHotelDetails();

  return (
    <Box>
      <SimpleGrid
        cols={{
          sm: 1,
          md: 2
        }}>
        <Stack
          maw={{
            base: '100%',
            md: '80%'
          }}>
          <Title order={3} fw={400}>
            {t('Automate Minimum Stay Restrictions')}
          </Title>

          <Text>
            {t(
              'Restrictions can help you maximise your revenue and reduce operational costs. RoomPriceGenie automatically imports minimum stay restrictions from your {{pmsOrChannelManager}} and also uploads any changes you make directly in the Calendar.',
              {
                pmsOrChannelManager: hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
              }
            )}
          </Text>
          <Text>
            {t('Using the criteria here you can automate when these restrictions are applied.')}
          </Text>
          <Text>
            {t(
              'For example, you can set automation to remove all minimum stay restrictions within 5 days of the arrival date.'
            )}
          </Text>

          <Title order={5} fw={500}>
            {t(
              'How do I make sure my minimum stay restrictions are imported from my {{pmsOrChannelManager}}?',
              {
                pmsOrChannelManager: hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
              }
            )}
          </Title>

          <List spacing="md">
            <List.Item>
              {t(
                `We only consider 'Applied Stay Restrictions'. Any 'Closed to Arrival' and 'Closed to Departure' restrictions you have set in your {{pmsOrChannelManager}} are not displayed in your calendar or affected by anything you do in the RoomPriceGenie app.`,
                {
                  pmsOrChannelManager: hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                }
              )}
            </List.Item>
          </List>
        </Stack>

        <Box>
          <MinStayRulesCard />
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default MinStayRulesPage;
