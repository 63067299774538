import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';

export const useInflation = (date: Date) => {
  const { roomPrices, pricingSettings } = useRoomPrices();

  const inflation = useMemo(() => {
    const formattedDateMonth = dayjs(date).format('YYYY-MM');
    return {
      isAutomaticInflationIncrease: pricingSettings?.hotel?.automatic_inflation_increase,
      inflationByMonth:
        1 + (roomPrices?.prices?.inflation_by_month?.[formattedDateMonth] ?? 0) / 100
    };
  }, [roomPrices, date, pricingSettings]);

  return inflation;
};
