import { resetPickupboostpriceSegmentationEffect } from '@common/api/pricingAlgorithm';
import { useNotificationsStore } from '@common/store/notifications';
import { useGetRunPricingRunningStore } from '@pages/Client/PricingStrategy/Segmentation/store/segmentation';
import { useMutation } from '@tanstack/react-query';

export const useRecalculation = () => {
  const { setLoading } = useGetRunPricingRunningStore();
  const { addNotification } = useNotificationsStore();

  const query = useMutation({
    mutationFn: (data: any) => resetPickupboostpriceSegmentationEffect(data),
    onSuccess: (response: any) => {
      const { data } = response;
      if (data?.is_segmentation_reset_executed) setLoading(true);
      addNotification('success', data.message);
    }
  });

  return {
    query
  };
};
