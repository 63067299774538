import { BannerMessages } from '@common/components/molecules/BannerMessages';
import { cn } from '@common/utils/cn';
import React, { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

interface PageProps {
  children: React.ReactNode;
  header: React.ReactNode;
  external?: React.ReactNode;
  fullWidth?: boolean;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, header, external, fullWidth }, ref) => {
    const subdomain = window.location.hostname.split('.')[0];
    const isIframe = subdomain.includes('iframe');
    const location = useLocation();
    const isClientPage = !location.pathname.includes('admin');
    return (
      <div
        ref={ref}
        className={cn(
          isIframe ? '' : 'pt-0 md:pt-7',
          fullWidth ? 'max-w-[1920px]' : 'max-w-[1440px]'
        )}>
        {isClientPage ? <BannerMessages fullWidth={fullWidth} /> : null}
        {header}
        <div
          className={cn(
            'mt-2 pl-4 pr-4 md:mt-4 md:pl-8 md:pr-8',
            fullWidth ? 'min-[2305px]:pr-0' : 'min-[1755px]:pr-0'
          )}>
          {children}
        </div>
        {external}
      </div>
    );
  }
);
