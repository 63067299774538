import { getHotelUsers } from '@common/api/account';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useGetHotelUsers = (isEnabled?: boolean) => {
  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_USERS],
    queryFn: getHotelUsers,
    enabled: isEnabled
  });

  return {
    query,
    hotelUsers: query.data
  };
};
