import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { useModal } from '@common/hooks/useModal';
import { useCallback, useEffect, useState } from 'react';
import { AddNewPartnerModal } from '@pages/Admin/PartnerUsers/components/AddNewPartnerModal';
import { DeletePartnerModal } from '@pages/Admin/PartnerUsers/components/DeletePartnerModal';
import { UpdatePartnerModal } from '@pages/Admin/PartnerUsers/components/UpdatePartnerModal';
import { useGetPartnersList } from '@pages/Admin/PartnerUsers/hooks/usePartnersList';
import { SelectedPartner } from '@pages/Admin/PartnerUsers/types/selectedPartner';
import * as z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { debounce } from 'lodash-es';
import { ActionIcon, Button } from '@mantine/core';

const schema = z.object({
  page: z.number(),
  search: z.string().optional()
});

type Params = z.infer<typeof schema>;

const filterEmptyValues = (params: Params): Params => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_key, value]) => value !== '' && value !== undefined && value !== null
    )
  ) as Params;
};

export const PartnersListTable = () => {
  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const [selectedPartner, setSelectedPartner] = useState<SelectedPartner>();

  const defaultValues = {
    page: 1,
    search: undefined
  };

  const { watch, reset, control, getValues } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });
  const {
    partnerList,
    query: { isLoading }
  } = useGetPartnersList(
    filterEmptyValues({
      page: watch('page'),
      search: getValues('search')
    })
  );

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, defaultValues, filterEmptyValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const ROWS_PER_PAGE = 20;

  const startIndex = partnerList?.current ? (partnerList.current - 1) * ROWS_PER_PAGE + 1 : 0;
  const endIndex =
    partnerList?.current && partnerList.results ? startIndex + partnerList.results.length - 1 : 0;

  const handleNext = () => {
    if (!partnerList?.next) return;
    const params = new URLSearchParams(partnerList.next.toString().split('?')[1]);
    const page = params.get('page');
    if (!page) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: parseInt(page)
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Delete',
      accessorKey: 'delete',
      cell: (row: any) => {
        return (
          <ActionIcon
            onClick={() => {
              setSelectedPartner(row?.row?.original);
              deleteClick();
            }}>
            <Icon.Delete className="text-copyTextGrey" />
          </ActionIcon>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const PartnersListTableHeader = () => {
    return (
      <div className="flex w-full items-center justify-between py-2">
        <Typography>Partner List</Typography>
      </div>
    );
  };

  return (
    <div>
      <Table
        isHover
        isFetching={isLoading}
        headerComponent={<PartnersListTableHeader />}
        skeletonCount={20}
        isRowClickable
        onClickRow={(row: any) => {
          setSelectedPartner(row?.row?.original);
          editClick();
        }}
        columns={columns}
        data={partnerList?.results?.sort((a, b) => a.name.localeCompare(b.name)) || []}
        next={handleNext}
        isNext={!!partnerList?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={partnerList?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        actionComponents={
          <div className="flex items-center gap-4">
            <Controller
              name="search"
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <Input
                  background="grey"
                  placeholder="Search ..."
                  name={name}
                  id={name}
                  type="text"
                  value={value}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    onChange(e);
                  }}
                  onClear={() => {
                    reset(defaultValues);
                  }}
                />
              )}
            />
            <div>
              <Button onClick={addClick}>Add New Partner</Button>
            </div>
          </div>
        }
      />
      <AddNewPartnerModal isOpen={addOpen} onClose={addClose} />
      <UpdatePartnerModal isOpen={editOpen} onClose={editClose} selected={selectedPartner} />
      <DeletePartnerModal isOpen={deleteOpen} user={selectedPartner} onClose={deleteClose} />
    </div>
  );
};
