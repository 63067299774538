import { searchClient } from '@common/api/account';
import { SearchClientResponse } from '@common/api/account/types';
import { ClientQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Params = {
  search: string;
};

export const useSearchClient = ({ search = '' }: Params) => {
  const query = useQuery({
    queryKey: [ClientQueryKeys.SEARCH_CLIENT, search],
    queryFn: async () => {
      if (search.length >= 5) {
        const response = await searchClient(search);
        return response?.data || []; // Return an empty array if response.data is undefined
      } else {
        return [];
      }
    },
    retry: false
  });

  const clientList = useMemo(() => {
    return query.data as SearchClientResponse;
  }, [query.data]);

  return { clientList, query };
};
