import { SelectDropdownInput } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { AGGRESSIVENESS_OPTIONS } from '@common/constants/pricing';
import { useTranslation } from 'react-i18next';
import { AggressivenessOptions } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function AggressiveAdjustmentForm() {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();

  const elasticityOptions = [...AGGRESSIVENESS_OPTIONS].map((elasticity) => ({
    label: elasticity.label,
    value: elasticity.value
  })) as AggressivenessOptions[];

  return (
    <div>
      <Typography variant="paragraph-1" className="text-paragraph-1-medium" color="darkGrey">
        Aggressiveness (All {hotelDetails?.room_apartment_space_name} Types)
      </Typography>
      <div className="mt-2">
        <SelectDropdownInput<AggressivenessOptions>
          background="grey"
          fullWidth
          options={elasticityOptions}
          name={ADJUSTMENT.AGGRESSIVENESS}
          fallbackLabel={t('Select Aggressiveness')}
        />
      </div>
    </div>
  );
}
