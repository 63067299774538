import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 3 * (60 * 1000) // 3 minutes of stale time. If we want to update latest data, just use refetch() method.
    }
  }
});
