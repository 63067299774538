import { memo, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash-es';
import { Card } from '@common/components/molecules/Card';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { Typography } from '@common/components/foundations/Typography';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';

type GroupedData = {
  [key: string]: { name: string; date: string; value: number }[];
};

interface Props {
  data: {
    id: string;
    title: string;
    lineData: { name: string; date: string; value: number }[];
  }[];
}

interface CustomLineChartProps {
  title: string;
  data: GroupedData;
  colors: string[];
  fontFamily: string;
  fontSize: string;
  fontWeight: number;
  mediumGrey: string;
  grey: string;
  CustomTooltip: React.FC<any>;
  seriesVisibility: Record<string, boolean>;
  handleLegendClick: (key: string) => void;
  showLegend?: boolean;
  isPercentage?: boolean;
  isCurrency?: boolean;
}

const CustomLineChart: React.FC<CustomLineChartProps> = ({
  title,
  data,
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  mediumGrey,
  grey,
  CustomTooltip,
  seriesVisibility,
  showLegend,
  handleLegendClick,
  isPercentage,
  isCurrency
}) => {
  const generateSeriesData = (groupedData: GroupedData) => {
    const combinedData: { [key: string]: { x: string; [key: string]: number | string } } = {};

    Object.keys(groupedData).forEach((name) => {
      groupedData[name].forEach((item) => {
        if (!combinedData[item.date]) {
          combinedData[item.date] = { x: item.date };
        }
        combinedData[item.date][name] = item.value;
      });
    });

    return Object.values(combinedData);
  };

  const seriesData = useMemo(() => {
    // Sort data by correct month order
    return sortBy(generateSeriesData(data), (item) => {
      const month = dayjs(item.x).month();
      return month;
    });
  }, [data]);

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;
    const { t } = useTranslation();

    return (
      <ul className="mb-8 flex max-w-full list-none justify-center overflow-hidden">
        {payload?.map((entry: { dataKey: any; value: string; color: string }, index: number) => (
          <li
            key={`item-${index}`}
            className="mr-2 flex max-w-full cursor-pointer items-center overflow-hidden"
            onClick={() => handleLegendClick(entry.dataKey)}>
            <div className="max-w-full">
              <div
                className="mr-1 h-3 w-3 rounded-full"
                style={{
                  backgroundColor: entry.color,
                  opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                }}
              />
            </div>
            <Typography
              color="copyTextGrey"
              variant="meta-2"
              className={clsx(
                'truncate',
                seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30'
              )}>
              {t(entry.value)}
            </Typography>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Typography element="h3" variant="h6" className="font-medium" color="darkGrey">
        {title}
      </Typography>
      <Card backgroundColor="white">
        <ResponsiveContainer width="100%" height={350}>
          <LineChart
            data={seriesData}
            margin={{
              top: 10,
              right: 20,
              left: 20,
              bottom: 0
            }}>
            <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
            <XAxis
              axisLine={false}
              tick={{ fill: grey, stroke: 'none' }}
              height={60}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              dataKey="x"
              tickFormatter={(value) => dayjs(value).format('MMM')}
              interval={0}
            />
            <YAxis
              domain={isPercentage ? [0, 100] : undefined}
              axisLine={false}
              tick={{ fill: grey, stroke: 'none' }}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              tickFormatter={(value) =>
                isPercentage
                  ? formattedPercentage(value)
                  : isCurrency
                  ? formattedCurrency(value)
                  : value
              }
            />
            {showLegend ? (
              <Legend verticalAlign="top" content={(props) => <CustomLegend {...props} />} />
            ) : null}
            <Tooltip
              content={(props) => (
                <CustomTooltip {...props} isCurrency={isCurrency} isPercentage={isPercentage} />
              )}
            />
            {Object.keys(data).map((name, index) => (
              <Line
                isAnimationActive={false}
                visibility={seriesVisibility[name] ? 'visible' : 'hidden'}
                key={index}
                type="linear"
                dataKey={name}
                name={data[name][0].name}
                stroke={colors[index]}
                fill={colors[index]}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};

export const AnalyticsCharts = memo<Props>(function AnalyticsCharts({ data }) {
  const gold = useTailwindColor('gold');
  const indigo = useTailwindColor('indigo');
  const green = useTailwindColor('darkGreen');
  const grey = useTailwindColor('grey');
  const orange = useTailwindColor('orange');
  const uiRed = useTailwindColor('uiRed');
  const uiGreen = useTailwindColor('uiGreen');
  const darkGrey = useTailwindColor('darkGrey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;

  const [seriesVisibility, setSeriesVisibility] = useState<Record<string, boolean>>({});

  const updateSeriesVisibility = (data: Props['data']) => {
    const newSeriesVisibility = data.reduce((acc, item) => {
      item.lineData.forEach((line) => {
        if (!acc[line.name]) {
          acc[line.name] = true;
        }
      });
      return acc;
    }, {} as Record<string, boolean>);

    setSeriesVisibility((prev) => ({ ...prev, ...newSeriesVisibility }));
  };

  useEffect(() => {
    updateSeriesVisibility(data);
  }, [data]);

  const groupLineData = (id: string, data: Props['data']): GroupedData => {
    const groupedData: GroupedData = {};
    data
      .find((item) => item.id === id)
      ?.lineData.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = [];
        }
        groupedData[item.name].push(item);
      });
    return groupedData;
  };

  // Group lineData by 'name' property
  const revenueGroupedData = groupLineData('revenue', data);
  const adrGroupedData = groupLineData('adr', data);
  const occupancyGroupedData = groupLineData('occupancy', data);
  const medianLeadTimeGroupedData = groupLineData('median_lead_time', data);
  const medianLOSGroupedData = groupLineData('median_length_of_stay', data);

  const getTitleById = (id: string, data: Props['data']) =>
    data.find((item) => item.id === id)?.title || '';

  const revenueTitle = getTitleById('revenue', data);
  const adrTitle = getTitleById('adr', data);
  const occupancyTitle = getTitleById('occupancy', data);
  const medianLeadTimeTitle = getTitleById('median_lead_time', data);
  const medianLOSTitle = getTitleById('median_length_of_stay', data);

  const colors = [gold, indigo, green, grey, orange, uiRed, uiGreen, darkGrey];

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload, isCurrency, isPercentage } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-2" className="text-meta-2-semibold" color="grey">
          {dayjs(dataPoint.x).format('MMM YYYY')}
        </Typography>
        {payload.map((entry: any, index: number) => (
          <div
            key={`${entry}-${index}`}
            className="flex max-w-xs items-center justify-between gap-x-3 overflow-hidden">
            <Typography variant="meta-2" color="darkGrey" className="truncate">
              {entry.name}
            </Typography>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              {isPercentage
                ? `${Math.round(entry.value)}%`
                : isCurrency
                ? formattedCurrency(entry.value)
                : entry.value}
            </Typography>
          </div>
        ))}
      </Card>
    );
  };

  // Add onClick handler to toggle series visibility
  const handleLegendClick = (dataKey: any) => {
    setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
  };

  return (
    <>
      <div className="flex flex-col gap-y-6">
        <CustomLineChart
          title={revenueTitle}
          data={revenueGroupedData}
          colors={colors as string[]}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={fontWeight}
          mediumGrey={mediumGrey as string}
          grey={grey as string}
          CustomTooltip={CustomTooltip}
          seriesVisibility={seriesVisibility}
          handleLegendClick={handleLegendClick}
          showLegend
          isCurrency
        />
      </div>

      <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:grid-cols-2">
        <div className="flex flex-col gap-y-6">
          <CustomLineChart
            title={occupancyTitle}
            data={occupancyGroupedData}
            colors={colors as string[]}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            mediumGrey={mediumGrey as string}
            grey={grey as string}
            CustomTooltip={CustomTooltip}
            seriesVisibility={seriesVisibility}
            handleLegendClick={handleLegendClick}
            isPercentage
          />
        </div>
        <div className="flex flex-col gap-y-6">
          <CustomLineChart
            title={adrTitle}
            data={adrGroupedData}
            colors={colors as string[]}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            mediumGrey={mediumGrey as string}
            grey={grey as string}
            CustomTooltip={CustomTooltip}
            seriesVisibility={seriesVisibility}
            handleLegendClick={handleLegendClick}
            isCurrency
          />
        </div>
        <div className="flex flex-col gap-y-6">
          <CustomLineChart
            title={medianLeadTimeTitle}
            data={medianLeadTimeGroupedData}
            colors={colors as string[]}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            mediumGrey={mediumGrey as string}
            grey={grey as string}
            CustomTooltip={CustomTooltip}
            seriesVisibility={seriesVisibility}
            handleLegendClick={handleLegendClick}
          />
        </div>
        <div className="flex flex-col gap-y-6">
          <CustomLineChart
            title={medianLOSTitle}
            data={medianLOSGroupedData}
            colors={colors as string[]}
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontWeight={fontWeight}
            mediumGrey={mediumGrey as string}
            grey={grey as string}
            CustomTooltip={CustomTooltip}
            seriesVisibility={seriesVisibility}
            handleLegendClick={handleLegendClick}
          />
        </div>
      </div>
    </>
  );
});
