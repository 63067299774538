import { useEffect, useMemo, useState } from 'react';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { find, isNil, keys, map } from 'lodash-es';
import { usePricingAdjustmentOptions } from '@pages/Client/Calendar/hooks/usePricingAdjustmentOptions';
import { useFormContext } from 'react-hook-form';
import { SegmentedControl } from '@mantine/core';

export function PricingTabOptions() {
  const formName = 'adjustmentType';
  const { setValue, formState } = useFormContext();
  const { adjustmentOptions } = usePricingAdjustmentOptions();
  const { adjustment } = useCalendarPageStore();
  const [currentTab, setCurrentTab] = useState(0);

  const options = useMemo(() => {
    return map(adjustmentOptions, (option, index) => {
      return {
        ...option,
        tab: index
      };
    });
  }, [adjustmentOptions]);

  function updateTab(optionValue: number) {
    const newAdjustment = find(options, ['value', optionValue]);
    setValue(formName, Number(optionValue));
    setCurrentTab(newAdjustment?.tab ?? 0);
  }

  function handleTabChange(optionValue: number) {
    updateTab(optionValue);
  }

  useEffect(() => {
    const newAdjustment = find(options, ['value', adjustment]);
    if (!isNil(adjustment) && newAdjustment?.tab !== currentTab) {
      updateTab(adjustment);
    }
  }, []);

  useEffect(() => {
    const { isSubmitting, isValid, errors } = formState;
    if (isSubmitting && !isValid) {
      const newAdjustment = find(options, ['id', keys(errors)[0]]);
      updateTab(newAdjustment?.value ?? 0);
    }
  }, [formState]);

  return (
    <SegmentedControl
      onChange={(value) => {
        handleTabChange(Number(value));
      }}
      data={options.map((option) => ({
        value: option.value.toString(),
        label: <span data-userflow-id={option.userflowId}>{option.label}</span>
      }))}
      value={adjustmentOptions[currentTab]?.value.toString()}
    />
  );
}
