import { setAuthToken } from '@common/api/axiosInstance';
import { getProperties } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useGetSSOToken } from '@pages/Auth/SSO/store/ssoToken';
import { ErrorResponse } from '@pages/Auth/SSO/types/error';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useGetProperties = () => {
  const { addNotification } = useNotificationsStore();
  const { ssoToken } = useGetSSOToken();

  const { data, isLoading, error, refetch, isError } = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_PROPERTIES],
    queryFn: async () => {
      setAuthToken(ssoToken, true);
      const properties = await getProperties();
      return properties;
    },
    enabled: !!ssoToken,
    retry: false
  });

  if (isError) {
    const err = error as AxiosError;
    let errorMessage = 'Failed to get hotel properties';
    const responseError = err.response?.data as ErrorResponse;
    if (responseError && responseError.errors) {
      errorMessage = responseError.errors[0]?.detail ?? errorMessage;
    }
    addNotification('fail', errorMessage);
  }

  return {
    hotelProperties: data?.data,
    isLoading,
    refetch,
    error
  };
};
