import React, { useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  Line
} from 'recharts';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { useDate } from '@common/hooks/useDate';
import { API_DATE_FORMAT } from '@common/constants/date';
import { usePriceDrawerStore } from '@common/store/priceDrawer';

type DataEntry = {
  date: string;
  value: number | undefined;
  airbnbValue: number | undefined;
  stlyValue: number | undefined;
  pctValue: number | undefined;
  pctAirbnbValue: number | undefined;
  pctStlyValue: number | undefined;
};

interface Props {
  data: DataEntry[];
  view?: 'monthly' | 'yearly';
}

export const AirbnbPickupChart: React.FC<Props> = ({ data, view }) => {
  const { t } = useTranslation();
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;
  const indigo = useTailwindColor('indigo');
  const orange = useTailwindColor('orange');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const darkGrey = useTailwindColor('darkGrey');
  const gold = useTailwindColor('gold');
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    pctValue: true,
    pctAirbnbValue: true,
    pctStlyValue: true
  });

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dayjs(dataPoint.date).format(view === 'monthly' ? 'ddd, DD MMM YYYY' : 'MMM YYYY')}
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full" style={{ backgroundColor: orange }} />
            <div className="flex flex-col">
              <Typography variant="meta-2" color="darkGrey">
                {t(`Vacation Rental Pickup`)}
              </Typography>
            </div>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{dataPoint.airbnbValue ? Math.round(dataPoint.airbnbValue) : 'n.A.'}</b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full" style={{ backgroundColor: orange }} />
            <div className="flex flex-col">
              <Typography variant="meta-2" color="darkGrey">
                {t(`Vacation Rental Pickup Index in %`)}
              </Typography>
            </div>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>
              {dataPoint.pctAirbnbValue ? formattedPercentage(dataPoint.pctAirbnbValue) : 'n.A.'}
            </b>
          </Typography>
        </div>
        {dataPoint.value || dataPoint.value === 0 ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-indigo" />
              <Typography variant="meta-2" color="darkGrey">
                {t(`On the Books Pickup`)}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{dataPoint.value ? Math.round(dataPoint.value) : 'n.A.'}</b>
            </Typography>
          </div>
        ) : null}
        {dataPoint.pctValue || dataPoint.pctValue === 0 ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-indigo" />
              <Typography variant="meta-2" color="darkGrey">
                {t(`On the Books Pickup in %`)}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{dataPoint.pctValue ? formattedPercentage(dataPoint.pctValue) : 'n.A.'}</b>
            </Typography>
          </div>
        ) : null}
        {dataPoint.stlyValue || dataPoint.stlyValue === 0 ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-gold" />
              <Typography variant="meta-2" color="darkGrey">
                {t(`STLY Pickup`)}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{Math.round(dataPoint.stlyValue)}</b>
            </Typography>
          </div>
        ) : null}
        {dataPoint.pctStlyValue || dataPoint.pctStlyValue === 0 ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-gold" />
              <Typography variant="meta-2" color="darkGrey">
                {t(`STLY Pickup in %`)}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>{formattedPercentage(dataPoint.pctStlyValue)}</b>
            </Typography>
          </div>
        ) : null}
      </Card>
    );
  };

  const customLegendPayload = [
    {
      dataKey: 'pctAirbnbValue',
      value: t(`Vacation Rental Pickup`),
      color: orange
    },
    {
      dataKey: 'pctValue',
      value: t(`On the Books`),
      color: indigo
    },
    {
      dataKey: 'pctStlyValue',
      value: t(`Same Time Last Year`),
      color: gold
    }
  ];

  const filteredLegendPayload = customLegendPayload.filter((item) =>
    data.some(
      (d) => d[item.dataKey as DataKey] !== null && d[item.dataKey as DataKey] !== undefined
    )
  );

  type DataKey = 'pctValue' | 'pctAirbnbValue' | 'pctStlyValue';

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    return (
      <ul className="mb-8 flex h-6 list-none justify-center gap-4">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => {
            return (
              <li
                key={`item-${index}`}
                className="mr-2 flex cursor-pointer items-center"
                onClick={() => handleLegendClick(entry.dataKey)}>
                <div
                  className="mr-1 h-3 w-3 rounded-full"
                  style={{
                    backgroundColor: entry.color,
                    opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                  }}
                />
                <Typography
                  color="copyTextGrey"
                  variant="meta-2"
                  className={cn(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                  {entry.value}
                </Typography>
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const CustomTick: React.FC<any> = ({ x, y, payload }) => {
    const { isToday, isWeekend } = useDate();
    const date = new Date(payload.value);

    if (view === 'yearly') {
      return (
        <text
          x={x}
          y={y + 15}
          fill="grey"
          fontSize={fontSize}
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          textAnchor="middle"
          className="cursor-pointer">
          {dayjs(payload.value).format('MMM YYYY')}
        </text>
      );
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={16}
          dy={0}
          textAnchor="end"
          fill={isToday(date) ? darkGrey : grey}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={isToday(date) ? 'bold' : fontWeight}
          textDecoration={isWeekend(date) ? 'underline' : 'none'}
          transform="rotate(-45 0 16)">
          {dayjs(payload.value).format('MMM DD')}
        </text>
      </g>
    );
  };

  interface CustomBarProps {
    fill: string;
    x: number;
    y: number;
    width: number;
    height: number;
    dataKey: string;
    seriesVisibility: { [key: string]: boolean };
    borderRadius?: number;
  }

  const CustomBar: React.FC<CustomBarProps> = ({
    fill,
    x,
    y,
    width,
    height,
    dataKey,
    seriesVisibility,
    borderRadius = 2
  }) => {
    // Prevent undefined console errors
    if (!height) height = 0;
    if (!y) y = 0;

    const barVisible = seriesVisibility[dataKey];

    // Adjust y and height for negative values
    const adjustedY = height < 0 ? y + height : y;
    const adjustedHeight = Math.abs(height);

    return (
      <g>
        {barVisible ? (
          <rect
            x={x}
            y={adjustedY}
            width={width}
            height={adjustedHeight}
            fill={fill}
            rx={borderRadius}
            ry={borderRadius}
          />
        ) : null}
      </g>
    );
  };

  const handleOpenPriceDrawer = (date: string | undefined) => {
    if (!date || view === 'yearly') return;
    const formattedDate = new Date(dayjs(date).format(API_DATE_FORMAT));
    setDrawerState(true);
    setViewingDate(formattedDate);
  };

  return (
    <Card backgroundColor="white">
      <ScrollArea>
        <ResponsiveContainer minWidth={1000} width="100%" height={400}>
          <ComposedChart
            onClick={(data: any) => {
              handleOpenPriceDrawer(data?.activeLabel);
            }}
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
            barGap={-20}
            barCategoryGap={5}>
            <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
            <XAxis
              axisLine={false}
              height={80}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              dataKey="date"
              tick={<CustomTick />}
              interval={0}
            />
            <YAxis
              domain={undefined}
              axisLine={false}
              tick={{ fill: grey }}
              tickLine={false}
              width={80}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              tickFormatter={(value) => formattedPercentage(value)}
            />
            <RechartsTooltip
              content={(props) => <CustomTooltip {...props} />}
              wrapperStyle={{ outline: 'none' }}
            />
            <Legend
              verticalAlign="top"
              content={(props) => <CustomLegend {...props} payload={filteredLegendPayload} />}
            />
            <Bar
              visibility={seriesVisibility.pctValue ? 'visible' : 'hidden'}
              dataKey="pctValue"
              name="Value"
              fill={indigo}
              barSize={8}
              shape={(props) => (
                <CustomBar {...props} seriesVisibility={seriesVisibility} dataKey="pctValue" />
              )}
            />
            <Bar
              visibility={seriesVisibility.pctAirbnbValue ? 'visible' : 'hidden'}
              dataKey="pctAirbnbValue"
              name="Vacation Rental Value"
              fill={orange}
              barSize={8}
              shape={(props) => (
                <CustomBar
                  {...props}
                  seriesVisibility={seriesVisibility}
                  dataKey="pctAirbnbValue"
                />
              )}
            />
            <Line
              isAnimationActive={false}
              visibility={seriesVisibility.pctStlyValue ? 'visible' : 'hidden'}
              dataKey="pctStlyValue"
              name="Same Time Last Year"
              stroke={gold}
              fill={gold}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </Card>
  );
};
