import { reportIssue } from '@common/api/hotel';
import { ReportIssuePayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useReportIssue = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: ReportIssuePayload) => {
      return reportIssue(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully to report issue');
    },
    onError: () => {
      addNotification('fail', 'Failed to send report issue');
    }
  });

  return {
    reportIssue: mutate,
    isLoading
  };
};
