import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Card } from '@common/components/molecules/Card';
import { useHotelStore } from '@common/store/auth';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { DownloadJson } from '@pages/Client/SettingsLog/utils/JsonDownload';
import { useModal } from '@common/hooks/useModal';
import { ResetPriceModal } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/PickupBoostPrices/components/ResetPricesModal';
import { PickupBoostPriceChart } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/PickupBoostPrices/components/PickupBoostPricesChart';
import { useResetPickupBoostPriceAndOccupancy } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useResetPickupBoostPriceAndOccupancy';
import { useResetPickupBoostPrice } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useResetPickupBoostPrice';

export const PickupBoostPrices = () => {
  const { cachePriceQuery } = useRoomPrices();
  const { id } = useParams();
  const { hotelAuthToken } = useHotelStore();
  const { data: cachePrice } = cachePriceQuery;
  const { isOpen: isResetOpen, openModal: resetClick, closeModal: resetClose } = useModal();
  const [isResetOccupancy, setIsResetOccupancy] = useState(false);

  const { resetPickupBoostPrice, isLoading: isResetPickupBoostPriceLoading } =
    useResetPickupBoostPrice();
  const {
    resetPickupBoostPriceAndOccupancy,
    isLoading: isResetPickupBoostPriceAndOccupancyLoading
  } = useResetPickupBoostPriceAndOccupancy();

  const dataPickupBoostPrice = cachePrice?.data.pickup_boost_prices
    ? cachePrice.data.pickup_boost_prices
    : {};

  const data = Object.entries(dataPickupBoostPrice).map(([date, details]) => ({
    date: date,
    pms_price: details.arguments.current_pms_price,
    pickup_boost_price: details.pickup_boost_price
  }));

  const handleResetPickupBoostPrice = () => {
    const startDate = dayjs().format('YYYY-MM-DD');
    const endDate = dayjs().add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD');
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: hotelAuthToken
    };
    if (isResetOccupancy) {
      resetPickupBoostPriceAndOccupancy({
        ...data,
        return_full_dates: false
      });
    } else {
      resetPickupBoostPrice(data);
    }
  };

  return (
    <Card backgroundColor="white" className="shadow-none">
      <div className="my-2 flex justify-end  gap-3">
        <Button
          intent="text"
          onClick={() => {
            DownloadJson(dataPickupBoostPrice, 'pickup-boost-prices', parseInt(id ? id : '0'));
          }}>
          <Icon.Download className="h-4 w-4" />
          Download Log
        </Button>
        <Button
          intent="outline"
          onClick={() => {
            resetClick();
            setIsResetOccupancy(false);
          }}
          isLoading={isResetPickupBoostPriceLoading}>
          Reset Model To PMS Prices
        </Button>
        <Button
          intent="outline"
          onClick={() => {
            resetClick();
            setIsResetOccupancy(true);
          }}
          isLoading={isResetPickupBoostPriceAndOccupancyLoading}>
          Reset Model To PMS Prices + Occupancy
        </Button>
      </div>
      <PickupBoostPriceChart data={data} />
      <ResetPriceModal
        isOpen={isResetOpen}
        onClose={resetClose}
        onReset={handleResetPickupBoostPrice}
      />
    </Card>
  );
};
