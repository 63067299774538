import { getHotelPricePerRate } from '@common/api/hotel';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useHotelPricePerRate = (id: number | null) => {
  const query = useQuery({
    queryKey: [PricingQueryKeys.GET_HOTEL_PRICE_PER_RATE, id],
    queryFn: () => getHotelPricePerRate(id),
    enabled: !!id
  });
  const hotelPricePerRate = useMemo(() => {
    return query.data;
  }, [query.data]);

  return {
    query,
    hotelPricePerRate
  };
};
