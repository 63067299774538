import { LDClient } from 'launchdarkly-react-client-sdk';
import { isEqual, merge } from 'lodash-es';

export type RPGLDIdentityKey = 'hotel' | 'user' | 'clerk';

export interface UserContext {
  key: string;
  [key: string]: any;
}

export interface RPGLDIdentities {
  user?: string | number | boolean | UserContext;
  [key: string]: any;
}

export async function ldIdentify(ldClient: LDClient | undefined, newContext: RPGLDIdentities) {
  if (!ldClient) return;
  const currentContext = ldClient.getContext();
  if (isEqual(currentContext, newContext)) return;
  const newMerge = merge({}, currentContext, newContext);
  await ldClient.identify(newMerge);
}
