export function ArrowSubscription(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="10"
      height="41"
      viewBox="0 0 10 41"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M9.34436 0.500185C9.6204 0.507705 9.83808 0.737575 9.83056 1.01361L9.70803 5.51195C9.70051 5.78799 9.47064 6.00567 9.1946 5.99815C8.91856 5.99063 8.70088 5.76076 8.7084 5.48472L8.81732 1.4862L4.8188 1.37728C4.54276 1.36976 4.32508 1.13989 4.3326 0.863853C4.34012 0.587813 4.56999 0.370134 4.84603 0.377653L9.34436 0.500185ZM9.67454 1.36305C-1.35332 11.8061 -1.82744 29.2116 8.61557 40.2395L7.88947 40.9271C-2.93328 29.4982 -2.44192 11.4597 8.98695 0.636951L9.67454 1.36305Z" />
    </svg>
  );
}
