import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { useSurgeProtectionStore } from '@pages/Client/PricingStrategy/SurgeProtection/store/surgeProtection';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { z } from 'zod';

export const useFormSchema = () => {
  const { isAutoProtectionCheckboxEnable } = useSurgeProtectionStore();
  const { pricingSettings } = usePricingSettings();

  const referenceRoomId = pricingSettings?.rooms?.reference?.id ?? '';
  const basePrice = Math.round(pricingSettings?.default[referenceRoomId]?.avg_price || 0);

  const validationEmptyMessage = 'Please enter a value';

  const schema = z.object({
    user_recipients: z.array(z.number()).optional().default([]),
    auto_price_protection: z.boolean().optional().default(false),
    // 10% of total number of rooms
    threshold: z
      .string({
        invalid_type_error: 'Please enter a number'
      })
      .nonempty({ message: validationEmptyMessage })
      .or(z.number())
      .transform((val) => parseInt(val.toString(), 10))
      .refine((val) => !isNaN(val) && val >= 2, {
        message: 'Please enter a value greater than 2'
      }),
    days_ahead: z
      .string({
        invalid_type_error: 'Please enter a number'
      })
      .nonempty({ message: validationEmptyMessage })
      .or(z.number())
      .transform((val) => parseInt(val.toString(), 10))
      .refine((val) => !isNaN(val) && val >= 15, {
        message: 'Please enter a value greater than 15'
      }),
    fix_price: !isAutoProtectionCheckboxEnable
      ? z
          .string({
            invalid_type_error: 'Please enter a number'
          })
          .or(z.number())
          .transform((val) => parseInt(val.toString(), 10))
      : z
          .string({
            invalid_type_error: 'Please enter a number'
          })
          .nonempty({ message: validationEmptyMessage })
          .or(z.number())
          .transform((val) => parseInt(val.toString(), 10))
          .refine((val) => !isNaN(val) && val >= Math.round(basePrice * 1.5), {
            message: `Must be at least 50% higher than your base price (${CurrencyFormatter.format(
              Math.round(basePrice * 1.5)
            )}).`
          }),
    duration_in_days: !isAutoProtectionCheckboxEnable
      ? z
          .string({
            invalid_type_error: 'Please enter a number'
          })
          .or(z.number())
          .transform((val) => parseInt(val.toString(), 10))
      : z
          .string({
            invalid_type_error: 'Please enter a number'
          })
          .nonempty({ message: validationEmptyMessage })
          .or(z.number())
          .transform((val) => parseInt(val.toString(), 10))
          .refine((val) => !isNaN(val) && val >= 1, {
            message: 'Please enter a value greater than 1'
          }),
    decay_step: z.number().optional()
  });

  return {
    schema
  };
};
