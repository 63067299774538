import { LANGUAGES } from '@common/constants/language';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, Select, Stack, Switch } from '@mantine/core';
import {
  useEnableIframe,
  useEnableIframeStatus
} from '@pages/Client/Account/Integrations/hooks/useEnableIframe';
import { useOwnerDetails } from '@pages/Client/Account/User/hooks/useUser';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = z.object({
  iframe_token: z.string().trim().min(1, { message: 'Required' }),
  iframe_language: z.string().trim().min(1, { message: 'Required' }).nullable().optional(),
  enable: z.boolean().optional()
});

const ManageIframeModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelIframeId } = useAuthStore();
  const { enableIframe } = useEnableIframe();
  const { ownerDetails } = useOwnerDetails();
  const { iframe, isLoading } = useEnableIframeStatus();

  const { handleSubmit, control, formState, setValue } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (!hotelIframeId) return;
    if (iframe && !isLoading) {
      setValue('iframe_language', iframe?.data?.language || ownerDetails?.language);
      setValue('enable', iframe?.data?.enabled);
      setValue('iframe_token', hotelIframeId);
    }
  }, [iframe, isLoading]);

  const onSubmit = (data: any) => {
    enableIframe(data);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={t(`Manage Iframe`)}
      size="md"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}>
      <Stack p="md" gap="lg">
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              disabled={formState.isSubmitting}
              data={LANGUAGES}
              label={t('Language')}
              withAsterisk
            />
          )}
          name="iframe_language"
          control={control}
        />
        <Controller
          name="enable"
          control={control}
          render={({ field }) => {
            const { value, ...restField } = field;

            return (
              <Switch
                {...restField}
                checked={value ?? false}
                label={t('Enable Iframe')}
                onChange={(event) => field.onChange(event.target.checked)}
                disabled={formState.isSubmitting}
                size="md"
              />
            );
          }}
        />
      </Stack>
      <ModalFooter>
        <Button onClick={onClose} variant="subtle">
          {t('Cancel')}
        </Button>
        <Button type="submit" loading={false} onClick={() => handleSubmit(onSubmit)()}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ManageIframeModal;
