export const Comment = (props: any) => {
  return (
    <svg
      width="24"
      {...props}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V17C2 18.1046 2.89543 19 4 19H9.09C9.35234 19.0011 9.60374 19.1053 9.79 19.29L11.28 20.78C11.4205 20.9207 11.6112 20.9998 11.81 21H12.19C12.3888 20.9998 12.5795 20.9207 12.72 20.78L14.21 19.29C14.3963 19.1053 14.6477 19.0011 14.91 19H20C21.1046 19 22 18.1046 22 17V5ZM14 13.5C14 13.7761 13.7761 14 13.5 14H6.5C6.22386 14 6 13.7761 6 13.5V12.5C6 12.2239 6.22386 12 6.5 12H13.5C13.7761 12 14 12.2239 14 12.5V13.5ZM17.5 10C17.7761 10 18 9.77614 18 9.5V8.5C18 8.22386 17.7761 8 17.5 8H6.5C6.22386 8 6 8.22386 6 8.5V9.5C6 9.77614 6.22386 10 6.5 10H17.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
