import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { useState, useMemo, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useHotelRoomNotes } from '@pages/Client/Calendar/hooks/useHotelRoomNotes';
import { API_DATE_FORMAT } from '@common/constants/date';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { applyIndividualAdjustment, savePricingSettings } from '@common/api/hotel';
import { UserNotesSchema } from '@pages/Client/Calendar/utils/validateBaseSettings';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from '@common/components/molecules/DateRangePicker/DateRangePicker';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { Textarea } from '@common/components/atoms/Textarea';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { isNil } from 'lodash-es';
import { useNotificationsStore } from '@common/store/notifications';
import { NotesQueryKeys, PricingQueryKeys } from '@common/types/query-keys';

interface NotesSectionProps {
  day: Dayjs;
}

const generateId = () => Math.floor(1000 + Math.random() * 9000);

export const NotesSection = ({ day }: NotesSectionProps) => {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const { notes: roomNotes } = useHotelRoomNotes();
  const { pricingSettings } = usePricingSettings();
  const { addNotification } = useNotificationsStore();

  const { mutateAsync: saveNote } = useMutation({
    mutationKey: [NotesQueryKeys.SAVE_NOTE],
    mutationFn: savePricingSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
      queryClient.invalidateQueries({ queryKey: [NotesQueryKeys.GET_HOTEL_ROOM_NOTES] });
    }
  });
  const [isAddingNewNote, setIsAddingNewNote] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState<z.infer<typeof UserNotesSchema> | null>(null);

  const { control, handleSubmit, setValue, reset, getValues, formState } = useForm<
    z.infer<typeof UserNotesSchema>
  >({
    mode: 'onSubmit',
    resolver: zodResolver(UserNotesSchema),
    defaultValues: {
      name: '',
      start_date: day.startOf('day').toDate(),
      end_date: day.endOf('day').toDate()
    }
  });

  useEffect(() => {
    setValue('start_date', day.startOf('day').toDate());
    setValue('end_date', day.endOf('day').toDate());
  }, [day]);

  const notes = useMemo(() => {
    const currentDate = day.startOf('day').format(API_DATE_FORMAT);

    return roomNotes?.[currentDate] ?? [];
  }, [roomNotes, day]);

  const startEditing = (note: z.infer<typeof UserNotesSchema>) => {
    setValue('name', note.name);
    setValue('start_date', dayjs(note.start_date).startOf('day').toDate());
    setValue('end_date', dayjs(note.end_date).endOf('day').toDate());
    setIsEditingNote(note);
    setIsAddingNewNote(true);
  };

  const onSubmit = async () => {
    try {
      const id = isEditingNote ? isEditingNote.id : generateId();

      const newNotes = [...(pricingSettings?.user_notes || [])];
      if (isEditingNote) {
        newNotes[newNotes.findIndex((note) => note.id === id)] = {
          id,
          name: getValues('name'),
          start_date: dayjs(getValues('start_date')).startOf('day').format(API_DATE_FORMAT),
          end_date: dayjs(getValues('end_date')).endOf('day').format(API_DATE_FORMAT)
        };
      } else {
        newNotes.push({
          id,
          name: getValues('name'),
          start_date: dayjs(getValues('start_date')).startOf('day').format(API_DATE_FORMAT),
          end_date: dayjs(getValues('end_date')).endOf('day').format(API_DATE_FORMAT)
        });
      }

      await saveNote(
        JSON.stringify({
          ...pricingSettings,
          user_notes: newNotes
        })
      );
      if (isNil(pricingSettings)) throw new Error('Invalid new prices');
      await applyIndividualAdjustment({
        is_write_to_cache: true,
        start_date: dayjs(getValues('start_date')).startOf('day').format(API_DATE_FORMAT),
        end_date: dayjs(getValues('end_date')).endOf('day').format(API_DATE_FORMAT),
        json_settings: JSON.stringify({
          ...pricingSettings,
          user_notes: newNotes
        })
      });
      setIsEditingNote(null);
      setIsAddingNewNote(false);
      reset();
      // Track event
      trackEvent('NoteAdded');
    } catch (error) {
      addNotification('fail', 'Failed to add a note');
      setIsEditingNote(null);
      setIsAddingNewNote(false);
    }
  };

  const deleteNote = async (id: number) => {
    try {
      const newNotes = [...(pricingSettings?.user_notes || [])].filter((note) => note.id !== id);

      await saveNote(
        JSON.stringify({
          ...pricingSettings,
          user_notes: newNotes
        })
      );
      if (isNil(pricingSettings)) throw new Error('Invalid new prices');
      await applyIndividualAdjustment({
        is_write_to_cache: true,
        start_date: dayjs(getValues('start_date')).startOf('day').format(API_DATE_FORMAT),
        end_date: dayjs(getValues('end_date')).endOf('day').format(API_DATE_FORMAT),
        json_settings: JSON.stringify({
          ...pricingSettings,
          user_notes: newNotes
        })
      });

      // Reset states
      setIsEditingNote(null);
      setIsAddingNewNote(false);
      reset();
      // Track event
      trackEvent('NoteRemoved');
    } catch (error) {
      addNotification('fail', 'Failed to delete a note');
      setIsEditingNote(null);
      setIsAddingNewNote(false);
    }
  };

  return (
    <>
      <div className="text-meta-2-semibold text-grey">{t('Notes')}</div>
      {notes.map((note) => (
        <div key={`note-${note.id}`}>
          <button
            className="mt-2 flex w-full justify-between rounded-md border border-grey-reduced px-4 py-2 hover:bg-indigo-reduced hover:bg-opacity-30"
            type="button"
            onClick={() => startEditing(note)}>
            <div className="flex w-full items-center justify-between gap-2">
              <div className="text-left">
                <dt className="text-meta-2 text-copyTextGrey">
                  {dayjs(note.start_date).startOf('day').format('DD/MM/YYYY')}
                  {dayjs(note.start_date).startOf('day').isSame(dayjs(note.end_date).endOf('day'))
                    ? null
                    : ` - ${dayjs(note.end_date).endOf('day').format('DD/MM/YYYY')}`}
                </dt>
                <dd className="text-meta-1 text-darkGrey">{note.name}</dd>
              </div>
              <div>
                <Button icon>
                  <Icon.Delete className="h-4 w-4" onClick={() => deleteNote(note.id)} />
                </Button>
              </div>
            </div>
          </button>

          {isEditingNote && isEditingNote.id === note.id && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Textarea
                    {...field}
                    onClick={() => setIsAddingNewNote(true)}
                    placeholder={t('Add a note') as string}
                    className="mt-2 px-4 py-2 text-left"
                  />
                )}
              />

              <div className="mt-4 flex items-center">
                <DateRangePicker
                  ISOWeek={hotelDetails?.starts_monday}
                  timezone={hotelDetails?.timezone}
                  startDate={dayjs(getValues('start_date')).toDate()}
                  endDate={dayjs(getValues('end_date')).toDate()}
                  onDateChange={(date) => {
                    setValue('start_date', dayjs(date?.from).toDate());
                    setValue('end_date', dayjs(date?.to).toDate());
                  }}
                />
              </div>
              <div className="mt-4 flex justify-end gap-x-4 text-sm">
                <Button
                  type="button"
                  intent="text"
                  onClick={() => {
                    reset();
                    setIsEditingNote(null);
                  }}>
                  {t('Cancel')}
                </Button>
                <Button intent="primary" type="submit" disabled={!formState.isValid}>
                  {t('Save Note')}
                </Button>
              </div>
            </form>
          )}
        </div>
      ))}

      {!isEditingNote ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Textarea
                {...field}
                onClick={() => setIsAddingNewNote(true)}
                placeholder={t('Add a note') as string}
                className="mt-2 px-4 py-2 text-left"
              />
            )}
          />
          {isAddingNewNote && !isEditingNote ? (
            <>
              <div className="mt-4 flex items-center">
                <DateRangePicker
                  ISOWeek={hotelDetails?.starts_monday}
                  timezone={hotelDetails?.timezone}
                  startDate={dayjs(getValues('start_date')).toDate()}
                  endDate={dayjs(getValues('end_date')).toDate()}
                  onDateChange={(date) => {
                    setValue('start_date', dayjs(date?.from).toDate());
                    setValue('end_date', dayjs(date?.to).toDate());
                  }}
                />
              </div>
              <div className="mt-4 flex justify-end gap-x-4 text-sm">
                <Button
                  type="button"
                  intent="text"
                  onClick={() => {
                    reset();
                    setIsAddingNewNote(false);
                  }}>
                  {t('Cancel')}
                </Button>
                <Button intent="primary" type="submit" disabled={!formState.isValid}>
                  {t('Save Note')}
                </Button>
              </div>
            </>
          ) : null}
        </form>
      ) : null}
    </>
  );
};
