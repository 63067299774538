export const Calendar = (props: any) => (
  <svg
    fill="currentColor"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 4H18V2.5C18 2.22386 17.7761 2 17.5 2H16.5C16.2239 2 16 2.22386 16 2.5V4H8V2.5C8 2.22386 7.77614 2 7.5 2H6.5C6.22386 2 6 2.22386 6 2.5V4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V6C21 4.89543 20.1046 4 19 4ZM19 19H5V8H19V19ZM12.5 12H11.5C11.2239 12 11 11.7761 11 11.5V10.5C11 10.2239 11.2239 10 11.5 10H12.5C12.7761 10 13 10.2239 13 10.5V11.5C13 11.7761 12.7761 12 12.5 12ZM16.5 12C16.7761 12 17 11.7761 17 11.5V10.5C17 10.2239 16.7761 10 16.5 10H15.5C15.2239 10 15 10.2239 15 10.5V11.5C15 11.7761 15.2239 12 15.5 12H16.5ZM9 11.5C9 11.7761 8.77614 12 8.5 12H7.5C7.22386 12 7 11.7761 7 11.5V10.5C7 10.2239 7.22386 10 7.5 10H8.5C8.77614 10 9 10.2239 9 10.5V11.5ZM11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V14.5C13 14.2239 12.7761 14 12.5 14H11.5C11.2239 14 11 14.2239 11 14.5V15.5C11 15.7761 11.2239 16 11.5 16ZM9 15.5C9 15.7761 8.77614 16 8.5 16H7.5C7.22386 16 7 15.7761 7 15.5V14.5C7 14.2239 7.22386 14 7.5 14H8.5C8.77614 14 9 14.2239 9 14.5V15.5Z"
    />
  </svg>
);
