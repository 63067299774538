import { AdjustmentType } from '@pages/Client/Calendar/constants';

export enum ADJUSTMENT {
  ADJUSTMENT_TYPE = 'adjustmentType',
  AGGRESSIVENESS = 'aggressivenessAdjustment',
  CLOSED = 'closedRoomsAdjustment',
  DERIVATION = 'derivationAdjustment',
  FIX_PRICES = 'fixPricesAdjustment',
  MEDIAN = 'medianAdjustment',
  MIN_MAX = 'minMaxAdjustment',
  MIN_STAY = 'minStayAdjustment',
  MIN_STAY_SKIP_RULE = 'minStaySkipRuleAdjustment',
  OCCUPANCY = 'occupancyAdjustment',
  PERCENT = 'percentAdjustment'
}

export enum ADJUSTMENT_DB {
  AGGRESSIVENESS_KEY = 'PELL',
  AVG_PRICE_KEY = 'avg_price',
  CLOSED_KEY = 'closed_rooms',
  DERIVATION_ABSOLUTE_KEY = 'adjustment_to_reference_room_is_absolute',
  DERIVATION_KEY = 'adjustment_to_reference_room',
  FIX_PRICES_KEY = 'fix_price',
  INDIVIDUAL_ADJUSTMENT_KEY = 'individual_adjustment',
  MAX_PRICE_KEY = 'max_price',
  MEDIAN_KEY = 'median_lead_time',
  MIN_PRICE_KEY = 'min_price',
  IS_SKIP_MIN_STAY_RULE = 'is_skip_min_stay_rule',
  MIN_STAY_KEY = 'min_stay',
  OCCUPANCY_KEY = 'occupancy_pricing',
  PELL_WEEKDAY_KEY = 'PELL_weekday',
  PELL_WEEKEND_KEY = 'PELL_weekend',
  PERCENT_KEY = 'percentage_adjustment',
  RPG_ARGUMENTS_KEY = 'rpg_arguments',
  SKIP_DATE_KEY = 'skip_date'
}

export type AdjustmentOptionType = {
  id: ADJUSTMENT;
  value: AdjustmentType;
  label: string;
  userflowId?: string;
};
