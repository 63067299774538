import { useQuery } from '@tanstack/react-query';
import { UtilQueryKeys } from '@common/types/query-keys';
import { getResetPasswordSession } from '@common/api/account';

export const useGetSession = (token: string | undefined) => {
  const query = useQuery({
    queryKey: [UtilQueryKeys.GET_RESET_PASSWORD_SESSION, token],
    queryFn: () => {
      return getResetPasswordSession(token);
    }
  });

  return {
    query
  };
};
