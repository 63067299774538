import { Typography } from '@common/components/foundations/Typography';
import { Page } from '@common/components/organisms/Page';

export const Error404 = () => {
  return (
    <Page
      header={
        <div className="pl-8">
          <Typography color="darkGrey" variant="h1">
            404
          </Typography>
        </div>
      }>
      The page you requested does not exist.
    </Page>
  );
};
