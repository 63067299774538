import { getPriceCacheData } from '@common/api/roomPrices';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';
import { RoomsQueryKeys } from '@common/types/query-keys';

export const useRoomPrices = (isEnabled?: boolean) => {
  const { pricingSettingsQuery } = usePricingSettings(isEnabled);

  const cachePriceQuery = useQuery({
    queryKey: [RoomsQueryKeys.GET_ROOM_PRICES],
    queryFn: getPriceCacheData,
    enabled: !!isEnabled // Ensure boolean value for enabled flag
  });

  const roomPrices = useMemo(() => {
    return cachePriceQuery.data?.data;
  }, [cachePriceQuery.data]);

  const pricingSettings = useMemo(() => {
    return pricingSettingsQuery.data;
  }, [pricingSettingsQuery.data]);

  const isFull = (date: Date) => {
    const roomData = {
      ...roomPrices?.prices?.data?.[dayjs(date).format(API_DATE_FORMAT)]?.property
    };
    const isFull = roomData.occupancy === 100;

    return isFull;
  };

  const isClosedRooms = (date: Date) => {
    const formattedDate = dayjs(date).startOf('day').format(API_DATE_FORMAT);

    if (!roomPrices || !roomPrices.prices.data?.[dayjs(date).format(API_DATE_FORMAT)]) return false;

    const roomData = roomPrices.prices.data[formattedDate].property;
    const isClosed = roomData?.closed_rooms === pricingSettings?.hotel?.number_of_rooms;

    return isClosed;
  };

  const getRoomData = (roomId: number, dateKey: string) => {
    return roomPrices?.prices.data?.[dateKey]?.[roomId];
  };

  const refRoomOnly = !pricingSettings?.hotel.all_room_pricing;
  const referenceRoom = pricingSettings?.rooms.reference;

  return {
    cachePriceQuery,
    pricingSettingsQuery,
    roomPrices,
    pricingSettings,
    refRoomOnly,
    referenceRoom,
    getRoomData,
    isFull,
    isClosedRooms
  };
};
