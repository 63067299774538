import { EditReportData, NewReportData } from '@common/api/dashboard/types';
import { isEmpty } from 'lodash-es';

export const isValidScheduleReportData = (data: NewReportData | EditReportData) => {
  const isValid =
    !isEmpty(data.receipient_user_ids) &&
    !isEmpty(data.accounting_mode) &&
    !isEmpty(data.frequency) &&
    !isEmpty(data.report_type);
  return isValid;
};
