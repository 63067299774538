import { Fragment } from 'react';
import { map } from 'lodash-es';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { SelectRoomDropDown } from '@pages/Client/Calendar/components/BulkEdit/Fragments/SelectRoomDropDown';
import { OccupancyAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/OccupancyAdjustmentForm';

export function OccupancyAdjustmentWrapper() {
  const { displayHotelRooms, selectedHotelRoomId } = useHotelRoomsList();
  return (
    <Fragment>
      <SelectRoomDropDown />
      <div className="mt-4">
        {map(displayHotelRooms, (room, index) =>
          room.id === selectedHotelRoomId ? (
            <OccupancyAdjustmentForm key={room.id} nestIndex={index} roomId={room.id} />
          ) : null
        )}
      </div>
    </Fragment>
  );
}
