import { Badge } from '@common/components/atoms/Badge';

import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Page } from '@common/components/organisms/Page';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDocumentTitle } from '@mantine/hooks';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { Fragment, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useOnDemandSchedule } from '@pages/Admin/Scrape/OnDemandSchedule/hooks/useOnDemandSchedule';
import { ActionIcon, Button } from '@mantine/core';

const schema = z.object({
  page: z.number(),
  status: z.number().optional(),
  search: z.string().optional()
});

type Params = z.infer<typeof schema>;

const SCHEDULE_STATUS = [
  {
    label: 'Done',
    value: 3
  },
  {
    label: 'In Progress',
    value: 2
  },
  {
    label: 'Scheduled',
    value: 1
  }
];

export const OnDemandSchedule = () => {
  useDocumentTitle('On Demand Schedule');
  const defaultValues = {
    page: 1,
    search: undefined,
    status: undefined
  };
  const { watch, reset, control, getValues, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues<Params>({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, filterEmptyValues, defaultValues]
  );

  const { onDemandScheduleLists, isLoading } = useOnDemandSchedule(
    filterEmptyValues<Params>({
      page: watch('page'),
      search: getValues('search'),
      status: watch('status')
    })
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const ROWS_PER_PAGE = 20;

  const startIndex = onDemandScheduleLists?.current
    ? (onDemandScheduleLists.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    onDemandScheduleLists?.current && onDemandScheduleLists.results
      ? startIndex + onDemandScheduleLists.results.length - 1
      : 0;

  const handleNext = () => {
    if (!onDemandScheduleLists?.next) return;
    const params = new URLSearchParams(onDemandScheduleLists.next.split('?')[1]);
    const page = params.get('page');
    const search = params.get('search');
    const status = params.get('status');

    if (!page) return;

    reset(
      filterEmptyValues<Params>({
        page: parseInt(page),
        search: search ? search : undefined,
        status: status ? parseInt(status) : undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues<Params>({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  const columns: any[] = [
    {
      header: 'Hotel Id',
      accessorKey: 'hotel_list',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Name',
      accessorKey: 'hotel_name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Scraper Version',
      accessorKey: 'scraper_version',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Record Scraped',
      accessorKey: 'record_scraped',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Client Hotel Id',
      accessorKey: 'client_hotel_id',
      cell: (row: any) => {
        const formattedIds = row?.getValue()?.replace(/((?:[^,]+,){2}[^,]+),/g, '$1<br />');
        return (
          <div className="flex max-w-[300px] items-center gap-3">
            <span dangerouslySetInnerHTML={{ __html: formattedIds }} />
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Start Date',
      accessorKey: 'start_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'End Date',
      accessorKey: 'end_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: (row: any) => {
        const statusMapping: Record<number, string> = {
          1: 'Scheduled',
          2: 'In Progress',
          3: 'Done'
        };
        const statusBgMapping: Record<number, string> = {
          1: 'darkGrey',
          2: 'orange',
          3: 'darkGreen'
        };
        return (
          <div className="flex items-center gap-3">
            <Badge className="text-center" background={statusBgMapping[row?.getValue() as number]}>
              {statusMapping[row?.getValue() as number]}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <Page header={<Header title="On Demand Schedule" />}>
      <Table
        isHover
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading}
        data={!isLoading ? (onDemandScheduleLists?.results as any) : []}
        next={handleNext}
        isNext={!!onDemandScheduleLists?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={onDemandScheduleLists?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        headerComponent={<Fragment />}
        actionComponents={
          <div className="flex items-center justify-center gap-5">
            <div>
              <Controller
                name="search"
                control={control}
                render={({ field: { value, name, onChange } }) => (
                  <Input
                    placeholder="Search"
                    background="grey"
                    name={name}
                    id={name}
                    type="text"
                    value={value}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                      onChange(e);
                    }}
                    onClear={() => {
                      reset(defaultValues);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <RPGPopover>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <RPGPopover.Button as={ActionIcon}>
                        <Icon.FilterList />
                      </RPGPopover.Button>
                    </TooltipTrigger>
                    <TooltipContent side="top" className="bg-white text-darkGrey">
                      Filter
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <RPGPopover.Panel>
                  <div className="mb-2 flex items-center gap-3">
                    <Typography className="text-meta-1-medium" color="darkGrey">
                      Filters
                    </Typography>
                    <Button size="compact-xs" onClick={() => reset(defaultValues)}>
                      Reset
                    </Button>
                  </div>
                  <div className="grid grid-cols-1 gap-2">
                    <div>
                      <Controller
                        control={control}
                        name="status"
                        render={({ field: { value, name, onChange } }) => (
                          <SelectDropdown
                            fullWidth
                            hint="Schedule Status"
                            placeholder="Select Schedule Status"
                            name={name}
                            key={name}
                            value={value || ''}
                            options={SCHEDULE_STATUS}
                            onChange={(value) => {
                              onChange(value);
                              onResetAndSet('status', value);
                              setValue('page', 1);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </RPGPopover.Panel>
              </RPGPopover>
            </div>
          </div>
        }
      />
    </Page>
  );
};
