import { setAuthToken } from '@common/api/axiosInstance';
import { ssoRegisterProperties } from '@common/api/hotel';
import { useAuthStore, useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useGetSSOToken } from '@pages/Auth/SSO/store/ssoToken';
import { ErrorResponse } from '@pages/Auth/SSO/types/error';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRegisterProperties = () => {
  const { addNotification } = useNotificationsStore();
  const navigate = useNavigate();
  const { setHotelAuthToken } = useHotelStore();
  const { hotel, setHotel, setAuth, token } = useAuthStore();
  const { ssoToken } = useGetSSOToken();

  useEffect(() => {
    if (hotel && token) {
      navigate(`/client/calendar/${hotel}/pricing`);
    }
  }, [hotel, token, navigate]);

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (propertiesCode: string) => {
      setAuthToken(ssoToken, true);
      return ssoRegisterProperties(propertiesCode);
    },
    onSuccess: (response) => {
      const { data } = response;
      const token = data?.data?.token;
      const id = data?.data?.id;
      const user = data?.data?.user ?? null;
      if (token) {
        setAuthToken(token);
        setAuth(token, user);
        setHotelAuthToken(token);
      }
      if (id) {
        setHotel(id);
      }
      addNotification('success', 'Registered properties Successfully');
    },
    onError: (error: AxiosError) => {
      let errorMessage = 'Failed to register properties';
      const responseError = error.response?.data as ErrorResponse;
      if (responseError && responseError.errors) {
        errorMessage = responseError.errors[0]?.detail ?? errorMessage;
      }
      addNotification('fail', errorMessage);
    }
  });

  return {
    registerProperties: mutate,
    isRegisterPropertiesLoading: isLoading,
    isSuccess
  };
};
