/**
 * Validates if the input is a numeric value.
 * Accepts numbers and numeric strings containing only digits (0-9).
 * Rejects undefined, null, non-numeric strings, or numbers with decimals, commas, or special characters.
 *
 * @param input - The value to validate (can be string, number, or unknown).
 * @returns true if the input is numeric, otherwise false.
 */
export const validateNumeric = (input: unknown): boolean => {
  if (typeof input !== 'string' && typeof input !== 'number') return false;
  return /^[0-9]+$/.test(input.toString());
};
