import { Dayjs } from 'dayjs';

export const calculateLeadTime = (today: Dayjs, viewingDate?: Dayjs | null): number | null => {
  if (!viewingDate) return null;

  const viewingEndOfMonth = viewingDate.endOf('month');

  if (viewingEndOfMonth.isBefore(today, 'day')) return null;

  let leadTime = today.endOf('month').date() - today.date();

  while (!today.isSame(viewingEndOfMonth, 'month')) {
    today = today.add(1, 'month');
    leadTime += today.daysInMonth();
  }
  return -leadTime;
};
