export const UserSwitch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00091 4.49599C6.00091 3.11749 7.12058 2 8.50175 2C9.88293 2 11.0026 3.11749 11.0026 4.49599C11.0026 5.87448 9.88293 6.99197 8.50175 6.99197C7.83849 6.99197 7.20239 6.729 6.73339 6.26091C6.26439 5.79283 6.00091 5.15796 6.00091 4.49599ZM13.8936 12.5331L12.8932 10.6361C12.3913 9.64156 11.378 9.00705 10.2623 8.98876H6.74116C5.6075 8.98841 4.57041 9.62569 4.06026 10.6361L3.10994 12.5331C2.95392 12.8421 2.96935 13.2097 3.15072 13.5046C3.33209 13.7995 3.65353 13.9797 4.00024 13.9807H13.0033C13.35 13.9797 13.6714 13.7995 13.8528 13.5046C14.0342 13.2097 14.0496 12.8421 13.8936 12.5331ZM18.0049 4.49599C18.0049 5.87448 16.8853 6.99197 15.5041 6.99197C14.84 6.99464 14.2024 6.73253 13.7328 6.26386C13.2632 5.79519 13.0006 5.15878 13.0033 4.49599C13.0033 3.11749 14.1229 2 15.5041 2C16.8853 2 18.0049 3.11749 18.0049 4.49599ZM20.8959 12.5331L19.9456 10.6361C19.4355 9.62569 18.3984 8.98841 17.2647 8.98876H14.2637C14.4467 9.22644 14.6075 9.48043 14.7439 9.74754L15.6942 11.6345C16.0559 12.3663 16.1029 13.2136 15.8242 13.9807H20.0056C20.3523 13.9797 20.6738 13.7995 20.8551 13.5046C21.0365 13.2097 21.0519 12.8421 20.8959 12.5331ZM13.6135 15.9775H17.5048C17.6478 15.9636 17.7901 16.0094 17.8981 16.104C18.0061 16.1985 18.0701 16.3334 18.075 16.4767V20.3605C18.0825 20.5651 17.9615 20.7528 17.7718 20.8307C17.582 20.9087 17.3637 20.8605 17.2247 20.7099L16.2243 19.7115C14.4725 21.6353 11.7955 22.4297 9.2751 21.7735C6.75471 21.1174 4.8075 19.1193 4.22031 16.5865C4.19252 16.4389 4.22889 16.2865 4.32035 16.1672C4.41373 16.0468 4.55795 15.9767 4.71048 15.9775H5.71081C5.92249 15.9718 6.11482 16.0998 6.19098 16.297C6.68219 18.05 8.09045 19.3971 9.86606 19.8126C11.6417 20.2281 13.5031 19.6461 14.7238 18.2938L13.2634 16.8262C13.1232 16.6835 13.0818 16.4713 13.158 16.2866C13.2342 16.1019 13.4134 15.9803 13.6135 15.9775Z"
      />
    </svg>
  );
};
