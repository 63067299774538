import { ScheduleHistoryProgram } from '@common/api/amalgamation/types';

import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useDocumentTitle } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';
import { ScheduleHistoryDetailsModal } from '@pages/Admin/Scrape/ScheduleHistory/components/ScheduleHistoryDetailsModal';
import { useScheduleHistory } from '@pages/Admin/Scrape/ScheduleHistory/hooks/useScheduleHistory';
import { Button } from '@mantine/core';

export const ScheduleHistory = () => {
  useDocumentTitle('Schedule History');
  const [pageParams, setPageParams] = React.useState<number>(1);
  const [scheduleId, setScheduleId] = React.useState<string | null>(null);
  const { isOpen: detailOpen, openModal: detailClick, closeModal: detailClose } = useModal();

  const { scheduleHistoryList, isLoading } = useScheduleHistory(pageParams);

  const columns: any[] = [
    {
      header: 'Name',
      accessorKey: 'program',
      cell: (row: any) => {
        const program = row?.getValue() as ScheduleHistoryProgram;
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {program?.name}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Start Date',
      accessorKey: 'program_start_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'End Date',
      accessorKey: 'program_end_time',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Number of Task',
      accessorKey: 'total',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as number}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'In Queue',
      accessorKey: 'registered',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as number}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Task in Progress',
      accessorKey: 'in_progress',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as number}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Completed Task',
      accessorKey: 'completed',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as number}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Unique Id',
      accessorKey: 'program_unique_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Button
              variant="subtle"
              onClick={() => {
                setScheduleId(row?.getValue() as string);
                detailClick();
              }}>
              {row?.getValue() as string}
            </Button>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const ROWS_PER_PAGE = 20;

  const startIndex = scheduleHistoryList?.current
    ? (scheduleHistoryList.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    scheduleHistoryList?.current && scheduleHistoryList.results
      ? startIndex + scheduleHistoryList.results.length - 1
      : 0;

  const handleNext = () => {
    if (!scheduleHistoryList?.next) return;
    const params = new URLSearchParams(scheduleHistoryList.next.split('?')[1]);
    const page = params.get('page');

    if (!page) return;
    setPageParams(Number(page));
  };

  const handlePrevious = () => {
    if (scheduleHistoryList?.current) {
      setPageParams(scheduleHistoryList.current - 1);
    }
  };
  return (
    <Page header={<Header title="Schedule History" />}>
      <Table
        isHover
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading}
        data={!isLoading ? (scheduleHistoryList?.results as any) : []}
        next={handleNext}
        isNext={!!scheduleHistoryList?.next}
        previous={handlePrevious}
        isPrevious={pageParams > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={scheduleHistoryList?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
      />
      {scheduleId ? (
        <ScheduleHistoryDetailsModal
          scheduleId={scheduleId}
          isOpen={detailOpen}
          onClose={() => {
            detailClose();
            setScheduleId(null);
          }}
        />
      ) : null}
    </Page>
  );
};
