import { create } from 'zustand';
interface HasMetaDataStore {
  isHasMetadata: boolean;
  setHasMetadata: (metadata: boolean) => void;
  isAllLogs: boolean;
  setIsAllLogs: (logs: boolean) => void;
}

export const useGetHasMetadataStore = create<HasMetaDataStore>((set) => ({
  isHasMetadata: false,
  setHasMetadata: (metadata: boolean) => set({ isHasMetadata: metadata }),
  isAllLogs: false,
  setIsAllLogs: (logs: boolean) => set({ isAllLogs: logs })
}));
