export const formatNumber = (value: number | null | undefined): string => {
  if (value == null) {
    return '0';
  }

  if (isNaN(value)) {
    return '0';
  }

  if (!isFinite(value)) {
    return '∞';
  }

  return value.toFixed(0);
};

export const formatLocaleNumber = (number: number, locale = 'en-US') => {
  return new Intl.NumberFormat(locale).format(number);
};
