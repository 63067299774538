import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Table } from '@common/components/molecules/Table';
import { pmsHasRoomTypesColumn, pmsHasUpdateRate } from '@common/constants/pmsList';
import { useModal } from '@common/hooks/useModal';
import { cn } from '@common/utils/cn';
import { AddNewRoomRateModal } from '@pages/Client/Account/Integrations/components/AddNewRoomRateModal';
import { DeleteRoomRatesModal } from '@pages/Client/Account/Integrations/components/DeleteRoomRatesModal';
import { UpdateRoomRateModal } from '@pages/Client/Account/Integrations/components/UpdateRoomRateModal';
import { usePmsRoomRate } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreateRoomRatesStore } from '@pages/Client/Account/Integrations/store/createRoomRate';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectedRoomRate {
  id: number | string;
  rate_id: string;
  name: string;
  pms_room: null;
  description: null | string;
  checkin: string;
  checkout: string;
  room_rate: any[];
  other_rate: null;
  extra: string | null;
}

export const RoomRatesTable = () => {
  const { hotelDetails } = useHotelDetails();
  const { t } = useTranslation();
  const { pmsRoomRateList, isLoading } = usePmsRoomRate();
  const { isCreateLoading } = useCreateRoomRatesStore();
  const { pmsRoomTypeList } = usePmsRoomType();

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const [selectedRoomRate, setSelectedRoomRate] = useState<SelectedRoomRate>({
    id: '',
    name: '',
    rate_id: '',
    pms_room: null,
    description: null,
    checkin: '',
    checkout: '',
    room_rate: [],
    other_rate: null,
    extra: null
  });

  const roomApartmentSpaceName = hotelDetails?.room_apartment_space_name;
  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const getRoomTypeTitle = (
    pmsProviderId: number,
    roomApartmentSpaceName: string | undefined
  ): string => {
    const providerTitles: Record<number, string> = {
      15: t(`Protel Rates`),
      41: t(`RoomRaccoon Rates`),
      45: t(`Rates Plans`),
      78: t(`Rate Plans`),
      22: t(`Guestline Types and Flexcodes`),
      34: t(`Rate Types`),
      85: t(`Rate Plans`),
      77: t(`RoomMaster Anywhere Rates`)
    };
    return providerTitles[pmsProviderId] || t(`${roomApartmentSpaceName} Rates`);
  };
  const getPlanCodeColumnName = (pmsProviderId: number): string => {
    const providerTitles: Record<number, string> = {
      22: t(`Rate Plan Code`),
      34: t(`Rate Plan Code`),
      69: t(`Rate Plan Code`),
      15: t(`Rate Plan Code`),
      45: t(`Rate Plan Code`),
      77: t(`Rate Plan Code`)
    };
    return providerTitles[pmsProviderId] || t(`Rate Flex Code`);
  };

  const providerTitles = getRoomTypeTitle(pmsProviderId, roomApartmentSpaceName);
  const planCodeColumnName = getPlanCodeColumnName(pmsProviderId);
  const planNameColumnName =
    pmsProviderId === 34 || pmsProviderId === 45 ? t('Rate Plan Name') : t('Rate Plan');

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const deleteColumn = {
    header: 'Actions',
    accessorKey: 'id',
    cell: (row: any) => {
      return (
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-3">
            <Button
              icon
              onClick={() => {
                setSelectedRoomRate(row.row.original);
                deleteClick();
              }}>
              <Icon.Delete className="text-copyTextGrey" />
            </Button>
          </div>
        </div>
      );
    },
    meta: {
      showOnMobile: true
    }
  };

  const siteMinderNewColumn = [
    {
      header: 'Rate Plan',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Rate Plan Code',
      accessorKey: 'extra',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const guestLineAdditionalColumns = [
    {
      header: `Pms ${roomApartmentSpaceName} Code`,
      accessorKey: 'room_rates',
      cell: (row: any) => {
        const pmsRooms = row?.row?.original?.room_rate.map((room: any) => room.pms_room_id);
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {pmsRoomTypeList
                ?.filter((item: any) => pmsRooms.includes(item.id))
                .map((item: any) => item.name)
                .join(', ')}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
        // cellAlignment: 'center'
      }
    },
    {
      header: t('Rate Flex Table'),
      accessorKey: 'other_rate',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) || '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
        // cellAlignment: 'center'
      }
    }
  ];

  const dEdgeAdditionalColumns = [
    {
      header: `${roomApartmentSpaceName} Types`,
      accessorKey: 'room_rates',
      cell: (row: any) => {
        const pmsRooms = row?.row?.original?.room_rate.map((room: any) => room.pms_room_id);
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {pmsRoomTypeList
                ?.filter((item: any) => pmsRooms.includes(item.id))
                .map((item: any) => item.name)
                .join(', ')}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
        // cellAlignment: 'center'
      }
    }
  ];

  const roomTypesAdditionalColumn = [
    {
      header: `${roomApartmentSpaceName} Types`,
      accessorKey: 'room_rate',
      cell: (row: any) => {
        const pmsRooms = row?.row?.original?.room_rate.map((room: any) => room.pms_room_id);
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {pmsRoomTypeList
                ?.filter((item: any) => pmsRooms.includes(item.id))
                .map((item: any) => item.name)
                .join(', ')}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
        // cellAlignment: 'center'
      }
    }
  ];

  const columns = [
    {
      header: `${planCodeColumnName}`,
      accessorKey: 'rate_id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
        // cellAlignment: 'center'
      }
    },
    ...(pmsProviderId === 22 ? guestLineAdditionalColumns : []),
    {
      header: `${planNameColumnName}`,
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    ...(pmsHasRoomTypesColumn.includes(pmsProviderId) ? roomTypesAdditionalColumn : []),
    ...(pmsProviderId === 45 ? dEdgeAdditionalColumns : []),
    {
      header: 'Description',
      accessorKey: 'description',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) || '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    ...(pmsProviderId === 64 ? [] : [deleteColumn])
  ];

  const tableColumn = pmsProviderId === 64 ? siteMinderNewColumn : columns;

  return (
    <div className={cn('my-8', pmsProviderId === 80 ? 'max-w-4xl' : '')}>
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          if (pmsHasUpdateRate.includes(pmsProviderId)) {
            setSelectedRoomRate({
              ...row?.row.original,
              room_types: row?.row?.original?.room_rate.map((item: any) => item.pms_room_id)
            });
          } else {
            setSelectedRoomRate(row?.row.original);
          }
          editClick();
        }}
        headerComponent={
          <div className="my-3 flex items-center justify-between">
            <Typography variant="h6" element="h6" color="darkGrey">
              {providerTitles}
            </Typography>
          </div>
        }
        actionComponents={
          ![64].includes(pmsProviderId) ? (
            <Button isLoading={isCreateLoading} onClick={() => addClick()}>
              {pmsProviderId === 15 ? t('Add new Protel Rate') : t('Add new Rate')}
            </Button>
          ) : null
        }
        skeletonCount={10}
        columns={tableColumn}
        data={pmsRoomRateList ? pmsRoomRateList : []}
        isHover
        isRowClickable
      />
      <AddNewRoomRateModal isOpen={addOpen} onClose={addClose} />
      <UpdateRoomRateModal
        selectedRoomRate={selectedRoomRate}
        isOpen={editOpen}
        onClose={editClose}
      />
      <DeleteRoomRatesModal
        selectedRoomRate={selectedRoomRate}
        isOpen={deleteOpen}
        onClose={deleteClose}
      />
    </div>
  );
};
