export const AllDone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1499 5.83036L22.8499 6.54036C22.9445 6.63424 22.9978 6.76204 22.9978 6.89536C22.9978 7.02868 22.9445 7.15648 22.8499 7.25036L12.2499 17.8504C12.156 17.945 12.0282 17.9983 11.8949 17.9983C11.7615 17.9983 11.6337 17.945 11.5399 17.8504L6.14985 12.4604C6.0552 12.3665 6.00195 12.2387 6.00195 12.1054C6.00195 11.972 6.0552 11.8442 6.14985 11.7504L6.84985 11.0504C6.94374 10.9557 7.07153 10.9025 7.20485 10.9025C7.33817 10.9025 7.46597 10.9557 7.55985 11.0504L9.39985 12.9304L16.3999 5.88036C16.5977 5.68858 16.912 5.68858 17.1099 5.88036L17.8099 6.59036C17.9045 6.68424 17.9578 6.81204 17.9578 6.94536C17.9578 7.07868 17.9045 7.20648 17.8099 7.30036L10.8099 14.3004L11.8899 15.3804L21.4399 5.83036C21.6377 5.63858 21.952 5.63858 22.1499 5.83036ZM8.30985 16.7904L2.55985 11.0504C2.46597 10.9557 2.33817 10.9025 2.20485 10.9025C2.07153 10.9025 1.94374 10.9557 1.84985 11.0504L1.14985 11.7504C1.0552 11.8442 1.00195 11.972 1.00195 12.1054C1.00195 12.2387 1.0552 12.3665 1.14985 12.4604L6.53985 17.8504C6.63374 17.945 6.76153 17.9983 6.89485 17.9983C7.02817 17.9983 7.15597 17.945 7.24985 17.8504L8.30985 16.7904Z"
      />
    </svg>
  );
};
