export type RoleKey = 1 | 2 | 3;
export type RoleKeyString = '1' | '2' | '3';

export function getRole(num: RoleKey) {
  const roles = {
    1: 'Manager',
    2: 'Staff',
    3: 'Admin'
  };
  return roles[num] ?? 'Unknown';
}

export function getRoleString(role: string): RoleKeyString {
  const reverseRoles: Record<string, RoleKeyString> = {
    Manager: '1',
    Staff: '2',
    Admin: '3'
  };
  return reverseRoles[role];
}
