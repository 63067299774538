import { z } from 'zod';

export const schema = z
  .object({
    tag_name: z.string().min(1, 'Tag name cannot be empty.'),
    color: z.string(),
    min_adjustment: z
      .union([
        z.number().int(),
        z
          .string()
          .nonempty({ message: 'Please enter value' })
          .min(1, 'Please enter a value')
          .transform((value) => Number(value))
      ])
      .refine((value) => value <= 0 && value >= -25, {
        message: 'Min adjustment must be between -25 and 0'
      }),
    max_adjustment: z
      .union([
        z.number().int(),
        z
          .string()
          .nonempty({ message: 'Please enter value' })
          .min(1, 'Please enter a value')
          .transform((value) => Number(value))
      ])
      .refine((value) => value >= 0 && value <= 25, {
        message: 'Max adjustment must be between 0 and 25'
      }),
    room_types: z
      .array(z.union([z.string().min(1, 'Please enter a value'), z.number()]))
      .min(1, 'At least one room type is required.'),
    speed: z.number()
  })
  .superRefine((data, ctx) => {
    if (data.max_adjustment < data.min_adjustment) {
      ctx.addIssue({
        path: ['max_adjustment'],
        message: 'Max adjustment cannot be lower than Min adjustment.',
        code: z.ZodIssueCode.custom
      });
    }
  });
