import { create } from 'zustand';
interface LoadingStore {
  isScoreCompetitorLoading: boolean;
  setLoading: (newLoading: boolean) => void;
}

export const useScoreCompetitorLoading = create<LoadingStore>((set) => ({
  isScoreCompetitorLoading: false,
  setLoading: (newLoading: boolean) => set({ isScoreCompetitorLoading: newLoading })
}));
