import { getHotelClientMap, getHotelClientMapDetail } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

type Params = {
  min_lat: number;
  max_lat: number;
  min_lng: number;
  max_lng: number;
  limit?: number;
  pms?: string;
  country?: string;
  subscription_state?: string;
  subscription_plan?: string;
};

export const useHotelClientMap = ({
  min_lat,
  max_lat,
  min_lng,
  max_lng,
  limit,
  pms,
  country,
  subscription_state,
  subscription_plan
}: Params) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery({
    queryKey: [
      HotelQueryKeys.GET_HOTEL_CLIENT_MAP,
      min_lat,
      max_lat,
      min_lng,
      max_lng,
      limit,
      pms,
      country,
      subscription_state,
      subscription_plan
    ],
    queryFn: () =>
      getHotelClientMap(
        min_lat,
        max_lat,
        min_lng,
        max_lng,
        limit,
        pms,
        country,
        subscription_state,
        subscription_plan
      )
  });

  useEffect(() => {
    if (query.error) {
      addNotification('fail', 'Failed to fetch client map data');
    }
  }, [query.error]);

  const hotelClientMapData = useMemo(() => {
    return query.data;
  }, [query.data]);

  return { hotelClientMapData, query };
};

export const useHotelClientMapDetail = (hotelId: number | undefined) => {
  const query = useQuery({
    queryKey: [HotelQueryKeys.HOTEL_CLIENT_MAP_DETAIL, hotelId],
    queryFn: () => getHotelClientMapDetail(hotelId!),
    enabled: !!hotelId
  });

  const hotelClientMapDetailData = useMemo(() => {
    return query.data;
  }, [query.data]);

  return { hotelClientMapDetailData, query };
};
