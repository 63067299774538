export const HelpOutlined = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM11.5 16C11.2239 16 11 16.2239 11 16.5V17.5C11 17.7761 11.2239 18 11.5 18H12.5C12.7761 18 13 17.7761 13 17.5V16.5C13 16.2239 12.7761 16 12.5 16H11.5ZM13 6.12C14.5906 6.12 15.88 7.40942 15.88 9L15.95 9.56C15.9416 10.806 15.1355 11.9063 13.95 12.29L13 12.6C12.9358 12.6048 12.8848 12.6558 12.88 12.72V13.5C12.88 13.7761 12.6561 14 12.38 14H11.62C11.3439 14 11.12 13.7761 11.12 13.5V12.72C11.1222 11.9109 11.6418 11.1939 12.41 10.94L13.36 10.63C13.8157 10.4721 14.121 10.0423 14.12 9.56V9C14.12 8.38144 13.6186 7.88 13 7.88H11C10.703 7.88 10.4181 7.998 10.208 8.20804C9.998 8.41808 9.88 8.70296 9.88 9V9.5C9.88 9.77614 9.65614 10 9.38 10H8.62C8.34386 10 8.12 9.77614 8.12 9.5V9C8.12 7.40942 9.40942 6.12 11 6.12H13Z"
      />
    </svg>
  );
};
