import { API_DATE_FORMAT, API_DATE_FORMAT_FULL } from '@common/constants/date';
import { savePricingSettings, applyIndividualAdjustment } from '@common/api/hotel';
import { uploadPrice } from '@common/api/pricingAlgorithm';
import { UploadPriceRequest } from '@common/api/pricingAlgorithm/types';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useMutation } from '@tanstack/react-query';
import { useNotificationsStore } from '@common/store/notifications';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useTranslation } from 'react-i18next';
import { useUploadPMSPriceModalState } from '@pages/Client/Calendar/components/BulkEdit/store/useUploadPMSPriceModalState';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '@common/components/molecules/Modal';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import UploadPriceConfirmationModal from '@pages/Client/Calendar/components/UploadPriceConfirmationModal';
import { isNil } from 'lodash-es';
import { PricingQueryKeys } from '@common/types/query-keys';

export function UploadPricesModal({ viewingDate }: { viewingDate: Dayjs }): JSX.Element {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { setDrawerState, setAdjustmentsData } = usePriceDrawerStore();
  const { addNotification } = useNotificationsStore();
  const { selectedHotelRoomId } = useHotelRoomsList();
  const { cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { modalState, setModalState, isInterMediate, toggleIsInterMediate, intermediateFunction } =
    useUploadPMSPriceModalState();
  const { mutateAsync: savePricingSettingsMutate, isPending: isUploadingPrice } = useMutation({
    mutationFn: savePricingSettings,
    mutationKey: [PricingQueryKeys.UPDATE_PRICING_SETTINGS]
  });
  const { mutateAsync: applyIndividualAdjustmentMutate, isPending: isApplying } = useMutation({
    mutationFn: applyIndividualAdjustment
  });
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const currentDate = dayjs(viewingDate).format(API_DATE_FORMAT);

  const updatePricePms = async () => {
    const clientTimeZone = hotelDetails?.timezone;
    const clientTime = dayjs().tz(hotelDetails?.timezone).format(API_DATE_FORMAT_FULL);
    let requestData: UploadPriceRequest = {} as UploadPriceRequest;
    if (!clientTimeZone) return;

    if (currentDate) {
      requestData = {
        specific_date: dayjs(currentDate).format(API_DATE_FORMAT),
        start_date: '',
        end_date: '',
        command: 'upload_price_to_pms',
        disable_threshold: true,
        client_time: clientTime,
        client_timezone: clientTimeZone
      };
    }

    await uploadPrice(requestData);
    await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
    addNotification(
      'success',
      `Sending Prices. Waiting for the ${
        hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
      } to confirm.`
    );
  };

  const submitUploadingPrice = async () => {
    try {
      const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
      if (!latestPricingSettings || !selectedHotelRoomId) return;
      await savePricingSettingsMutate(JSON.stringify(latestPricingSettings));
      const applyRequestParam = {
        json_settings: JSON.stringify(latestPricingSettings),
        is_write_to_cache: true,
        start_date: currentDate,
        end_date: currentDate
      };

      const { data } = await applyIndividualAdjustmentMutate(applyRequestParam);

      if (data) {
        if (hotelDetails && hotelDetails.pms_sync === 2 && !isNil(hotelDetails.pms_provider)) {
          await updatePricePms();
        }
      }
    } catch (e) {
      console.error(e);
      addNotification('fail', 'Prices update Failed');
    }
  };

  async function handleSubmitModal() {
    console.log('handleSubmitModal', isInterMediate, intermediateFunction);

    if (!isInterMediate) await submitUploadingPrice();

    if (
      isInterMediate &&
      hotelDetails &&
      hotelDetails.pms_sync === 2 &&
      !isNil(hotelDetails.pms_provider)
    ) {
      await intermediateFunction();
      await updatePricePms();
    }
    setModalState(false);
    setDrawerState(false);
    toggleIsInterMediate(false);
    setAdjustmentsData({});
  }

  function handleDismissModal() {
    setModalState(false);
  }

  return (
    <Modal
      isLoading={
        isUploadingPrice ||
        isApplying ||
        cachePriceQuery.isRefetching ||
        pricingSettingsQuery.isRefetching
      }
      onClose={handleDismissModal}
      size="xl"
      open={modalState}
      okText={`${t(`Confirm & Upload To ${channelManagerOrPms}`)}`}
      onClick={handleSubmitModal}
      disableCloseOnOk
      className="z-[9999999]"
    >
      <UploadPriceConfirmationModal currentViewingDate={viewingDate} />
    </Modal>
  );
}
