import { deleteHotel } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useDeleteProperty = () => {
  const { addNotification } = useNotificationsStore();
  const navigate = useNavigate();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return deleteHotel();
    },
    onSuccess: () => {
      addNotification('success', 'Successfully delete property');
      navigate('/admin/clients');
    },
    onError: () => {
      addNotification('fail', 'Failed to delete property');
    }
  });

  return {
    deleteHotel: mutate,
    isLoading
  };
};
