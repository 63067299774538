import { useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Day, useLilius } from 'use-lilius';

import { Page } from '@common/components/organisms/Page';
import { PusherEventNames, PusherEventResponse } from '@common/constants/pusher';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { CalendarHeader } from '@pages/Client/Calendar/components/CalendarHeader';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useHotelCompetitor } from '@pages/Client/Calendar/hooks/useHotelCompetitor';
import { usePusherStore } from '@common/store/pusher';
import {
  RunPricingProvider,
  useRunPricingContext
} from '@pages/Client/Calendar/hooks/useRunPricing';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';
import { runPricingAndFetchInventoryStatus } from '@common/api/pricingAlgorithm';
import { useRouteHash } from '@common/hooks/useRouteHash';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';

const CalendarLayoutComponent = () => {
  const {
    hotelDetails,
    query: { isLoading: isHotelDetailsLoading }
  } = useHotelDetails();
  const {
    hotelRooms,
    selectedHotelRoom,
    setSelectedHotelRoomId,
    query: { isLoading: isRoomListLoading }
  } = useHotelRoomsList();
  const {
    query: { isLoading: isHotelCompetitorLoading }
  } = useHotelCompetitor();
  const { setLiliusInstance, liliusInstance: storeLiliusInstance } = useCalendarPageStore();
  const liliusInstance = useLilius({
    weekStartsOn: hotelDetails?.starts_monday ? Day.MONDAY : Day.SUNDAY,
    viewing: storeLiliusInstance?.viewing
  });
  const { channel, channelBind, channelUnbind } = usePusherStore();
  const { addNotification } = useNotificationsStore();
  const { pricingSettings, cachePriceQuery, pricingSettingsQuery } = useRoomPrices();
  const { hasWebHookMessage } = usePmsProvider();
  const { runPricing } = useRunPricingContext()!;
  const { hasGetLatestData } = useGetDetailProviderConfig();

  useRouteHash('compare');

  const initCalendarPagePusher = useCallback(() => {
    channelBind(PusherEventNames.WebhookMessage, async (data: any) => {
      if (data.success) {
        const message = 'Prices Uploaded Successfully';
        addNotification('success', message);
        await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
      }
      if (!data.success) {
        const message = 'Prices update Failed';
        addNotification('fail', message);
      }
    });

    channelBind<PusherEventResponse['UploadPriceMessage']>(
      PusherEventNames.UploadPriceMessage,
      async (data) => {
        if (!data.error) {
          const message = hasWebHookMessage()
            ? 'Waiting for response'
            : 'Prices Uploaded Successfully';
          addNotification('success', message);
          await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
        } else {
          const message = data.message ?? 'Prices update Failed';
          addNotification(
            'fail',
            typeof message === 'string'
              ? message
              : message && typeof message.detail === 'string'
                ? message.detail
                : 'Something went Wrong!'
          );
        }
      }
    );

    channelBind(PusherEventNames.InventoryMessage, (data: any) => {
      if (data.error) {
        const message =
          data.message ??
          `Something went Wrong While Fetching Values from ${
            hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
          }`;
        addNotification('fail', message);
      }
    });
  }, [channel]);

  // Listen to Pusher events
  useEffect(() => {
    channel && initCalendarPagePusher();

    return () => {
      channelUnbind(PusherEventNames.WebhookMessage);
      channelUnbind(PusherEventNames.UploadPriceMessage);
      channelUnbind(PusherEventNames.InventoryMessage);
    };
  }, [channel]);

  // Run Pricing on load if it's required in API data
  useEffect(() => {
    if (!pricingSettings || !hotelDetails) return;

    (async () => {
      const { fetch_inventory, run_pricing } = await runPricingAndFetchInventoryStatus();

      if (!run_pricing) return;

      const startDate = dayjs().utc().tz().format(API_DATE_FORMAT);
      const endDate = dayjs()
        .utc()
        .tz()
        .add(pricingSettings.features?.includes(23) ? 545 : 365, 'days')
        .format(API_DATE_FORMAT);

      if (hasGetLatestData && fetch_inventory) {
        runPricing({
          getLatestPMS: true,
          startDate,
          endDate
        });
      } else {
        runPricing({
          getLatestPMS: false,
          startDate,
          endDate,
          pricingType: hotelDetails.last_run_pricing_type
        });
      }
    })();
  }, [hotelDetails]);

  /**
   * Sync lilius instance with Zustand store.
   */
  useEffect(() => {
    setLiliusInstance(liliusInstance);
  }, [liliusInstance.viewing]);

  // Set default selected hotel room effect
  useEffect(() => {
    if (!selectedHotelRoom && hotelRooms) {
      if (import.meta.env.DEV) {
        console.log('Setting default selected hotel room...');
      }

      const refRoom = hotelRooms.find((room) => room.is_reference_room);
      setSelectedHotelRoomId(refRoom?.id);
    }
  }, [hotelRooms]);

  const isFetchingData = isHotelDetailsLoading || isRoomListLoading || isHotelCompetitorLoading;
  if (isFetchingData) {
    return null;
  }

  return (
    <Page fullWidth header={<CalendarHeader />}>
      <Outlet />
    </Page>
  );
};

export const CalendarLayout = () => {
  const { hotelDetails } = useHotelDetails();
  return (
    <RunPricingProvider>
      <CalendarLayoutComponent key={hotelDetails?.starts_monday.toString()} />
    </RunPricingProvider>
  );
};
