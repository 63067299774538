export function formatNumberScore(score: number | null | undefined): string {
  if (score === null || score === undefined) {
    return '-';
  } else if (isNaN(score)) {
    return '0';
  } else if (score === 0) {
    return '0';
  } else {
    return score.toFixed(2);
  }
}
