import { Icon } from '@common/components/foundations/icons';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import {
  Skeleton,
  Flex,
  Stack,
  SegmentedControl,
  Checkbox,
  Radio,
  Group,
  Text,
  rem,
  TextInput,
  Card,
  SimpleGrid,
  Title
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { SurgeProtectionDecayChart } from '@pages/Client/PricingStrategy/SurgeProtection/components/SurgeProtectionDecayChart';
import { SurgeSettingsText } from '@pages/Client/PricingStrategy/SurgeProtection/components/SurgeSettingsText';
import { useFormSchema } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useFormSchema';
import {
  Tabs,
  useSurgeProtectionStore
} from '@pages/Client/PricingStrategy/SurgeProtection/store/surgeProtection';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { ChangeEvent, Fragment, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const DEFAULT_DECAY_SPEED = 20;

export const SurgeProtectionPriceSettings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Surge Protection Auto Price Settings'));

  const DURATION_IN_DAYS = [
    {
      title: t('Rapid'),
      label: t('5 days'),
      value: 5
    },
    {
      title: t('Medium'),
      label: t('20 days'),
      value: 20
    },
    {
      title: t('Slow'),
      label: t('50 days'),
      value: 50
    }
  ];

  const { features } = useFeaturesStore();

  const { view } = useViewStore();

  const { schema } = useFormSchema();
  const { getValues, watch, setValue, control } = useFormContext<z.infer<typeof schema>>();
  const { pricingTypeTabValue, setPricingTypeTabValue, setIsAutoProtectionCheckboxEnable } =
    useSurgeProtectionStore();
  const { pricingSettings, pricingSettingsQuery } = usePricingSettings();
  const { isLoading: isPricingSettingsLoading } = pricingSettingsQuery;

  const [isHovering, setIsHovering] = useState(false);
  const { SparkIcon, isAutoSetPricesUpselling, setUpsellingModalOpen, ProEntryPoints } =
    useUpselling();

  const isAccessToAutoProtection = features?.includes(Feature.SurgeProtectionPro);

  const referenceRoomId = pricingSettings?.rooms?.reference?.id ?? '';
  const basePrice = Math.round(pricingSettings?.default[referenceRoomId]?.avg_price || 0);

  const handleAutoProtectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setValue('auto_price_protection', true);
      setIsAutoProtectionCheckboxEnable(true);
    } else {
      setValue('auto_price_protection', false);
      setIsAutoProtectionCheckboxEnable(false);
    }
  };

  const calculateDecayStep = (fixPrice: number, basePrice: number, durationInDays: number) => {
    const decayStep = Math.abs((fixPrice - basePrice) / durationInDays);
    return decayStep;
  };

  return (
    <Fragment>
      <SimpleGrid
        cols={{
          xs: 1,
          md: 2
        }}
        spacing="xl">
        <Stack gap="lg">
          <Title fz={rem(24)} fw="normal">
            {t('Protection Price Settings')}
          </Title>
          <SurgeSettingsText />
          {pricingTypeTabValue === Tabs.DECAY_PRICE && getValues('auto_price_protection') ? (
            <Card>
              <Stack mb="xs" gap={rem(2)}>
                <Text c="dark.9">{t('Price Decay Visualisation')}</Text>
                <Text size="xs">{t('Choose a different Decay Speed to see the chart adapt')}</Text>
              </Stack>
              <SurgeProtectionDecayChart
                initialFixPrice={watch('fix_price') ?? 0}
                durationInDays={watch('duration_in_days') ?? 0}
                decayStep={watch('decay_step') ?? 0}
              />
            </Card>
          ) : null}
        </Stack>
        <Stack gap="lg">
          {ProEntryPoints.isAutoSetPricesEntryPoint ? (
            <Skeleton visible={isPricingSettingsLoading}>
              <Flex
                align="center"
                h={rem(24)}
                gap={rem(6)}
                onMouseOver={() => {
                  if (isAutoSetPricesUpselling) {
                    setIsHovering(true);
                  }
                }}
                onMouseOut={() => setIsHovering(false)}
                onClick={() => {
                  if (isAutoSetPricesUpselling) {
                    setUpsellingModalOpen(true);
                  }
                }}>
                <Checkbox
                  label={t('Automatically Set Protection Price')}
                  id="auto-protection"
                  name="auto_price_protection"
                  className="hover:cursor-pointer"
                  disabled={isAutoSetPricesUpselling || !isAccessToAutoProtection}
                  checked={watch('auto_price_protection')}
                  onChange={handleAutoProtectionChange}
                />
                <SparkIcon className={isHovering && view != 'admin' ? 'block' : 'hidden'} />
              </Flex>
            </Skeleton>
          ) : null}

          <Stack gap="lg">
            <Skeleton visible={isPricingSettingsLoading}>
              <Controller
                name="fix_price"
                control={control}
                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                  <TextInput
                    placeholder={t('Please enter fix price') as string}
                    disabled={!watch('auto_price_protection')}
                    error={error?.message}
                    label={t('Starting Protection Price') as string}
                    description={t('Set for the reference room (others will be derived)') as string}
                    name={name}
                    id={name}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      const durationInDays = getValues('duration_in_days');
                      if (!durationInDays) return;
                      const parsedValue = parseInt(e.target.value);
                      setValue(
                        'decay_step',
                        calculateDecayStep(parsedValue, basePrice, durationInDays)
                      );
                    }}
                    inputMode="numeric"
                    leftSection={CurrencyFormatter.currencySymbol()}
                  />
                )}
              />
            </Skeleton>

            <Skeleton visible={isPricingSettingsLoading}>
              <SegmentedControl
                fullWidth
                value={pricingTypeTabValue}
                data={[
                  {
                    label: t('Fixed Protection Price'),
                    value: Tabs.FIXED_PRICE
                  },
                  {
                    label: t('Decaying Protection Price'),
                    value: Tabs.DECAY_PRICE
                  }
                ]}
                onChange={(value) => {
                  setPricingTypeTabValue(value as Tabs);
                  if (value === Tabs.FIXED_PRICE) {
                    setValue('decay_step', undefined);
                  } else {
                    const fixPrice = getValues('fix_price');

                    const durationInDaysOptionValues = DURATION_IN_DAYS.map((item) => item.value);
                    const durationInDaysFormValue = getValues('duration_in_days');

                    const durationInDays = durationInDaysOptionValues.includes(
                      durationInDaysFormValue
                    )
                      ? durationInDaysFormValue
                      : DEFAULT_DECAY_SPEED;

                    setValue('duration_in_days', durationInDays);
                    if (!fixPrice || !durationInDays) return;
                    setValue('decay_step', calculateDecayStep(fixPrice, basePrice, durationInDays));
                  }
                }}
              />
            </Skeleton>

            {/* Expire Price Fields */}
            {pricingTypeTabValue === Tabs.FIXED_PRICE ? (
              <Skeleton visible={isPricingSettingsLoading}>
                <Controller
                  name="duration_in_days"
                  control={control}
                  render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                    <TextInput
                      placeholder={t('Please enter number of days') as string}
                      disabled={!watch('auto_price_protection')}
                      error={error?.message}
                      label={t('Keep fix price for this many days') as string}
                      name={name}
                      id={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      inputMode="numeric"
                    />
                  )}
                />
              </Skeleton>
            ) : null}

            {/* Decay Price Fields */}
            {pricingTypeTabValue === Tabs.DECAY_PRICE ? (
              <Skeleton visible={isPricingSettingsLoading}>
                <Controller
                  name="duration_in_days"
                  control={control}
                  render={({ field: { value, name, onChange } }) => (
                    <Radio.Group
                      value={value?.toString()}
                      label={t('Decay Speed')}
                      onChange={(value) => {
                        const parsedValue = parseInt(value);
                        onChange(parsedValue);
                        const fixPrice = getValues('fix_price');
                        if (!fixPrice) return;
                        setValue(
                          'decay_step',
                          calculateDecayStep(fixPrice, basePrice, parsedValue)
                        );
                      }}
                      name={name}>
                      <Stack pt="xs" gap="xs">
                        {DURATION_IN_DAYS.map((durationInDays) => (
                          <Radio.Card
                            key={durationInDays.title}
                            px="md"
                            py="xs"
                            radius="lg"
                            disabled={!watch('auto_price_protection')}
                            value={durationInDays.value?.toString()}
                            className="group bg-white disabled:cursor-not-allowed disabled:opacity-50 data-[checked]:border-[var(--mantine-primary-color-filled)]">
                            <Group wrap="nowrap" align="center" justify="space-between">
                              <div>
                                <Text c="dark">{durationInDays.title}</Text>
                                <Text size="sm">{durationInDays.label}</Text>
                              </div>
                              <div className="hidden shrink-0 text-indigo group-data-[checked]:flex">
                                <Icon.CheckList className="h-6 w-6" />
                              </div>
                            </Group>
                          </Radio.Card>
                        ))}
                      </Stack>
                    </Radio.Group>
                  )}
                />
              </Skeleton>
            ) : null}
          </Stack>
        </Stack>
      </SimpleGrid>
    </Fragment>
  );
};
