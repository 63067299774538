import { scrapeBookingUrl } from '@common/api/hotel';
import { BookingScrape } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useScrapeBookingUrl = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: BookingScrape) => {
      return scrapeBookingUrl(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully scrape booking Url');
    },
    onError: (error: any) => {
      addNotification('fail', error.response.data.message);
    }
  });

  return {
    scrapeBookingUrl: mutate,
    isLoading
  };
};
