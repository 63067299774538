export function LightOn(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4H11.5C11.2239 4 11 3.77614 11 3.5V1.5C11 1.22386 11.2239 1 11.5 1H12.5C12.7761 1 13 1.22386 13 1.5V3.5C13 3.77614 12.7761 4 12.5 4ZM6.24458 10.3338C6.98571 7.76996 9.33119 6.00382 12 6C14.6688 6.00382 17.0143 7.76996 17.7554 10.3338C18.4965 12.8976 17.4551 15.6428 15.2 17.07C14.761 17.3422 14.4957 17.8235 14.5 18.34V18.5C14.5 18.7761 14.2761 19 14 19H10C9.72386 19 9.5 18.7761 9.5 18.5V18.34C9.50039 17.8245 9.23606 17.3449 8.8 17.07C6.54485 15.6428 5.50345 12.8976 6.24458 10.3338ZM8 12C8 14.2091 9.79086 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12ZM10 21.59C10.0011 21.8523 10.1053 22.1037 10.29 22.29L10.71 22.71C10.8963 22.8947 11.1477 22.9989 11.41 23H12.59C12.8523 22.9989 13.1037 22.8947 13.29 22.71L13.71 22.29C13.8947 22.1037 13.9989 21.8523 14 21.59V21H10V21.59ZM20.13 4.58L19.42 3.87C19.3293 3.77332 19.2026 3.71848 19.07 3.71848C18.9374 3.71848 18.8107 3.77332 18.72 3.87L17.3 5.28C17.1082 5.47781 17.1082 5.79219 17.3 5.99L18 6.7C18.1978 6.89178 18.5122 6.89178 18.71 6.7L20.12 5.28C20.218 5.19066 20.2746 5.06476 20.2765 4.93216C20.2784 4.79957 20.2254 4.67211 20.13 4.58ZM5.28 3.87L6.7 5.28C6.89834 5.47902 6.89834 5.80098 6.7 6L6 6.7C5.80098 6.89834 5.47902 6.89834 5.28 6.7L3.87 5.28C3.77332 5.18927 3.71848 5.06259 3.71848 4.93C3.71848 4.79741 3.77332 4.67073 3.87 4.58L4.58 3.87C4.67073 3.77332 4.79741 3.71848 4.93 3.71848C5.06259 3.71848 5.18927 3.77332 5.28 3.87ZM22.5 11H20.5C20.2239 11 20 11.2239 20 11.5V12.5C20 12.7761 20.2239 13 20.5 13H22.5C22.7761 13 23 12.7761 23 12.5V11.5C23 11.2239 22.7761 11 22.5 11ZM1.5 11H3.5C3.77614 11 4 11.2239 4 11.5V12.5C4 12.7761 3.77614 13 3.5 13H1.5C1.22386 13 1 12.7761 1 12.5V11.5C1 11.2239 1.22386 11 1.5 11Z"
      />
    </svg>
  );
}
