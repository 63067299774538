import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@common/components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import * as z from 'zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Typography } from '@common/components/foundations/Typography';
import { useAuthStore } from '@common/store/auth';
import { useLoginUser } from '@pages/Auth/Login/hooks/useLoginUsers';
import { OTPInput } from '@pages/Auth/OTP/components/OTPInput';
import { useGetOTP, useVerifyOTP } from '@pages/Auth/OTP/hooks';

const schema = z.object({
  otpInput: z.string().min(6, 'Please enter a valid OTP.')
});

export function OTP() {
  const navigate = useNavigate();
  const { mutate: getOTP, isPending: isLoading } = useGetOTP();
  const { mutate: verifyOTP, isSuccess, data: verifyResult } = useVerifyOTP();
  const { data } = useLoginUser();
  const setOTPValidation = useAuthStore((state) => state.setOTPValidation);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const { handleSubmit, control, watch, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (!isSuccess || !verifyResult?.success) return;
    setOTPValidation(true);
    navigate('/admin');
  }, [isSuccess, verifyResult]);

  const handleComplete = () => {
    verifyOTP(watch('otpInput').toUpperCase());
  };

  const handleGetNewOtp = () => {
    reset({ otpInput: '' });
    setResendButtonDisabled(true);
    getOTP();
    setTimeout(() => {
      setResendButtonDisabled(false);
    }, 5 * 60 * 1000);
  };

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          <Typography element="h2" variant="h5" className="font-medium" color="darkGrey">
            Please enter the verification code
          </Typography>
          <Typography>
            An email with a verification code was sent to your email {data?.user.email}
          </Typography>
        </div>

        <div className="mt-8">
          <div className="mt-6">
            <form>
              <div className="space-y-6">
                <div className="mt-2">
                  <div className="flex justify-between gap-x-2">
                    <Controller
                      name="otpInput"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <OTPInput
                          value={value}
                          onChange={onChange}
                          length={6}
                          onComplete={handleSubmit(handleComplete)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-8 flex flex-col space-y-4">
                <Button
                  type="submit"
                  intent="primary"
                  size="large"
                  onClick={handleGetNewOtp}
                  disabled={resendButtonDisabled}
                  isLoading={isLoading}>
                  Resend Verification Code
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
