import { Button } from '@common/components/atoms/Button';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { Switcher } from '@common/components/atoms/Switcher';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { QuickMonthNavigator } from '@common/components/molecules/QuickMonthNavigator/QuickMonthNavigator';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { isEmpty, isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import {
  monthlyPricesColumns,
  roomTypeDerivationColumns,
  weekPricesColumns
} from '@pages/Client/HealthReport/common/constants/columns';
import { useHealthReport } from '@pages/Client/HealthReport/hooks/useHealthReport';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelUpdate } from '@pages/Client/hooks/useHotelUpdate';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@mantine/hooks';
dayjs.extend(advancedFormat);

const schema = z.object({
  page: z.number().optional()
});
const defaultValues = {
  page: 1
};

export const HealthReport = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Customer Health Report'));
  const [currentMonthIndex, setCurrentMonthIndex] = useState(dayjs().format('YYYY-MM'));
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();
  const {
    hotelDetails,
    query: { isLoading: isGetHotelDetailLoading }
  } = useHotelDetails();
  const { updateHotel, isLoading: isHotelUpdateLoading } = useHotelUpdate();

  const { selectedHotelRoom, hotelRooms, setSelectedHotelRoomId } = useHotelRoomsList();
  const { watch, setValue } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const { healthReport, isLoading, isFetching } = useHealthReport(
    filterEmptyValues({
      page: watch('page')
    })
  );

  const handleUpdateHotel = (value: boolean) => {
    updateHotel({
      enable_health_check_hubspot_ticket: value
    });
  };

  useEffect(() => {
    if (!selectedHotelRoom && hotelRooms) {
      if (import.meta.env.DEV) {
        console.log('Setting default selected hotel room...');
      }

      const refRoom = hotelRooms.find((room) => room.is_reference_room);
      setSelectedHotelRoomId(refRoom?.id);
    }
  }, [hotelRooms]);

  const healthReportResult = healthReport?.results || {};
  const isHealthReportEmpty = isEmpty(healthReportResult);
  const basePriceTooHigh =
    healthReportResult?.[currentMonthIndex]?.overall_price_health?.label === 'TOO_HIGH';
  const basePriceTooLow =
    healthReportResult?.[currentMonthIndex]?.overall_price_health?.label === 'TOO_LOW';
  const goodBasePrice = !basePriceTooHigh && !basePriceTooLow;
  const healthKey = healthReportResult ? Object.keys(healthReportResult) : [];
  const currentPage = healthReport?.current || 1;
  const handlePrevMonth = (): void => {
    setCurrentMonthIndex((prev) => dayjs(prev).subtract(1, 'month').format('YYYY-MM'));
    if (!healthReport?.next) return;
    const params = new URLSearchParams(healthReport.next.split('?')[1]);
    const page = params.get('page');
    if (currentMonthIndex === healthKey[healthKey.length - 1]) {
      setValue('page', Number(page));
    }
  };

  const handleNextMonth = (): void => {
    setCurrentMonthIndex((prev) => dayjs(prev).add(1, 'month').format('YYYY-MM'));
    if (!currentPage) return;
    const previousPage = currentPage - 1;
    if (previousPage === 0) return;
    if (currentMonthIndex === healthKey[0]) {
      setValue('page', previousPage);
    }
  };

  const dateWhenMaxPriceIsToLow = healthReportResult?.[currentMonthIndex]
    ?.dates_when_max_price_is_too_low
    ? Object.keys(healthReportResult[currentMonthIndex].dates_when_max_price_is_too_low)
    : [];

  const handleSnooze = async () => {
    const nextMonth = dayjs().add(1, 'month').startOf('month');
    const compareDate = dayjs(hotelDetails?.date_health_check_hubspot_ticket_snoozed);

    if (
      !isNil(hotelDetails?.date_health_check_hubspot_ticket_snoozed) ||
      dayjs().isBefore(compareDate)
    ) {
      await updateHotel({
        date_health_check_hubspot_ticket_snoozed: null
      });
    } else {
      // Add 3 more months
      const resultDate = nextMonth.add(3, 'month');
      await updateHotel({
        date_health_check_hubspot_ticket_snoozed: resultDate.format('YYYY-MM-DD')
      });
    }
  };

  return (
    <>
      <div className="mb-5 flex flex-row items-center justify-between">
        <div className="w-1/2 p-2">
          {isGetHotelDetailLoading ? (
            <Skeleton className="h-5 max-w-xl" />
          ) : (
            <div className="flex gap-4">
              <Switcher
                label={`${isHotelUpdateLoading ? 'Updating...' : 'Send HubSpot Tickets'}`}
                size="small"
                checked={hotelDetails?.enable_health_check_hubspot_ticket}
                onChange={handleUpdateHotel}
              />
              {hotelDetails?.enable_health_check_hubspot_ticket ? (
                <div className="flex items-center gap-2">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button icon onClick={() => handleSnooze()}>
                          {isHotelUpdateLoading ? <Icon.LoadingCircle /> : <Icon.Snooze />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="bg-darkGrey text-white">
                        <div className="max-w-[180px] text-center">
                          <Typography className="text-center text-meta-2-medium" color="white">
                            {dayjs().isBefore(
                              dayjs(hotelDetails?.date_health_check_hubspot_ticket_snoozed)
                            )
                              ? 'Unsnooze the HubSpot ticket'
                              : 'Snooze the HubSpot ticket for 3 month'}
                          </Typography>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <Typography className="text-center text-meta-2-medium" color="copyTextGrey">
                    {hotelDetails?.date_health_check_hubspot_ticket_snoozed
                      ? `Snoozed until ${dayjs(
                          hotelDetails?.date_health_check_hubspot_ticket_snoozed
                        ).format('MMMM YYYY')}`
                      : null}
                  </Typography>
                  {healthReportResult[currentMonthIndex]?.hubspot_ticket_id ? (
                    <Button
                      className="text-center text-meta-2-medium text-indigo hover:cursor-pointer"
                      intent="text"
                      onClick={() => {
                        window.open(
                          `https://app.hubspot.com/contacts/5641770/record/0-5/${healthReportResult[currentMonthIndex]?.hubspot_ticket_id}`,
                          '_blank'
                        );
                      }}>
                      Go to HubSpot Ticket
                    </Button>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </div>

        {/* TODO: Move this to header after changing its usage to global state  */}
        <div className="-ml-5 flex items-center text-darkGrey">
          <div className="-mr-2 mb-5 flex w-[150px] flex-col items-center justify-center ">
            <div>
              <Typography variant="meta-3" color="darkGrey">
                Generated Report From
              </Typography>
            </div>
            <div className="-mr-[30%]">
              <QuickMonthNavigator
                label="Jump to Month"
                textElement="h6"
                currentYear={Number(dayjs().format('YYYY'))}
                previousYear={Number(dayjs().subtract(1, 'year').format('YYYY'))}
                nextYear={Number(dayjs().add(1, 'year').format('YYYY'))}
                currentDate={dayjs(currentMonthIndex).toDate()}
                setDate={(value) => {
                  setCurrentMonthIndex(dayjs(value as Date).format('YYYY-MM'));
                }}
              />
            </div>
          </div>
          <Button
            icon
            onClick={handlePrevMonth}
            disabled={
              (currentMonthIndex === healthKey[healthKey.length - 1] && !healthReport?.next) ||
              isHealthReportEmpty
            }>
            <Icon.ChevronLeft />
          </Button>
          <Button
            intent="text"
            onClick={() => {
              setValue('page', 1);
              setCurrentMonthIndex(dayjs().format('YYYY-MM'));
            }}
            className="hidden whitespace-nowrap md:block">
            <Typography element="span" variant="meta-1" className="text-darkGrey">
              Current month
            </Typography>
          </Button>
          <Button
            icon
            onClick={handleNextMonth}
            disabled={currentPage <= 1 && currentMonthIndex === dayjs().format('YYYY-MM')}>
            <Icon.ChevronRight />
          </Button>
        </div>
      </div>

      {isHealthReportEmpty && !isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Typography variant="h6" color="darkGrey" className="text-center">
            No data found
          </Typography>
        </div>
      ) : (
        <div className="-mt-4 space-y-4">
          <div className="grid grid-cols-12 gap-4">
            <Card backgroundColor="white" className="col-span-3 shadow-none">
              <Typography color="darkGrey" variant="h6" className="text-center">
                Base Price Reference Room
              </Typography>
              <div className="flex w-full flex-col items-center justify-center py-3">
                {isLoading ? (
                  <Skeleton className="h-20 w-20 rounded-full ring-[10px] ring-darkGrey-reduced" />
                ) : (
                  <div
                    className={`mt-2 flex h-20 w-20 items-center justify-center rounded-full ${
                      goodBasePrice ? 'bg-uiGreen' : 'bg-uiRed'
                    } py-2 ring-[10px] ${
                      goodBasePrice ? 'ring-uiGreen' : 'ring-uiRed'
                    } ring-opacity-20`}>
                    {isLoading || isFetching ? (
                      <Skeleton className="h-5 bg-white" />
                    ) : (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <Typography color="white" variant="h6" className="text-center">
                              {!goodBasePrice ? 'Too' : null} {!goodBasePrice ? <br /> : null}
                              {goodBasePrice && healthKey?.includes(currentMonthIndex)
                                ? 'Good'
                                : basePriceTooHigh
                                ? 'High'
                                : basePriceTooLow
                                ? 'Low'
                                : 'n.A.'}
                            </Typography>
                          </TooltipTrigger>
                          <TooltipContent side="top" className="bg-darkGrey text-white">
                            <div className="max-w-[180px] text-center">
                              <Typography className="text-center text-meta-2-medium" color="white">
                                Calculated based on monthly performance of next 6 months
                              </Typography>
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                )}
              </div>
              <Typography className="mt-3 text-center text-meta-2-medium" color="darkGrey">
                Base Price:{' '}
                {CurrencyFormatter.format(
                  Math.round(healthReportResult?.[currentMonthIndex]?.base_price || 0)
                )}
              </Typography>
            </Card>
            <Card backgroundColor="white" className=" col-span-9 shadow-none">
              <Typography color="darkGrey" variant="h6">
                Dates where recommendation greater than max price
              </Typography>
              <div
                className={`flex flex-wrap gap-2 ${
                  dateWhenMaxPriceIsToLow?.length > 0 ? 'grid-cols-7' : 'grid-cols-1'
                } gap-3`}>
                {isLoading ? (
                  Array.from({ length: 6 }).map((_, index) => (
                    <th className="text-meta-1-medium text-darkGrey" key={index}>
                      <Skeleton className="h-6" />
                    </th>
                  ))
                ) : dateWhenMaxPriceIsToLow?.length > 0 ? (
                  dateWhenMaxPriceIsToLow.map((date, index) => (
                    <div key={index}>
                      <Button
                        size="small"
                        className="text-darkGrey"
                        intent="outline"
                        onClick={() => {
                          const formattedDate = new Date(dayjs(date).format('YYYY-MM-DD'));
                          setViewingDate(formattedDate);
                          setDrawerState(true);
                        }}>
                        {dayjs(date).format('MMMM DD, YYYY')}
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="my-4 flex w-full items-center justify-center">
                    <Typography color="grey" variant="meta-1">
                      No dates found
                    </Typography>
                  </div>
                )}
              </div>
            </Card>
          </div>
          <Table
            isHover
            isStriped
            isRowClickable={false}
            isFetching={isLoading || isFetching}
            headerComponent={
              <Typography color="darkGrey" variant="h6">
                Monthly Prices
              </Typography>
            }
            skeletonCount={4}
            columns={monthlyPricesColumns}
            data={healthReportResult?.[currentMonthIndex]?.monthly_health ?? []}
          />
          <Table
            isHover
            isStriped
            isRowClickable={false}
            isFetching={isLoading || isFetching}
            headerComponent={
              <Typography color="darkGrey" variant="h6">
                Day-of-the-week Prices
              </Typography>
            }
            skeletonCount={4}
            columns={weekPricesColumns}
            data={healthReportResult?.[currentMonthIndex]?.day_of_the_week_health ?? []}
          />

          <Table
            isHover
            isStriped
            isRowClickable={false}
            isFetching={isLoading || isFetching}
            headerComponent={
              <Typography color="darkGrey" variant="h6">
                Room Type Derivation
              </Typography>
            }
            skeletonCount={4}
            columns={roomTypeDerivationColumns}
            data={
              healthReportResult?.[currentMonthIndex]?.derivation_room_type_health?.sort((a, b) => {
                if (a.is_reference_room && !b.is_reference_room) {
                  return -1;
                } else if (!a.is_reference_room && b.is_reference_room) {
                  return 1;
                } else {
                  return a.name.localeCompare(b.name);
                }
              }) ?? []
            }
          />
        </div>
      )}
    </>
  );
};
