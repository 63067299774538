export const ArrowLongLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h6 w-6"
      {...props}>
      <path d="M22.0032 11.5V12.5C22.0032 12.7762 21.7792 13 21.503 13H5.99824V15C5.99543 15.2001 5.87362 15.3792 5.68857 15.4553C5.50353 15.5315 5.29087 15.4901 5.14798 15.35L2.14706 12.35C1.95521 12.1522 1.95521 11.8378 2.14706 11.64L5.14798 8.64002C5.29218 8.49872 5.50722 8.45795 5.69315 8.53667C5.87909 8.6154 5.99943 8.79817 5.99824 9.00002V11H21.503C21.7792 11 22.0032 11.2239 22.0032 11.5Z" />
    </svg>
  );
};
