import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { name: string; code: string }) => void;
  selectedRoom: { name: string; code: string };
  isLoading: boolean;
  mode: 'add' | 'edit';
};

const schema = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  code: z.string().trim().min(1, { message: 'Required' }),
});

export const UpdateNewbookRoomMappingModal = ({
  isOpen,
  onClose,
  onSave,
  selectedRoom,
  isLoading,
  mode,
}: ModalProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control, setValue, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (mode === 'edit') {
      setValue('name', selectedRoom.name);
      setValue('code', selectedRoom.code);
    } else if (mode === 'add' && isOpen) {
      reset({ name: '', code: '' }); // Reset fields when opening in "add" mode
    }
  }, [selectedRoom, setValue, mode, reset, isOpen]);

  const onSubmit = handleSubmit((data) => {
    onSave(data);
  });

  return (
    <Modal.Root opened={isOpen} onClose={onClose} size="md" centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            {mode === 'edit'
              ? `${t('Edit')}: ${selectedRoom.name}`
              : t('Add New Room Mapping')}
          </Modal.Title>
          <Modal.CloseButton onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-4">
            {mode === 'add' ? (
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    type="text"
                    background="grey"
                    label={`${t('Room Name')}*`}
                    placeholder={`${t('Enter room name')}`}
                    {...field}
                    error={fieldState.invalid}
                    onClear={() =>  setValue('name', '')}
                    helperMessage={
                      fieldState.error ? (
                        <InputHelperMessage
                          icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                          message={fieldState.error.message}
                        />
                      ) : null
                    }
                  />
                )}
              />
            ) : null}
            <Controller
              name="code"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  disabled={false}
                  type="text"
                  background="grey"
                  label={`${t('Room Code')}*`}
                  placeholder={`${t('Enter room code')}`}
                  {...field}
                  error={fieldState.invalid}
                  onClear={() =>  setValue('code', '')}
                  helperMessage={
                    fieldState.error ? (
                      <InputHelperMessage
                        icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                        message={fieldState.error.message}
                      />
                    ) : null
                  }
                />
              )}
            />
          </div>
        </Modal.Body>
        <ModalFooter>
          <Button variant="subtle" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={onSubmit} loading={isLoading}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal.Content>
    </Modal.Root>
  );
};
