import { create } from 'zustand';

type NotificationType = 'success' | 'fail';

export type Notification = {
  id: string;
  type: NotificationType;
  message: string;
};

type NotificationsState = {
  notifications: Notification[];
  addNotification: (type: NotificationType, message: string) => void;
  removeNotification: (id: string) => void;
};

export const useNotificationsStore = create<NotificationsState>((set, _get) => ({
  notifications: [],

  addNotification: (type, message) =>
    set((state) => {
      // Check if a notification with the same type and message already exists
      const isDuplicate = state.notifications.some(
        (notification) => notification.type === type && notification.message === message
      );

      if (!isDuplicate) {
        const id = Date.now().toString();
        return {
          ...state,
          notifications: [...state.notifications, { id, type, message }]
        };
      }

      // If a duplicate is found, just return the current state without adding a new notification
      return state;
    }),

  removeNotification: (id) =>
    set((state) => ({
      ...state,
      notifications: state.notifications.filter((notification) => notification.id !== id)
    }))
}));
