import { enablePushPms } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useEnablePushPms = () => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: boolean) => {
      return enablePushPms(payload);
    },
    onError: () => {
      addNotification('fail', 'An error occurred while enabling push data.');
    },
    onSuccess: (response) => {
      addNotification('success', response?.data?.message ?? 'Push data enabled successfully.');
    }
  });

  return {
    enablePushPms: mutate,
    isLoading
  };
};
