import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@mantine/hooks';
import { Table } from '@common/components/molecules/Table';
import { useModal } from '@common/hooks/useModal';
import { TableHeader } from '@pages/Client/Account/User/components/TableHeader';
import { InviteModal } from '@pages/Client/Account/User/components/InviteUserModal';
import { EditUserModal } from '@pages/Client/Account/User/components/EditUserModal';
import { EditOwnerModal } from '@pages/Client/Account/User/components/EditOwnerModal';
import { DeleteUserModal } from '@pages/Client/Account/User/components/DeleteUserModal';
import { EditOwner, InviteUser } from '@common/api/account/types';
import { DeleteFunctions, Role } from '@pages/Client/Account/User/types/delete';
import {
  useInviteUser,
  useDeleteManager,
  useDeleteStaff,
  useDeleteAdmin
} from '@pages/Client/Account/User/hooks/useUser';
import { ActionIcon, Badge, Group, Text, Tooltip } from '@mantine/core';
import { Icon } from '@common/components/foundations/icons';
import { UserList } from '@pages/Client/Account/User/types/userList';
import { useUserTableData } from '@pages/Client/Account/User/hooks/useUserTableData';
import { useAuthStore } from '@common/store/auth';
import { useGetHotelUsers } from '@pages/Client/hooks/useGetHotelUsers';

export const UserSettings: React.FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Users'));

  const { hotelUsers } = useGetHotelUsers();

  const { user: currentUser } = useAuthStore();
  const isCurrentUserStaff =
    hotelUsers?.find((user) => user.id === currentUser?.id)?.role === 'staff';

  const { isOpen: inviteOpen, openModal: inviteClick, closeModal: inviteCloseClick } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editCloseClick } = useModal();
  const {
    isOpen: editOwnerOpen,
    openModal: editOwnerClick,
    closeModal: editOwnerCloseClick
  } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteCloseClick } = useModal();

  const [deleteUser, setDeleteUser] = useState<UserList | undefined>();
  const [loadingRows, setLoadingRows] = useState<Set<string>>(new Set());
  const [editOwnerData, setEditOwnerData] = useState<EditOwner | undefined>();
  const [editUserData, setEditUserData] = useState<any>();

  const { mutateAsync: inviteUser } = useInviteUser();

  const { mutateAsync: deleteManager } = useDeleteManager();
  const { mutateAsync: deleteStaff } = useDeleteStaff();
  const { mutateAsync: deleteAdmin } = useDeleteAdmin();

  const { mergedList, isUserLoading, pendingListRaws, pendingList } = useUserTableData();

  const handleDeleteUser = useCallback(
    async (id: number, role: Role) => {
      const deleteFunctions: DeleteFunctions = {
        Manager: deleteManager,
        Staff: deleteStaff,
        Admin: deleteAdmin
      };
      const deleteFunction = deleteFunctions[role];
      if (!deleteFunction) {
        throw new Error(`No delete function found for role: ${role}`);
      }
      await deleteFunction(id);
    },
    [deleteManager, deleteStaff, deleteAdmin]
  );

  const handleInviteUser = useCallback(
    async (email: string) => {
      setLoadingRows((prev) => new Set(prev).add(email));
      try {
        await inviteUser(
          !isUserLoading ? pendingListRaws?.find((u: InviteUser) => u.email === email) : null
        );
      } finally {
        setLoadingRows((prev) => {
          const newSet = new Set(prev);
          newSet.delete(email);
          return newSet;
        });
      }
    },
    [inviteUser, isUserLoading, pendingListRaws]
  );

  const columns = useMemo(
    () => [
      {
        header: t('Name'),
        accessorKey: 'name',
        cell: (row: any) => {
          return (
            <Group gap="sm">
              <Text size="sm" component="span">
                {row?.getValue() as string}
              </Text>
              {row.row.original?.pending ? (
                <Badge color="gold" variant="dot">
                  Pending
                </Badge>
              ) : null}
            </Group>
          );
        },
        meta: {
          showOnMobile: true
        }
      },
      {
        header: t('Email'),
        accessorKey: 'email',
        meta: {
          showOnMobile: true
        }
      },

      {
        header: t('Role'),
        accessorKey: 'type',
        meta: {
          showOnMobile: true
        },
        cell: (row: any) => {
          return (
            <Text size="sm" component="span">
              {t(`${row.getValue()}`)}{' '}
              {row.row.original?.is_read_only ? `(${t('Read Only')})` : null}
            </Text>
          );
        }
      },
      {
        header: t('Access to Hotels'),
        accessorKey: 'assigned_hotels',
        meta: {
          maxWidth: '200px'
        },
        cell: (row: any) => {
          return (
            <Text size="sm" component="span">
              {row.getValue()}
            </Text>
          );
        }
      },
      {
        header: t('Actions'),
        accessorKey: 'delete',
        cell: (row: any) => {
          const { original } = row.row;
          return (
            <Group gap="sm">
              {original?.type === 'Owner' || original?.pending ? null : (
                <ActionIcon
                  onClick={() => {
                    setDeleteUser(original);
                    deleteClick();
                  }}
                >
                  <Icon.Delete className="h-5 w-5" />
                </ActionIcon>
              )}
              {original?.pending ? (
                <Tooltip label={t('Resend Invitation')}>
                  <ActionIcon
                    className="group relative "
                    onClick={() => handleInviteUser(original?.email)}
                    loading={loadingRows.has(original?.email)}
                  >
                    <Icon.Refresh className="h-5 w-5" />
                  </ActionIcon>
                </Tooltip>
              ) : null}
            </Group>
          );
        }
      }
    ],
    [t, handleInviteUser, loadingRows, deleteClick]
  );

  if (isCurrentUserStaff) {
    return null;
  }

  return (
    <>
      <Table
        skeletonCount={10}
        onClickRow={(row) => {
          if (row.column.id === 'delete' || row.row.original.pending) return;
          const userData = mergedList.find((user) => user.id === row.row.original.id);
          if (row.row.original.type === 'Owner') {
            setEditOwnerData(userData as EditOwner);
            editOwnerClick();
          } else {
            setEditUserData(userData);
            editClick();
          }
        }}
        isFetching={isUserLoading}
        isRowClickable
        headerComponent={
          <TableHeader onInviteUser={inviteClick} pendingInvitation={pendingList?.length} />
        }
        data={mergedList}
        columns={columns}
        isHover={true}
      />
      <InviteModal isOpen={inviteOpen} onClose={inviteCloseClick} />
      <EditUserModal
        isOpen={editOpen}
        onClose={() => {
          editCloseClick();
          setEditUserData(undefined);
        }}
        selectedUser={editUserData}
      />
      <EditOwnerModal
        isOpen={editOwnerOpen}
        onClose={() => {
          editOwnerCloseClick();
          setEditOwnerData(undefined);
        }}
        selectedUser={editOwnerData}
      />
      <DeleteUserModal
        isOpen={deleteOpen}
        title={deleteUser?.name}
        onClose={deleteCloseClick}
        onDelete={() => deleteUser && handleDeleteUser(deleteUser.id, deleteUser.type as Role)}
      />
    </>
  );
};
