import { forwardRef, useEffect, useState } from 'react';
import { RadioGroup as HeadlessUIRadioGroup } from '@headlessui/react';
import styles from '@common/components/atoms/RadioGroup/RadioButton.module.css';
import clsx from 'clsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';

interface RadioOption<TValue = unknown> {
  value: TValue;
  label: string;
  description?: string;
  renderAdjacent?: () => React.ReactElement | null;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  renderHoverContent?: () => React.ReactNode;
}

interface WithForwardRefProps<TOption extends RadioOption<TOption['value']>> {
  options: TOption[];
  value?: TOption['value'];
  defaultSelectedOption?: TOption['value'];
  onChange?: (value: TOption['value']) => void;
  disableSelector?: (option: TOption) => boolean;

  labelClassName?: string;
  radioClassName?: string;
  radioWrapperClassName?: string;

  optionsWrapperClassName?: string;
  className?: string;
  type?: 'default' | 'block';
  disabled?: boolean;
}

interface RadioContentProps {
  option: RadioOption;
  checked: boolean;
  disabled: boolean;
}

// Defines an interface for a functional component that can be used with forwardRef.
// The interface extends the React.FC type and takes a generic type T that extends RadioOption.
// It also defines a function that takes props with the generic type T and returns the same type of component.
// The ReturnType utility type is used to infer the return type of this function.
interface WithForwardRefType extends React.FC<WithForwardRefProps<RadioOption>> {
  <T extends RadioOption>(props: WithForwardRefProps<T>): ReturnType<
    React.FC<WithForwardRefProps<T>>
  >;
}

export const RadioGroup: WithForwardRefType = forwardRef(
  (
    {
      options,
      value,
      defaultSelectedOption,
      onChange,
      labelClassName,
      radioClassName,
      radioWrapperClassName,
      optionsWrapperClassName,
      className,
      disableSelector,
      type = 'default',
      disabled = false
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [currentSelection, setCurrentSelection] = useState<typeof value>(() => {
      if (value !== undefined) {
        return value;
      } else if (defaultSelectedOption !== undefined) {
        return defaultSelectedOption;
      } else {
        return value;
      }
    });

    useEffect(() => {
      if (value !== undefined) {
        setCurrentSelection(value);
      }
    }, [value]);

    const handleOptionChange = (v: typeof value) => {
      setCurrentSelection(v);
      if (onChange !== undefined) {
        onChange(v);
      }
    };

    const renderRadioContent = ({ option, checked, disabled }: RadioContentProps) => {
      return (
        <div
          onMouseEnter={option?.onMouseEnter}
          onMouseLeave={option?.onMouseLeave}
          className={clsx(
            styles.radioButtonContentWrapper,
            disabled ? 'opacity-30' : null,
            'group'
          )}>
          <label
            className={clsx(
              styles.labelBase,
              disabled
                ? 'cursor-not-allowed'
                : 'cursor-pointer focus-within:ring-2 focus-within:ring-focusNative group-hover:bg-indigo group-hover:bg-opacity-20'
            )}>
            <input
              ref={ref}
              title={option.label}
              type="radio"
              className="absolute h-full w-full cursor-pointer appearance-none opacity-0"
            />
            <div className={clsx(styles.radio, radioClassName)}>
              <div
                className={clsx(
                  'transition-scale h-2 w-2 shrink-0 rounded-full bg-indigo duration-75 ease-in-out',
                  !checked ? 'scale-0' : 'scale-100'
                )}
              />
            </div>
          </label>
          <div className={clsx(styles.baseLabel, labelClassName)}>
            <HeadlessUIRadioGroup.Label as="p">{option.label}</HeadlessUIRadioGroup.Label>
            {option.description && (
              <HeadlessUIRadioGroup.Description
                as="span"
                className={`inline ${checked ? 'text-yellow-200' : 'text-gray-500'}`}>
                {option.description}
              </HeadlessUIRadioGroup.Description>
            )}
          </div>
          {option?.renderAdjacent?.()}
        </div>
      );
    };

    return (
      <HeadlessUIRadioGroup
        disabled={disabled}
        value={currentSelection}
        onChange={handleOptionChange}
        className={clsx(styles.radioGroup, type === 'block' && styles.blockRadioGroup, className)}>
        <HeadlessUIRadioGroup.Label className="sr-only">
          Select an option
        </HeadlessUIRadioGroup.Label>
        <div className={clsx(styles.innerWrapper, optionsWrapperClassName)}>
          {options.map((option) => (
            <HeadlessUIRadioGroup.Option
              key={`${option.value}`}
              disabled={disableSelector?.(option) ?? false}
              value={option.value}
              className={({ active }) =>
                clsx(styles.radioButton, active && 'active', radioWrapperClassName)
              }>
              {({ checked, disabled }) => (
                <>
                  {option?.renderHoverContent ? (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          {renderRadioContent({ option, checked, disabled })}
                        </TooltipTrigger>
                        <TooltipContent side="bottom" align="start" className="max-w-xs">
                          {option.renderHoverContent()}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ) : (
                    <>{renderRadioContent({ option, checked, disabled })}</>
                  )}
                </>
              )}
            </HeadlessUIRadioGroup.Option>
          ))}
        </div>
      </HeadlessUIRadioGroup>
    );
  }
);
