export const Bed = (props: any) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3337 13.2499C17.3337 13.48 17.1471 13.6666 16.917 13.6666H16.0837C15.8535 13.6666 15.667 13.48 15.667 13.2499V10.3333H2.33366V13.2499C2.33366 13.48 2.14711 13.6666 1.91699 13.6666H1.08366C0.85354 13.6666 0.666992 13.48 0.666992 13.2499V0.749919C0.666992 0.5198 0.85354 0.333252 1.08366 0.333252H1.91699C2.14711 0.333252 2.33366 0.5198 2.33366 0.749919V7.83325H16.5003C16.9606 7.83325 17.3337 8.20635 17.3337 8.66658V13.2499ZM15.667 3.66659H9.00033C8.07985 3.66659 7.33366 4.41278 7.33366 5.33325V6.58325C7.33366 6.81337 7.52021 6.99992 7.75033 6.99992H16.917C17.1471 6.99992 17.3337 6.81337 17.3337 6.58325V5.33325C17.3337 4.41278 16.5875 3.66659 15.667 3.66659ZM6.50033 6.58325C6.50033 6.81337 6.31378 6.99992 6.08366 6.99992H3.58366C3.35354 6.99992 3.16699 6.81337 3.16699 6.58325V6.16659C3.16699 5.24611 3.91318 4.49992 4.83366 4.49992C5.75413 4.49992 6.50033 5.24611 6.50033 6.16659V6.58325Z"
      />
    </svg>
  );
};
