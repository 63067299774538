import { Button } from '@common/components/atoms/Button';
import { useTranslation } from 'react-i18next';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useMutation } from '@tanstack/react-query';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { StepComponentProps } from '@pages/Client/PricingStrategy/RoomSetup/components/SetupRooms/AdminSetupRoomsCard';
import { useNotificationsStore } from '@common/store/notifications';
import { useState } from 'react';
import { ShadDialog } from '@common/components/molecules/ShadDialog/ShadDialog';
import { useHotelRoomsList } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelRoomList';
import { Typography } from '@common/components/foundations/Typography';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { find, forEach, map, orderBy } from 'lodash-es';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { updateHotelRoomDerivedGenerateMaxMinPrice } from '@common/api/hotel';
import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { PricingQueryKeys } from '@common/types/query-keys';

interface MergedDataItem {
  id: number;
  average: number;
  name: string;
  is_reference_room: boolean;
  min_price: number;
  max_price: number;
}

export const SetMinMaxDerivedRoomsSetupStep = ({ index }: StepComponentProps) => {
  const { t } = useTranslation();
  const { features } = useFeaturesStore();
  const { isApartment } = useRoomSetupStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const { addNotification } = useNotificationsStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [returnedMinMax, setReturnedMinMax] = useState<MergedDataItem[] | []>([]);
  const apartmentsOrRooms = isApartment ? 'Apartments' : 'Rooms';
  const enabled = features?.includes(Feature.IndividualMinMax);
  const {
    hotelRooms,
    query: { isLoading: isHotelRoomsLoading }
  } = useHotelRoomsList();
  const {
    pricingSettings,
    pricingSettingsQuery: { refetch: refetchPricingSettings }
  } = usePricingSettings();
  const baseAverages = pricingSettings?.default;

  const { mutateAsync: updateMinMaxPrice, isPending: isUpdateMinMaxUpdateLoading } = useMutation({
    mutationKey: [PricingQueryKeys.UPDATE_MIN_MAX_PRICE],
    mutationFn: updateHotelRoomDerivedGenerateMaxMinPrice,
    onError: (error: unknown) => {
      const axiosError = error as any;
      addNotification('fail', t(`Error setting Min/Max for derived rooms - ${axiosError.message}`));
    }
  });

  async function handleClick() {
    setIsModalOpen(true);
    const newAverage = await updateMinMaxPrice(false);
    const mergedData: MergedDataItem[] = [];
    forEach(newAverage, (room, id) => {
      const roomData = find(hotelRooms, { id: parseInt(id) });
      if (!roomData) return;
      mergedData.push({
        id: roomData.id,
        average: room[ADJUSTMENT_DB.AVG_PRICE_KEY],
        name: roomData.name,
        is_reference_room: roomData.is_reference_room ?? false,
        min_price: room[ADJUSTMENT_DB.MIN_PRICE_KEY],
        max_price: room[ADJUSTMENT_DB.MAX_PRICE_KEY]
      });
    });
    setReturnedMinMax(mergedData);
  }

  async function handleDialogConfirm() {
    await updateMinMaxPrice(true);
    setIsSuccess(true);
    await refetchPricingSettings();
    setIsModalOpen(false);
  }

  const isLoading = isHotelRoomsLoading || isUpdateMinMaxUpdateLoading;

  function ActionButton() {
    return (
      <Button
        type="button"
        intent="primary"
        isLoading={isLoading}
        onClick={() => handleDialogConfirm()}>
        {t('Save')}
      </Button>
    );
  }

  function CloseButton() {
    return (
      <Button
        type="button"
        intent="text"
        disabled={isLoading}
        onClick={() => setIsModalOpen(!isModalOpen)}>
        {t('Cancel')}
      </Button>
    );
  }

  function DataComponent({ isLoading }: { isLoading: boolean }) {
    return (
      <LineTable>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>{t('Current Min/Max')}</th>
            <th>{t('Proposed Min/Max')}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? map(orderBy(hotelRooms, ['is_reference_room', 'name'], ['desc', 'asc']), (room) => {
                const isDerivedRoom = !room.is_reference_room;
                return (
                  <tr key={room.id} className="w-full">
                    <td className="w-2/5">{room.name}</td>
                    <td>
                      <Typography>
                        {CurrencyFormatter.format(
                          Math.round(baseAverages?.[room.id].min_price ?? 0)
                        )}{' '}
                        /{' '}
                        {CurrencyFormatter.format(
                          Math.round(baseAverages?.[room.id].max_price ?? 0)
                        )}
                      </Typography>
                    </td>
                    <td>
                      {isDerivedRoom ? (
                        <div className="flex justify-start gap-2">
                          <Skeleton className="h-4 w-10" /> / <Skeleton className="h-4 w-10" />
                        </div>
                      ) : (
                        ' - '
                      )}
                    </td>
                  </tr>
                );
              })
            : map(
                orderBy(returnedMinMax, ['is_reference_room', 'name'], ['desc', 'asc']),
                (room) => {
                  const isDerivedRoom = !room.is_reference_room;
                  return (
                    <tr key={room.id} className="w-full">
                      <td className="w-2/5">{room.name}</td>
                      <td>
                        <Typography>
                          {CurrencyFormatter.format(
                            Math.round(baseAverages?.[room.id].min_price ?? 0)
                          )}{' '}
                          /{' '}
                          {CurrencyFormatter.format(
                            Math.round(baseAverages?.[room.id].max_price ?? 0)
                          )}
                        </Typography>
                      </td>
                      <td>
                        {isDerivedRoom ? (
                          <Typography variant="meta-1" className="font-semibold">
                            {CurrencyFormatter.format(Math.round(room.min_price))} /{' '}
                            {CurrencyFormatter.format(Math.round(room.max_price))}
                          </Typography>
                        ) : (
                          ' - '
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
        </tbody>
      </LineTable>
    );
  }

  const baseDialogConfig = {
    open: isModalOpen,
    openChange: setIsModalOpen,
    actionButtons: [ActionButton],
    closeButton: CloseButton
  };

  return enabled ? (
    <div className="flex items-center justify-start align-middle">
      <ShadDialog
        config={{
          ...baseDialogConfig,
          title: isLoading ? t('Please wait') : t('Please double-check the prices'),
          content: () => <DataComponent isLoading={isLoading} />
        }}>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          isSuccess={isSuccess}
          type="button"
          onClick={handleClick}
          intent="outline">
          <span>{index}.</span>
          {t(`Set Min/Max for Derived ${apartmentsOrRooms}`)}
        </Button>
      </ShadDialog>
    </div>
  ) : null;
};
