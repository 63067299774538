import { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { Icon } from '@common/components/foundations/icons';
import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import clsx from 'clsx';
interface Data extends Record<string, any> {
  value: string | number;
  label: string;
  address?: string;
  // Additional properties
  [key: string]: any;
}

interface AutoCompleteInputProps {
  placeholder?: string;
  value?: Data | null;
  options?: Data[];
  loadingOptions?: boolean;
  onChange?: (value: Data | null) => void;
  query?: string;
  onQueryChange?: (query: string) => void;
  hint?: string | null;
  additionalInfo?: string;
  name?: string;
  disabled?: boolean;
  customOnClear?: boolean;
  onClear?: () => void;
  truncateLength?: number;
  minQueryLength?: number;
  showOptions?: boolean;
  showHint?: boolean;
  showLoading?: boolean;
  customSearchWhenNotFound?: boolean;
  onSearchWhenNotFound?: (query: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent) => void;
  showOnclick?: boolean;
  background?: 'grey' | 'white';
  label?: string;
}

export default function AutoCompleteInput({
  options,
  placeholder,
  value,
  onChange,
  loadingOptions = false,
  query,
  hint,
  disabled = false,
  name,
  customOnClear = false,
  onClear,
  minQueryLength = 5,
  showOptions = true,
  additionalInfo,
  truncateLength = 1200,
  showHint = true,
  showLoading = true,
  customSearchWhenNotFound = false,
  onSearchWhenNotFound,
  onKeyDown,
  onFocus,
  onQueryChange,
  showOnclick = true,
  background = 'grey',
  label
}: AutoCompleteInputProps) {
  const [selected, setSelected] = useState<Data | null>(value || null);
  const [inputQuery, setInputQuery] = useState(query || '');

  const filteredData =
    inputQuery === ''
      ? options
      : options?.filter((data) =>
          data.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(inputQuery.toLowerCase().replace(/\s+/g, ''))
        );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInputQuery(inputValue);
    if (onQueryChange) {
      onQueryChange(inputValue);
    }
    if (!inputValue) {
      setSelected(null);
    }
  };

  const handleOptionSelect = (selectedOption: Data) => {
    setSelected(selectedOption);
    setInputQuery(selectedOption.label);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  return (
    <div>
      <Combobox value={selected} onChange={handleOptionSelect} disabled={disabled}>
        <div className="relative">
          {label ? (
            <label
              htmlFor={name}
              className={clsx(label ? 'block text-meta-1 text-grey' : 'hidden')}>
              {label}
            </label>
          ) : null}
          <div className="focus-visible:ring-offset-teal-300 group relative w-full cursor-default overflow-hidden text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
            {showOnclick ? (
              <Combobox.Button as="div">
                <Combobox.Input
                  id={name}
                  name={name}
                  placeholder={placeholder || ' Search ...'}
                  className={`${
                    disabled ? 'cursor-not-allowed' : null
                  } w-full truncate rounded-md border border-mediumGrey bg-lightGrey px-4 py-3 pr-10 text-sm leading-5 text-darkGrey`}
                  value={selected?.label || inputQuery}
                  displayValue={(selected: Data) => {
                    if (!selected || !selected.label) {
                      return '';
                    }
                    return selected.label.length > 30
                      ? selected.label.substring(0, truncateLength) + '...'
                      : selected.label;
                  }}
                  onChange={handleInputChange}
                  onKeyDown={onKeyDown}
                  onFocus={onFocus}
                />
              </Combobox.Button>
            ) : (
              <Combobox.Input
                id={name}
                name={name}
                placeholder={placeholder || ' Search ...'}
                className={`${
                  disabled ? 'cursor-not-allowed' : null
                } w-full truncate rounded-md border border-mediumGrey bg-lightGrey px-4 py-3 pr-10 text-sm leading-5 text-darkGrey`}
                value={selected?.label || inputQuery}
                displayValue={(selected: Data) => {
                  if (!selected || !selected.label) {
                    return '';
                  }
                  return selected.label.length > 30
                    ? selected.label.substring(0, truncateLength) + '...'
                    : selected.label;
                }}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
              />
            )}

            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-3">
              {loadingOptions ? (
                <>{showLoading ? <Icon.LoadingCircle className="mr-2 h-4 w-4" /> : null}</>
              ) : null}
              {selected ? (
                <Button
                  icon
                  className="mr-2"
                  title="Clear Button"
                  onClick={() => {
                    customOnClear ? onClear?.() : setSelected(null);
                  }}>
                  <Icon.Clear className="h-3 w-3" />
                </Button>
              ) : null}

              <span
                className={clsx(
                  background === 'white'
                    ? 'bg-lightGrey group-hover:bg-grey-reduced'
                    : 'bg-white group-hover:bg-grey-reduced',
                  'inline-flex select-none items-center justify-center gap-x-1 whitespace-nowrap rounded-[200px] p-1 font-sans transition-colors disabled:cursor-not-allowed disabled:text-opacity-30'
                )}>
                <Icon.ChevronDown className="h-3 w-3" aria-hidden="true" />
              </span>
            </Combobox.Button>
          </div>
          {query && query.length < minQueryLength ? (
            <>
              {showHint ? (
                <Typography variant="meta-2" color="grey" className="ml-2">
                  {hint}
                </Typography>
              ) : null}
            </>
          ) : null}
          {additionalInfo ? (
            <Typography element="p" variant="meta-3" className="mt-1">
              {additionalInfo}
            </Typography>
          ) : null}
          {showOptions ? (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setInputQuery('')}>
              <Combobox.Options className="ring-black absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-grey shadow-lg ring-1 ring-opacity-5 hover:cursor-pointer focus:outline-none sm:text-sm">
                {filteredData?.length === 0 && inputQuery !== '' ? (
                  <>
                    {customSearchWhenNotFound ? (
                      <div
                        onClick={() => {
                          inputQuery && onSearchWhenNotFound && onSearchWhenNotFound(inputQuery);
                        }}
                        className="relative cursor-default select-none px-4 py-2 text-grey hover:cursor-pointer">
                        Search result for &quot;{inputQuery}&quot;
                      </div>
                    ) : (
                      <div className="relative cursor-default select-none px-4 py-2 text-grey">
                        {loadingOptions ? 'Loading ...' : 'No results found'}
                      </div>
                    )}
                  </>
                ) : (
                  filteredData?.map((data) => (
                    <Combobox.Option
                      key={data.value}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 hover:cursor-pointer ${
                          active
                            ? 'bg-indigo-reduced bg-opacity-30 pr-4 text-grey'
                            : 'text-gray-900'
                        }`
                      }
                      value={data}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}>
                            {data.label}
                          </span>
                          {selected && (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-grey' : 'text-teal-600'
                              }`}>
                              <Icon.CheckList className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          ) : null}
        </div>
      </Combobox>
    </div>
  );
}
