import { Modal } from '@common/components/molecules/Modal';
import { ProgressBar } from '@pages/Client/Calendar/components/ProgressBar';

interface PriceCalculationLoadingModalProps {
  open: boolean;
  progressMessage: string;
}

export const PriceCalculationLoadingModal = ({
  open,
  progressMessage
}: PriceCalculationLoadingModalProps) => {
  return (
    <Modal
      onClose={() => {}}
      hideCloseButton
      open={open}
      size="lg"
      showFooter={false}
      className="z-[9999] rounded-b-xl">
      <img src="/images/price_calculation_new.gif" style={{ margin: 'auto' }} />

      <div className="mt-5">
        <ProgressBar />
        <span className="text-meta-1">{progressMessage}</span>
      </div>
    </Modal>
  );
};
