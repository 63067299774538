export enum NavUserflowIds {
  REPORTING_MENU_ITEM = 'nav-reporting-menu-item',
  MARKET_MENU_ITEM = 'nav-market-menu-item',
  CALENDAR_MENU_ITEM = 'nav-calendar-menu-item',
  CHART_MENU_ITEM = 'nav-chart-menu-item',
  PRICING_STRATEGY_MENU_ITEM = 'nav-pricing-strategy-menu-item',
  USER_PROFILE_MENU_ITEM = 'nav-user-profile-menu-item'
}

export enum CalendarUserflowIds {
  PRICES_CALENDAR_NAV_ITEM = 'calendar-prices-nav-item',
  OCCUPANCY_CALENDAR_NAV_ITEM = 'calendar-occupancy-nav-item',
  PICKUP_CALENDAR_NAV_ITEM = 'calendar-pickup-nav-item',
  REVENUE_CALENDAR_NAV_ITEM = 'calendar-revenue-nav-item',
  CALENDAR_VIEW_ITEM = 'calendar-view-item',
  ADJUSTMENT_SELECT_ITEM = 'calendar-adjustment-select-item',
  CALENDAR_WEEK_VIEW_ITEM = 'calendar-week-view-item'
}

export enum PriceDrawerUserflowIds {
  DRAWER_CONTAINER = 'price-drawer-container',
  OVERVIEW_TAB_ITEM = 'price-drawer-overview-tab-item',
  EDIT_PRICES_TAB_ITEM = 'price-drawer-edit-prices-tab-item',
  ALL_ROOM_TYPES_TAB_ITEM = 'price-drawer-all-room-types-tab-item',
  PRICE_EVOLUTION_TAB_ITEM = 'price-drawer-price-evolution-tab-item',
  PERCENT_ADJUSTMENT_TAB_ITEM = 'price-drawer-percent-adjustment-tab-item',
  MIN_MAX_PRICES_TAB_ITEM = 'price-drawer-min-max-prices-tab-item',
  FIX_PRICES_TAB_ITEM = 'price-drawer-fix-prices-tab-item',
  MIN_STAY_TAB_ITEM = 'price-drawer-min-stay-tab-item',
  PREFILL_DERIVED_ROOM_SWITCHER = 'price-drawer-prefill-derived-room-switcher',
  SAVE_PRICES_BUTTON = 'price-drawer-save-prices-button',
  REMOVE_ADJUSTMENTS_BUTTON = 'price-drawer-remove-adjustments-button',
  UPLOAD_PRICES_BUTTON = 'price-drawer-upload-prices-button',
  CANCEL_BUTTON = 'price-drawer-cancel-button',
  BASE_PRICE_TEXT = 'price-drawer-base-price-text',
  MARKET_FACTOR_TEXT = 'price-drawer-market-factor-text',
  OCCUPANCY_PICKUP_TEXT = 'price-drawer-occupancy-pickup-text',
  YOUR_ADJUSTMENTS_TEXT = 'price-drawer-your-adjustments-text',
  PRICE_IN_TEXT = 'price-drawer-price-in-text',
  PRICE_YESTERDAY_TEXT = 'price-drawer-price-yesterday-text',
  PRICE_7DAYS_AGO_TEXT = 'price-drawer-price-7days-ago-text',
  PRICE_PICKUP_MODEL_TEXT = 'price-drawer-price-pickup-model-text',
  EVENTS_TEXT = 'price-drawer-events-text'
}

export enum BulkEditUserflowIds {
  BULK_EDIT_BUTTON = 'bulk-edit-button',
  BULK_EDIT_ADJUSTMENTS_TAB_ITEM = 'bulk-edit-adjustments-tab-item',
  BULK_DISMISS_SURGE_EVENTS_TAB_ITEM = 'bulk-dismiss-surge-events-tab-item',
  EDIT_DATE_RANGE_SELECT = 'bulk-edit-date-range-select',
  WEEKDAY_CHIPS = 'bulk-edit-weekday-chips',
  ADJUSTMENT_TYPE_SELECT = 'bulk-edit-adjustments-type-select',
  SAVE_BUTTON = 'bulk-edit-save-button',
  CANCEL_BUTTON = 'bulk-edit-cancel-button',
  BULK_REMOVE_ADJUSTMENTS_TAB_ITEM = 'bulk-remove-adjustments-tab-item',
  REMOVE_BUTTON = 'bulk-remove-remove-button',
  ADJUSTMENT_OPTION_PREFIX = 'bulk-edit-adjustment'
}

export enum ChartUserflowIds {
  PRICE_CHART_CONTAINER = 'chart-price-chart-container',
  ROOM_TYPE_SELECT = 'chart-room-type-select'
}
