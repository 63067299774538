import { Flags } from '@common/constants/flags';
import { getEnvVar } from '@common/utils/env';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

declare global {
  interface Window {
    DigitalHawkChatbot_id: string;
    DigitalHawkChatbot_baseUrl: string;
    DigitalHawkChatbot_clientName: string;
    DigitalHawkChatbot_displayMode: string;
    DigitalHawkChatbot_additionalData: Record<string, any>;
    DigitalHawkChatbot_responsive: boolean;
    DigitalHawkChatbot: (selector: string) => void;
  }
}

interface AdditionalChatData {
  accountId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  location?: string;
  planTier?: string;
  partnerSubscription?: boolean;
  provider?: string;
  language?: string;
  hotelId?: number;
}

export const useAIChatBot = ({
  additionalData,
  enabled
}: {
  additionalData: AdditionalChatData;
  enabled: boolean;
}) => {
  const flags = useFlags();

  const isAIChatBotAvailable = flags[Flags.AIChatBot];

  useEffect(() => {
    if (!isAIChatBotAvailable || !enabled) return;

    const timestamp = new Date().getTime();

    // Create and append the CSS link
    const cssLink = document.createElement('link');
    cssLink.rel = 'stylesheet';
    cssLink.href = `${getEnvVar('VITE_AI_CHATBOT_BASE_URL')}/widget.css?v=${timestamp}`;
    document.head.appendChild(cssLink);

    // Create and append the script
    const script = document.createElement('script');
    script.src = `${getEnvVar('VITE_AI_CHATBOT_BASE_URL')}/widget.js?v=${timestamp}`;
    script.async = true;
    script.onload = () => {
      if (typeof window.DigitalHawkChatbot === 'function') {
        window.DigitalHawkChatbot_id = getEnvVar('VITE_AI_CHATBOT_CHAT_ID');
        window.DigitalHawkChatbot_baseUrl = `${getEnvVar('VITE_AI_CHATBOT_BASE_URL')}/`;
        window.DigitalHawkChatbot_clientName = getEnvVar('VITE_AI_CHATBOT_CLIENT_NAME');
        window.DigitalHawkChatbot_displayMode = 'floating';
        window.DigitalHawkChatbot_additionalData = additionalData || {};
        window.DigitalHawkChatbot_responsive = false;

        const digitalhawk_chatbot_container = document.createElement('div');
        digitalhawk_chatbot_container.id = 'digitalhawk-chatbot-container';
        document.body.appendChild(digitalhawk_chatbot_container);

        window.DigitalHawkChatbot('#digitalhawk-chatbot-container');
      }
    };

    document.body.appendChild(script);

    // Cleanup function to remove the script and CSS link
    return () => {
      document.head.removeChild(cssLink);
      document.body.removeChild(script);
    };
  }, [isAIChatBotAvailable, enabled]);
};
