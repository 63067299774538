import { axiosInstance, setAuthToken as setAxiosToken } from '@common/api/axiosInstance';
import {
  LoginResponse,
  AccountError,
  LoginInput,
  loginUser,
  OTPResponse,
  iframeLogin
} from '@common/api/auth';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';

export const useLoginUser = () => {
  const { t } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth } = useAuthStore();

  return useMutation<LoginResponse, AccountError, LoginInput>({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      // Only For Client Users
      if (isEmpty(data.hotel_list) && !data.user.is_staff && isNil(data.user.sub_account_type)) {
        addNotification('fail', t('You don`t have permission to access any hotel'));
        return;
      }
      // Set token for all axios requests
      setAxiosToken(data.token);

      setAuth(data.token, data.user);

      if (data.user.is_staff) {
        await axiosInstance.post<OTPResponse>('/account/two-factor-auth/resend_otp/');
        addNotification('success', t('Sending OTP Successful'));
      } else {
        addNotification('success', t('Login Successful'));
      }
    },
    onError: () => {
      addNotification('fail', t('Login Failed'));
    }
  });
};

export const useIframeLogin = (
  token: string | null,
  hotel_id: string | null,
  language: string | null
) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { setAuth, setHotel } = useAuthStore();

  // Set token for all axios requests
  setAxiosToken(token);

  return useMutation<Omit<LoginResponse, 'token'>, AccountError>({
    mutationFn: iframeLogin,
    onSuccess: async (data) => {
      setAuth(token, data.user);
      i18n.changeLanguage(language ? language : 'en');
      setHotel(+hotel_id!);
      navigate(`/client/calendar/${hotel_id}/pricing`, { replace: true });
      addNotification('success', t('Login Successful'));
    },
    onError: () => {
      addNotification('fail', t('Login Failed'));
    }
  });
};
