import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getHotelRoomNotes } from '@common/api/hotel';
import dayjs from 'dayjs';
import { API_DATE_FORMAT } from '@common/constants/date';
import { NotesQueryKeys } from '@common/types/query-keys';
import { useHotelStore } from '@common/store/auth';

export const useHotelRoomNotes = () => {
  const { hotelAuthToken } = useHotelStore();
  const query = useQuery({
    queryKey: [NotesQueryKeys.GET_HOTEL_ROOM_NOTES],
    queryFn: getHotelRoomNotes,
    staleTime: Infinity,
    enabled: !!hotelAuthToken
  });

  const notes = useMemo(() => {
    return query.data?.data;
  }, [query.data]);

  const getNoteByDay = (day: Date) => {
    if (!notes) return null;

    const key = dayjs(day).format(API_DATE_FORMAT);

    return notes[key]?.[0]?.name ?? null;
  };

  return {
    notes,
    getNoteByDay
  };
};
