import { getHotelPmsList } from '@common/api/hotel';
import { useHotelStore } from '@common/store/auth';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useGetHotelPmsList = (enabled = true) => {
  const { hotelAuthToken } = useHotelStore();
  const query = useQuery({
    queryKey: [HotelQueryKeys.GET_HOTEL_PMS_LIST],
    queryFn: getHotelPmsList,
    enabled: enabled && !!hotelAuthToken
  });

  const pmsList = useMemo(() => {
    return query.data;
  }, [query]);

  return {
    query,
    pmsList
  };
};
