import { Table } from '@common/components/molecules/Table';
import { Tabs, usePriceDrawerStore } from '@common/store/priceDrawer';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { zodResolver } from '@hookform/resolvers/zod';
import { Badge, Box, Text } from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { RoomPriceDataTooltipTable } from '@pages/Client/PricingStrategy/SurgeProtection/components/RoomPriceDataTooltipTable';
import { useSurgeEventLogs } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

interface HeaderProps {
  id: string;
  desc: boolean;
}

const paginationSchema = z.object({
  page: z.number(),
  date: z.string().optional(),
  ordering: z.string().optional()
});

type Params = z.infer<typeof paginationSchema>;

export const SurgeEventLog = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Surge Protection Event Logs'));
  const { setDrawerState, setViewingDate, setActiveTab } = usePriceDrawerStore();
  const { hotelDetails } = useHotelDetails();
  const {
    pricingSettings,
    pricingSettingsQuery: { isLoading: pricingSettingsIsLoading }
  } = usePricingSettings();
  const { cachePriceQuery } = useRoomPrices();
  const { data: cachePrice, isLoading: cachePriceLoading } = cachePriceQuery;
  const defaultValues = {
    page: 1,
    ordering: '-active,-timestamp'
  };

  const { watch, reset, setValue } = useForm<z.infer<typeof paginationSchema>>({
    defaultValues,
    resolver: zodResolver(paginationSchema)
  });

  const { surgeLogs, isLoading, isFetching } = useSurgeEventLogs(
    filterEmptyValues<Params>({
      page: watch('page'),
      ordering: watch('ordering')
    })
  );

  const ROWS_PER_PAGE = 20;

  const startIndex = surgeLogs?.current ? (surgeLogs.current - 1) * ROWS_PER_PAGE + 1 : 0;
  const endIndex =
    surgeLogs?.current && surgeLogs.results
      ? startIndex + (surgeLogs?.results?.length || 0) - 1
      : 0;

  const handleNext = () => {
    if (!surgeLogs?.next) return;
    const params = new URLSearchParams(surgeLogs.next.split('?')[1]);
    const page = params.get('page');
    const ordering = params.get('ordering');
    if (!page) return;
    reset(
      filterEmptyValues({
        page: Number(page),
        ordering: ordering || undefined
      })
    );
  };

  const handlePrevious = () => {
    const currentPage = watch('page');
    const previousPage = currentPage - 1;
    if (previousPage <= 0) return;

    reset(
      filterEmptyValues({
        ...defaultValues,
        page: previousPage
      })
    );
  };

  // Reference Room Id
  const referenceRoomId = pricingSettings?.rooms?.reference?.id;

  const priceCacheData = cachePrice?.data.prices.data;

  const surgeLogsData =
    surgeLogs && priceCacheData && referenceRoomId
      ? surgeLogs.results.map((item) => {
          return {
            ...item,
            reference: CurrencyFormatter.format(
              Math.round(priceCacheData?.[item.date]?.[referenceRoomId]?.price)
            ),
            occupancy: priceCacheData[item.date]?.property?.occupancy
          };
        })
      : [];

  const surgeLogsColumn: any[] = [
    {
      header: t('Surge Date'),
      accessorKey: 'date',
      cell: (row: any) => {
        return row?.getValue();
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Triggered Date'),
      accessorKey: 'timestamp',
      cell: (row: any) => {
        return dayjs(row?.getValue()).format('YYYY-MM-DD HH:mm:ss');
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Status'),
      accessorKey: 'active',
      cell: (row: any) => {
        const isActive = row?.getValue();
        return (
          <Badge color={isActive ? 'indigo' : 'green.9'} tt="capitalize">
            {isActive ? t('Not Reviewed') : t('Reviewed')}
          </Badge>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Current Reference {{roomOrApartment}} Price', {
        roomOrApartment: hotelDetails?.room_apartment_space_name
      }),
      accessorKey: 'reference',
      cell: (row: any) => {
        return (
          <>
            {row?.getValue()} <RoomPriceDataTooltipTable date={row.row.original.date} />
          </>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: t('Occupancy'),
      accessorKey: 'occupancy',
      cell: (row: any) => {
        return row?.getValue() ? `${row?.getValue()}%` : '-';
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    }
  ];

  return (
    <Box mt="xl">
      <Table
        isHover
        isStriped
        isRowClickable
        isFetching={isLoading || isFetching || pricingSettingsIsLoading || cachePriceLoading}
        skeletonCount={20}
        columns={surgeLogsColumn}
        data={surgeLogsData || []}
        next={handleNext}
        isNext={!!surgeLogs?.next}
        previous={handlePrevious}
        isPrevious={watch('page') > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={surgeLogs?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        manualSorting={true}
        onClickRow={(row) => {
          const date = row.row.original.date;
          setViewingDate(date);
          setDrawerState(true);
          setActiveTab(Tabs.EditPrices);
        }}
        onSortingChange={(value) => {
          const values = value as HeaderProps[];
          if (watch('ordering') === values[0].id) {
            setValue('ordering', `-${values[0].id}`);
          } else {
            setValue('ordering', values[0].id);
          }
        }}
        sorting={[
          {
            id: 'date',
            desc: watch('ordering') === 'date'
          }
        ]}
        headerComponent={
          <Text size="lg" c="dark">
            {t('Surge Protection Event Logs')}
          </Text>
        }
      />
    </Box>
  );
};
