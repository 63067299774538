export function NotificationOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      {...props}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.88 14.88C18.3173 14.3179 18.0007 13.5554 18 12.76V10C18 8.70178 17.579 7.43858 16.8 6.4L15.9 5.2C15.3335 4.44458 14.4443 4 13.5 4H13V2.5C13 2.22386 12.7762 2 12.5 2H11.5C11.2239 2 11 2.22386 11 2.5V4H10.5C9.55575 4 8.66658 4.44458 8.10002 5.2L7.20002 6.4C6.42109 7.43858 6.00002 8.70178 6.00002 10V12.76C5.99932 13.5554 5.68279 14.3179 5.12002 14.88L4.29002 15.71C4.10528 15.8963 4.00113 16.1477 4.00002 16.41V17C4.00002 17.5523 4.44774 18 5.00002 18H19C19.5523 18 20 17.5523 20 17V16.41C19.9989 16.1477 19.8948 15.8963 19.71 15.71L18.88 14.88ZM16 16H8.00002V10C8.00002 9.13452 8.28073 8.29238 8.80002 7.6L9.70002 6.4C9.88888 6.14819 10.1853 6 10.5 6H13.5C13.8148 6 14.1112 6.14819 14.3 6.4L15.2 7.6C15.7193 8.29238 16 9.13452 16 10V16ZM14 20C14 21.1046 13.1046 22 12 22C10.8955 22 10 21.1046 10 20H14ZM5.59002 5.21C5.70931 5.05475 5.68266 4.83262 5.53002 4.71L4.53002 3.93C4.44641 3.86047 4.3381 3.82797 4.23002 3.84C4.13534 3.86112 4.05275 3.91858 4.00002 4C2.80806 5.58596 2.11216 7.48923 2.00002 9.47C1.98998 9.60792 2.04119 9.74327 2.14002 9.84C2.2316 9.94239 2.36265 10.0006 2.50002 10H3.50002C3.76651 9.99523 3.98439 9.78607 4.00002 9.52C4.1041 7.96023 4.6562 6.46367 5.59002 5.21ZM19.73 3.84C19.8389 3.85194 19.9373 3.91026 20 4C21.1878 5.58824 21.8832 7.4902 22 9.47C21.9981 9.60777 21.9446 9.73981 21.85 9.84C21.757 9.94035 21.6269 9.99818 21.49 10H20.49C20.2254 9.99492 20.0106 9.78447 20 9.52C19.8814 7.95503 19.3117 6.45798 18.36 5.21C18.2407 5.05475 18.2674 4.83262 18.42 4.71L19.42 3.93C19.5082 3.86181 19.619 3.82964 19.73 3.84Z"
        fill="currentColor"
      />
    </svg>
  );
}
