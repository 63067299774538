import { ErrorResponse } from '@common/api/auth';
import { Country, Currency } from '@common/api/hotel/types';
import { getCountryList, getCurrencyList } from '@common/api/system';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { map } from 'lodash-es';
import { useMemo } from 'react';

export const useCountryList = () => {
  const query = useQuery<Country[], ErrorResponse>({
    queryKey: [UtilQueryKeys.GET_COUNTRY_LIST],
    queryFn: getCountryList
  });

  const countryList = useMemo(() => {
    return query.data;
  }, [query.data]);

  const selectCountryList = useMemo(() => {
    const output = map(countryList, (country) => ({
      value: String(country.id),
      label: `${country.name} (${country.abbreviation})`
    }));
    return output;
  }, [countryList]);

  return { countryList, selectCountryList, query };
};

export const useCurrencyList = () => {
  const query = useQuery<Currency[], ErrorResponse>({
    queryKey: [UtilQueryKeys.GET_CURRENCY_LIST],
    queryFn: getCurrencyList
  });

  const currencyList = useMemo(() => {
    return query.data;
  }, [query.data]);

  return { currencyList, query };
};
