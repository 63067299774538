import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface PropertyHeaderProps {
  onClick: (data: any) => void;
  disabled?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
}

export const PropertyHeader = ({
  onClick,
  disabled,
  isLoading,
  isSuccess
}: PropertyHeaderProps) => {
  const { t } = useTranslation();
  const tabs = [
    { name: t('Name and Location'), href: 'name-and-location', value: 0 },
    { name: t('Property Type'), href: 'property-type', value: 1 }
  ];

  return (
    <div>
      <div className="mb-8 bg-appBackground ">
        <div className="flex items-center justify-between">
          <div>
            <Typography element="h1" variant="h3" color="darkGrey">
              {t('Property')}
            </Typography>
            <Typography element="p" variant="paragraph-3" color="copyTextGrey">
              {t('Manage your property preferences.')}
            </Typography>
          </div>
          <div>
            <Button
              size="large"
              isLoading={isLoading}
              disabled={disabled}
              isSuccess={isSuccess}
              intent="primary"
              onClick={onClick}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex h-12 gap-4 border-b-2 border-darkGrey border-opacity-10">
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            className={({ isActive }) =>
              isActive
                ? 'border-b-[3px] border-indigo py-1 font-medium text-indigo'
                : 'inline-block py-1 text-grey'
            }
            to={tab.href}>
            {tab.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
