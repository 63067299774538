import { cn } from '@common/utils/cn';
import React from 'react';

interface SkeletonProps {
  className: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({ className }: SkeletonProps) => {
  return (
    <div
      data-testid="skeletonElement"
      className={cn(
        'py-1/8 block w-full animate-pulse rounded-sm bg-grey bg-opacity-30',
        className
      )}></div>
  );
};
