import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { useLogout } from '@common/store/auth';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Button } from '@common/components/atoms/Button';

interface Props {
  isLogout?: boolean;
}

export const SnapError: React.FC<Props> = ({ isLogout }) => {
  const routeError = useRouteError() as Error;
  const { t } = useTranslation();
  const logoutUser = useLogout();

  if (routeError) {
    Sentry.setTags({
      isSnapError: true
    });
  }

  return (
    <div className="mt-10 flex justify-center">
      <div className="flex max-w-md flex-1 flex-col items-center justify-center rounded-md border-2 border-dashed border-grey-reduced p-10">
        <img src="/genie-working.gif" className="max-h-64" />
        <div className="text-left">
          <Typography variant="h5" element="h3" className="text-darkGrey text-opacity-80">
            {t('Oh Snap!')}
          </Typography>
          <Typography className="mt-4">
            {t(
              "We're sorry, but something went wrong. Our team already got a notification and we will investigate as soon as possible. Please find the chat button on the bottom right corner to contact us, if there's anything we can do to help you in the meantime."
            )}
          </Typography>
        </div>
        {isLogout ? (
          <div className="mt-10">
            <Button type="button" onClick={logoutUser} intent="outline">
              <Icon.SignOut className="h-4 w-4" />
              Logout
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
