import { Icon } from '@common/components/foundations/icons';
import { Stack, Group, ActionIcon, Title, Button } from '@mantine/core';

export const Buttons = () => {
  return (
    <Stack py="xs">
      <Stack>
        <Title order={2} size="h3">
          ActionIcon
        </Title>
        <Group gap="xs">
          <ActionIcon>
            <Icon.Add />
          </ActionIcon>
          <ActionIcon variant="default">
            <Icon.Add />
          </ActionIcon>
          <ActionIcon variant="light">
            <Icon.Add />
          </ActionIcon>
          <ActionIcon variant="subtle">
            <Icon.Add />
          </ActionIcon>
          <ActionIcon variant="outline">
            <Icon.Add />
          </ActionIcon>
          <ActionIcon.Group>
            <ActionIcon variant="default">
              <Icon.Heart className="h-4 w-4" />
            </ActionIcon>
            <ActionIcon variant="default">
              <Icon.AllDone className="h-4 w-4" />
            </ActionIcon>
          </ActionIcon.Group>
        </Group>
      </Stack>
      <Stack>
        <Title order={2} size="h3">
          Button
        </Title>
        <Group gap="xs">
          <Button>Button</Button>
          <Button variant="default">Button</Button>
          <Button variant="light">Button</Button>
          <Button variant="subtle">Button</Button>
          <Button variant="outline">Button</Button>
          <Button loading>Button</Button>
        </Group>
      </Stack>
    </Stack>
  );
};
