import React from 'react';

import { useAppFlags } from '@common/hooks/useAppFlags';
import DerivedRatesV1 from './v1/DerivedRatesV1';
import DerivedRatesV2 from './v2/DerivedRatesV2';

const DerivedRates: React.FC = () => {
  const { isDerivedRatesV2Enabled } = useAppFlags();

  if (isDerivedRatesV2Enabled) {
    return <DerivedRatesV2 />;
  }

  return <DerivedRatesV1 />;
};

export default DerivedRates;
