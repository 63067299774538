import { Outlet } from 'react-router-dom';
import { ScheduleReportsHeader } from './components/ScheduleReportsHeader';

export const ScheduleReportsLayout = () => {
  return (
    <>
      <ScheduleReportsHeader />
      <Outlet />
    </>
  );
};
