import { Returns } from 'use-lilius';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  AnalyticsDashboardFilters,
  AnalyticsDashboardType,
  HotelAnalyticsFilters,
  HotelPropertyFilters
} from '@common/api/dashboard/types';
import { isEmpty, omit } from 'lodash-es';
import {
  ANALYTICS_COMPARE_OPTIONS,
  OCCUPANCY_TYPE_OPTIONS
} from '@pages/Client/Calendar/constants';

export type PerformanceFilter = (typeof PERFORMANCE_OPTIONS)[number]['value'];
export type AnalyticsFilter = (typeof ANALYTICS_OPTIONS)[number]['value'];
export type CompareToFilter = (typeof ANALYTICS_COMPARE_OPTIONS)[number]['value'];
export type OccupancyTypeFilter = (typeof OCCUPANCY_TYPE_OPTIONS)[number]['value'];
export type LastYearFilter = (typeof LAST_YEAR_OPTIONS)[number]['value'];

export const PERFORMANCE_OPTIONS = [
  {
    value: 'grossValuesExclExtraServices',
    label: 'Gross excl. Extra Services'
  },
  {
    value: 'grossValuesInclExtraServices',
    label: 'Gross incl. Extra Services'
  },
  {
    value: 'netValuesExclExtraServices',
    label: 'Net excl. Extra Services'
  },
  {
    value: 'netValuesInclExtraServices',
    label: 'Net incl. Extra Services'
  }
];

export const ANALYTICS_OPTIONS = [
  {
    value: 'weekday',
    label: 'Weekday Analytics'
  },
  {
    value: 'roomType',
    label: 'Room Type Analytics'
  },
  {
    value: 'channel',
    label: 'Channel Analytics'
  },
  {
    value: 'segmentTag',
    label: 'Segment Tag Analytics'
  }
];

type AnalyticsTypeMap = {
  [key: string]: AnalyticsDashboardType;
  weekday: AnalyticsDashboardType;
  roomType: AnalyticsDashboardType;
  channel: AnalyticsDashboardType;
  segmentTag: AnalyticsDashboardType;
  multiProperty: AnalyticsDashboardType;
};

export const ANALYTICS_TYPE_MAP: AnalyticsTypeMap = {
  weekday: 'day_of_the_week',
  roomType: 'room_type',
  channel: 'channel_name',
  segmentTag: 'segment_id',
  multiProperty: 'multi_property'
};

export const LAST_YEAR_OPTIONS = [
  {
    value: 'DOW',
    label: 'Day-of-Week'
  },
  {
    value: 'DTD',
    label: 'Date-to-Date'
  }
];

interface DashboardPageStore {
  /**
   * Dashboard instance for all Dashboard pages
   */
  liliusInstance?: Returns;
  setLiliusInstance: (instance: DashboardPageStore['liliusInstance']) => void;
  view?: 'monthly' | 'yearly';
  setView: (view: DashboardPageStore['view']) => void;
  performanceFilter: PerformanceFilter;
  analyticsFilter: AnalyticsFilter;
  compareToFilter: CompareToFilter;
  occupancyTypeFilter: OccupancyTypeFilter;
  lastYearFilter: LastYearFilter;
  advancedAnalyticsFilters?: HotelAnalyticsFilters;
  propertyIds: HotelPropertyFilters;
  setPerformanceFilter: (option: DashboardPageStore['performanceFilter']) => void;
  setAnalyticsFilter: (option: DashboardPageStore['analyticsFilter']) => void;
  setCompareToFilter: (option: DashboardPageStore['compareToFilter']) => void;
  setOccupancyTypeFilter: (option: DashboardPageStore['occupancyTypeFilter']) => void;
  setLastYearFilter: (option: DashboardPageStore['lastYearFilter']) => void;
  setAdvancedAnalyticsFilters: (filters: DashboardPageStore['advancedAnalyticsFilters']) => void;
  updateHotelAnalyticsFilters: (hotelId: number, filters: AnalyticsDashboardFilters) => void;
  setPropertyIds: (option: DashboardPageStore['propertyIds']) => void;
  updatePropertyIds: (hotelId: number, propertyIds: number[]) => void;
}

export const useDashboardPageStore = create<DashboardPageStore>()(
  persist(
    (set, get) => ({
      view: 'monthly',
      setLiliusInstance: (instance) => {
        set({
          liliusInstance: instance
        });
      },
      setView: (view) => {
        const currentLiliusInstance = get().liliusInstance;
        if (!currentLiliusInstance) return;
        set({
          view,
          liliusInstance: {
            ...currentLiliusInstance,
            viewing: new Date()
          }
        });
      },
      performanceFilter: PERFORMANCE_OPTIONS[0].value,
      analyticsFilter: ANALYTICS_OPTIONS[0].value,
      lastYearFilter: LAST_YEAR_OPTIONS[0].value,
      compareToFilter: ANALYTICS_COMPARE_OPTIONS[0].value,
      occupancyTypeFilter: OCCUPANCY_TYPE_OPTIONS[1].value,
      advancedAnalyticsFilters: {},
      propertyIds: {},

      setPerformanceFilter: (option) => set({ performanceFilter: option }),
      setAnalyticsFilter: (option) => set({ analyticsFilter: option }),
      setCompareToFilter: (option) => set({ compareToFilter: option }),
      setOccupancyTypeFilter: (option) => set({ occupancyTypeFilter: option }),
      setLastYearFilter: (option) => set({ lastYearFilter: option }),
      setAdvancedAnalyticsFilters: (filters) => set({ advancedAnalyticsFilters: filters }),
      updateHotelAnalyticsFilters: (hotelId: number, newFilters: AnalyticsDashboardFilters) => {
        set((state) => {
          const prevFilters = state.advancedAnalyticsFilters || {};

          if (isEmpty(newFilters)) {
            return {
              advancedAnalyticsFilters: omit(prevFilters, hotelId.toString())
            };
          }
          return {
            advancedAnalyticsFilters: {
              ...prevFilters,
              [hotelId]: newFilters
            }
          };
        });
      },
      setPropertyIds: (option) => set({ propertyIds: option }),
      updatePropertyIds: (hotelId: number, newIds: number[]) => {
        set((state) => {
          const prevIds = state.propertyIds || {};

          if (isEmpty(newIds)) {
            return {
              propertyIds: omit(prevIds, hotelId.toString())
            };
          }
          return {
            propertyIds: {
              ...prevIds,
              [hotelId]: newIds
            }
          };
        });
      }
    }),
    {
      name: 'dashboardPageStore',
      onRehydrateStorage: () => {
        return (state) => {
          if (state?.liliusInstance?.viewing) {
            state.liliusInstance.viewing = new Date(state.liliusInstance.viewing);
          }
          state?.setLiliusInstance(state?.liliusInstance);
          state?.setView(state?.view);
          state?.setPerformanceFilter(state?.performanceFilter);
          state?.setAnalyticsFilter(state?.analyticsFilter);
          state?.setCompareToFilter(state?.compareToFilter);
          state?.setOccupancyTypeFilter(state?.occupancyTypeFilter);
          state?.setLastYearFilter(state?.lastYearFilter);
          state?.setAdvancedAnalyticsFilters(state?.advancedAnalyticsFilters);
          state?.setPropertyIds(state?.propertyIds);
        };
      }
    }
  )
);
