import { create } from 'zustand';
interface SelectedPartner {
  selectedPartnerId: number | null;
  setSelectedPartnerId: (newId: number | null) => void;
}

export const useSelectedPartnerStore = create<SelectedPartner>((set) => ({
  selectedPartnerId: null,
  setSelectedPartnerId: (newId: number | null) => set({ selectedPartnerId: newId })
}));
