import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { API_DATE_FORMAT } from '@common/constants/date';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { utcToZonedTime } from 'date-fns-tz';
import { isSameDay } from 'date-fns';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { cn } from '@common/utils/cn';
import {
  TABLE_VIEW_PROPERTY_FIELDS,
  TABLE_VIEW_ROOM_FIELDS
} from '@pages/Client/Calendar/constants';
import { useHotelEvents } from '@pages/Client/Calendar/hooks/useHotelEvents';
import { useHotelRoomNotes } from '@pages/Client/Calendar/hooks/useHotelRoomNotes';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { isNumber } from 'lodash-es';
import React from 'react';
import { smartTruncate } from '@common/utils/smartTruncate';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useMinStayVisualiser } from '@pages/Client/Calendar/hooks/useMinStayVisualiser';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useMinStayValue } from '@pages/Client/Calendar/hooks/useMinStayValue';
import {
  SimpleGrid,
  Flex,
  Tooltip as MantineTooltip,
  ThemeIcon,
  Stack,
  rem,
  SemiCircleProgress,
  Text,
  Group
} from '@mantine/core';
import { priceTrendStatus } from '@pages/Client/Calendar/utils/priceTrendStatus';
import { IconBoltOff } from '@tabler/icons-react';
import { useFeaturesStore } from '@common/store/features';

interface Props {
  week: Date[];
  weeksInCalendar: Date[][];
}

export const EventList = ({ day, isToday }: { day: Date; isToday: boolean }) => {
  const { t } = useTranslation();
  const { events: unsortedEvents, isFetchingHotelDayEvents } = useHotelEvents(day);
  const events =
    unsortedEvents?.[dayjs(day).format(API_DATE_FORMAT)]?.sort(
      (a, b) => +b.local_rank - +a.local_rank
    ) ?? [];

  const trimmedEvents = useMemo(() => {
    return events.slice(0, 2);
  }, [events]);

  return (
    <div className="flex w-full flex-col gap-1">
      {trimmedEvents.length > 0 ? (
        <Typography variant="meta-3" color="grey">
          {t('Events')}
        </Typography>
      ) : null}
      {isFetchingHotelDayEvents ? (
        <div className="flex flex-col gap-2">
          {Array.from({ length: 2 }).map((_item, index) => (
            <Skeleton key={`event-skeleton-item-${index}`} className="h-3 w-full" />
          ))}
        </div>
      ) : null}
      {!isFetchingHotelDayEvents && trimmedEvents.length === 0 ? (
        <Typography variant="meta-3" color="grey">
          {t('No Events')}
        </Typography>
      ) : null}
      {trimmedEvents.map((event) => (
        <TooltipProvider
          key={`${event.title}-${event.local_rank}`}
          delayDuration={75}
          disableHoverableContent
        >
          <Tooltip>
            <TooltipTrigger className="inline-block cursor-default text-left">
              <Typography
                element="div"
                variant="meta-2"
                className={cn(isToday ? 'text-darkGrey' : 'text-copyTextGrey', 'truncate')}
              >
                {event.title}
              </Typography>
            </TooltipTrigger>
            <TooltipContent hidden={event.title.length < 30} className="max-w-xs">
              {event.title}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
    </div>
  );
};

export const TableView: React.FC<Props> = React.memo(({ week, weeksInCalendar }) => {
  const { t } = useTranslation();
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();
  const {
    weekIndex: weekIndexFromStore,
    minStayDays,
    setMinStayDays,
    visualMinStayDays,
    setVisualMinStayDays
  } = useCalendarPageStore();
  const { sortedHotelRooms, setSelectedHotelRoomId } = useHotelRoomsList();
  const [activeRoom, setActiveRoom] = useState<number | undefined>();
  const [activeDay, setActiveDay] = useState<string | undefined>();
  const { roomPrices, pricingSettings, isFull, isClosedRooms } = useRoomPrices();
  const { hotelDetails } = useHotelDetails();
  const { notes } = useHotelRoomNotes();
  const orange = useTailwindColor('orange');
  const grey = useTailwindColor('grey');
  const { minStaysVisualiser } = useMinStayVisualiser();
  const { getMinStayValue } = useMinStayValue();
  const { hasMinStayFeature } = useFeaturesStore();

  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();

  const tableViewRef = useRef<HTMLDivElement>(null);

  const mobileHeader = document.querySelector<HTMLDivElement>('.mobile-header');
  const calendarHeader = document.querySelector<HTMLDivElement>('.calendar-header');

  useEffect(() => {
    if (activeDay) {
      if (minStayDays?.[activeDay]) {
        setVisualMinStayDays(minStayDays?.[activeDay]?.dates.map((date) => date.date));
      } else {
        setVisualMinStayDays([]);
      }
    }
  }, [activeDay, minStayDays]);

  useEffect(() => {
    setVisualMinStayDays([]);
    setMinStayDays(minStaysVisualiser(activeRoom));
  }, [activeRoom]);

  useEffect(() => {
    const header = document.querySelector<HTMLDivElement>('.sticky-header');
    const sentinel = document.querySelector<HTMLDivElement>('.sentinel');

    const updateHeaderWidth = () => {
      if (header && tableViewRef.current) {
        header.style.width = `${tableViewRef.current.clientWidth}px`;
      }
    };

    const observerCallback: IntersectionObserverCallback = ([entry]) => {
      if (!header) return;

      if (!entry.isIntersecting) {
        // Sentinel is out of view, make header sticky and add resize listener
        header.style.position = 'fixed';
        header.style.top =
          ((mobileHeader?.clientHeight || 0) + (calendarHeader?.clientHeight || 0)) * 1.0005 + 'px';
        header.style.paddingTop = '0.5rem';
        header.style.paddingBottom = '0.5rem';
        updateHeaderWidth(); // Set the width to match the parent container
        window.addEventListener('resize', updateHeaderWidth);
      } else {
        // Sentinel is in view, reset header style and remove resize listener
        header.style.position = '';
        header.style.top = '';
        header.style.width = '';
        header.style.paddingTop = '';
        header.style.paddingBottom = '';
        window.removeEventListener('resize', updateHeaderWidth);
      }
    };

    const observer = new IntersectionObserver(observerCallback);

    // Observe the sentinel
    if (sentinel) observer.observe(sentinel);

    // Cleanup
    return () => {
      if (sentinel) observer.unobserve(sentinel);
      window.removeEventListener('resize', updateHeaderWidth);
    };
  }, []);

  const openPricingDrawer = (date: Date, roomId?: number) => {
    setSelectedHotelRoomId(roomId ?? pricingSettings?.rooms?.reference?.id);
    setViewingDate(date);
    setDrawerState(true);
  };

  return (
    <>
      <div className="sticky-header z-20 mb-2 bg-appBackground bg-opacity-80 backdrop-blur-sm">
        <SimpleGrid spacing={0} cols={8} miw="100%">
          <div />
          {isNumber(weekIndexFromStore) &&
            weeksInCalendar?.[weekIndexFromStore]?.map((day) => {
              // Current date in hotel timezone
              const currentDateInHotelTimezone = utcToZonedTime(
                new Date(),
                hotelDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
              );
              const isToday = isSameDay(day, currentDateInHotelTimezone);

              const formattedDate = dayjs(day).format(API_DATE_FORMAT);

              const isPastDate = dayjs(day).isBefore(dayjs().startOf('day'));

              return (
                <div
                  key={`weekday-container-${formattedDate}`}
                  className={cn(
                    'ml-4 transition-all',
                    activeDay === formattedDate
                      ? '-ml-0 rounded-sm bg-indigo bg-opacity-20 pl-4 transition-all duration-300'
                      : null,
                    activeRoom && activeDay === formattedDate && minStayDays?.[formattedDate]
                      ? 'bg-darkGreen-reduced bg-opacity-100 ring-2 ring-inset ring-darkGreen'
                      : null,
                    visualMinStayDays.includes(formattedDate)
                      ? 'mx-1 rounded-sm bg-darkGreen-reduced pl-4 transition-all duration-300'
                      : null
                  )}
                >
                  <Typography
                    className={cn(
                      isToday ? 'text-darkGrey' : isPastDate ? 'text-grey' : 'text-copyTextGrey'
                    )}
                  >
                    {dayjs(day).format('DD')} {isToday ? t('Today') : null}
                  </Typography>
                  <div className="flex w-full items-center justify-between">
                    <Typography
                      variant="meta-1"
                      className={cn(
                        isToday ? 'text-darkGrey' : isPastDate ? 'text-grey' : 'text-copyTextGrey'
                      )}
                    >
                      {dayjs(day).format('dd')}
                    </Typography>
                  </div>
                </div>
              );
            })}
        </SimpleGrid>
      </div>
      <div
        ref={tableViewRef}
        className="relative overflow-x-auto rounded-xl bg-white"
        onMouseLeave={() => setVisualMinStayDays([])}
      >
        {/* Sentinel - used to detect when the header is at the top */}
        <div className="sentinel" style={{ height: '1px' }} />

        <SimpleGrid spacing={0} cols={8} miw="100%">
          <div className="col-span-1 col-start-1">
            <div className="flex items-start justify-between gap-2 pb-1.5 pl-2 pr-2 pt-1 text-left lg:pl-4 lg:pr-3">
              <Typography color="darkGrey" className="font-medium" variant="meta-1">
                {t('Property')}{' '}
              </Typography>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger className="truncate text-right">
                    <Typography color="darkGrey" variant="meta-1" className="shrink-0 font-bold">
                      {pricingSettings?.hotel?.number_of_rooms}{' '}
                      {t(
                        `${hotelDetails?.room_apartment_space_name || ''}${
                          pricingSettings?.hotel?.number_of_rooms === 1 ? '' : 's'
                        }`
                      )}
                    </Typography>
                  </TooltipTrigger>
                  <TooltipContent side="top">
                    {pricingSettings?.hotel?.number_of_rooms}{' '}
                    {t(
                      `${hotelDetails?.room_apartment_space_name || ''}${
                        pricingSettings?.hotel?.number_of_rooms === 1 ? '' : 's'
                      }`
                    )}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>

          <div className="col-span-7 col-start-2">
            <SimpleGrid cols={7} spacing={0}>
              {week?.map((day, indexOfWeek) => {
                const roomData = {
                  ...roomPrices?.prices?.data?.[dayjs(day).format(API_DATE_FORMAT)]?.property
                };

                const displayData = (propertyField: string) => {
                  switch (propertyField) {
                    case TABLE_VIEW_PROPERTY_FIELDS.ROOMS_LEFT:
                      return `${roomData.inventory_remaining ?? '-'}`;
                    case TABLE_VIEW_PROPERTY_FIELDS.CLOSED_ROOMS:
                      return isClosedRooms(day)
                        ? t('Closed')
                        : isFull(day)
                          ? t('Full')
                          : `${roomData.closed_rooms ?? '-'}`;
                    case TABLE_VIEW_PROPERTY_FIELDS.OCCUPANCY:
                      return isClosedRooms(day) ? '' : `${roomData.occupancy ?? '-'}%`;
                    default:
                      break;
                  }
                };

                const getCellClassName = (day: Date) => {
                  if (!roomPrices?.prices.data) return '';

                  const dateKey = dayjs(day).startOf('day').format(API_DATE_FORMAT);
                  const propertyData = roomPrices.prices.data[dateKey]?.property;

                  if (!propertyData) return '';

                  const roomsSold = Math.max(
                    0,
                    propertyData.number_of_rooms - propertyData.inventory_remaining
                  );

                  if (propertyData.exproomssold_u3 != undefined) {
                    // occFontColor = "#595959";
                    switch (true) {
                      case roomsSold > propertyData.exproomssold_u3: // dark too many
                      case roomsSold > propertyData.exproomssold_u2: // mid
                        return 'bg-indigo bg-opacity-50 hover:bg-indigo hover:bg-opacity-20';
                      case roomsSold > propertyData.exproomssold_u1: // light
                        return 'bg-indigo bg-opacity-30 hover:bg-indigo hover:bg-opacity-20';
                      case roomsSold < propertyData.exproomssold_l3: // dark not enogh
                      case roomsSold < propertyData.exproomssold_l2: // mid
                        return 'bg-orange bg-opacity-50 hover:bg-orange hover:bg-opacity-20';
                      case roomsSold < propertyData.exproomssold_l1:
                        return 'bg-orange bg-opacity-30 hover:bg-orange hover:bg-opacity-20';
                    }
                  }

                  return '';
                };

                const getCellTextClassName = (day: Date) => {
                  if (!roomPrices?.prices.data) return '';

                  const dateKey = dayjs(day).startOf('day').format(API_DATE_FORMAT);
                  const propertyData = roomPrices.prices.data[dateKey]?.property;

                  if (!propertyData) return '';

                  const roomsSold = Math.max(
                    0,
                    propertyData.number_of_rooms - propertyData.inventory_remaining
                  );

                  if (propertyData.exproomssold_u3 != undefined) {
                    // occFontColor = "#595959";
                    switch (true) {
                      case roomsSold > propertyData.exproomssold_u3: // dark too many
                      case roomsSold > propertyData.exproomssold_u2: // mid
                        return 'text-indigo-darker font-medium';
                      case roomsSold > propertyData.exproomssold_u1: // light
                        return 'text-indigo-darker font-medium';
                      case roomsSold < propertyData.exproomssold_l3: // dark not enogh
                      case roomsSold < propertyData.exproomssold_l2: // mid
                        return 'text-orange-darker font-medium';
                      case roomsSold < propertyData.exproomssold_l1:
                        return 'text-orange-darker font-medium';
                    }
                  }

                  return '';
                };

                const displayLabel = {
                  [TABLE_VIEW_PROPERTY_FIELDS.CLOSED_ROOMS]: t('closed'),
                  [TABLE_VIEW_PROPERTY_FIELDS.ROOMS_LEFT]: t('left')
                };

                return (
                  <button
                    type="button"
                    onClick={() => openPricingDrawer(day)}
                    key={`property-column-${day}-${indexOfWeek}`}
                    className={cn(
                      'group border-r border-darkGrey-reduced first:border-l last:border-r-0 hover:bg-indigo-reduced hover:bg-opacity-20 hover:last:rounded-tr-xl',
                      isClosedRooms(day)
                        ? 'bg-gold bg-opacity-30 hover:bg-gold hover:bg-opacity-20'
                        : isFull(day)
                          ? ''
                          : getCellClassName(day)
                    )}
                  >
                    <div className="h-full">
                      <div className="z-20">
                        <Flex direction="column" px="xs" py={3}>
                          <Flex>
                            <Typography
                              variant="meta-1"
                              color="darkGrey"
                              className={cn('shrink-0', getCellTextClassName(day), 'pr-2')}
                            >
                              {displayData(TABLE_VIEW_PROPERTY_FIELDS.ROOMS_LEFT)}
                            </Typography>

                            <Typography
                              variant="meta-1"
                              color="darkGrey"
                              className={cn('shrink-0', getCellTextClassName(day))}
                            >
                              <Typography
                                variant="meta-2"
                                className={cn(getCellTextClassName(day))}
                              >
                                {displayLabel[TABLE_VIEW_PROPERTY_FIELDS.ROOMS_LEFT]}
                              </Typography>
                            </Typography>
                          </Flex>
                          <Flex justify="space-between">
                            <Flex justify="flex-start">
                              <Typography
                                variant="meta-1"
                                color="darkGrey"
                                className={cn(
                                  'shrink-0',
                                  isClosedRooms(day) || isFull(day) ? 'font-medium' : null,
                                  getCellTextClassName(day),
                                  'pr-2 xl:pr-0.5'
                                )}
                              >
                                <Typography
                                  variant="meta-1"
                                  color="darkGrey"
                                  className={cn('shrink-0', getCellTextClassName(day))}
                                >
                                  {displayData(TABLE_VIEW_PROPERTY_FIELDS.CLOSED_ROOMS)}
                                </Typography>
                              </Typography>

                              <Typography
                                variant="meta-1"
                                color="darkGrey"
                                className={cn(
                                  'shrink-0',
                                  isClosedRooms(day) || isFull(day) ? 'font-medium' : null,
                                  getCellTextClassName(day),
                                  'w-10 truncate xl:w-full'
                                )}
                              >
                                <Typography
                                  variant="meta-2"
                                  className={cn(getCellTextClassName(day))}
                                >
                                  {displayLabel[TABLE_VIEW_PROPERTY_FIELDS.CLOSED_ROOMS]}
                                </Typography>
                              </Typography>
                            </Flex>
                            <Flex>
                              <Typography
                                variant="meta-1"
                                color="darkGrey"
                                className={cn(
                                  'shrink-0',
                                  isClosedRooms(day) || isFull(day) ? 'font-medium' : null,
                                  getCellTextClassName(day)
                                )}
                              >
                                <Typography
                                  variant="meta-1"
                                  className={cn(getCellTextClassName(day))}
                                >
                                  {displayData(TABLE_VIEW_PROPERTY_FIELDS.OCCUPANCY)}
                                </Typography>
                              </Typography>
                            </Flex>
                          </Flex>
                        </Flex>
                      </div>
                    </div>
                  </button>
                );
              })}
            </SimpleGrid>
          </div>

          <div className="col-span-1 col-start-1 border-b border-darkGrey-reduced bg-indigo bg-opacity-[0.10]">
            <div className="pb-1.5 pl-2 pr-2 pt-1 text-left lg:pl-4 lg:pr-3">
              <Typography color="darkGrey" className="font-medium" variant="meta-1">
                {t('Notes & Events')}
              </Typography>
            </div>
          </div>

          <div className="col-span-7 col-start-2">
            <SimpleGrid
              cols={7}
              spacing={0}
              h="100%"
              w="100%"
              className="bg-indigo bg-opacity-[0.10]"
            >
              {week?.map((day) => {
                const currentDateInHotelTimezone = utcToZonedTime(
                  new Date(),
                  hotelDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                );
                const isToday = isSameDay(day, currentDateInHotelTimezone);

                const formattedDate = dayjs(day).format(API_DATE_FORMAT);

                return (
                  <button
                    type="button"
                    key={`event-list-${day}`}
                    onClick={() => openPricingDrawer(day)}
                    className={cn(
                      'flex flex-col items-start gap-1 border-b border-r border-darkGrey-reduced pb-2 pl-2 pr-2 pt-1 text-left first:border-l last:border-r-0 hover:bg-indigo-reduced hover:bg-opacity-20 lg:pl-4 lg:pr-3'
                    )}
                  >
                    {notes?.[formattedDate]?.length || 0 > 0 ? (
                      <div className="flex w-full flex-col gap-2">
                        <div className="flex w-full flex-col gap-1">
                          <Typography variant="meta-3" color="grey">
                            {t('Notes')}
                          </Typography>
                          {notes?.[formattedDate]?.map((note) => (
                            <TooltipProvider
                              key={`${note.id}-${note.name}`}
                              delayDuration={75}
                              disableHoverableContent
                            >
                              <Tooltip>
                                <TooltipTrigger>
                                  <Typography
                                    element="div"
                                    variant="meta-2"
                                    className={cn(
                                      isToday ? 'text-darkGrey' : 'text-copyTextGrey',
                                      'truncate text-left'
                                    )}
                                  >
                                    {note.name}
                                  </Typography>
                                </TooltipTrigger>
                                <TooltipContent hidden={note.name.length < 30} className="max-w-xs">
                                  {note.name}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    <EventList day={day} isToday={isToday} />
                  </button>
                );
              })}
            </SimpleGrid>
          </div>

          {sortedHotelRooms.map((hotelRoom, index) => {
            const numberOfRooms = hotelRoom.is_reference_room
              ? pricingSettings?.rooms?.reference?.number_of_rooms
              : pricingSettings?.rooms?.derived?.[hotelRoom.id]?.number_of_rooms;
            return (
              <Fragment key={`tableView-hotelRoom-roomName-${hotelRoom.id}`}>
                <div
                  className={cn(
                    'col-span-1 col-start-1',
                    index % 2 === 0 ? 'bg-white' : 'bg-indigo bg-opacity-[0.06]',
                    activeRoom === hotelRoom.id ? 'bg-indigo bg-opacity-20' : null
                  )}
                  onMouseEnter={() => setActiveRoom(hotelRoom.id)}
                  onMouseLeave={() => setActiveRoom(undefined)}
                >
                  <div className="pb-1.5 pl-2 pr-2 pt-1 text-left lg:pl-4 lg:pr-3 h-24 xl:h-20">
                    <div className="flex w-full items-start justify-between gap-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger className="text-left">
                            <Typography color="darkGrey" className="font-normal" variant="meta-1">
                              {smartTruncate(hotelRoom.name, 10, 10, 30)}
                            </Typography>
                          </TooltipTrigger>
                          <TooltipContent hidden={hotelRoom.name.length <= 30}>
                            {hotelRoom.name}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <Typography
                              color="darkGrey"
                              variant="meta-1"
                              className="shrink-0 font-bold"
                            >
                              ({numberOfRooms})
                            </Typography>
                          </TooltipTrigger>
                          <TooltipContent side="top">
                            {numberOfRooms}{' '}
                            {t(
                              `${hotelDetails?.room_apartment_space_name || ''}${
                                numberOfRooms === 1 ? '' : 's'
                              }`
                            )}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </div>
                </div>
                <div
                  key={`tableView-hotelRoom-${hotelRoom.id}`}
                  className={cn(
                    'col-span-7 col-start-2 ',
                    index % 2 === 0 ? 'bg-white' : 'bg-indigo bg-opacity-[0.06]'
                  )}
                >
                  <SimpleGrid spacing={0} h="100%" cols={7}>
                    {week?.map((day) => {
                      const roomFields = [
                        TABLE_VIEW_ROOM_FIELDS.ROOMS_LEFT,
                        TABLE_VIEW_ROOM_FIELDS.PRICE_IN_PMS,
                        TABLE_VIEW_ROOM_FIELDS.RECOMMENDED_PRICE
                      ];
                      const formatCurrency = CurrencyFormatter.format;

                      const dateKey = dayjs(day).format(API_DATE_FORMAT);

                      const roomData = {
                        ...roomPrices?.prices?.data?.[dateKey]?.[hotelRoom.id]
                      };

                      const isFixPrice =
                        !!pricingSettings?.dates?.[dateKey]?.[hotelRoom.id]?.fix_price;

                      const priceChange =
                        roomData.price &&
                        roomData.original_price &&
                        ((roomData.price - roomData.original_price) / roomData.original_price) *
                          100;

                      const leftRooms =
                        roomPrices?.prices.data?.[dateKey]?.property?.all_rooms_inventories?.[
                          hotelRoom.id
                        ];

                      const closedRooms =
                        roomPrices?.prices.data?.[dateKey]?.property?.all_closed_rooms?.[
                          hotelRoom.id
                        ];

                      const numberOfRooms = hotelRoom.is_reference_room
                        ? pricingSettings?.rooms?.reference?.number_of_rooms
                        : pricingSettings?.rooms?.derived?.[hotelRoom.id]?.number_of_rooms;

                      const isClosed = closedRooms === numberOfRooms;

                      function calculateRoomOccupancy(
                        numberOfRooms: number | undefined,
                        closedRooms: number | undefined,
                        leftRooms: number | undefined
                      ) {
                        // Ensure all inputs are defined and are numbers
                        if (
                          typeof numberOfRooms !== 'number' ||
                          typeof closedRooms !== 'number' ||
                          typeof leftRooms !== 'number'
                        ) {
                          return 0;
                        }

                        if (isClosed) {
                          return 100;
                        }

                        // Calculate the denominator
                        const availableRooms = numberOfRooms - closedRooms;

                        // If no rooms are available, return 0 occupancy
                        if (availableRooms <= 0) {
                          return 0;
                        }

                        // Calculate the number of occupied rooms
                        const occupiedRooms = numberOfRooms - leftRooms - closedRooms;

                        // Ensure occupied rooms count is not negative
                        if (occupiedRooms < 0) {
                          return 0;
                        }

                        // Calculate and return the occupancy percentage
                        return Math.round((occupiedRooms / availableRooms) * 100);
                      }

                      const roomOccupancy = calculateRoomOccupancy(
                        numberOfRooms,
                        closedRooms,
                        leftRooms
                      );

                      const { isSurgePrice, surgePrice } = getSurgePriceForDay(
                        dateKey,
                        hotelRoom.id
                      );

                      const minStay = minStayDays?.[dateKey];
                      const isSurgeEvent = getSurgeEventByDay(day)?.active;

                      const rawPrice = isSurgePrice && surgePrice ? surgePrice : roomData.price;

                      const trending =
                        priceChange && rawPrice
                          ? priceTrendStatus({
                              isLocked: isFixPrice || isSurgePrice,
                              priceChange,
                              rawPrice
                            })
                          : null;

                      const IconComponent = trending
                        ? trending === 'down'
                          ? Icon.TrendingDown
                          : trending === 'lock'
                            ? Icon.Lock
                            : Icon.TrendingUp
                        : undefined;

                      const displayData = (roomField: string) => {
                        switch (roomField) {
                          case TABLE_VIEW_ROOM_FIELDS.ROOMS_LEFT:
                            return `${leftRooms ?? 0}`;
                          case TABLE_VIEW_ROOM_FIELDS.RECOMMENDED_PRICE:
                            return (
                              <span
                                className={cn(
                                  'inline-flex items-center gap-1',
                                  trending === 'lock' ? 'flex items-center text-darkGrey' : null,
                                  trending === 'up' || trending === 'down'
                                    ? '-mr-1 ml-auto rounded-full  bg-opacity-100 px-1 py-0.5'
                                    : null,
                                  trending === 'up' ? 'bg-[#ebf7f3] text-uiGreen' : null,
                                  trending === 'down' ? 'bg-[#fbecef] text-uiRed' : null
                                )}
                              >
                                {isSurgeEvent ? (
                                  <Icon.Ripple className="h-6 w-6 text-uiGreen" />
                                ) : IconComponent ? (
                                  <IconComponent className="h-4 w-4" />
                                ) : null}
                                {formatCurrency(isSurgePrice ? surgePrice : roomData.price, '-')}
                              </span>
                            );
                          default:
                            break;
                        }
                      };

                      const formattedDate = dayjs(day).format(API_DATE_FORMAT);

                      const {
                        originalMinStay,
                        suggestedMinStay,
                        overrideMinStay,
                        shouldUseSuggestedMinStay,
                        isSkipMinStayRule,
                        hasOverrideMinStay
                      } = getMinStayValue(formattedDate, hotelRoom.id) ?? {};

                      const isPastDate = dayjs(day).isBefore(dayjs().startOf('day'));
                      const highlightColor = isPastDate ? grey : orange;

                      const overrideMinStayToZero = overrideMinStay ?? 0;
                      const originalMinStayToZero = originalMinStay ?? 0;

                      const renderHighlight = () =>
                        shouldUseSuggestedMinStay ? (
                          <span
                            className="ml-[3px] inline-flex items-center font-bold"
                            style={{ color: highlightColor }}
                          >
                            <Icon.Flash className="h-4 w-4" />
                            {suggestedMinStay === 0 ? t('None') : suggestedMinStay}
                          </span>
                        ) : null;

                      const renderPMSMinStay = (): string | null => {
                        const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'CM' : 'PMS';

                        if (originalMinStayToZero === 0) {
                          return t(`No ${pmsOrChannelManager} Min Stay`);
                        }

                        return t(`${pmsOrChannelManager} Min Stay {{originalMinStay}}`, {
                          originalMinStay: originalMinStayToZero
                        });
                      };

                      const renderMinStayLabel = () => {
                        return (
                          <>
                            {hasOverrideMinStay
                              ? overrideMinStayToZero !== 0
                                ? t('Min Stay')
                                : t('No Min Stay')
                              : t('Min Stay')}
                            <div
                              className={cn(
                                shouldUseSuggestedMinStay
                                  ? 'line-through decoration-orange decoration-2'
                                  : null
                              )}
                            >
                              {hasOverrideMinStay
                                ? overrideMinStayToZero === 0
                                  ? null
                                  : overrideMinStayToZero
                                : t('Not Set')}
                            </div>
                          </>
                        );
                      };

                      const renderSkipRuleIcon = () =>
                        isSkipMinStayRule ? (
                          <MantineTooltip label={t('Correction Ignored')}>
                            <ThemeIcon
                              size="xs"
                              variant="transparent"
                              color={isPastDate ? 'gray' : 'dark'}
                              c={isPastDate ? 'gray' : 'dark'}
                            >
                              <IconBoltOff />
                            </ThemeIcon>
                          </MantineTooltip>
                        ) : null;

                      return (
                        <button
                          type="button"
                          onClick={() => openPricingDrawer(day, hotelRoom.id)}
                          key={`open-pricing-drawer-for-${day}-${hotelRoom.id}`}
                          onMouseEnter={() => {
                            setActiveRoom(hotelRoom.id);
                            setActiveDay(formattedDate);
                          }}
                          onMouseLeave={() => {
                            setActiveRoom(undefined);
                            setActiveDay(undefined);
                          }}
                          className={cn(
                            'group relative h-full border-r border-darkGrey-reduced first:border-l last:border-r-0',
                            activeRoom === hotelRoom.id && activeDay === null
                              ? 'bg-indigo bg-opacity-40'
                              : null,
                            activeDay === formattedDate && activeRoom === hotelRoom.id
                              ? 'bg-indigo bg-opacity-40'
                              : null,
                            minStay?.min_stay
                              ? 'ring-inset ring-darkGreen hover:bg-darkGreen-reduced hover:ring-2'
                              : null,
                            visualMinStayDays.includes(formattedDate) && activeRoom === hotelRoom.id
                              ? 'bg-darkGreen-reduced'
                              : null
                          )}
                        >
                          <div className="h-full overflow-y-auto">
                            <div className="z-20 h-full">
                              <div className="h-full pb-1.5 pl-2 pr-2 pt-1 text-left lg:pl-4 lg:pr-3">
                                <div className="flex h-full flex-col 2xl:flex-row items-end justify-between gap-1">
                                  {roomFields
                                    .filter(
                                      (roomField) => roomField === TABLE_VIEW_ROOM_FIELDS.ROOMS_LEFT
                                    )
                                    .map((roomField) => (
                                      <Flex
                                        gap="4"
                                        align="flex-end"
                                        key={`roomField-occupancy-${roomField}`}
                                        className="min-w-0 flex-shrink self-start 2xl:self-end"
                                        mb="7"
                                      >
                                        <SemiCircleProgress
                                          fillDirection="left-to-right"
                                          orientation="up"
                                          filledSegmentColor="indigo"
                                          emptySegmentColor="orange"
                                          size={30}
                                          thickness={5}
                                          value={roomOccupancy}
                                          styles={{
                                            label: {
                                              fontSize: 'var(--mantine-font-size-xxs)',
                                              lineHeight: 'var(--mantine-line-height-xxs)',
                                              color: 'var(--mantine-color-gray-8)',
                                              fontWeight: 500
                                            }
                                          }}
                                        />
                                        <Text size="xxs" fw={500} truncate>
                                          {isClosed ? t('Closed') : displayData(roomField)}{' '}
                                          {isClosed ? null : t('left')}
                                        </Text>
                                      </Flex>
                                    ))}
                                  <div className="flex flex-grow flex-col items-end justify-end 2xl:justify-start">
                                    {hasMinStayFeature() ? (
                                      <Stack
                                        gap={rem(1)}
                                        lh="xs"
                                        className="text-right text-meta-3"
                                      >
                                        {renderPMSMinStay()}
                                        <div
                                          className={cn(
                                            'flex items-center justify-end gap-1',
                                            hasOverrideMinStay ? 'font-bold' : 'font-normal'
                                          )}
                                        >
                                          {renderMinStayLabel()}
                                          {renderHighlight()}
                                          {renderSkipRuleIcon()}
                                        </div>
                                      </Stack>
                                    ) : null}
                                    {roomFields
                                      .filter(
                                        (roomField) =>
                                          roomField !== TABLE_VIEW_ROOM_FIELDS.ROOMS_LEFT
                                      )
                                      .map((roomField) => (
                                        <div
                                          key={`roomField-${roomField}`}
                                          className="flex items-center justify-end"
                                        >
                                          <span
                                            color="darkGrey"
                                            className={cn(
                                              'shrink-0 flex items-center justify-center',
                                              roomField === TABLE_VIEW_ROOM_FIELDS.RECOMMENDED_PRICE
                                                ? 'text-[12px] font-medium xl:text-[13px] 2xl:text-paragraph-2'
                                                : null
                                            )}
                                          >
                                            {displayData(roomField)}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </button>
                      );
                    })}
                  </SimpleGrid>
                </div>
              </Fragment>
            );
          })}
        </SimpleGrid>
      </div>
    </>
  );
});
