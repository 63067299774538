import { HotelScrape, ScrapeResult } from '@common/api/hotel/types';

import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useDocumentTitle } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';
import { DeleteHotelScrapeModal } from '@pages/Admin/Scrape/ScrapeList/components/DeleteHotelScrapeModal';
import { useDeleteHotelScrape } from '@pages/Admin/Scrape/ScrapeList/hooks/useDeleteHotelScrape';
import { useHotelScrapeList } from '@pages/Admin/Scrape/ScrapeList/hooks/useHotelScrapeList';
import { ActionIcon } from '@mantine/core';

export const ScrapeList = () => {
  useDocumentTitle('Scrape List');
  const [pageParams, setPageParams] = React.useState<number>(1);
  const [selectedHotel, setSelectedHotel] = React.useState<ScrapeResult | null>(null);
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();

  const { hotelScrapeList, isLoading } = useHotelScrapeList(pageParams);
  const { deleteHotelScrape } = useDeleteHotelScrape();

  const columns: any[] = [
    {
      header: 'Name',
      accessorKey: 'hotel',
      cell: (row: any) => {
        const hotel = row?.getValue() as HotelScrape;
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {hotel?.name}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Last Scape',
      accessorKey: 'last_updated_at',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Number of Price',
      accessorKey: 'number_of_price',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Avg.Price',
      accessorKey: 'avg_price',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as number)?.toFixed(2)}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Max.Price',
      accessorKey: 'max_price',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as number)?.toFixed(2)}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Min.Price',
      accessorKey: 'min_price',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as number)?.toFixed(2)}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <ActionIcon
              onClick={() => {
                setSelectedHotel(row?.row?.original);
                deleteClick();
              }}>
              <Icon.Delete className="text-copyTextGrey" />
            </ActionIcon>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const ROWS_PER_PAGE = 20;

  const startIndex = hotelScrapeList?.current
    ? (hotelScrapeList.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    hotelScrapeList?.current && hotelScrapeList.results
      ? startIndex + hotelScrapeList.results.length - 1
      : 0;

  const handleNext = () => {
    if (!hotelScrapeList?.next) return;
    const params = new URLSearchParams(hotelScrapeList.next.split('?')[1]);
    const page = params.get('page');

    if (!page) return;
    setPageParams(Number(page));
  };

  const handlePrevious = () => {
    if (hotelScrapeList?.current) {
      setPageParams(hotelScrapeList.current - 1);
    }
  };
  return (
    <Page header={<Header title="Scrape List" />}>
      <Table
        isHover
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading}
        data={!isLoading ? (hotelScrapeList?.results as any) : []}
        next={handleNext}
        isNext={!!hotelScrapeList?.next}
        previous={handlePrevious}
        isPrevious={pageParams > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={hotelScrapeList?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
      />
      <DeleteHotelScrapeModal
        isOpen={deleteOpen}
        onClose={deleteClose}
        name={selectedHotel?.hotel?.name}
        onDelete={() => {
          if (!selectedHotel?.id) return;
          deleteHotelScrape(selectedHotel?.id);
          deleteClose();
        }}
      />
    </Page>
  );
};
