import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';

export const useSurgePrice = (isEnabled?: boolean) => {
  const { view } = useViewStore();
  const { features } = useFeaturesStore();
  const { cachePriceQuery, pricingSettings } = useRoomPrices(Boolean(isEnabled));
  const { data: cachePrice, isLoading: isCachePriceLoading } = cachePriceQuery;

  const hasSurgeProtectionFeatures = features?.includes(Feature.SurgeProtectionPro) ?? false;
  const isAdminView = view === 'admin';

  const getSurgePriceForDay = (dateKey: string, roomId: number | undefined) => {
    if (!roomId)
      return {
        surgePrice: undefined,
        isSurgePrice: false,
        isDisableEditPrice: false
      };

    const recommendedPrice = cachePrice?.data?.prices?.data?.[dateKey]?.[roomId]?.price;
    const surgePriceInPricingSetting =
      pricingSettings?.dates?.[dateKey]?.[roomId]?.surge_protection;
    const isSurgeNotEmailOnly =
      !!surgePriceInPricingSetting && surgePriceInPricingSetting === recommendedPrice;

    return {
      surgePrice: recommendedPrice,
      isSurgePrice: hasSurgeProtectionFeatures && isSurgeNotEmailOnly,
      isDisableEditPrice: surgePriceInPricingSetting !== undefined && !isAdminView
    };
  };

  return {
    getSurgePriceForDay,
    isSurgePriceEnabled: hasSurgeProtectionFeatures,
    isCachePriceLoading
  };
};
