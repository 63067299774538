/**
 * Formats a date according to the user's locale. Falls back to returning
 * the date as is if the Intl API is unavailable.
 *
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date.
 */

export const localeDateFormat = (date: Date, fallback: string): string => {
  if (typeof Intl !== 'undefined' && Intl.DateTimeFormat) {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale ?? 'en-US';
    return new Intl.DateTimeFormat(locale).format(date);
  } else {
    return fallback;
  }
};
