import { ResultErrorTicket } from '@common/api/errors/types';
import { Icon } from '@common/components/foundations/icons';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { useAuthStore } from '@common/store/auth';
import { browserTimezone } from '@common/utils/browserTimezone';
import { getEnvVar } from '@common/utils/env';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Anchor,
  Button,
  Code,
  Grid,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
  rem
} from '@mantine/core';
import { TICKET_STATUS } from '@pages/Admin/ErrorTickets/common/constants/ticket-status';
import { useSupportTeamList } from '@pages/Admin/ErrorTickets/hooks/useSupportTeamList';
import { useUpdateTicket } from '@pages/Admin/ErrorTickets/hooks/useUpdateErrorTicket';
import { Editor } from '@tinymce/tinymce-react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import * as z from 'zod';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  data?: ResultErrorTicket;
};

const schema = z.object({
  id: z.number().optional(),
  assignee: z.string().or(z.number()).nullish().optional(),
  status: z.string().optional(),
  token: z.string().optional().nullish(),
  note: z.string().optional()
});

export const UpdateErrorTicketModal = ({ isOpen, onClose, data }: Props) => {
  const { token } = useAuthStore();
  const { updateErrorTicket } = useUpdateTicket();
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const { supportTeams } = useSupportTeamList();

  const defaultValues = {
    id: undefined,
    assignee: undefined,
    status: undefined,
    note: undefined,
    token
  };

  const { reset, control, setValue, handleSubmit } = useForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    if (data && data.id) {
      setValue('note', data.note);
      setValue('id', data.id);
      setValue('status', data.status.toString());
      setValue('assignee', data?.assignee?.toString());
    }
  }, [data?.id]);

  // console.log('data', data);

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (formData) => {
    if (data?.id) {
      const { assignee, status, note, token } = formData;
      await updateErrorTicket([
        {
          id: data.id,
          assignee: Number(assignee) !== 0 ? Number(assignee) : null,
          status: Number(status),
          note,
          token: token ?? null
        },
        data?.id
      ]);
    }
    onClose();
  };

  const jiraTicketUrl =
    'https://roompricegenie.atlassian.net/secure/CreateIssueDetails!init.jspa?issuetype=10009&pid=10015' +
    '&summary=Hotel+Id%3A+' +
    encodeURIComponent(data?.hotel as number) +
    '+-+' +
    encodeURIComponent(data?.pms_name as string) +
    '+-+' +
    '&description=' +
    'Hotel+ID%3A+' +
    encodeURIComponent(data?.hotel as number) +
    '%0AHotel+Name%3A+' +
    encodeURIComponent(data?.hotel_name as string) +
    '%0AResponsible+Person%3A+' +
    encodeURIComponent(data?.responsible_person as string) +
    '%0AReseller%3A+' +
    encodeURIComponent(data?.reseller as string) +
    '%0APMS%2FCM+Name%3A+' +
    encodeURIComponent(data?.pms_name as string) +
    `&labels=${(data?.pms_name as string)
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join('')}`;

  return (
    <Modal.Root
      opened={isOpen}
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
      size={rem('1280')}
    >
      <Modal.Overlay />
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header bg="white">
            <Modal.Title>Edit Error Ticket</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Stack>
              <SimpleGrid
                cols={2}
                w={{
                  xs: '100%',
                  md: '50%'
                }}
              >
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { value, name, onChange } }) => (
                    <Select
                      label="Ticket Status"
                      placeholder="Select Ticket Status"
                      name={name}
                      key={name}
                      value={value || ''}
                      data={TICKET_STATUS}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="assignee"
                  render={({ field: { value, name, onChange } }) => (
                    <Select
                      label="Assignee"
                      placeholder="Select Assignee"
                      name={name}
                      key={name}
                      value={(value as string) || ''}
                      data={supportTeams?.map((team) => ({
                        label: `${team.first_name} ${team.last_name}`,
                        value: team.id.toString()
                      }))}
                      onChange={onChange}
                      clearable
                      onClear={() => onChange(null)}
                    />
                  )}
                />
              </SimpleGrid>

              <Stack>
                <Group gap="xs">
                  <SimpleGrid cols={2}>
                    <SimpleGrid spacing="xs">
                      <SimpleGrid cols={2}>
                        <Title order={5}>Property Name</Title>
                        <Anchor
                          href={`/client/account/${data?.hotel}/integrations`}
                          target="_blank"
                          c="indigo"
                          underline="always"
                        >
                          {data?.hotel_name} ({data?.hotel})
                        </Anchor>
                      </SimpleGrid>
                      <SimpleGrid cols={2}>
                        <Title order={5}>PMS/CM Name</Title>
                        <Text>{data?.pms_name}</Text>
                      </SimpleGrid>
                      <SimpleGrid cols={2}>
                        <Title order={5}>Tags</Title>
                        <Text>
                          {data?.tag} ({data?.code})
                        </Text>
                      </SimpleGrid>

                      <SimpleGrid cols={2}>
                        <Title order={5}>Created Date</Title>
                        <Tooltip
                          label={
                            <table className="table-auto">
                              <tbody>
                                <tr>
                                  <td className="pr-2">
                                    <Text c="white">Browser Time:</Text>
                                  </td>
                                  <td>
                                    <Text c="white">
                                      {dayjs(data?.created_at)
                                        .tz(browserTimezone)
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                    </Text>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <Text>
                            {dayjs(data?.created_at).utc().format('YYYY-MM-DD HH:mm [UTC]')}
                          </Text>
                        </Tooltip>
                      </SimpleGrid>
                      <SimpleGrid cols={2}>
                        <Title order={5}>Modified Date</Title>
                        <Tooltip
                          label={
                            <table className="table-auto">
                              <tbody>
                                <tr>
                                  <td className="pr-2">
                                    <Text c="white">Browser Time:</Text>
                                  </td>
                                  <td>
                                    <Text c="white">
                                      {dayjs(data?.modified_at)
                                        .tz(browserTimezone)
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                    </Text>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <Text>
                            {dayjs(data?.modified_at).utc().format('YYYY-MM-DD HH:mm [UTC]')}
                          </Text>
                        </Tooltip>
                      </SimpleGrid>
                      <SimpleGrid cols={2}>
                        <Title order={5}>Responsible Person</Title>
                        <Text>
                          {data?.responsible_person === 'Not Assigned'
                            ? '-'
                            : data?.responsible_person}
                        </Text>
                      </SimpleGrid>
                      <SimpleGrid cols={2}>
                        <Title order={5}>Reseller</Title>
                        <Text>{data?.reseller === 'Not Assigned' ? '-' : data?.reseller}</Text>
                      </SimpleGrid>

                      <SimpleGrid cols={2}>
                        <Title order={5}>Hotel Name</Title>
                        <Text>{data?.hotel_name}</Text>
                      </SimpleGrid>

                      <SimpleGrid cols={2}>
                        <Title order={5}>Count</Title>
                        <Tooltip label="How many tickets ticket modified since it was last opened.">
                          <Text>{data?.count}</Text>
                        </Tooltip>
                      </SimpleGrid>
                      <SimpleGrid cols={2}>
                        <Title order={5}>Lifetime Count</Title>
                        <Tooltip label="Since it was created for the first time">
                          <Text>{data?.lifetime_count}</Text>
                        </Tooltip>
                      </SimpleGrid>
                    </SimpleGrid>
                    <Stack gap="xs">
                      <Group gap="lg">
                        <Title order={5}>Note</Title>
                        {isEditorLoading ? <Icon.LoadingCircle /> : null}
                      </Group>
                      <Controller
                        control={control}
                        name="note"
                        defaultValue={data?.note}
                        render={({ field: { value, onChange } }) => (
                          <Editor
                            apiKey={`${getEnvVar('VITE_TINYMCE')}`}
                            initialValue={value}
                            init={{
                              height: 320,
                              menubar: false,
                              branding: false,
                              plugins:
                                'preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link codesample table  hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount  textpattern noneditable help charmap emoticons',
                              toolbar:
                                'undo redo | bold italic underline emoticons | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | link  codesample numlist bullist | forecolor backcolor removeformat | pagebreak | outdent indent fullscreen '
                            }}
                            onChange={(_event, editor) => {
                              const data = editor.getContent();
                              onChange(data);
                            }}
                            onInit={() => {
                              setIsEditorLoading(false);
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </SimpleGrid>
                </Group>
              </Stack>

              <Grid columns={3}>
                <Grid.Col span={1}>
                  <Title order={5}>Error Message</Title>
                </Grid.Col>

                <Grid.Col span={3}>
                  <Code block fz="md">
                    <JsonView src={data?.message} />
                  </Code>
                </Grid.Col>
              </Grid>
            </Stack>
          </Modal.Body>

          <ModalFooter>
            <Button variant="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={() => {
                window.open(jiraTicketUrl, '_blank');
              }}
            >
              Create Jira Ticket
            </Button>
            <Button component="a" variant="default" href={`${data?.datadog_url}`} target="_blank">
              Open Datadog URL
            </Button>

            <Button type="submit">Save</Button>
          </ModalFooter>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
