import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { DatePicker } from '@common/components/molecules/DatePicker';
import { Modal } from '@common/components/molecules/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAddFreeTrial } from '@pages/Client/Account/Subscription/hooks/usePayment';
import { useEnableClientLoading } from '@pages/Client/Calendar/store/enableClient';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const schema = z
  .object({
    free_trial_end: z.date()
  })
  .refine(
    (data) => {
      const currentDate = new Date();
      const freeTrialEndDate = data.free_trial_end || currentDate;
      return freeTrialEndDate >= currentDate;
    },
    {
      message: 'Free trial end date cannot be in the past',
      path: ['free_trial_end']
    }
  );

const formatDate = (dateString: any) => {
  return dayjs(dateString).format('YYYY-MM-DD');
};

export const EnableClientAccessModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { mutate: addFreeTrialTime, isLoading: isAddFreeTrialLoading } = useAddFreeTrial(false);
  const { setEnableClientLoading } = useEnableClientLoading();
  const { control, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      free_trial_end: dayjs(hotelDetails?.free_trial_end).toDate()
    }
  });

  const { pricingSettings, pricingSettingsQuery } = usePricingSettings();
  const { savePricingSettings, isLoading: isSavePricingSettingLoading } =
    useUpdatePricingSettings();

  useEffect(() => {
    if (isAddFreeTrialLoading || isSavePricingSettingLoading) {
      setEnableClientLoading(true);
    } else {
      setEnableClientLoading(false);
    }
  }, [isAddFreeTrialLoading, isSavePricingSettingLoading]);

  const onSubmit = async (data: z.infer<typeof schema>) => {
    const newFeatures = pricingSettings?.features?.filter((number) => number !== 19);
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const newSettings = {
      ...latestPricingSettings,
      features: newFeatures
    };

    await addFreeTrialTime({
      free_trial_end: formatDate(data.free_trial_end)
    });

    await savePricingSettings(JSON.stringify(newSettings));
  };

  return (
    <Modal
      okText={`${t('Save')}`}
      continueIntent="primary"
      size="sm"
      open={isOpen}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      onClose={onClose}
      children={
        <div className="w-full">
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            {t('Set End of Free Trial')}
          </Typography>
          <Controller
            control={control}
            name="free_trial_end"
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <>
                <DatePicker
                  ISOWeek={hotelDetails?.starts_monday}
                  selectedDate={value || dayjs().add(7, 'day').toDate()}
                  onDateChange={(date) => {
                    onChange(date);
                  }}
                  timezone={hotelDetails?.timezone}
                  closeOnSelect
                />
                <div className="mt-2">
                  {errors && (
                    <div className="flex items-center gap-2 text-error">
                      {errors.free_trial_end ? <Icon.WarningOutline className="w-4" /> : ''}
                      <Typography element="span" color="error" variant="meta-2">
                        {errors.free_trial_end ? errors?.free_trial_end?.message?.toString() : ''}
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            )}
          />
        </div>
      }
    />
  );
};
