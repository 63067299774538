import { disconnectPms } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useQueryClient, useMutation } from '@tanstack/react-query';

export const useDisconnectPms = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: () => {
      return disconnectPms();
    },
    onSuccess: () => {
      addNotification('success', `${channelManagerOrPms} Connection Updated Successfully`);
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_PMS_LIST] });
      queryClient.invalidateQueries({ queryKey: [HotelQueryKeys.GET_HOTEL_CLIENT_DETAIL] });
    },
    onError: () => {
      addNotification('fail', `Failed to update ${channelManagerOrPms} connection`);
    }
  });

  return {
    disconnectPms: mutate,
    isLoading,
    isSuccess
  };
};
