import { create } from 'zustand';
interface LoadingStore {
  isManualSearchLoading: boolean;
  setLoading: (newLoading: boolean) => void;
  isSuccess: boolean;
  setSuccess: (newSuccess: boolean) => void;
}

export const useManualSearchAirdnaStore = create<LoadingStore>((set) => ({
  isManualSearchLoading: false,
  setLoading: (newLoading: boolean) => set({ isManualSearchLoading: newLoading }),
  isSuccess: false,
  setSuccess: (newSuccess: boolean) => set({ isSuccess: newSuccess })
}));
