export function smartTruncate(
  str: string,
  frontChars: number,
  endChars: number,
  totalChars: number
) {
  // Correct negative values to 0
  frontChars = Math.max(0, frontChars);
  endChars = Math.max(0, endChars);
  totalChars = Math.max(0, totalChars);

  // If the string is empty or its length is less than or equal to totalChars, return it as is
  if (str.length === 0 || str.length <= totalChars) {
    return str;
  }

  // Calculate the maximum length available for the ellipsis
  const maxEllipsisLength = totalChars - frontChars - endChars;

  // If there's not enough space for the ellipsis, return a directly truncated string
  if (maxEllipsisLength <= 3) {
    return str.substring(0, totalChars - 3) + '...';
  }

  // Extract the front and end parts of the string based on frontChars and endChars
  const frontPart = str.substring(0, frontChars);
  const endPart = str.substring(str.length - endChars);

  // Reconstruct the truncated string with ellipsis in the middle
  return `${frontPart} ... ${endPart}`;
}
