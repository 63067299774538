import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  CartesianGrid,
  ReferenceLine,
  Legend,
  ReferenceDot
} from 'recharts';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { Box, Flex, Pill, Text, lighten } from '@mantine/core';
import { Card } from '@common/components/molecules/Card';
import { useTranslation } from 'react-i18next';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { entries, range, fromPairs, get } from 'lodash-es';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import dayjs from 'dayjs';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { PricingSettings } from '@common/api/hotel/types';
import { API_DATE_FORMAT } from '@common/constants/date';

interface Props {
  initialFixPrice: number;
  durationInDays: number;
  decayStep: number;
  isSurgeEventContext?: boolean;
  surgeEventDate?: string;
  surgeEventTimestamp?: string;
}

export const SurgeProtectionDecayChart: React.FC<Props> = ({
  initialFixPrice,
  durationInDays,
  isSurgeEventContext,
  surgeEventDate,
  surgeEventTimestamp,
  decayStep
}) => {
  const { t } = useTranslation();
  const { pricingSettings } = usePricingSettings();
  const { getRoomData } = useRoomPrices();
  const { selectedHotelRoomId } = useCalendarPageStore();

  const decayEndDate = dayjs(surgeEventTimestamp).add(durationInDays, 'days');
  const isSurgeEventBeforeDecayEnd = isSurgeEventContext
    ? dayjs(surgeEventDate).startOf('day').isBefore(decayEndDate.endOf('day'))
    : false;

  const currentDecayDay = useMemo(() => {
    if (!surgeEventDate || !surgeEventTimestamp) return 0;

    const surgeDate = dayjs(surgeEventDate).startOf('day');
    const surgeTimestamp = dayjs(surgeEventTimestamp).startOf('day');
    const today = dayjs().tz();

    const daysFromTimestampToToday = today.diff(surgeTimestamp, 'days');
    const totalDuration = surgeDate.diff(surgeTimestamp, 'days');

    return Math.min(daysFromTimestampToToday, totalDuration);
  }, [surgeEventDate, surgeEventTimestamp, durationInDays]);

  const indigo = useTailwindColor('indigo');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const uiGreen = useTailwindColor('uiGreen');
  const darkGrey = useTailwindColor('darkGrey');

  const FONT_FAMILY = "'Inter var', sans-serif";
  const FONT_SIZE = '12px';
  const FONT_WEIGHT = 400;

  const referenceRoomId = pricingSettings?.rooms?.reference?.id;
  const getPrice = (
    roomId: number | undefined,
    pricingSettings: PricingSettings | undefined,
    key: string
  ) => {
    return get(pricingSettings, `default.${roomId}.${key}`, 0);
  };

  const basePrice = isSurgeEventContext
    ? getPrice(selectedHotelRoomId, pricingSettings, 'avg_price')
    : getPrice(referenceRoomId, pricingSettings, 'avg_price');

  const recommendedPrice = Math.round(
    isSurgeEventContext
      ? selectedHotelRoomId && surgeEventDate
        ? get(getRoomData(selectedHotelRoomId, surgeEventDate), 'suggested_price', 0)
        : 0
      : getPrice(referenceRoomId, pricingSettings, 'avg_price')
  );

  const priceDecay = useMemo(() => {
    durationInDays = isSurgeEventBeforeDecayEnd
      ? dayjs(surgeEventDate).endOf('day').diff(dayjs(surgeEventTimestamp).startOf('day'), 'days')
      : durationInDays;
    const indexes = range(0, durationInDays + 1);

    const priceDecayData = fromPairs(
      indexes.map((index) => {
        const date = isSurgeEventContext
          ? dayjs(surgeEventTimestamp).add(index, 'days').format(API_DATE_FORMAT)
          : index;

        let price;
        if (index === 0) {
          price = initialFixPrice;
        } else if (index === durationInDays) {
          if (isSurgeEventBeforeDecayEnd) {
            price = recommendedPrice;
          } else {
            price = basePrice;
          }
        } else {
          price = initialFixPrice - decayStep * index;
        }

        return [index, { date, price: Math.round(price) }];
      })
    );

    return priceDecayData;
  }, [initialFixPrice, durationInDays, surgeEventDate, surgeEventTimestamp]);

  const data = entries(priceDecay).map(([key, { date, price }]) => {
    return {
      key,
      date,
      priceDecay: price
    };
  });

  const intersectionData = useMemo(() => {
    const intersectionPoint = data.find((item) => {
      return item.priceDecay <= recommendedPrice && item.priceDecay >= basePrice;
    });

    if (intersectionPoint) {
      return {
        date: intersectionPoint.date,
        price: recommendedPrice
      };
    }
    return null;
  }, [data, recommendedPrice, basePrice]);

  const intersectionIndex = data.findIndex((item) => item.date === intersectionData?.date);

  const CustomLegend: React.FC<any> = () => {
    return (
      <ul className="mb-3 flex list-none justify-center">
        <li className="mr-2 flex items-center">
          <div
            className="mr-1 h-3 w-3 rounded-full"
            style={{
              backgroundColor: indigo
            }}
          />
          <Text size="xs">{t('Decaying Protection Price')}</Text>
        </li>
        <li className="mr-2 flex items-center">
          <div
            className="mr-1 h-3 w-3 rounded-full"
            style={{
              backgroundColor: darkGrey
            }}
          />
          <Text size="xs">
            {t('Recommended Price')}{' '}
            {isSurgeEventContext ? `(${CurrencyFormatter.format(recommendedPrice)})` : null}
          </Text>
        </li>
      </ul>
    );
  };

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;

    const dateLabel = isSurgeEventContext
      ? dayjs(dataPoint.date).format('ddd, DD MMM YYYY')
      : `${Number(dataPoint.key) + 1} ${t('Days')}`;

    return (
      <Card backgroundColor="white">
        <Text size="xs" fw={600} c="grey">
          {dateLabel}
        </Text>
        <div className="flex items-center justify-between gap-x-3">
          <Text size="xs" c="dark">
            {t('Decaying Protection Price')}
          </Text>
          <Text size="xs" fw={500} c="dark">
            {CurrencyFormatter.format(dataPoint.priceDecay)}
          </Text>
        </div>
      </Card>
    );
  };

  const dataMax = Math.floor(
    Math.max(...(data || []).map((item) => Math.max(item.priceDecay, recommendedPrice) * 1.1))
  ); // 10% padding
  const dataMin = Math.floor(
    Math.min(...(data || []).map((item) => Math.min(item.priceDecay, recommendedPrice) * 0.9))
  ); // 10% padding

  const CustomLabel: React.FC<any> = ({ viewBox, value, fill, fontSize, position = 'bottom' }) => {
    const [textWidth, setTextWidth] = useState<number>(0);
    const textRef = useRef<SVGTextElement>(null);

    useEffect(() => {
      if (textRef.current) {
        const width = textRef.current.getBBox().width;
        setTextWidth(width + 10);
      }
    }, [value]);

    if (!viewBox) return null;
    const { x, y } = viewBox;

    const xOffset = position === 'left' ? -textWidth - 15 : 0; // Adjust this value as needed
    const yOffset = position === 'left' ? 4 : 10; // Adjust this value as needed

    return (
      <g>
        <text ref={textRef} x="-9999" y="-9999" fontSize={fontSize || '12px'} fill={fill}>
          {value}
        </text>
        <foreignObject x={x + xOffset} y={y - yOffset} width="100%" height="100%">
          <Pill bg="indigo" c="white">
            {value || ''}
          </Pill>
        </foreignObject>
      </g>
    );
  };

  return (
    <Box pos="relative" h="100%" w="100%">
      <ResponsiveContainer width="100%" height={isSurgeEventContext ? 400 : 300}>
        <ComposedChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: -15
          }}>
          <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
          <XAxis axisLine={false} tick={false} />
          <YAxis
            domain={[dataMin, dataMax]}
            axisLine={false}
            tick={{ fill: grey, stroke: 'none' }}
            tickLine={false}
            fontSize={FONT_SIZE}
            fontFamily={FONT_FAMILY}
            fontWeight={FONT_WEIGHT}
            tickFormatter={(value: number) => CurrencyFormatter.format(value)}
          />
          <Tooltip content={(props) => <CustomTooltip {...props} />} />
          <Legend verticalAlign="top" content={() => <CustomLegend />} />

          {isSurgeEventContext ? (
            <ReferenceLine
              x={currentDecayDay}
              stroke={uiGreen}
              strokeWidth={2}
              label={{
                position: 'insideTopLeft',
                value: 'Today',
                fontSize: FONT_SIZE,
                fill: uiGreen,
                fontWeight: 600
              }}
            />
          ) : null}

          <Line type="stepAfter" dataKey="priceDecay" stroke={indigo} fill={indigo} dot={false} />

          <ReferenceLine
            name={t('Recommended Price') as string}
            y={recommendedPrice}
            stroke={darkGrey}
            strokeWidth={2}
          />

          {isSurgeEventContext &&
          dayjs(intersectionData?.date).startOf('day').isBefore(decayEndDate.startOf('day')) ? (
            <ReferenceDot
              x={intersectionIndex}
              y={intersectionData?.price}
              r={10}
              fill={indigo}
              stroke={indigo ? lighten(indigo, 0.6) : undefined}
              strokeWidth={3}
              label={
                <CustomLabel
                  value={`${t('Decay will end')} ${dayjs(intersectionData?.date).format('L')}`}
                  fill={indigo}
                  fontSize={FONT_SIZE}
                  position="left"
                />
              }
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
      <Flex justify="space-between" mt={-2}>
        <Text size="xs" c="grey">
          {t('Surge Event Detected {{date}}', { date: dayjs(surgeEventTimestamp).format('L') })}
        </Text>
        <Text size="xs" c="grey">
          {t('Decay Ends {{date}}', {
            date: isSurgeEventBeforeDecayEnd
              ? dayjs(surgeEventDate).format('L')
              : decayEndDate.format('L')
          })}
        </Text>
      </Flex>
    </Box>
  );
};
