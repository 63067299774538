import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetPartnersList } from '@pages/Admin/PartnerUsers/hooks/usePartnersList';
import { useUpdatePartnerUser } from '@pages/Admin/PartnerUsers/hooks/useUpdatePartnerUser';
import { SelectedPartnerUser } from '@pages/Admin/PartnerUsers/types/selectedPartnerUser';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  selected?: SelectedPartnerUser;
};

const schema = z.object({
  token: z.string().or(z.undefined()).nullable(),
  email: z.string().email(),
  first_name: z.string(),
  id: z.number(),
  last_name: z.string(),
  sub_account_type: z.number(),
  reseller: z.number()
});

export const UpdatePartnerUserModal = ({ isOpen, onClose, selected }: ModalProps) => {
  const { token } = useAuthStore();
  //   const { createAdminUser } = useAddNewAdminUser();
  const { updatePartnerUser } = useUpdatePartnerUser();

  const { partnerList } = useGetPartnersList({ page: 1, search: '' });

  const { control, reset, handleSubmit, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      token,
      sub_account_type: selected?.sub_account_type
    }
  });

  const PARTNER_OPTIONS = partnerList?.results?.map((partner) => ({
    label: partner?.name,
    value: partner?.id
  }));

  useEffect(() => {
    if (selected) {
      reset(selected);
    }
  }, [selected]);

  const onSubmit = (data: any) => {
    if (selected?.id) {
      const payloadData = {
        payload: {
          token,
          ...data
        },
        id: selected?.id
      };
      updatePartnerUser(payloadData);
    }
  };

  return (
    <Modal
      okText="Save"
      continueIntent="primary"
      size="xl"
      open={isOpen}
      disableCloseOnOk={!formState.isValid}
      onClick={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
      }}
      children={
        <div>
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            Add New Partner User
          </Typography>
          <div className="flex w-full justify-between gap-4 ">
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="text"
                    value={value}
                    label="First Name *"
                    placeholder="Please enter first name"
                    onChange={onChange}
                    onClear={() =>
                      reset({
                        first_name: ''
                      })
                    }
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="email"
                    value={value}
                    label="Email *"
                    placeholder="Please enter email"
                    onChange={onChange}
                    onClear={() =>
                      reset({
                        email: ''
                      })
                    }
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
            </div>
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <Input
                    name={name}
                    background="grey"
                    type="text"
                    value={value}
                    label="Last Name *"
                    placeholder="Please enter last name"
                    onChange={onChange}
                    onClear={() =>
                      reset({
                        last_name: ''
                      })
                    }
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <div className="flex flex-col items-start">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  Partner*
                </Typography>
                <Controller
                  name="reseller"
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <>
                      <SelectDropdown
                        name={name}
                        fullWidth
                        value={value}
                        placeholder="Select Partner"
                        options={PARTNER_OPTIONS}
                        onChange={(value) => onChange(value)}
                      />
                      <div className="mt-1">
                        {error && (
                          <div className="flex items-center gap-2 text-error">
                            {error ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {error ? error?.message?.toString() : ''}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
