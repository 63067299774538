import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useUserRole } from '@common/hooks/useUserRole';
import { PartnersListTable } from '@pages/Admin/PartnerUsers/pages/PartnersListTable';
import { PartnersUsersList } from '@pages/Admin/PartnerUsers/pages/PartnersUserListTable';
import { useDocumentTitle } from '@mantine/hooks';

export const PartnersUsers = () => {
  useDocumentTitle('Partner Users');
  const { isPartner } = useUserRole();
  return (
    <Page header={<Header title="Partners" />}>
      {isPartner ? null : <PartnersListTable />}
      <div className="mt-20">
        <PartnersUsersList />
      </div>
    </Page>
  );
};
