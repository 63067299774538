import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useErrorProfileChangePasswordStore } from '@pages/Client/Account/Profile/store/profile';
import { UserProfile } from '@clerk/clerk-react';
import { useClerkConditionalRender } from '@common/hooks/useClerkConditionalRender';

export const Password = () => {
  const { t } = useTranslation();
  const { renderClerk, clerk } = useClerkConditionalRender();
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const {
    isError,
    isOldPasswordError,
    setError,
    setOldPasswordError,
    message: errorPasswordMessage,
    oldPasswordMessage
  } = useErrorProfileChangePasswordStore();

  const getRefineNewPasswordErrors = () => {
    if ((errors.new_password as any)?.new_password) {
      return (errors.new_password as any).new_password.message;
    }
    return '';
  };

  useEffect(() => {
    if (isError && !watch('new_password')) {
      setError(false);
    }
    if (isOldPasswordError && !watch('old_password')) {
      setOldPasswordError(false);
    }
  }, [isError, watch('new_password'), isOldPasswordError, watch('old_password')]);

  return (
    <div className="flex w-full ">
      {clerk.isLoaded && renderClerk ? <UserProfile /> : null}
      {clerk.isLoaded && !renderClerk ? (
        <div className="flex  w-full flex-col justify-between md:flex-row lg:flex-row">
          <div>
            <Typography>{t('Edit Your Password')}</Typography>
          </div>
          <div className="w-full flex-col gap-5 md:w-1/2 ">
            <div className="pb-5">
              <Controller
                name="old_password"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    label={(t('Old Password') as string) + '*'}
                    placeholder={t('Please enter your old password') as string}
                    onBlur={onBlur}
                    onChange={(value) => {
                      onChange(value);
                      setOldPasswordError(false);
                    }}
                    value={value}
                    type={showOldPassword ? 'text' : 'password'}
                    error={error || isOldPasswordError ? true : false}
                    onClear={() => {
                      setValue('old_password', '');
                      setOldPasswordError(false);
                    }}
                    trailingAddon={
                      <button onClick={() => setShowOldPassword(!showOldPassword)}>
                        {showOldPassword ? (
                          <Icon.Visible className="h-5 w-5" />
                        ) : (
                          <Icon.Hidden className="h-5 w-5" color="grey" />
                        )}
                      </button>
                    }
                    helperMessage={
                      isOldPasswordError ? (
                        <div className="flex items-center gap-2 text-error">
                          <Icon.WarningOutline className="w-4" />
                          <Typography element="span" color="error" variant="meta-2">
                            {oldPasswordMessage}
                          </Typography>
                        </div>
                      ) : error ? (
                        <div className="flex items-center gap-2 text-error">
                          <Icon.WarningOutline className="w-4" />
                          <Typography element="span" color="error" variant="meta-2">
                            {error?.message || ''}
                          </Typography>
                        </div>
                      ) : null
                    }
                  />
                )}
              />
            </div>
            <div className="pb-5">
              <Controller
                name="new_password"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <>
                    <div className="mb-1 flex items-center gap-1 ">
                      <TooltipProvider delayDuration={75}>
                        <Tooltip>
                          <TooltipTrigger type="button">
                            <Icon.Info className="h-5 w-5 fill-grey" />
                          </TooltipTrigger>
                          <TooltipContent side="bottom" className="max-w-md">
                            <ul className="list-decimal px-2">
                              <li>{t('Minimum of 8 characters.')}</li>
                              <li>{t('Must include at least 1 number (0-9).')}</li>
                              <li>{t('Requires at least 1 uppercase letter (A-Z).')}</li>
                              <li>
                                {t('Must include at least 1 special character (@,#,$,etc.).')}
                              </li>
                              <li>{t('Must not be similar to the email.')}</li>
                            </ul>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                      <Typography className="text-meta-1 text-grey" color="copyTextGrey">
                        {t('New Password')}*
                      </Typography>
                    </div>

                    <Input
                      placeholder={t('Please enter your new password') as string}
                      onBlur={onBlur}
                      onChange={(value) => {
                        onChange(value);
                        setError(false);
                      }}
                      value={value}
                      type={showPassword ? 'text' : 'password'}
                      error={error || isError ? true : false}
                      onClear={() => {
                        setValue('new_password', '');
                        setError(false);
                      }}
                      trailingAddon={
                        <button onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <Icon.Visible className="h-5 w-5" />
                          ) : (
                            <Icon.Hidden className="h-5 w-5" color="grey" />
                          )}
                        </button>
                      }
                      helperMessage={
                        isError ? (
                          <div className="flex items-center gap-2 text-error">
                            <Icon.WarningOutline className="w-4" />
                            <Typography element="span" color="error" variant="meta-2">
                              {errorPasswordMessage}
                            </Typography>
                          </div>
                        ) : errors ? (
                          <div className="flex items-center gap-2 text-error">
                            {errors.new_password ? <Icon.WarningOutline className="w-4" /> : null}
                            <Typography element="span" color="error" variant="meta-2">
                              {errors.new_password ? errors?.new_password?.message?.toString() : ''}
                              {(errors?.new_password as any)?.new_password &&
                              watch('new_password').length >= 8
                                ? getRefineNewPasswordErrors()
                                : ''}
                            </Typography>
                          </div>
                        ) : null
                      }
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
