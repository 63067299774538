import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { RoomsQueryKeys } from '@common/types/query-keys';
import { getHotelRoomsList } from '@common/api/hotel';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';

export const useHotelRoomsList = (isEnabled?: boolean) => {
  const query = useQuery({
    queryKey: [RoomsQueryKeys.GET_HOTEL_ROOMS_LIST],
    queryFn: getHotelRoomsList,
    enabled: Boolean(isEnabled)
  });

  const hotelRooms = useMemo(() => query.data?.data || [], [query.data]);

  const { pricingSettings } = useRoomPrices(Boolean(isEnabled));

  const { selectedHotelRoomId, setSelectedHotelRoomId } = useCalendarPageStore();

  const selectedHotelRoom = useMemo(
    () => hotelRooms.find((room) => room.id === selectedHotelRoomId),
    [hotelRooms, selectedHotelRoomId]
  );

  const sortedHotelRooms = useMemo(
    () => [...hotelRooms].sort((a, b) => +b.is_reference_room - +a.is_reference_room),
    [hotelRooms]
  );

  const displayHotelRooms = useMemo(() => {
    const isAllRoomPricing = pricingSettings?.hotel.all_room_pricing;
    return sortedHotelRooms.filter((room) => (!isAllRoomPricing ? room.is_reference_room : true));
  }, [sortedHotelRooms, pricingSettings]);

  return {
    query,
    hotelRooms,
    displayHotelRooms,
    sortedHotelRooms,
    selectedHotelRoom,
    selectedHotelRoomId,
    setSelectedHotelRoomId
  };
};
