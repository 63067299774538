import { enableIframe, getEnableIframeStatus } from '@common/api/hotel';
import { EnableIframePayload, EnableIframeResponse } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ManageIframeQueryKeys } from '@common/types/query-keys';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useEnableIframe = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: EnableIframePayload) => {
      return enableIframe(data);
    },
    onSuccess: (data) => {
      const res = data.data as EnableIframeResponse;
      if (res.data?.success) {
        queryClient.invalidateQueries({ queryKey: [ManageIframeQueryKeys.GET_IFRAME_STATUS] });
        addNotification('success', res?.data.message ?? `Updated successfully`);
      } else {
        addNotification('fail', res?.data.message ?? `Failed to update iframe settings`);
      }
    },
    onError: () => {
      addNotification('fail', `Failed to update iframe settings`);
    }
  });

  return {
    enableIframe: mutate,
    isLoading
  };
};

export const useEnableIframeStatus = () => {
  const { hasManageIframe } = usePmsProvider();
  const { data, isLoading, error } = useQuery({
    queryKey: [ManageIframeQueryKeys.GET_IFRAME_STATUS],
    queryFn: () => {
      return getEnableIframeStatus();
    },
    retry: true,
    enabled: hasManageIframe
  });

  return {
    iframe: data?.data as EnableIframeResponse,
    isLoading,
    error
  };
};
