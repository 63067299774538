import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
  onAddNewSchedule?: () => void;
}

export const TableHeaderScheduleReports = ({ onAddNewSchedule }: TableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full items-center justify-between gap-3 py-2">
      <div className="flex gap-3">
        <Typography element="span" color="copyTextGrey">
          {t('Scheduled Reports')}
        </Typography>
      </div>
      <Button onClick={onAddNewSchedule}>{t('Add New Scheduled Reports')}</Button>
    </div>
  );
};
