import { Button } from '@common/components/atoms/Button';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { TypographyColors } from '@common/components/foundations/colorVariants';
import { Typography } from '@common/components/foundations/Typography';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/foundations/icons';
import { useFeaturesStore } from '@common/store/features';
import { cn } from '@common/utils/cn';
import { usePaymentStore } from '@pages/Client/Account/Subscription/store/subscription';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import {
  formatPrice,
  getDescription,
  getTypeLabel
} from '@pages/Client/Account/Subscription/utils/subscription';
import { HotelPlan, HotelState, SubscriptionType } from '@pages/Client/hooks/useSubscription';

interface CardSubscriptionProps {
  type?: number;
  current?: number;
  isSubscribed?: boolean;
  isProliteMonthly?: boolean;
  isProliteYearly?: boolean;
  isMonthly?: boolean;
  price?: number;
  isLoading?: boolean;
  buttonDisabled?: boolean;
  onclick?: () => void;
  monthPrice?: number;
  currentSubscriptionType?: number;
  isAdvanceHighlight?: boolean | (() => boolean);
  isProfessionalHighlight?: boolean | (() => boolean);
  currencySymbol?: string;
}
interface ButtonLabelProps {
  type?: number;
  current?: number;
  isMonthly?: boolean;
  isSubscribed?: boolean;
  isSubscribeMonthly?: boolean;
  isSubscribeYearly?: boolean;
  currentSubscriptionType?: number;
}

function priceColorAndSymbolColor(
  type?: number,
  isSubscribed?: boolean,
  isAdvanceHighlight?: boolean | (() => boolean),
  isProfessionalHighlight?: boolean | (() => boolean)
): TypographyColors {
  const evaluateHighlight = (
    highlightCondition: boolean | (() => boolean) | undefined
  ): boolean => {
    return typeof highlightCondition === 'function' ? highlightCondition() : !!highlightCondition;
  };

  if (type === HotelPlan.ADVANCED || type === HotelPlan.PRO_LITE) {
    return isSubscribed || !evaluateHighlight(isAdvanceHighlight) ? 'indigo' : 'white';
  } else if (type === HotelPlan.PROFESSIONAL) {
    return isSubscribed || !evaluateHighlight(isProfessionalHighlight) ? 'indigo' : 'white';
  }

  // Default color for STARTER, SMALLBUSINESS
  return 'indigo';
}

interface GetClassNamesProps {
  type?: number;
  isSubscribed?: boolean;
  CurrentSubscriptionType?: number;
}

function getStyleCard({ type, isSubscribed }: GetClassNamesProps): string {
  let borderColor = '';
  switch (type) {
    case HotelPlan.STARTER:
      borderColor = isSubscribed ? 'border border-grey' : '';
      break;
    case HotelPlan.ADVANCED:
    case HotelPlan.PRO_LITE:
      borderColor = isSubscribed ? 'border border-indigo' : '';
      break;
    case HotelPlan.PROFESSIONAL:
      borderColor = isSubscribed ? 'border border-orange' : '';
      break;
    case HotelPlan.SMALLBUSINESS:
    default:
      borderColor = '';
  }
  return `bg-white ${borderColor}`;
}

export default function CardSubscription({
  type,
  isSubscribed,
  isMonthly,
  isProliteMonthly,
  isProliteYearly,
  isLoading,
  price,
  monthPrice,
  currentSubscriptionType,
  current = HotelPlan.DEFAULT_SUBSCRIPTION,
  buttonDisabled,
  currencySymbol,
  onclick
}: CardSubscriptionProps) {
  const { t } = useTranslation();
  const cardStyles = getStyleCard({
    type,
    isSubscribed
  });
  const { hotelDetails: hotel } = useHotelDetails();
  const isPricingMonthly = usePaymentStore((state) => state.isMonthly);
  const {
    hasAdvancedUploadFeature,
    hasProUploadFeature,
    hasSmallBusinessFeature,
    hasStarterUploadFeature
  } = useFeaturesStore();

  const isFreeTrial = hotel?.state === HotelState.FREE_TRIAL;

  function getTrialType() {
    let trialText = '';
    if (isFreeTrial) {
      if (hasProUploadFeature() && type === HotelPlan.PROFESSIONAL) {
        trialText = 'You are trialling Professional';
      } else if (
        hasAdvancedUploadFeature() &&
        type === HotelPlan.ADVANCED &&
        !hasProUploadFeature()
      ) {
        trialText = 'You are trialling Advanced';
      } else if (
        hasSmallBusinessFeature() &&
        type === HotelPlan.SMALLBUSINESS &&
        !hasAdvancedUploadFeature() &&
        !hasProUploadFeature()
      ) {
        trialText = 'You are trialling Small Business';
      } else if (
        hasStarterUploadFeature() &&
        type === HotelPlan.STARTER &&
        !hasAdvancedUploadFeature() &&
        !hasProUploadFeature() &&
        !hasSmallBusinessFeature()
      ) {
        trialText = 'You are trialling Starter';
      }
    }
    return trialText ? (
      <div className="mt-4 flex w-full items-center gap-1 text-center text-orange">
        <Icon.Timer40s />
        <Typography
          element="h6"
          variant="paragraph-1"
          className="pr-1 text-center text-sm font-medium text-orange">
          {t(trialText)}
        </Typography>
      </div>
    ) : (
      ''
    );
  }

  function getButtonLabel({ type, current, isSubscribed, isMonthly }: ButtonLabelProps): string {
    const subscriptionType = hotel?.subscription_type;

    // Helper function to determine subscription type
    const getSubscriptionTypeLabel = (
      subscriptionType: SubscriptionType,
      current: number | undefined,
      isMonthly: boolean | undefined
    ): string => {
      switch (subscriptionType) {
        case SubscriptionType.YEARLY:
          return isMonthly
            ? t('Switch to Monthly Payment')
            : t(`You are on ${getTypeLabel(current)}`);
        case SubscriptionType.MONTHLY:
          return !isMonthly
            ? t('Switch to Yearly Payment')
            : t(`You are on ${getTypeLabel(current)}`);
        default:
          return '';
      }
    };

    switch (type) {
      case HotelPlan.STARTER:
        if (subscriptionType && current === HotelPlan.STARTER) {
          return getSubscriptionTypeLabel(subscriptionType, current, isMonthly);
        } else {
          return current === HotelPlan.PROFESSIONAL ||
            current === HotelPlan.ADVANCED ||
            current === HotelPlan.PRO_LITE
            ? t('Downgrade to Starter')
            : t('Subscribe to Starter');
        }
      case HotelPlan.ADVANCED:
      case HotelPlan.PRO_LITE:
        if (subscriptionType && current === type) {
          return getSubscriptionTypeLabel(subscriptionType, current, isMonthly);
        } else if (current === HotelPlan.PROFESSIONAL) {
          return t('Downgrade to Advanced');
        } else if (current === HotelPlan.STARTER) {
          return t('Upgrade to Advanced');
        } else {
          return t(`Subscribe to ${getTypeLabel(type)}`);
        }
      case HotelPlan.PROFESSIONAL:
        if (subscriptionType && current === HotelPlan.PROFESSIONAL) {
          return getSubscriptionTypeLabel(subscriptionType, current, isMonthly);
        } else if (
          [
            HotelPlan.STARTER,
            HotelPlan.ADVANCED,
            HotelPlan.SMALLBUSINESS,
            HotelPlan.PRO_LITE
          ].includes(current as HotelPlan)
        ) {
          return t('Upgrade to Professional');
        } else {
          return t('Subscribe to Professional');
        }
      case HotelPlan.SMALLBUSINESS:
        if (isSubscribed) {
          return t('You are on Small Business');
        } else if (
          [HotelPlan.STARTER, HotelPlan.ADVANCED, HotelPlan.PROFESSIONAL].includes(
            current as HotelPlan
          )
        ) {
          return t('Downgrade to Small Business');
        } else {
          return t('Subscribe to Small Business');
        }
      default:
        return '';
    }
  }

  return (
    <div
      className={clsx(
        'max-w[300px] h-full max-h-[400px] min-w-[280px] rounded-[1rem] p-6',
        cardStyles
      )}>
      <div className="flex justify-between">
        <Typography
          element="h6"
          variant="h6"
          color={
            type !== HotelPlan.ADVANCED && type !== HotelPlan.PROFESSIONAL
              ? 'copyTextGrey'
              : isSubscribed
              ? 'indigo'
              : type == HotelPlan.PROFESSIONAL || type == HotelPlan.ADVANCED
              ? 'copyTextGrey'
              : 'copyTextGrey'
          }>
          {getTypeLabel(type)}
        </Typography>
        {type === HotelPlan.PROFESSIONAL ? (
          <div className={cn('rounded bg-orange p-1 px-2 text-sm font-medium text-white')}>
            {t('Most Valuable')}
          </div>
        ) : null}
      </div>

      <div className="my-4">
        <div className="flex items-end gap-4">
          <div className="flex items-end justify-end gap-1">
            {isLoading ? (
              <Skeleton className="h-12 w-[80px] rounded-[2px] px-5" />
            ) : (
              <Typography
                element="h3"
                variant="h3"
                color={priceColorAndSymbolColor(type, isSubscribed)}>
                {isMonthly
                  ? `${currencySymbol}${formatPrice(price as number)}`
                  : `${currencySymbol}${formatPrice(monthPrice as number)}`}
              </Typography>
            )}
          </div>
          <div className="pb-2">
            <Typography
              element="p"
              variant="paragraph-3"
              color={
                type === HotelPlan.STARTER || type === HotelPlan.SMALLBUSINESS || isSubscribed
                  ? 'copyTextGrey'
                  : type == HotelPlan.PROFESSIONAL || type == HotelPlan.ADVANCED
                  ? 'copyTextGrey'
                  : 'copyTextGrey'
              }
              className="font-light">
              {t('per month')}
            </Typography>
            <Typography
              element="p"
              variant="paragraph-3"
              color={
                type !== HotelPlan.ADVANCED && type !== HotelPlan.PROFESSIONAL
                  ? 'copyTextGrey'
                  : isSubscribed
                  ? 'copyTextGrey'
                  : type == HotelPlan.PROFESSIONAL || type == HotelPlan.ADVANCED
                  ? 'copyTextGrey'
                  : 'copyTextGrey'
              }>
              {isMonthly
                ? t('billed monthly')
                : `${currencySymbol}${formatPrice(price as number)} ${t('billed yearly')}`}
            </Typography>
          </div>
        </div>
      </div>

      <div className="my-4 min-h-[150px]">
        <Typography
          element="p"
          variant="paragraph-2"
          color={
            type !== HotelPlan.ADVANCED && type !== HotelPlan.PROFESSIONAL
              ? 'copyTextGrey'
              : isSubscribed
              ? 'copyTextGrey'
              : type == HotelPlan.PROFESSIONAL || type == HotelPlan.ADVANCED
              ? 'copyTextGrey'
              : 'copyTextGrey'
          }
          className="font-light">
          {t(`${getDescription(type)}`)}
        </Typography>

        {isFreeTrial ? <>{getTrialType()}</> : null}

        {type === HotelPlan.PRO_LITE && isProliteYearly && !isMonthly ? (
          <div className="mt-4 flex items-center gap-1 text-orange">
            <Icon.Add />
            <Typography element="p" className="pr-1 text-sm font-medium text-orange">
              {t('Professional Lite')}
            </Typography>
          </div>
        ) : null}
        {type === HotelPlan.PRO_LITE && isProliteMonthly && isMonthly ? (
          <div className="mt-4 flex items-center gap-1 text-orange">
            <Icon.Add />
            <Typography element="p" className="pr-1 text-sm font-medium text-orange">
              {t('Professional Lite')}
            </Typography>
          </div>
        ) : null}
      </div>

      <Button
        size="large"
        onClick={onclick}
        disabled={buttonDisabled}
        className={'w-full bg-indigo text-white'}>
        {getButtonLabel({
          type,
          current,
          isSubscribed,
          currentSubscriptionType,
          isMonthly: isPricingMonthly
        })}
      </Button>
    </div>
  );
}
