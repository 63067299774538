import React from 'react';

export const GenieAlt = (props: React.SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="93"
      height="100"
      viewBox="0 0 93 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M48.4002 89.3359L48.3598 89.3469L48.346 89.3628C47.4969 90.3369 45.2446 91.4751 43.057 92.1755C42.5727 92.3306 42.1205 92.4484 41.6632 92.5294L41.6611 92.5298C40.9293 92.6638 40.1869 92.7026 39.323 92.6382L39.2267 92.6311L39.2221 92.6325C37.4747 92.4954 34.7613 92.0399 32.4789 91.4217C31.3173 91.1071 30.1692 90.7363 29.2462 90.3526C28.3217 89.9683 27.6309 89.5741 27.3709 89.2126L27.3709 89.2126C27.0413 88.756 27.6292 88.1764 28.4423 87.6246C28.8464 87.3504 29.3011 87.0863 29.7138 86.8516C29.7856 86.8108 29.8563 86.7708 29.9252 86.7318C30.2481 86.5491 30.5339 86.3874 30.7269 86.2598C32.039 86.2621 33.3835 86.2077 34.5371 86.0887C35.7413 85.9644 36.7613 85.7671 37.3001 85.4823L37.3002 85.4823C38.1418 85.0371 38.1184 84.5323 37.647 84.0507C38.1048 83.9499 38.5438 83.847 38.9695 83.7472C39.0611 83.7257 39.1521 83.7044 39.2426 83.6832C39.7713 83.5597 40.281 83.4428 40.7903 83.3408C41.8088 83.1369 42.8116 82.9957 43.9293 82.9821L43.9293 82.9821L43.9338 82.982C46.0451 82.9394 48.7386 83.2078 50.8286 83.7501L50.8296 83.7504C52.8686 84.2705 54.6997 84.8056 55.9256 85.2865C56.5387 85.5271 56.9951 85.752 57.2521 85.953C57.5098 86.1545 57.559 86.3255 57.392 86.4629C57.2173 86.6031 56.9883 86.7353 56.7098 86.8567L56.7097 86.8567L56.7075 86.8577C55.959 87.1997 54.7896 87.5722 53.3478 87.984C52.385 88.259 51.3071 88.5498 50.1588 88.8596C49.5881 89.0135 49.0001 89.1722 48.4002 89.3359Z"
        fill="white"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M53.6113 88.0574C54.9748 87.6681 56.1167 87.308 56.8919 86.9716C59.4228 88.0308 60.8732 89.334 60.7852 90.704C60.7531 91.2044 60.4754 91.6102 60.2081 92.0007C60.1023 92.1552 59.9982 92.3074 59.9116 92.462C59.6066 93.0066 59.5334 93.5669 60.4148 94.3335C61.2982 95.1018 62.7009 95.3998 64.0603 95.4108C65.4012 95.4216 66.6361 95.1513 67.1676 94.8241L67.1679 94.824C67.4326 94.6595 68.3327 94.1392 69.581 93.7445C70.2052 93.5472 70.9047 93.3851 71.6436 93.312C72.3739 93.2398 73.1529 93.2534 73.9599 93.413C74.7843 93.5762 75.2039 93.7551 75.3706 93.9406C75.5383 94.1272 75.4573 94.3279 75.2326 94.5383C75.0077 94.7491 74.6441 94.965 74.2625 95.1804C74.2023 95.2144 74.1417 95.2484 74.081 95.2824C73.7604 95.462 73.4415 95.6407 73.2082 95.8104C72.7627 96.1344 72.6996 96.3682 72.8252 96.5307C72.8852 96.6082 72.9882 96.6691 73.1106 96.7146C72.7238 96.6964 72.3845 96.6207 72.1909 96.5004C71.9225 96.3335 71.9258 96.0724 72.5487 95.754C73.3674 95.3354 73.6087 94.9994 73.5179 94.7443C73.4268 94.4885 73.0025 94.3181 72.5209 94.2346C71.9953 94.1436 71.4573 94.2123 71.0275 94.3251C70.5958 94.4384 70.2244 94.607 69.9813 94.7551C69.8927 94.8091 69.7788 94.8975 69.6394 95.0055C69.5855 95.0474 69.5278 95.0921 69.4662 95.139C69.2424 95.3095 68.9616 95.5133 68.6124 95.7213C67.9105 96.1395 66.9471 96.5659 65.6457 96.7768C64.3072 96.9936 63.0356 97.122 61.7552 97.0951C60.4785 97.0682 59.1673 96.8862 57.7547 96.4666C56.538 96.1052 55.747 95.8434 55.0442 95.5101C54.3401 95.1761 53.7205 94.7684 52.8563 94.1135C51.9771 93.4473 50.7468 93.0603 48.9486 92.8362C47.2709 92.627 45.1079 92.561 42.3045 92.5328C42.6415 92.4562 42.9751 92.3625 43.3174 92.2529C45.5333 91.5434 47.8107 90.3954 48.6882 89.4025C49.2716 89.2434 49.8452 89.0887 50.4033 88.9381C51.5564 88.6271 52.6429 88.334 53.6113 88.0574Z"
        fill="white"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M11.7373 82.1863C12.487 81.0191 12.7305 80.2399 12.6725 79.612C13.3135 79.6417 14.2382 79.6406 15.1785 79.6156C16.2368 79.5875 17.3411 79.5284 18.1166 79.4443C18.3292 79.4243 18.5363 79.4041 18.7405 79.3842C19.589 79.3014 20.3886 79.2234 21.3333 79.1875C22.5004 79.143 23.8931 79.1629 25.8477 79.3255C27.774 79.4856 29.4215 79.9107 30.5572 80.3519C31.124 80.5721 31.5592 80.7948 31.8356 80.9877C31.9739 81.0842 32.0713 81.1724 32.1261 81.2486C32.1586 81.2937 32.1754 81.3335 32.1782 81.3675C32.1086 81.3593 32.0341 81.3504 31.9546 81.3408C31.1623 81.2454 29.8714 81.0901 27.9743 81.0804C26.8061 81.0744 25.0988 81.2062 23.687 81.3555C22.9782 81.4305 22.3354 81.5108 21.8626 81.5823C21.6269 81.6179 21.4283 81.652 21.2837 81.6829C21.2117 81.6983 21.1478 81.7141 21.0985 81.7299C21.0534 81.7444 21 81.7654 20.9871 81.7912L20.987 81.7913C20.9278 81.9114 20.7757 82.0911 20.5867 82.3144C20.5705 82.3334 20.5541 82.3528 20.5375 82.3724C20.3273 82.621 20.083 82.9153 19.8838 83.2283C19.4865 83.8526 19.2628 84.5594 19.8642 85.1337C20.2793 85.5349 20.8708 85.7771 21.5114 85.945C21.9826 86.0685 22.4841 86.1521 22.9384 86.2278C23.0783 86.2511 23.2138 86.2737 23.3424 86.2964C22.4194 86.4586 20.9608 86.6556 19.3605 86.7008C17.6165 86.7501 15.7018 86.6196 14.1124 86.0633C10.3357 84.7378 10.9632 83.3916 11.7373 82.1863Z"
        fill="white"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M32.3673 81.4439C32.4011 81.451 32.4353 81.4584 32.4697 81.4659C32.4684 81.4664 32.4674 81.4669 32.4666 81.4672C32.4597 81.47 32.4521 81.4723 32.4568 81.4708L32.3644 81.4452L32.3673 81.4439ZM32.27 81.4898L32.2723 81.4887C34.0787 81.8722 36.5864 82.7415 37.5511 83.6935L37.5512 83.6937C38.1443 84.2719 38.1574 84.8768 37.1049 85.42L37.1048 85.42C36.4768 85.7445 35.2799 85.9935 33.7933 86.1667C32.31 86.3394 30.5603 86.4339 28.8605 86.4534L28.86 86.4534C26.8258 86.4788 24.8774 86.395 23.5673 86.2131C23.4135 86.1856 23.257 86.1595 23.099 86.1331C22.6518 86.0586 22.1925 85.9821 21.7485 85.8657C21.1541 85.7098 20.5934 85.4829 20.1947 85.0975L20.1945 85.0974C19.619 84.5478 19.8277 83.8623 20.2229 83.2413C20.42 82.9317 20.662 82.6401 20.872 82.3917C20.8894 82.3712 20.9065 82.3509 20.9234 82.331C21.1079 82.1132 21.2633 81.9297 21.3266 81.804C21.3295 81.8028 21.3349 81.8007 21.3444 81.7976C21.3697 81.7895 21.4114 81.7787 21.4728 81.7656C21.5951 81.7394 21.7746 81.7082 22.0028 81.6737C22.4577 81.605 23.0852 81.5265 23.7841 81.4525C25.1877 81.304 26.845 81.1778 27.948 81.1834C29.7764 81.1928 31.0031 81.34 31.7993 81.4356C31.977 81.457 32.1333 81.4757 32.27 81.4898Z"
        fill="white"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M30.6406 81.0609C30.9048 80.6331 28.14 79.2238 24.2109 78.8998C20.2819 78.5757 18.549 78.816 16.3788 79.0196C14.8691 79.1838 12.0227 79.2542 10.8008 79.173C10.8828 79.8073 10.6455 80.598 9.87618 81.8038C9.10682 83.0096 8.45842 84.3787 12.2863 85.7222C15.542 86.8619 20.1636 86.2609 21.9831 85.9184C21.9082 85.9054 21.8535 85.8941 21.7855 85.883C20.6407 85.6778 19.1501 85.5262 18.3362 84.7396C17.1593 83.6157 19.201 81.9547 19.4647 81.4197C19.5387 81.2719 23.9967 80.7443 26.268 80.7559C28.5392 80.7675 29.9125 80.9899 30.6411 81.0649C30.6342 81.063 30.6342 81.063 30.6406 81.0609Z"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4733 85.8351C21.5684 85.8499 21.6704 85.8666 21.7857 85.8831C23.1278 86.0701 25.1089 86.1545 27.1624 86.129C30.5919 86.0895 34.2419 85.7479 35.5584 85.0678C36.6442 84.5074 36.6238 83.8864 36.0221 83.2998C35.038 82.3285 32.4743 81.4449 30.6408 81.061C30.6201 81.0553 30.593 81.0517 30.5723 81.046"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M86.9046 54.3225L86.904 54.3226C85.1547 54.6071 82.9299 54.6009 80.3444 54.4634C78.6193 54.3716 76.7392 54.2218 74.7375 54.0624C73.7399 53.9829 72.712 53.901 71.6581 53.8227L71.6225 53.8201L71.5908 53.8363C67.8112 55.7729 62.2254 56.9359 58.0566 56.9358L58.0545 56.9359C57.2519 56.9497 56.4509 56.8625 55.6702 56.6763C54.5605 56.3825 53.7424 55.8265 53.1313 54.8688C51.9236 52.9641 50.7987 49.2248 50.6113 45.4304C50.5177 43.5335 50.6115 41.4928 50.9504 39.6143C51.2897 37.7341 51.873 36.0261 52.7516 34.7869C53.3099 34.002 54.1759 33.3354 55.2133 32.7635C56.2497 32.192 57.4494 31.7193 58.6666 31.3184C59.8834 30.9177 61.1143 30.5899 62.2127 30.3073C62.4006 30.2589 62.5847 30.2119 62.7643 30.166C63.6324 29.9441 64.3951 29.7492 64.9621 29.5668L64.9912 29.5574L65.0082 29.5405C66.2103 30.7255 67.6235 31.7807 69.0583 32.4761C70.5271 33.1879 72.0324 33.5293 73.3604 33.2288L73.3606 33.2287C75.4747 32.7474 76.9983 31.3298 78.0524 29.5745C78.8163 29.7099 79.5684 29.8193 80.2982 29.9254C80.4356 29.9453 80.5723 29.9652 80.708 29.9851C81.5723 30.1118 82.4019 30.24 83.1857 30.4095C84.7537 30.7486 86.1253 31.2504 87.2065 32.2212L87.2065 32.2212L87.2092 32.2235C89.2885 33.9872 90.9295 37.1247 91.1718 40.4793L91.1719 40.4804C91.4349 43.7122 91.4623 46.8099 91.1011 49.2262C90.9205 50.4346 90.6436 51.466 90.2546 52.2567C89.8658 53.0471 89.371 53.5856 88.7596 53.831C88.1595 54.0574 87.538 54.222 86.9046 54.3225Z"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M86.9045 54.3225L86.904 54.3226C85.1547 54.6071 82.9299 54.6009 80.3444 54.4634C78.6193 54.3716 76.7392 54.2219 74.7376 54.0624C73.7399 53.9829 72.712 53.901 71.6581 53.8227L71.6225 53.8201L71.5908 53.8363C67.8112 55.7729 62.2254 56.9359 58.0566 56.9358L58.0545 56.9359C57.2519 56.9497 56.4509 56.8625 55.6701 56.6763C54.5605 56.3825 53.7424 55.8265 53.1313 54.8688C51.9236 52.9641 50.7987 49.2248 50.6113 45.4304C50.5177 43.5335 50.6115 41.4928 50.9504 39.6143C51.2897 37.7341 51.873 36.0261 52.7516 34.7869C53.3099 34.002 54.1759 33.3354 55.2133 32.7635C56.2497 32.192 57.4494 31.7193 58.6666 31.3184C59.8834 30.9177 61.1143 30.5899 62.2127 30.3073C62.4006 30.2589 62.5847 30.2119 62.7643 30.166C63.6324 29.9441 64.3951 29.7492 64.9621 29.5668L64.9912 29.5574L65.0082 29.5405C66.2103 30.7255 67.6235 31.7807 69.0583 32.4761C70.5271 33.1879 72.0324 33.5293 73.3604 33.2288L73.3606 33.2287C75.4747 32.7474 76.9983 31.3298 78.0524 29.5745C78.8163 29.7099 79.5684 29.8193 80.2982 29.9254C80.4356 29.9453 80.5723 29.9652 80.708 29.9851C81.5723 30.1118 82.4019 30.24 83.1857 30.4095C84.7537 30.7486 86.1253 31.2504 87.2065 32.2212L87.2065 32.2212L87.2092 32.2235C89.2885 33.9872 90.9295 37.1247 91.1718 40.4793L91.1719 40.4804C91.4349 43.7122 91.4623 46.8099 91.1011 49.2262C90.9205 50.4346 90.6435 51.466 90.2546 52.2567C89.8658 53.0471 89.371 53.5856 88.7596 53.831C88.1595 54.0574 87.538 54.222 86.9045 54.3225Z"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M86.7694 54.6004C85.0199 54.8615 82.8348 54.8499 80.3314 54.7167C78.6029 54.6248 76.7171 54.4746 74.7133 54.315C73.7275 54.2364 72.7133 54.1556 71.6752 54.0784C67.8555 56.0261 62.2491 57.1894 58.0581 57.1896C57.3759 57.2012 56.6947 57.1418 56.0255 57.0124C58.5869 59.641 60.4076 61.7703 61.3082 63.859C62.2489 66.0407 62.1827 68.1697 60.9464 70.7565C59.7241 73.3139 59.0352 74.9832 58.6505 76.5267C58.2663 78.0682 58.1839 79.4902 58.1839 81.5566C58.1839 83.964 58.8234 85.6338 59.9208 86.8483C61.0213 88.0665 62.595 88.8408 64.491 89.4307C66.3597 90.0121 68.5758 89.6819 70.5042 89.1497C71.4661 88.8843 72.3513 88.5701 73.0796 88.2981C73.2841 88.2217 73.4772 88.1483 73.6566 88.0801C74.1112 87.9072 74.4776 87.7679 74.7183 87.6991C75.3861 87.5083 76.2257 87.3777 76.9461 87.4455C77.659 87.5126 78.3096 87.7822 78.4967 88.4451C78.6742 89.074 78.5423 89.8941 77.8577 90.6594C77.1751 91.4225 75.9472 92.1278 73.9329 92.5543C72.3587 92.8877 71.5233 93.621 71.2443 94.3453C71.0095 94.9548 71.1587 95.5793 71.6224 96.0059C71.6022 95.7315 71.6782 95.3958 71.9207 95.0427C72.2898 94.5053 73.0419 93.9285 74.4284 93.4394C74.4285 93.4394 74.4286 93.4393 74.4288 93.4393L74.471 93.5589C75.1889 93.3033 76.0096 93.0961 76.8493 92.8841C80.0253 92.0825 83.4727 91.2123 82.6544 87.3884C81.6197 82.5536 73.3422 84.6983 71.8372 85.1309L86.7694 54.6004ZM86.7694 54.6004C85.8416 59.7938 83.1403 64.7125 78.8091 68.4422C77.2368 69.7961 75.7263 70.6731 74.2818 71.5118C73.7128 71.8423 73.1539 72.1667 72.6056 72.5121C70.6678 73.7325 68.8931 75.1982 67.3515 78.0727C66.5798 79.5116 66.2858 80.7361 66.3335 81.7533C66.3812 82.7727 66.772 83.5764 67.3552 84.1688C68.5159 85.3477 70.4244 85.6776 71.8729 85.2527"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M79.891 20.1813C79.8538 20.0889 79.8145 19.9856 79.7715 19.8724C79.4676 19.0731 78.9756 17.7794 77.7162 16.3713C76.9429 15.5069 75.4654 14.5576 74.1402 13.8627C73.4756 13.5141 72.8435 13.2265 72.3495 13.0433C72.1031 12.9519 71.8873 12.8851 71.7173 12.8504C71.6326 12.8331 71.555 12.8228 71.4884 12.8227C71.4263 12.8226 71.3532 12.8311 71.2949 12.8708L71.2944 12.8712C70.9559 13.1047 70.4036 13.4066 69.7207 13.7799C69.6679 13.8087 69.6144 13.838 69.5601 13.8677C68.8069 14.2797 67.9186 14.7701 67.0146 15.336C65.2104 16.4654 63.3247 17.9065 62.3206 19.6471L62.3205 19.6473C61.623 20.8607 61.4329 21.8407 61.4559 22.6875C61.4728 23.3104 61.6067 23.8636 61.7288 24.3683C61.7704 24.54 61.8106 24.7061 61.8444 24.8674C61.8456 24.8771 61.847 24.8866 61.8485 24.8959C60.8182 24.5962 59.2831 23.9666 58.0424 22.8575C56.7269 21.6817 55.7428 19.9687 56.0379 17.5247L56.0379 17.5246C56.7359 11.7177 60.4881 9.10229 63.9745 6.92212C67.3814 4.79166 69.4873 3.18566 70.9944 1.70255C71.3467 2.24465 71.9935 2.94248 72.717 3.59648C73.5042 4.30815 74.3965 4.98095 75.1247 5.3552C75.3221 5.46851 75.5167 5.57713 75.709 5.68446C76.4814 6.11557 77.2167 6.52598 77.947 7.13646C78.8561 7.89632 79.7585 8.96848 80.6881 10.7762C81.607 12.5632 81.6796 14.7501 81.3648 16.5945C81.2077 17.5154 80.9547 18.3457 80.6665 18.9918C80.5224 19.3149 80.3703 19.59 80.2184 19.8065C80.1055 19.9675 79.9951 20.0926 79.891 20.1813Z"
        stroke="white"
        strokeWidth="0.253774"
      />
      <path
        d="M79.851 20.3689C80.152 22.5888 79.7099 26.483 77.9979 29.4177C76.9538 31.1955 75.4394 32.6253 73.3324 33.105C70.7551 33.6882 67.4347 31.7881 65.0079 29.3613C63.5499 27.9127 62.4212 26.2666 61.9697 24.8557C61.6969 23.5482 61.0573 22.1091 62.4306 19.7199C64.4059 16.296 69.8803 14.0103 71.3665 12.985C71.7804 12.7028 76.1073 14.7722 77.6217 16.4653C79.1361 18.1584 79.5217 19.6728 79.8321 20.3783L79.851 20.3689Z"
        stroke="white"
        strokeWidth="1.52264"
      />
      <path
        d="M70.9277 1.87061C72.8428 4.40342 75.2644 10.6551 71.1131 13.1756"
        stroke="white"
        strokeWidth="1.52264"
      />
      <path
        d="M64.9232 29.4458C62.2048 30.3206 54.9432 31.487 52.648 34.7133C50.8609 37.2342 50.2965 41.6363 50.4846 45.4364C50.6727 49.2366 51.8015 53.0085 53.0243 54.9367"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M60.8008 33.7471C62.4224 34.1564 65.3239 34.773 66.2796 36.1894"
        stroke="white"
        strokeWidth="1.52264"
      />
      <path
        d="M82.0137 33.3765C80.3691 33.6811 77.0059 34.6341 76.7092 36.009"
        stroke="white"
        strokeWidth="1.52264"
      />
      <path
        d="M86.9334 54.3726C86.9334 54.4008 86.924 54.4196 86.924 54.4478C86.021 59.7341 83.2932 64.7477 78.8911 68.5384C74.489 72.3291 70.5195 72.4326 67.4625 78.1327C64.4055 83.8329 69.0145 85.9588 71.8364 85.131C73.3414 84.6983 81.6189 82.5537 82.6536 87.3885C83.6883 92.2233 77.9034 92.3362 74.4701 93.559C71.7235 94.5279 71.573 95.8165 71.8364 96.3244C70.3784 95.4402 70.7076 93.1075 73.9058 92.4303C77.9034 91.5837 78.703 89.646 78.3737 88.4796C78.0445 87.3133 76.0692 87.4449 74.7523 87.8212C73.4355 88.1974 68.262 90.7371 64.4525 89.5519C60.643 88.3667 58.0562 86.4197 58.0562 81.5566C58.0562 77.4179 58.3855 75.8188 60.8311 70.7018C63.2767 65.5848 58.6953 60.8049 57.0273 57.0983"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M53.0244 54.9369C53.6546 55.9245 54.5012 56.4983 55.6394 56.7993C56.4309 56.9882 57.2431 57.0767 58.0568 57.0627C62.2425 57.0627 67.8487 55.8963 71.6488 53.9492"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M85.8529 43.3672C80.9359 43.3672 76.1174 48.9438 71.6494 53.9492"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M71.6494 53.9491C77.9798 54.4194 83.3978 55.0214 86.9252 54.4476C87.5676 54.3458 88.1979 54.1787 88.8064 53.9491C91.4119 52.905 91.8258 46.9414 91.2991 40.4699C91.0545 37.0837 89.399 33.9138 87.292 32.1266C85.0815 30.1419 81.7047 30.0948 78.0551 29.4458"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M57.584 48.2026C62.0708 45.3337 70.3716 44.8667 81.3059 44.8667"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M59.8082 47.0905C59.9304 45.8677 59.6538 42.8796 59.4375 41.9014"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M83.1597 43.9402C83.1597 42.9619 83.2123 42.2061 82.7891 40.4189"
        stroke="white"
        strokeWidth="1.52264"
        strokeMiterlimit="10"
      />
      <path
        d="M79.8509 20.3688C81.0831 19.5881 82.6727 14.3488 80.8009 10.7274C78.9291 7.106 77.1513 6.38172 75.1854 5.25297C73.7556 4.51928 71.6486 2.591 71.0184 1.50928C69.5134 3.01428 67.4064 4.64156 63.9073 6.82381C60.4081 9.00606 56.6174 11.6492 55.912 17.5187C55.31 22.504 59.9378 24.5264 62.0072 25.0813C61.9978 24.9967 61.979 24.9308 61.9696 24.8556C61.6968 23.5481 61.0572 22.109 62.4305 19.7198C64.4058 16.2959 69.8802 14.0102 71.3664 12.9849C71.7803 12.7027 76.1072 14.7721 77.6216 16.4652C79.136 18.1583 79.5216 19.6727 79.8321 20.3782L79.8509 20.3688Z"
        stroke="white"
        strokeWidth="1.52264"
      />
      <path
        d="M61.8662 24.517C61.8944 24.6205 61.9321 24.7333 61.9697 24.8556C62.4212 26.2666 63.5499 27.9127 65.0079 29.3612C67.4347 31.788 70.7551 33.6881 73.3324 33.1049C75.4394 32.6252 76.9538 31.1954 77.9979 29.4177C79.7099 26.4829 80.152 22.5887 79.851 20.3688C79.8416 20.3406 79.8416 20.3124 79.8322 20.2842"
        stroke="white"
        strokeWidth="1.52264"
      />
    </svg>
  );
};
