import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { useModal } from '@common/hooks/useModal';
import { AddNewReservationStatusModal } from '@pages/Client/Account/Integrations/components/AddNewReservationStatus';
import { UpdateReservationStatusModal } from '@pages/Client/Account/Integrations/components/UpdateReservationStatusModal';
import { useGetReservationsStatus } from '@pages/Client/Account/Integrations/hooks/useGetReservationsStatus';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { ReservationStatusSchema } from '@pages/Client/Calendar/utils/validateBaseSettings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const ReservationStatusMappingTable = () => {
  const { t } = useTranslation();
  const { isLoading, reservationStatus } = useGetReservationsStatus();
  const { pricingSettings } = useRoomPrices();

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const [selectedStatus, setSelectedStatus] = useState<z.infer<
    typeof ReservationStatusSchema
  > | null>(null);

  const columns = [
    {
      header: 'Status in pAir',
      accessorKey: 'status_description',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Reservation Status',
      accessorKey: 'value',
      cell: (row: any) => {
        const statusValue = row.getValue() as number;
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {reservationStatus?.find((status) => status.value === statusValue)?.key ?? '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <div className="my-8">
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          setSelectedStatus(row.row.original);
          editClick();
        }}
        headerComponent={
          <div className="my-3 flex items-center justify-between">
            <Typography variant="h6" element="h6" color="darkGrey">
              {t('Reservation Status Mapping')}
            </Typography>
          </div>
        }
        actionComponents={
          <div className="flex items-center gap-3">
            <Button onClick={() => addClick()}>{t('Add new Status')}</Button>
          </div>
        }
        skeletonCount={10}
        columns={columns}
        data={pricingSettings?.reservation_status ?? []}
        isHover
        isRowClickable
      />
      <UpdateReservationStatusModal
        isOpen={editOpen}
        onClose={editClose}
        selectedStatus={selectedStatus}
      />
      <AddNewReservationStatusModal isOpen={addOpen} onClose={addClose} />
    </div>
  );
};
