import { HotelPlan } from '@pages/Client/hooks/useSubscription';

export function getMemberShipKey(key: number): string {
  const membershipKeys: Record<number, string> = {
    2: 'starter',
    3: 'advanced',
    4: 'professional',
    5: 'small-business',
    6: 'pro-lite'
  };

  return membershipKeys[key] || '';
}

const descriptions: Record<number, string> = {
  [HotelPlan.STARTER]:
    'Our starter solution helping you to automate your pricing. Covering all the basics you need for great pricing.',
  [HotelPlan.PRO_LITE]:
    'Our always-up-to-date and never-late solution that lets you maximize your opportunities. The perfect solution for everyone looking for a full-time revenue genie.',
  [HotelPlan.ADVANCED]:
    'Our always-up-to-date and never-late solution that lets you maximize your opportunities. The perfect solution for everyone looking for a full-time revenue genie.',
  [HotelPlan.PROFESSIONAL]:
    'More information, more control, and more service. Ideal for either an expert revenue manager or if you would like the help and support to become one.',
  [HotelPlan.SMALLBUSINESS]:
    'Our starter solution helping you to automate your pricing. Covering all the basics you need for great pricing.'
};

export function getDescription(type?: number): string {
  if (type && type in descriptions) {
    return descriptions[type];
  } else {
    return '';
  }
}

export function getTypeLabel(type?: number): string {
  const typeLabels: Record<number, string> = {
    [HotelPlan.STARTER]: 'Starter',
    [HotelPlan.PRO_LITE]: 'Advanced',
    [HotelPlan.ADVANCED]: 'Advanced',
    [HotelPlan.PROFESSIONAL]: 'Professional',
    [HotelPlan.SMALLBUSINESS]: 'Small Business'
  };

  return type ? typeLabels[type] || '' : '';
}

export function formatPrice(
  number: number,
  locale = 'en-US',
  options?: Intl.NumberFormatOptions
): string {
  try {
    return new Intl.NumberFormat(locale, options).format(number);
  } catch (error) {
    return new Intl.NumberFormat('en-US').format(number);
  }
}
