interface MonthlyData {
  [month: string]: {
    standard: number;
  };
}

interface TransformedData {
  month: string;
  value: number;
}

export function transformedDataMonthly(data: MonthlyData | undefined): TransformedData[] {
  if (typeof data === 'undefined') {
    return []; // Return an empty array when data is undefined
  }

  const monthNames = Object.keys(data);

  const transformedData = monthNames.map((month) => ({
    month: month.slice(0, 3),
    value: data[month].standard
  }));

  return transformedData;
}
