import { updateCompetitor } from '@common/api/hotel';
import { UpdateCompetitor } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { MarketIntelligenceQueryKeys } from '@common/types/query-keys';
import { useScrapeTopTwenty } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useScrapeTopTwenty';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateCompetitors = (isNotification: boolean) => {
  const { scrapeTopTwenty } = useScrapeTopTwenty();

  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const getNotifications = () => {
    if (isNotification) {
      addNotification('success', 'Successfully update settings');
    }
  };

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (competitor: UpdateCompetitor) => {
      return updateCompetitor(competitor);
    },
    onSuccess: () => {
      getNotifications();
      if (isNotification) {
        scrapeTopTwenty(false);
      } else {
        scrapeTopTwenty(true);
      }
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [MarketIntelligenceQueryKeys.GET_HOTEL_COMPETITOR]
        });
        queryClient.invalidateQueries({
          queryKey: [MarketIntelligenceQueryKeys.GET_COMPETITOR_SETUP]
        });
      }, 500);
    },
    onError: () => {
      addNotification('fail', 'Failed to update settings');
    }
  });

  return {
    updateCompetitors: mutate,
    isLoading,
    isSuccess
  };
};
