import * as z from 'zod';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';

export const baseSchemaDerivationSchema = z.object({
  id: z.number(),
  value: z.union([z.number(), z.nan(), z.null()]).optional(),
  baseValue: z.number().or(z.string()).optional(),
  isReferenceRoom: z.boolean().default(false),
  isBulkEdit: z.boolean().default(true)
});

/**
 * Form Validation Logic for Derivation Adjustment Schema:
 *
 * - Validation Rule:
 *   - Condition:
 *     - The 'value' must not have any decimal places.
 *   - Message:
 *     - If validation fails, the error message will be 'Value is not allowed.'
 *
 * This rule is applied to 'derivationAdjustmentZodOutputSchema', which is based on 'baseSchemaDerivationSchema'.
 * The validation ensures that the 'value' field in the form data is an integer without any fractional part.
 */

export const derivationAdjustmentZodOutputSchema = baseSchemaDerivationSchema.refine(
  (val) => validateNoDecimals(val?.value),
  { message: 'Value is not allowed.' }
);

export type Derivation = z.infer<typeof baseSchemaDerivationSchema>;
