import { Checkbox } from '@mantine/core';
import { Input } from '@common/components/atoms/Input';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import {
  numberRegex,
  PasswordValidationMessage,
  specialCharRegex
} from '@common/constants/password';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useAddNewAdminUser } from '@pages/Admin/RpgUsers/hooks/useAddNewAdminUser';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const schema = z.object({
  token: z.string().or(z.undefined()).nullable(),
  username: z.string().email(),
  password: z
    .string()
    .min(8, PasswordValidationMessage.Length)
    .max(50)
    .refine((value) => /[A-Z]/.test(value), {
      message: PasswordValidationMessage.UppercaseLetter,
      path: ['password']
    })
    .refine((value) => numberRegex.test(value), {
      message: PasswordValidationMessage.Number,
      path: ['password']
    })
    .refine((value) => specialCharRegex.test(value), {
      message: PasswordValidationMessage.SpecialChar,
      path: ['password']
    }),
  first_name: z.string(),
  last_name: z.string(),
  is_sales: z.boolean().optional(),
  sub_account_type: z.number().or(z.string()).nullable().optional()
});

export const AddNewAdminUserModal = ({ isOpen, onClose }: ModalProps) => {
  const { token } = useAuthStore();
  const { createAdminUser } = useAddNewAdminUser();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
    watch
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      token,
      is_sales: false
    }
  });

  const getRefineErrors = () => {
    if ((errors.password as any)?.password) {
      return (errors.password as any).password.message;
    }
    return '';
  };

  const onSubmit = (data: any) => {
    createAdminUser(data);
  };

  return (
    <Modal
      okText="Save"
      continueIntent="primary"
      size="xl"
      open={isOpen}
      disableCloseOnOk={!isValid}
      onClick={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        reset();
      }}
      children={
        <div>
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            Add New Admin User
          </Typography>
          <div className="flex w-full justify-between gap-4 ">
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="text"
                    value={value}
                    label="First Name *"
                    placeholder="Please enter first name"
                    onChange={onChange}
                    showClearButton={false}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="email"
                    value={value}
                    label="Email *"
                    placeholder="Please enter email"
                    onChange={onChange}
                    showClearButton={false}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
            </div>
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <Input
                    name={name}
                    background="grey"
                    type="text"
                    value={value}
                    label="Last Name *"
                    placeholder="Please enter last name"
                    onChange={onChange}
                    showClearButton={false}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <div>
                <div className="mb-1 flex items-center gap-1 ">
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <Icon.Info className="h-5 w-5 fill-grey" />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-xs">
                        1.Minimum of 8 characters. <br /> 2.Must include at least 1 number (0-9).{' '}
                        <br /> 3.Requires at least 1 uppercase letter (A-Z). <br /> 4.Must include
                        at least 1 special character (@,#,$,etc.).
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <Typography className="text-meta-1 text-grey" color="copyTextGrey">
                    Password*
                  </Typography>
                </div>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <Input
                      name={name}
                      background="grey"
                      type="text"
                      value={value}
                      placeholder="Please enter password"
                      onChange={onChange}
                      showClearButton={false}
                      error={!!error}
                      helperMessage={
                        errors ? (
                          <div className="flex items-center gap-2 text-error">
                            {errors.password ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {errors.password ? errors?.password?.message?.toString() : ''}
                              {(errors?.password as any)?.password && watch('password').length >= 8
                                ? getRefineErrors()
                                : ''}
                            </Typography>
                          </div>
                        ) : null
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 flex items-center gap-2">
            <Controller
              name="sub_account_type"
              control={control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    name={name}
                    fullWidth
                    value={value}
                    placeholder="Select Team"
                    hint="Team"
                    options={[
                      { label: 'Not Selected', value: null },
                      { label: 'Sales ', value: 1 },
                      { label: 'Support ', value: 3 }
                    ]}
                    onChange={(value) => onChange(value)}
                  />
                  <div className="mt-1">
                    {error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error?.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </div>
      }
    />
  );
};
