import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../auth';
import { axiosInstance } from '../axiosInstance';
import { DashboardDailyReportsPayload, DashboardDailyReportsResponse } from './types';

export const getDashboardDailyReports = async ({
  hotel_id,
  date_from,
  date_to
}: DashboardDailyReportsPayload) => {
  try {
    const { data } = await axiosInstance.get<DashboardDailyReportsResponse>(
      `/explorer/daily-reports/`,
      {
        params: {
          hotel_id,
          date_from,
          date_to
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
