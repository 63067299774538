export interface Tag {
  id: number;
  value: string;
}

export interface CloudBedsOptions {
  property_code: string;
  property_name: string;
}

export type GetOptionsParams = {
  data: any;
  provider: number | string;
};

export type METHOD = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
export type Payload = Record<string, any> | undefined;

export enum PMS_NAME_SSO {
  APALEO = 'apaleo',
  LOBBI = 'lobbi'
}
