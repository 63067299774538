import { create } from 'zustand';

type UpsellingState = {
  upsellingModalOpen: boolean;
  setUpsellingModalOpen: (value: boolean) => void;
};

export const useUpsellingStore = create<UpsellingState>((set) => ({
  upsellingModalOpen: false,
  setUpsellingModalOpen: (value) => set({ upsellingModalOpen: value })
}));
