export const Update = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9989 4.47795V9.47795C20.9989 9.75409 20.7749 9.97795 20.4986 9.97795H15.4952C15.2188 9.97795 14.9948 9.75409 14.9948 9.47795V9.18795C14.9929 9.05236 15.0472 8.92203 15.1449 8.82795L16.9261 7.04795C15.625 5.72624 13.8481 4.98067 11.9928 4.97795C8.18862 4.99196 5.09103 8.03751 5.01517 11.8383C4.93931 15.6391 7.91291 18.8057 11.7135 18.9713C15.514 19.1368 18.7521 16.2409 19.0076 12.448C19.0235 12.1836 19.2429 11.9775 19.5079 11.978H20.5086C20.645 11.982 20.7744 12.0395 20.8688 12.138C20.9608 12.2384 21.0078 12.3721 20.9989 12.508C20.7593 16.7447 17.5908 20.2387 13.3951 20.8926C9.19934 21.5466 5.11652 19.183 3.59715 15.2203C2.07777 11.2577 3.53469 6.77289 7.09346 4.45767C10.6522 2.14244 15.3458 2.62594 18.3571 5.61795L19.8482 4.12795C19.9423 4.03029 20.0727 3.97598 20.2084 3.97795H20.4986C20.7749 3.97795 20.9989 4.20181 20.9989 4.47795ZM12.8734 11.618V7.47795C12.8734 7.20181 12.6494 6.97795 12.373 6.97795H11.6125C11.3362 6.97795 11.1122 7.20181 11.1122 7.47795V12.138C11.1171 12.2692 11.1706 12.3939 11.2623 12.488L14.5545 15.778C14.6485 15.8726 14.7764 15.9258 14.9098 15.9258C15.0432 15.9258 15.1711 15.8726 15.265 15.778L15.7954 15.248C15.8901 15.1541 15.9434 15.0263 15.9434 14.893C15.9434 14.7596 15.8901 14.6318 15.7954 14.538L12.8734 11.618Z"
      />
    </svg>
  );
};
