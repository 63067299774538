import { deletePartner } from '@common/api/account';
import { useNotificationsStore } from '@common/store/notifications';
import { PartnerQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeletePartner = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: number) => {
      return deletePartner(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Partner delete successfully');
      queryClient.invalidateQueries({ queryKey: [PartnerQueryKeys.GET_PARTNER_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to delete partner');
    }
  });

  return {
    deletePartner: mutate,
    isLoading,
    isSuccess
  };
};
