import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateForecastingCache } from '@common/api/dashboard';
import { useNotificationsStore } from '@common/store/notifications';
import { useDashboardPageStore } from '@pages/Client/Dashboard/store/dashboard';
import { ForecastQueryKeys } from '@common/types/query-keys';

export const useUpdateForecastingCache = () => {
  const { addNotification } = useNotificationsStore();
  const { propertyIds } = useDashboardPageStore();
  const queryClient = useQueryClient();

  const invalidateAllForecastingCache = () => {
    // Temporary fix until backend supports sync response
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: [ForecastQueryKeys.GET_FORECASTING_CACHE, propertyIds, true]
      });
      queryClient.invalidateQueries({
        queryKey: [ForecastQueryKeys.GET_FORECASTING_CACHE, propertyIds, false]
      });
    }, 3000);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return updateForecastingCache();
    },
    onSuccess: () => {
      invalidateAllForecastingCache();
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Forecasting Cache');
    }
  });
  return {
    updateForecastingCache: mutate,
    isLoading
  };
};
