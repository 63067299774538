import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { Button, Modal } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface SelectedRoom {
  name: string;
  code: string;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  selectedRoom: SelectedRoom;
};

export const DeleteNewbookRoomMappingModal = ({
  selectedRoom,
  onDelete,
  isLoading,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal.Root opened={isOpen} onClose={onClose} size="md" centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col items-start gap-3">
            <div className="rounded-full bg-error p-3 mb-3">
              <Icon.Delete color="white" />
            </div>
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t(`Delete: ${selectedRoom?.name}`)}
            </Typography>
            <Typography>
              {t(`Are you sure you want to delete ${selectedRoom?.name}?`)}
            </Typography>
          </div>
        </Modal.Body>
        <ModalFooter>
          <Button variant='subtle' color='grey' onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button className="bg-error text-white hover:opacity-80" onClick={onDelete} loading={isLoading}>
            {t('Delete')}
          </Button>
        </ModalFooter>
      </Modal.Content>
    </Modal.Root>
  );
};
