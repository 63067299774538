import { GuestLineUpdatePayload, RoomsRateListData } from '@common/api/hotel/types';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Modal } from '@common/components/molecules/Modal';
import { useHotelStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdatePmsRoomRateList } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useGetHotelPmsList } from '@pages/Client/Features/hooks/useGetHotelPmsList';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

interface SelectedRoomRate {
  id: number | string;
  rate_id: string;
  name: string;
  description: string | null;
  extra: string | null;
  room_types?: any[];
  other_rate?: string | null;
  room_rate?: any[];
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedRoomRate: SelectedRoomRate;
};

const schema = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  rate_id: z.string().trim().min(1, { message: 'Required' }),
  description: z.string().nullable().optional(),
  room_types: z.array(z.any()).optional(),
  room_rate: z.array(z.any()).optional(),
  other_rate: z.string().nullable().optional(),
  extra: z.string().nullable().optional()
});

export const UpdateRoomRateModal = ({ isOpen, onClose, selectedRoomRate }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { pmsRoomTypeList } = usePmsRoomType();
  const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const { hotelAuthToken } = useHotelStore();
  const { updateRoomRateList } = useUpdatePmsRoomRateList();
  const { hasSpecialRates, hasIndependentRates } = usePmsProvider();
  const { pmsList } = useGetHotelPmsList();

  function getSchema(hasIndependentRates: boolean) {
    return hasIndependentRates
      ? schema.extend({
          room_rate: z.array(z.any()).nonempty({ message: 'Room type is required' })
        })
      : schema;
  }
  const roomRateSchema = getSchema(!hasIndependentRates());

  const { handleSubmit, control, setValue, formState, watch } = useForm<
    z.infer<typeof roomRateSchema>
  >({
    resolver: zodResolver(roomRateSchema)
  });

  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const roomTypeOptions = pmsRoomTypeList?.map((item) => ({
    value: item.id,
    label: item.name
  }));

  useEffect(() => {
    setValue('name', selectedRoomRate.name);
    setValue('rate_id', selectedRoomRate.rate_id);
    setValue('description', selectedRoomRate.description);
    setValue('room_types', selectedRoomRate.room_types);
    setValue('other_rate', selectedRoomRate.other_rate);
    setValue('extra', selectedRoomRate.extra);
    if (pmsProviderId === PmsProvider.GUESTLINE || hasSpecialRates()) {
      setValue(
        'room_rate',
        selectedRoomRate?.room_rate?.map((item) => item.pms_room_id)
      );
    }
  }, [selectedRoomRate]);

  const protelFilterRoomRate = pmsRoomTypeList?.filter((item) =>
    watch('room_rate')?.includes(item.id)
  );

  const defaultRateId = selectedRoomRate?.room_rate?.map((item) => item.pms_room_id);
  const newId = watch('room_rate')?.filter((item) => !defaultRateId?.includes(item));

  const roomRate = protelFilterRoomRate
    ?.map((item) => {
      const matchingRoomRate = selectedRoomRate?.room_rate?.find(
        (rate: { pms_room: string }) => rate.pms_room === item.room_id
      );

      if (matchingRoomRate) {
        return {
          id: matchingRoomRate.id,
          rate_id: matchingRoomRate.rate_id,
          pms_room: item.room_id
        };
      }
      return null;
    })
    .filter(Boolean);

  const filteredPmsRoomTypeList = pmsRoomTypeList?.filter((item) => newId?.includes(item.id));

  const roomRateAdd = filteredPmsRoomTypeList?.map((item) => ({
    id: '',
    rate_id: selectedRoomRate?.rate_id || '',
    pms_room: item.room_id
  }));

  const pmsId = pmsList?.find((pms) => pms.primary)?.id;

  const onSubmit = async (data: z.infer<typeof roomRateSchema>) => {
    const commonPayload: GuestLineUpdatePayload = {
      checkin: null,
      checkout: null,
      description: data.description as string,
      room_rate: [...roomRate, ...roomRateAdd] as RoomsRateListData[],
      pms_id: selectedRoomRate?.room_rate?.length ? selectedRoomRate?.room_rate[0].pms_id : pmsId,
      rate_id: data.rate_id,
      name: data.name,
      other_rate: data.other_rate as string,
      token: hotelAuthToken,
      is_for_all_room_type: false,
      extra: data.extra as string
    };

    if (pmsProviderId === PmsProvider.GUESTLINE) {
      await updateRoomRateList({
        ...commonPayload,
        id: selectedRoomRate?.id
      });
    } else if (hasSpecialRates()) {
      // Protel Update
      await updateRoomRateList(commonPayload);
    }
  };

  return (
    <Modal
      onClose={onClose}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      okText={`${t('Save')}`}
      size={'xl'}
      open={isOpen}>
      <div>
        <div className="flex flex-col items-start gap-4">
          <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
            {t(`Edit`)} {selectedRoomRate?.name ? `${selectedRoomRate.name}` : ''}
          </Typography>
          {pmsProviderId === PmsProvider.SITEMINDER_NEW ? (
            <div className="w-full min-w-[300px] flex-1 items-start">
              <Controller
                name="extra"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    background="grey"
                    showClearButton={false}
                    tabIndex={1}
                    label={`${t('Rate Plan Code')}*`}
                    placeholder={`${t('Please enter type code')}`}
                    value={value as string}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={
                            error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                          }
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                  />
                )}
              />
            </div>
          ) : (
            <>
              <div className="flex w-full items-start justify-between gap-4">
                <div className="w-1/2 flex-1 items-start">
                  <Controller
                    name="rate_id"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={false}
                        type="text"
                        background="grey"
                        showClearButton={false}
                        tabIndex={1}
                        label={`${t('Rate Plan Code')}*`}
                        placeholder={`${t('Please enter Rate Plan Code')}`}
                        value={value || ''}
                        error={invalid}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="w-1/2 flex-1 items-start">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={false}
                        type="text"
                        background="grey"
                        showClearButton={false}
                        tabIndex={1}
                        label={`${t('Rate Plan Name')}*`}
                        placeholder={`${t('Please enter Rate Plan Name')}`}
                        value={value || ''}
                        error={invalid}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex w-full items-start justify-between gap-4">
                {hasSpecialRates() ? (
                  <div className="w-1/2 flex-1 items-start">
                    <Controller
                      name="room_rate"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                        <>
                          <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                            {t(`${pmsOrChannelManager} Room Type`)}{' '}
                            {!hasIndependentRates() ? '*' : ''}
                          </Typography>
                          <MultiSelect
                            options={roomTypeOptions}
                            placeholder={`${t(`Select ${pmsOrChannelManager} Room`)}`}
                            value={value}
                            onChange={onChange}
                            disabled={hasIndependentRates()}
                            inputClassName="py-[14px]"
                            name="room_rate"
                          />
                          {invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )}
                        </>
                      )}
                    />
                  </div>
                ) : (
                  <div className="w-1/2 flex-1 items-start">
                    <Controller
                      name="room_types"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                            {t(`${pmsOrChannelManager} Room`)}
                          </Typography>
                          <MultiSelect
                            options={roomTypeOptions}
                            placeholder={`${t(`Select ${pmsOrChannelManager} Room`)}`}
                            value={value}
                            onChange={onChange}
                            inputClassName="py-[14px]"
                            name="room_types"
                          />
                        </>
                      )}
                    />
                  </div>
                )}
                <div className="w-1/2 flex-1 items-start">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={false}
                        type="text"
                        background="grey"
                        showClearButton={false}
                        tabIndex={1}
                        label={`${t('Description')}`}
                        placeholder={`${t('Please enter Description')}`}
                        value={value || ''}
                        error={invalid}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          )}
          {pmsProviderId === PmsProvider.GUESTLINE ? (
            <div className="w-full flex-1 items-start">
              <Controller
                name="other_rate"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    background="grey"
                    showClearButton={false}
                    tabIndex={1}
                    label={`${t('Rate Flex Table')}`}
                    placeholder={`${t('Please enter Rate Flex Table')}`}
                    value={value || ''}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={
                            error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                          }
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
