import { UpdateCompetitor } from '@common/api/hotel/types';
import { Button } from '@common/components/atoms/Button';
import { Checkbox } from '@mantine/core';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useHotelCompetitor } from '@pages/Client/Calendar/hooks/useHotelCompetitor';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelUpdate } from '@pages/Client/hooks/useHotelUpdate';
import { useScrapeTopTwenty } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useScrapeTopTwenty';
import { useUpdateCompetitors } from '@pages/Client/PricingStrategy/MarketIntelligence/hooks/useUpdateCompetitor';
import { GetTopTwentyCompetitorModal } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/CompetitorList/components/GetTopTwentyCompetitorModal';
import { ScoreCompetitorModal } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/CompetitorList/components/ScoreCompetitorModal';
import { useGetTopTwentyLoading } from '@pages/Client/PricingStrategy/MarketIntelligence/store/getTopTwentyLoading';
import { useScoreCompetitorLoading } from '@pages/Client/PricingStrategy/MarketIntelligence/store/scoreCompetitor';
import { useModal } from '@common/hooks/useModal';

interface HotelCompetitorProps {
  hotelCompetitor: UpdateCompetitor | undefined;
}

export const CompetitorHeader = ({ hotelCompetitor }: HotelCompetitorProps) => {
  const { isGetTopTwentyLoading } = useGetTopTwentyLoading();
  const { hotelDetails } = useHotelDetails();
  const { updateHotel, isLoading } = useHotelUpdate();
  const { updateCompetitors, isLoading: isUpdateCompetitorLoading } = useUpdateCompetitors(false);
  const {
    query: { refetch: competitorRefetch }
  } = useHotelCompetitor();

  const { isLoading: isScrapeTopTwentyLoading } = useScrapeTopTwenty();
  const { isOpen: isScoreOpen, openModal: scoreClick, closeModal: scoreClose } = useModal();
  const { isScoreCompetitorLoading } = useScoreCompetitorLoading();

  const {
    isOpen: getTopTwentyOpen,
    openModal: getTopTwentyClick,
    closeModal: getTopTwentyClose
  } = useModal();

  const handleHotelUpdate = (value: boolean) => {
    updateHotel({
      is_seasonal: value
    });
  };

  const handleScapeTopTwenty = async () => {
    if (hotelCompetitor) {
      await updateCompetitors(hotelCompetitor);
      competitorRefetch();
    }
  };

  return (
    <div className="grid grid-cols-12 gap-20 rounded-md border border-indigo-reduced  p-6">
      <div className="col-span-3 flex flex-col gap-2">
        <Typography variant="meta-1" className="text-meta-1-medium">
          Get up to 20 initial suggestions
        </Typography>
        <Button
          size="large"
          intent="outline"
          onClick={getTopTwentyClick}
          isLoading={isGetTopTwentyLoading}>
          Get comp suggestions
        </Button>
      </div>
      <div className="col-span-3 flex flex-col gap-2">
        <div className="flex gap-2">
          <Icon.WarningOutline className="h-5 w-5 text-orange" />
          <Typography variant="meta-1" className="text-meta-1-medium">
            Only the first 10 comps will be used
          </Typography>
        </div>
        <Button
          disabled={isScrapeTopTwentyLoading}
          size="large"
          intent="outline"
          onClick={handleScapeTopTwenty}
          isLoading={isScrapeTopTwentyLoading || isUpdateCompetitorLoading}>
          Scrape all 20 comps
        </Button>
      </div>
      <div className="col-span-3 flex flex-col gap-2">
        <Typography variant="meta-1" className="text-meta-1-medium">
          Let our algo score them
        </Typography>
        <Button
          size="large"
          intent="outline"
          onClick={scoreClick}
          isLoading={isScoreCompetitorLoading}>
          Score competitors
        </Button>
      </div>
      <div className="col-span-3 flex flex-col gap-2">
        <Typography variant="meta-1" className="text-meta-1-medium">
          Is it a seasonal property?
        </Typography>
        <div className="mt-2 flex items-center gap-2">
          <Checkbox
            // value={1}
            onChange={() => {
              hotelDetails?.is_seasonal ? handleHotelUpdate(false) : handleHotelUpdate(true);
            }}
            checked={hotelDetails?.is_seasonal}
          />
          <Typography element="p" variant="meta-1" className="text-grey">
            {isLoading ? 'Updating...' : 'Seasonal Property'}
          </Typography>
        </div>
      </div>
      <GetTopTwentyCompetitorModal isOpen={getTopTwentyOpen} onClose={getTopTwentyClose} />
      <ScoreCompetitorModal isOpen={isScoreOpen} onClose={scoreClose} />
    </div>
  );
};
