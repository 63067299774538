import {
  BulkEditTab,
  useBulkEditsStore
} from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';
import { Icon } from '@common/components/foundations/icons';
import styles from '@pages/Client/Calendar/components/BulkEditTab/BulkEditTab.module.css';
import { useSurgeManageModal } from '@common/store/surgeModal';
import { BulkEditUserflowIds } from '@common/types/userflow-ids';

export function BulkEditTabs(): JSX.Element {
  const { t } = useTranslation();
  const { bulkEditTab, setBulkEditTab } = useBulkEditsStore();
  const { hasSurgeEvents } = useSurgeManageModal();
  return (
    <div className={cn(styles.tabs, 'mr-7 mt-7 flex items-center gap-x-4')}>
      <button
        onClick={() => setBulkEditTab(BulkEditTab.EDIT)}
        data-userflow-id={BulkEditUserflowIds.BULK_EDIT_ADJUSTMENTS_TAB_ITEM}>
        <div
          className={cn(
            styles.tab,
            bulkEditTab === BulkEditTab.EDIT ? styles.active_tab : styles.inactive_tab
          )}>
          <Icon.Edit className="mr-2 inline-block h-5 w-5 align-middle" />
          {t('Bulk Edit Adjustments')}
        </div>
      </button>
      <button
        onClick={() => setBulkEditTab(BulkEditTab.REMOVE)}
        data-userflow-id={BulkEditUserflowIds.BULK_REMOVE_ADJUSTMENTS_TAB_ITEM}>
        <div
          className={cn(
            styles.tab,
            bulkEditTab === BulkEditTab.REMOVE ? styles.active_tab : styles.inactive_tab
          )}>
          <Icon.Delete className="mr-2 inline-block h-5 w-5 align-middle" />
          {t('Bulk Remove Adjustments')}
        </div>
      </button>
      {hasSurgeEvents ? (
        <button
          onClick={() => setBulkEditTab(BulkEditTab.SURGES)}
          data-userflow-id={BulkEditUserflowIds.BULK_DISMISS_SURGE_EVENTS_TAB_ITEM}>
          <div
            className={cn(
              styles.tab,
              bulkEditTab === BulkEditTab.SURGES ? styles.active_tab : styles.inactive_tab
            )}>
            <Icon.Ripple className="mr-2 inline-block h-5 w-5 align-middle" />
            {t('Bulk Dismiss Surge Events')}
          </div>
        </button>
      ) : null}
    </div>
  );
}
