import { planSelectLookup } from '@common/config/app-config';
import { useClientListStore } from '@common/store/hotel-list-store';
import { ComboboxItem, Select } from '@mantine/core';
import { isNil, find } from 'lodash-es';

export function FiltersPlanSelect() {
  const paramKey = 'plan';
  const { params, setParams, setFilters } = useClientListStore();

  function handleChange(option: ComboboxItem) {
    setFilters({ [paramKey]: isNil(option) ? undefined : option.label });
    setParams({ [paramKey]: isNil(option) ? undefined : Number(option.value) });
  }

  return (
    <Select
      searchable
      clearable
      variant="filled"
      label="Plan"
      placeholder="All Plans"
      value={find(planSelectLookup, { value: String(params[paramKey]) })?.value || null}
      data={planSelectLookup}
      onChange={(_value, option) => handleChange(option)}
      comboboxProps={{ withinPortal: false }}
    />
  );
}
