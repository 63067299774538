export function Timer40s(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0169 7.37L20.1481 6.24C20.3388 6.04558 20.3388 5.73442 20.1481 5.54L19.4373 4.83C19.3433 4.73534 19.2154 4.6821 19.0819 4.6821C18.9485 4.6821 18.8205 4.73534 18.7266 4.83L17.6154 6C16.5627 5.14667 15.3309 4.54164 14.0116 4.23V3C14.0116 2.44772 13.5634 2 13.0105 2H11.0084C10.4556 2 10.0074 2.44772 10.0074 3V4.23C5.90988 5.16292 3.00232 8.80184 3 13C3.05461 17.8476 6.90015 21.8034 11.7492 22C15.2574 22.0939 18.5009 20.1442 20.0609 17.0039C21.6209 13.8636 21.2135 10.1041 19.0169 7.37ZM12.0095 20C8.13942 20 5.00211 16.866 5.00211 13C5.00211 9.13401 8.13942 6 12.0095 6C15.8796 6 19.0169 9.13401 19.0169 13C19.0169 16.866 15.8796 20 12.0095 20ZM12.1633 8.13193C12.2644 8.03657 12.4014 7.98862 12.54 8C15.0577 8.22741 17.0105 10.2945 17.092 12.8185C17.1735 15.3425 15.3582 17.531 12.8604 17.92C11.46 18.1626 10.0224 17.799 8.90622 16.92C8.78993 16.8321 8.71684 16.6987 8.70536 16.5535C8.69388 16.4082 8.74512 16.2651 8.84615 16.16L12.0095 13V8.5C12.0062 8.36108 12.0621 8.22729 12.1633 8.13193Z"
      />
    </svg>
  );
}
