export function SwapHorizontal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8538 12.36L21.8538 9.36002C22.0494 9.15825 22.045 8.83628 21.8438 8.64002L18.8438 5.64002C18.6997 5.49872 18.4847 5.45795 18.2988 5.53667C18.113 5.6154 17.9927 5.79817 17.9938 6.00002V8.00002H10.4938C10.2177 8.00002 9.99384 8.22388 9.99384 8.50002V9.50002C9.99384 9.77616 10.2177 10 10.4938 10H17.9938V12C17.9884 12.2063 18.1102 12.3948 18.3006 12.4744C18.4909 12.5541 18.7107 12.5087 18.8538 12.36ZM5.99384 14H13.4938C13.77 14 13.9938 14.2239 13.9938 14.5V15.5C13.9938 15.7762 13.77 16 13.4938 16H5.99384V18C5.99103 18.2001 5.86925 18.3792 5.68426 18.4553C5.49927 18.5315 5.28669 18.4901 5.14384 18.35L2.14384 15.35C1.95205 15.1522 1.95205 14.8378 2.14384 14.64L5.14384 11.64C5.28799 11.4987 5.50297 11.458 5.68884 11.5367C5.87472 11.6154 5.99502 11.7982 5.99384 12V14Z"
      />
    </svg>
  );
}
