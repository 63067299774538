import { Draft } from 'immer';
import { forOwn, isObject, isArray, isEmpty, isNil } from 'lodash-es';

export function removeEmptyObjects(draft: Draft<Record<string, any>>): void {
  forOwn(draft, (value, key) => {
    if (isNil(value)) delete draft[key];
    if (Number.isNaN(value)) delete draft[key];
    if (isObject(value) && !isArray(value)) {
      removeEmptyObjects(value);
      if (isEmpty(value) || isNil(value)) delete draft[key];
    }
  });
}
