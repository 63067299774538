import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { Checkbox, Flex, rem, Stack, Text } from '@mantine/core';
import { editSchema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { RoomSetupDrawerTabProps } from '@pages/Client/PricingStrategy/RoomSetup/types';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider, usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useGetDetailProviderConfig } from '@pages/Client/hooks/useProviderConfig';
import { sortBy } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const PmsMappingEdit: React.FC<RoomSetupDrawerTabProps> = ({
  baseRateInPmsOptions,
  roomInPmsOptions,
  roomOccupancyPricingOptions
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { isSubmitting }
  } = useFormContext<z.infer<typeof editSchema>>();
  const { pmsMapping } = watch();
  const { hotelDetails } = useHotelDetails();
  const { isApartment } = useRoomSetupStore();
  const { hasDashboardFeature, hasMinStayFeature } = useFeaturesStore();
  const { hotelPmsDataMap } = useHotelPmsDataMap();
  const { hasRoomOccupancyForPricing } = usePmsProvider();
  const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'CM' : 'PMS';

  const { view } = useViewStore();
  const { t } = useTranslation();
  const { isRoomOnly, isOpenApi } = useGetDetailProviderConfig();

  const isBeds24V2Api = hotelDetails?.pms_provider === 84;

  const isSemper = hotelDetails?.pms_provider === 38;

  const isMappingMasterCultSwitch =
    hotelDetails?.pms_provider === 39 || hotelDetails?.pms_provider === 35;

  const isVerticalBooking = hotelDetails?.pms_provider === 34;

  const isSabeeApp = hotelDetails?.pms_provider === 71;

  const isEviivo = hotelDetails?.pms_provider === 46;

  const isMews = hotelDetails?.pms_provider === PmsProvider.MEWS && hasMinStayFeature();

  const numOfRoomsInPms = hotelPmsDataMap?.mapped_data.find(
    (item) => item.id === pmsMapping.roomInPms
  )?.number_of_rooms;

  return (
    <>
      <div className="flex w-full flex-col gap-y-6 md:w-2/3">
        <Controller
          control={control}
          name="pmsMapping.roomInPms"
          render={({ field: { value, onChange, name } }) => (
            <SelectDropdown
              fullWidth
              hint={`${t(
                `${isApartment ? 'Apartment' : 'Room'} in ${
                  hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                }`
              )} *`}
              background="grey"
              name={name}
              value={value}
              options={[
                {
                  label: t('Please Map'),
                  value: null
                },
                ...(sortBy(roomInPmsOptions, 'label') || [])
              ]}
              onChange={onChange}
              suffixIcon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-xs text-left">
                      {t(
                        `Please select the ${hotelDetails?.room_apartment_space_name} from your ${
                          hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                        } that you would like to map to this ${hotelDetails?.room_apartment_space_name.toLowerCase()} in RoomPriceGenie.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
            />
          )}
        />

        {hasRoomOccupancyForPricing() ? (
          <>
            {isBeds24V2Api || isOpenApi ? null : (
              <Controller
                control={control}
                name="pmsMapping.roomOccupancyForPricing"
                render={({ field: { value, onChange, name } }) => (
                  <SelectDropdown
                    hint={`${t('Room Occupancy for Pricing')}`}
                    fullWidth
                    background="grey"
                    name={name}
                    value={value}
                    options={[
                      {
                        label: '-',
                        value: null
                      },
                      ...(sortBy(roomOccupancyPricingOptions, 'label') || [])
                    ]}
                    onChange={onChange}
                  />
                )}
              />
            )}

            {isRoomOnly && isOpenApi ? null : (
              <Controller
                control={control}
                name="pmsMapping.baseRateInPms"
                render={({ field: { value, onChange, name } }) => (
                  <SelectDropdown
                    hint={`${t(
                      `Base Rate in ${hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'}`
                    )}`}
                    fullWidth
                    background="grey"
                    name={name}
                    value={value}
                    options={[
                      {
                        label: 'Not Priced',
                        value: null
                      },
                      ...(sortBy(baseRateInPmsOptions, 'label') || [])
                    ]}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </>
        ) : isRoomOnly ? null : (
          <>
            <Controller
              control={control}
              name="pmsMapping.baseRateInPms"
              render={({ field: { value, onChange, name } }) => (
                <SelectDropdown
                  hint={`${t(
                    `Base Rate in ${hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'}`
                  )} *`}
                  fullWidth
                  background="grey"
                  name={name}
                  value={value}
                  options={[
                    {
                      label: t('Not Priced'),
                      value: null
                    },
                    ...(sortBy(baseRateInPmsOptions, 'label') || [])
                  ]}
                  onChange={onChange}
                />
              )}
            />
            {isMews ? (
              <Input
                showClearButton={false}
                readOnly
                type="text"
                label={`${t(
                  `Min Stay in ${hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'}`
                )} *`}
                background="grey"
                value={t('All Rates') as string}
                trailingAddon={
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <Icon.Help className="h-5 w-5 fill-grey" />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-xs text-left">
                        {t(
                          `Min Stay restrictions map to Room Types only and will appear under "All Rates" in Mews.`
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                }
              />
            ) : null}
          </>
        )}

        {isEviivo ? (
          <Input
            showClearButton={false}
            readOnly
            type="text"
            label={`${t('Reference Occupancy')}`}
            background="grey"
            defaultValue={
              hotelPmsDataMap?.mapped_data.find((item) => item.id === pmsMapping.roomInPms)
                ?.max_occupancy
            }
          />
        ) : null}

        <Controller
          control={control}
          name="pmsMapping.roomName"
          render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
            <Input
              showClearButton={false}
              type="text"
              label={`${t('Name')}*`}
              background="grey"
              placeholder={`${t('Please enter room name')}`}
              disabled={isSubmitting}
              error={invalid}
              trailingAddon={
                <TooltipProvider delayDuration={75}>
                  <Tooltip>
                    <TooltipTrigger type="button">
                      <Icon.Help className="h-5 w-5 fill-grey" />
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-xs text-left">
                      {t(
                        `Please give this ${hotelDetails?.room_apartment_space_name.toLowerCase()} a name in RoomPriceGenie. For simplicity we recommend the same name as in your ${
                          hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
                        }.`
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="pmsMapping.numberOfRooms"
          render={({ field: { value, onChange, name }, fieldState: { invalid, error } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting || pmsMapping.virtualRoomType}
              type="number"
              label={`${t(`Number of ${isApartment ? 'Apartments' : 'Rooms'}`)} *`}
              placeholder={`${t(`Please enter number of ${isApartment ? 'apartments' : 'rooms'}`)}`}
              background="grey"
              name={name}
              value={value}
              onChange={(e) => {
                if (e.target.value === '0') {
                  setValue('pmsMapping.virtualRoomType', true);
                }
                if (e.target.value !== '0') {
                  setValue('pmsMapping.virtualRoomType', false);
                }
                onChange(e);
              }}
              error={invalid}
              trailingAddon={
                <Flex gap="xs">
                  {!numOfRoomsInPms ? null : (
                    <Stack gap={rem(4)}>
                      <Text size="xxs" className="text-right select-none" c="gray">
                        {t(`Rooms in ${pmsOrChannelManager}`)}
                      </Text>
                      <Text size="xxs" className="text-right select-none" c="gray">
                        {numOfRoomsInPms}
                      </Text>
                    </Stack>
                  )}
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <Icon.Help className="h-5 w-5 fill-grey" />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-xs text-left">
                        {t(
                          `How many of this ${
                            hotelDetails?.room_apartment_space_name
                          } Type do you have in your hotel? The total of all the ${hotelDetails?.room_apartment_space_name.toLowerCase()} types should add up to the total number of ${hotelDetails?.room_apartment_space_name.toLowerCase()}s in the hotel.`
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Flex>
              }
              helperMessage={
                invalid ? (
                  <InputHelperMessage
                    icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                    message={error?.message}
                  />
                ) : null
              }
            />
          )}
        />

        {isMappingMasterCultSwitch || isVerticalBooking || isSabeeApp ? (
          <Controller
            control={control}
            name="occupancyPricing.defaultOccupancy"
            render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
              <Input
                showClearButton={false}
                disabled={isSubmitting}
                type="number"
                label={`${t('Default Occupancy')}`}
                placeholder={`${t('Please enter the default occupancy')}`}
                background="grey"
                name={name}
                value={value}
                onChange={onChange}
                error={invalid}
                helperMessage={
                  invalid ? (
                    <InputHelperMessage
                      icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                      message={error?.message}
                    />
                  ) : null
                }
              />
            )}
          />
        ) : null}

        {isSemper ? (
          <Controller
            control={control}
            name="pmsMapping.priceType"
            render={({ field: { value, onChange, name } }) => (
              <SelectDropdown
                hint={`${t('Price Type')}`}
                fullWidth
                background="grey"
                name={name}
                value={value}
                options={[
                  {
                    label: t('Unit Based'),
                    value: 'unit_based'
                  },
                  {
                    label: t('Occupancy Based'),
                    value: 'occupancy_based'
                  }
                ]}
                onChange={onChange}
              />
            )}
          />
        ) : null}

        <Controller
          control={control}
          name="pmsMapping.virtualRoomType"
          render={({ field: { value, onChange, name } }) => (
            <Checkbox
              label={t(`Virtual ${isApartment ? 'Apartment' : 'Room'} Type`)}
              id={name}
              name={name}
              checked={value}
              onChange={(e) => {
                if (e.target.checked) {
                  setValue('pmsMapping.numberOfRooms', 0);
                }
                onChange(e);
              }}
            />
          )}
        />

        {pmsMapping.virtualRoomType && hasDashboardFeature() ? (
          <Controller
            control={control}
            name="pmsMapping.numberOfBedsPerPhysicalRoom"
            render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
              <Input
                label={`${t(`For Hostel Dorm Rooms Only: Number of Beds per Room`)}`}
                disabled={isSubmitting}
                background="grey"
                type="number"
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                error={invalid}
                helperMessage={error?.message}
                showClearButton={false}
                trailingAddon={
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <Icon.Help className="h-5 w-5 fill-grey" />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-xs text-left">
                        {t(
                          `The number of beds per room is required for the dashboard calculations.`
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                }
              />
            )}
          />
        ) : null}
      </div>

      {view === 'admin' ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="pmsMapping.roomId"
            render={({ field: { value, onChange, name } }) => (
              <Input
                showClearButton={false}
                readOnly
                type="text"
                label={`${t(`${isApartment ? 'Apartment' : 'Room'} ID`)}`}
                background="grey"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      ) : null}
    </>
  );
};
