import { FormInput } from '@common/components/atoms/Input';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { FormInputSwitcher, Switcher } from '@common/components/atoms/Switcher';
import { cn } from '@common/utils/cn';
import { debounce, isEmpty, isNil, map, result } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { useRecommendedMinStay } from '@pages/Client/Calendar/components/Tables/hooks';
import { Icon } from '@common/components/foundations/icons';
import dayjs from 'dayjs';
import { Typography } from '@common/components/foundations/Typography';
import useMedia from 'use-media';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { useBuildAdjustmentInputsArray } from '@pages/Client/Calendar/components/BulkEdit/hooks/useBuildAdjustmentInputsArray';
import { useFormPreflight } from '@pages/Client/Calendar/components/BulkEdit/hooks/useFormPreflight';
import { MinStay } from '@pages/Client/Calendar/components/BulkEdit/types/schema/minStaySchema';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useMinStayValue } from '@pages/Client/Calendar/hooks/useMinStayValue';
import { TransformSchema } from '@pages/Client/Calendar/components/BulkEdit/types/schema';
import { useFeaturesStore } from '@common/store/features';
import { Anchor, Stack, Text, Tooltip, Group, Box, Indicator, Pill } from '@mantine/core';
import { Link } from 'react-router-dom';
import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function MinStayAdjustmentForm() {
  const { t } = useTranslation();
  const formName = ADJUSTMENT.MIN_STAY;
  const { hotelDetails } = useHotelDetails();
  const grey = useTailwindColor('grey');
  const orange = useTailwindColor('orange');
  const { pricingSettings } = useRoomPrices();
  const { setDrawerState } = usePriceDrawerStore();
  const { hasMinStayFeature } = useFeaturesStore();
  const isMobile = useMedia({ minWidth: 768 });
  const { displayHotelRooms } = useHotelRoomsList();
  const { control, setValue, getValues, watch, formState, trigger } = useFormContext();
  const editDate = getValues('editDate');
  const isBulkEdit = getValues('isBulkEdit');
  const [prefillDerivedRooms, setPrefillDerivedRooms] = useState(true);
  const adjustmentFields: MinStay[] = useBuildAdjustmentInputsArray<MinStay>(formName);
  const { preflightCallback, isUpdating } = useFormPreflight();
  const { isUpdatingRecommendedMinStay, originalMinStayData } = useRecommendedMinStay(
    dayjs(editDate)
  );
  const { getMinStayValue } = useMinStayValue();
  const formattedDate = dayjs(editDate).startOf('day').format(API_DATE_FORMAT);
  const isPastDate = dayjs(editDate).isBefore(dayjs().startOf('day'));
  const { fields, replace } = useFieldArray({ name: formName, control });
  const { pmsMinStayConfig } = usePmsProvider();

  const minStayRemovalValue = pmsMinStayConfig?.min_stay_remove_value ?? 0;

  const isInputUpdating = isUpdatingRecommendedMinStay || isUpdating;
  const runPreflight = useCallback(debounce(preflightCallback, 700), []);

  const skipMinStayRulesEnabled = hasMinStayFeature();

  function updatePrefillDerivedRooms(name?: string) {
    if (prefillDerivedRooms) {
      map(fields, (_, index) => {
        const root = `${formName}.${index}`;
        if (prefillDerivedRooms && name === `${formName}.0.value`) {
          if (index > 0) {
            setValue(`${root}.value`, Number(getValues(`${formName}.0.value`)));
          }
        }
        if (prefillDerivedRooms && name === `${formName}.0.is_skip_min_stay_rule`) {
          if (index > 0) {
            setValue(
              `${root}.is_skip_min_stay_rule`,
              Boolean(getValues(`${formName}.0.is_skip_min_stay_rule`))
            );
          }
        }
      });
    }
    trigger(formName);
  }

  useEffect(() => {
    if (isEmpty(fields) && !isEmpty(pricingSettings)) replace(adjustmentFields);
  }, [adjustmentFields, pricingSettings]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      updatePrefillDerivedRooms(name);
      trigger(formName);
      if (type === 'change') {
        preflightCallback();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, prefillDerivedRooms, fields]);

  return (
    <Fragment>
      <Group justify="space-between" align="center">
        {pricingSettings?.hotel?.all_room_pricing ? (
          <Switcher
            checked={prefillDerivedRooms}
            onChange={setPrefillDerivedRooms}
            size="small"
            label={`${t(`Prefill Derived ${hotelDetails?.room_apartment_space_name}s`)}`}
            className="text-sm font-medium text-copyTextGrey"
          />
        ) : null}
        {!isBulkEdit && isUpdating ? (
          <Icon.LoadingCircle className="ml-2 inline-block opacity-50" />
        ) : null}
      </Group>
      <LineTable>
        <thead>
          <tr>
            <th>{t(`${hotelDetails?.room_apartment_space_name}`)}</th>
            <th>{t(`Min Stay`)}</th>
            {!isBulkEdit && (
              <Tooltip
                color="dark.8"
                fz="xs"
                position="bottom"
                label={
                  <Stack gap={1}>
                    <Text size="xs" c="gray">
                      {t('Based on automation settings')}
                    </Text>
                    <Text size="xs" c="gray.0">
                      {t('Click to view automation settings')}
                    </Text>
                  </Stack>
                }
              >
                <th>
                  <Anchor
                    c="gray.5"
                    underline="always"
                    fw={500}
                    size="xs"
                    component={Link}
                    onClick={() => setDrawerState(false)}
                    to={`/client/min-stay/${hotelDetails?.id}/removal`}
                  >
                    {t('Corrected Min Stay')}
                  </Anchor>
                </th>
              </Tooltip>
            )}
            {skipMinStayRulesEnabled ? <th>{t('Ignore Correction')}</th> : null}
          </tr>
        </thead>

        <tbody>
          {map(fields, (field, index) => {
            const room = displayHotelRooms[index];
            const highlightColor = isPastDate ? grey : orange;
            const { suggestedMinStay, shouldUseSuggestedMinStay } =
              getMinStayValue(formattedDate, room?.id) ?? {};

            const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'CM' : 'PMS';

            return !isNil(room) && !isEmpty(field) ? (
              <tr key={field.id}>
                <td>
                  {room?.name} {`${import.meta.env.DEV ? room.id : ''}`}
                </td>
                <td>
                  <div className={cn(isBulkEdit ? 'w-full' : 'max-w-[170px]')}>
                    <Stack gap="xs">
                      <Indicator
                        position="bottom-center"
                        styles={{
                          indicator: { '--indicator-color': 'var(--mantine-color-transparent)' }
                        }}
                        label={
                          <Pill
                            onClick={
                              watch(`${formName}.${index}.value`) === 0
                                ? undefined
                                : () => {
                                    setValue(`${formName}.${index}.value`, minStayRemovalValue);
                                    runPreflight();
                                  }
                            }
                            size="xs"
                            bg={
                              watch(`${formName}.${index}.value`) === minStayRemovalValue ||
                              watch(`${formName}.${index}.value`) === 0
                                ? 'orange'
                                : 'dark'
                            }
                            c="white"
                            radius="sm"
                          >
                            {watch(`${formName}.${index}.value`) === minStayRemovalValue
                              ? t('Removes Min Stay')
                              : watch(`${formName}.${index}.value`) === 0
                                ? t('Resets to PMS Min Stay')
                                : t('Enter {{minStayRemovalValue}} to remove', {
                                    minStayRemovalValue
                                  })}
                          </Pill>
                        }
                      >
                        <FormInput
                          type="number"
                          nameKey={`${formName}.${index}.id` as const}
                          valueKey={`${formName}.${index}.value` as const}
                          background="grey"
                          showClearButton={false}
                          trailingAddon={
                            !isMobile
                              ? null
                              : !isBulkEdit && (
                                  <span className="hidden flex-col items-end md:flex">
                                    {originalMinStayData?.[room.id] ? (
                                      <>
                                        <Typography variant="meta-3" color="grey">
                                          {t(`${pmsOrChannelManager} Min Stay`)}
                                        </Typography>
                                        <Typography variant="meta-2" color="grey">
                                          {originalMinStayData?.[room.id]}
                                        </Typography>
                                      </>
                                    ) : (
                                      <Typography variant="meta-3" color="grey">
                                        {t(`No ${pmsOrChannelManager} Min Stay`)}
                                      </Typography>
                                    )}
                                  </span>
                                )
                          }
                        />
                      </Indicator>
                      <Box px="xs">
                        <Text c="red" size="xxs">
                          {result(formState, `errors.${formName}[${index}].message`) as string}
                        </Text>
                      </Box>
                    </Stack>
                  </div>
                </td>
                {!isBulkEdit ? (
                  <td className="text-center">
                    {shouldUseSuggestedMinStay ? (
                      <span className="mr-1 inline-flex items-center">
                        <svg
                          className="h-3 w-3"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill={highlightColor}
                        >
                          <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" />
                        </svg>
                      </span>
                    ) : (
                      <Fragment>{isInputUpdating ? '' : ' - '}</Fragment>
                    )}

                    {shouldUseSuggestedMinStay ? (
                      <span style={{ color: highlightColor, fontWeight: 'bold' }}>
                        {suggestedMinStay === 0 ? t('None') : suggestedMinStay}
                      </span>
                    ) : null}
                  </td>
                ) : null}
                {skipMinStayRulesEnabled ? (
                  <td>
                    <div className="flex items-center justify-center">
                      <FormInputSwitcher<TransformSchema>
                        size="large"
                        className="text-sm font-medium text-copyTextGrey"
                        nameKey={`${formName}.${index}.id` as const}
                        valueKey={`${formName}.${index}.is_skip_min_stay_rule` as const}
                      />
                    </div>
                  </td>
                ) : null}
              </tr>
            ) : null;
          })}
        </tbody>
      </LineTable>
    </Fragment>
  );
}
