import { KPIData } from '@common/api/dashboard/types';
import { PerformanceFilter } from '@pages/Client/Dashboard/store/dashboard';

const revenueLookup: Record<PerformanceFilter, keyof KPIData<string>> = {
  grossValuesExclExtraServices: 'REVENUE',
  grossValuesInclExtraServices: 'REVENUE_INCL_SERVICES',
  netValuesExclExtraServices: 'REVENUE_NET',
  netValuesInclExtraServices: 'REVENUE_NET_INCL_SERVICES'
};

const adrLookup: Record<PerformanceFilter, keyof KPIData<string>> = {
  grossValuesExclExtraServices: 'ADR',
  grossValuesInclExtraServices: 'ADR_INCL_SERVICES',
  netValuesExclExtraServices: 'ADR_NET',
  netValuesInclExtraServices: 'ADR_NET_INCL_SERVICES'
};

const revParLookup: Record<PerformanceFilter, keyof KPIData<string>> = {
  grossValuesExclExtraServices: 'REV_PAR',
  grossValuesInclExtraServices: 'REV_PAR_INCL_SERVICES',
  netValuesExclExtraServices: 'REV_PAR_NET',
  netValuesInclExtraServices: 'REV_PAR_NET_INCL_SERVICES'
};

export const getRevenueKey = (performanceFilter: PerformanceFilter): keyof KPIData<string> => {
  return revenueLookup[performanceFilter];
};

export const getADRKey = (performanceFilter: PerformanceFilter): keyof KPIData<string> => {
  return adrLookup[performanceFilter];
};

export const getRevParKey = (performanceFilter: PerformanceFilter): keyof KPIData<string> => {
  return revParLookup[performanceFilter];
};
