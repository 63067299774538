export function Hotel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 19.5C22 19.7761 21.7761 20 21.5 20H20.5C20.2239 20 20 19.7761 20 19.5V16H4V19.5C4 19.7761 3.77614 20 3.5 20H2.5C2.22386 20 2 19.7761 2 19.5V4.5C2 4.22386 2.22386 4 2.5 4H3.5C3.77614 4 4 4.22386 4 4.5V13H21C21.5523 13 22 13.4477 22 14V19.5ZM20 8H12C10.8954 8 10 8.89543 10 10V11.5C10 11.7761 10.2239 12 10.5 12H21.5C21.7761 12 22 11.7761 22 11.5V10C22 8.89543 21.1046 8 20 8ZM9 11.5C9 11.7761 8.77614 12 8.5 12H5.5C5.22386 12 5 11.7761 5 11.5V11C5 9.89543 5.89543 9 7 9C8.10457 9 9 9.89543 9 11V11.5Z"
      />
    </svg>
  );
}
