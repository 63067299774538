import { PricingSettings } from '@common/api/hotel/types';

const useMinstay = () => {
  function roomTypesOption(data?: PricingSettings['rooms']) {
    if (!data) return [];

    const options = data.derived
      ? Object.keys(data.derived).reduce((acc: { label: string; value: string }[], key) => {
          if (data?.derived?.[key]) {
            acc.push({
              value: key,
              label: data.derived[key].name
            });
          }
          return acc;
        }, [])
      : [];

    if (data.reference && data.reference.id !== undefined && data.reference.name) {
      options.push({
        value: data.reference.id.toString(),
        label: data.reference.name || 'Default Reference Room'
      });
    }

    return options;
  }

  function getRoomNameFromIds(roomIds: string[], rooms?: PricingSettings['rooms']) {
    if (!rooms) return [];

    return roomIds.map((id) => {
      const room = rooms.derived?.[id];
      const referenceRoom =
        rooms.reference && rooms.reference.id?.toString() === id.toString()
          ? rooms.reference
          : undefined;
      return room?.name || referenceRoom?.name;
    });
  }

  return { roomTypesOption, getRoomNameFromIds };
};

export default useMinstay;
