import { create } from 'zustand';
interface LoadingStore {
  isGetTopTwentyLoading: boolean;
  setLoading: (newLoading: boolean) => void;
}

export const useGetTopTwentyLoading = create<LoadingStore>((set) => ({
  isGetTopTwentyLoading: false,
  setLoading: (newLoading: boolean) => set({ isGetTopTwentyLoading: newLoading })
}));
