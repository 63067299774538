import dayjs from 'dayjs';
import { useCalendarOccupancy } from '@pages/Client/Calendar/hooks/useCalendarOccupancy';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useTranslation } from 'react-i18next';
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip as TooltipComponent
} from '@common/components/molecules/Tooltip';
import { useViewStore } from '@common/store/view';
import { Link } from 'react-router-dom';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { Typography } from '@common/components/foundations/Typography';

export const CalendarOccupancy = () => {
  const { t } = useTranslation();
  const { occupancy, futureOccupancy, targetOccupancy } = useCalendarOccupancy();
  const { liliusInstance } = useCalendarPageStore();
  const { hotelDetails } = useHotelDetails();
  const { view } = useViewStore();
  const shouldShowExpectedOccupancy = (() => {
    if (!liliusInstance) return false;

    return dayjs(liliusInstance.viewing).isSameOrAfter(dayjs().tz(), 'month');
  })();

  return (
    <div className="flex h-full flex-col justify-between">
      <Typography variant="meta-2">{t('Monthly Performance')}</Typography>
      <div className="flex items-center p-0.5">
        <div className="shrink-0 rounded-sm bg-white px-2 py-[1px] shadow-sm">
          <span className="text-meta-1 font-semibold">{formattedPercentage(occupancy)}</span>
          <span className="text-meta-2 text-grey">&nbsp;{t('Occupancy')}</span>
        </div>
        {shouldShowExpectedOccupancy && (
          <TooltipProvider delayDuration={75}>
            <TooltipComponent>
              <TooltipTrigger type="button">
                <div className="ml-2 rounded-sm bg-white px-2 py-[1px] shadow-sm">
                  <span className="text-meta-1 font-semibold">
                    {formattedPercentage(futureOccupancy)}
                  </span>

                  <span className="text-meta-2 text-grey">
                    &nbsp;{t(' Expected Occupancy by Today')}
                  </span>
                </div>
              </TooltipTrigger>
              <TooltipContent side="bottom" className="max-w-xs">
                <div className="flex flex-col gap-y-4">
                  <div>
                    {t(
                      `Expected Occupancy refers to how many bookings we would expect you to have received so far.`
                    )}
                  </div>
                  <div>
                    {t(
                      `It is derived from your pricing strategy settings: we look at how high you expect your occupancy to be at the end of the month (also called target occupancy) and how fast we expect these bookings to arrive (median booking window).`
                    )}
                  </div>
                  <div>
                    {t(
                      `If your actual occupancy (on the left) is lower than your expected occupancy, this means that your occupancy is lower than we would expect it to be at this point in time (if you want to hit your target occupancy), and we will lower price recommendations a little so that you can reach your target occupancy. Vice versa, if actual occupancy is higher than expected we will raise price recommendations.`
                    )}
                  </div>
                </div>
              </TooltipContent>
            </TooltipComponent>
          </TooltipProvider>
        )}
        {view === 'admin' ? (
          <Link
            to={`/client/occupancy/${hotelDetails?.id}/target-occupancy`}
            className="ml-2 shrink-0 rounded-sm bg-white px-2 py-[1px] shadow-sm">
            <span className="text-meta-1 font-semibold">
              {formattedPercentage(targetOccupancy)}
            </span>
            <span className="text-meta-2 text-grey">&nbsp;{t('Target Occupancy')}</span>
          </Link>
        ) : null}
      </div>
    </div>
  );
};
