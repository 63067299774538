import { useAuthStore } from '@common/store/auth';
import { useEffect, useState } from 'react';

type UseRoleReturn = {
  isStaff: boolean;
  isPartner: boolean;
  isAdminPartner: boolean;
};

export const useUserRole = (): UseRoleReturn => {
  const { user } = useAuthStore();

  const [isStaff, setIsStaff] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isAdminPartner, setIsAdminPartner] = useState(false);

  useEffect(() => {
    setIsStaff(false);
    setIsPartner(user?.sub_account_type === 2 ? true : false);
    setIsAdminPartner(user?.is_staff || user?.sub_account_type ? true : false);
  }, [user]);

  return {
    isStaff,
    isPartner,
    isAdminPartner
  };
};
