import { createPartner } from '@common/api/account';
import { PartnerPayload } from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { PartnerQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddNewPartner = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: PartnerPayload) => {
      return createPartner(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Partner created successfully');
      queryClient.invalidateQueries({ queryKey: [PartnerQueryKeys.GET_PARTNER_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to create partner');
    }
  });

  return {
    createPartner: mutate,
    isLoading,
    isSuccess
  };
};
