import { Typography } from '@common/components/foundations/Typography';
import { useClientListStore } from '@common/store/hotel-list-store';
import { Flex, Pill } from '@mantine/core';
import { isEqual, map, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export function ActiveFiltersList() {
  const { t } = useTranslation();
  const { filters, setFilters, defaultFilters, setParams } = useClientListStore();
  const hasChanges = !isEqual(JSON.stringify(filters), JSON.stringify(defaultFilters));

  function handleRemove(paramKey: string) {
    setFilters({ [paramKey]: undefined });
    setParams({ [paramKey]: undefined });
  }

  return hasChanges ? (
    <>
      <Typography element="h3" variant="meta-1" className="text-meta-1-medium">
        {t('Filters')}
        <span className="sr-only">, active</span>
      </Typography>
      <Flex gap="md" align="center" visibleFrom="md">
        {map(filters, (value, key) =>
          isNil(value) ? null : (
            <Pill withRemoveButton key={key} onRemove={() => handleRemove(key)}>
              {value}
            </Pill>
          )
        )}
      </Flex>
    </>
  ) : null;
}
