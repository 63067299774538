export function Verified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6164 10.8467C21.3261 11.5928 21.3261 12.4206 21.6164 13.1667L21.7865 13.6567C22.4059 15.2746 21.6557 17.0947 20.0759 17.8067L19.6258 18.0067C18.8874 18.3248 18.2901 18.9006 17.9452 19.6267L17.7452 20.0767C17.0329 21.656 15.2122 22.406 13.5938 21.7867L13.1337 21.6167C12.3873 21.3265 11.5593 21.3265 10.8129 21.6167L10.3528 21.7867C8.73434 22.406 6.91363 21.656 6.20142 20.0767L6.00135 19.6267C5.68313 18.8886 5.10713 18.2914 4.38083 17.9467L3.93068 17.7467C2.35087 17.0347 1.60065 15.2146 2.22013 13.5967L2.39018 13.1367C2.68048 12.3906 2.68048 11.5628 2.39018 10.8167L2.22013 10.3567C1.60065 8.73879 2.35087 6.91868 3.93068 6.2067L4.38083 6.0067C5.09668 5.67675 5.6713 5.10232 6.00135 4.3867L6.23143 3.9367C6.94443 2.34154 8.78584 1.58848 10.4128 2.2267L10.8729 2.3967C11.6193 2.6869 12.4473 2.6869 13.1937 2.3967L13.6538 2.2267C15.2723 1.60742 17.093 2.3574 17.8052 3.9367L18.0052 4.3867C18.3353 5.10232 18.9099 5.67675 19.6258 6.0067L20.0759 6.2367C21.6557 6.94868 22.4059 8.76879 21.7865 10.3867L21.6164 10.8467ZM10.7329 15.3567L16.3547 9.7367C16.5389 9.54355 16.5389 9.23985 16.3547 9.0467L15.8245 8.5167C15.63 8.32612 15.3188 8.32612 15.1243 8.5167L10.3828 13.2567L8.88229 11.7667C8.79152 11.67 8.6648 11.6152 8.53217 11.6152C8.39954 11.6152 8.27282 11.67 8.18206 11.7667L7.65189 12.2967C7.5572 12.3906 7.50394 12.5184 7.50394 12.6517C7.50394 12.785 7.5572 12.9128 7.65189 13.0067L10.0327 15.3567C10.1234 15.4534 10.2501 15.5082 10.3828 15.5082C10.5154 15.5082 10.6421 15.4534 10.7329 15.3567Z"
      />
    </svg>
  );
}
