import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Input } from '@common/components/atoms/Input';
import { Button } from '@common/components/atoms/Button';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import { useLoginUser } from '@pages/Auth/Login/hooks/useLoginUsers';
import { useAuthStore } from '@common/store/auth';
import { isEmpty, isNil } from 'lodash-es';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { SignedIn } from '@clerk/clerk-react';
import { ldIdentify } from '@common/helpers/ld-identify';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { LoginResponse, User } from '@common/api/auth';

const schema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address.' }),
  password: z.string()
});

export function Login() {
  const ldClient = useLDClient();
  const { mutate: login, isSuccess, data, isError, isPending: isLoading } = useLoginUser();
  const { hotel, setHotel } = useAuthStore();
  const { trackEvent } = useEventTracking({ isHotelAccountLoaded: !!hotel });
  const { handleSubmit, control, getFieldState, watch } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });
  const [searchParams] = useSearchParams();
  const error = searchParams.getAll('error');

  const navigate = useNavigate();
  const { email, password } = watch();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    async function identifyUser(data: LoginResponse | undefined) {
      if (!isSuccess || !data) return;
      // Only For Client Users
      if (isEmpty(data.hotel_list) && !data.user.is_staff && isNil(data.user.sub_account_type))
        return;

      // Needed for LD flags
      await ldIdentify(ldClient, { user: { key: 'user', ...data.user } });

      if (data.token && data.user) {
        trackEvent('Login');

        if (data.user.is_staff) {
          navigate('/otp');
          return;
        }

        // If a user is sub_account_type === 2 (which is a partner user), redirect to admin page
        if (data.user.sub_account_type === 2) {
          navigate('/admin/clients');
          return;
        }

        if (hotel) {
          navigate(`/client/calendar/${hotel}`);
          return;
        }

        const clientHotel =
          data.hotel_list.filter(
            (hotel) =>
              (hotel.state === 0 && !hotel.is_client_access_disabled) ||
              (hotel.state === 1 && !hotel.is_client_access_disabled)
          )[0]?.id ?? data.hotel_list[0].id;

        setHotel(clientHotel);
        navigate(`/client/calendar/${clientHotel}`);
      }
    }

    identifyUser(data);
  }, [isSuccess]);

  const handleLogin = () => {
    login({
      email,
      password
    });
  };

  // throw new Error();
  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <SignedIn>
        <Navigate to="/login-compass" replace />
      </SignedIn>
      <div className="mx-auto w-full 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
            <div className="h-14 w-14">
              <RoomPriceGenieText className="fill-indigo" />
            </div>
          </div>
          {isEmpty(error) ? (
            <Typography element="h2" variant="h4" className="font-medium" color="darkGrey">
              Welcome to RoomPriceGenie
            </Typography>
          ) : null}
        </div>

        <div className="mt-8">
          <div className="mt-6">
            {!isEmpty(error) ? (
              <div className="flex max-w-md flex-1 flex-col items-center justify-center rounded-md border-2 border-dashed border-grey-reduced p-10">
                <img src="/genie-working.gif" className="max-h-64" />
                <div className="flex flex-col items-center">
                  <Icon.WarningOutline color="darkGrey" />
                  <strong className="font-bold text-darkGrey">Error!</strong>
                </div>
                <ul className="flex flex-col">
                  {error.map((error, index) => (
                    <li key={index} className="block text-meta-1 sm:inline">
                      {error.length > 1 ? `${index + 1}.` : null}
                      {error}
                    </li>
                  ))}
                </ul>
                <div className="mt-10">
                  <Button type="button" onClick={() => navigate('/sso')} intent="outline">
                    Back To Login
                  </Button>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit(handleLogin)}>
                <div className="space-y-6">
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name="email"
                      render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                        <Input
                          disabled={isLoading}
                          type="email"
                          tabIndex={1}
                          label="Email*"
                          placeholder="Email"
                          value={value || ''}
                          error={invalid || isError}
                          helperMessage={
                            invalid && (
                              <InputHelperMessage
                                icon={
                                  error ? (
                                    <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                  ) : null
                                }
                                message={error?.message}
                              />
                            )
                          }
                          onChange={onChange}
                          showClearButton={false}
                        />
                      )}
                    />
                  </div>

                  <div className="space-y-1">
                    <Controller
                      control={control}
                      name="password"
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                        <Input
                          disabled={isLoading}
                          tabIndex={2}
                          label="Password*"
                          placeholder="Password"
                          type={showPassword ? 'text' : 'password'}
                          value={value || ''}
                          trailingAddon={
                            <button type="button" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? (
                                <Icon.Visible className="h-5 w-5" />
                              ) : (
                                <Icon.Hidden className="h-5 w-5" />
                              )}
                            </button>
                          }
                          error={invalid || isError}
                          helperMessage={
                            invalid && (
                              <InputHelperMessage
                                icon={
                                  error ? (
                                    <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                  ) : null
                                }
                                message={error?.message}
                              />
                            )
                          }
                          onChange={(e) => {
                            onChange(e);
                          }}
                          showClearButton={false}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="mt-2 flex items-center justify-between">
                  <a target="_blank" href="https://roompricegenie.com/sign-up/" rel="noreferrer">
                    <Typography className="font-sans text-xs font-normal leading-3 text-indigo">
                      Don&apos;t have an account?
                    </Typography>
                  </a>

                  <Link to="/get-new-password">
                    {' '}
                    <Typography className="text-xs leading-3 text-indigo">
                      Need a new password?
                    </Typography>
                  </Link>
                </div>

                <div className="mt-8 flex flex-col space-y-4">
                  <Button
                    intent="primary"
                    size="large"
                    type="submit"
                    disabled={getFieldState('email').invalid}
                    isLoading={isLoading}>
                    Login
                  </Button>
                  <Button
                    type="button"
                    intent="outline"
                    size="large"
                    onClick={() => navigate('/sso')}>
                    Connect with SSO
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
