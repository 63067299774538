export function Navigate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      {...props}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.9684 19.9421C19.0377 20.1302 18.9909 20.3416 18.8488 20.4825L18.4702 20.8627C18.2811 21.0457 17.9817 21.0457 17.7926 20.8627C14.5549 17.79 9.49175 17.79 6.25404 20.8627C6.06493 21.0457 5.76557 21.0457 5.57647 20.8627L5.19783 20.4825C5.03421 20.3557 4.9633 20.1421 5.01847 19.9421L11.3756 3.32023C11.4525 3.12788 11.6376 3.00134 11.844 3H12.1429C12.3493 3.00134 12.5343 3.12788 12.6112 3.32023L18.9684 19.9421Z"
        fill="currentColor"
      />
    </svg>
  );
}
