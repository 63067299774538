export function Star(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3917 2.32C11.47 2.1244 11.6602 1.99723 11.8703 2H12.1296C12.3379 1.99557 12.5261 2.12405 12.5983 2.32L15.0815 8.21L21.454 8.72C21.6591 8.73255 21.8344 8.87243 21.8928 9.07L21.9725 9.32C22.0381 9.50869 21.9829 9.7184 21.8329 9.85L16.9863 14L18.4223 20.22C18.4709 20.4188 18.3963 20.6275 18.2328 20.75L18.0134 20.9C17.9282 20.967 17.8225 21.0023 17.7143 21C17.6229 21.002 17.533 20.9777 17.455 20.93L12 17.6L6.57492 20.93C6.49692 20.9777 6.40695 21.002 6.31563 21C6.20738 21.0023 6.10167 20.967 6.01646 20.9L5.76714 20.75C5.60364 20.6275 5.52904 20.4188 5.57766 20.22L7.01371 14L2.17702 9.86C2.01929 9.72918 1.95959 9.51365 2.02744 9.32L2.13713 9.07C2.19546 8.87243 2.37082 8.73255 2.57593 8.72L8.94839 8.21L11.3917 2.32ZM18.6517 10.25L13.8549 9.87L12 5.41L10.1451 9.87L5.3483 10.25L9.00822 13.38L7.88132 18.06L12 15.55L16.1087 18.06L14.9918 13.38L18.6517 10.25Z"
      />
    </svg>
  );
}
