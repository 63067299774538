import { useBanners } from '@common/store/banner';
import { useFeaturesStore } from '@common/store/features';
import { Anchor } from '@mantine/core';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useMinStayBanner = () => {
  const { createBanner, removeBanner } = useBanners();
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { hasMinStayFeature } = useFeaturesStore();

  const pmsProvider = hotelDetails?.pms_provider;

  const bannerDescriptions = {
    mews: (
      <div>
        <span>
          <strong>{t('Please note:')}</strong>{' '}
        </span>
        {t(
          'Our integration with Mews does not allow us to overwrite the minimum stay restrictions that you have set in Mews. This means that Orphan Gap correction and other automation features will not work on these restrictions. To fully benefit from Minimum Stay Restrictions in RoomPriceGenie, we recommend removing any existing restrictions in Mews and setting all minimum stay restrictions directly through RoomPriceGenie.'
        )}
      </div>
    ),
    beds24: (
      <div>
        <span>
          <strong>{t('Please note:')}</strong>{' '}
        </span>
        {t(
          'Before setting Minimum Stay restrictions in RoomPriceGenie, please ensure your Beds24 account is properly configured. Make sure no default Min Stay policies are applied to your Room Types and Daily Prices.'
        )}{' '}
        {t('Follow')}
        <Anchor
          mx={4}
          fz="sm"
          fw={500}
          underline="always"
          c="indigo"
          href="https://app.tango.us/app/workflow/Configuring-your-Beds24-Account-for-Min-Stay-Restrictions-c116f7606a2149a0ab94dadcbaf76b33"
          target="_blank"
          rel="noopener noreferrer">
          {t('this guide')}
        </Anchor>
        {t('to check your settings and make adjustments if necessary.')}
      </div>
    )
  };

  const bannerConfig: Record<number, { bannerId: string; description: JSX.Element }> = {
    [PmsProvider.MEWS]: {
      bannerId: 'min-stay-mews-announcement',
      description: bannerDescriptions.mews
    },
    [PmsProvider.BED_24_V2_API]: {
      bannerId: 'min-stay-bed24-announcement',
      description: bannerDescriptions.beds24
    }
  };

  useEffect(() => {
    if (hasMinStayFeature() && pmsProvider && bannerConfig[pmsProvider]) {
      const { bannerId, description } = bannerConfig[pmsProvider];

      if (bannerId && description) {
        createBanner({
          id: bannerId,
          description,
          type: 'info',
          showAction: false
        });

        return () => removeBanner(bannerId);
      }
    }
  }, [pmsProvider, hasMinStayFeature]);
};
