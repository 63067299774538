import { getPricingSettings } from '@common/api/roomPrices';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const usePricingSettings = (isEnabled?: boolean) => {
  const pricingSettingsQuery = useQuery({
    queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS],
    queryFn: getPricingSettings,
    enabled: Boolean(isEnabled)
  });

  const pricingSettings = useMemo(() => {
    return pricingSettingsQuery.data;
  }, [pricingSettingsQuery.data]);

  return {
    pricingSettingsQuery,
    pricingSettings
  };
};
