import AutoCompleteInput from '@common/components/atoms/AutoCompleteInput';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportIssueModal } from '@pages/Client/Help/components/ReportIssueModal';
import { WalkthroughsModal } from '@pages/Client/Help/components/WalkthroughsModal';
import { cn } from '@common/utils/cn';
import { useDocumentTitle } from '@mantine/hooks';

export const Help = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Help'));
  const subdomain = window.location.hostname.split('.')[0];
  const isIframe = subdomain.includes('iframe');
  const { isOpen: reportOpen, openModal: reportClick, closeModal: reportClose } = useModal();
  const { isOpen: walkOpen, openModal: walkClick, closeModal: walkClose } = useModal();
  const [query, setQuery] = useState('');

  const HELP_CENTER_OPTIONS = [
    {
      value: '1',
      label: t('Change Reference Room'),
      link: ' https://help.roompricegenie.com/knowledge/how-to-change-the-reference-room'
    },
    {
      value: '2',
      label: t('Set Base Price'),
      link: 'https://help.roompricegenie.com/knowledge/set-base-price'
    },
    {
      value: '3',
      label: t('Bulk-Edit Prices'),
      link: 'https://help.roompricegenie.com/knowledge/bulk-edit-prices'
    },
    {
      value: '4',
      label: t(`Close Rooms`),
      link: 'https://help.roompricegenie.com/knowledge/closed-rooms'
    }
  ];

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      window.open(
        `https://help.roompricegenie.com/knowledge/kb-search-results?term=${e.target.value}`,
        '_blank'
      );
    }
  };

  return (
    <Page header={<Header title={`${t('How can we help?')}`} />}>
      <div className="rounded-sm bg-white p-4">
        <div className="my-12 flex w-full flex-col items-center justify-center gap-4">
          <Typography variant="h6" element="h6" color="darkGrey">
            {t('Help Center')}
          </Typography>
          <div className="w-full max-w-3xl">
            <AutoCompleteInput
              showOptions
              customSearchWhenNotFound
              options={HELP_CENTER_OPTIONS}
              loadingOptions={false}
              query={query}
              onQueryChange={(value) => {
                setQuery(value);
              }}
              minQueryLength={0}
              onSearchWhenNotFound={(value) => {
                window.open(
                  `https://help.roompricegenie.com/knowledge/kb-search-results?term=${value}`,
                  '_blank'
                );
              }}
              onChange={(value) => {
                window.open(
                  HELP_CENTER_OPTIONS.find((item) => item.value === value?.value)?.link,
                  '_blank'
                );
              }}
              onKeyDown={handleKeyDown}
              placeholder={`${t('Search in Help Center...')}`}
            />
          </div>
        </div>
        <div
          className={cn(
            'my-12 grid grid-cols-2 gap-2  lg:gap-4',
            isIframe ? 'lg:grid-cols-3' : 'lg:grid-cols-4'
          )}>
          {isIframe ? null : (
            <div
              onClick={walkClick}
              className="group flex h-full   flex-wrap items-center  justify-start gap-4 rounded-lg border-[1px] border-grey border-opacity-40 px-4 py-5 hover:cursor-pointer hover:border-indigo hover:border-opacity-100 md:flex-nowrap ">
              <div className="grid h-10 w-10 items-center rounded-full bg-indigo">
                <Icon.VisibleOutlined className="m-auto h-8 w-8 text-white " />
              </div>
              <Typography
                variant="paragraph-2"
                className="min-w-[150px] max-w-[150px] font-medium text-darkGrey group-hover:text-indigo">
                {t('In-App Walkthroughs')}
              </Typography>
            </div>
          )}

          <div
            onClick={() => {
              window.open(
                'https://academy.roompricegenie.com/login/index.php?roompricegenie_client',
                '_blank'
              );
            }}
            className="group flex h-full  flex-wrap items-center  justify-start gap-4 rounded-lg border-[1px] border-grey border-opacity-40 px-4 py-5 hover:cursor-pointer hover:border-indigo hover:border-opacity-100 md:flex-nowrap ">
            <div className="grid h-10 w-10 items-center rounded-full bg-indigo">
              <Icon.Pen className="m-auto h-8 w-8 text-white " />
            </div>
            <Typography
              variant="paragraph-2"
              className="min-w-[150px] max-w-[150px] font-medium text-darkGrey group-hover:text-indigo">
              {t('RoomPriceGenie Academy')}
            </Typography>
          </div>

          <div
            onClick={() => {
              window.open('https://help.roompricegenie.com/knowledge', '_blank');
            }}
            className="group flex h-full  flex-wrap items-center  justify-start gap-4 rounded-lg border-[1px] border-grey border-opacity-40 px-4 py-5 hover:cursor-pointer hover:border-indigo hover:border-opacity-100 md:flex-nowrap ">
            <div className="grid h-10 w-10 items-center rounded-full bg-indigo">
              <Icon.HelpOutlined className="m-auto h-8 w-8 fill-white " />
            </div>
            <Typography
              variant="paragraph-2"
              className="font-medium text-darkGrey group-hover:text-indigo ">
              {t('Go to Help Center')}
            </Typography>
          </div>

          <div
            onClick={reportClick}
            className="group flex h-full  flex-wrap items-center  justify-start gap-4 rounded-lg border-[1px] border-grey border-opacity-40 px-4 py-5 hover:cursor-pointer hover:border-indigo hover:border-opacity-100 md:flex-nowrap ">
            <div className="grid h-10 w-10 items-center rounded-full bg-indigo">
              <Icon.ErrorOutlined className="m-auto h-8 w-8 text-white " />
            </div>
            <Typography
              variant="paragraph-2"
              className="font-medium text-darkGrey group-hover:text-indigo">
              {t('Report an Issue')}
            </Typography>
          </div>
        </div>
        <ReportIssueModal isOpen={reportOpen} onClose={reportClose} />
        <WalkthroughsModal isOpen={walkOpen} onClose={walkClose} />
      </div>
    </Page>
  );
};
