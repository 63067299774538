import { create } from 'zustand';

interface AuthStore {
  ssoToken: string | null;
  setSsoToken: (token: string | null) => void;
}

export const useGetSSOToken = create<AuthStore>((set) => ({
  ssoToken: null,
  setSsoToken: (token) => set({ ssoToken: token })
}));
