import { Button } from '@common/components/atoms/Button';
import { getEnvVar } from '@common/utils/env';
import { zodResolver } from '@hookform/resolvers/zod';
import { Editor } from '@tinymce/tinymce-react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react18-json-view/src/style.css';
import * as z from 'zod';
import { useCreateNote } from '@pages/Client/Notes/hooks/useNotes';

const schema = z.object({
  note: z.string().nonempty('Note is required')
});

interface PopoverNoteProps {
  onClose: () => void;
}

export const PopoverNote = ({ onClose }: PopoverNoteProps) => {
  const { t } = useTranslation();
  const { createNote } = useCreateNote();

  const {
    reset,
    control,
    getValues,
    handleSubmit,
    formState: { isValid }
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const onSubmit = async () => {
    await createNote(getValues().note);
    reset();
    onClose();
  };

  return (
    <div className="flex flex-col gap-1">
      <div>
        <Controller
          control={control}
          name="note"
          render={({ field: { value, onChange } }) => (
            <Editor
              apiKey={`${getEnvVar('VITE_TINYMCE')}`}
              value={value}
              init={{
                auto_focus: true,
                height: 300,
                menubar: false,
                branding: false,
                plugins:
                  'anchor autolink autosave charmap code codesample directionality fullscreen help image importcss insertdatetime link nonbreaking pagebreak preview save searchreplace table lists visualblocks visualchars wordcount',
                toolbar:
                  'bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent numlist bullist | forecolor backcolor pagebreak link codesample',
                toolbar_mode: 'sliding',
                toolbar_persist: true,
                advlist_bullet_styles: 'square'
              }}
              onEditorChange={(content, _editor) => {
                onChange(content);
              }}
            />
          )}
        />
      </div>
      <div className="flex w-full items-center justify-end gap-3 p-2">
        <Button disabled={!isValid} type="button" intent="primary" onClick={handleSubmit(onSubmit)}>
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
