import { find } from 'lodash-es';
import { ADJUSTMENT } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { AdjustmentType } from '@pages/Client/Calendar/constants';
import { AggressiveAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/AggressiveAdjustmentForm';
import { ClosedSpacesAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/ClosedSpacesAdjustmentForm';
import { DerivationAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/DerivationAdjustmentForm';
import { FixedPricesAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/FixedPricesAdjustmentForm';
import { MedianBookingAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/MedianBookingAdjustmentForm';
import { MinMaxAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/MinMaxAdjustmentForm';
import { MinStayAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/MinStayAdjustmentForm';
import { OccupancyAdjustmentWrapper } from '@pages/Client/Calendar/components/BulkEdit/Fragments/OccupancyAdjustmentWrapper';
import { PercentageAdjustmentForm } from '@pages/Client/Calendar/components/BulkEdit/Adjustments/PercentageAdjustmentForm';
import { useBulkAdjustmentOptions } from '@pages/Client/Calendar/hooks/useBulkAdjustmentOptions';
import { useFormContext } from 'react-hook-form';
import { usePricingAdjustmentOptions } from '@pages/Client/Calendar/hooks/usePricingAdjustmentOptions';

export type AdjustmentOptionType = {
  id: string;
  label: string;
  value: AdjustmentType;
};

export function PricingForm() {
  const { getValues } = useFormContext();
  const isBulkEdit = getValues('isBulkEdit');
  const adjustmentType = getValues('adjustmentType');
  const { adjustmentOptions: bulkAdjustmentOptions } = useBulkAdjustmentOptions();
  const { adjustmentOptions: pricingAdjustmentOptions } = usePricingAdjustmentOptions();
  const options = isBulkEdit ? bulkAdjustmentOptions : pricingAdjustmentOptions;

  const { id } = find(options, ['value', adjustmentType]) || options[0] || {};

  return (
    <div>
      {id === ADJUSTMENT.AGGRESSIVENESS ? <AggressiveAdjustmentForm /> : null}
      {id === ADJUSTMENT.CLOSED ? <ClosedSpacesAdjustmentForm /> : null}
      {id === ADJUSTMENT.DERIVATION ? <DerivationAdjustmentForm /> : null}
      {id === ADJUSTMENT.FIX_PRICES ? <FixedPricesAdjustmentForm /> : null}
      {id === ADJUSTMENT.MEDIAN ? <MedianBookingAdjustmentForm /> : null}
      {id === ADJUSTMENT.MIN_MAX ? <MinMaxAdjustmentForm /> : null}
      {id === ADJUSTMENT.MIN_STAY ? <MinStayAdjustmentForm /> : null}
      {id === ADJUSTMENT.OCCUPANCY ? <OccupancyAdjustmentWrapper /> : null}
      {id === ADJUSTMENT.PERCENT ? <PercentageAdjustmentForm /> : null}
    </div>
  );
}
