import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ManageIframeModal from '@pages/Client/Account/Integrations/components/iframe/ManageIframeModal';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useTranslation } from 'react-i18next';

const ManageIframe = () => {
  const { hasManageIframe } = usePmsProvider();
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();
  return (
    <>
      {hasManageIframe() ? (
        <Button type="button" variant="outline" onClick={open}>
          {t('Manage Iframe')}
        </Button>
      ) : null}
      <ManageIframeModal isOpen={opened} onClose={close} />
    </>
  );
};

export default ManageIframe;
