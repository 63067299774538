import { axiosInstance } from '../axiosInstance';
import { GetWeatherInfoResponse } from './weather.type';

export const getWeatherInfo = () => {
  return axiosInstance.get<GetWeatherInfoResponse[]>('/pricingalgorithm/calculate-weather/', {
    params: {
      format: 'json'
    }
  });
};
