export const DASHBOARD_TAGS = [
  { label: 'Process All Reservation', value: 'Process All Reservation' },
  { label: 'Reservation', value: 'Reservation' },
  { label: 'Update Reservation KPI', value: 'Update Reservation KPI' },
  {
    label: 'Dashboard: No Reservations for Mapped Room Types',
    value: 'Dashboard: No Reservations for Mapped Room Types'
  },
  { label: 'Transformation failed', value: 'Transformation failed' },
  { label: 'Dashboard cache calculation failed', value: 'Dashboard cache calculation failed' },
  { label: 'Analytics cache calculation failed', value: 'Analytics cache calculation failed' },
  {
    label: 'Segmentation cache calculation failed',
    value: 'Segmentation cache calculation failed'
  },
  { label: 'Forecasting cache calculation failed', value: 'Forecasting cache calculation failed' }
];
