import { getScheduleHistory } from '@common/api/amalgamation';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useScheduleHistory = (page?: number) => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, error, isError } = useQuery({
    queryKey: [UtilQueryKeys.GET_SCHEDULE_HISTORY_LIST, page],
    queryFn: () => {
      return getScheduleHistory(page);
    },

    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get schedule history list');

  return {
    scheduleHistoryList: data?.data,
    isLoading,
    error
  };
};
