import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SelectDropdownInput } from '@common/components/atoms/Select/SelectDropdown';
import { useBulkAdjustmentOptions } from '@pages/Client/Calendar/hooks/useBulkAdjustmentOptions';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { AdjustmentOptionType } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { isNil, isNull } from 'lodash-es';

export function AdjustmentTypeSelect() {
  const formName = 'adjustmentType';
  const { setValue, getValues, trigger } = useFormContext();
  const { adjustmentOptions } = useBulkAdjustmentOptions();
  const { adjustment } = useCalendarPageStore();
  const newAdjustment = getValues(formName);

  useEffect(() => {
    const defaultAdjustment = adjustmentOptions[0]?.value;
    if ((isNull(adjustment) || isNil(adjustment)) && newAdjustment === 0) {
      setValue(formName, defaultAdjustment);
      trigger(formName);
    } else if (!isNull(adjustment) && !isNil(adjustment) && newAdjustment === 0) {
      setValue(formName, adjustment);
      trigger(formName);
    }
  }, [adjustment, newAdjustment]);

  return (
    <SelectDropdownInput<AdjustmentOptionType>
      background="grey"
      fullWidth
      options={adjustmentOptions}
      name={formName}
    />
  );
}
