import { MappedData } from '@common/api/hotel/types';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateRoomMappingModal } from '@pages/Client/Account/Integrations/components/UpdateRoomMappingModal';
import { useHotelPmsDataMap } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPmsDataMap';
import { useModal } from '@common/hooks/useModal';

interface Unit {
  id: number | null;
  unit: string;
}

interface TransformedData {
  id: number | string;
  name: string;
  extra: string;
  raw_extra: Unit[];
}

export const RoomMappingTable = () => {
  const { t } = useTranslation();
  const {
    hotelPmsDataMap,
    query: { isLoading }
  } = useHotelPmsDataMap();

  function transformData(pmsDataMap: MappedData[]): TransformedData[] {
    return pmsDataMap.map((item) => {
      if (!item || !item.extra) {
        return {
          id: item ? item.id : 0,
          name: item ? item.name : ' ',
          extra: ' ',
          raw_extra: ''
        };
      }

      const extraData = JSON.parse(item.extra);
      const unitNames = extraData.room_units
        .map((unit: { id: number | null; unit: string }) => unit.unit)
        .join(', ');

      return {
        id: item.id,
        name: item.name,
        extra: unitNames,
        raw_extra: extraData.room_units
      };
    });
  }
  const transformedPmsData = transformData(hotelPmsDataMap?.mapped_data || []);

  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();

  const [selectedRoomType, setSelectedRoomType] = useState<TransformedData>({
    id: '',
    name: '',
    extra: '',
    raw_extra: []
  });

  const columns = [
    {
      header: 'Room Type Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Room Name/Number',
      accessorKey: 'extra',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {(row?.getValue() as string) || '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  return (
    <div className="my-8 max-w-4xl">
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          setSelectedRoomType(row?.row.original);
          editClick();
        }}
        headerComponent={
          <div className="my-3 flex items-center justify-between">
            <Typography variant="h6" element="h6" color="darkGrey">
              {t('Room Units')}
            </Typography>
          </div>
        }
        skeletonCount={10}
        columns={columns}
        data={transformedPmsData ? transformedPmsData : []}
        isHover
        isRowClickable
      />
      <UpdateRoomMappingModal
        selectedRoomType={selectedRoomType}
        isOpen={editOpen}
        onClose={editClose}
      />
    </div>
  );
};
