import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { cn } from '@common/utils/cn';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { editSchema } from '@pages/Client/PricingStrategy/RoomSetup/common/formSchema';
import { useHotelPricePerOccupancyV2 } from '@pages/Client/PricingStrategy/RoomSetup/hooks/useHotelPricePerOccupancy';
import { useRoomSetupStore } from '@pages/Client/PricingStrategy/RoomSetup/store/roomSetup';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { PmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const OccupancyPricingEdit: React.FC = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useFormContext<z.infer<typeof editSchema>>();
  const { hotelDetails } = useHotelDetails();
  const { pmsMapping, pricingSetup } = getValues();
  const defaultOccupancy = watch('occupancyPricing.defaultOccupancy');
  const { hotelPricePerOccupancyV2 } = useHotelPricePerOccupancyV2(pmsMapping.roomId);
  const { isOccupancyPercentDerivation } = useRoomSetupStore();
  const basePrice = pricingSetup?.basePrice;

  const isPmsHasRuleMaxOccupancy = [PmsProvider.ROOM_MASTER, PmsProvider.SKYTOUCH].includes(
    Number(hotelDetails?.pms_provider)
  );
  const isSkyTouch = hotelDetails?.pms_provider === PmsProvider.SKYTOUCH;

  const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  const minOccupancy = watch('occupancyPricing.minOccupancy');
  const maxOccupancy = watch('occupancyPricing.maxOccupancy');

  useEffect(() => {
    if (defaultOccupancy === undefined || minOccupancy === undefined || maxOccupancy === undefined)
      return;
    if (defaultOccupancy < minOccupancy || defaultOccupancy > maxOccupancy) {
      setError('occupancyPricing.defaultOccupancy', {
        type: 'manual',
        message: `Default Occupancy must be between ${minOccupancy} and ${maxOccupancy}`
      });
    } else {
      clearErrors('occupancyPricing.defaultOccupancy');
    }

    if (isPmsHasRuleMaxOccupancy && maxOccupancy > 2) {
      setError('occupancyPricing.maxOccupancy', {
        type: 'manual',
        message: `${pmsOrChannelManager} limits max occupancy to 2.`
      });
    } else {
      clearErrors('occupancyPricing.maxOccupancy');
    }

    if (isSkyTouch && minOccupancy !== 1) {
      setError('occupancyPricing.minOccupancy', {
        type: 'manual',
        message: `${pmsOrChannelManager} min occupancy must be 1.`
      });
    } else {
      clearErrors('occupancyPricing.minOccupancy');
    }
  }, [defaultOccupancy, minOccupancy, maxOccupancy, setError, clearErrors]);

  useEffect(() => {
    if (!hotelPricePerOccupancyV2) return;

    // Set default, min, and max occupancy
    setValue('occupancyPricing.defaultOccupancy', hotelPricePerOccupancyV2.data.occupancy.default);
    setValue('occupancyPricing.minOccupancy', hotelPricePerOccupancyV2.data.occupancy.minimum);
    setValue('occupancyPricing.maxOccupancy', hotelPricePerOccupancyV2.data.occupancy.maximum);

    // Use derivations array directly and fill missing values
    const minOccupancy = hotelPricePerOccupancyV2.data.occupancy.minimum;
    const maxOccupancy = hotelPricePerOccupancyV2.data.occupancy.maximum;

    // Generate an array of derivations covering the full range
    const derivations = Array.from({ length: maxOccupancy - minOccupancy + 1 }, (_, i) => {
      const occupancy = minOccupancy + i;
      const existingDerivation = hotelPricePerOccupancyV2.data.derivations.find(
        (d) => d.occupancy === occupancy
      );

      return {
        occupancy,
        derivation: existingDerivation ? existingDerivation.derivation : 0 // Default to 0 if not found
      };
    });

    setValue('occupancyPricing.occupancyDerivation', derivations);
  }, [hotelPricePerOccupancyV2, setValue]);

  const isReconline = hotelDetails?.pms_provider === 44;
  const isWebhotelier = hotelDetails?.pms_provider === 28;
  const isOperaCloud = hotelDetails?.pms_provider === 61;
  const isSeekom = hotelDetails?.pms_provider === 24;

  return (
    <>
      <div className="flex w-full gap-y-6 gap-4">
        <Controller
          control={control}
          name="occupancyPricing.defaultOccupancy"
          rules={{
            validate: (value) => {
              if (!value) return;
              return value >= (minOccupancy || 0) && value <= (maxOccupancy || 0);
            }
          }}
          render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              type="number"
              label={`${t('Default Occupancy')}`}
              placeholder={`${t('Default occupancy')}`}
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              helperMessage={
                errors?.occupancyPricing?.defaultOccupancy && (
                  <InputHelperMessage
                    message={errors?.occupancyPricing?.defaultOccupancy?.message}
                    icon={<Icon.WarningOutline className="h-4 w-4" />}
                  />
                )
              }
              error={invalid}
            />
          )}
        />
        <Controller
          control={control}
          name="occupancyPricing.minOccupancy"
          render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              readOnly={!hotelPricePerOccupancyV2?.data.occupancy.allow_minmax_change}
              type="number"
              label={`${t('Min Occupancy')}`}
              placeholder={`${t('Min occupancy')}`}
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              helperMessage={
                errors?.occupancyPricing?.minOccupancy && (
                  <InputHelperMessage
                    message={errors?.occupancyPricing?.minOccupancy?.message}
                    icon={<Icon.WarningOutline className="h-4 w-4" />}
                  />
                )
              }
              error={invalid}
            />
          )}
        />
        <Controller
          control={control}
          name="occupancyPricing.maxOccupancy"
          render={({ field: { value, onChange, name }, fieldState: { invalid } }) => (
            <Input
              showClearButton={false}
              disabled={isSubmitting}
              readOnly={!hotelPricePerOccupancyV2?.data.occupancy.allow_minmax_change}
              type="number"
              label={`${t('Max Occupancy')}`}
              placeholder={`${t('Max occupancy')}`}
              background="grey"
              name={name}
              value={value}
              onChange={onChange}
              helperMessage={
                errors?.occupancyPricing?.maxOccupancy && (
                  <InputHelperMessage
                    message={errors?.occupancyPricing?.maxOccupancy?.message}
                    icon={<Icon.WarningOutline className="h-4 w-4" />}
                  />
                )
              }
              error={invalid}
            />
          )}
        />
      </div>

      <LineTable>
        <thead>
          <tr>
            <th>{t('Occupancy (Persons)')}</th>
            <th>{t('Price Difference from Default Occupancy Price')}</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            const derivations = watch('occupancyPricing.occupancyDerivation') || [];
            const minOccupancy = watch('occupancyPricing.minOccupancy');
            const maxOccupancy = watch('occupancyPricing.maxOccupancy');

            if (!minOccupancy || !maxOccupancy || derivations.length === 0) return null;

            return derivations.map((item, index) => {
              const isOccEqualDefault = isEqual(Number(item.occupancy), Number(defaultOccupancy));

              return (
                <tr
                  key={item.occupancy}
                  className={cn(isOccEqualDefault ? 'bg-indigo bg-opacity-[0.06]' : null)}
                >
                  <td align="right">{item.occupancy}</td>
                  <td>
                    {isOccEqualDefault ? (
                      <Typography color="darkGrey">
                        {t('Base Price')}: {CurrencyFormatter.currencySymbol()}
                        {basePrice}
                      </Typography>
                    ) : (
                      <div className="w-full md:w-2/3">
                        <Controller
                          control={control}
                          name={`occupancyPricing.occupancyDerivation.${index}.derivation`}
                          render={({
                            field: { value, onChange },
                            fieldState: { error, invalid }
                          }) => (
                            <Input
                              disabled={isSubmitting}
                              leadingAddon={
                                (defaultOccupancy || 0) < item.occupancy
                                  ? `${
                                      isOccupancyPercentDerivation
                                        ? '%'
                                        : CurrencyFormatter.currencySymbol()
                                    }+`
                                  : `${
                                      isOccupancyPercentDerivation
                                        ? '%'
                                        : CurrencyFormatter.currencySymbol()
                                    }-`
                              }
                              background="grey"
                              value={value ?? 0}
                              onChange={(e) => onChange(Number(e.target.value))}
                              error={invalid}
                              helperMessage={error?.message}
                              showClearButton={false}
                            />
                          )}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            });
          })()}
        </tbody>
      </LineTable>

      {isOperaCloud || isSeekom ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="occupancyPricing.additionalAdultPrice"
            render={({ field: { value, onChange, name }, fieldState: { error, invalid } }) => (
              <Input
                showClearButton={false}
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                label={t('Additional Price Per Person') as string}
                name={name}
                value={value}
                onChange={onChange}
                error={invalid}
                helperMessage={
                  invalid ? (
                    <InputHelperMessage
                      icon={<Icon.WarningOutline className="h-3 w-3 fill-uiRed" />}
                      message={error?.message}
                    />
                  ) : null
                }
              />
            )}
          />
        </div>
      ) : null}

      {isReconline ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="occupancyPricing.additionalChildPrice"
            render={({ field: { value, onChange, name } }) => (
              <Input
                disabled={isSubmitting}
                label="+1 Child Additional Fee (2 Adults)"
                placeholder="Please enter the additional child fee"
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                name={name}
                value={value}
                onChange={onChange}
                showClearButton={false}
              />
            )}
          />
        </div>
      ) : null}

      {isWebhotelier ? (
        <div className="flex w-full flex-col gap-y-6 md:w-2/3">
          <Controller
            control={control}
            name="occupancyPricing.additionalAdultPrice"
            render={({ field: { value, onChange, name } }) => (
              <Input
                disabled={isSubmitting}
                label="Extra Adult"
                placeholder="Please enter the extra child fee"
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                name={name}
                value={value}
                onChange={onChange}
                showClearButton={false}
              />
            )}
          />
          <Controller
            control={control}
            name="occupancyPricing.additionalChildPrice"
            render={({ field: { value, onChange, name } }) => (
              <Input
                disabled={isSubmitting}
                label="Extra Child"
                placeholder="Please enter the extra child fee"
                background="grey"
                leadingAddon={CurrencyFormatter.currencySymbol()}
                name={name}
                value={value}
                onChange={onChange}
                showClearButton={false}
              />
            )}
          />
        </div>
      ) : null}
    </>
  );
};
