export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_FORMAT_FULL = 'YYYY-MM-DD HH:mm:ss';
export const WEEK_DAYS = (startWithMonday: boolean) =>
  startWithMonday
    ? (['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const)
    : (['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const);

export const DATE_FORMAT_WITH_DAY = 'MMM, MM/DD/YYYY';

export const DATE_FORMAT_DATE_FNS = 'dd/MM/yyyy';
export const DATE_FORMAT_FULL_DATE_FNS = 'dd/MM/yyyy, HH:mm:ss';
