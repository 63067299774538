import { EditReportData } from '@common/api/dashboard/types';
import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Modal } from '@common/components/molecules/Modal';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateScheduleReports } from '@pages/Client/Account/ScheduleReports/hooks/useScheduleReports';
import { isValidScheduleReportData } from '@pages/Client/Account/ScheduleReports/utils/isValidScheduleReportData';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

type userOptions = {
  label: string;
  value: number;
};

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  userOptions: userOptions[] | undefined;
  data?: EditReportData;
};

export const EditScheduleReportsModal = ({ isOpen, onClose, userOptions, data }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelAuthToken } = useHotelStore();
  const { hotelDetails: hotel } = useHotelDetails();

  const handleComplete = () => {
    reset();
    onClose();
    setEditScheduleReport(defaultEditScheduleReport);
  };
  const { updateScheduleReports, isLoading } = useUpdateScheduleReports({
    onComplete: handleComplete
  });
  const { addNotification } = useNotificationsStore();

  const schema = z.object({
    name: z.string().nonempty('Please enter a name report')
  });

  const { handleSubmit, control, reset, watch, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const defaultEditScheduleReport: EditReportData = {
    id: data ? data.id : 0,
    name: data ? data.name : '',
    report_type: data ? data.report_type : '',
    mode: 'YEARLY',
    frequency: data ? data.frequency : '',
    accounting_mode: data ? data.accounting_mode : '',
    pickup_tab_options: null,
    financials_tab_options: null,
    receipient_user_ids: data ? data.receipient_user_ids : [],
    hotel: hotel ? hotel.id : 0,
    token: hotelAuthToken
  };
  const [editScheduleReport, setEditScheduleReport] =
    useState<EditReportData>(defaultEditScheduleReport);

  const ACCOUNTING_MODE = [
    {
      label: 'Net',
      value: 'NET'
    },
    {
      label: 'Gross',
      value: 'GROSS'
    }
  ];
  const FREQUENCY = [
    {
      label: 'Daily',
      value: 'DAILY'
    },
    {
      label: 'Weekly',
      value: 'WEEKLY'
    },
    {
      label: 'Monthly',
      value: 'MONTHLY'
    }
  ];

  const onUpdate = () => {
    const { name } = watch();
    const dataUpdateScheduleReport: EditReportData = {
      ...editScheduleReport,
      name: name
    };
    if (!isValidScheduleReportData(dataUpdateScheduleReport)) {
      return addNotification('fail', t('Please fill out all fields'));
    }
    updateScheduleReports([data ? data.id : 0, dataUpdateScheduleReport]);
  };

  useEffect(() => {
    if (data) {
      setEditScheduleReport(data);
      reset({
        name: data.name
      });
    }
  }, [data]);

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      onClose={handleClose}
      isLoading={isLoading}
      okText="Update"
      showFooter={false}
      size="custom"
      open={isOpen}
      disableCloseOnOk={!formState.isValid}>
      <div className="min-w-[480px] max-w-[480px] rounded-xl bg-white">
        <Button
          type="button"
          icon
          className="absolute right-6 top-6 z-30 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={handleClose}>
          <Icon.Clear className="h-5 w-5" />
        </Button>
        <div className="p-5">
          <div className="flex flex-col items-start gap-4">
            <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
              {t('Update Scheduled Reports')}
            </Typography>
            <div className="flex w-full items-start justify-between gap-4">
              <div className="w-1/2 flex-1 items-start">
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                    <Input
                      disabled={false}
                      type="text"
                      background="grey"
                      tabIndex={1}
                      label={`${t('Report Name*')}`}
                      placeholder={`${t('Please enter report name')}`}
                      value={value || ''}
                      error={invalid}
                      helperMessage={
                        invalid && (
                          <InputHelperMessage
                            icon={
                              error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                            }
                            message={error?.message}
                          />
                        )
                      }
                      onChange={onChange}
                      onClear={() => {
                        reset({ name: '' });
                      }}
                    />
                  )}
                />
              </div>
              <div className="w-1/2 flex-1 items-start">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Accounting Mode')}*
                </Typography>
                <SelectDropdown
                  name="Accounting Mode*"
                  options={ACCOUNTING_MODE}
                  value={editScheduleReport.accounting_mode}
                  onChange={(value) => {
                    setEditScheduleReport({ ...editScheduleReport, accounting_mode: value });
                  }}
                />
              </div>
            </div>
            <div className="flex w-full items-start justify-between gap-4">
              <div className="w-1/2 flex-1 items-start">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Report Type*')}
                </Typography>
                <SelectDropdown
                  name="Performance"
                  value={editScheduleReport.report_type}
                  options={[
                    {
                      label: 'Performance',
                      value: 'PERFORMANCE'
                    }
                  ]}
                  onChange={(value) => {
                    setEditScheduleReport({ ...editScheduleReport, report_type: value });
                  }}
                />
              </div>
              <div className="w-1/2 flex-1 items-start">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Frequency*')}
                </Typography>
                <SelectDropdown
                  name="Frequency"
                  options={FREQUENCY}
                  value={editScheduleReport.frequency}
                  onChange={(value) => {
                    setEditScheduleReport({ ...editScheduleReport, frequency: value });
                  }}
                />
              </div>
            </div>
            <div className="flex w-full items-start justify-between gap-4">
              <div className="max-w-1/2 w-1/2 flex-1 items-start">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Recipients*')}
                </Typography>
                <MultiSelect
                  fullWidth
                  name="property_list"
                  placeholder="Select user recipients"
                  options={userOptions}
                  value={editScheduleReport.receipient_user_ids}
                  onChange={(value) => {
                    const newValue = value as [];
                    setEditScheduleReport({ ...editScheduleReport, receipient_user_ids: newValue });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => onClose()}
              className="text-copyTextGrey">
              {t('Cancel')}
            </Button>
            <Button type="button" intent="primary" onClick={handleSubmit(onUpdate)}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
