import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import * as z from 'zod';
import { useAdminChangePassword } from '@pages/Client/ChangePassword/hooks/useChangePassword';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useEffect, useState } from 'react';
import {
  numberRegex,
  PasswordValidationMessage,
  specialCharRegex
} from '@common/constants/password';
import { useErrorChangePasswordStore } from '@pages/Client/ChangePassword/store/password';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '@mantine/hooks';

const schema = z.object({
  new_password: z
    .string()
    .min(8, PasswordValidationMessage.Length)
    .max(50)
    .refine((value) => /[A-Z]/.test(value), {
      message: PasswordValidationMessage.UppercaseLetter,
      path: ['new_password']
    })
    .refine((value) => numberRegex.test(value), {
      message: PasswordValidationMessage.Number,
      path: ['new_password']
    })
    .refine((value) => specialCharRegex.test(value), {
      message: PasswordValidationMessage.SpecialChar,
      path: ['new_password']
    })
});

export const ChangePassword = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Change Password'));
  const { token } = useAuthStore();
  const { hotelDetails } = useHotelDetails();
  const { changePassword, isLoading } = useAdminChangePassword();
  const { isError, message: errorPasswordMessage, setError } = useErrorChangePasswordStore();

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = () => {
    changePassword({
      new_password: watch('new_password'),
      token: token as string,
      user: hotelDetails ? hotelDetails?.client : null
    });
  };

  const getRefineErrors = () => {
    if ((errors.new_password as any)?.new_password) {
      return (errors.new_password as any).new_password.message;
    }
    return '';
  };

  useEffect(() => {
    if (isError && !watch('new_password')) {
      setError(false);
    }
  }, [isError, watch('new_password')]);

  return (
    <Page
      header={
        <div className="max-w-4xl">
          <Header
            title="Change Password"
            actions={
              <div className="pr-16">
                <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading}>
                  Change Password
                </Button>
              </div>
            }
          />
        </div>
      }>
      <div className="flex min-h-[100px] max-w-3xl flex-col justify-center rounded-sm bg-white p-4 ">
        <Controller
          name="new_password"
          control={control}
          render={({ field: { value, name, onChange } }) => (
            <Input
              background="grey"
              placeholder="Please enter New Password"
              error={errors.new_password || isError ? true : false}
              label="New Password *"
              name={name}
              id={name}
              value={value}
              onChange={(e) => {
                onChange(e);
              }}
              onClear={() => {
                reset({
                  new_password: ''
                });
                setError(false);
              }}
              type={showPassword ? 'text' : 'password'}
              trailingAddon={
                <button onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <Icon.Visible className="h-5 w-5" />
                  ) : (
                    <Icon.Hidden className="h-5 w-5" color="grey" />
                  )}
                </button>
              }
              helperMessage={
                isError ? (
                  <div className="flex items-center gap-2 text-error">
                    <Icon.WarningOutline className="w-4" />
                    <Typography element="span" color="error" variant="meta-2">
                      {errorPasswordMessage}
                    </Typography>
                  </div>
                ) : errors ? (
                  <div className="flex items-center gap-2 text-error">
                    {errors.new_password ? <Icon.WarningOutline className="w-4" /> : ''}
                    <Typography element="span" color="error" variant="meta-2">
                      {errors.new_password ? errors?.new_password?.message?.toString() : ''}
                      {(errors?.new_password as any)?.new_password &&
                      watch('new_password').length >= 8
                        ? getRefineErrors()
                        : ''}
                    </Typography>
                  </div>
                ) : null
              }
            />
          )}
        />
      </div>
    </Page>
  );
};
