import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Genie, RoomPriceGenieText } from '@common/components/foundations/Logo';
import ApaleoLogoWhite from '@common/assets/images/apaleo_logo.png';
import ApaleoLogoDark from '@common/assets/images/apaleo-logo-dark.webp';
import { getEnvVar } from '@common/utils/env';
import { Icon } from '@common/components/foundations/icons';
import { useEffect } from 'react';

export const Apaleo = () => {
  useEffect(() => {
    document.title = 'RoomPriceGenie + apaleo: Start using RoomPriceGenie with your apaleo account';
  }, []);

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className="hidden w-0 p-4 lg:block lg:w-1/2">
        <div className="flex h-full justify-center rounded-lg bg-[#363941]">
          <div className="flex items-center gap-x-8">
            <div className="flex items-center gap-x-4">
              <div className="h-auto w-8 2xl:w-16">
                <Genie className="fill-white" />
              </div>
              <div className="h-16 w-16 2xl:h-24 2xl:w-24">
                <RoomPriceGenieText className="fill-white" />
              </div>
            </div>
            <div>
              <Icon.Add className="h-12 w-12 text-white 2xl:h-16 2xl:w-16" />
            </div>
            <div className="h-16 w-auto shrink-0 2xl:h-24">
              <img
                className="h-full w-full shrink-0"
                src={ApaleoLogoWhite}
                alt="apaleo white logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full 2xl:w-3/5">
          <div>
            <div className="mb-4 flex items-center gap-x-4 lg:hidden">
              <div className="flex items-center gap-x-2">
                <div className="h-auto w-10">
                  <Genie className="fill-[#030b23]" />
                </div>
                <div className="h-14 w-14">
                  <RoomPriceGenieText className="fill-[#030b23]" />
                </div>
                <div>
                  <Icon.Add className="h-8 w-8 text-[#030b23]" />
                </div>
                <div className="h-12 w-auto shrink-0">
                  <img
                    className="h-full w-full shrink-0"
                    src={ApaleoLogoDark}
                    alt="apaleo dark logo"
                  />
                </div>
              </div>
            </div>
            <Typography element="h1" variant="h6" className="font-medium" color="darkGrey">
              Start using RoomPriceGenie with your apaleo account
            </Typography>

            <div className="mt-2">
              <Typography>
                Please use the SSO button below to create your free account. Our revenue management
                team will get in contact with you shortly. We&apos;ll do an initial setup for you
                and then schedule a call to fine-tune your strategy.
              </Typography>
            </div>
          </div>

          <div className="mt-8">
            <div className="space-y-6">
              <div className="mt-2">
                <div className="flex max-w-xs flex-col space-y-2">
                  <a href={getEnvVar('VITE_SSO_APALEO')}>
                    <Button intent="apaleo" size="large" fullWidth>
                      Connect with apaleo
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
