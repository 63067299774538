import { reprocessData } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useGetCurrentUTCTime } from '@pages/Client/hooks/useGetCurrentUTCTime';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

export const useReprocessData = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelDetails } = useHotelDetails();
  const channelManagerOrPms = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';

  const { data: currentUtcTime } = useGetCurrentUTCTime();
  const startDate = dayjs
    .utc(currentUtcTime?.current_utc_time)
    .tz(hotelDetails?.timezone)
    .format('YYYY-MM-DD');
  const date = dayjs(startDate).subtract(3, 'day').format('YYYY-MM-DD');

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return reprocessData(date).then((res) => {
        if (res.data.error) {
          addNotification('fail', `${res.data.message}`);
        } else {
          addNotification('success', `${res.data.message}`);
        }
      });
    },
    onError: () => {
      addNotification('fail', `Failed to reprocess ${channelManagerOrPms} data`);
    }
  });

  return {
    reprocessData: mutate,
    isLoading
  };
};
