export function ArrowUpWard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M12.5 22H11.5C11.2238 22 11 21.7761 11 21.5V5.33L6.55997 9.78C6.46609 9.87466 6.33829 9.9279 6.20497 9.9279C6.07166 9.9279 5.94386 9.87466 5.84997 9.78L5.14997 9.07C5.05532 8.97612 5.00208 8.84832 5.00208 8.715C5.00208 8.58168 5.05532 8.45388 5.14997 8.36L11.28 2.22C11.4205 2.07931 11.6111 2.00018 11.81 2H12.19C12.3884 2.0023 12.5783 2.08112 12.72 2.22L18.85 8.36C18.9446 8.45388 18.9979 8.58168 18.9979 8.715C18.9979 8.84832 18.9446 8.97612 18.85 9.07L18.14 9.78C18.0478 9.87406 17.9217 9.92707 17.79 9.92707C17.6583 9.92707 17.5321 9.87406 17.44 9.78L13 5.33V21.5C13 21.7761 12.7761 22 12.5 22Z" />
    </svg>
  );
}
