export const GenieCircle = (props: any) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="24" cy="24" r="24" fill="#5B48EE" />
      <path
        d="M20.408 36.9006L20.3864 36.9065L20.3789 36.9151C20.1013 37.2336 19.3635 37.6065 18.6473 37.8358C18.4893 37.8864 18.3432 37.9244 18.1969 37.9503L18.1957 37.9505C17.9631 37.9931 17.7284 38.0054 17.4531 37.9849L17.4014 37.981L17.4001 37.9814C16.8313 37.935 15.955 37.7872 15.2152 37.5868C14.8344 37.4837 14.4583 37.3622 14.1562 37.2366C13.8533 37.1107 13.629 36.9824 13.545 36.8655L13.5449 36.8655C13.4392 36.7191 13.6276 36.5314 13.8951 36.3498C14.0275 36.26 14.1766 36.1733 14.3124 36.0962C14.3359 36.0828 14.3591 36.0697 14.3817 36.0569C14.4825 35.9999 14.573 35.9487 14.6367 35.9074C15.0642 35.9075 15.5016 35.8894 15.879 35.8505C16.2794 35.8092 16.6249 35.7429 16.8087 35.6458L16.8087 35.6458C17.088 35.4981 17.0835 35.3315 16.9337 35.1735C17.0772 35.1417 17.2151 35.1094 17.3486 35.0781C17.3787 35.071 17.4086 35.064 17.4383 35.0571C17.6122 35.0164 17.7791 34.9782 17.9455 34.9448C18.2781 34.8782 18.6016 34.833 18.9601 34.8286L18.9601 34.8286L18.9625 34.8286C19.6472 34.8147 20.525 34.9019 21.207 35.0789L21.2076 35.079C21.8779 35.25 22.4784 35.4256 22.8797 35.583C23.0804 35.6618 23.2284 35.7349 23.3113 35.7997C23.3945 35.8648 23.4077 35.918 23.3572 35.9596C23.3008 36.0048 23.227 36.0475 23.1372 36.0866L23.1371 36.0866L23.136 36.0871C22.8922 36.1985 22.51 36.3204 22.0358 36.4558C21.7193 36.5462 21.3652 36.6417 20.9876 36.7436C20.7996 36.7943 20.6059 36.8466 20.408 36.9006ZM20.4679 36.9217C20.1855 37.2456 19.4386 37.6228 18.7127 37.8552C18.5512 37.9069 18.398 37.947 18.2409 37.9749C17.9871 38.0213 17.7276 38.0348 17.4289 38.0126L20.4679 36.9217ZM16.8471 35.1581C17.0017 35.1242 17.1497 35.0895 17.2939 35.0557C17.8517 34.9249 18.3524 34.8075 18.9593 34.8002C19.6703 34.7858 20.5704 34.876 21.2676 35.0569C22.6121 35.3999 23.6917 35.7648 23.4433 35.9689C23.3841 36.0165 23.3064 36.0613 23.2118 36.1026L16.8471 35.1581ZM16.7295 35.632C16.9883 35.4951 16.9906 35.3393 16.8503 35.1877L16.7295 35.632Z"
        fill="white"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M23.2251 36.1493C24.0362 36.494 24.4996 36.9159 24.4712 37.3591C24.4607 37.5227 24.3703 37.6547 24.2826 37.7828C24.2476 37.8339 24.2131 37.8844 24.1842 37.936C24.083 38.1166 24.0587 38.303 24.3513 38.5575C24.6449 38.8128 25.1159 38.9153 25.5828 38.9191C26.0396 38.9228 26.4586 38.8309 26.6393 38.7197L26.6395 38.7196C26.7261 38.6657 27.0203 38.4958 27.427 38.3672C27.6303 38.303 27.8555 38.2511 28.0906 38.2278C28.321 38.205 28.5665 38.2091 28.823 38.2599C29.089 38.3125 29.2207 38.3695 29.2728 38.4275C29.3254 38.486 29.3007 38.5496 29.228 38.6178C29.1551 38.686 29.0369 38.7563 28.9113 38.8272C28.8917 38.8383 28.8718 38.8494 28.8519 38.8606C28.7464 38.9197 28.6403 38.9791 28.5627 39.0356C28.4143 39.1435 28.391 39.223 28.4348 39.2795C28.446 39.294 28.4615 39.3069 28.4803 39.3182C28.4023 39.3077 28.336 39.2883 28.2929 39.2615C28.2113 39.2108 28.2085 39.129 28.409 39.0265C28.6811 38.8874 28.764 38.7743 28.733 38.6872C28.7019 38.5997 28.5562 38.5405 28.3876 38.5113C28.1953 38.478 28.0013 38.5041 27.8552 38.5424C27.708 38.5811 27.5831 38.638 27.5018 38.6875C27.4718 38.7058 27.4333 38.7357 27.387 38.7715C27.3694 38.7852 27.3507 38.7997 27.3309 38.8147C27.2575 38.8707 27.1655 38.9374 27.0514 39.0054C26.8212 39.1426 26.5085 39.2802 26.0908 39.3479C25.6531 39.4188 25.2416 39.4601 24.8297 39.4514C24.4197 39.4428 23.9954 39.3844 23.5347 39.2475C23.1348 39.1287 22.8767 39.0432 22.6475 38.9345C22.4177 38.8255 22.2148 38.6921 21.9305 38.4767C21.6381 38.2551 21.227 38.1254 20.6255 38.0505C20.0869 37.9833 19.4 37.9607 18.5237 37.9509C18.6101 37.9291 18.696 37.9042 18.7832 37.8763C19.5138 37.6423 20.2643 37.2646 20.557 36.9365C20.7458 36.8851 20.9316 36.8349 21.1125 36.7861C21.4922 36.6837 21.8505 36.587 22.1696 36.4959C22.6019 36.3725 22.9685 36.2574 23.2251 36.1493Z"
        fill="white"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M8.39928 34.5569C8.64097 34.1806 8.72337 33.9261 8.70883 33.7207C8.921 33.7288 9.2082 33.7278 9.49756 33.7202C9.84698 33.7109 10.2136 33.6913 10.4732 33.6632C10.5437 33.6566 10.6121 33.6499 10.6794 33.6434C10.9583 33.6162 11.2175 33.5909 11.5246 33.5792C11.9032 33.5648 12.3568 33.5711 12.9965 33.6243C13.6211 33.6763 14.1579 33.8144 14.5293 33.9587C14.7144 34.0306 14.8561 34.1032 14.9458 34.1658C14.9907 34.1971 15.022 34.2255 15.0395 34.2498C15.0445 34.2568 15.0483 34.2633 15.051 34.2694C15.0455 34.2687 15.04 34.2681 15.0344 34.2674C14.7741 34.236 14.3426 34.184 13.711 34.1808C13.3197 34.1788 12.7526 34.2228 12.2871 34.272C12.0528 34.2968 11.8396 34.3234 11.6821 34.3472C11.6038 34.359 11.5364 34.3706 11.4865 34.3813C11.4617 34.3866 11.4384 34.3922 11.4196 34.3983C11.4031 34.4036 11.379 34.4126 11.373 34.4246L11.373 34.4246C11.3538 34.4636 11.3042 34.5221 11.2417 34.596C11.2364 34.6021 11.2311 34.6084 11.2258 34.6148C11.1566 34.6966 11.0759 34.7937 11.0101 34.8971C10.8791 35.1029 10.804 35.3377 11.0045 35.5293C11.1429 35.6631 11.3408 35.7443 11.5564 35.8009C11.7144 35.8423 11.8839 35.8705 12.0341 35.8955C12.0606 35.9 12.0866 35.9043 12.1118 35.9086C11.81 35.9593 11.3609 36.0168 10.872 36.0307C10.3075 36.0466 9.68872 36.0045 9.17363 35.8242C7.94138 35.3917 8.14485 34.953 8.39928 34.5569Z"
        fill="white"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M15.1601 34.3126C15.1786 34.3165 15.1973 34.3206 15.2162 34.3249C15.2159 34.325 15.2157 34.3251 15.2155 34.3251C15.2116 34.3267 15.2073 34.328 15.2095 34.3273L15.1586 34.3133L15.1601 34.3126ZM15.109 34.3367L15.109 34.3367C15.698 34.4627 16.5126 34.746 16.8263 35.0556L16.8264 35.0556C17.0198 35.2442 17.0226 35.4399 16.6831 35.6151L16.6831 35.6151C16.4827 35.7187 16.0981 35.7993 15.6128 35.8558C15.1293 35.9121 14.5576 35.9431 14.0013 35.9495L14.001 35.9495C13.3356 35.9578 12.7011 35.9303 12.2766 35.8715C12.2248 35.8623 12.1728 35.8536 12.1207 35.8449C11.9744 35.8205 11.8275 35.796 11.6842 35.7585C11.4934 35.7084 11.3121 35.6353 11.1826 35.5102L11.1825 35.5101C10.9958 35.3318 11.0629 35.1084 11.1928 34.9044C11.2574 34.8028 11.3368 34.7071 11.4059 34.6254C11.4117 34.6186 11.4174 34.6118 11.423 34.6052C11.4819 34.5356 11.5325 34.476 11.5546 34.4342C11.5609 34.4323 11.5717 34.4295 11.5885 34.4259C11.6264 34.4178 11.6835 34.4078 11.7578 34.3966C11.9057 34.3743 12.1106 34.3486 12.3396 34.3244C12.8007 34.2756 13.3409 34.2346 13.6972 34.2365C14.2918 34.2395 14.6889 34.2871 14.9512 34.3186C15.0104 34.3257 15.0628 34.332 15.109 34.3367Z"
        fill="white"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M14.586 34.1854C14.6729 34.0445 13.763 33.5807 12.4698 33.4741C11.1767 33.3674 10.6064 33.4465 9.8921 33.5135C9.39523 33.5675 8.45842 33.5907 8.05626 33.564C8.08324 33.7727 8.00515 34.033 7.75194 34.4298C7.49872 34.8267 7.28532 35.2773 8.54516 35.7195C9.61669 36.0946 11.1378 35.8968 11.7366 35.7841C11.7119 35.7798 11.6939 35.7761 11.6715 35.7724C11.2948 35.7049 10.8042 35.655 10.5363 35.3961C10.149 35.0262 10.8209 34.4795 10.9077 34.3034C10.9321 34.2548 12.3993 34.0811 13.1468 34.085C13.8944 34.0888 14.3463 34.162 14.5861 34.1867C14.5839 34.186 14.5839 34.186 14.586 34.1854Z"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M11.5682 35.7564C11.5995 35.7612 11.6331 35.7667 11.671 35.7722C12.1127 35.8337 12.7648 35.8615 13.4406 35.8531C14.5693 35.8401 15.7706 35.7276 16.2039 35.5038C16.5613 35.3194 16.5546 35.115 16.3565 34.9219C16.0326 34.6023 15.1889 34.3115 14.5854 34.1851C14.5786 34.1832 14.5697 34.182 14.5629 34.1801"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M21.8288 18.9314L21.8844 18.971C22.065 18.7171 22.3462 18.5001 22.6859 18.3128C23.025 18.1258 23.4181 17.9708 23.8179 17.8392C24.2175 17.7076 24.6219 17.5999 24.9834 17.5069C25.045 17.491 25.1055 17.4755 25.1646 17.4604C25.4503 17.3874 25.7023 17.323 25.8897 17.2627L25.8926 17.2618C26.2868 17.6477 26.7487 17.9913 27.2183 18.2189C27.7044 18.4545 28.2063 18.5693 28.6516 18.4686L28.6516 18.4686C29.3502 18.3095 29.8541 17.844 30.2032 17.2693C30.449 17.3125 30.6909 17.3477 30.9254 17.3817C30.9706 17.3883 31.0155 17.3949 31.0602 17.4014C31.3446 17.4431 31.6168 17.4852 31.8738 17.5408C32.3881 17.652 32.8343 17.8158 33.1851 18.1308L33.1851 18.1309L33.1866 18.1321C33.8651 18.7077 34.4022 19.7335 34.4815 20.831L34.4815 20.8316C34.568 21.8944 34.5769 22.9116 34.4584 23.704C34.3992 24.1003 34.3086 24.4368 34.1822 24.6936C34.056 24.9503 33.8972 25.1213 33.7044 25.1988C33.5086 25.2726 33.3059 25.3263 33.0993 25.3591L33.099 25.3591C32.5262 25.4523 31.7964 25.4504 30.9457 25.4051C30.3783 25.375 29.7601 25.3257 29.1016 25.2733C28.7731 25.2471 28.4346 25.2201 28.0874 25.1943L28.0683 25.1929L28.0513 25.2017C26.8116 25.8368 24.9774 26.2189 23.6089 26.2189L23.6077 26.2189C23.346 26.2234 23.0846 26.1949 22.8299 26.1342C22.4706 26.0391 22.2073 25.8597 22.0102 25.5508C21.6158 24.9288 21.2464 23.703 21.1849 22.4573C21.1542 21.8347 21.185 21.1651 21.2961 20.5491C21.4075 19.9322 21.5985 19.3743 21.8845 18.9709L21.8288 18.9314ZM21.8288 18.9314C21.2406 19.7611 21.0549 21.2069 21.1168 22.4607C21.1787 23.7145 21.5502 24.9528 21.9527 25.5874L25.8689 17.1978C25.6836 17.2574 25.4342 17.3211 25.1485 17.3942C24.0544 17.6738 22.4278 18.0895 21.8288 18.9314Z"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M21.8288 18.9314L21.8844 18.971C22.065 18.7171 22.3462 18.5001 22.6859 18.3128C23.025 18.1258 23.4181 17.9708 23.8179 17.8392C24.2175 17.7076 24.6219 17.5999 24.9834 17.5069C25.045 17.491 25.1055 17.4755 25.1646 17.4604C25.4503 17.3874 25.7023 17.323 25.8897 17.2627L25.8926 17.2618C26.2868 17.6477 26.7487 17.9913 27.2183 18.2189C27.7044 18.4545 28.2063 18.5693 28.6516 18.4686L28.6516 18.4686C29.3502 18.3095 29.8541 17.844 30.2032 17.2693C30.449 17.3125 30.6909 17.3477 30.9254 17.3817C30.9706 17.3883 31.0155 17.3949 31.0602 17.4014C31.3446 17.4431 31.6168 17.4852 31.8738 17.5408C32.3881 17.652 32.8343 17.8158 33.1851 18.1308L33.1851 18.1309L33.1866 18.1321C33.8651 18.7077 34.4022 19.7335 34.4815 20.831L34.4815 20.8316C34.568 21.8944 34.5769 22.9116 34.4584 23.704C34.3992 24.1003 34.3086 24.4368 34.1822 24.6936C34.056 24.9503 33.8972 25.1213 33.7044 25.1988C33.5086 25.2726 33.3059 25.3263 33.0993 25.3591L33.099 25.3591C32.5262 25.4523 31.7964 25.4504 30.9457 25.4051C30.3783 25.375 29.7601 25.3257 29.1015 25.2733C28.7731 25.2471 28.4346 25.2201 28.0874 25.1943L28.0683 25.1929L28.0513 25.2017C26.8116 25.8368 24.9774 26.2189 23.6089 26.2189L23.6077 26.2189C23.346 26.2234 23.0846 26.1949 22.8299 26.1342C22.4706 26.0391 22.2073 25.8597 22.0102 25.5508C21.6158 24.9288 21.2464 23.703 21.1849 22.4573C21.1542 21.8347 21.185 21.1651 21.2961 20.5491C21.4075 19.9322 21.5985 19.3743 21.8845 18.9709L21.8288 18.9314ZM21.8288 18.9314C21.2406 19.7611 21.0549 21.2069 21.1168 22.4607C21.1787 23.7145 21.5502 24.9528 21.9527 25.5874L25.8689 17.1978C25.6836 17.2574 25.4342 17.3211 25.1485 17.3942C24.0544 17.6738 22.4278 18.0895 21.8288 18.9314ZM25.8967 17.1699C26.6955 17.9687 27.7883 18.594 28.6365 18.4021C29.33 18.2442 29.8284 17.7736 30.172 17.1885L25.8967 17.1699Z"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M23.6087 26.3551C24.9891 26.355 26.8344 25.9728 28.0956 25.3316C28.4343 25.3569 28.7655 25.3833 29.0875 25.4089C29.7472 25.4615 30.3685 25.5109 30.9377 25.5412C31.7446 25.5841 32.4531 25.5889 33.0254 25.508L22.9339 26.2267C23.1557 26.2706 23.3818 26.2908 23.6081 26.2869V26.3551C23.6083 26.3551 23.6085 26.3551 23.6087 26.3551ZM23.6087 26.3551C23.41 26.3585 23.2116 26.3437 23.0159 26.311C23.8288 27.1491 24.4107 27.8367 24.7024 28.5133C25.0155 29.2393 24.9926 29.948 24.5829 30.8052C24.1808 31.6467 23.955 32.1941 23.8291 32.6993C23.7034 33.2035 23.6763 33.6689 23.6763 34.3484C23.6763 35.1357 23.8853 35.6785 24.2411 36.0723C24.5987 36.4681 25.1113 36.7211 25.7335 36.9147C26.3411 37.1037 27.0642 36.9968 27.6977 36.822C28.0132 36.7349 28.3037 36.6318 28.5431 36.5424C28.6096 36.5176 28.6727 36.4936 28.7315 36.4712C28.8818 36.4141 29.0041 36.3676 29.0844 36.3446C29.3062 36.2813 29.586 36.2375 29.8274 36.2603C30.0648 36.2826 30.2944 36.3736 30.3607 36.6084C30.4218 36.8249 30.3754 37.104 30.1447 37.362C29.915 37.6188 29.5049 37.8528 28.8387 37.9939C28.3258 38.1025 28.0605 38.3398 27.973 38.567C27.9078 38.7363 27.9372 38.909 28.0469 39.0389M23.6087 26.3551L28.0469 39.0389M28.0469 39.0389C28.054 38.9574 28.0846 38.866 28.1492 38.772C28.275 38.5887 28.5283 38.3964 28.9874 38.2344L28.0469 39.0389Z"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M27.9898 11.7769C28.126 11.684 29.5501 12.3651 30.0485 12.9223C30.4572 13.3793 30.6161 13.7968 30.7164 14.0602C30.7286 14.0923 30.74 14.122 30.7508 14.1493C30.7681 14.1377 30.7865 14.1222 30.8058 14.1026M27.9898 11.7769L30.8058 14.1026M27.9898 11.7769C27.8765 11.855 27.6928 11.9554 27.4693 12.0776M27.9898 11.7769L27.4693 12.0776M30.8058 14.1026C30.8298 14.0781 30.8553 14.0473 30.8815 14.0099C30.9303 13.9403 30.9796 13.8514 31.0265 13.7462C31.1203 13.5358 31.203 13.2647 31.2544 12.9635C31.3574 12.3597 31.3331 11.6464 31.0342 11.0651C30.7296 10.4727 30.4347 10.1228 30.1386 9.87538C29.9003 9.67619 29.661 9.54257 29.407 9.40081C29.3437 9.36545 29.2795 9.32959 29.2141 9.29208C28.9716 9.16736 28.6762 8.9444 28.4165 8.70962C28.1897 8.50451 27.9841 8.28504 27.863 8.10789C27.3667 8.59171 26.676 9.11597 25.571 9.80696C24.4242 10.5241 23.1992 11.3791 22.9711 13.2772L22.9711 13.2772C22.8752 14.0715 23.1944 14.6272 23.6222 15.0095C24.0101 15.3563 24.4872 15.5597 24.8226 15.6622C24.8135 15.621 24.8032 15.5786 24.7926 15.5348C24.7523 15.3681 24.7072 15.1821 24.7016 14.974C24.6939 14.6901 24.7578 14.3628 24.9896 13.9595L24.9897 13.9594C25.3236 13.3806 25.949 12.9034 26.5434 12.5313C26.8417 12.3446 27.1346 12.1829 27.3826 12.0473C27.4002 12.0376 27.4176 12.0282 27.4347 12.0188C27.6602 11.8955 27.8408 11.7968 27.951 11.7207L27.9514 11.7205C27.9773 11.7029 28.008 11.7001 28.03 11.7001C28.0543 11.7001 28.0818 11.7039 28.1106 11.7098C28.1684 11.7216 28.2408 11.7441 28.3225 11.7744C28.4865 11.8352 28.6956 11.9303 28.915 12.0454C29.3516 12.2744 29.8415 12.5886 30.0993 12.8769C30.5175 13.3444 30.6813 13.7755 30.7811 14.0382C30.7898 14.0609 30.798 14.0824 30.8058 14.1026ZM27.4693 12.0776C26.7278 12.4829 25.5483 13.1276 25.0488 13.9935M27.4693 12.0776L25.0488 13.9935M25.0488 13.9935C24.6555 14.6776 24.7638 15.1251 24.8577 15.5132M25.0488 13.9935L24.8577 15.5132M24.8577 15.5132C24.8717 15.5712 24.8854 15.6279 24.8971 15.6838L24.8577 15.5132Z"
        stroke="white"
        strokeWidth="0.136418"
      />
      <path
        d="M30.7816 14.2103C30.8806 14.9409 30.7351 16.2225 30.1717 17.1884C29.8281 17.7735 29.3296 18.2441 28.6362 18.402C27.7879 18.5939 26.6951 17.9686 25.8964 17.1698C25.4166 16.6931 25.0451 16.1513 24.8965 15.687C24.8067 15.2566 24.5962 14.783 25.0481 13.9966C25.6983 12.8698 27.5 12.1175 27.9892 11.78C28.1254 11.6872 29.5495 12.3683 30.0479 12.9255C30.5463 13.4827 30.6732 13.9812 30.7754 14.2134L30.7816 14.2103Z"
        stroke="white"
        strokeWidth="0.818506"
      />
      <path
        d="M27.8447 8.12207C28.475 8.95567 29.272 11.0133 27.9057 11.8428"
        stroke="white"
        strokeWidth="0.818506"
      />
      <path
        d="M25.8689 17.1978C24.9742 17.4857 22.5842 17.8695 21.8288 18.9314C21.2406 19.7611 21.0549 21.2099 21.1168 22.4606C21.1787 23.7113 21.5502 24.9527 21.9527 25.5874"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5127 18.6133C25.0464 18.748 26.0013 18.9509 26.3159 19.4171"
        stroke="white"
        strokeWidth="0.818506"
      />
      <path
        d="M31.4932 18.4912C30.9519 18.5915 29.845 18.9051 29.7473 19.3576"
        stroke="white"
        strokeWidth="0.818506"
      />
      <path
        d="M33.1133 25.4014C33.1133 25.4107 33.1102 25.4168 33.1102 25.4261C32.813 27.166 31.9152 28.816 30.4663 30.0636C29.0175 31.3113 27.7111 31.3453 26.7049 33.2214C25.6988 35.0974 27.2158 35.7971 28.1445 35.5247C28.6398 35.3822 31.3641 34.6764 31.7047 36.2676C32.0452 37.8589 30.1413 37.896 29.0113 38.2985C28.1073 38.6174 28.0578 39.0415 28.1445 39.2087C27.6646 38.9177 27.773 38.1499 28.8256 37.927C30.1413 37.6484 30.4044 37.0106 30.2961 36.6268C30.1877 36.2429 29.5376 36.2862 29.1042 36.4101C28.6708 36.5339 26.9681 37.3698 25.7143 36.9797C24.4605 36.5896 23.6091 35.9488 23.6091 34.3482C23.6091 32.9861 23.7175 32.4598 24.5224 30.7757C25.3273 29.0916 23.8195 27.5184 23.2705 26.2985"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M21.9531 25.5873C22.1605 25.9123 22.4392 26.1012 22.8138 26.2002C23.0743 26.2624 23.3416 26.2915 23.6094 26.2869C24.987 26.2869 26.8321 25.903 28.0828 25.2622"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M32.7567 21.7793C31.1384 21.7793 29.5525 23.6147 28.082 25.2621"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M28.082 25.2623C30.1655 25.4171 31.9487 25.6153 33.1096 25.4264C33.321 25.3929 33.5285 25.3379 33.7288 25.2623C34.5863 24.9187 34.7225 22.956 34.5492 20.826C34.4687 19.7115 33.9238 18.6683 33.2304 18.0801C32.5028 17.4268 31.3914 17.4114 30.1903 17.1978"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M23.4531 23.3709C24.9298 22.4267 27.6618 22.2729 31.2605 22.2729"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M24.1855 23.0047C24.2257 22.6023 24.1347 21.6188 24.0635 21.2969"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M31.871 21.968C31.871 21.646 31.8883 21.3973 31.749 20.8091"
        stroke="white"
        strokeWidth="0.818506"
        strokeMiterlimit="10"
      />
      <path
        d="M30.7822 14.21C31.1877 13.9531 31.7109 12.2287 31.0949 11.0368C30.4788 9.84493 29.8937 9.60656 29.2467 9.23506C28.7761 8.99359 28.0827 8.35895 27.8752 8.00293C27.3799 8.49826 26.6864 9.03383 25.5348 9.75206C24.3832 10.4703 23.1356 11.3402 22.9034 13.272C22.7052 14.9128 24.2284 15.5784 24.9095 15.761C24.9064 15.7332 24.9002 15.7115 24.8971 15.6867C24.8073 15.2564 24.5968 14.7827 25.0488 13.9964C25.6989 12.8695 27.5006 12.1173 27.9898 11.7798C28.126 11.6869 29.5501 12.368 30.0485 12.9253C30.5469 13.4825 30.6738 13.9809 30.776 14.2131L30.7822 14.21Z"
        stroke="white"
        strokeWidth="0.818506"
      />
      <path
        d="M24.8623 15.5757C24.8716 15.6098 24.884 15.6469 24.8964 15.6872C25.045 16.1516 25.4165 16.6933 25.8963 17.1701C26.695 17.9688 27.7878 18.5941 28.6361 18.4022C29.3296 18.2443 29.828 17.7738 30.1716 17.1886C30.735 16.2228 30.8806 14.9411 30.7815 14.2105C30.7784 14.2012 30.7784 14.1919 30.7753 14.1826"
        stroke="white"
        strokeWidth="0.818506"
      />
    </svg>
  );
};
