import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Card } from '@common/components/molecules/Card';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { LANGUAGES } from '@common/constants/language';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';
import { useMutation } from '@tanstack/react-query';
import { iframeUrl } from '@common/api/auth';
import { Button } from '@common/components/atoms/Button';
import { useRef, useState } from 'react';
import { Icon } from '@common/components/foundations/icons';
import {
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
  Tooltip
} from '@common/components/molecules/Tooltip';
import { useDocumentTitle } from '@mantine/hooks';
import { UtilQueryKeys } from '@common/types/query-keys';

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
  hotel_id: z.string().min(1),
  language: z.string().min(1)
});

export const RequestIframeURL = () => {
  const { data } = useGetAccountHotel();
  useDocumentTitle('Request Iframe URL');
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const {
    mutateAsync,
    data: iframeUrlResponse,
    isPending: isLoading
  } = useMutation({
    mutationKey: [UtilQueryKeys.REQUEST_IFRAME_URL],
    mutationFn: iframeUrl,
    onSuccess: () => {
      setIsFormSuccess(true);
      setTimeout(() => {
        setIsFormSuccess(false);
      }, 2000);
    }
  });

  const { handleSubmit, control, formState } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      hotel_id: data?.enc_hotel_id
    }
  });

  const onSubmit = async (data: z.infer<typeof schema>) => {
    mutateAsync(data);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setIsCopySuccess(true);

          setTimeout(() => {
            setIsCopySuccess(false);
          }, 3000);
        })
        .catch(() => {
          setIsCopySuccess(false);
        });
    }
  };

  return (
    <Page header={<Header title="Request Iframe URL" />}>
      <Card backgroundColor="white" className="max-w-5xl">
        <div className="flex flex-col gap-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex h-full w-full flex-col gap-4">
              <Controller
                control={control}
                name="email"
                render={({ field: { name, onChange, value }, fieldState: { error, invalid } }) => (
                  <Input
                    label="Partner Email"
                    type="email"
                    name={name}
                    onChange={onChange}
                    value={value}
                    error={invalid}
                    helperMessage={error?.message}
                    background="grey"
                    showClearButton={false}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field: { name, onChange, value }, fieldState: { error, invalid } }) => (
                  <Input
                    label="Partner Password"
                    type="password"
                    name={name}
                    onChange={onChange}
                    value={value}
                    background="grey"
                    error={invalid}
                    helperMessage={error?.message}
                    showClearButton={false}
                  />
                )}
              />
              <Controller
                control={control}
                name="hotel_id"
                render={({ field: { name, onChange, value } }) => (
                  <Input
                    label="Hotel ID"
                    readOnly
                    name={name}
                    showClearButton={false}
                    onChange={onChange}
                    value={value}
                    background="grey"
                  />
                )}
              />
              <Controller
                control={control}
                name="language"
                render={({ field: { name, onChange, value } }) => (
                  <SelectDropdown
                    hint="Select Language"
                    name={name}
                    onChange={onChange}
                    value={value}
                    options={LANGUAGES}
                    background="grey"
                  />
                )}
              />
            </div>
            <div className="flex">
              <Button
                type="submit"
                isLoading={isLoading}
                isSuccess={isFormSuccess}
                disabled={!formState.isValid}>
                Request
              </Button>
            </div>
          </form>
        </div>
        <div className="mt-20 w-full">
          <div className="grid grid-cols-12 items-center gap-3">
            <div className="col-span-11">
              <Input
                ref={inputRef}
                readOnly
                placeholder="Iframe URL"
                value={iframeUrlResponse?.url}
                background="grey"
                showClearButton={false}
              />
            </div>
            <div className="group relative col-span-1">
              <TooltipProvider>
                <Tooltip delayDuration={200}>
                  <TooltipTrigger>
                    <Button icon intent="primary" size="small" onClick={handleCopy}>
                      {isCopySuccess ? (
                        <Icon.CheckList className="h-5 w-5  text-uiGreen" />
                      ) : (
                        <Icon.Copy className="h-5 w-5  text-opacity-100" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="top">{isCopySuccess ? 'Copied!' : 'Copy'}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
};
