import { usePriceDrawerStore } from '@common/store/priceDrawer';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useRouteHash = (hashToTest: string) => {
  const { hash } = useLocation();
  const { drawerState, setDrawerState, setViewingDate } = usePriceDrawerStore();

  useEffect(() => {
    const shouldOpenDrawer = hash.includes(hashToTest) && !drawerState;
    if (shouldOpenDrawer) {
      setDrawerState(true);
      setViewingDate(new Date());
      history.replaceState(
        '',
        document.title,
        `${window.location.pathname}${window.location.search}`
      );
    }
  }, [hash]);
  return;
};
