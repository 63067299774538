import { create } from 'zustand';
interface LoadingStore {
  isLoading: boolean;
  setLoading: (newLoading: boolean) => void;
}

export const useLoadingStore = create<LoadingStore>((set) => ({
  isLoading: false,
  setLoading: (newLoading: boolean) => set({ isLoading: newLoading })
}));
