import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import './ModalLogs.css';
import { Modal } from '@mantine/core';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  Logs?: object;
};

export const ModalLogs = ({ isOpen, onClose, Logs }: Props) => {
  return (
    <Modal opened={isOpen} onClose={onClose} size="xl">
      <JsonView src={Logs} collapseObjectsAfterLength={20} collapseStringsAfterLength={200} />
    </Modal>
  );
};
