export const Settings = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2001 14.0741L21.0001 14.7354C21.3797 15.0439 21.4812 15.582 21.2401 16.008L20.0801 18.012C19.8402 18.4022 19.3666 18.5797 18.9301 18.4428L17.9301 18.0821C17.3399 17.8611 16.6804 17.9317 16.1501 18.2725C15.915 18.4352 15.6676 18.5793 15.4101 18.7034C14.8474 18.9925 14.4543 19.5313 14.3501 20.1562L14.1801 21.1582C14.1009 21.6483 13.6755 22.0063 13.1801 21.9999H10.8801C10.3847 22.0063 9.95931 21.6483 9.88008 21.1582L9.71008 20.1562C9.60585 19.5313 9.21278 18.9925 8.65008 18.7034C8.39258 18.5793 8.14516 18.4352 7.91008 18.2725C7.37979 17.9317 6.72022 17.8611 6.13008 18.0821L5.13008 18.4428C4.67584 18.6119 4.16595 18.4304 3.92008 18.012L2.76008 16.008C2.519 15.582 2.6205 15.0439 3.00008 14.7354L3.80008 14.0741C4.28513 13.6707 4.55195 13.0617 4.52008 12.4309V12V11.5691C4.55195 10.9383 4.28513 10.3293 3.80008 9.92587L3.00008 9.26455C2.6205 8.95612 2.519 8.41797 2.76008 7.99202L3.92008 5.98803C4.16411 5.57925 4.66163 5.39911 5.11008 5.55717L6.11008 5.91789C6.70022 6.13888 7.35979 6.06834 7.89008 5.72751C8.12516 5.56478 8.37258 5.42072 8.63008 5.29665C9.17743 4.99804 9.55457 4.46084 9.65008 3.84376L9.82008 2.84176C9.89931 2.35173 10.3247 1.9937 10.8201 2.00008H13.1201C13.6155 1.9937 14.0409 2.35173 14.1201 2.84176L14.2901 3.84376C14.3943 4.46873 14.7874 5.0075 15.3501 5.29665C15.6076 5.42072 15.855 5.56478 16.0901 5.72751C16.6204 6.06834 17.2799 6.13888 17.8701 5.91789L18.8701 5.55717C19.3243 5.38806 19.8342 5.56962 20.0801 5.98803L21.2401 7.99202C21.4812 8.41797 21.3797 8.95612 21.0001 9.26455L20.2001 9.92587C19.715 10.3293 19.4482 10.9383 19.4801 11.5691V12V12.4309C19.4482 13.0617 19.715 13.6707 20.2001 14.0741ZM9.00008 12C9.00008 13.6602 10.3432 15.006 12.0001 15.006C12.7957 15.006 13.5588 14.6893 14.1214 14.1256C14.684 13.5618 15.0001 12.7972 15.0001 12C15.0001 10.3398 13.6569 8.99401 12.0001 8.99401C10.3432 8.99401 9.00008 10.3398 9.00008 12Z"
      />
    </svg>
  );
};
