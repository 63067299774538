import { SignedIn, SignIn } from '@clerk/clerk-react';
import { Center, Stack } from '@mantine/core';
import { Link, Navigate } from 'react-router-dom';
import { useCheckIfClerkIsAvailableForLogin } from '@common/hooks/useCheckIfClerkIsAvailableForLogin';
import { ClerkAuthFlags } from '@pages/Auth/Clerk/types/clerk-types';
import LoginPage from '@pages/Auth/Login';
import { Typography } from '@common/components/foundations/Typography';

export default function ConditionalLoginPage() {
  const { clerkAvailable, isLoaded } = useCheckIfClerkIsAvailableForLogin();

  switch (true) {
    case !isLoaded:
      return null;

    case isLoaded && !clerkAvailable:
      return <LoginPage />;

    case isLoaded && !!clerkAvailable:
      return <ClerkLoginPage />;

    default:
      return null;
  }
}

export function ClerkLoginPage() {
  return (
    <Center w="100%">
      <Stack align="center">
        <SignIn forceRedirectUrl="/login-compass" />
        <Link to="/login-legacy">
          <Typography className="text-xs leading-3 text-indigo">Legacy Login</Typography>
        </Link>
      </Stack>
      <SignedIn>
        <Navigate to="/login-compass" replace />
      </SignedIn>
    </Center>
  );
}
