import { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { API_DATE_FORMAT } from '@common/constants/date';
import { applyIndividualAdjustment } from '@common/api/hotel';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { isNil, set } from 'lodash-es';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';

export const useRecommendedPrice = (currentViewingDate?: Dayjs) => {
  const { sortedHotelRooms } = useHotelRoomsList();
  const [isUpdatingRecommendedPrice, setIsUpdatingRecommendedPrice] = useState(false);
  const { recommendedPriceData, setRecommendedPriceData } = useCalendarPageStore();
  const { roomPrices } = useRoomPrices();

  const defaultRecommendedPriceData = useMemo(() => {
    if (!currentViewingDate) return;

    return sortedHotelRooms.reduce((accumulateVal, room) => {
      accumulateVal[room.id] =
        roomPrices?.prices.data?.[currentViewingDate.format(API_DATE_FORMAT)]?.[room.id]?.price ||
        0;
      return accumulateVal;
    }, {} as Record<number, number>);
  }, [currentViewingDate]);

  const runPreflight = async (priceSettings: string, currentDateStr: string) => {
    // TODO - align and reuse with src/pages/Client/Calendar/components/BulkEdit/hooks/useFormPreflight.tsx
    setIsUpdatingRecommendedPrice(true);
    try {
      if (isNil(priceSettings)) throw new Error('Price settings are empty');
      const { data } = await applyIndividualAdjustment({
        start_date: currentDateStr,
        end_date: currentDateStr,
        json_settings: priceSettings,
        is_write_to_cache: false
      });
      const newSuggestedPrice = {};
      for (const roomId in data[currentDateStr]) {
        set(newSuggestedPrice, roomId, data[currentDateStr][roomId].price);
      }
      setRecommendedPriceData(newSuggestedPrice);
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdatingRecommendedPrice(false);
    }
  };

  return {
    runPreflight,
    isUpdatingRecommendedPrice,
    recommendedPriceData: recommendedPriceData || defaultRecommendedPriceData,
    setRecommendedPriceData
  };
};

export const useRecommendedMinStay = (currentViewingDate?: Dayjs) => {
  const { sortedHotelRooms } = useHotelRoomsList();
  const [isUpdatingRecommendedMinStay] = useState(false);
  const { recommendedMinStayData, setRecommendedMinStayData } = useCalendarPageStore();
  const { roomPrices } = useRoomPrices();

  const defaultRecommendedMinStayData = useMemo(() => {
    if (!currentViewingDate) return;

    return sortedHotelRooms.reduce((accumulateVal, room) => {
      accumulateVal[room.id] =
        roomPrices?.prices.data?.[currentViewingDate.format(API_DATE_FORMAT)]?.[room.id]
          ?.suggested_min_stay || undefined;
      return accumulateVal;
    }, {} as Record<number, number | undefined>);
  }, [currentViewingDate]);

  const originalMinStayData = useMemo(() => {
    if (!currentViewingDate) return;

    return sortedHotelRooms.reduce((accumulateVal, room) => {
      accumulateVal[room.id] =
        roomPrices?.prices.data?.[currentViewingDate.format(API_DATE_FORMAT)]?.[room.id]
          ?.original_min_stay || undefined;
      return accumulateVal;
    }, {} as Record<number, number | undefined>);
  }, [currentViewingDate]);

  return {
    isUpdatingRecommendedMinStay,
    recommendedMinStayData: recommendedMinStayData || defaultRecommendedMinStayData,
    setRecommendedMinStayData,
    originalMinStayData
  };
};
