import { createRatePlanDerivation, getRatePlanDerivation } from '@common/api/hotel';
import { useAppFlags } from '@common/hooks/useAppFlags';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useGetRateDerivations = (id: number | null) => {
  const { isDerivedRatesV2Enabled } = useAppFlags();

  const query = useQuery({
    queryKey: [PricingQueryKeys.GET_RATE_DERIVATION, id],
    queryFn: () => getRatePlanDerivation(id),
    enabled: !!id && isDerivedRatesV2Enabled
  });
  const rateDerivationList = useMemo(() => {
    return query.data?.data.data.derivations || [];
  }, [query.data]);

  return {
    query,
    rateDerivationList
  };
};

export const useCreateRateDerivation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: [PricingQueryKeys.SAVE_RATE_DERIVATION],
    mutationFn: createRatePlanDerivation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PricingQueryKeys.GET_RATE_DERIVATION]
      });
    }
  });

  return {
    ...mutation,
    createRatePlanDerivations: mutation.mutateAsync,
    isCreateRatePlanDerivations: mutation.isPending
  };
};
