import { RadioGroup } from '@common/components/atoms/RadioGroup';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { useGetTopTwenty } from '../../../hooks/useCompetitorSetup';
import { useGetTopTwentyLoading } from '../../../store/getTopTwentyLoading';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const GET_TOP_TWENTY_OPTIONS = [
  { label: 'Much Closer', value: 10 },
  { label: 'Closer', value: 30 },
  { label: 'Standard', value: 80 },
  { label: 'Further Away', value: 120 },
  { label: 'Much Further Away', value: 180 }
];

const schema = z.object({
  distance: z.number()
});

export const GetTopTwentyCompetitorModal = ({ isOpen, onClose }: ModalProps) => {
  const { getTopTwenty, isLoading: getTopTwentyLoading } = useGetTopTwenty();
  const { setLoading } = useGetTopTwentyLoading();

  const { control, handleSubmit, reset } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      distance: 80
    }
  });

  useEffect(() => {
    setLoading(getTopTwentyLoading);
  }, [getTopTwentyLoading]);

  const onSubmit = async (data: z.infer<typeof schema>) => {
    setLoading(true);
    await getTopTwenty({
      distance: data.distance
    });
    reset({
      distance: 80
    });
  };

  return (
    <Modal
      okText="Get Top 20"
      continueIntent="primary"
      size="sm"
      open={isOpen}
      onClick={handleSubmit(onSubmit)}
      onClose={onClose}
      children={
        <div className="w-full">
          <Typography className="mb-6" element="h6" color="darkGrey" variant="h6">
            Competitor Distance
          </Typography>
          <Typography className="mb-6" element="p" color="darkGrey" variant="paragraph-2">
            How close to the client should the competitor suggestions be?
          </Typography>
          <Controller
            control={control}
            name="distance"
            render={({ field: { value, onChange } }) => (
              <RadioGroup options={GET_TOP_TWENTY_OPTIONS} value={value} onChange={onChange} />
            )}
          />
        </div>
      }
    />
  );
};
