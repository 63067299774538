export function Document(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00033 0.333496H10.0003C10.7367 0.333496 11.3337 0.93045 11.3337 1.66683V8.84016C11.3332 9.37042 11.1222 9.87878 10.747 10.2535L7.92033 13.0802C7.54561 13.4553 7.03725 13.6664 6.50699 13.6668H2.00033C1.26395 13.6668 0.666992 13.0699 0.666992 12.3335V1.66683C0.666992 0.93045 1.26395 0.333496 2.00033 0.333496ZM10.0003 1.66683H2.00033V12.3335H6.00033V9.00016C6.00033 8.63197 6.2988 8.3335 6.66699 8.3335H10.0003V1.66683Z"
      />
    </svg>
  );
}
