import { Button } from '@common/components/atoms/Button';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useHotelStore } from '@common/store/auth';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import * as z from 'zod';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useHotelUpdate } from '@pages/Client/hooks/useHotelUpdate';

const isWithinRange = (val: number | undefined) => val === undefined || (val >= 30 && val <= 100);
const validationMessage = 'Please enter a value between 30% and 100%.';
const validationEmptyMessage = 'Please enter a value';

const aggressivenessErrorMap: z.ZodErrorMap = (error) => {
  switch (error.code) {
    case 'invalid_type':
      return { message: 'Value should be negative' };
    default:
      return { message: 'Value should be negative' };
  }
};

const targetOccupancySchema = z.object({
  0: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  1: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  2: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  3: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  4: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  5: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  6: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  7: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  8: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  9: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  10: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  11: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
    .refine(isWithinRange, { message: validationMessage }),
  ...Array.from({ length: 12 }, (_, i) => `sold_${i + 1}`).reduce(
    (fields, key) => ({
      ...fields,
      [key]: z
        .number()
        .int()
        .optional()
        .refine((val) => val === undefined || val >= 0, {
          message: 'Please enter a positive value'
        })
    }),
    {}
  ),
  target_occupancy: z
    .number()
    .or(z.string().nonempty({ message: validationEmptyMessage }).transform(Number))
});

const medianLeadTimeSchema = z.object({
  medianLeadTime: z.number().or(z.string().transform(Number))
});

const aggressivenessSchema = z.object({
  PELL_weekday: z
    .number({ errorMap: aggressivenessErrorMap })
    .or(z.string().transform(Number))
    .refine((val) => val < 0, {
      message: 'Value should be negative'
    }),
  PELL_weekend: z
    .number({ errorMap: aggressivenessErrorMap })
    .or(z.string().transform(Number))
    .refine((val) => val < 0, {
      message: 'Value should be negative'
    }),
  do_not_reduce_last_day: z.boolean().optional()
});

const closeOutSalesSchema = z.object({
  close_out_days: z.number().or(z.string()),
  close_out_hour: z.number().or(z.string())
});

const mergedSchema = z.object({
  ...targetOccupancySchema.shape,
  ...medianLeadTimeSchema.shape,
  ...aggressivenessSchema.shape,
  ...closeOutSalesSchema.shape
});

export const OccupancyStrategy = () => {
  const { t } = useTranslation();
  const { hotelAuthToken } = useHotelStore();
  const location = useLocation();
  const { features } = useFeaturesStore();
  const { view } = useViewStore();
  const isMedianBookingWindowPage = location.pathname.includes('median-booking-window');
  const isAggressivenessPage = location.pathname.includes('aggressiveness');
  const isCloseOutSalesPage = location.pathname.includes('close-out-sales');
  const isMinStayRulesPage = location.pathname.includes('min-stay-rules');
  const [isSuccessRef, setIsSuccessRef] = useState<{ current: boolean }>({ current: false });

  const isHasAccessAggressiveness = features?.includes(Feature.OccupancyStrategy);

  const {
    isLoading: isSavePricingLoading,
    savePricingSettings,
    isSuccess: isSaveSuccess
  } = useUpdatePricingSettings();

  const { pricingSettingsQuery } = useRoomPrices();
  const { data: pricingSettings } = pricingSettingsQuery;

  const { hotelDetails, query: hotelDetailQuery } = useHotelDetails();
  const {
    updateHotel,
    isLoading: hotelUpdateLoading,
    isSuccess: isHotelUpdateSuccess
  } = useHotelUpdate();

  const getSchema = () => {
    if (isMedianBookingWindowPage) {
      return medianLeadTimeSchema;
    } else if (isAggressivenessPage) {
      return aggressivenessSchema;
    } else if (isCloseOutSalesPage) {
      return closeOutSalesSchema;
    } else {
      return targetOccupancySchema;
    }
  };

  type FormValues =
    | z.infer<typeof targetOccupancySchema>
    | z.infer<typeof medianLeadTimeSchema>
    | z.infer<typeof aggressivenessSchema>
    | z.infer<typeof closeOutSalesSchema>
    | z.infer<typeof mergedSchema>;

  const methods = useForm<FormValues>({
    resolver: zodResolver(getSchema())
  });

  useEffect(() => {
    if (isMedianBookingWindowPage) {
      const defaultValuesMedianLeadTime = {
        medianLeadTime: pricingSettings?.rpg_arguments.median_lead_time
      };
      if (defaultValuesMedianLeadTime) {
        methods.setValue('medianLeadTime', defaultValuesMedianLeadTime.medianLeadTime as number);
      }
    } else if (isAggressivenessPage) {
      const defaultValuesAggressiveness = {
        PEL_weekday: pricingSettings?.rpg_arguments.PELL_weekday,
        PEL_weekend: pricingSettings?.rpg_arguments.PELL_weekend,
        do_not_reduce_last_day: pricingSettings?.rpg_arguments.do_not_reduce_last_day
      };
      if (defaultValuesAggressiveness) {
        methods.setValue('PELL_weekday', defaultValuesAggressiveness.PEL_weekday as number);
        methods.setValue('PELL_weekend', defaultValuesAggressiveness.PEL_weekend as number);
        methods.setValue(
          'do_not_reduce_last_day',
          defaultValuesAggressiveness.do_not_reduce_last_day as boolean
        );
      }
    } else if (isCloseOutSalesPage) {
      const defaultValuesCloseOutSales = {
        close_out_days: hotelDetails?.close_out_days,
        close_out_hour: hotelDetails?.close_out_hour
      };
      if (defaultValuesCloseOutSales) {
        methods.setValue('close_out_days', defaultValuesCloseOutSales.close_out_days as number);
        methods.setValue('close_out_hour', defaultValuesCloseOutSales.close_out_hour as number);
      }
    } else {
      const defaultValuesTargetOccupancy = pricingSettings?.monthly_target_occupancy?.reduce(
        (acc, val, index) => ({ ...acc, [index]: val }),
        {}
      );
      const defaultRoomNightSold = pricingSettings?.rooms_sold_to_groups
        ? Object.keys(pricingSettings.rooms_sold_to_groups).reduce((acc, key) => {
            const index = parseInt(key);
            const soldKey = `sold_${index}`;
            return { ...acc, [soldKey]: pricingSettings.rooms_sold_to_groups[key] };
          }, {})
        : null;

      if (defaultRoomNightSold) {
        Object.entries(defaultRoomNightSold).forEach(([key, value]) => {
          methods.setValue(key as never, value as never);
        });
      }
      if (defaultValuesTargetOccupancy) {
        Object.entries(defaultValuesTargetOccupancy).forEach(([key, value]) => {
          methods.setValue(key as never, value as never);
        });
      }
      methods.setValue('target_occupancy', pricingSettings?.hotel.target_occupancy as number);
    }
  }, [
    methods.setValue,
    isMedianBookingWindowPage,
    isAggressivenessPage,
    isCloseOutSalesPage,
    hotelDetails,
    pricingSettings?.monthly_target_occupancy,
    pricingSettings?.rpg_arguments.median_lead_time,
    pricingSettings?.rpg_arguments.PELL_weekday,
    pricingSettings?.rpg_arguments.PELL_weekend,
    pricingSettings?.rpg_arguments.do_not_reduce_last_day
  ]);

  const onSubmit = async (data: any) => {
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
    if (isMedianBookingWindowPage) {
      const settings = JSON.stringify({
        ...latestPricingSettings,
        rpg_arguments: {
          ...latestPricingSettings?.rpg_arguments,
          median_lead_time: data.medianLeadTime
        }
      });
      await savePricingSettings(settings);
    } else if (isAggressivenessPage) {
      const settings = JSON.stringify({
        ...latestPricingSettings,
        rpg_arguments: {
          ...latestPricingSettings?.rpg_arguments,
          PELL_weekday: data.PELL_weekday,
          PELL_weekend: data.PELL_weekend,
          do_not_reduce_last_day: data.do_not_reduce_last_day
        }
      });
      await savePricingSettings(settings);
    } else if (isCloseOutSalesPage) {
      const settings = {
        close_out_days: data.close_out_days,
        close_out_hour: data.close_out_hour,
        token: hotelAuthToken
      };
      await updateHotel(settings);
      await hotelDetailQuery.refetch();
    } else {
      const monthlyTargetOccupancy = Object.values(data).slice(0, 12);
      const roomsSoldToGroups = Object.values(data).slice(12);
      const roomsSoldToGroupsObject: { [key: number]: number } = {};

      (roomsSoldToGroups as number[]).forEach((val, index) => {
        roomsSoldToGroupsObject[index + 1] = val;
      });

      const settings = JSON.stringify({
        ...latestPricingSettings,
        hotel: {
          ...latestPricingSettings?.hotel,
          target_occupancy: data.target_occupancy
        },
        monthly_target_occupancy: monthlyTargetOccupancy,
        rooms_sold_to_groups: roomsSoldToGroupsObject
      });
      await savePricingSettings(settings);
    }
  };

  const subMenuLink = [
    {
      id: 'target-occupancy',
      label: t('Target Occupancy'),
      to: 'target-occupancy'
    },
    {
      id: 'median-booking-window',
      label: t('Median Booking Window'),
      to: 'median-booking-window'
    },
    ...(isHasAccessAggressiveness || view === 'admin'
      ? [
          {
            id: 'aggressiveness',
            label: t('Aggressiveness'),
            to: 'aggressiveness'
          }
        ]
      : []),
    {
      id: 'close-out-sales',
      label: t('Close Out Sales (Last Day)'),
      to: 'close-out-sales'
    }
  ];

  useEffect(() => {
    setIsSuccessRef({ current: isSaveSuccess || isHotelUpdateSuccess });
    if (isSaveSuccess) {
      setTimeout(() => {
        setIsSuccessRef({ current: false });
      }, 2000);
    }
  }, [isSaveSuccess, isHotelUpdateSuccess]);

  return (
    <FormProvider {...methods}>
      <Page
        header={
          <Header
            title={`${t('Occupancy Strategy')}`}
            tabs={subMenuLink}
            actions={
              isMinStayRulesPage ? null : (
                <Button
                  disabled={isSavePricingLoading || hotelUpdateLoading}
                  isSuccess={isSuccessRef.current}
                  isLoading={isSavePricingLoading}
                  intent="primary"
                  type="submit"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  {t('Save')}
                </Button>
              )
            }
          />
        }
      >
        <Outlet />
      </Page>
    </FormProvider>
  );
};
