import React, { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { KPIEvent } from '@common/components/molecules/KPI';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { useHotelEvents } from '@pages/Client/Calendar/hooks/useHotelEvents';
import { API_DATE_FORMAT } from '@common/constants/date';
import { Typography } from '@common/components/foundations/Typography';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { PriceDrawerUserflowIds } from '@common/types/userflow-ids';

type Props = {
  day: Dayjs;
};

export const EventSection: React.FC<Props> = ({ day }) => {
  const { t } = useTranslation();
  const { events: unsortedEvents, isFetchingHotelDayEvents } = useHotelEvents(day.toDate());
  const [isShowAll, setIsShowAll] = useState(false);

  const currentDate = day.format(API_DATE_FORMAT);

  const events = useMemo(() => {
    return unsortedEvents?.[currentDate]?.sort((a, b) => +b.local_rank - +a.local_rank) ?? [];
  }, [unsortedEvents]);

  const trimmedEvents = useMemo(() => {
    return events.slice(0, 2);
  }, [events]);

  const displayEvents = useMemo(() => {
    return isShowAll ? events : trimmedEvents;
  }, [isShowAll, events]);

  return (
    <>
      <div
        className="text-meta-2-semibold text-grey"
        data-userflow-id={PriceDrawerUserflowIds.EVENTS_TEXT}>
        {t('Events')}
      </div>
      {isFetchingHotelDayEvents ? (
        <Icon.LoadingCircle className="mt-1" />
      ) : events.length > 0 ? (
        <>
          <div className="mt-2 grid grid-cols-1 gap-4">
            {displayEvents.map((event) => (
              <KPIEvent
                key={`${event.start_date}-${event.title}-${event.end_date}`}
                date={`${dayjs(event.start_date).tz().format('DD/MM')} - ${dayjs(
                  event.end_date
                ).format('DD/MM/YYYY')}`}
                name={event.title}
                tag={event.category}
                showAttendance={!isEmpty(event.predicted_event_spend_industries)}
                attendance={event.attendances}
                predictedEventSpending={
                  event.predicted_event_spend_industries?.accommodation ||
                  0 + event.predicted_event_spend_industries?.hospitality ||
                  0 + event.predicted_event_spend_industries?.transportation ||
                  0
                }
              />
            ))}
          </div>

          {events.length > 2 ? (
            <div className="w-1/3">
              <Button
                type="button"
                intent="text"
                className="mt-3 text-meta-2 text-indigo"
                onClick={() => setIsShowAll(!isShowAll)}>
                {isShowAll ? <b>—</b> : <Icon.Add className="h-4 w-4" />}
                {isShowAll ? 'Show less events' : 'Show all events'}
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <Typography element="p" variant="meta-1" className="mt-1 text-left">
          {t('No Events')}
        </Typography>
      )}
    </>
  );
};
