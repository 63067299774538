import { retrieveBlockedRooms } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
export const useRetrieveBlockedRooms = () => {
  const { addNotification } = useNotificationsStore();
  const startDate = dayjs().subtract(2, 'year').format('YYYY-MM-DD');
  const endDate = dayjs().add(2, 'year').format('YYYY-MM-DD');

  // Default Dates
  const dates = {
    start_date: startDate,
    end_date: endDate
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: () => {
      return retrieveBlockedRooms(dates);
    },
    onSuccess: (response) => {
      addNotification(
        'success',
        `${response.data.message ?? `Retrieve Blocked Rooms Task has been started`}`
      );
    },
    onError: () => {
      addNotification('fail', `Failed to retrieve blocked rooms`);
    }
  });

  return {
    retrieveBlockedRoom: mutate,
    isLoading
  };
};
