import clsx from 'clsx';
import { CalendarView } from '@pages/Client/Calendar/components/BigCalendar/CalendarView';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import PickupDateCellContent from '@pages/Client/Calendar/pages/Pickup/components/PickupDateCellContent';
import styles from '@pages/Client/Calendar/pages/Pickup/Pickup.module.css';
import { useHotelEvents } from '@pages/Client/Calendar/hooks/useHotelEvents';
import React, { useEffect } from 'react';
import { CalendarOptions } from '@pages/Client/Calendar/components/CalendarOptions/CalendarOptions';
import dayjs from 'dayjs';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useHotelRoomNotes } from '@pages/Client/Calendar/hooks/useHotelRoomNotes';
import { API_DATE_FORMAT } from '@common/constants/date';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useCalendarInteractions } from '@pages/Client/Calendar/hooks/useCalendarInteractions';
import { CalendarOccupancy } from '@pages/Client/Calendar/components/CalendarOccupancy';
import { useTranslation } from 'react-i18next';
import { NO_MARKET_DATA } from '@pages/Client/Calendar/constants';
import { OccupancyLegend } from '@pages/Client/Calendar/pages/Occupancy/components/OccupancyLegend';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useDocumentTitle } from '@mantine/hooks';

export const Pickup = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Pickup Calendar'));
  const {
    liliusInstance,
    comparePickupTo,
    isTableView,
    setIsTableView,
    initialRoomId,
    setSelectedHotelRoomId
  } = useCalendarPageStore();
  const { getEventNameByDay } = useHotelEvents();
  const { getNoteByDay } = useHotelRoomNotes();
  const {
    roomPrices,
    pricingSettings,
    cachePriceQuery: { isFetching: isCachePriceLoading }
  } = useRoomPrices();
  const { selectedHotelRoomId } = useHotelRoomsList();
  const interactions = useCalendarInteractions();
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();

  useEffect(() => {
    if (isTableView) {
      setIsTableView(false);
      if (initialRoomId) {
        setSelectedHotelRoomId(initialRoomId);
      }
      return;
    }
  }, []);

  const getColorClass = (day: Date) => {
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);

    if (!roomPrices || !roomPrices.pickup_boost_prices[formattedDate]?.arguments) return null;

    let pu: number | string = 0;

    switch (comparePickupTo) {
      case 'yesterday': {
        pu = dayjs(day).isBefore(dayjs().tz(), 'date')
          ? 0
          : roomPrices.pickup_boost_prices[formattedDate]?.arguments
              .roomsleft_change_vs_yesterday ?? 0;
        break;
      }
      case 'last-month': {
        pu = dayjs(day).isBefore(dayjs().tz(), 'date')
          ? 0
          : roomPrices.pickup_boost_prices[formattedDate]?.arguments
              .roomsleft_change_vs_30daysago ?? 0;
        break;
      }
      case 'last-week': {
        pu = dayjs(day).isBefore(dayjs().tz(), 'date')
          ? 0
          : roomPrices.pickup_boost_prices[formattedDate]?.arguments.roomsleft_change_vs_7daysago ??
            0;
        break;
      }
      default: {
        break;
      }
    }

    if (pu < 0 && pu >= -3) return styles.decreased;
    if (pu < -3) return styles.greatlyDecreased;
    if (pu > 0 && pu <= 3) return styles.increased;
    if (pu > 3) return styles.greatlyIncreased;

    return null;
  };

  const getCellContent = (
    day: Date
  ): React.ComponentProps<typeof PickupDateCellContent>['content'] => {
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);

    const data = {
      pu: 'n.A.',
      occupancy: 'n.A.',
      recommendedPrice: 'n.A.'
    };

    if (
      !roomPrices ||
      !roomPrices.pickup_boost_prices[formattedDate]?.arguments ||
      !selectedHotelRoomId
    )
      return data;

    const argumentsData = roomPrices.pickup_boost_prices[formattedDate]?.arguments;
    const priceData = roomPrices.prices.data?.[formattedDate]?.[selectedHotelRoomId];

    const { isSurgePrice, surgePrice } = getSurgePriceForDay(formattedDate, selectedHotelRoomId);

    Object.assign(data, {
      occupancy: `${roomPrices.prices.data?.[formattedDate]?.property?.occupancy ?? 0}%`,
      recommendedPrice: isSurgePrice
        ? `${CurrencyFormatter.format(surgePrice)}`
        : `${CurrencyFormatter.format(priceData?.price)}`
    });

    switch (comparePickupTo) {
      case 'yesterday': {
        Object.assign(data, {
          pu: dayjs(day).startOf('day').isBefore(dayjs().tz(), 'date')
            ? '-'
            : argumentsData.roomsleft_change_vs_yesterday !== null
              ? `${argumentsData.roomsleft_change_vs_yesterday} Pickup`
              : '- Pickup'
        });
        break;
      }
      case 'last-month': {
        Object.assign(data, {
          pu: dayjs(day).startOf('day').isBefore(dayjs().tz(), 'date')
            ? '-'
            : argumentsData.roomsleft_change_vs_30daysago !== null
              ? `${argumentsData.roomsleft_change_vs_30daysago} Pickup`
              : '- Pickup'
        });
        break;
      }
      default: {
        Object.assign(data, {
          pu: dayjs(day).startOf('day').isBefore(dayjs().tz(), 'date')
            ? '-'
            : argumentsData.roomsleft_change_vs_7daysago !== null
              ? `${argumentsData.roomsleft_change_vs_7daysago} Pickup`
              : '- Pickup'
        });
        break;
      }
    }

    return data;
  };

  const getNoteAndEvent = (day: Date): string | null => {
    if (!selectedHotelRoomId) return null;
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);
    const noMarketData =
      roomPrices?.prices.data?.[formattedDate]?.property?.note === NO_MARKET_DATA
        ? t('No Market Data')
        : null;
    const note = getNoteByDay(day);
    const event = getEventNameByDay(day);
    const isSurgeEvent = getSurgeEventByDay(day)?.active;

    const surgeEventNote = isSurgeEvent ? t('Surge Event') : null;

    return noMarketData ?? surgeEventNote ?? note ?? event ?? null;
  };

  if ((!roomPrices?.prices || !roomPrices.pickup_boost_prices) && !isCachePriceLoading)
    return (
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div className="w-full rounded-lg border-2 border-dashed border-mediumGrey p-12">
          <div className="flex flex-col items-center gap-4">
            <Icon.Calendar className="text-copyTextGrey" />
            <div className="flex flex-col items-center gap-1">
              <Typography element="span" variant="paragraph-1" color="copyTextGrey">
                {t('Pickup data not yet available')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="my-2 grid gap-4 xl:grid-cols-2">
        {isTableView ? null : <CalendarOccupancy />}
        {isTableView ? (
          <div className="inline-flex flex-1 items-center justify-end text-paragraph-3 leading-none text-copyTextGrey">
            <OccupancyLegend />
          </div>
        ) : null}

        {isTableView ? null : <CalendarOptions optionType="pickup" />}
      </div>

      <div>
        <CalendarView
          {...interactions}
          context="pickup"
          topRightCellContent={(day) => <span>{getNoteAndEvent(day)}</span>}
          dateCellContent={(date) => <PickupDateCellContent content={getCellContent(date)} />}
          liliusInstance={liliusInstance!}
          dateCellClassName={(date) => clsx(styles.pickupDateCell, getColorClass(date))}
          pricingSettings={pricingSettings}
          selectedRoomId={pricingSettings?.rooms?.reference?.id}
          roomPrices={roomPrices}
          isLoading={isCachePriceLoading}
          surgeEvent={(day) => {
            const isSurgeEvent = getSurgeEventByDay(day)?.active ?? false;
            const surgePriceForDay = getSurgePriceForDay(
              dayjs(day).format(API_DATE_FORMAT),
              selectedHotelRoomId
            );
            const hasSurgePrice = surgePriceForDay?.isSurgePrice;
            return { isSurgeEvent, hasSurgePrice };
          }}
        />
      </div>
    </div>
  );
};
