import { Badge, Button, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
  onInviteUser?: () => void;
  pendingInvitation?: number;
}

export const TableHeader = ({ onInviteUser, pendingInvitation }: TableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Group justify="space-between" py="xs" gap="sm" w="100%">
      <Group gap="sm">
        <Text component="span">{t('Users')}</Text>
        {pendingInvitation ? (
          <Badge variant="dot" color="gold">
            {pendingInvitation} {t('Pending Invitation')}
          </Badge>
        ) : null}
      </Group>
      <Button onClick={onInviteUser}>{t('Invite User')}</Button>
    </Group>
  );
};
