import { createHSTicket } from '@common/api/hotel';
import { CreateHSTicketPayload } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useHSTicket = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const { addNotification } = useNotificationsStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: CreateHSTicketPayload) => {
      return createHSTicket(data);
    },
    onSuccess: () => {
      onSuccess?.();
    },
    onError: () => {
      addNotification('fail', 'Failed to create the ticket');
    }
  });

  return {
    createHSTicket: mutate,
    isLoading
  };
};
