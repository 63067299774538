import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Modal } from '@common/components/molecules/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetReservationsStatus } from '@pages/Client/Account/Integrations/hooks/useGetReservationsStatus';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { ReservationStatusSchema } from '@pages/Client/Calendar/utils/validateBaseSettings';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddNewReservationStatusModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { pricingSettings, pricingSettingsQuery } = useRoomPrices();
  const { reservationStatus, pairStatus } = useGetReservationsStatus();
  const { handleSubmit, control, reset, formState } = useForm<
    z.infer<typeof ReservationStatusSchema>
  >({
    resolver: zodResolver(ReservationStatusSchema)
  });

  const { savePricingSettings } = useUpdatePricingSettings();

  const existingPair = pricingSettings?.reservation_status?.map(
    (status) => status.status_description
  );

  const PAIR_OPTIONS = pairStatus
    ?.map((pair) => ({ value: pair, label: pair }))
    .filter((pair) => !existingPair?.includes(pair.label));

  const onSubmit = async (data: z.infer<typeof ReservationStatusSchema>) => {
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

    const updatedSettings = {
      ...latestPricingSettings,
      reservation_status: [
        ...(latestPricingSettings?.reservation_status ?? []),
        {
          status_description: data.status_description,
          value: data.value
        }
      ]
    };

    await savePricingSettings(JSON.stringify(updatedSettings));
    onClose();
    reset();
  };

  return (
    <Modal
      onClick={handleSubmit(onSubmit)}
      open={isOpen}
      okText={`${t('Save')}`}
      onClose={onClose}
      disableCloseOnOk={!formState.isValid}
      size={'xl'}
      children={
        <div className="min-w-[320px]">
          <div className="flex  flex-col items-start gap-3">
            <Typography className="mb-1" element="h6" color="darkGrey" variant="h6">
              {t(`Add New Status`)}
            </Typography>
            <Controller
              name="status_description"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    fullWidth
                    name="status_description"
                    hint={t('pAir Status') as string}
                    placeholder={t('Select Reservation Status') as string}
                    disabled={!PAIR_OPTIONS?.length}
                    options={PAIR_OPTIONS}
                    background="grey"
                    value={value}
                    onChange={onChange}
                  />
                  <div className="-mt-2">
                    {error && (
                      <div className="ml-2 flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
            <Controller
              name="value"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    fullWidth
                    name="value"
                    hint={t('Reservation Status') as string}
                    placeholder={t('Select Reservation Status') as string}
                    options={reservationStatus?.map((status) => ({
                      value: status.value,
                      label: status.key
                    }))}
                    background="grey"
                    value={value}
                    onChange={onChange}
                  />
                  <div className="-mt-2">
                    {error && (
                      <div className="ml-2 flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </div>
      }
    />
  );
};
