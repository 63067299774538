import { MappedSurgeEvent } from '@pages/Client/PricingStrategy/SurgeProtection/SurgeBulkManage';
import { filter } from 'lodash-es';
import { create } from 'zustand';

interface SurgeModalStore {
  eventsToDismiss: MappedSurgeEvent[];
  modalState: boolean;
  hasSurgeEvents: boolean;
  setModalState: (open: boolean) => void;
  setHasSurgeEvents: (hasEvents: boolean) => void;
  addEventToDismiss: (event: MappedSurgeEvent) => void;
  addAllEventsToDismiss: (events: MappedSurgeEvent[]) => void;
  removeEventToDismiss: (event: MappedSurgeEvent) => void;
  clearEventsToDismiss: () => void;
}

export const useSurgeManageModal = create<SurgeModalStore>()((set) => ({
  eventsToDismiss: [],
  modalState: false,
  hasSurgeEvents: false,
  setModalState: (open) => set({ modalState: open }),
  setHasSurgeEvents: (hasEvents) => set({ hasSurgeEvents: hasEvents }),
  addEventToDismiss: (event) =>
    set((state) => ({ eventsToDismiss: [...state.eventsToDismiss, event] })),
  addAllEventsToDismiss: (events) =>
    set((state) => ({ eventsToDismiss: [...state.eventsToDismiss, ...events] })),
  removeEventToDismiss: (event) =>
    set((state) => ({
      eventsToDismiss: filter(state.eventsToDismiss, (e) => e.id !== event.id)
    })),
  clearEventsToDismiss: () => set({ eventsToDismiss: [] })
}));
