import { Flex, Group } from '@mantine/core';

type ModalFooterProps = {
  children: React.ReactNode;
};

export const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  return (
    <Flex
      bg="gray.0"
      px="lg"
      bottom={0}
      left={0}
      right={0}
      pos="sticky"
      w="100%"
      h="70px"
      align="center"
      justify="flex-end"
      className="shadow-inner">
      <Group gap="xs" align="flex-end" justify="flex-end">
        {children}
      </Group>
    </Flex>
  );
};
