import { Returns } from 'use-lilius';
import { create } from 'zustand';

interface PriceChartStore {
  liliusInstance?: Returns;
  setLiliusInstance: (instance: PriceChartStore['liliusInstance']) => void;
  view?: 'monthly' | 'yearly';
  setView: (view: PriceChartStore['view']) => void;
}

export const usePriceChartStore = create<PriceChartStore>((set, get) => ({
  view: 'yearly',
  setLiliusInstance: (instance) => {
    set({
      liliusInstance: instance
    });
  },
  setView: (view) => {
    const currentLiliusInstance = get().liliusInstance;
    if (!currentLiliusInstance) return;
    set({
      view,
      liliusInstance: {
        ...currentLiliusInstance,
        viewing: new Date()
      }
    });
  }
}));
