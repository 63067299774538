import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore } from '@common/store/auth';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useIframeLogin } from '@pages/Auth/Login/hooks/useLoginUsers';

function extractHotelIDFromURL(url: string): number | null {
  const regex = /\/client\/account\/(\d+)\/integrations\//;
  const matches = url.match(regex);

  if (matches && matches[1]) {
    return parseInt(matches[1]);
  }

  return null;
}

export const RootRedirect = () => {
  const navigate = useNavigate();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const code = url.searchParams.get('code');
  const state = url.searchParams.get('state');
  const { token: tokenFromStore, isAuthenticated, user } = useAuthStore();
  const subdomain = window.location.hostname.split('.')[0];
  const isIframeSubdomain = subdomain.includes('iframe');
  const token = url.searchParams.get('token');
  const hotel_id = url.searchParams.get('hotel_id');
  const language = url.searchParams.get('language');
  const { mutate: iframeLoginMutation } = useIframeLogin(token, hotel_id, language);

  useEffect(() => {
    if (!tokenFromStore) return;
    setAuthToken(tokenFromStore);
  }, [tokenFromStore]);

  useEffect(() => {
    if (isIframeSubdomain && token && hotel_id && language) {
      iframeLoginMutation();
    }
  }, [isIframeSubdomain, token, hotel_id, language]);

  useEffect(() => {
    if (!code || !state) return;

    if (state === 'apaleo') {
      return navigate(`/sso/init/apaleo/${code}`);
    }

    const stateUrl = new URL(state ?? '');
    const init = stateUrl.searchParams.get('init');
    const hotelId = extractHotelIDFromURL(stateUrl.pathname ?? '');
    const isConnect = state?.includes('/client/account/');

    if (isConnect) {
      return navigate(`/sso/connect/${hotelId}/${init}/${code}?state=${state}`);
    }

    return navigate(`/sso/init/${init}/${code}`);
  }, [code, state]);

  if (isAuthenticated && user) {
    // If user is staff or sub_account_type === 2 (which is a partner user), redirect to admin page
    return user.is_staff || user.sub_account_type === 2 ? (
      <Navigate to="/admin" replace />
    ) : (
      <Navigate to="/client" replace />
    );
  }

  return <Navigate to="/login" replace />;
};
