import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';

export const useMinStayValue = () => {
  const { roomPrices } = useRoomPrices();
  const { pricingSettings } = usePricingSettings();
  const { pmsMinStayConfig } = usePmsProvider();

  const getMinStayValue = (dateKey: string, roomId: number) => {
    if (!roomPrices || !roomPrices.prices.data?.[dateKey]) return;

    let originalMinStay = roomPrices.prices.data?.[dateKey]?.[roomId]?.original_min_stay;
    let suggestedMinStay = roomPrices.prices.data?.[dateKey]?.[roomId]?.suggested_min_stay;
    let overrideMinStay = pricingSettings?.dates?.[dateKey]?.[roomId]?.min_stay;
    const hasOverrideMinStay = pricingSettings?.dates?.[dateKey]?.[roomId]?.min_stay !== undefined;

    if (pmsMinStayConfig) {
      if (pmsMinStayConfig.min_stay_remove_value >= originalMinStay) {
        originalMinStay = 0;
      }

      if (pmsMinStayConfig.min_stay_remove_value >= suggestedMinStay) {
        suggestedMinStay = 0;
      }

      if (overrideMinStay && pmsMinStayConfig.min_stay_remove_value >= overrideMinStay) {
        overrideMinStay = 0;
      }
    }

    const shouldUseSuggestedMinStay =
      typeof overrideMinStay === 'number'
        ? suggestedMinStay < overrideMinStay
        : suggestedMinStay < originalMinStay;

    const minStayValueToUse = shouldUseSuggestedMinStay
      ? suggestedMinStay
      : overrideMinStay ?? originalMinStay;

    const isSkipMinStayRule = pricingSettings?.dates?.[dateKey]?.[roomId]?.is_skip_min_stay_rule;

    return {
      minStayValueToUse,
      originalMinStay,
      suggestedMinStay,
      overrideMinStay,
      hasOverrideMinStay,
      shouldUseSuggestedMinStay,
      isSkipMinStayRule
    };
  };

  return {
    getMinStayValue
  };
};
