import { getDefaultAfter365DailyAverages } from '@common/api/pricingAlgorithm';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useDefaultOneYearAverages = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [UtilQueryKeys.GET_DEFAULT_AFTER_365_DAILY_AVERAGES],
    queryFn: () => {
      return getDefaultAfter365DailyAverages();
    }
  });

  return {
    defaultAfter365DailyAverages: data,
    isLoading,
    error
  };
};
