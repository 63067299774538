import { ADJUSTMENT_DB } from '@pages/Client/Calendar/components/BulkEdit/types/adjustments';
import { Draft } from 'immer';
import { Engine, Rule } from 'json-rules-engine';
import { PricingSettings } from '@common/api/hotel/types';
import { isEmpty, map } from 'lodash-es';
import { ClosedSpaces } from '@pages/Client/Calendar/components/BulkEdit/types/schema/closedSpacesSchema';

/**
 * Rules for setting and deleting the closed spaces key:
 *
 * Bulk edit only:
 * - Set Closed Spaces Key:
 *   - Condition:
 *     - The bulk edit mode is enabled (isBulkEdit is true)
 *     - The value is greater than 0
 *   - Action:
 *     - Set the closed spaces key (as specified by the `setClosedSpacesKey` event)
 *
 * - Delete Closed Spaces Key:
 *   - Condition:
 *     - The bulk edit mode is enabled (isBulkEdit is true)
 *     - The value is equal to 0
 *   - Action:
 *     - Delete the closed spaces key (as specified by the `deleteClosedSpaces` event)
 */

const actions = {
  setClosedSpacesKey: 'setClosedSpacesKey',
  deleteClosedSpaces: 'deleteClosedSpaces'
};

const deleteClosedSpaces = new Rule({
  conditions: {
    all: [
      { fact: 'isBulkEdit', operator: 'equal', value: true },
      { fact: 'value', operator: 'equal', value: 0 }
    ]
  },
  event: { type: actions.deleteClosedSpaces }
});

const setClosedSpacesKey = new Rule({
  conditions: {
    all: [
      { fact: 'isBulkEdit', operator: 'equal', value: true },
      { fact: 'value', operator: 'greaterThan', value: 0 }
    ]
  },
  event: { type: actions.setClosedSpacesKey }
});

export async function transformClosedSpaces(
  currentPricingDraft: Draft<PricingSettings>, // this is an immer draft
  formDataById: Record<string, ClosedSpaces>,
  date: string
) {
  if (isEmpty(formDataById)) return;
  const engine = new Engine();
  engine.addRule(deleteClosedSpaces);
  engine.addRule(setClosedSpacesKey);

  engine.on('success', (event, almanac) => {
    (almanac.factValue('id') as Promise<string>).then((id: string) => {
      if (event.type === actions.setClosedSpacesKey) {
        if (!currentPricingDraft.dates[date]) currentPricingDraft.dates[date] = {};
        if (!currentPricingDraft.dates[date][id]) currentPricingDraft.dates[date][id] = {};
        currentPricingDraft.dates[date][id][ADJUSTMENT_DB.CLOSED_KEY] = formDataById[id]
          .value as number;
      }
      if (event.type === actions.deleteClosedSpaces) {
        delete currentPricingDraft.dates[date]?.[id]?.[ADJUSTMENT_DB.CLOSED_KEY];
      }
    });
  });

  await Promise.all(
    map(formDataById, async (item: ClosedSpaces) => {
      await engine.run(item);
    })
  );

  return;
}
