import { useState } from 'react';
import {
  Button,
  Combobox,
  useCombobox,
  Group,
  ScrollArea,
  AccordionChevron,
  Text,
  Box
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

type Props = {
  data: string[];
  label?: string;
  defaultValue?: string;
  searchPlaceholder?: string;
  searchable?: boolean;
};

export const ButtonDropdown: React.FC<Props> = ({
  data,
  label,
  searchPlaceholder,
  searchable,
  defaultValue
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState<string | null>(defaultValue || null);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    }
  });

  const options = data
    .filter((item) => item.toLowerCase().includes(search.toLowerCase().trim()))
    .map((item) => (
      <Combobox.Option
        value={item}
        key={item}
        active={item === selectedItem}
        bg={item === selectedItem ? 'indigo' : undefined}
        c={item === selectedItem ? 'white' : undefined}>
        <Group gap="xs">
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Box>
      {label ? (
        <Text component="label" size="sm" lh="md" pl="sm">
          {label}
        </Text>
      ) : null}
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
          setSelectedItem(val);
          combobox.closeDropdown();
        }}>
        <Combobox.Target withAriaAttributes={false}>
          <Button
            fullWidth
            justify="space-between"
            variant="white"
            c="dark"
            rightSection={<AccordionChevron />}
            onClick={() => combobox.toggleDropdown()}>
            {selectedItem || t('Choose an option')}
          </Button>
        </Combobox.Target>

        <Combobox.Dropdown>
          {searchable ? (
            <Combobox.Search
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder={searchPlaceholder || `${t('Search')}`}
            />
          ) : null}
          <Combobox.Options>
            <ScrollArea.Autosize mah={200} type="scroll">
              {!isEmpty(options) ? (
                options
              ) : (
                <Combobox.Empty>{t('No results found')}</Combobox.Empty>
              )}
            </ScrollArea.Autosize>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Box>
  );
};
