import { getWeatherInfo } from '@common/api/pricingAlgorithm/weather';
import { API_DATE_FORMAT } from '@common/constants/date';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';

export const useWeather = () => {
  const query = useQuery({
    queryKey: [UtilQueryKeys.GET_WEATHER_INFO],
    queryFn: getWeatherInfo
  });
  const weatherInfo = useMemo(() => query.data?.data, [query.data]);

  const getWeatherForDate = (date: Date | Dayjs) => {
    const key = dayjs(date).tz().format(API_DATE_FORMAT);
    return weatherInfo?.find((item) => item.date === key);
  };

  return {
    query,
    weatherInfo,
    getWeatherForDate
  };
};
