import { changePassword } from '@common/api/account';
import { ChangePasswordPayload } from '@common/api/account/types';
import { setAuthToken } from '@common/api/axiosInstance';
import { useAuthStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useErrorChangePasswordStore } from '@pages/Client/ChangePassword/store/password';
import { useMutation } from '@tanstack/react-query';

export const useAdminChangePassword = () => {
  const { addNotification } = useNotificationsStore();
  const { token } = useAuthStore();
  const { setError, setMessage } = useErrorChangePasswordStore();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: ChangePasswordPayload) => {
      setAuthToken(token);
      return changePassword(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully change password');
    },
    onError: (error: any) => {
      if (error.response.data.password) {
        setError(true);
        setMessage(error.response.data.password.join(','));
      }
      addNotification('fail', 'Failed to change password');
    }
  });

  return {
    changePassword: mutate,
    isLoading
  };
};
