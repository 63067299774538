import { Icon } from '@common/components/foundations/icons';
import { Stack, Tabs, Badge, Title } from '@mantine/core';

export const Navigation = () => {
  return (
    <Stack py="xs">
      <Stack>
        <Title order={2} size="h3">
          Tabs
        </Title>
        <Tabs defaultValue="first">
          <Tabs.List>
            <Tabs.Tab value="first" rightSection={<Badge color="gold">PRO</Badge>}>
              First tab
            </Tabs.Tab>
            <Tabs.Tab value="second" leftSection={<Icon.Flash />}>
              Second tab
            </Tabs.Tab>
            <Tabs.Tab value="third">Third tab</Tabs.Tab>
          </Tabs.List>
        </Tabs>
      </Stack>
    </Stack>
  );
};
