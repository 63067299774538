export const DownloadJson = (data: any, filename: string, hotelId: number | undefined) => {
  const date = new Date();
  const formattedDate = date.toISOString().replace(/:/g, '_');
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = `${filename}-hotelId(${hotelId})-${formattedDate}.json`;

  link.click();
};
