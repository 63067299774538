import { Badge } from '@common/components/atoms/Badge';

import { Typography } from '@common/components/foundations/Typography';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { useDocumentTitle } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import { useBookingScrapeList } from '@pages/Admin/Scrape/NewHotel/hooks/useBookingScrapeList';
import { AddBookingUrlModal } from '@pages/Client/PricingStrategy/MarketIntelligence/pages/CompetitorList/components/AddBookingUrlModal';
import { Button } from '@mantine/core';

export const NewHotelScraper = () => {
  useDocumentTitle('New Hotel Scraper');
  const [pageParams, setPageParams] = React.useState<number>(1);
  const { isOpen: scrapeOpen, openModal: scrapeClick, closeModal: scrapeClose } = useModal();

  const { bookingScrapeList, isLoading } = useBookingScrapeList(pageParams);

  const columns: any[] = [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        cellAlignment: 'right',
        showOnMobile: true
      }
    },
    {
      header: 'Date',
      accessorKey: 'created',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() ? dayjs(row?.getValue() as string).format('YYYY-MM-DD HH:mm') : '-'}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'URL',
      accessorKey: 'url',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Status',
      accessorKey: 'scrape_status',
      cell: (row: any) => {
        const statusMapping: Record<number, string> = {
          1: 'Scheduled',
          2: 'In Progress',
          3: 'Done',
          4: 'Failed'
        };
        const statusBgMapping: Record<number, string> = {
          1: 'darkGrey',
          2: 'orange',
          3: 'darkGreen',
          4: 'error'
        };
        return (
          <div className="flex items-center gap-3">
            <Badge className="text-center" background={statusBgMapping[row?.getValue() as number]}>
              {statusMapping[row?.getValue() as number]}
            </Badge>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const ROWS_PER_PAGE = 20;

  const startIndex = bookingScrapeList?.current
    ? (bookingScrapeList.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    bookingScrapeList?.current && bookingScrapeList.results
      ? startIndex + bookingScrapeList.results.length - 1
      : 0;

  const handleNext = () => {
    if (!bookingScrapeList?.next) return;
    const params = new URLSearchParams(bookingScrapeList.next.split('?')[1]);
    const page = params.get('page');

    if (!page) return;
    setPageParams(Number(page));
  };

  const handlePrevious = () => {
    if (bookingScrapeList?.current) {
      setPageParams(bookingScrapeList.current - 1);
    }
  };
  return (
    <Page header={<Header title="New Hotel" />}>
      <Table
        isHover
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading}
        headerComponent={<Fragment />}
        actionComponents={<Button onClick={scrapeClick}>Scrape New Hotel</Button>}
        data={!isLoading ? (bookingScrapeList?.results as any) : []}
        next={handleNext}
        isNext={!!bookingScrapeList?.next}
        previous={handlePrevious}
        isPrevious={pageParams > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={bookingScrapeList?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
      />
      <AddBookingUrlModal isOpen={scrapeOpen} onClose={scrapeClose} />
    </Page>
  );
};
