import { ErrorResponse } from '@common/api/auth';
import { setAuthToken } from '@common/api/axiosInstance';
import { getAlgoProgramExecute, getPricingHistory } from '@common/api/pricingAlgorithm';
import {
  AlgoProgramExecuteLogsPagination,
  PricingHistory
} from '@common/api/pricingAlgorithm/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { HotelQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

type Params = {
  page: number;
  start_date?: string;
  end_date?: string;
};

export const useProgramExecute = ({ page = 1, start_date, end_date }: Params) => {
  const { addNotification } = useNotificationsStore();

  const query = useQuery<AlgoProgramExecuteLogsPagination, ErrorResponse>({
    queryKey: [HotelQueryKeys.ALGO_PROGRAM_EXECUTE, page],
    queryFn: () => {
      if (start_date && end_date) {
        return getAlgoProgramExecute(page, start_date, end_date);
      }
      return getAlgoProgramExecute(page);
    },
    retry: !!start_date && !!end_date
  });

  useEffect(() => {
    if (query.isError) {
      addNotification('fail', 'Failed to get program execute');
    }
  }, [query.isError]);

  const algoProgramExecute = useMemo(() => {
    return query.data;
  }, [query.data]);

  const refetchLogs = async () => {
    await query.refetch();
    addNotification('success', 'Reservation API logs have been updated');
  };

  const isRefetchLoading = query.isLoading || query.isFetching;

  return { algoProgramExecute, query, refetchLogs, isRefetchLoading };
};

export const useDownloadPricingHistory = () => {
  const { addNotification } = useNotificationsStore();
  const { hotelAuthToken } = useHotelStore();
  const [additionalFileName, setAdditionalFileName] = useState({
    name: '',
    date: ''
  });

  const formatDateAndTime = (dateTimeString: string) => {
    const [date, time] = dateTimeString.split(' ');
    const formattedDate = date.split('/').join('-');
    const formattedTime = time.replace(':', '_');
    return `${formattedDate}_${formattedTime}`;
  };

  const downloadPricingHistory = (response: any) => {
    const data = JSON.stringify(response);
    const blob = new Blob([data], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Pricing-History-(${additionalFileName.name})-${additionalFileName.date}.txt`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: PricingHistory) => {
      setAdditionalFileName({
        name: data.name,
        date: formatDateAndTime(data.date)
      });
      setAuthToken(hotelAuthToken);
      return getPricingHistory(data);
    },
    onSuccess: (response) => {
      downloadPricingHistory(response);
    },
    onError: () => {
      addNotification('fail', 'Failed to download pricing history');
    }
  });

  return {
    getPricingHistory: mutate,
    isLoading
  };
};
