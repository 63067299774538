import { create } from 'zustand';
interface ErrorPasswordStore {
  isError: boolean;
  setError: (newError: boolean) => void;
  message: string;
  setMessage: (message: string) => void;
}

export const useErrorInviteUserPasswordStore = create<ErrorPasswordStore>((set) => ({
  isError: false,
  setError: (newError: boolean) => set({ isError: newError }),
  message: '',
  setMessage: (newMessage: string) => set({ message: newMessage })
}));
