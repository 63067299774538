export function ChartBarThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H17C16.4477 20 16 19.5523 16 19V5C16 4.44772 16.4477 4 17 4H19ZM14 19V9C14 8.44772 13.5523 8 13 8H11C10.4477 8 10 8.44772 10 9V19C10 19.5523 10.4477 20 11 20H13C13.5523 20 14 19.5523 14 19ZM7 20H5C4.44772 20 4 19.5523 4 19V13C4 12.4477 4.44772 12 5 12H7C7.55228 12 8 12.4477 8 13V19C8 19.5523 7.55228 20 7 20Z"
      />
    </svg>
  );
}
