import { updatePartner } from '@common/api/account';
import { useNotificationsStore } from '@common/store/notifications';
import { PartnerQueryKeys } from '@common/types/query-keys';
import { SelectedPartner } from '@pages/Admin/PartnerUsers/types/selectedPartner';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface UpdatePartner {
  payload: SelectedPartner;
  id: number;
}

export const useUpdatePartner = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (data: UpdatePartner) => {
      return updatePartner(data.payload, data.id);
    },
    onSuccess: () => {
      addNotification('success', 'Partner update successfully');
      queryClient.invalidateQueries({ queryKey: [PartnerQueryKeys.GET_PARTNER_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to update partner');
    }
  });

  return {
    updatePartner: mutate,
    isLoading,
    isSuccess
  };
};
