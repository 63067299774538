import {
  Checkbox,
  Divider,
  Group,
  MultiSelect,
  MultiSelectProps,
  TextInput,
  Skeleton,
  Stack,
  Title,
  SimpleGrid,
  rem
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { useDefaultSurgeConfig } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useDefaultSurgeConfig';
import { useGetHotelUsers } from '@pages/Client/hooks/useGetHotelUsers';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { ChangeEvent, Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSurgeProtectionStore } from '@pages/Client/PricingStrategy/SurgeProtection/store/surgeProtection';
import { useFormSchema } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useFormSchema';
import { z } from 'zod';
import { SurgeSettingsText } from '@pages/Client/PricingStrategy/SurgeProtection/components/SurgeSettingsText';

// TODO: Add auto price protection back, when it's ready
export const SurgeProtectionSettings = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Surge Protection Settings'));

  const { schema } = useFormSchema();
  const { setValue, control } = useFormContext<z.infer<typeof schema>>();

  const { isDontSendEmail, setIsDontSendEmail } = useSurgeProtectionStore();

  const {
    hotelUsers,
    query: { isLoading: isGetHotelUsersLoading }
  } = useGetHotelUsers();

  const USER_OPTIONS = hotelUsers?.map((user) => ({
    value: user.id.toString(),
    label: `${user.first_name} ${user.last_name} (${user.email})`
  }));

  const {
    pricingSettings,
    pricingSettingsQuery: { isLoading: isPricingSettingsLoading }
  } = usePricingSettings();

  const { defaultConfig, isLoading: isDefaultConfigLoading } = useDefaultSurgeConfig();

  useEffect(() => {
    setIsDontSendEmail(pricingSettings?.surge_protection?.user_recipients?.length === 0);
  }, [pricingSettings?.surge_protection?.user_recipients]);

  const handleEmailOnlyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsDontSendEmail(isChecked);
    if (isChecked) {
      setValue('user_recipients', []);
    } else {
      setValue('user_recipients', [defaultConfig?.user_recipients[0] as number]);
    }
  };

  const renderMultiSelectOption: MultiSelectProps['renderOption'] = ({ option, checked }) => (
    <Group gap="sm" wrap="nowrap">
      <Checkbox
        disabled={option.disabled}
        checked={checked}
        onChange={() => {}}
        aria-hidden
        tabIndex={-1}
        style={{ pointerEvents: 'none' }}
      />
      <span>{option.label}</span>
    </Group>
  );

  return (
    <Fragment>
      <SimpleGrid
        cols={{
          xs: 1,
          md: 2
        }}
        spacing="xl">
        <Stack>
          <Title fz={rem(24)} fw="normal">
            {t('Surge Settings')}
          </Title>
          <SurgeSettingsText />
        </Stack>
        <Stack gap="lg">
          <Skeleton visible={isGetHotelUsersLoading}>
            <Controller
              name="user_recipients"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <MultiSelect
                  label={t('User Recipients') as string}
                  renderOption={renderMultiSelectOption}
                  disabled={isDontSendEmail}
                  placeholder={`${t('Select User Recipients')}`}
                  value={value?.map((val) => val.toString())}
                  onChange={(selectedStringValues) => {
                    const numericValues = selectedStringValues.map((str) => parseInt(str, 10));
                    onChange(numericValues);
                  }}
                  data={USER_OPTIONS ?? []}
                  error={error?.message}
                  w="100%"
                />
              )}
            />
          </Skeleton>
          <Skeleton visible={isGetHotelUsersLoading}>
            <Checkbox
              label={t("Don't send email")}
              id="dont-send-email"
              name="dont-send-email"
              checked={isDontSendEmail}
              onChange={handleEmailOnlyChange}
            />
          </Skeleton>

          <Divider />

          <Skeleton visible={isPricingSettingsLoading || isDefaultConfigLoading}>
            <Controller
              name="threshold"
              control={control}
              render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter number of bookings')}`}
                  error={error?.message}
                  label={t('Number of bookings to trigger surge protection') as string}
                  name={name}
                  id={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  inputMode="numeric"
                />
              )}
            />
          </Skeleton>

          <Skeleton visible={isPricingSettingsLoading || isDefaultConfigLoading}>
            <Controller
              name="days_ahead"
              control={control}
              render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={`${t('Please enter number of days')}`}
                  error={error?.message}
                  label={t('Only check after this many days to go') as string}
                  name={name}
                  id={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  inputMode="numeric"
                />
              )}
            />
          </Skeleton>
        </Stack>
      </SimpleGrid>
    </Fragment>
  );
};
