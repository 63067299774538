import { useEffect, useState } from 'react';
import { Typography } from '@common/components/foundations/Typography';
import { Table } from '@common/components/molecules/Table';
import { useModal } from '@common/hooks/useModal';
import { cn } from '@common/utils/cn';
import { useInternalRoomMapping, useUpdateInternalRoomMapping } from '@pages/Client/Account/Integrations/hooks/useNewbookRoomMapping';
import { UpdateNewbookRoomMappingModal } from '@pages/Client/Account/Integrations/components/UpdateNewbookRoomMappingModal';
import { DeleteNewbookRoomMappingModal } from '@pages/Client/Account/Integrations/components/DeleteNewbookRoomMappingModal';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Icon } from '@common/components/foundations/icons';

interface SelectedRoom {
  name: '',
  code: '',
}

export const NewbookRoomMappingTable = () => {
  const { t } = useTranslation();
  const { data: rawData, isLoading } = useInternalRoomMapping();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { mutate: updateRoomMapping, isPending } = useUpdateInternalRoomMapping({
    onSuccess: () => {
      editClose();
      deleteClose();
    },
  });

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  }, [deleteOpen]);

  const [selectedRoom, setSelectedRoom] = useState<SelectedRoom>({
    name: '',
    code: '',
  });
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('edit');

  const data = rawData
    ? Object.entries(rawData).map(([key, value]) => ({
        name: key,
        code: value,
      }))
    : [];

  const columns = [
    {
      header: 'Room Name',
      accessorKey: 'name',
      cell: (row: any) => (
        <div className="flex items-center gap-3">
          <Typography element="span" color="copyTextGrey">
            {row?.getValue() as string}
          </Typography>
        </div>
      ),
    },
    {
      header: 'Room Code',
      accessorKey: 'code',
      cell: (row: any) => (
        <div className="flex items-center gap-3">
          <Typography element="span" color="copyTextGrey">
            {row?.getValue() as string}
          </Typography>
        </div>
      ),
    },
    {
      header: 'Actions',
      size: 50,
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-3">
              <Button
                variant='subtle'
                onClick={() => {
                  setSelectedRoom(row.row.original);
                  deleteClick();
                }}>
                <Icon.Delete className="text-copyTextGrey" />
              </Button>
            </div>
          </div>
        );
      }
    }
  ];

  const handleSave = (updatedRoom: { name: string; code: string }) => {
    const updatedMapping = {
      ...rawData,
      [updatedRoom.name]: updatedRoom.code,
    };
    updateRoomMapping(updatedMapping);
  };

  const handleDelete = () => {
    if (!selectedRoom.name) return;

    // Remove the selected room from the mapping
    const updatedMapping = { ...rawData };
    delete updatedMapping[selectedRoom.name];

    updateRoomMapping(updatedMapping);
  };

  return (
    <div className={cn('my-8')}>
      <Table
        isFetching={isLoading}
        onClickRow={(row: any) => {
          setSelectedRoom(row?.row.original);
          setModalMode('edit');
          editClick();
        }}
        headerComponent={
          <div className="my-3 flex items-center justify-between">
            <Typography variant="h6" element="h6" color="darkGrey">
              Room Mapping
            </Typography>
          </div>
        }
        actionComponents={
          <div className="flex items-center gap-3">
            <Button
              onClick={() => {
                setSelectedRoom({ name: '', code: '' });
                setModalMode('add');
                editClick();
              }}
            >
              {t('Add new Room Mapping')}
            </Button>
          </div>
        }
        skeletonCount={10}
        columns={columns}
        data={data}
        isHover
        isRowClickable
      />
      <UpdateNewbookRoomMappingModal
        mode={modalMode}
        selectedRoom={selectedRoom}
        onSave={handleSave}
        isLoading={isPending}
        isOpen={editOpen}
        onClose={editClose}
      />
      <DeleteNewbookRoomMappingModal
        selectedRoom={selectedRoom}
        onDelete={handleDelete}
        isLoading={isPending}
        isOpen={deleteOpen}
        onClose={deleteClose}
      />
    </div>
  );
};
