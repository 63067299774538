import React from 'react';
interface DateCellContentProps {
  content: {
    priceText?: React.ReactNode;
    compareText?: React.ReactNode;
    occupancyPercentage?: React.ReactNode;
  };
}

export const DateCellContent = ({ content }: DateCellContentProps) => {
  return (
    <div className="flex items-end justify-between text-meta-2">
      <span>{content.priceText}</span>

      <div className="text-right">
        <div>{content.compareText}</div>

        <div className="percent-text -mr-1 rounded-full px-1 py-0.5 text-calendar font-medium">
          {content.occupancyPercentage}
        </div>
      </div>
    </div>
  );
};
