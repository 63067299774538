export function DownloadFromCloud(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9995 4C14.9795 4 17.4455 6.174 17.9125 9.021C20.7565 9.233 22.9995 11.602 22.9995 14.5C22.9995 17.538 20.5365 20 17.4995 20H5.99951C3.23851 20 0.999512 17.761 0.999512 15C0.999512 12.239 3.23851 10 5.99951 10C5.99951 6.686 8.68651 4 11.9995 4ZM12.3535 16.852L15.8885 13.316C16.0835 13.121 16.0835 12.804 15.8885 12.609L15.6815 12.402C15.5875 12.308 15.4605 12.255 15.3275 12.255H13.9995V8.5C13.9995 8.224 13.7765 8 13.4995 8H10.4995C10.2235 8 9.99951 8.224 9.99951 8.5V12.255H8.67151C8.53851 12.255 8.41151 12.308 8.31851 12.402L8.11151 12.609C7.91551 12.804 7.91551 13.121 8.11151 13.316L11.6465 16.852C11.8445 17.049 12.1555 17.049 12.3535 16.852Z"
      />
    </svg>
  );
}
