import { getPriceDevelopmentData } from '@common/api/roomPrices';
import { useNotificationsStore } from '@common/store/notifications';
import { PricingQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePriceDevelopment = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationsStore();

  const priceDevelopmentQuery = useQuery({
    queryKey: [PricingQueryKeys.GET_PRICE_DEVELOPMENT],
    queryFn: getPriceDevelopmentData
  });

  if (priceDevelopmentQuery.isError) {
    addNotification('fail', t('Failed to fetch price development data'));
  }

  const priceDevelopment = useMemo(() => {
    return priceDevelopmentQuery.data?.data;
  }, [priceDevelopmentQuery.data]);

  return {
    priceDevelopmentQuery,
    priceDevelopment
  };
};
