import { useState, useEffect, useMemo } from 'react';

const deepCompare = (obj1: any, obj2: any): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

/**
 * `useDeepCompareMemo` is a custom hook that memoizes a value, similar to `useMemo`,
 * but performs a deep comparison of the dependencies.
 *
 * @template T The type of the value to be memoized.
 *
 * @param {() => T} callback - The function that computes the memoized value.
 * @param {any[]} dependencies - An array of dependencies which, when changed, will recompute the memoized value.
 * @param {boolean} [debug=false] - An optional flag to enable debug mode, which will console.log any differences found in the dependencies. Only DEV
 *
 * @returns {T} The memoized value.
 *
 * @example
 * const data = useDeepCompareMemo(() => computeExpensiveValue(a, b), [a, b], true);
 */

export const useDeepCompareMemo = <T,>(
  callback: () => T,
  dependencies: any[],
  debug = false
): T => {
  const [memoDeps, setMemoDeps] = useState(dependencies);

  useEffect(() => {
    if (!deepCompare(memoDeps, dependencies)) {
      if (import.meta.env.DEV && debug) console.log('Differences found:', memoDeps, dependencies);
      setMemoDeps(dependencies);
    }
  }, [dependencies]);

  return useMemo(callback, [memoDeps]);
};
