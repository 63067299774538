import { useSearchParams } from 'react-router-dom';
import { InvitationUserDataPayload } from '@common/api/account/types';
import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { Icon } from '@common/components/foundations/icons';
import { Genie } from '@common/components/foundations/Logo';
import { Typography } from '@common/components/foundations/Typography';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { useGetDataInvitation } from '@pages/Auth/InviteUser/hooks/useDataInvitationUser';
import {
  numberRegex,
  PasswordValidationMessage,
  specialCharRegex
} from '@common/constants/password';
import { useInviteUserByInvitation } from '@pages/Auth/InviteUser/hooks/useInviteUserByInvitation';
import { useErrorInviteUserPasswordStore } from '@pages/Auth/InviteUser/store/password';

const schema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address.' }),
  password: z
    .string()
    .min(8, PasswordValidationMessage.Length)
    .max(50)
    .refine((value) => /[A-Z]/.test(value), {
      message: PasswordValidationMessage.UppercaseLetter,
      path: ['password']
    })
    .refine((value) => numberRegex.test(value), {
      message: PasswordValidationMessage.Number,
      path: ['password']
    })
    .refine((value) => specialCharRegex.test(value), {
      message: PasswordValidationMessage.SpecialChar,
      path: ['password']
    }),
  first_name: z.string(),
  last_name: z.string()
});

export function InviteUser() {
  const [searchParams] = useSearchParams();
  const emailToken = searchParams.get('email_token');
  const { setError, isError, message: errorPasswordMessage } = useErrorInviteUserPasswordStore();

  const { createUserByInvitation, isLoading: isCreateLoading } = useInviteUserByInvitation();

  const {
    userDataInvitation,
    query: { isLoading: isGetDataInvitationLoading }
  } = useGetDataInvitation({
    email_token: emailToken || ''
  });

  const {
    handleSubmit,
    control,
    getFieldState,
    watch,
    setValue,
    formState: { errors }
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const navigate = useNavigate();
  const { email, password, first_name, last_name } = watch();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!userDataInvitation) return;
    if (userDataInvitation?.email) {
      setValue('email', userDataInvitation?.email);
    }
  }, [userDataInvitation]);

  const handleCreateUser = () => {
    const dataUser: InvitationUserDataPayload = {
      email,
      password,
      first_name,
      last_name
    };
    createUserByInvitation([dataUser, emailToken || '']);
  };

  const joinedProperties: string = Object.entries(userDataInvitation?.property_list || {})
    .map(([_key, value]) => `${value}`)
    .join(', ');

  const getRefineErrors = () => {
    if ((errors.password as any)?.password) {
      return (errors.password as any).password.message;
    }
    return '';
  };

  useEffect(() => {
    if (isError && !watch('password')) {
      setError(false);
    }
  }, [isError, watch('password')]);

  return (
    <div className="flex w-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:flex-none lg:px-20 xl:px-24">
      <div className=" mx-auto w-full rounded-md bg-white p-8 2xl:w-3/4">
        <div>
          <div className="mb-4 flex items-center gap-x-2 lg:hidden">
            <div className="h-auto w-10">
              <Genie className="fill-indigo" />
            </div>
          </div>
          <Typography element="h2" variant="h4" className="mb-2 font-medium" color="darkGrey">
            Welcome!
          </Typography>
          <Typography element="p" variant="paragraph-3" color="grey">
            You got invited to join RoomPriceGenie with access to the following properties:
            <br />
            {isGetDataInvitationLoading ? (
              <Skeleton className="h-4" />
            ) : (
              <span className="font-semibold text-darkGrey">{joinedProperties}</span>
            )}
          </Typography>
        </div>

        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit(handleCreateUser)}>
              <div className="space-y-6">
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="first_name"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={isCreateLoading || isGetDataInvitationLoading}
                        type="text"
                        background="grey"
                        label="First Name*"
                        placeholder="Please enter first name"
                        value={value || ''}
                        error={!!error}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="last_name"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={isCreateLoading || isGetDataInvitationLoading}
                        type="text"
                        background="grey"
                        label="Last Name*"
                        placeholder="Please enter last name"
                        value={value || ''}
                        error={!!error}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                        showClearButton={false}
                      />
                    )}
                  />
                </div>
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        type="email"
                        background="grey"
                        disabled
                        label="Email*"
                        placeholder="Email"
                        value={value || ''}
                        error={!!error}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                        showClearButton={false}
                      />
                    )}
                  />
                </div>

                <div className="space-y-1">
                  <Controller
                    control={control}
                    name="password"
                    rules={{ required: true }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        disabled={isCreateLoading || isGetDataInvitationLoading}
                        tabIndex={2}
                        label="Password*"
                        background="grey"
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={value || ''}
                        trailingAddon={
                          <button type="button" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                              <Icon.Visible className="h-5 w-5" />
                            ) : (
                              <Icon.Hidden className="h-5 w-5" />
                            )}
                          </button>
                        }
                        error={!!error || isError}
                        helperMessage={
                          isError ? (
                            <div className="flex items-center gap-2 text-error">
                              <Icon.WarningOutline className="w-4" />
                              <Typography element="span" color="error" variant="meta-2">
                                {errorPasswordMessage}
                              </Typography>
                            </div>
                          ) : errors ? (
                            <div className="flex items-center gap-2 text-error">
                              {errors.password ? <Icon.WarningOutline className="w-4" /> : ''}
                              <Typography element="span" color="error" variant="meta-2">
                                {errors.password ? errors?.password?.message?.toString() : ''}
                                {(errors?.password as any)?.password &&
                                watch('password').length >= 8
                                  ? getRefineErrors()
                                  : ''}
                              </Typography>
                            </div>
                          ) : null
                        }
                        onChange={(e) => {
                          onChange(e);
                        }}
                        showClearButton={false}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="mt-8 flex flex-col space-y-4">
                <Button
                  intent="primary"
                  size="large"
                  type="submit"
                  disabled={
                    getFieldState('email').invalid || !emailToken || isGetDataInvitationLoading
                  }>
                  {isCreateLoading ? 'Loading...' : 'Save and go to login'}
                </Button>
                <Button
                  type="button"
                  intent="outline"
                  size="large"
                  onClick={() => navigate('/login')}>
                  Go back to login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
