import { Switcher } from '@common/components/atoms/Switcher';
import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { PricingScheduleLogs } from '@pages/Client/PricingSchedule/components/PricingScheduleLogs';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useEnablePricing } from '@pages/Client/hooks/usePricingEnable';

export const PricingSchedule = () => {
  const { enablePricing, isLoading: isEnablePricingLoading } = useEnablePricing();
  const {
    query: { isLoading },
    hotelDetails
  } = useHotelDetails();

  const isPricingEnabled = hotelDetails?.is_pricing_turn_on;

  return (
    <Page
      header={
        <Header
          title="Pricing Logs"
          actions={
            <div className="flex gap-2">
              <div className="flex w-full justify-start py-3 md:justify-end">
                <div className="flex items-end gap-3 md:justify-end">
                  <Switcher
                    label={`${isEnablePricingLoading ? 'Updating...' : 'Pricing'}`}
                    disabled={isLoading}
                    size="large"
                    checked={isPricingEnabled}
                    onChange={() => enablePricing(!isPricingEnabled)}
                  />
                </div>
              </div>
            </div>
          }
        />
      }>
      <div>
        <PricingScheduleLogs />
      </div>
    </Page>
  );
};
