import { getSurgeDefaultConfig } from '@common/api/pricingAlgorithm';
import { DefaultConfigSurgeResponse } from '@common/api/pricingAlgorithm/types';
import { HotelQueryKeys } from '@common/types/query-keys';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useDefaultSurgeConfig = () => {
  const { data, isLoading, error, isFetching } = useQuery<DefaultConfigSurgeResponse>({
    queryKey: [HotelQueryKeys.DEFAULT_CONFIG_SURGE],
    queryFn: () => {
      return getSurgeDefaultConfig();
    },
    retry: false,
    placeholderData: keepPreviousData
  });

  return {
    defaultConfig: data?.data,
    isLoading,
    isFetching,
    error
  };
};
