import { ErrorResponse } from '@common/api/auth';
import { axiosInstance } from '@common/api/axiosInstance';
import { After365DailyAverages } from '@common/api/hotel/types';
import {
  AlgoProgramExecuteLogsPagination,
  DefaultConfigSurgeResponse,
  DiagnosticsPayload,
  DownloadPriceRequest,
  GetInventoryOrRunPriceRequest,
  HealthReportResponse,
  PricingHistory,
  ResetPickupBoostPrice,
  ResetPickupboostpriceSegmentationEffect,
  RunPricingAndFetchInventoryStatusResponse,
  RunPricingFetchInventoryStatus,
  SimulateSurgeEventPayload,
  SurgeLogResponse,
  UploadPriceRequest
} from '@common/api/pricingAlgorithm/types';
import axios, { AxiosError } from 'axios';

import { isEmpty, isNil } from 'lodash-es';

export enum SurgeDismissalReason {
  INDIVIDUAL_DISMISS = 'individualDismiss',
  BULK_DISMISS = 'bulkDismiss'
}

export const getRunPricingFetchInventoryStatus = async () => {
  try {
    const { data } = await axiosInstance.get<RunPricingFetchInventoryStatus>(
      '/pricingalgorithm/run-pricing-and-fetch-inventory-status/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getInventoryPrice = async () => {
  try {
    const { data } = await axiosInstance.get('/pricingalgorithm/inventory-price/');
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getPricingHistory = async (payload: PricingHistory) => {
  try {
    const { data } = await axiosInstance.post(
      '/pricingalgorithm/algo-program/pricing-result/',
      payload
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getAlgoProgramExecute = async (page = 1, start_date?: string, end_date?: string) => {
  try {
    const { data } = await axiosInstance.get<AlgoProgramExecuteLogsPagination>(
      '/pricingalgorithm/algo-program/execute/list/',
      {
        params: {
          page,
          start_date,
          end_date
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getInventoryOrRunPrice = async (request: GetInventoryOrRunPriceRequest) => {
  try {
    const { data } = await axiosInstance.post(
      'pricingalgorithm/get-inventory-or-run-price/',
      request
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }

    throw error as ErrorResponse;
  }
};

export const runPricingAndFetchInventoryStatus = async () => {
  const { data } = await axiosInstance.get<RunPricingAndFetchInventoryStatusResponse>(
    '/pricingalgorithm/run-pricing-and-fetch-inventory-status/'
  );

  return data;
};

export const uploadPrice = async (request: UploadPriceRequest) => {
  return await axiosInstance.post('/pricingalgorithm/upload-price/', request);
};

export const generateDefaultValueSet = async () => {
  return await axiosInstance.post(
    '/pricingalgorithm/default-after-365-daily-averages/?src=settings-page'
  );
};
export const resetPickupboostpriceSegmentationEffect = async (
  payload: ResetPickupboostpriceSegmentationEffect
) => {
  return await axiosInstance.post('/pricingalgorithm/reset-pickupboostprice-segmentation-effect/', {
    new_segmentation_data: payload
  });
};

export const getDefaultAfter365DailyAverages = async () => {
  const { data } = await axiosInstance.get<After365DailyAverages>(
    '/pricingalgorithm/default-after-365-daily-averages/'
  );
  return data;
};

export const downloadPriceCsv = async (request: DownloadPriceRequest) => {
  return await axiosInstance.post('/pricingalgorithm/pms-price-csv/', request);
};

export const resetPickupBoostPrice = async (payload: ResetPickupBoostPrice) => {
  try {
    const { data } = await axiosInstance.post('/pricingalgorithm/reset-pickupboostprice/', payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};
export const resetPickupBoostPriceAndOccupancy = async (payload: ResetPickupBoostPrice) => {
  try {
    const { data } = await axiosInstance.post(
      '/pricingalgorithm/reset-pickupboostprice-bad-pms/',
      payload
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getDiagnosticsCsv = async (payload: DiagnosticsPayload) => {
  try {
    const { data } = await axiosInstance.post('/pricingalgorithm/diagnostic-file/', payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getHealthReport = async (page?: number) => {
  try {
    const { data } = await axiosInstance.get<HealthReportResponse>(
      '/pricingalgorithm/health-check/',
      {
        params: {
          page
        }
      }
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getSurgeLogs = async (
  signal: AbortSignal | undefined,
  page?: number,
  ordering?: string,
  startDate?: string,
  endDate?: string,
  active?: string,
  pageSize?: number
) => {
  try {
    const { data } = await axiosInstance.get<SurgeLogResponse>('/pricingalgorithm/surge-event/', {
      params: {
        page,
        ordering,
        date__gte: startDate,
        date__lte: endDate,
        active,
        'page-size': pageSize
      },
      signal
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getSurgeEvents = async () => {
  try {
    const { data } = await axiosInstance.get<SurgeLogResponse>('/pricingalgorithm/surge-event/', {
      params: {
        active: true,
        'page-size': 1000
      }
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const getSurgeDefaultConfig = async () => {
  try {
    const { data } = await axiosInstance.get<DefaultConfigSurgeResponse>(
      '/pricingalgorithm/surge-protection-config-defaults/'
    );
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error as ErrorResponse;
  }
};

export const deactivateSurgeEventTBDeleted = async (id: number | undefined) => {
  if (!id) {
    return;
  }
  try {
    const { data } = await axiosInstance.patch(`/pricingalgorithm/surge-event/${id}/`, {
      active: false
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error;
  }
};

/**
 * TODO: eventually we need to merge these two calls into one:
 * deactivateSurgeEvent and deactivateSurgeEvents
 */
export const deactivateSurgeEvent = async (id: number | undefined) => {
  if (!id) return;
  try {
    const { data } = await axiosInstance.patch(`/pricingalgorithm/surge-event/bulk-dismiss/`, {
      ids: [id],
      dismissal_reason: SurgeDismissalReason.INDIVIDUAL_DISMISS
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error;
  }
};

export const deactivateSurgeEvents = async ({
  ids,
  dismissReason = SurgeDismissalReason.INDIVIDUAL_DISMISS
}: {
  ids: number[];
  dismissReason?: string;
}) => {
  if (isNil(ids) || isEmpty(ids)) return;
  try {
    const { data } = await axiosInstance.patch(`/pricingalgorithm/surge-event/bulk-dismiss/`, {
      ids,
      dismissal_reason: dismissReason
    });
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error;
  }
};

export const simulateSurgeEvents = async (payload: SimulateSurgeEventPayload) => {
  try {
    const { data } = await axiosInstance.post('/pricingalgorithm/simulate-surge-event/', payload);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error as AxiosError<ErrorResponse>;
    }
    throw error;
  }
};
