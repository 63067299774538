import { getResellerList, getResellerMember, getSalesList } from '@common/api/account';
import { ResellerMember } from '@common/api/account/types';
import { useAuthStore } from '@common/store/auth';
import { ResellerQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

export const useGetResellerList = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [ResellerQueryKeys.GET_RESELLER_LIST],
    queryFn: () => {
      return getResellerList();
    },

    retry: false
  });

  return {
    reseller: data,
    isLoading,
    error
  };
};

export const useGetResellerMember = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [ResellerQueryKeys.GET_RESELLER_MEMBER],
    queryFn: () => {
      return getResellerMember();
    },
    retry: false
  });

  return {
    reseller_member: data as unknown as ResellerMember[],
    isLoading,
    error
  };
};

export const useGetSalesList = () => {
  const { user } = useAuthStore();

  const isPartnerUser = user?.sub_account_type === 2;

  const { data, isLoading, error } = useQuery({
    queryKey: [ResellerQueryKeys.GET_SALES_LIST],
    queryFn: () => {
      if (isPartnerUser) {
        return [];
      } else {
        return getSalesList();
      }
    },
    retry: false
  });

  return {
    sales: data,
    isLoading,
    error
  };
};
