import { Switch, SwitchProps } from '@headlessui/react';
import { cva, VariantProps } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { FieldValues, Path, useController, useFormContext } from 'react-hook-form';
import { cn } from '@common/utils/cn';
import { Typography } from '@common/components/foundations/Typography';

const switcherStyles = cva(
  'relative inline-flex cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-focusNative focus:ring-offset-0',
  {
    variants: {
      size: {
        small: 'h-4 w-[30px]',
        large: 'h-6 w-11'
      },
      checked: {
        true: 'bg-indigo',
        false: 'bg-mediumGrey'
      },
      disabled: {
        true: 'bg-opacity-30 cursor-not-allowed'
      }
    },
    defaultVariants: {
      size: 'small',
      checked: false,
      disabled: false
    }
  }
);

const toggleStyles = cva(
  'pointer-events-none inline-block transform rounded-full bg-white shadow-sm ring-0 transition duration-200 ease-in-out',
  {
    variants: {
      size: {
        small: 'h-3 w-3',
        large: 'h-5 w-5'
      },
      checked: {
        true: 'translate-x-[14px]',
        false: 'translate-x-0'
      },
      withLabel: {
        true: 'translate-x-[14px]',
        false: 'translate-x-0'
      }
    },
    compoundVariants: [
      {
        checked: true,
        size: 'small',
        className: 'translate-x-[14px]'
      },
      {
        checked: false,
        size: 'small',
        className: 'translate-x-0'
      },
      {
        checked: true,
        size: 'large',
        className: 'translate-x-[20px]'
      },
      {
        checked: false,
        size: 'large',
        className: 'translate-x-0'
      }
    ],
    defaultVariants: {
      size: 'small',
      checked: false
    }
  }
);

type SwitcherProps = SwitchProps<'span'> &
  VariantProps<typeof switcherStyles> & {
    uncheckedIcon?: React.ReactNode;
    checkedIcon?: React.ReactNode;
    label?: string;
    disabled?: boolean;
  };

export function Switcher({
  size,
  disabled,
  uncheckedIcon,
  checkedIcon,
  label,
  checked: initialChecked,
  onChange,
  className,
  ...props
}: SwitcherProps) {
  const [checked, setChecked] = useState(() => {
    if (initialChecked !== undefined) {
      return initialChecked;
    }
    return false;
  });

  const handleOnChange = (value: boolean) => {
    setChecked(value);

    if (onChange !== undefined) {
      onChange(value);
    }
  };

  // Sync controlled value with internal value
  useEffect(() => {
    if (initialChecked !== undefined) setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <div className="flex items-center gap-x-2">
      <Switch
        checked={checked}
        className={cn(switcherStyles({ checked, size, disabled }), className)}
        onChange={handleOnChange}
        {...props}>
        <span className={toggleStyles({ checked, size })} aria-hidden="true">
          <span
            className={cn(
              checked ? 'duration-100 ease-out' : 'duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true">
            {checked ? checkedIcon : uncheckedIcon}
          </span>
        </span>
      </Switch>
      {label && (
        <Typography
          className={cn(size === 'small' ? 'text-meta-1-medium' : 'text-paragraph-1-medium')}
          color="copyTextGrey">
          {label}
        </Typography>
      )}
    </div>
  );
}

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function FormInputSwitcher<T extends FieldValues>({
  size,
  disabled,
  uncheckedIcon,
  checkedIcon,
  label,
  className,
  nameKey,
  valueKey
}: SwitcherProps & {
  nameKey: Path<T>;
  valueKey: Path<T>;
}) {
  const { control } = useFormContext();
  const { field } = useController({ name: valueKey, control });

  const checked = disabled ? false : field.value;

  return (
    <div className={cn('flex items-center gap-x-2', disabled ? 'hover:cursor-not-allowed' : '')}>
      <Switch
        id={nameKey}
        name={field.name}
        checked={field.value}
        onChange={(value: boolean) => field.onChange(value)}
        className={cn(switcherStyles({ checked, size, disabled }), className)}>
        <span className={toggleStyles({ checked, size })} aria-hidden="true">
          <span
            className={cn(
              checked ? 'duration-100 ease-out' : 'duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true">
            {checked ? checkedIcon : uncheckedIcon}
          </span>
        </span>
      </Switch>
      {label && (
        <Typography
          className={cn(
            'font-medium',
            size === 'small' ? 'text-[14px] leading-[14px]' : 'text-[16px] leading-[16px]'
          )}
          color="copyTextGrey">
          {label}
        </Typography>
      )}
    </div>
  );
}
