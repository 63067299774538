export const MenuLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 7H3.5C3.22386 7 3 6.77614 3 6.5V5.5C3 5.22386 3.22386 5 3.5 5H20.5C20.7761 5 21 5.22386 21 5.5V6.5C21 6.77614 20.7761 7 20.5 7ZM15 12.5V11.5C15 11.2239 14.7761 11 14.5 11H3.5C3.22386 11 3 11.2239 3 11.5V12.5C3 12.7761 3.22386 13 3.5 13H14.5C14.7761 13 15 12.7761 15 12.5ZM9 17.5V18.5C9 18.7761 8.77614 19 8.5 19H3.5C3.22386 19 3 18.7761 3 18.5V17.5C3 17.2239 3.22386 17 3.5 17H8.5C8.77614 17 9 17.2239 9 17.5Z"
      />
    </svg>
  );
};
