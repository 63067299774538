import { useEffect } from 'react';

export const useAppCuesScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//fast.appcues.com/59586.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
