import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { SelectDropdownInput } from '@common/components/atoms/Select/SelectDropdown';
import { useFormContext } from 'react-hook-form';
import { isNil } from 'lodash-es';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function SelectRoomDropDown() {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();
  const { sortedHotelRooms, selectedHotelRoomId, setSelectedHotelRoomId } = useHotelRoomsList();
  const selectedRoom = getValues('selectedRoom');

  const roomTypeOptions = sortedHotelRooms.map((room) => ({
    label: `${room.name} (${room.is_reference_room ? t('Reference') : t('Derived')})`,
    value: room.id
  }));

  useEffect(() => {
    if (!isNil(selectedHotelRoomId) && isNil(selectedRoom)) {
      setValue('selectedRoom', selectedHotelRoomId);
    }

    if (!isNil(selectedRoom) && selectedHotelRoomId !== selectedRoom) {
      setSelectedHotelRoomId(selectedRoom);
    }
  }, [selectedHotelRoomId, selectedRoom]);

  return (
    <Fragment>
      <SelectDropdownInput<{ label: string; value: number }>
        background="grey"
        fullWidth
        options={roomTypeOptions}
        name="selectedRoom"
      />
    </Fragment>
  );
}
