import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  ReferenceDot,
  Area,
  ComposedChart,
  Legend
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { usePriceDevelopment } from '@pages/Client/Calendar/hooks/usePriceDevelopment';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { API_DATE_FORMAT } from '@common/constants/date';
import { Card } from '@common/components/molecules/Card';
import { Typography } from '@common/components/foundations/Typography';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip as TooltipComponent
} from '@common/components/molecules/Tooltip';
import { LoadingCircle } from '@common/components/foundations/icons/icons/LoadingCircle';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { DISABLED_MAX_MARKET_LEVEL } from '@pages/Client/Calendar/constants';

interface RateEvolutionSectionProps {
  currentViewingDate: Dayjs;
}

interface PriceData {
  day: number;
  price: number | null;
  date: string;
  occupancy: number | null;
  weighted_average_price: number | null;
}

type DataKey = 'price' | 'occupancy' | 'weighted_average_price';

export const RateEvolution: React.FC<RateEvolutionSectionProps> = ({ currentViewingDate }) => {
  const { t } = useTranslation();
  const { trackEvent } = useEventTracking();
  const { pricingSettings, roomPrices } = useRoomPrices();
  const { priceDevelopment, priceDevelopmentQuery } = usePriceDevelopment();
  const { SparkIcon, setUpsellingModalOpen } = useUpselling();
  const { features } = useFeaturesStore();
  const { view } = useViewStore();

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    price: true,
    occupancy: true,
    weighted_average_price: true
  });
  const indigo = useTailwindColor('indigo');
  const indigoOpacity = 'rgb(91, 72, 238, 0.3)';
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const orange = useTailwindColor('orange');
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '10px';
  const fontWeight = 400;

  const dateString = currentViewingDate.format(API_DATE_FORMAT);
  const priceDevelopmentOfSelectedDay = priceDevelopment?.[dateString];

  const referenceRoomId = pricingSettings?.rooms?.reference?.id;
  const currentDateData = roomPrices?.prices?.data?.[dateString];
  const currentRoomData = referenceRoomId ? currentDateData?.[referenceRoomId] : undefined;

  const today = dayjs();
  const isPastDate = currentViewingDate.isBefore(dayjs().startOf('day'));
  const startingDate = isPastDate ? currentViewingDate : today;
  const currentData = {
    price: Math.round(currentRoomData?.price ?? 0),
    occupancy: currentDateData?.property?.occupancy,
    weighted_average_price: currentRoomData?.weighted_average_price
  };

  useEffect(() => {
    trackEvent('RateEvolutionChartOpened');
  }, []);

  const formatDayData = (): PriceData[] => {
    if (!priceDevelopmentOfSelectedDay) return [];

    return Array.from({ length: 31 }, (_, i) => {
      // Create a 30-day history starting from the startingDate (either today or currentViewingDate)
      const date = startingDate.subtract(i, 'day').format(API_DATE_FORMAT);
      const dayData = priceDevelopmentOfSelectedDay[date];

      let price: number | null = null;
      let occupancy: number | null = null;
      let weightedAveragePrice: number | null = null;

      if (typeof dayData === 'object') {
        // Adjust to new object structure if exists
        price = dayData.price || null;
        occupancy = dayData.occupancy || null;
        weightedAveragePrice = dayData.weighted_average_price || null;
      } else if (typeof dayData === 'number') {
        price = dayData;
      }

      if (i === 0) {
        // Overwrite the first element's data with currentData
        price = currentData.price || null;
        occupancy = currentData.occupancy || null;
        weightedAveragePrice = currentData.weighted_average_price || null;
      }

      // Exclude if weightedAveragePrice is the disabled max market level price
      if (weightedAveragePrice === DISABLED_MAX_MARKET_LEVEL) {
        weightedAveragePrice = null;
      }

      return {
        day: i,
        price,
        date,
        occupancy,
        weighted_average_price: weightedAveragePrice
      };
    }).reverse();
  };

  const customTickFormatter = (value: number) => {
    if (value === 0) return !isPastDate ? t('Today') : dayjs(dateString).format(API_DATE_FORMAT);
    if (value % 2 === 0 && value <= 30) return `-${value.toString()}`;
    return '';
  };

  const customLegendPayload = [
    {
      dataKey: 'price',
      value: 'Recommended/Fix Price',
      color: indigo
    },
    {
      dataKey: 'weighted_average_price',
      value: 'Market Price Level',
      color: orange
    },
    {
      dataKey: 'occupancy',
      value: 'Your Occupancy',
      color: indigoOpacity
    }
  ];

  type LegendPayloadEntry = (typeof customLegendPayload)[number];

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dayjs(dataPoint.date).format('ddd, DD MMM YYYY')}
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-indigo" />
            <Typography variant="meta-2" color="darkGrey">
              {t('Recommended/Fix Price')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium font-bold" color="darkGrey">
            {CurrencyFormatter.format(Math.round(dataPoint.price), '-')}
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full bg-orange" />
            <Typography variant="meta-2" color="darkGrey">
              {t('Market Price Level')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium font-bold" color="darkGrey">
            {CurrencyFormatter.format(dataPoint.weighted_average_price, '-')}
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full" style={{ backgroundColor: indigoOpacity }} />
            <Typography variant="meta-2" color="darkGrey">
              {t('Occupancy')}
            </Typography>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium font-bold" color="darkGrey">
            {formattedPercentage(dataPoint.occupancy)}
          </Typography>
        </div>
      </Card>
    );
  };

  const CustomLegend: React.FC<any> = (props) => {
    const { payload } = props;

    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    // Tooltip mapping for legend labels
    const tooltipTexts: Record<LegendPayloadEntry['dataKey'], string> = {
      weighted_average_price:
        "The Market Price Level shows the evolution of the average price of your ten 'market' competitor properties for this night. Specifically, we display the weighted average market price over time for all room types, scaled to your base price and including your current weekday and monthly adjustment.",
      price: 'Small Double Room defined as your reference room'
    };

    return (
      <ul className="mb-4 flex list-none justify-center">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => (
            <li key={`item-${index}`} className="mr-2 flex cursor-pointer items-center">
              {tooltipTexts[entry.dataKey] ? (
                <TooltipProvider delayDuration={75}>
                  <TooltipComponent>
                    <TooltipTrigger type="button" onClick={() => handleLegendClick(entry.dataKey)}>
                      <div className="flex items-center">
                        <div
                          className="mr-1 h-3 w-3 rounded-full"
                          style={{
                            backgroundColor: entry.color,
                            opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                          }}
                        />
                        <Typography
                          color="copyTextGrey"
                          variant="meta-2"
                          className={cn(
                            seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30'
                          )}>
                          {t(entry.value)}
                        </Typography>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="max-w-xs">
                      <div>{t(tooltipTexts[entry.dataKey])}</div>
                    </TooltipContent>
                  </TooltipComponent>
                </TooltipProvider>
              ) : (
                <div onClick={() => handleLegendClick(entry.dataKey)} className="flex items-center">
                  <div
                    className="mr-1 h-3 w-3 rounded-full"
                    style={{
                      backgroundColor: entry.color,
                      opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                    }}
                  />
                  <Typography
                    color="copyTextGrey"
                    variant="meta-2"
                    className={cn(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                    {t(entry.value)}
                  </Typography>
                </div>
              )}
            </li>
          )
        )}
      </ul>
    );
  };

  const dayData = formatDayData();

  const maxPrice = useMemo(() => {
    const allValues = [
      ...(dayData.map((item) => item.price).filter((price) => price !== null) as number[]),
      ...(dayData
        .map((item) => item.weighted_average_price)
        .filter((price) => price !== null) as number[])
    ];

    return Math.max(...allValues);
  }, [dayData]);

  const maxY = Math.ceil(maxPrice * 1.1);

  if (priceDevelopmentQuery.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <LoadingCircle className="mt-2" />
      </div>
    );
  }

  const CustomLabel: React.FC<any> = ({
    viewBox,
    visibility,
    value,
    fill,
    fontSize,
    yOffset = 10
  }) => {
    const [textWidth, setTextWidth] = useState<number>(0);
    const textRef = useRef<SVGTextElement>(null);

    useEffect(() => {
      if (textRef.current) {
        const width = textRef.current.getBBox().width;
        setTextWidth(width + 10);
      }
    }, [value]);

    if (visibility === 'hidden' || !viewBox) return null;
    const { x, y } = viewBox;
    const padding = 7;

    return (
      <g>
        {/* Invisible SVG text to measure text width to have dynamic width */}
        <text ref={textRef} x="-9999" y="-9999" fontSize={fontSize || '12px'}>
          {value}
        </text>
        <foreignObject x={x + padding} y={y - yOffset} width={textWidth} height="100%">
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '4px',
              display: 'flex',
              padding: '2px',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: fontSize || '12px',
              color: fill || 'black'
            }}>
            {value || ''}
          </div>
        </foreignObject>
      </g>
    );
  };

  const areValuesOverlapping = (val1: number, val2: number) => {
    if (val1 === 0 || val2 === 0) return false;
    const ratio = val1 / val2;
    return ratio > 0.9 && ratio < 1.1;
  };

  const getDynamicYOffset = (price: number | undefined, marketPrice: number | undefined) => {
    if (!price || !marketPrice) {
      return {};
    }
    let priceOffset = 10;
    let marketPriceOffset = 10;

    if (areValuesOverlapping(price, marketPrice)) {
      // adjust yOffset for the smaller value
      if (price < marketPrice) {
        priceOffset -= 15;
      } else {
        marketPriceOffset -= 15;
      }
    }
    return {
      priceOffset,
      marketPriceOffset
    };
  };

  const referenceYOffsets = getDynamicYOffset(
    currentData.price,
    currentData.weighted_average_price
  );

  const isToday = currentViewingDate.isSame(dayjs().startOf('day'));

  const shouldShowPriceEvolution =
    isToday || view === 'admin' || features?.includes(Feature.PriceEvolution);

  return (
    <div className="relative rounded-md border border-mediumGrey pb-4 pt-4">
      {!shouldShowPriceEvolution ? (
        <div
          className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
          onClick={() => setUpsellingModalOpen(true)}
          style={{ cursor: 'pointer' }}>
          <div className="flex items-center gap-2 rounded-md bg-white p-4 text-darkGrey shadow-lg">
            <SparkIcon />
            <Typography>{t('To view the price evolution, upgrade to')}</Typography>
            <Typography className="font-bold text-indigo">{t('Professional plan.')}</Typography>
          </div>
        </div>
      ) : null}
      <div className={`${!shouldShowPriceEvolution ? 'opacity-50' : ''}`}>
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            key={currentViewingDate.toString()}
            data={dayData}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}>
            <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
            <XAxis
              dataKey="day"
              tickFormatter={customTickFormatter}
              tick={{
                fill: grey
              }}
              tickLine={false}
              axisLine={false}
              padding={{ left: 20, right: 20 }}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              label={{
                value: t('Days Back'),
                position: 'insideBottom',
                offset: 0,
                fontSize,
                fontFamily,
                fontWeight
              }}
            />
            <YAxis
              axisLine={false}
              domain={[0, maxY]}
              tick={{ fill: grey }}
              tickCount={8}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              allowDataOverflow={true}
              tickFormatter={(value) => CurrencyFormatter.format(value, '-')}
            />
            <YAxis
              orientation="right"
              yAxisId="right"
              axisLine={false}
              domain={[0, 100]}
              ticks={[0, 25, 50, 75, 100]}
              tick={{ fill: grey }}
              tickCount={5}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              allowDataOverflow={true}
              tickFormatter={(value) => `${value}%`}
            />
            {shouldShowPriceEvolution ? <Tooltip content={<CustomTooltip />} /> : null}
            <Legend
              verticalAlign="top"
              content={(props) => <CustomLegend {...props} payload={customLegendPayload} />}
            />
            {shouldShowPriceEvolution ? (
              <>
                <Area
                  yAxisId="right"
                  visibility={seriesVisibility.occupancy ? 'visible' : 'hidden'}
                  dataKey="occupancy"
                  stroke={indigoOpacity}
                  strokeWidth={0}
                  fill={indigo}
                  fillOpacity={0.2}
                  connectNulls={true}
                />
                <Line
                  visibility={seriesVisibility.price ? 'visible' : 'hidden'}
                  dataKey="price"
                  type="linear"
                  stroke={indigo}
                  dot={false}
                  strokeWidth={2}
                  connectNulls={true}
                />
                <Line
                  visibility={seriesVisibility.weighted_average_price ? 'visible' : 'hidden'}
                  dataKey="weighted_average_price"
                  type="linear"
                  stroke={orange}
                  dot={false}
                  strokeWidth={2}
                  connectNulls={true}
                />
              </>
            ) : null}
            <ReferenceDot
              visibility={seriesVisibility.price ? 'visible' : 'hidden'}
              x={0}
              y={currentData.price || undefined}
              r={3}
              fill={indigo}
              label={
                <CustomLabel
                  visibility={seriesVisibility.price ? 'visible' : 'hidden'}
                  value={CurrencyFormatter.format(currentData.price || undefined, '-')}
                  fill={indigo}
                  fontSize={fontSize}
                  yOffset={referenceYOffsets.priceOffset}
                />
              }
            />
            <ReferenceDot
              visibility={seriesVisibility.weighted_average_price ? 'visible' : 'hidden'}
              x={0}
              y={currentData.weighted_average_price || undefined}
              r={3}
              fill={orange}
              label={
                <CustomLabel
                  visibility={seriesVisibility.weighted_average_price ? 'visible' : 'hidden'}
                  value={CurrencyFormatter.format(
                    currentData.weighted_average_price || undefined,
                    '-'
                  )}
                  fill={orange}
                  fontSize={fontSize}
                  yOffset={referenceYOffsets.marketPriceOffset}
                />
              }
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
