import React, { useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  Line,
  ReferenceArea
} from 'recharts';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { cn } from '@common/utils/cn';
import { Typography } from '@common/components/foundations/Typography';
import { Card } from '@common/components/molecules/Card';
import { formattedPercentage } from '@pages/Client/Dashboard/utils/formattedPercentage';
import { useTailwindColor } from '@common/hooks/useTailwindColors';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { formattedCurrency } from '@pages/Client/Dashboard/utils/formattedCurrency';
import { Icon } from '@common/components/foundations/icons';
import { TooltipIcon } from '@common/components/molecules/TooltipIcon';
import { AirbnbTabKey } from '@pages/Client/Dashboard/pages/Market/AirbnbInsights/AirbnbInsights';
import { useDate } from '@common/hooks/useDate';
import { Feature, useFeaturesStore } from '@common/store/features';
import { API_DATE_FORMAT } from '@common/constants/date';
import { usePriceDrawerStore } from '@common/store/priceDrawer';

type DataKey = 'value' | 'airbnbValue' | 'stlyValue' | 'demandScore';

type DataEntry = {
  date: string;
  value: number | undefined;
  airbnbValue: number | undefined;
  stlyValue: number | undefined;
  demandScore: number | undefined;
};

interface Props {
  data: DataEntry[];
  isCurrency?: boolean;
  view?: 'monthly' | 'yearly';
  type?: AirbnbTabKey;
}

export const AirbnbChart: React.FC<Props> = ({ data, isCurrency, view, type }) => {
  const { t } = useTranslation();
  const fontFamily = "'Inter var', sans-serif";
  const fontSize = '12px';
  const fontWeight = 400;
  const indigo = useTailwindColor('indigo');
  const orange = useTailwindColor('orange');
  const grey = useTailwindColor('grey');
  const mediumGrey = useTailwindColor('mediumGrey');
  const darkGrey = useTailwindColor('darkGrey');
  const gold = useTailwindColor('gold');
  const { features } = useFeaturesStore();
  const { setDrawerState, setViewingDate } = usePriceDrawerStore();

  const shouldDisableIndexDesc = !features?.includes(Feature.LimitedDashboard);

  const airbnbIndexLabel = useMemo(() => {
    if (type === 'occ') return 'Vacation Rental Occupancy';
    if (type === 'adr') {
      return !shouldDisableIndexDesc ? 'Vacation Rental ADR Index' : 'Vacation Rental ADR';
    }
    if (type === 'revpar') {
      return !shouldDisableIndexDesc ? 'Vacation Rental RevPar Index' : 'Vacation Rental RevPar';
    }
    return '';
  }, [type, shouldDisableIndexDesc]);

  const [seriesVisibility, setSeriesVisibility] = useState<Record<DataKey, boolean>>({
    value: true,
    airbnbValue: true,
    stlyValue: true,
    demandScore: true
  });

  const highDemandThreshold = 8;
  const highDemandHighlightColor = '#FFA07A';

  const highDemandReferenceAreas = data
    .filter(
      (entry) =>
        entry.demandScore !== undefined && Math.round(entry.demandScore) > highDemandThreshold
    )
    .map(
      (entry) =>
        seriesVisibility.demandScore && (
          <ReferenceArea
            x1={entry.date}
            x2={entry.date}
            ifOverflow="extendDomain"
            stroke="none"
            fill={highDemandHighlightColor}
            fillOpacity={0.3}
            key={`ref-${entry.date}`}
          />
        )
    );

  const CustomTooltip: React.FC<any> = (props) => {
    const { active, payload } = props;
    if (!active || !payload || !payload[0]) return null;
    const dataPoint = payload[0].payload;
    return (
      <Card backgroundColor="white">
        <Typography variant="meta-1" className="font-semibold" color="darkGrey">
          {dayjs(dataPoint.date).format(view === 'monthly' ? 'ddd, DD MMM YYYY' : 'MMM YYYY')}
        </Typography>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-2 w-2 rounded-full" style={{ backgroundColor: orange }} />
            <div className="flex flex-col">
              <Typography variant="meta-2" color="darkGrey">
                {t(airbnbIndexLabel)}
              </Typography>
            </div>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>
              {dataPoint.airbnbValue
                ? isCurrency
                  ? formattedCurrency(dataPoint.airbnbValue)
                  : formattedPercentage(dataPoint.airbnbValue)
                : 'n.A.'}
            </b>
          </Typography>
        </div>
        <div className="flex items-center justify-between gap-x-3">
          <div className="flex items-center gap-2">
            <div className="h-3 w-2">
              <Icon.Flame color={highDemandHighlightColor} className="-mt-0-5 -ml-0.5 h-3 w-3" />
            </div>
            <div className="flex flex-col">
              <Typography variant="meta-2" color="darkGrey">
                {t(`High Demand Score`)}
              </Typography>
            </div>
          </div>
          <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
            <b>{dataPoint.demandScore ? `${Math.round(dataPoint.demandScore)}/10` : 'n.A.'}</b>
          </Typography>
        </div>
        {dataPoint.value || dataPoint.value === 0 ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-indigo" />
              <Typography variant="meta-2" color="darkGrey">
                {t(`On the Books`)}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>
                {dataPoint.value
                  ? isCurrency
                    ? formattedCurrency(dataPoint.value)
                    : formattedPercentage(dataPoint.value)
                  : 'n.A.'}
              </b>
            </Typography>
          </div>
        ) : null}
        {dataPoint.stlyValue || dataPoint.stlyValue === 0 ? (
          <div className="flex items-center justify-between gap-x-3">
            <div className="flex items-center gap-2">
              <div className="h-2 w-2 rounded-full bg-gold" />
              <Typography variant="meta-2" color="darkGrey">
                {t(`Same Time Last Year`)}
              </Typography>
            </div>
            <Typography variant="meta-2" className="text-meta-2-medium" color="darkGrey">
              <b>
                {dataPoint.stlyValue
                  ? isCurrency
                    ? formattedCurrency(dataPoint.stlyValue)
                    : formattedPercentage(dataPoint.stlyValue)
                  : 'n.A.'}
              </b>
            </Typography>
          </div>
        ) : null}
      </Card>
    );
  };

  const customLegendPayload = [
    {
      dataKey: 'airbnbValue',
      value: t(airbnbIndexLabel),
      color: orange
    },
    {
      dataKey: 'demandScore',
      value: t(`High Vacation Rental Demand Score`),
      color: highDemandHighlightColor
    },
    {
      dataKey: 'value',
      value: t(`On the Books`),
      color: indigo
    },
    {
      dataKey: 'stlyValue',
      value: t(`Same Time Last Year`),
      color: gold
    }
  ];

  const filteredLegendPayload = customLegendPayload.filter((item) =>
    data.some(
      (d) => d[item.dataKey as DataKey] !== null && d[item.dataKey as DataKey] !== undefined
    )
  );

  interface CustomBarProps {
    fill: string;
    x: number;
    y: number;
    width: number;
    height: number;
    budget?: number;
    maxY: number;
    dataKey: DataKey;
    seriesVisibility: {
      [key: string]: boolean;
    };
    borderWidth?: number;
    borderRadius?: number;
  }

  const CustomBar: React.FC<CustomBarProps> = ({
    fill,
    x,
    y,
    width,
    height,
    dataKey,
    seriesVisibility,
    borderWidth = 1,
    borderRadius = 2
  }) => {
    const barVisible = seriesVisibility[dataKey];

    const adjustedHeight = Math.max(0, height);
    const adjustedY = height < 0 ? y + height : y;

    return (
      <g>
        {barVisible ? (
          <>
            {adjustedHeight > 0 ? (
              <rect
                x={x}
                y={adjustedY}
                width={width}
                height={adjustedHeight}
                fill={fill}
                rx={borderRadius}
                ry={borderRadius}
              />
            ) : null}
            {adjustedHeight > borderWidth * 2 ? (
              <rect
                x={x + borderWidth}
                y={adjustedY + borderWidth}
                width={width - borderWidth * 2}
                height={adjustedHeight - borderWidth * 2}
                fill={fill}
                rx={borderRadius - borderWidth}
                ry={borderRadius - borderWidth}
              />
            ) : null}
          </>
        ) : null}
      </g>
    );
  };

  const CustomLegend: React.FC<any> = (props) => {
    const { payload, type } = props;

    const handleLegendClick = (dataKey: DataKey) => {
      setSeriesVisibility((prev) => ({ ...prev, [dataKey]: !prev[dataKey] }));
    };

    const airbnbTooltipContent = useMemo(() => {
      if (type === 'adr') {
        return !shouldDisableIndexDesc
          ? `The indexing of Vacation Rental market ADR is calculated relative to your property's ADR (ADR of next 12 months on a rolling basis). This index allows for easy comparison of Vacation Rental prices in the market, indicating whether they are higher or lower compared to your ADR.`
          : null;
      }
      if (type === 'revpar') {
        return !shouldDisableIndexDesc
          ? `The indexing of Vacation Rental market REVPAR is calculated relative to your property's REVPAR (REVPAR of next 12 months on a rolling basis). This index allows for easy comparison of Vacation Rental prices in the market, indicating whether they are higher or lower compared to your REVPAR.`
          : null;
      }
      return '';
    }, [type, shouldDisableIndexDesc]);

    return (
      <ul className="mb-8 flex h-6 list-none justify-center gap-4">
        {payload?.map(
          (entry: { dataKey: DataKey; value: string; color: string }, index: number) => {
            return (
              <li
                key={`item-${index}`}
                className="mr-2 flex cursor-pointer items-center"
                onClick={() => handleLegendClick(entry.dataKey)}>
                {entry.dataKey === 'demandScore' ? (
                  <div className="mr-0.5">
                    <Icon.Flame
                      color={highDemandHighlightColor}
                      style={{
                        opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="mr-1 h-3 w-3 rounded-full"
                    style={{
                      backgroundColor: entry.color,
                      opacity: seriesVisibility[entry.dataKey] ? 1 : 0.3
                    }}
                  />
                )}
                <Typography
                  color="copyTextGrey"
                  variant="meta-2"
                  className={cn(seriesVisibility[entry.dataKey] ? 'opacity-100' : 'opacity-30')}>
                  {entry.value}
                </Typography>
                {entry.dataKey === 'airbnbValue' && airbnbTooltipContent ? (
                  <TooltipIcon iconClassName="ml-1" content={t(airbnbTooltipContent)} />
                ) : null}
                {entry.dataKey === 'demandScore' ? (
                  <TooltipIcon
                    iconClassName="ml-1"
                    content={t(
                      'All days with a Vacation Rental Demand Score >8 are considered as very high demand days and are highlighted in the chart.'
                    )}
                  />
                ) : null}
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const CustomTick: React.FC<any> = ({ x, y, payload }) => {
    const { isToday, isWeekend } = useDate();
    const date = new Date(payload.value);

    if (view === 'yearly') {
      return (
        <text
          x={x}
          y={y + 15}
          fill="grey"
          fontSize={fontSize}
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          textAnchor="middle"
          className="cursor-pointer">
          {dayjs(payload.value).format('MMM YYYY')}
        </text>
      );
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={16}
          dy={0}
          textAnchor="end"
          fill={isToday(date) ? darkGrey : grey}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={isToday(date) ? 'bold' : fontWeight}
          textDecoration={isWeekend(date) ? 'underline' : 'none'}
          transform="rotate(-45 0 16)">
          {dayjs(payload.value).format('MMM DD')}
        </text>
      </g>
    );
  };

  function safeMax(numbers: (number | undefined)[]): number {
    // Filter out undefined values and provide a default value if the array is empty
    const filteredNumbers = numbers.filter((num): num is number => num !== undefined);
    return filteredNumbers.length > 0 ? Math.max(...filteredNumbers) : 0; // Provide 0 or any appropriate fallback value
  }

  const maxY = useMemo(() => {
    const allValues = [
      ...data.map((item) => item.value).filter((num) => num !== undefined),
      ...data.map((item) => item.airbnbValue).filter((num) => num !== undefined),
      ...data.map((item) => item.stlyValue).filter((num) => num !== undefined)
    ];

    return safeMax([...allValues]);
  }, [data]);

  const handleOpenPriceDrawer = (date: string | undefined) => {
    if (!date || view === 'yearly') return;
    const formattedDate = new Date(dayjs(date).format(API_DATE_FORMAT));
    setDrawerState(true);
    setViewingDate(formattedDate);
  };

  return (
    <Card backgroundColor="white">
      <ScrollArea>
        <ResponsiveContainer minWidth={1000} width="100%" height={400}>
          <ComposedChart
            onClick={(data: any) => {
              handleOpenPriceDrawer(data?.activeLabel);
            }}
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 0 }}
            barGap={-20}
            barCategoryGap={5}>
            <CartesianGrid stroke={mediumGrey} strokeDasharray="2" vertical={false} />
            <XAxis
              axisLine={false}
              height={80}
              tickLine={false}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              dataKey="date"
              tick={<CustomTick />}
              interval={0}
            />
            <YAxis
              domain={!isCurrency ? [0, 100] : undefined}
              axisLine={false}
              tick={{ fill: grey }}
              tickLine={false}
              width={80}
              fontFamily={fontFamily}
              fontSize={fontSize}
              fontWeight={fontWeight}
              tickFormatter={(value) =>
                isCurrency ? formattedCurrency(value) : formattedPercentage(value)
              }
            />
            <RechartsTooltip
              content={(props) => <CustomTooltip {...props} />}
              wrapperStyle={{ outline: 'none' }}
            />
            <Legend
              verticalAlign="top"
              content={(props) => (
                <CustomLegend {...props} payload={filteredLegendPayload} type={type} />
              )}
            />

            {highDemandReferenceAreas}

            <Bar
              visibility={seriesVisibility.value ? 'visible' : 'hidden'}
              dataKey="value"
              name="Value"
              fill={indigo}
              barSize={8}
              shape={(props) => (
                <CustomBar
                  {...props}
                  maxY={maxY}
                  seriesVisibility={seriesVisibility}
                  dataKey="value"
                />
              )}
            />
            <Bar
              visibility={seriesVisibility.airbnbValue ? 'visible' : 'hidden'}
              dataKey="airbnbValue"
              name="Vacation Rental Value"
              fill={orange}
              barSize={8}
              shape={(props) => (
                <CustomBar
                  {...props}
                  maxY={maxY}
                  seriesVisibility={seriesVisibility}
                  dataKey="airbnbValue"
                />
              )}
            />
            <Line
              isAnimationActive={false}
              visibility={seriesVisibility.stlyValue ? 'visible' : 'hidden'}
              dataKey="stlyValue"
              name="Same Time Last Year"
              stroke={gold}
              fill={gold}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </Card>
  );
};
