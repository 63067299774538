import { Stack, Table, Title, Text, TextInput, Card } from '@mantine/core';

export const Typography = () => {
  const elements = [
    { adjustment: 120, room: 'Standard', recommendedPrice: 150 },
    { adjustment: 100, room: 'Single', recommendedPrice: 130 },
    { adjustment: 140, room: 'Double', recommendedPrice: 170 },
    { adjustment: 600, room: 'Penthouse', recommendedPrice: 630 }
  ];

  const tableRows = elements.map((element) => (
    <Table.Tr key={element.room}>
      <Table.Td>{element.room}</Table.Td>
      <Table.Td>
        <TextInput variant="filled" leftSection={'€'} defaultValue={element.adjustment} />
      </Table.Td>
      <Table.Td>{element.recommendedPrice}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Stack py="xs">
      <Stack>
        <Title order={2} size="h3">
          Table
        </Title>
        <Card>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Room</Table.Th>
                <Table.Th>Fixed Price</Table.Th>
                <Table.Th>Recommended Price</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{tableRows}</Table.Tbody>
          </Table>
        </Card>
      </Stack>
      <Stack>
        <Title order={2} size="h3">
          Title
        </Title>
        <Title order={1}>This is a h1 title</Title>
        <Title order={2}>This is a h2 title</Title>
        <Title order={3}>This is a h3 title</Title>
        <Title order={4}>This is a h4 title</Title>
        <Title order={5}>This is a h5 title</Title>
        <Title order={6}>This is a h6 title</Title>
        <Title order={2} size="h3">
          Text
        </Title>
        <Text size="xl">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Text>
        <Text size="lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Text>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Text>
        <Text size="sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Text>
        <Text size="xs">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </Text>
      </Stack>
    </Stack>
  );
};
