/* eslint-disable @typescript-eslint/ban-types */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Pusher, { Channel } from 'pusher-js';
import { getEnvVar } from '@common/utils/env';
import { BasePusherEventResponse } from '@common/constants/pusher';

type State = {
  pusher: Pusher | null;
  channel: Channel | null;
  channelBind: <ResponseData extends BasePusherEventResponse>(
    eventName: string,
    callback: (data: ResponseData) => void
  ) => void;
  channelUnbind: (eventName: string, callback?: Function) => void;
  preparePusher: (hotelId: number) => void;
};
export const usePusherStore = create<State>()(
  devtools((set, get) => ({
    pusher: null,
    channel: null,
    channelBind: (eventName, callback) => {
      const pusher = get().pusher;
      const channel = get().channel;
      if (pusher && channel) {
        channel.bind(eventName, callback);
      }
    },
    channelUnbind: (eventName, callback?: Function) => {
      const channel = get().channel;

      if (channel) {
        channel.unbind(eventName, callback);
      }
    },
    preparePusher: (hotelId: number) => {
      const pusherInstance = new Pusher(getEnvVar('VITE_KEY'), {
        cluster: getEnvVar('VITE_CLUSTER')
      });

      const channel = pusherInstance.subscribe(hotelId.toString());
      set({ channel, pusher: pusherInstance });
    }
  }))
);
