import { createAdminUser } from '@common/api/account';
import { CreateAdminUserPayload } from '@common/api/account/types';
import { useNotificationsStore } from '@common/store/notifications';
import { RPGUsersQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddNewAdminUser = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: CreateAdminUserPayload) => {
      return createAdminUser(payload);
    },
    onSuccess: () => {
      addNotification('success', 'User created successfully');
      queryClient.invalidateQueries({ queryKey: [RPGUsersQueryKeys.GET_ADMIN_USER_LIST] });
    },
    onError: (error: unknown) => {
      const axiosError = error as any;
      if (axiosError.response?.data?.username) {
        addNotification('fail', `${axiosError.response.data.username[0]}`);
      } else if (axiosError.response?.data?.last_name) {
        addNotification('fail', `${axiosError.response.data.last_name[0]}`);
      } else if (axiosError.response?.data?.first_name) {
        addNotification('fail', `${axiosError.response.data.first_name[0]}`);
      } else {
        addNotification('fail', 'Failed to create new user');
      }
    }
  });

  return {
    createAdminUser: mutate,
    isLoading,
    isSuccess
  };
};
