import { Navigate, RouteObject } from 'react-router-dom';
import SupportCriticalTicketsPage from '@pages/Admin/ErrorTickets/pages/SupportCriticalTickets';
import SupportOtherTicketsPage from '@pages/Admin/ErrorTickets/pages/SupportOtherTickets';
import SupportDevTicketsPage from '@pages/Admin/ErrorTickets/pages/DevCriticalTickets';
import DashboardCriticalTicketsPage from '@pages/Admin/ErrorTickets/pages/DashboardCriticalTickets';
import DashboardOtherTicketsPage from '@pages/Admin/ErrorTickets/pages/DashboardOtherTickets';

export const errorTicketsRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="support-critical" /> },
  {
    path: 'support-critical',
    element: <SupportCriticalTicketsPage />
  },
  {
    path: 'support-other',
    element: <SupportOtherTicketsPage />
  },
  {
    path: 'dev-critical',
    element: <SupportDevTicketsPage />
  },
  {
    path: 'dashboard-critical',
    element: <DashboardCriticalTicketsPage />
  },
  {
    path: 'dashboard-other',
    element: <DashboardOtherTicketsPage />
  }
];
