import { searchCompetitor } from '@common/api/hotel';
import { SearchHotelCompetitorListResponse } from '@common/api/hotel/types';
import { PropertyQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Params = {
  search: string;
};

export const useSearchHotelCompetitor = ({ search = '' }: Params) => {
  const query = useQuery({
    queryKey: [PropertyQueryKeys.SEARCH_HOTEL_COMPETITOR, search],
    queryFn: async () => {
      if (search.length >= 5) {
        const response = await searchCompetitor(search);
        return response?.data || [];
      }
      return [];
    },
    enabled: search.length >= 5 // Only run the query if the search length is at least 5
  });

  const searchCompetitorResult = useMemo(() => {
    return query.data as SearchHotelCompetitorListResponse;
  }, [query.data]);

  return { searchCompetitorResult, query };
};
