import { Title, Text, ActionIcon, Tabs, Anchor, Container, Code } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { Inputs } from '@pages/DesignSystem/components/inputs';
import { Combobox } from '@pages/DesignSystem/components/combobox';
import { Buttons } from '@pages/DesignSystem/components/buttons';
import { Overlays } from '@pages/DesignSystem/components/overlays';
import { DataDisplay } from '@pages/DesignSystem/components/data-display';
import { Typography } from '@pages/DesignSystem/components/typography';
import { Navigation } from '@pages/DesignSystem/components/navigation';
import { useDocumentTitle } from '@mantine/hooks';

export const DesignSystemLayout = () => {
  useDocumentTitle('Design System');
  return (
    <Container p="md">
      <Title order={1}>Design System</Title>
      <Text>
        This page is a collection of components that are available in the Mantine core library to
        illustrate the RoomPriceGenie design system. The Mantine theme overrides some of the
        defaults to adhere to the RoomPriceGenie brand guide. Under the hood the theme still
        enforces the{' '}
        <Code color="indigo" c="white">
          open-color
        </Code>{' '}
        scale used in the Mantine default theme. The components are used for development purposes to
        test accessibility and design consistency. The collection here are the most used components
        throughout the app, for further documentation see the{' '}
        <Anchor href="//mantine.dev" variant="text">
          Mantine documentation
        </Anchor>
        .
      </Text>
      <Tabs defaultValue="inputs" mt="xs">
        <Tabs.List>
          <Tabs.Tab
            value="inputs"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/input/">
                <IconLink />
              </ActionIcon>
            }>
            Inputs
          </Tabs.Tab>
          <Tabs.Tab
            value="combobox"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/combobox/">
                <IconLink />
              </ActionIcon>
            }>
            Combobox
          </Tabs.Tab>
          <Tabs.Tab
            value="buttons"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/button/">
                <IconLink />
              </ActionIcon>
            }>
            Buttons
          </Tabs.Tab>
          <Tabs.Tab
            value="overlays"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/drawer/">
                <IconLink />
              </ActionIcon>
            }>
            Overlays
          </Tabs.Tab>
          <Tabs.Tab
            value="data-display"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/badge/">
                <IconLink />
              </ActionIcon>
            }>
            Data Display
          </Tabs.Tab>
          <Tabs.Tab
            value="typography"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/text/">
                <IconLink />
              </ActionIcon>
            }>
            Typography
          </Tabs.Tab>
          <Tabs.Tab
            value="navigation"
            rightSection={
              <ActionIcon
                variant="light"
                size="xs"
                component="a"
                href="https://mantine.dev/core/tabs/">
                <IconLink />
              </ActionIcon>
            }>
            Navigation
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="inputs">
          <Inputs />
        </Tabs.Panel>

        <Tabs.Panel value="combobox">
          <Combobox />
        </Tabs.Panel>

        <Tabs.Panel value="buttons">
          <Buttons />
        </Tabs.Panel>

        <Tabs.Panel value="overlays">
          <Overlays />
        </Tabs.Panel>

        <Tabs.Panel value="data-display">
          <DataDisplay />
        </Tabs.Panel>

        <Tabs.Panel value="typography">
          <Typography />
        </Tabs.Panel>

        <Tabs.Panel value="navigation">
          <Navigation />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
