import {
  CreatePartnerPayload,
  PartnerSchema,
  UpdatePartnerPayload
} from '@common/api/integration/schema';
import { ModalFooter } from '@common/mantine/components/modal-footer';
import { IntegrationPartnerQueryKeys } from '@common/types/query-keys';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ActionIcon,
  Button,
  Checkbox,
  CopyButton,
  Divider,
  LoadingOverlay,
  Modal,
  rem,
  Stack,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import {
  useCreateIntPartner,
  useGetDetailIntPartner,
  useUpdateIntPartner
} from '@pages/Admin/IntegrationPartner/hooks/useIntegrationPartner';
import { useSelectedPartnerStore } from '@pages/Admin/IntegrationPartner/store/intpartners';
import { IconCheck, IconCopy, IconInfoCircle } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { isNil } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';

type IntegrationPartnerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: 'add' | 'edit';
};

interface TooltipDesc {
  room_only_mapping: string;
  independent_rate: string;
  derivation_rates: string;
  occupancy_based_pricing: string;
  channel_manager: string;
  disable_integration_partner: string;
}

export const IntegrationPartnerModal = ({
  isOpen,
  onClose,
  mode
}: IntegrationPartnerModalProps) => {
  const { createIntPartner } = useCreateIntPartner();
  const { updateIntPartner } = useUpdateIntPartner();
  const { setSelectedPartnerId, selectedPartnerId } = useSelectedPartnerStore();

  const queryClient = useQueryClient();

  const {
    intPartnerDetail,
    query: { isLoading: isGetDetailLoading }
  } = useGetDetailIntPartner({
    id: selectedPartnerId,
    mode
  });

  const defaultValues = {
    name: '',
    email: '',
    event_url: '',
    is_active: true,
    configurations: {
      is_channel_manager: false,
      room_config: {
        is_occupancy_based: false,
        is_rate_derivation: false,
        room_rate_has_relation: false,
        room_type_only_mapping: false
      }
    },
    api_key: ''
  };

  const tooltip_desc: TooltipDesc = {
    room_only_mapping: t(
      'When enabled, Rate Plans will be unavailable for accounts linked to this partner. Prices will be set at the Room Type level.'
    ),
    independent_rate: t(
      'When enabled, rates are configured separately and can be applied to any room type.'
    ),
    derivation_rates: t(
      'Activates the Rate Derivation feature for accounts using this partner’s PMS/CM.'
    ),
    occupancy_based_pricing: t(
      'Activates the Occupancy Pricing feature for accounts connected to this partner’s PMS/CM.'
    ),
    channel_manager: t(
      'Identifies this partner as a Channel Manager and ensures it is correctly displayed in the accounts interface.'
    ),
    disable_integration_partner: t(
      "When enabled, all accounts connected to this partner's PMS/CM will be de-activated."
    )
  };

  const methods = useForm({
    resolver: zodResolver(PartnerSchema),
    defaultValues: defaultValues
  });

  useEffect(() => {
    if (mode === 'edit' && !isNil(selectedPartnerId) && !isGetDetailLoading) {
      if (intPartnerDetail?.data) {
        methods.reset(intPartnerDetail.data);
      }
    }
  }, [selectedPartnerId, isGetDetailLoading, mode, intPartnerDetail]);

  const handleClose = () => {
    methods.reset();
    if (mode === 'edit') {
      setSelectedPartnerId(null);
      queryClient.removeQueries({
        queryKey: [IntegrationPartnerQueryKeys.GET_INTEGRATION_PARTNER]
      });
    }
    onClose();
  };

  const savePartner = async (data: FieldValues) => {
    if (mode === 'edit') {
      const payloadData = {
        ...data,
        id: selectedPartnerId
      };
      await updateIntPartner(payloadData as UpdatePartnerPayload & { id: number });
    } else {
      await createIntPartner(data as CreatePartnerPayload);
    }
    handleClose();
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={mode === 'add' ? t('Add New Integration Partner') : t('Edit Integration Partner')}
      size="lg"
      centered
      styles={{
        body: {
          padding: '0'
        }
      }}>
      <LoadingOverlay
        visible={isGetDetailLoading && mode === 'edit'}
        zIndex={1000}
        overlayProps={{ radius: 'md', blur: 2 }}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(savePartner)}>
          <Stack px="md" pb="md" gap="lg">
            <Controller
              name="name"
              control={methods.control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={t('Please enter provider name') as string}
                  error={error?.message}
                  variant="filled"
                  label={t('Provider Name')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
            <Controller
              name="event_url"
              control={methods.control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={t('Please enter provider url') as string}
                  error={error?.message}
                  variant="filled"
                  label={t('Provider URL')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
            <Controller
              name="email"
              control={methods.control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={t('Please enter provider email') as string}
                  error={error?.message}
                  type="email"
                  variant="filled"
                  label={t('Provider Email')}
                  value={value}
                  onChange={onChange}
                  withAsterisk
                />
              )}
            />
            {mode === 'edit' ? (
              <Controller
                name="api_key"
                control={methods.control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextInput
                    error={error?.message}
                    variant="filled"
                    label={t('API KEY')}
                    value={value}
                    onChange={onChange}
                    withAsterisk
                    readOnly
                    rightSection={
                      <CopyButton value={methods.getValues('api_key')} timeout={2000}>
                        {({ copied, copy }) => (
                          <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                            <ActionIcon
                              color={copied ? 'teal' : 'gray'}
                              variant="subtle"
                              onClick={copy}>
                              {copied ? (
                                <IconCheck style={{ width: rem(16) }} />
                              ) : (
                                <IconCopy style={{ width: rem(16) }} />
                              )}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                    }
                  />
                )}
              />
            ) : null}
            <Divider />
            {mode === 'edit' ? (
              <>
                <Controller
                  control={methods.control}
                  name="is_active"
                  render={({ field: { value, onChange } }) => (
                    <div className="flex items-center gap-1.5">
                      <Checkbox
                        label={t('Disable this integration partner')}
                        checked={!value}
                        onChange={(e) => {
                          onChange(!e.target.checked);
                        }}
                      />
                      <Tooltip
                        label={tooltip_desc.disable_integration_partner}
                        multiline
                        maw="20rem"
                        transitionProps={{ transition: 'fade-up', duration: 500 }}>
                        <IconInfoCircle size={20} className="text-grey" />
                      </Tooltip>
                    </div>
                  )}
                />
                <Divider />
              </>
            ) : null}
            <Text>Configurations</Text>
            <Controller
              control={methods.control}
              name={'configurations.room_config.room_type_only_mapping'}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-1.5">
                  <Checkbox
                    label={t('Room Only Mapping')}
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  <Tooltip
                    label={tooltip_desc.room_only_mapping}
                    multiline
                    maw="20rem"
                    transitionProps={{ transition: 'fade-up', duration: 500 }}>
                    <IconInfoCircle size={20} className="text-grey" />
                  </Tooltip>
                </div>
              )}
            />
            <Controller
              control={methods.control}
              name={'configurations.room_config.room_rate_has_relation'}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-1.5">
                  <Checkbox
                    label={t('Independent Rate')}
                    checked={!value}
                    onChange={(e) => {
                      onChange(!e.target.checked);
                    }}
                  />
                  <Tooltip
                    label={tooltip_desc.independent_rate}
                    multiline
                    maw="20rem"
                    transitionProps={{ transition: 'fade-up', duration: 500 }}>
                    <IconInfoCircle size={20} className="text-grey" />
                  </Tooltip>
                </div>
              )}
            />
            <Controller
              control={methods.control}
              name={'configurations.room_config.is_rate_derivation'}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-1.5">
                  <Checkbox
                    label={t('Derivation Rates')}
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  <Tooltip
                    label={tooltip_desc.derivation_rates}
                    multiline
                    maw="20rem"
                    transitionProps={{ transition: 'fade-up', duration: 500 }}>
                    <IconInfoCircle size={20} className="text-grey" />
                  </Tooltip>
                </div>
              )}
            />
            <Controller
              control={methods.control}
              name={'configurations.room_config.is_occupancy_based'}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-1.5">
                  <Checkbox
                    label={t('Occupancy Based Pricing')}
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  <Tooltip
                    label={tooltip_desc.occupancy_based_pricing}
                    multiline
                    maw="20rem"
                    transitionProps={{ transition: 'fade-up', duration: 500 }}>
                    <IconInfoCircle size={20} className="text-grey" />
                  </Tooltip>
                </div>
              )}
            />
            <Controller
              control={methods.control}
              name={'configurations.is_channel_manager'}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-1.5">
                  <Checkbox
                    label={t('Channel Manager')}
                    checked={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  <Tooltip
                    label={tooltip_desc.channel_manager}
                    multiline
                    maw="20rem"
                    transitionProps={{ transition: 'fade-up', duration: 500 }}>
                    <IconInfoCircle size={20} className="text-grey" />
                  </Tooltip>
                </div>
              )}
            />
          </Stack>
          <ModalFooter>
            <Button onClick={handleClose} variant="subtle">
              {t('Cancel')}
            </Button>
            <Button type="submit" loading={false}>
              {t('Save')}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};
