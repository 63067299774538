export function Snooze(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94274 3.06006C6.98659 3.01085 7.00828 2.94576 7.00271 2.88006C6.99853 2.80744 6.96627 2.7393 6.91276 2.69006C5.72023 1.69923 3.96948 1.78126 2.87471 2.87925C1.77993 3.97725 1.7021 5.7292 2.69518 6.92006C2.74601 6.96992 2.81391 6.99853 2.88507 7.00006C2.95219 7.00297 3.01745 6.97757 3.06497 6.93006L6.94274 3.06006ZM11.9998 4.00006C7.03213 4.00006 3.005 8.0295 3.005 13.0001C3.005 17.9706 7.03213 22.0001 11.9998 22.0001C16.9676 22.0001 20.9947 17.9706 20.9947 13.0001C20.9947 8.0295 16.9676 4.00006 11.9998 4.00006ZM11.9998 20.0001C8.13607 20.0001 5.00386 16.8661 5.00386 13.0001C5.00386 9.13407 8.13607 6.00006 11.9998 6.00006C15.8636 6.00006 18.9958 9.13407 18.9958 13.0001C18.9958 16.8661 15.8636 20.0001 11.9998 20.0001ZM9.00156 9.50006C9.00156 9.22392 9.22529 9.00006 9.50128 9.00006H14.4984C14.7744 9.00006 14.9981 9.22392 14.9981 9.50006V10.6001C14.9998 10.8562 14.9032 11.1031 14.7283 11.2901L11.2503 15.0001H14.4984C14.7744 15.0001 14.9981 15.2239 14.9981 15.5001V16.5001C14.9981 16.7762 14.7744 17.0001 14.4984 17.0001H9.50128C9.22529 17.0001 9.00156 16.7762 9.00156 16.5001V15.4001C8.99989 15.144 9.09648 14.897 9.27141 14.7101L12.7494 11.0001H9.50128C9.22529 11.0001 9.00156 10.7762 9.00156 10.5001V9.50006ZM17.0769 2.69006C17.6164 2.24282 18.2953 1.99869 18.9958 2.00006C20.16 1.99817 21.22 2.67069 21.7147 3.72506C22.2095 4.77944 22.0496 6.02506 21.3045 6.92006C21.2553 6.9736 21.1872 7.00588 21.1146 7.01006C21.0475 7.01297 20.9822 6.98757 20.9347 6.94006L17.0569 3.06006C17.0095 3.01251 16.9841 2.94722 16.987 2.88006C16.9912 2.80744 17.0234 2.7393 17.0769 2.69006Z"
        fill="currentColor"
      />
    </svg>
  );
}
