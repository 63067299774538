import React, { useState } from 'react';
import GoogleMapReact, { Coords, Props } from 'google-map-react';
import { useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { cn } from '@common/utils/cn';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { getEnvVar } from '@common/utils/env';
import { Skeleton } from '@common/components/atoms/Skeleton';
import { useHotelCompetitor } from '@pages/Client/Calendar/hooks/useHotelCompetitor';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';

interface MarkerProps {
  lat: number;
  lng: number;
  text?: string | undefined;
  markerClassName?: string;
  onClick?: () => void;
}

export const MapHotelClient = () => {
  const { hotelDetails } = useHotelDetails();
  const { hotelCompetitor } = useHotelCompetitor();
  const { id: hotelId } = useParams();

  const mapOptions: Props['options'] = {
    // Configure map options
  };

  const center: Coords = {
    lat: parseFloat(hotelDetails?.lat || '0'),
    lng: parseFloat(hotelDetails?.lng || '0')
  };

  const Marker: React.FC<MarkerProps> = ({ text, markerClassName, onClick }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
      <div
        className={cn('group relative', { 'cursor-pointer': !!onClick })}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={onClick}>
        <div
          className={`flex h-8 w-8 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-full  ring-1 ring-white  ${markerClassName}`}>
          <Icon.Poi className="mx-auto my-auto h-6 w-6 text-white" />
        </div>
        <Transition
          show={showTooltip}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="absolute left-1/2 top-6 z-50 w-48 -translate-x-1/2 rounded-sm bg-white px-4 py-2 opacity-0 shadow-lg backdrop:blur">
          <Typography className="text-gray-800 text-sm">{text}</Typography>
        </Transition>
      </div>
    );
  };

  const BASE_CLIENT_URL = '/client/calendar';

  return (
    <div className=" w-full rounded-md bg-white p-4">
      <div className="mb-4 flex items-center justify-center gap-3">
        <div className="flex items-center  gap-2">
          <div className="h-4 w-4 rounded-full bg-indigo" />
          <Typography variant="paragraph-3" element="p">
            Client Hotel
          </Typography>
        </div>
        <div className="flex items-center  gap-2">
          <div className="h-4 w-4 rounded-full bg-orange" />
          <Typography variant="paragraph-3" element="p">
            {' '}
            Client Hotel Competitor 1-10
          </Typography>
        </div>
        <div className="flex items-center  gap-2">
          <div className="h-4 w-4 rounded-full bg-orange-reduced" />
          <Typography variant="paragraph-3" element="p">
            {' '}
            Client Hotel Competitor 11-20
          </Typography>
        </div>
        <div className="flex items-center  gap-2">
          <div className="h-4 w-4 rounded-full bg-uiGreen" />
          <Typography variant="paragraph-3" element="p">
            Other Clients
          </Typography>
        </div>
        <div className="flex items-center  gap-2">
          <div className="h-4 w-4 rounded-full bg-gold" />
          <Typography variant="paragraph-3" element="p">
            Other Clients Competitors
          </Typography>
        </div>
      </div>
      <div className="h-[60vh]">
        {hotelCompetitor && hotelDetails && hotelId ? (
          <GoogleMapReact
            bootstrapURLKeys={{ key: `${getEnvVar('VITE_GOOGLEAPIKEY')}` }}
            defaultCenter={center}
            defaultZoom={14}
            options={mapOptions}>
            {/* Other Client Competitor */}
            {hotelCompetitor?.nearby_client_and_competitor?.client_competitor_list?.map((hotel) =>
              hotel?.competitor?.hotel?.lat && hotel?.competitor?.hotel?.lng ? (
                <Marker
                  key={hotel?.index}
                  markerClassName="bg-gold"
                  lat={parseFloat(hotel?.competitor?.hotel?.lat)}
                  lng={parseFloat(hotel?.competitor?.hotel?.lng)}
                  text={`${hotel?.index}. ${hotel.competitor?.hotel?.name}`}
                />
              ) : null
            )}
            {/* Other Client */}
            {hotelCompetitor?.nearby_client_and_competitor?.client_list
              ?.filter((hotel) => hotel?.id !== parseInt(hotelId))
              .map((hotel) =>
                hotel?.lat && hotel?.lng ? (
                  <Marker
                    key={hotel?.id}
                    markerClassName="bg-uiGreen"
                    lat={parseFloat(hotel?.lat)}
                    lng={parseFloat(hotel?.lng)}
                    text={`${hotel?.name}`}
                    onClick={() =>
                      hotel?.id ? window.open(`${BASE_CLIENT_URL}/${hotel.id}`, '_blank') : null
                    }
                  />
                ) : null
              )}
            {/*  Client Competitor 11-20 */}
            {hotelCompetitor?.results
              ?.slice(10, 19)
              .map((hotel) =>
                hotel?.competitor?.hotel?.lat && hotel?.competitor?.hotel?.lng ? (
                  <Marker
                    key={hotel?.index}
                    markerClassName="bg-orange-reduced"
                    lat={parseFloat(hotel?.competitor?.hotel?.lat)}
                    lng={parseFloat(hotel?.competitor?.hotel?.lng)}
                    text={`${hotel?.index}. ${hotel?.competitor?.hotel?.name}`}
                  />
                ) : null
              )}
            {/*  Client Competitor 1-10 */}
            {hotelCompetitor?.results
              ?.slice(0, 9)
              .map((hotel) =>
                hotel?.competitor?.hotel?.lat && hotel?.competitor?.hotel?.lng ? (
                  <Marker
                    key={hotel?.index}
                    markerClassName="bg-orange"
                    lat={parseFloat(hotel?.competitor?.hotel?.lat)}
                    lng={parseFloat(hotel?.competitor?.hotel?.lng)}
                    text={`${hotel?.index}. ${hotel?.competitor?.hotel?.name}`}
                  />
                ) : null
              )}
            {/* Current Client */}
            <Marker
              markerClassName="bg-indigo"
              lat={center.lat}
              lng={center.lng}
              text={hotelDetails?.name}
            />
          </GoogleMapReact>
        ) : (
          <div>
            <Skeleton className="h-[60vh]" />
          </div>
        )}
      </div>
    </div>
  );
};
