import { getPartnerResellerList } from '@common/api/account';
import { ResellerListResponse } from '@common/api/account/types';
import { PartnerQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Params = {
  page: number;
  search?: string;
};

export const useGetPartnersList = ({ page = 1, search = '' }: Params) => {
  const query = useQuery({
    queryKey: [PartnerQueryKeys.GET_PARTNER_LIST, page, search],
    queryFn: async () => {
      const response = await getPartnerResellerList(page, search);
      return response; // Simplified to always call with search, empty search is handled by the API
    },
    retry: false
  });

  const partnerList = useMemo(() => {
    return query.data as unknown as ResellerListResponse;
  }, [query.data]);

  return { partnerList, query };
};
