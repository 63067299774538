import { round } from 'lodash-es';

/**
 * Should only be used within a React Hooks Form component
 * as it relies on the parent form context.
 * https://react-hook-form.com/docs/useformcontext
 */

export function absoluteOrPercentage(isAbsolute: boolean, value: number, adjustment: number) {
  return round(isAbsolute ? value + adjustment : value * (1 + adjustment / 100));
}
