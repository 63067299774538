import { useFormSchema } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useFormSchema';
import {
  Tabs,
  useSurgeProtectionStore
} from '@pages/Client/PricingStrategy/SurgeProtection/store/surgeProtection';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';
import { Text, Group, Box, Mark } from '@mantine/core';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { CurrencyFormatter } from '@common/utils/formatCurrency';

export const SurgeSettingsText = () => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const isAutoUploadOn = hotelDetails?.is_update_to_pms ?? false;
  const { pricingTypeTabValue } = useSurgeProtectionStore();
  const { schema } = useFormSchema();

  const { getValues, watch } = useFormContext<z.infer<typeof schema>>();

  return (
    <Box maw="70%">
      {getValues('auto_price_protection') && pricingTypeTabValue === Tabs.DECAY_PRICE ? (
        <Text size="md">
          <Trans
            i18nKey="When you receive <b>{{numberOfBookings}} bookings</b> for a single date within a period of 24 hours and with more than <b>{{numberOfDays}} days</b> to go then RoomPriceGenie will set the price to <b>{{fixPrice}}</b> (your other rooms will be derived from this as usual). The protection price will be reduced on a daily basis for a maximum of <b>{{durationInDays}} days</b> until it meets the recommended price."
            components={{ b: <Mark fw={500} color="indigo.1" /> }}
            values={{
              roomOrApartment: hotelDetails?.room_apartment_space_name.toLowerCase(),
              numberOfBookings: watch('threshold'),
              numberOfDays: watch('days_ahead'),
              fixPrice: CurrencyFormatter.format(parseInt(watch('fix_price') as unknown as string)),
              durationInDays: watch('duration_in_days')
            }}
          />
        </Text>
      ) : null}

      {!getValues('auto_price_protection') ? (
        <Text size="md">
          <Trans
            i18nKey="When you receive <b>{{numberOfBookings}} bookings</b> for a single date within a period of 24 hours and with more than <b>{{numberOfDays}} days</b> to go then RoomPriceGenie will notify you that a Surge has been detected."
            components={{ b: <Mark fw={500} color="indigo.1" /> }}
            values={{
              numberOfBookings: watch('threshold'),
              numberOfDays: watch('days_ahead')
            }}
          />
        </Text>
      ) : null}

      {getValues('auto_price_protection') && pricingTypeTabValue === Tabs.FIXED_PRICE ? (
        <Text size="md">
          <Trans
            i18nKey="When you receive <b>{{numberOfBookings}} bookings</b> for a single date within a period of 24 hours and with more than <b>{{numberOfDays}} days</b> to go then RoomPriceGenie will set the price to <b>{{fixPrice}}</b> (your other rooms will be derived from this as usual). This price will stay for <b>{{durationInDays}} days</b> and then return to the original price set for that day, unless you edit it in which case the new price will be set."
            components={{ b: <Mark fw={500} color="indigo.1" /> }}
            values={{
              roomOrApartment: hotelDetails?.room_apartment_space_name.toLowerCase(),
              numberOfBookings: watch('threshold'),
              numberOfDays: watch('days_ahead'),
              fixPrice: CurrencyFormatter.format(parseInt(watch('fix_price') as unknown as string)),
              durationInDays: watch('duration_in_days')
            }}
          />
        </Text>
      ) : null}

      {!isAutoUploadOn && hotelDetails && getValues('auto_price_protection') ? (
        <Group gap={2} mt="sm">
          <Text size="md" className="font-semibold">
            {t('Warning!')}
          </Text>
          <Text size="sm" className="font-semibold">
            {t(
              'Whenever a Surge Event is detected, prices will be updated in your {{pmsOrChannelManager}} as soon as possible, even if you don’t have auto-upload enabled.',
              {
                pmsOrChannelManager: hotelDetails?.is_channel_manager
                  ? t('Channel Manager')
                  : t('PMS')
              }
            )}
          </Text>
        </Group>
      ) : null}
    </Box>
  );
};
