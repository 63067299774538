import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { getProfitReport, saveLastPricingUpdate } from '@common/api/hotel';
import { getDiagnosticsCsv } from '@common/api/pricingAlgorithm';
import { Button } from '@common/components/atoms/Button';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { RPGPopover } from '@common/components/molecules/Popover/Popover';
import { API_DATE_FORMAT } from '@common/constants/date';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { DateNavigation } from '@pages/Client/Calendar/components/DateNavigation';
import { DownloadCSV } from '@pages/Client/Calendar/components/DownloadCSV/DownloadCSV';
import {
  PricingOptions,
  RunPricing
} from '@pages/Client/Calendar/components/RunPricing/RunPricing';
import { PricingOptionValue } from '@common/api/pricingAlgorithm/types';
import { Badge } from '@common/components/atoms/Badge';
import { BasePusherEventResponse, PusherEventNames } from '@common/constants/pusher';
import { useModal } from '@common/hooks/useModal';
import { useUserRole } from '@common/hooks/useUserRole';
import { Feature } from '@common/store/features';
import { useViewStore } from '@common/store/view';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { usePusherStore } from '@common/store/pusher';
import { SecureNavLink } from '@pages/Client/components/SecureNavLink';
import { useRunPricingContext } from '@pages/Client/Calendar/hooks/useRunPricing';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import styles from '@pages/Client/Calendar/components/CalendarHeader.module.css';
import { EnableClientAccessModal } from '@pages/Client/Calendar/components/EnableClientAccessModal/EnableClientAccessModal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { Divider } from '@common/components/atoms/Divider';
import { useUpdatePricingSettings } from '@pages/Client/hooks/useUpdatePricingSettings';
import { ScrollArea, ScrollBar } from '@common/components/molecules/ScrollArea';
import { useDiagnosticsStore } from '@pages/Client/Calendar/store/diagnostics';
import { useLocation } from 'react-router-dom';
import { cn } from '@common/utils/cn';
import { useSidebarStore } from '@common/store/sidebar';
import { useEnableClientLoading } from '@pages/Client/Calendar/store/enableClient';
import {
  BulkEditTab,
  useBulkEditsStore
} from '@pages/Client/Calendar/components/BulkEdit/store/editAdjustmentsStore';
import { BulkEdits } from '@pages/Client/Calendar/components/BulkEdit/BulkEdits';
import { ConsolidatedUploadPricesButton } from '@pages/Client/Calendar/components/UploadPrices/ConsolidatedUploadPricesButton';
import { useEventTracking } from '@common/hooks/useEventTracking';
import { useUpselling } from '@pages/Client/hooks/useUpselling';
import { useEnablePricing } from '@pages/Client/hooks/usePricingEnable';
import { SegmentedControl, Tabs } from '@mantine/core';
import { ReportsQueryKeys } from '@common/types/query-keys';
import { useBulkAdjustmentOptions } from '@pages/Client/Calendar/hooks/useBulkAdjustmentOptions';
import { isEmpty } from 'lodash-es';
import { BulkEditUserflowIds, CalendarUserflowIds } from '@common/types/userflow-ids';

enum CalendarTab {
  Week = 0,
  Month = 1
}

export const CalendarHeader = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // Hooks, store usage
  const { hotelAuthToken } = useHotelStore();
  const { addNotification } = useNotificationsStore();
  const { setBulkEditTab, setBulkEditDrawerState } = useBulkEditsStore();
  const { pricingSettings, pricingSettingsQuery, cachePriceQuery } = useRoomPrices();
  const {
    hotelDetails,
    pmsChannelManagerText,
    query: { refetch: refetchHotelDetails, isLoading: isHotelDetailLoading }
  } = useHotelDetails();
  const { trackEvent } = useEventTracking();

  const { view, isReadOnly } = useViewStore();

  const features = pricingSettings?.features;
  const { savePricingSettings, isLoading: isSavePricingLoading } = useUpdatePricingSettings();
  const { mutate: getProfitReportMutation } = useMutation({
    mutationKey: [ReportsQueryKeys.GET_PROFIT_REPORT],
    mutationFn: getProfitReport,
    onSuccess: (data) => {
      addNotification('success', t(data.data.message));
    }
  });
  const { downloadUrl, isDiagnosticsEnabled, setDownloadUrl, setShowButton, showButton } =
    useDiagnosticsStore();

  const { isPartner } = useUserRole();
  const [isEnableSuccess, setIsEnableSuccess] = useState(false);

  // Hourly Pricing Blocks
  const {
    enablePricing,
    isLoading: isEnablePricingLoading,
    isSuccess: isPricingEnabledSuccess
  } = useEnablePricing();
  const isPricingEnabled = hotelDetails?.is_pricing_turn_on;
  const isShowTurnOnPricing = (view === 'admin' || isPartner) && !isPricingEnabled;
  // End Hourly Pricing Blocks

  const isClientAccessDisabled = (view === 'admin' || isPartner) && features?.includes(19);
  const { isEnableClientLoading } = useEnableClientLoading();

  const {
    isTableView,
    setIsTableView,
    initialRoomId,
    setSelectedHotelRoomId,
    selectedHotelRoomId,
    setInitialRoomId,
    setWeekIndex,
    liliusInstance
  } = useCalendarPageStore();
  const { setSidebarOpen } = useSidebarStore();
  const { channelBind, channelUnbind } = usePusherStore();
  const {
    runPricing,
    showRunPricingLoadingModal,
    setShowRunPricingLoadingModal,
    setProgressMessage
  } = useRunPricingContext()!;
  const {
    SparkIcon,
    isProDashboardUpselling,
    isPickupCalendarUpselling,
    setUpsellingModalOpen,
    ProEntryPoints
  } = useUpselling();

  const { adjustmentOptions } = useBulkAdjustmentOptions();

  // Modals
  const [showRunPricingModal, setShowRunPricingModal] = useState(false);
  const [showDownloadCSVModal, setDownloadCSVModal] = useState(false);
  const { isOpen: enableOpen, closeModal: enableClose, openModal: enableClick } = useModal();
  // End Modals

  const [latestRunPricingOption, setLatestRunPricingOption] = useState<PricingOptions>();

  const runPricingFormRef: ComponentProps<typeof RunPricing>['ref'] = useRef(null);
  const downloadCsvRef: ComponentProps<typeof DownloadCSV>['ref'] = useRef(null);

  const onBulkEditClick = () => {
    setBulkEditTab(BulkEditTab.EDIT);
    setBulkEditDrawerState(true);
    trackEvent('BulkEditOpened');
  };

  const onRunPricingClick = () => {
    setShowRunPricingModal(true);
  };

  const onRunPricingClose = async (isOk: boolean) => {
    if (!isOk) {
      setShowRunPricingModal(false);
      return;
    }

    if (!runPricingFormRef.current || !pricingSettings) return;

    const pricingType = runPricingFormRef.current.runPricingOption;
    setLatestRunPricingOption(pricingType);

    let endDate = dayjs().tz();

    // 3 months
    if (pricingType === 3) {
      endDate = endDate.add(95, 'days');
    }
    // 6 months
    else if (pricingType === 4) {
      endDate = endDate.add(6, 'months');
    } else if (pricingType === 5) {
      endDate = endDate.add(365, 'days');
    } else if (pricingType === 6) {
      endDate = endDate.add(18, 'months');
    }

    setShowRunPricingModal(false);

    const getLatestPMS = runPricingFormRef.current.getLatestPMS;
    const startDate = dayjs().tz().format(API_DATE_FORMAT);
    const formattedEndDate = endDate.format(API_DATE_FORMAT);

    await runPricing({
      getLatestPMS,
      startDate,
      endDate: formattedEndDate,
      pricingType
    });
  };

  const handleDownloadCsv = () => {
    downloadCsvRef.current && downloadCsvRef.current.submitDownloadCSV();
  };

  const runPricingMessageCb = async (data: BasePusherEventResponse) => {
    // The run pricing has error at inventory message event step
    if (!showRunPricingLoadingModal) return;

    try {
      if (!data.error) {
        addNotification('success', t('Data Updated Successfully'));
        setShowRunPricingLoadingModal(false);
        pricingSettingsQuery.refetch();
        cachePriceQuery.refetch();
        refetchHotelDetails();
        const pricingType = runPricingFormRef?.current?.runPricingOption;
        let endDate = dayjs().tz();

        // 3 months
        if (pricingType === 3) {
          endDate = endDate.add(95, 'days');
        }
        // 6 months
        else if (pricingType === 4) {
          endDate = endDate.add(6, 'months');
        } else if (pricingType === 5) {
          endDate = endDate.add(365, 'days');
        } else if (pricingType === 6) {
          endDate = endDate.add(18, 'months');
        }

        const startDate = dayjs().tz().format(API_DATE_FORMAT);
        const formattedEndDate = endDate.format(API_DATE_FORMAT);

        if (isDiagnosticsEnabled) {
          await getDiagnosticsCsv({
            command: 'create_diagnostic_file',
            start_date: startDate,
            end_date: formattedEndDate
          }).then((res) => {
            const { url, success } = res;
            setDownloadUrl(url);
            setShowButton(success);
          });
        }

        if (latestRunPricingOption && hotelAuthToken) {
          saveLastPricingUpdate({
            last_run_pricing_type: `${latestRunPricingOption}`,
            token: hotelAuthToken
          });
        }
      } else {
        const message =
          data.message ??
          `${t('Something went Wrong While Fetching Values from')} ${
            hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS'
          }`;
        addNotification(
          'fail',
          typeof message === 'string'
            ? message
            : message && typeof message.detail === 'string'
              ? message.detail
              : 'Something went Wrong!'
        );
      }
    } catch (e) {
      if (isAxiosError(e) && e.message) {
        addNotification('fail', e.message);
      } else {
        addNotification(
          'fail',
          `${t('Something went Wrong While Fetching Values from')} ${pmsChannelManagerText}`
        );
      }
    } finally {
      setShowRunPricingLoadingModal(false);
    }
  };

  const inventoryMessageCb = (data: BasePusherEventResponse) => {
    // Break the run pricing process on error
    if (data.error) {
      setShowRunPricingLoadingModal(false);
    } else {
      setProgressMessage(t('Optimizing...') as string);
    }
  };

  const runPricingOneYear = () => {
    setLatestRunPricingOption(PricingOptionValue.OneHalfYear);
    return runPricing({
      getLatestPMS: false,
      startDate: dayjs().tz().format(API_DATE_FORMAT),
      endDate: dayjs()
        .tz()
        .add(Feature.EighteenMonthsPricing ? 545 : 365, 'days')
        .format(API_DATE_FORMAT),
      pricingType: Feature.EighteenMonthsPricing
        ? PricingOptionValue.OneHalfYear
        : PricingOptionValue.OneYear
    });
  };

  useEffect(() => {
    channelBind(PusherEventNames.RunPricingMessage, runPricingMessageCb);
    channelBind(PusherEventNames.InventoryMessage, inventoryMessageCb);

    return () => {
      channelUnbind(PusherEventNames.RunPricingMessage, runPricingMessageCb);
      channelUnbind(PusherEventNames.InventoryMessage, inventoryMessageCb);
    };
  }, [runPricingMessageCb, inventoryMessageCb]);

  // useEffect to handle success loading
  useEffect(() => {
    if (isPricingEnabledSuccess) {
      setIsEnableSuccess(true);

      const timeoutId = setTimeout(() => {
        setIsEnableSuccess(false);
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isPricingEnabledSuccess]);

  let format = hotelDetails?.prefered_date_format;
  format = format?.replace(/([-./]?Y{2,4})/g, '').trim(); // remove year and possible separator before it
  format = format?.replace(/([-./]Y{2,4})/g, '').trim(); // remove year and possible separator after it

  const isFreeTrial = hotelDetails?.state === 0;

  const onEnableClientAccess = async () => {
    const oldFeatures = features ? features : [];
    const newFeatures = oldFeatures?.filter((number) => number !== 19);
    const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();
    const settings = {
      ...latestPricingSettings,
      features: newFeatures
    };
    await savePricingSettings(JSON.stringify(settings));
  };

  const renderCalendarMenu = () => {
    return (
      <RPGPopover.Panel className="max-w-xs px-0 pb-5 pt-4">
        <div className={styles.actionItem} onClick={() => onRunPricingClick()}>
          <Icon.TrendingUp className="h-5 w-5" />
          <Typography color="darkGrey">{t('Run Pricing')}</Typography>
        </div>
        {downloadUrl && showButton ? (
          <a href={downloadUrl} rel="noreferrer" target="_blank">
            <div className={styles.actionItem}>
              <Icon.Download className="h-5 w-5" />
              <Typography color="darkGrey">{t('Diagnostics')}</Typography>
            </div>
          </a>
        ) : null}
        <div className={styles.actionItem} onClick={() => setDownloadCSVModal(true)}>
          <Icon.Download className="h-5 w-5" />
          <Typography color="darkGrey">{t('Download CSV')}</Typography>
        </div>
        {view === 'admin' ? (
          <div className={styles.actionItem} onClick={() => getProfitReportMutation()}>
            <Icon.ChartBarThree className="h-5 w-5" />
            <Typography color="darkGrey">{t('Profit Report')}</Typography>
          </div>
        ) : null}
      </RPGPopover.Panel>
    );
  };

  return (
    <div className="calendar-header sticky top-0 z-30 bg-appBackground bg-opacity-80 backdrop-blur">
      <div className="flex flex-col">
        <div className="mt-3 flex flex-col justify-between gap-y-4 pl-4 pr-4 md:flex-row md:pl-8 md:pr-8 min-[2305px]:pr-0">
          <div className="flex flex-1 items-center space-x-4">
            <DateNavigation />
            {(view === 'admin' ||
              features?.includes(Feature.AdvancedUpload) ||
              features?.includes(Feature.UploadAllRuns)) &&
            pathname.includes('pricing') ? (
              <div className={cn(isTableView ? 'pl-1' : 'pl-12')}>
                <SegmentedControl
                  value={isTableView ? CalendarTab.Week.toString() : CalendarTab.Month.toString()}
                  data-userflow-id={CalendarUserflowIds.CALENDAR_WEEK_VIEW_ITEM}
                  data={[
                    {
                      label: (
                        <span data-userflow-id={CalendarUserflowIds.CALENDAR_WEEK_VIEW_ITEM}>
                          {t('Week')}
                        </span>
                      ),
                      value: CalendarTab.Week.toString()
                    },
                    {
                      label: t('Month'),
                      value: CalendarTab.Month.toString()
                    }
                  ]}
                  onChange={(value) => {
                    if (value === CalendarTab.Week.toString()) {
                      setIsTableView(true);
                      const today = new Date();
                      let weekIndex = 0;
                      const liliusMonth = liliusInstance?.viewing;
                      const liliusMonthStart = dayjs(liliusInstance?.viewing)
                        .startOf('month')
                        .toDate();

                      if (liliusMonth?.getMonth() === today.getMonth()) {
                        const dayDifference = today.getDate() - liliusMonthStart.getDate();
                        weekIndex = Math.round((dayDifference + 1) / 7);
                      }

                      setWeekIndex(weekIndex);
                      setSidebarOpen(false);
                      setInitialRoomId(selectedHotelRoomId);
                      trackEvent('WeekViewOpenedFromTab');
                    } else {
                      setIsTableView(false);
                      if (initialRoomId) {
                        setSelectedHotelRoomId(initialRoomId);
                      }
                    }
                  }}
                />
              </div>
            ) : null}
            {view === 'admin' ? (
              <div className="hidden md:flex">
                <Badge>Admin</Badge>
              </div>
            ) : null}
            {isReadOnly ? (
              <div className="hidden md:flex">
                <Badge>Read Only</Badge>
              </div>
            ) : null}
            {/* Actions */}
            <div className="ml-6 flex items-center">
              <RPGPopover
                className="relative flex items-center justify-self-end md:hidden"
                actionOnMouseOver
              >
                <RPGPopover.Button as={Button} icon>
                  <Icon.MoreVertical />
                </RPGPopover.Button>
                {renderCalendarMenu()}
              </RPGPopover>
            </div>
          </div>
          {isReadOnly ? null : (
            <div className="ml-0 flex w-full items-center justify-end gap-x-4 md:ml-6 md:justify-end">
              {isShowTurnOnPricing ? (
                <Button
                  intent="danger"
                  isLoading={isEnablePricingLoading}
                  isSuccess={isEnableSuccess}
                  disabled={isHotelDetailLoading}
                  onClick={() => enablePricing(true)}
                >
                  <Icon.TurnOff className="h-4 w-4" />
                  {t('Turn On Pricing')}
                </Button>
              ) : null}

              {isClientAccessDisabled ? (
                <Button
                  intent="danger"
                  onClick={() => {
                    isFreeTrial ? enableClick() : onEnableClientAccess();
                  }}
                  isLoading={isSavePricingLoading || isEnableClientLoading}
                >
                  {t('Enable Client Access')}
                </Button>
              ) : null}

              <RPGPopover
                className="relative hidden items-center justify-self-end md:flex"
                actionOnMouseOver
              >
                <RPGPopover.Button as={Button} icon>
                  <Icon.MoreVertical />
                </RPGPopover.Button>
                {renderCalendarMenu()}
              </RPGPopover>

              <Modal
                size="lg"
                open={showDownloadCSVModal}
                className="p-10"
                cancelText={t('Cancel') as string}
                okText={t('Download CSV') as string}
                onClick={handleDownloadCsv}
                onClose={() => setDownloadCSVModal(false)}
              >
                <DownloadCSV ref={downloadCsvRef} />
              </Modal>

              <ScrollArea>
                <div className="flex gap-x-2">
                  {isEmpty(adjustmentOptions) ? null : (
                    <Button
                      data-userflow-id={BulkEditUserflowIds.BULK_EDIT_BUTTON}
                      intent="outline"
                      onClick={onBulkEditClick}
                    >
                      {t('Bulk Edit')}
                    </Button>
                  )}
                  <ConsolidatedUploadPricesButton />
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </div>
          )}
        </div>
        <Tabs className={styles.tabs}>
          <div className="flex items-center gap-x-6 pl-4 pr-4 md:pl-8 md:pr-8 min-[2305px]:pr-0">
            <ScrollArea>
              <div className="flex w-full flex-1 gap-x-6 p-0.5">
                <SecureNavLink
                  to="pricing"
                  data-userflow-id={CalendarUserflowIds.PRICES_CALENDAR_NAV_ITEM}
                >
                  {({ isActive }) => (
                    <div
                      className={clsx(
                        styles.tab,
                        'min-h-[48px]',
                        isActive ? styles.active_tab : styles.inactive_tab
                      )}
                    >
                      <Icon.DiscountTag className="mr-2 inline-block h-5 w-5 align-middle" />
                      {t('Prices')}
                    </div>
                  )}
                </SecureNavLink>
                <SecureNavLink
                  to="occupancy"
                  data-userflow-id={CalendarUserflowIds.OCCUPANCY_CALENDAR_NAV_ITEM}
                >
                  {({ isActive }) => (
                    <div
                      className={clsx(
                        styles.tab,
                        'min-h-[48px]',
                        isActive ? styles.active_tab : styles.inactive_tab
                      )}
                    >
                      <Icon.Bed className="mr-2 inline-block h-5 w-5 align-middle" />
                      <div className="flex gap-x-2">{t('Occupancy')}</div>
                    </div>
                  )}
                </SecureNavLink>
                {ProEntryPoints.isPickupCalendarEntryPoint ? (
                  <SecureNavLink
                    to="pickup"
                    data-userflow-id={CalendarUserflowIds.PICKUP_CALENDAR_NAV_ITEM}
                  >
                    {({ isActive }) => (
                      <div
                        onClick={(e) => {
                          if (!isFreeTrial && isPickupCalendarUpselling) {
                            e.preventDefault();
                            return setUpsellingModalOpen(true);
                          }
                        }}
                        className={clsx(
                          styles.tab,
                          'min-h-[48px]',
                          isActive ? styles.active_tab : styles.inactive_tab,
                          'group'
                        )}
                      >
                        <Icon.UserAdd className="mr-2 inline-block h-5 w-5 align-middle" />
                        {isFreeTrial ? (
                          <div className="flex flex-shrink-0 items-center gap-x-2">
                            {t('Pickup')}
                            <Badge variant="contained">PRO</Badge>
                          </div>
                        ) : (
                          <>{t('Pickup')}</>
                        )}
                        {!isFreeTrial && isPickupCalendarUpselling ? (
                          <span className="hidden group-hover:block">
                            <SparkIcon className="ml-2" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </SecureNavLink>
                ) : null}
                {ProEntryPoints.isProDashboardEntryPoint ? (
                  <SecureNavLink
                    to="revenue"
                    data-userflow-id={CalendarUserflowIds.REVENUE_CALENDAR_NAV_ITEM}
                  >
                    {({ isActive }) => (
                      <div
                        onClick={(e) => {
                          if (!isFreeTrial && isProDashboardUpselling) {
                            e.preventDefault();
                            return setUpsellingModalOpen(true);
                          }
                        }}
                        className={clsx(
                          styles.tab,
                          'min-h-[48px]',
                          isActive ? styles.active_tab : styles.inactive_tab,
                          'group'
                        )}
                      >
                        <Icon.ChartBarThree className="mr-2 inline-block h-5 w-5 align-middle" />
                        {isFreeTrial ? (
                          <div className="flex flex-shrink-0 items-center gap-x-2">
                            {t('Revenue')}
                            <Badge variant="contained">PRO</Badge>
                          </div>
                        ) : (
                          <>{t('Revenue')}</>
                        )}
                        {!isFreeTrial && isProDashboardUpselling ? (
                          <span className="hidden group-hover:block">
                            <SparkIcon className="ml-2" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </SecureNavLink>
                ) : null}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
            <div className="flex-1" />
            <div className="hidden py-3 sm:block">
              <TooltipProvider delayDuration={200}>
                <Tooltip>
                  <TooltipTrigger>
                    <Typography variant="nav" color="copyTextGrey" className="text-opacity-60">
                      {t('Last Upload:')}{' '}
                      {!hotelDetails?.last_update_price_pms_status &&
                      hotelDetails?.last_update_price_pms !== null ? (
                        <div className="inline-flex flex-1 items-center gap-x-1.5">
                          {t('Failed')}
                          <span className="h-3 w-3 rounded-full bg-error" />
                        </div>
                      ) : hotelDetails?.last_update_price_pms ? (
                        dayjs(hotelDetails?.last_update_price_pms).tz().format(format)
                      ) : (
                        'n.A.'
                      )}
                    </Typography>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <div className="flex flex-col gap-2">
                      <span>{t('Last Pricing Run')}</span>
                      {dayjs(hotelDetails?.last_run_pricing).format(format)}
                      <Divider />
                      <span>
                        {t('Last Inventory/Price Fetch From')}{' '}
                        {t(hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS')}
                      </span>
                      {dayjs(hotelDetails?.last_inventory_price_get).format(format)}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </Tabs>
      </div>

      <BulkEdits runPricing={runPricingOneYear} />

      <Modal
        size="lg"
        open={showRunPricingModal}
        className="p-10"
        okText={t('Run Pricing') as string}
        onClose={onRunPricingClose}
      >
        <RunPricing ref={runPricingFormRef} />
      </Modal>
      <EnableClientAccessModal isOpen={enableOpen} onClose={enableClose} />
    </div>
  );
};
