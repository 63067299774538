import { inRange } from 'lodash-es';
import * as z from 'zod';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';

export const baseMinStaySchema = z.object({
  id: z.number().or(z.string()),
  value: z.union([z.number(), z.nan(), z.null()]).optional(),
  isBulkEdit: z.boolean().default(false),
  is_skip_min_stay_rule: z.boolean().default(false)
});

/**
 * Validation Logic for MinStay Schema:
 *
 * - Validates that the 'value' is within the range of 1 to 8.
 *   - If out of range, it shows 'Min Stay should be between 1 and 8'.
 *
 * - Ensures that the 'value' does not have decimal places.
 *   - If it has decimals, the message 'Value is not allowed.' is shown.
 *
 */

export const minStayZodOutputSchema = baseMinStaySchema
  .refine((val) => (val?.value && !isNaN(val.value) ? inRange(val.value, 1, 9) : true), {
    message: 'Min Stay should be between 1 and 8'
  })
  .refine((val) => validateNoDecimals(val?.value), {
    message: 'Value is not allowed.'
  });

export type MinStay = z.infer<typeof baseMinStaySchema>;
