import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { HotelClientListPagination } from '@common/api/hotel/types';
import { useClientListStore } from '@common/store/hotel-list-store';
import { ErrorResponse } from '@common/api/auth';
import { getHotelClientList } from '@common/api/hotel';
import { HotelQueryKeys } from '@common/types/query-keys';

export function useHotelClientList() {
  const { params } = useClientListStore();
  return useQuery<HotelClientListPagination, ErrorResponse>({
    queryKey: [HotelQueryKeys.HOTEL_CLIENT_LIST, params],
    queryFn: () => getHotelClientList(params),
    placeholderData: keepPreviousData
  });
}
