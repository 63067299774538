import { useEffect, useRef } from 'react';
import userflow from 'userflow.js';
import { isNil } from 'lodash-es';

interface UseResourceCenterProps {
  shouldHideResourceCenter?: boolean;
}

export const useResourceCenter = ({ shouldHideResourceCenter }: UseResourceCenterProps = {}) => {
  // Ref to track whether the resource center was open before closing
  const wasResourceCenterOpenRef = useRef(false);

  useEffect(() => {
    if (isNil(shouldHideResourceCenter)) return;

    if (shouldHideResourceCenter) {
      window.HubSpotConversations?.widget?.remove();
    } else {
      window.HubSpotConversations?.widget?.refresh();
    }

    userflow.setResourceCenterLauncherHidden(shouldHideResourceCenter);

    const resourceCenterState = userflow.getResourceCenterState();

    // If resource center is open and the modal `shouldHideResourceCenter` is true, close the resource center
    if (resourceCenterState?.isOpen && shouldHideResourceCenter) {
      userflow.closeResourceCenter();
      wasResourceCenterOpenRef.current = true; // Track that the resource center was open
    } else if (!shouldHideResourceCenter && wasResourceCenterOpenRef.current) {
      // Reopen resource center if the modal is closing and it was previously open
      userflow.openResourceCenter();
      wasResourceCenterOpenRef.current = false; // Reset tracking
    }
  }, [shouldHideResourceCenter]);

  return {
    wasResourceCenterOpen: wasResourceCenterOpenRef.current
  };
};
