import * as z from 'zod';
import { validateNoDecimals } from '@pages/Client/Calendar/components/BulkEdit/helpers/validateNoDecimals';

export const baseSchemaPercentAdjustmentSchema = z.object({
  id: z.number(),
  value: z.union([z.number(), z.nan(), z.null()]).optional(),
  isBulkEdit: z.boolean().default(false)
});

/**
 * Validation Logic for Percentage Adjustment Schema:
 *
 * - Ensures that the 'value' does not have decimal places.
 *   - If it has decimals, the message 'Value is not allowed.' is shown.
 *
 */

export const percentageAdjustmentZodOutputSchema = baseSchemaPercentAdjustmentSchema.refine(
  (val) => validateNoDecimals(val?.value),
  {
    message: 'Value is not allowed.'
  }
);

export type PercentAdjustment = z.infer<typeof baseSchemaPercentAdjustmentSchema>;
