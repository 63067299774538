import React from 'react';
import { textColors, TypographyColors } from '@common/components/foundations/colorVariants';
import { clsx } from 'clsx';

type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'paragraph-1'
  | 'paragraph-2'
  | 'paragraph-3'
  | 'meta-1'
  | 'meta-2'
  | 'meta-3'
  | 'nav'
  | 'badge';

interface TypographyProps {
  children: React.ReactNode;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
  variant?: TypographyVariant;
  color?: TypographyColors;
  className?: string;
  userflowId?: string;
}

const fontFamily: { [_K in TypographyVariant]: 'font-heading' | 'font-sans' } = {
  h1: 'font-heading',
  h2: 'font-heading',
  h3: 'font-heading',
  h4: 'font-heading',
  h5: 'font-heading',
  h6: 'font-heading',
  'paragraph-1': 'font-sans',
  'paragraph-2': 'font-sans',
  'paragraph-3': 'font-sans',
  'meta-1': 'font-sans',
  'meta-2': 'font-sans',
  'meta-3': 'font-sans',
  nav: 'font-sans',
  badge: 'font-sans'
};

const fontSize: {
  [_K in TypographyVariant]: string;
} = {
  h1: 'text-h2 2xl:text-h1',
  h2: 'text-h3 2xl:text-h2',
  h3: 'text-h4 2xl:text-h3',
  h4: 'text-h5 2xl:text-h4',
  h5: 'text-paragraph-1 font-serif md:text-h6 2xl:text-h5',
  h6: 'text-paragraph-2 font-serif md:text-h6',
  'paragraph-1': 'text-paragraph-2 2xl:text-paragraph-1',
  'paragraph-2': 'text-paragraph-3 2xl:text-paragraph-2',
  'paragraph-3': 'text-paragraph-3',
  'meta-1': 'text-meta-1',
  'meta-2': 'text-meta-2',
  'meta-3': 'text-meta-3',
  nav: 'text-[13px] 2xl:text-nav',
  badge: 'text-badge'
};

export function Typography({
  element,
  variant,
  className,
  children,
  color,
  userflowId
}: TypographyProps) {
  return React.createElement(
    element || 'span',
    {
      className: clsx(
        className,
        textColors[color || 'copyTextGrey'],
        variant ? fontFamily[variant] : 'md:font-sans',
        variant ? fontSize[variant] : 'text-[15px]'
      ),
      'data-userflow-id': userflowId
    },
    children
  );
}
