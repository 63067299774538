import {
  TextInput,
  MultiSelect,
  Divider,
  Stack,
  Flex,
  SegmentedControl,
  Group,
  Checkbox,
  MultiSelectProps,
  Box
} from '@mantine/core';
import useMinstay from '@pages/Client/PricingStrategy/MinStay/hooks/useMinstay';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { usePricingSettings } from '@pages/Client/hooks/usePricingSettings';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MinStay, MinStayRule } from '@pages/Client/PricingStrategy/MinStay/types';

enum Tabs {
  LEAD_TIME = 'single',
  LEAD_TIME_AND_OCCUPANCY = 'multiple'
}

type TabValue = (typeof Tabs)[keyof typeof Tabs];

type Props = {
  mode: 'add' | 'edit';
  ruleToEdit?: MinStayRule;
};

export const RuleForm: React.FC<Props> = ({ mode, ruleToEdit }) => {
  const { t } = useTranslation();
  const { pricingSettings } = usePricingSettings();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [tabValues, setTabValues] = useState<string[]>([]);

  const { hotelDetails } = useHotelDetails();
  const { roomTypesOption } = useMinstay();

  const { reset, control, setValue, watch } = useFormContext<MinStay>();

  const rules = pricingSettings?.minstay?.rules || [];

  const { fields: fieldFields, append: appendField } = useFieldArray({
    control: control,
    name: 'rules.0.fields'
  });

  const ROOM_TYPES_OPTION = roomTypesOption(pricingSettings?.rooms) || [];

  const handleSelectAllChange = () => {
    const allSelected = !selectAll;
    setSelectAll(allSelected);
    setValue('rules.0.rooms', allSelected ? ROOM_TYPES_OPTION.map((option) => option.value) : []);
  };

  const handleMultiSelectChange = (values: string[]) => {
    const allValues = ROOM_TYPES_OPTION.map((option) => option.value);
    const isSelectAll = values.length === allValues.length;
    setSelectAll(isSelectAll);
    setValue('rules.0.rooms', values);
  };

  const renderMultiSelectOption: MultiSelectProps['renderOption'] = ({ option, checked }) => (
    <Group gap="sm" wrap="nowrap">
      <Checkbox
        disabled={option.disabled}
        checked={checked}
        onChange={() => {}}
        aria-hidden
        tabIndex={-1}
        style={{ pointerEvents: 'none' }}
      />
      <span>{option.label}</span>
    </Group>
  );

  const handleTabChange = (index: number, value: TabValue) => {
    const newTabValues = [...tabValues];
    newTabValues[index] = value;
    setTabValues(newTabValues);

    setValue(`rules.0.fields.${index}.type`, value);
    if (value === Tabs.LEAD_TIME) {
      setValue(`rules.0.fields.${index}`, {
        type: 'single',
        field: 'lead_time',
        value: '',
        comparison: '<',
        priority: index + 1,
        action: 'remove',
        min_stay_value: null
      });
    } else {
      setValue(`rules.0.fields.${index}.statement`, [
        { field: 'lead_time', value: '', comparison: '<' },
        { field: 'occupancy', value: '', comparison: '<' }
      ]);
    }
  };

  useEffect(() => {
    if (mode === 'add') {
      reset({
        rules: [
          {
            name: '',
            priority: rules ? rules.length + 1 : 1,
            rooms: []
          }
        ]
      });
      appendField({
        type: Tabs.LEAD_TIME,
        field: 'lead_time',
        comparison: '<',
        value: '',
        priority: 1,
        action: 'remove',
        min_stay_value: null
      });
      setTabValues([Tabs.LEAD_TIME]);
      setSelectAll(true);
      handleSelectAllChange();
    }

    if (mode === 'edit' && ruleToEdit) {
      reset({
        rules: [
          {
            name: ruleToEdit.name,
            rooms: ruleToEdit.rooms,
            fields: ruleToEdit.fields,
            priority: ruleToEdit.priority
          }
        ]
      });
      const allValues = ROOM_TYPES_OPTION.map((option) => option.value);
      const isSelectAll = ruleToEdit.rooms.length === allValues.length;
      setSelectAll(isSelectAll);
      setTabValues(ruleToEdit.fields.map((field) => field.type));
    }
  }, [ruleToEdit, mode]);

  return (
    <>
      <Controller
        name="rules.0.name"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TextInput
            placeholder={t('Please enter a name') as string}
            error={error?.message}
            variant="filled"
            label={t('Name')}
            value={value}
            onChange={onChange}
            withAsterisk
          />
        )}
      />
      <Group wrap="nowrap">
        <Box w="75%">
          <Controller
            name="rules.0.rooms"
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <MultiSelect
                label={t(`Please select ${hotelDetails?.room_apartment_space_name} Types`)}
                variant="filled"
                data={ROOM_TYPES_OPTION}
                value={value}
                onChange={handleMultiSelectChange}
                placeholder={
                  t(`Please select ${hotelDetails?.room_apartment_space_name} Types`) as string
                }
                error={error?.message}
                w="100%"
                withAsterisk
                renderOption={renderMultiSelectOption}
              />
            )}
          />
        </Box>
        <Box w="25%">
          <Checkbox
            label={t('Select all')}
            checked={selectAll}
            indeterminate={!selectAll && watch('rules.0.rooms')?.length > 0}
            onChange={handleSelectAllChange}
          />
        </Box>
      </Group>
      <Divider />
      {fieldFields.map((fieldItem, fieldIndex) => (
        <Stack key={fieldItem.id}>
          <Flex justify="space-between" align="center">
            <SegmentedControl
              value={tabValues[fieldIndex]}
              data={[
                {
                  label: t('Days to go'),
                  value: Tabs.LEAD_TIME
                },
                {
                  label: t('Occupancy'),
                  value: Tabs.LEAD_TIME_AND_OCCUPANCY
                }
              ]}
              onChange={(value) => handleTabChange(fieldIndex, value as TabValue)}
            />
          </Flex>
          {tabValues[fieldIndex] === Tabs.LEAD_TIME ? (
            <Controller
              name={`rules.0.fields.${fieldIndex}.value`}
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextInput
                  placeholder={
                    t('Please enter the number of days before the arrival date') as string
                  }
                  error={error?.message}
                  variant="filled"
                  label={t('Remove all restrictions when there are this many days to go')}
                  value={value}
                  onChange={onChange}
                  inputMode="numeric"
                  withAsterisk
                />
              )}
            />
          ) : (
            <>
              <Controller
                name={`rules.0.fields.${fieldIndex}.statement.0.value`} // Adjust the index as needed
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextInput
                    placeholder={
                      t('Please enter the number of days before the arrival date') as string
                    }
                    error={error?.message}
                    variant="filled"
                    label={t('When there are this many days to go')}
                    value={value}
                    onChange={onChange}
                    inputMode="numeric"
                    withAsterisk
                  />
                )}
              />
              <Controller
                name={`rules.0.fields.${fieldIndex}.statement.1.value`} // Adjust the index as needed
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextInput
                    placeholder={
                      t('Please enter the occupancy of the property as a percentage') as string
                    }
                    error={error?.message}
                    variant="filled"
                    label={t(
                      'Remove all restrictions if occupancy for the property is less than this percentage'
                    )}
                    value={value}
                    onChange={onChange}
                    inputMode="numeric"
                    withAsterisk
                    rightSection="%"
                  />
                )}
              />
            </>
          )}
        </Stack>
      ))}
    </>
  );
};
