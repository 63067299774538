import { HotelUsers } from '@common/api/account/types';
import { Badge } from '@common/components/atoms/Badge';
import { Button } from '@common/components/atoms/Button';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Table } from '@common/components/molecules/Table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useModal } from '@common/hooks/useModal';
import { AddNewScheduleReportsModal } from '@pages/Client/Account/ScheduleReports/components/AddNewScheduleReportsModal';
import { DeleteScheduleReportsModal } from '@pages/Client/Account/ScheduleReports/components/DeleteScheduleReportsModal';
import { EditScheduleReportsModal } from '@pages/Client/Account/ScheduleReports/components/EditScheduleReportsModal';
import { TableHeaderScheduleReports } from '@pages/Client/Account/ScheduleReports/components/TableHeaderScheduleReports';
import {
  useDeleteScheduleReports,
  useGenerateScheduleReports,
  useScheduleReportsList
} from '@pages/Client/Account/ScheduleReports/hooks/useScheduleReports';
import { useGetHotelUsers } from '@pages/Client/hooks/useGetHotelUsers';
import { includes, isArray, isEmpty, truncate } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ScheduleReports = () => {
  const { t } = useTranslation();

  const {
    hotelUsers,
    query: { isLoading: isGetHotelUsersLoading }
  } = useGetHotelUsers();

  const { isOpen: addOpen, openModal: addClick, closeModal: addClose } = useModal();
  const { isOpen: deleteOpen, openModal: deleteClick, closeModal: deleteClose } = useModal();
  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();

  const { schedule_reports, isLoading: isScheduleReportListLoading } = useScheduleReportsList();
  const { deleteScheduleReports } = useDeleteScheduleReports();
  const { generateScheduleReports, isLoading: generateScheduleReportsLoading } =
    useGenerateScheduleReports({ onComplete: undefined });

  interface Report {
    id: number;
    name: string;
    report_type: string;
    mode: string;
    frequency: string;
    accounting_mode: string;
    pickup_tab_options: null;
    financials_tab_options: null;
    receipient_user_ids: number[];
    hotel: number;
  }

  const [scheduleReportRowSelected, setScheduleReportRowSelected] = useState<Report>();

  function getUserNames(userIds: number[] | undefined, users: HotelUsers[] | undefined): string {
    if (isEmpty(userIds) || !isArray(userIds) || isEmpty(users) || !isArray(users)) {
      return '';
    }
    const result = users
      .filter((user) => includes(userIds, user.id))
      .map((user) => `${user.first_name.trim()} ${user.last_name.trim()}`)
      .join(', ');
    return result;
  }

  const USER_OPTIONS = hotelUsers?.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name} (${truncate(user.email, { length: 15 })}`
  }));

  useEffect(() => {
    if (deleteOpen) {
      editClose();
    }
  });

  const columns = [
    {
      header: t('Report Name'),
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: t('Report Type'),
      accessorKey: 'report_type'
    },

    {
      header: t('Frequency'),
      accessorKey: 'frequency',
      cell: (row: any) => {
        const bgColor =
          row.row.original.frequency === 'DAILY'
            ? 'orange'
            : row.row.original.frequency === 'WEEKLY'
            ? 'darkGreen'
            : 'indigo';
        return <Badge background={bgColor}>{row.getValue()}</Badge>;
      }
    },
    {
      header: t('Accounting Mode'),
      accessorKey: 'accounting_mode',
      meta: {
        maxWidth: '200px'
      },
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row.getValue()}
            </Typography>
          </div>
        );
      }
    },
    {
      header: t('Recipients'),
      accessorKey: 'receipient_user_ids',
      meta: {
        maxWidth: '200px'
      },
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {getUserNames(row.getValue(), hotelUsers)}
            </Typography>
          </div>
        );
      }
    },
    {
      header: t('Actions'),
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Button
              icon
              onClick={() => {
                setScheduleReportRowSelected(row.row.original);
                deleteClick();
              }}>
              <Icon.Delete className="text-copyTextGrey" />
            </Button>
            <TooltipProvider delayDuration={200}>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    icon
                    isLoading={
                      row.row.original?.id === scheduleReportRowSelected?.id &&
                      generateScheduleReportsLoading
                    }
                    onClick={(e) => {
                      // To prevent general row click action
                      e.stopPropagation();
                      setScheduleReportRowSelected(row.row.original);
                      row.row.original?.id && generateScheduleReports([row.row.original?.id]);
                    }}>
                    <Icon.Send className="text-copyTextGrey" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="bottom" className="max-w-xs">
                  {t('Send Scheduled Report to recipients now')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      }
    }
  ];
  return (
    <div>
      <Table
        skeletonCount={10}
        isFetching={isScheduleReportListLoading || isGetHotelUsersLoading}
        columns={columns}
        data={isScheduleReportListLoading ? [] : schedule_reports.results}
        isHover={true}
        headerComponent={<TableHeaderScheduleReports onAddNewSchedule={addClick} />}
        onClickRow={(row: any) => {
          setScheduleReportRowSelected(row.row.original);
          editClick();
        }}
      />
      <AddNewScheduleReportsModal userOptions={USER_OPTIONS} isOpen={addOpen} onClose={addClose} />
      <EditScheduleReportsModal
        data={scheduleReportRowSelected as Report}
        userOptions={USER_OPTIONS}
        isOpen={editOpen}
        onClose={editClose}
      />
      <DeleteScheduleReportsModal
        onDelete={() => {
          const reportId = scheduleReportRowSelected?.id;
          if (reportId !== undefined) {
            deleteScheduleReports(reportId);
          }
          deleteClose();
        }}
        title={scheduleReportRowSelected?.name}
        isOpen={deleteOpen}
        onClose={deleteClose}
      />
    </div>
  );
};
