import {
  DetailPartnerApiResponse,
  ParamsIntegrationPartner,
  ParamsIntegrationPartnerSchema
} from '@common/api/integration';
import { Typography } from '@common/components/foundations/Typography';
import { Icon } from '@common/components/foundations/icons';
import { Header } from '@common/components/molecules/Header/Header';
import { Table } from '@common/components/molecules/Table';
import { Page } from '@common/components/organisms/Page';
import { useModal } from '@common/hooks/useModal';
import { filterEmptyValues } from '@common/utils/filterEmptyValues';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon, Badge, Button, Flex, TextInput } from '@mantine/core';
import { useDisclosure, useDocumentTitle } from '@mantine/hooks';
import { DeleteIntegrationPartnerModal } from '@pages/Admin/IntegrationPartner/components/DeleteIntPartnerModal';
import { IntegrationPartnerModal } from '@pages/Admin/IntegrationPartner/components/IntegrationPartnerModal';
import { useGetIntPartners } from '@pages/Admin/IntegrationPartner/hooks/useIntegrationPartner';
import { useSelectedPartnerStore } from '@pages/Admin/IntegrationPartner/store/intpartners';
import { IconX } from '@tabler/icons-react';
import { SortingState, Updater } from '@tanstack/react-table';
import { debounce } from 'lodash-es';
import React, { Fragment, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Params } from 'react-router-dom';

export const IntegrationPartner = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Integration Partners'));

  const [pageParams, setPageParams] = React.useState<number>(1);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false }]);

  const [selectedPartner, setSelectedPartner] = React.useState<
    DetailPartnerApiResponse['data'] | undefined
  >(undefined);
  const [
    isIntegrationPartnerModalOpen,
    { open: integrationPartnerModalOpen, close: integrationPartnerModalClose }
  ] = useDisclosure(false);

  const [
    isDeleteIntegrationPartnerModalOpen,
    { open: deleteIntegrationPartnerModalOpen, close: deleteIntegrationPartnerModalClose }
  ] = useDisclosure(false);

  const { setSelectedPartnerId } = useSelectedPartnerStore();

  const { isOpen: editOpen, openModal: editClick, closeModal: editClose } = useModal();

  const defaultValues = {
    page: 1,
    search: undefined,
    ordering: undefined
  };

  const { watch, reset, control, getValues, setValue } = useForm<ParamsIntegrationPartner>({
    defaultValues,
    resolver: zodResolver(ParamsIntegrationPartnerSchema)
  });

  const onResetAndSet = useCallback(
    (field: keyof Params, value: Params[typeof field]) => {
      reset(
        filterEmptyValues({
          ...defaultValues,
          [field]: value
        })
      );
    },
    [reset, filterEmptyValues, defaultValues]
  );

  const debouncedSearch = debounce((value) => onResetAndSet('search', value), 1000);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const {
    intPartnerList,
    query: { isLoading, isFetching }
  } = useGetIntPartners(
    filterEmptyValues({
      page: watch('page'),
      search: getValues('search'),
      ordering: watch('ordering')
    })
  );

  const columns: any[] = [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (row: any) => {
        return (
          <div className="flex items-center gap-3">
            <Typography element="span" color="copyTextGrey">
              {row?.getValue() as string}
            </Typography>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Status',
      accessorKey: 'is_active',
      cell: (row: any) => {
        const isActive = row?.getValue() as boolean;
        return (
          <Badge color={isActive ? 'green.9' : 'red.9'}>{isActive ? 'Active' : 'Inactive'}</Badge>
        );
      },
      meta: {
        showOnMobile: true
      }
    },
    {
      header: 'Delete',
      accessorKey: 'actions',
      cell: (row: any) => {
        const isActive = row.row.original.is_active;
        return (
          <div className="flex items-center gap-3">
            <ActionIcon
              disabled={isActive}
              color="gray.0"
              onClick={() => {
                deleteIntegrationPartnerModalOpen();
                setSelectedPartner(row?.row?.original);
              }}>
              <Icon.Delete className="h-5 w-5 text-copyTextGrey" />
            </ActionIcon>
          </div>
        );
      },
      meta: {
        showOnMobile: true
      }
    }
  ];

  const ROWS_PER_PAGE = 20;

  const startIndex = intPartnerList?.metadata?.current
    ? (intPartnerList?.metadata?.current - 1) * ROWS_PER_PAGE + 1
    : 0;
  const endIndex =
    intPartnerList?.metadata?.current && intPartnerList?.metadata.count
      ? intPartnerList?.metadata.count
      : 0;

  const handleNext = () => {
    if (!intPartnerList?.metadata?.next) return;
    const params = new URLSearchParams(intPartnerList?.metadata.next.split('?')[1]);
    const page = Number(params.get('page'));
    const search = params.get('search');
    const ordering = params.get('ordering');
    if (!page) return;
    setPageParams(Number(page));
    reset(
      filterEmptyValues({
        page,
        search,
        ordering
      })
    );
  };

  const handlePrevious = () => {
    const previousPage = pageParams - 1;
    if (previousPage <= 0) return;
    reset(
      filterEmptyValues({
        ...watch(),
        page: previousPage
      })
    );
  };

  const orderingLookup: Record<string, string> = {
    id: 'id',
    name: 'name',
    email: 'email',
    is_active: 'is_active'
  };

  const handleSortingChange = (updater: Updater<SortingState>) => {
    const newSorting = typeof updater === 'function' ? updater(sorting) : updater;
    const columnIndex = sorting.findIndex((s) => s.id === newSorting[0].id);

    if (columnIndex >= 0) {
      newSorting[0].desc = !sorting[columnIndex].desc;
    }

    const ordering = newSorting[0].desc
      ? `-${orderingLookup[newSorting[0].id]}`
      : orderingLookup[newSorting[0].id];

    onResetAndSet('ordering', ordering);
    setSorting(newSorting);
  };

  return (
    <Page header={<Header title={t('Integration Partner')} />}>
      <Table
        isHover
        isRowClickable
        columns={columns}
        skeletonCount={20}
        isFetching={isLoading || isFetching}
        headerComponent={<Fragment />}
        actionComponents={
          <Flex gap="sm">
            <Controller
              name="search"
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <TextInput
                  placeholder="Search"
                  name={name}
                  id={name}
                  type="text"
                  value={value as string}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    onChange(e);
                  }}
                  rightSection={
                    value && (
                      <ActionIcon
                        size="xs"
                        variant="subtle"
                        onClick={() => {
                          reset(defaultValues);
                          setValue('page', 1);
                          setValue('search', '');
                        }}>
                        <IconX className="text-grey" />
                      </ActionIcon>
                    )
                  }
                />
              )}
            />
            <Button onClick={integrationPartnerModalOpen}>Add New Partner</Button>
          </Flex>
        }
        data={!isLoading ? (intPartnerList?.data as any) : []}
        next={handleNext}
        isNext={!!intPartnerList?.metadata?.next}
        previous={handlePrevious}
        isPrevious={pageParams > 1}
        count={`${startIndex}-${endIndex}`}
        totalCount={intPartnerList?.metadata?.count}
        manualPagination={true}
        rowsPerPage={ROWS_PER_PAGE}
        onClickRow={(row) => {
          if (row.column.id === 'actions') return;
          editClick();
          setSelectedPartner(row?.row?.original);
          setSelectedPartnerId(row?.row?.original.id);
        }}
        manualSorting
        onSortingChange={handleSortingChange}
        sorting={sorting}
      />
      <IntegrationPartnerModal
        mode="add"
        isOpen={isIntegrationPartnerModalOpen}
        onClose={integrationPartnerModalClose}
      />
      <IntegrationPartnerModal mode="edit" isOpen={editOpen} onClose={editClose} />
      <DeleteIntegrationPartnerModal
        selectedPartner={selectedPartner}
        isOpen={isDeleteIntegrationPartnerModalOpen}
        onClose={deleteIntegrationPartnerModalClose}
      />
    </Page>
  );
};
