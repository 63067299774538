import { Input } from '@common/components/atoms/Input';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { useAuthStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import {
  numberRegex,
  PasswordValidationMessage,
  specialCharRegex
} from '@common/constants/password';
import { useAddNewPartnerUser } from '@pages/Admin/PartnerUsers/hooks/useAddPartnerUser';
import { useGetResellerList } from '@common/hooks/useResellerPartner';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
};

const schema = z.object({
  token: z.string().or(z.undefined()).nullable(),
  reseller: z.number(),
  user_data: z.object({
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    password: z
      .string()
      .min(8, PasswordValidationMessage.Length)
      .max(50)
      .refine((value) => /[A-Z]/.test(value), {
        message: PasswordValidationMessage.UppercaseLetter,
        path: ['password']
      })
      .refine((value) => numberRegex.test(value), {
        message: PasswordValidationMessage.Number,
        path: ['password']
      })
      .refine((value) => specialCharRegex.test(value), {
        message: PasswordValidationMessage.SpecialChar,
        path: ['password']
      })
  })
});

export const AddNewPartnerUserModal = ({ isOpen, onClose }: ModalProps) => {
  const { token } = useAuthStore();
  const { createPartnerUser } = useAddNewPartnerUser();
  const [showPassword, setShowPassword] = useState(false);

  const { reseller } = useGetResellerList();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    watch
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      token
    }
  });

  const RESELLER_OPTIONS = reseller?.map((partner) => ({
    label: partner?.name,
    value: partner?.id
  }));

  const getRefineErrors = () => {
    if ((errors?.user_data?.password as any)?.password) {
      return (errors?.user_data?.password as any).password.message;
    }
    return '';
  };

  const onSubmit = (data: any) => {
    createPartnerUser(data);
  };

  return (
    <Modal
      okText="Save"
      continueIntent="primary"
      size="xl"
      open={isOpen}
      disableCloseOnOk={!isValid}
      onClick={handleSubmit(onSubmit)}
      onClose={() => {
        onClose();
        reset();
      }}
      children={
        <div>
          <Typography className="mb-8" element="h6" color="darkGrey" variant="h6">
            Add New Partner User
          </Typography>
          <div className="flex w-full justify-between gap-4 ">
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="user_data.first_name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="text"
                    value={value}
                    label="First Name *"
                    placeholder="Please enter first name"
                    onChange={onChange}
                    onClear={() => setValue('user_data.first_name', '')}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <Controller
                name="user_data.email"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    name="subject"
                    background="grey"
                    type="email"
                    value={value}
                    label="Email *"
                    placeholder="Please enter email"
                    onChange={onChange}
                    onClear={() => setValue('user_data.email', '')}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
            </div>
            <div className="flex w-1/2 flex-col gap-4">
              <Controller
                name="user_data.last_name"
                control={control}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <Input
                    name={name}
                    background="grey"
                    type="text"
                    value={value}
                    label="Last Name *"
                    placeholder="Please enter last name"
                    onChange={onChange}
                    onClear={() => setValue('user_data.last_name', '')}
                    error={!!error}
                    helperMessage={
                      error && (
                        <div className="flex items-center gap-2 text-error">
                          {error ? <Icon.WarningOutline className="w-4" /> : ''}
                          <Typography element="span" color="error" variant="meta-2">
                            {error ? error.message?.toString() : ''}
                          </Typography>
                        </div>
                      )
                    }
                  />
                )}
              />
              <div>
                <div className="mb-1 flex items-center gap-1 ">
                  <TooltipProvider delayDuration={75}>
                    <Tooltip>
                      <TooltipTrigger type="button">
                        <Icon.Info className="h-5 w-5 fill-grey" />
                      </TooltipTrigger>
                      <TooltipContent side="bottom" className="max-w-xs">
                        1. Minimum of 8 characters. <br /> 2. Must include at least 1 number (0-9).{' '}
                        <br /> 3. Requires at least 1 uppercase letter (A-Z). <br /> 4. Must include
                        at least 1 special character (@,#,$,etc.).
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <Typography className="text-meta-1 text-grey" color="copyTextGrey">
                    Password*
                  </Typography>
                </div>
                <Controller
                  name="user_data.password"
                  control={control}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <Input
                      name={name}
                      background="grey"
                      showClearButton={false}
                      type={showPassword ? 'text' : 'password'}
                      value={value}
                      placeholder="Please enter password"
                      onChange={onChange}
                      error={!!error}
                      helperMessage={
                        errors ? (
                          <div className="flex items-center gap-2 text-error">
                            {errors?.user_data?.password ? (
                              <Icon.WarningOutline className="w-4" />
                            ) : (
                              ''
                            )}
                            <Typography element="span" color="error" variant="meta-2">
                              {errors?.user_data?.password
                                ? errors?.user_data?.password?.message?.toString()
                                : ''}
                              {(errors?.user_data?.password as any)?.password &&
                              watch('user_data.password').length >= 8
                                ? getRefineErrors()
                                : ''}
                            </Typography>
                          </div>
                        ) : null
                      }
                      trailingAddon={
                        <button type="button" onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <Icon.Visible className="h-5 w-5" />
                          ) : (
                            <Icon.Hidden className="h-5 w-5" />
                          )}
                        </button>
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 flex flex-col items-start">
            <Typography element="p" variant="meta-1" className="mb-1 text-grey">
              Partner*
            </Typography>
            <Controller
              name="reseller"
              control={control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <>
                  <SelectDropdown
                    name={name}
                    fullWidth
                    value={value}
                    placeholder="Select Partner"
                    options={RESELLER_OPTIONS}
                    onChange={(value) => onChange(value)}
                  />
                  <div className="mt-1">
                    {error && (
                      <div className="flex items-center gap-2 text-error">
                        {error ? <Icon.WarningOutline className="w-4" /> : ''}
                        <Typography element="span" color="error" variant="meta-2">
                          {error ? error?.message?.toString() : ''}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </div>
      }
    />
  );
};
