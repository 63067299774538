import { useClerk } from '@clerk/clerk-react';
import { useForm, zodResolver } from '@mantine/form';
import {
  ActionIcon,
  Button,
  Center,
  Code,
  CopyButton,
  Flex,
  Loader,
  Paper,
  PinInput,
  rem,
  Title,
  Tooltip,
  Text,
  Grid
} from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { isNil, join, map, truncate } from 'lodash-es';
import QRCode from 'react-qr-code';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const totpSchema = z.object({
  code: z.string().length(6)
});

type TotpSchema = z.infer<typeof totpSchema>;

type Totp = {
  secret: string;
  uri: string;
};

export default function ClerkOTPStartPage() {
  const clerk = useClerk();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totp, setTotp] = useState<Totp | null>(null);
  const [verified, setVerified] = useState<boolean>(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);

  const form = useForm({
    validate: zodResolver(totpSchema),
    initialValues: { code: '' }
  });

  useEffect(() => {
    async function generateTotp() {
      try {
        const totp = await clerk?.user?.createTOTP();
        if (totp?.secret && totp?.uri) setTotp({ secret: totp.secret, uri: totp.uri });
      } catch (err: any) {
        console.log(err.errors[0].longMessage);
      }
    }

    if (isNil(totp)) generateTotp();
  }, [totp]);

  async function handleConfirm(data: TotpSchema) {
    try {
      setIsLoading(true);
      const verified = await clerk?.user?.verifyTOTP({ code: String(data.code) });
      if (verified?.verified && verified?.backupCodes) {
        setVerified(true);
        setBackupCodes(verified.backupCodes);
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log(err.errors[0].longMessage);
    }
  }

  async function handleSavedKeys() {
    setIsLoading(true);
    navigate(`/admin/clients/login`);
  }

  return (
    <Center w="100%">
      {verified ? (
        <Paper maw="500px" p="xl">
          <Title order={2}>{t('Great, please save these')}</Title>
          <Text visibleFrom="sm">
            {t(`Please save the backup codes below in a safe place. You can use these codes to access
            your account if you lose access to your authenticator app. You will not be able to log
            in if you lose access to your authenticator app and do not have these backup codes.`)}
          </Text>
          <Paper p="md" my="md">
            <Grid>
              {map(backupCodes, (code) => (
                <Grid.Col span={3} key={code}>
                  <Center>
                    <Code px="xs">{code}</Code>
                  </Center>
                </Grid.Col>
              ))}
            </Grid>
          </Paper>
          <Flex justify="space-between" align="center" mt="md">
            <CopyButton value={join(backupCodes, '\n')} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy Backup Codes'} withArrow position="right">
                  <Button onClick={copy} variant="light">
                    <Flex align="center" gap="md">
                      {t('Copy Backup Codes')}
                      {copied ? (
                        <IconCheck style={{ width: rem(16) }} />
                      ) : (
                        <IconCopy style={{ width: rem(16) }} />
                      )}
                    </Flex>
                  </Button>
                </Tooltip>
              )}
            </CopyButton>
            <Button onClick={handleSavedKeys}>{t('I have saved these codes')}</Button>
          </Flex>
        </Paper>
      ) : (
        <Paper maw="400px" p="xl">
          <Title order={2}>{t('Let us enable 2FA')}</Title>
          <Text visibleFrom="sm">
            {t(`To ensure the safety of your account, please scan the QR code with your authenticator
            app to generate a 6-digit code.`)}
          </Text>
          <Center>
            <Paper p="md" bg="white" w="200px" h="180px" my="md">
              <Center h="100%">
                {isNil(totp?.uri) ? <Loader /> : <QRCode value={totp?.uri || ''} level="H" />}
              </Center>
            </Paper>
          </Center>

          <>
            <Text>{t('Trouble scanning? Use the setup key to manually configure your app:')}</Text>

            {isNil(totp?.secret) ? (
              <Loader />
            ) : (
              <Flex>
                <Code>{truncate(totp?.secret, { length: 16 })}</Code>
                <CopyButton value={totp?.secret || ''} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                      <ActionIcon onClick={copy} variant="subtle">
                        {copied ? (
                          <IconCheck style={{ width: rem(16) }} />
                        ) : (
                          <IconCopy style={{ width: rem(16) }} />
                        )}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </Flex>
            )}
            <Title order={4} my="md">
              {t('Verify the code from you app below:')}
            </Title>
            <form onSubmit={form.onSubmit(handleConfirm)}>
              <PinInput
                autoFocus
                inputType="tel"
                inputMode="numeric"
                autoCapitalize="off"
                length={6}
                size="md"
                disabled={isLoading}
                {...form.getInputProps('code')}
              />
              <Button type="submit" mt="md" loading={isLoading}>
                {t('Verify')}
              </Button>
            </form>
          </>
        </Paper>
      )}
    </Center>
  );
}
