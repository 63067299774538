import { pmsLookup } from '@common/constants/pmsList';
import { useAuthStore } from '@common/store/auth';
import { useFeaturesStore } from '@common/store/features';
import { getPlanTier } from '@common/utils/getPlanTier';
import { getPlanType } from '@common/utils/getPlanType';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useFeatureDescription } from '@common/hooks/useFeatureDescription';
import { fromPairs, toPairs, map, kebabCase } from 'lodash-es';
import { useEffect, useState } from 'react';
import { EventNames, getEventInfo } from '@common/constants/tracked-events';
import { usePostHog } from 'posthog-js/react';

type TagKey = Lowercase<string>;
type Tags = Record<TagKey, string | number | boolean>;

export const useTrackingProperties = (isEnabled?: boolean) => {
  const { user } = useAuthStore();
  const { hotelDetails } = useHotelDetails(isEnabled);

  const pmsIntegrationType: {
    [key: number]: string;
  } = {
    1: 'one-way',
    2: 'two-way'
  };

  const isPartnerUser = user?.sub_account_type === 2;
  const isSalesUser = user?.sub_account_type === 1;

  const userType = isPartnerUser
    ? 'partner'
    : isSalesUser
    ? 'sales'
    : user?.is_staff
    ? 'admin'
    : 'user';

  // Use useState to manage trackingProperties
  const [trackingProperties, setTrackingProperties] = useState<{ [key: string]: string }>();
  const [profileProperties, setProfileProperties] = useState<{ [key: string]: string }>();

  useEffect(() => {
    if (!hotelDetails || !user) return;

    const adminProfileProperties = {
      'user-type': userType
    };

    const newTrackingProperties = {
      'user-type': userType,
      'hotel-id': hotelDetails.id.toString(),
      'hotel-location': hotelDetails.country.name,
      pms: pmsLookup[hotelDetails.pms_provider],
      integration: pmsIntegrationType[hotelDetails.pms_sync],
      plan: getPlanTier(hotelDetails.plan),
      state: getPlanType(hotelDetails.state),
      'auto-upload': hotelDetails.is_update_to_pms ? 'on' : 'off',
      'type-of-unit': hotelDetails.room_apartment_space_name
    };

    const profileProperties = newTrackingProperties;

    // Update state with the new tracking properties
    setTrackingProperties(newTrackingProperties);
    setProfileProperties(
      userType === 'admin' || userType === 'sales' || userType === 'partner'
        ? adminProfileProperties
        : profileProperties
    );
  }, [hotelDetails, user]);

  return {
    trackingProperties,
    profileProperties
  };
};

export const useEventTracking = ({
  isHotelAccountLoaded
}: { isHotelAccountLoaded?: boolean } = {}) => {
  const { features: featuresFromStore } = useFeaturesStore();
  const posthog = usePostHog();
  const { trackingProperties } = useTrackingProperties(isHotelAccountLoaded);
  const { featureDescriptions } = useFeatureDescription(isHotelAccountLoaded);

  const trackEvent = (
    eventName: EventNames,
    { tags }: { description?: string; tags?: Tags } = {}
  ) => {
    const eventInfo = getEventInfo(eventName);
    const { event } = eventInfo;

    if (!trackingProperties) return;

    const enabledFeatures = fromPairs(
      map(toPairs(featureDescriptions), ([key, value]) => {
        if (value === '18 months pricing') {
          return [
            kebabCase('eighteen-months-pricing'),
            featuresFromStore?.includes(Number(key)) ? 'enabled' : undefined
          ];
        }
        return [kebabCase(value), featuresFromStore?.includes(Number(key)) ? 'enabled' : undefined];
      })
    );

    // PostHog tracking
    posthog?.capture(event, {
      ...tags,
      $set: {
        ...trackingProperties,
        ...enabledFeatures
      }
    });
  };

  return {
    trackEvent
  };
};
