export const Visible = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9939 4.99959C16.3881 4.95443 20.3582 7.61605 21.9878 11.6996C22.0041 11.7989 22.0041 11.9002 21.9878 11.9996C21.9911 12.1126 21.9706 12.225 21.9279 12.3296C20.2967 16.3808 16.3588 19.0248 11.9939 18.9996C7.62406 19.0216 3.68479 16.3687 2.05997 12.3096C2.01986 12.2112 1.99948 12.1059 2.00001 11.9996C2.00129 11.887 2.02156 11.7754 2.05997 11.6696C3.69114 7.61836 7.629 4.97434 11.9939 4.99959ZM8.99575 11.9996C8.99575 13.6564 10.3381 14.9996 11.9939 14.9996C12.7891 14.9996 13.5517 14.6835 14.1139 14.1209C14.6762 13.5583 14.9921 12.7952 14.9921 11.9996C14.9921 10.3427 13.6498 8.99959 11.9939 8.99959C10.3381 8.99959 8.99575 10.3427 8.99575 11.9996Z"
      />
    </svg>
  );
};
