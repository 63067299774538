import { searchMarketCode } from '@common/api/hotel';
import { SearchMarketCode } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { useMutation } from '@tanstack/react-query';

export const useSearchMarketCode = () => {
  const { addNotification } = useNotificationsStore();

  const {
    mutate,
    isPending: isLoading,
    data
  } = useMutation({
    mutationFn: (payload?: SearchMarketCode) => {
      return searchMarketCode(payload);
    },
    onError: () => {
      addNotification('fail', 'Failed to to fetch 20 AirDNA Data');
    }
  });

  return {
    searchMarketCode: (payload?: SearchMarketCode) => mutate(payload || undefined),
    marketCodeData: data?.data?.data,
    isLoading
  };
};
