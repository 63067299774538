import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import React from 'react';
import { ChartValue } from '@pages/Client/PricingStrategy/MarketIntelligence/types/chart';
import { MarketIntelligenceChart } from '@pages/Client/PricingStrategy/MarketIntelligence/components/MarketIntelligenceChart';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  data: ChartValue[];
  hotelName?: string;
  graphTitle?: string;
};

export const MarketIntelligenceChartModal = ({
  isOpen,
  onClose,
  data,
  hotelName,
  graphTitle = 'CLEANED GRAPH'
}: ModalProps) => {
  return (
    <Modal
      okText="Save Price"
      continueIntent="primary"
      showFooter={false}
      size="full"
      open={isOpen}
      onClose={onClose}>
      <div className="p-4">
        <div className="my-2 flex flex-col  gap-4">
          <Typography variant="h5" element="h5" color="darkGrey">
            {graphTitle}
          </Typography>
          <Typography variant="h6" element="h6" color="darkGrey">
            <span className="text-copyTextGrey">Hotel Name:</span> {hotelName}
          </Typography>
        </div>
        <MarketIntelligenceChart xAxisVisible isTooltipEnabled data={data} height={500} />
      </div>
    </Modal>
  );
};
