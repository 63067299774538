import { getSupportTeam } from '@common/api/account';
import { UserListQueryKeys } from '@common/types/query-keys';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useSupportTeamList = () => {
  const query = useInfiniteQuery({
    queryKey: [UserListQueryKeys.GET_SUPPORT_TEAM],
    queryFn: ({ pageParam = 1 }) => getSupportTeam(pageParam),
    getNextPageParam: (lastPage) => {
      const nextPageUrl = lastPage.metadata.next;
      if (nextPageUrl) {
        const url = new URL(nextPageUrl.toString());
        const pageParam = url.searchParams.get('page');
        return pageParam ? Number(pageParam) : undefined;
      }
      return undefined;
    },
    initialPageParam: 1
  });

  return {
    query,
    isGetSupportTeamLoading: query.isLoading || query.isFetching,
    supportTeams: query.data?.pages.flatMap((page) => page.data) || [],
    fetchNextPage: query.fetchNextPage,
    hasNextPage: query.hasNextPage
  };
};
