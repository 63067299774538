import { deleteHotelScrape } from '@common/api/hotel';
import { useNotificationsStore } from '@common/store/notifications';
import { ScrapeQueryKeys } from '@common/types/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeleteHotelScrape = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (id: number) => {
      return deleteHotelScrape(id);
    },
    onSuccess: () => {
      addNotification('success', 'Update Error Ticket Successfully');
      queryClient.invalidateQueries({ queryKey: [ScrapeQueryKeys.GET_HOTEL_SCRAPE_LIST] });
    },
    onError: (error: any) => {
      const { competitor_list } = error.response.data;
      if (competitor_list) {
        addNotification('fail', competitor_list.message);
      } else {
        addNotification('fail', 'Failed to delete hotel scrape');
      }
    }
  });

  return {
    deleteHotelScrape: mutate,
    isLoading
  };
};
