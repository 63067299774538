import { applyIndividualAdjustment, savePricingSettings } from '@common/api/hotel';
import { PricingSettings, Room } from '@common/api/hotel/types';
import { deactivateSurgeEvent, getSurgeEvents, getSurgeLogs } from '@common/api/pricingAlgorithm';
import { SurgeLogResponse } from '@common/api/pricingAlgorithm/types';
import { API_DATE_FORMAT } from '@common/constants/date';
import { Feature, useFeaturesStore } from '@common/store/features';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useMutation, keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { produce } from 'immer';
import { isNil } from 'lodash-es';
import { PricingQueryKeys, SurgeEventQueryKeys } from '@common/types/query-keys';

type SurgeEventLogsParams = {
  page?: number;
  ordering?: string;
  startDate?: string;
  endDate?: string;
};

export const useSurgeEventLogs = ({ page, ordering, startDate, endDate }: SurgeEventLogsParams) => {
  const { data, isLoading, error, isFetching, refetch } = useQuery<SurgeLogResponse>({
    queryKey: [SurgeEventQueryKeys.GET_SURGE_LOGS, page, ordering, startDate, endDate],
    queryFn: ({ signal }) => {
      return getSurgeLogs(signal, page, ordering, startDate, endDate);
    },
    retry: false,
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData
  });

  return {
    surgeLogs: data,
    isLoading,
    isFetching,
    error,
    refetch
  };
};

export const useSurgeEvents = (isEnabled?: boolean) => {
  const { addNotification } = useNotificationsStore();
  const { features } = useFeaturesStore();
  const { sortedHotelRooms } = useHotelRoomsList(isEnabled);
  const { pricingSettings, cachePriceQuery, pricingSettingsQuery } = useRoomPrices(isEnabled);
  const { data: cachePrice } = cachePriceQuery;
  const queryClient = useQueryClient();

  const { mutateAsync: removeSurgePricesMutation } = useMutation({
    mutationKey: [SurgeEventQueryKeys.REMOVE_SURGE_PRICES],
    mutationFn: savePricingSettings,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PricingQueryKeys.GET_PRICING_SETTINGS] });
    }
  });

  const { mutateAsync: deactivateSurgeEventMutation } = useMutation({
    mutationKey: [SurgeEventQueryKeys.DEACTIVATE_SURGE_EVENT],
    mutationFn: deactivateSurgeEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SurgeEventQueryKeys.GET_SURGE_EVENTS] });
      queryClient.invalidateQueries({ queryKey: [SurgeEventQueryKeys.GET_SURGE_LOGS] });
    }
  });

  const { data, isLoading, error, isFetching, isError } = useQuery<SurgeLogResponse>({
    queryKey: [SurgeEventQueryKeys.GET_SURGE_EVENTS],
    queryFn: () => getSurgeEvents(),
    enabled: Boolean(isEnabled)
  });

  if (isError) addNotification('fail', 'Failed to retrieve surge logs.');

  const hasSurgeProtectionFeatures = features?.includes(Feature.SurgeProtection);

  const getSurgeEventByDay = (day: Date) => {
    if (!data || !hasSurgeProtectionFeatures) return;

    const key = dayjs(day).format(API_DATE_FORMAT);
    return data.results.find((item) => item.date === key);
  };

  const surgeDays =
    data?.results
      .filter((surgeEvent) => surgeEvent.active)
      .map((surgeEvent) => {
        const hasSurgePrices = sortedHotelRooms.some((room) => {
          const surgePriceInPricingSetting =
            pricingSettings?.dates?.[surgeEvent.date]?.[room.id]?.surge_protection;
          const recommendedPrice =
            cachePrice?.data?.prices?.data?.[surgeEvent.date]?.[room.id]?.price;
          const isSurgeNotEmailOnly =
            surgePriceInPricingSetting && surgePriceInPricingSetting === recommendedPrice;

          return isSurgeNotEmailOnly;
        });

        const hasDecayStep = sortedHotelRooms.some((room) => {
          const surgeEventConfig = surgeEvent.configurations;
          const decayStep = surgeEventConfig?.decay_step?.[room.id];
          return decayStep > 0;
        });

        return {
          date: surgeEvent.date,
          hasSurgePrices,
          hasDecayStep
        };
      }) ?? [];

  const surgeDaysWithDecayStep = surgeDays.filter((surgeDay) => surgeDay.hasDecayStep);

  function removeSurgePrices(
    pricingSettings: PricingSettings | undefined,
    sortedHotelRooms: Room[],
    currentViewingDate: Dayjs
  ): PricingSettings | undefined {
    if (!pricingSettings) return undefined;

    return produce(pricingSettings, (draft) => {
      const formattedDate = currentViewingDate.format(API_DATE_FORMAT);

      // Iterate over each room and remove surge_protection
      for (const room of sortedHotelRooms) {
        if (draft.dates?.[formattedDate]?.[room.id]?.surge_protection) {
          delete draft.dates[formattedDate][room.id].surge_protection;
        }
      }
    });
  }

  const handleDismissSurgeEvent = async (
    surgeEventId: number | undefined,
    currentViewingDate: Dayjs
  ) => {
    try {
      const { data: latestPricingSettings } = await pricingSettingsQuery.refetch();

      if (surgeEventId) {
        await deactivateSurgeEventMutation(surgeEventId);
      }

      const newPricingSettings = removeSurgePrices(
        latestPricingSettings,
        sortedHotelRooms,
        currentViewingDate
      );

      if (isNil(newPricingSettings)) throw new Error('Invalid new prices');

      await removeSurgePricesMutation(JSON.stringify(newPricingSettings));

      await applyIndividualAdjustment({
        is_write_to_cache: true,
        start_date: currentViewingDate.startOf('day').format(API_DATE_FORMAT),
        end_date: currentViewingDate.endOf('day').format(API_DATE_FORMAT),
        json_settings: JSON.stringify(newPricingSettings)
      });

      await Promise.all([cachePriceQuery.refetch(), pricingSettingsQuery.refetch()]);
    } catch (error) {
      console.error('Error dismissing surge event:', error);
    }
  };

  return {
    surgeEvents: data,
    getSurgeEventByDay,
    isLoading,
    isFetching,
    error,
    surgeDays,
    surgeDaysWithDecayStep,
    handleDismissSurgeEvent
  };
};
