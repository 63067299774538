import { setAuthToken } from '@common/api/axiosInstance';
import { connectChargebee } from '@common/api/hotel';
import { ConnectChargebeePayload } from '@common/api/hotel/types';
import { useHotelStore } from '@common/store/auth';
import { useNotificationsStore } from '@common/store/notifications';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useMutation } from '@tanstack/react-query';

export const useConnectChargebee = () => {
  const addNotification = useNotificationsStore((state) => state.addNotification);
  const { hotelAuthToken } = useHotelStore();
  const {
    query: { refetch }
  } = useHotelDetails();

  return useMutation({
    mutationFn: async (data: ConnectChargebeePayload) => {
      setAuthToken(hotelAuthToken);
      return connectChargebee(data);
    },
    onSuccess: () => {
      addNotification('success', 'Successfully connected to Chargebee');
      refetch();
    },
    onError: () => {
      addNotification('fail', 'Failed to connect to Chargebee');
    }
  });
};
