export function ArrowBackward(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path d="M22 11.4999V12.4999C22 12.776 21.7761 12.9999 21.5 12.9999H5.33L9.78 17.4399C9.87466 17.5337 9.9279 17.6615 9.9279 17.7949C9.9279 17.9282 9.87466 18.056 9.78 18.1499L9.07 18.8499C8.97612 18.9445 8.84832 18.9978 8.715 18.9978C8.58168 18.9978 8.45388 18.9445 8.36 18.8499L2.22 12.7199C2.07931 12.5793 2.00018 12.3887 2 12.1899V11.8099C2.0023 11.6114 2.08112 11.4216 2.22 11.2799L8.36 5.14985C8.45388 5.0552 8.58168 5.00195 8.715 5.00195C8.84832 5.00195 8.97612 5.0552 9.07 5.14985L9.78 5.85985C9.87406 5.95202 9.92707 6.07816 9.92707 6.20985C9.92707 6.34154 9.87406 6.46769 9.78 6.55985L5.33 10.9999H21.5C21.7761 10.9999 22 11.2237 22 11.4999Z" />
    </svg>
  );
}
