import { updateErrorTicket, updateMultipleTickets } from '@common/api/errors';
import { UpdateTicketPayload, UpdateMultipleTicketsPayload } from '@common/api/errors/types';
import { useNotificationsStore } from '@common/store/notifications';
import { ErrorTicketsQueryKeys } from '@common/types/query-keys';
import { useUpdateMultipleTicketStore } from '@pages/Admin/ErrorTickets/store/updateMultiple';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateTicket = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: ([payload, id]: [UpdateTicketPayload, number | undefined]) => {
      return updateErrorTicket(payload, id);
    },
    onSuccess: () => {
      addNotification('success', 'Update Error Ticket Successfully');
      queryClient.invalidateQueries({ queryKey: [ErrorTicketsQueryKeys.ERROR_TICKET_LIST] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Error Ticket');
    }
  });

  return {
    updateErrorTicket: mutate,
    isLoading
  };
};

export const useUpdateMultipleTickets = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();
  const { setUpdateMultipleTicketSuccess } = useUpdateMultipleTicketStore();

  const {
    mutate,
    isPending: isLoading,
    isSuccess
  } = useMutation({
    mutationFn: (payload: UpdateMultipleTicketsPayload) => {
      return updateMultipleTickets(payload);
    },
    onSuccess: () => {
      addNotification('success', 'Update Error Ticket Successfully');
      queryClient.invalidateQueries({ queryKey: [ErrorTicketsQueryKeys.ERROR_TICKET_LIST] });
      setUpdateMultipleTicketSuccess(true);
    },
    onError: () => {
      addNotification('fail', 'Failed to Update Error Ticket');
    }
  });

  return {
    updateMultipleErrorTickets: mutate,
    isLoading,
    isSuccess
  };
};
