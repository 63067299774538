export function CallTalking(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.49989V3.49989C12.9976 3.3613 13.0518 3.22771 13.15 3.12989C13.2597 3.03324 13.4044 2.9862 13.55 2.99989C17.5489 3.26654 20.7333 6.45102 21 10.4499C21.0082 10.5887 20.9615 10.7252 20.87 10.8299C20.7722 10.9281 20.6386 10.9822 20.5 10.9799H19.5C19.2388 10.9807 19.0209 10.7803 19 10.5199C18.7722 7.5708 16.4291 5.22773 13.48 4.99989C13.2117 4.98915 12.9998 4.76841 13 4.49989ZM13.17 7.13989C13.0608 7.23582 12.9987 7.37453 13 7.51989V8.51989C12.9959 8.75893 13.165 8.96601 13.4 9.00989C14.2041 9.15708 14.8376 9.77876 15 10.5799C15.0439 10.8149 15.251 10.984 15.49 10.9799H16.49C16.6354 10.9812 16.7741 10.9191 16.87 10.8099C16.9641 10.6992 17.0077 10.5541 16.99 10.4099C16.7289 8.64973 15.3494 7.26621 13.59 6.99989C13.4428 6.98764 13.2974 7.03854 13.19 7.13989H13.17ZM19.09 20.4099C18.7142 20.7879 18.203 21.0003 17.67 20.9999H17C15.3293 20.9928 13.6866 20.5701 12.22 19.7699L11.77 19.5199C8.66075 17.8482 6.11169 15.2991 4.44 12.1899L4.19 11.7399C3.41014 10.2816 3.00144 8.65363 3 6.99989V6.32989C2.99958 5.79684 3.21196 5.28569 3.59 4.90989L5.28 3.21989C5.44413 3.05449 5.67581 2.97476 5.90696 3.00415C6.13811 3.03353 6.34248 3.16868 6.46 3.36989L8.71 7.22989C8.93753 7.62278 8.87183 8.11964 8.55 8.43989L6.66 10.3299C6.50304 10.4851 6.46647 10.7249 6.57 10.9199L6.92 11.5799C8.17704 13.9083 10.0893 15.8171 12.42 17.0699L13.08 17.4299C13.275 17.5334 13.5148 17.4968 13.67 17.3399L15.56 15.4499C15.8802 15.1281 16.3771 15.0624 16.77 15.2899L20.63 17.5399C20.8312 17.6574 20.9664 17.8618 20.9957 18.0929C21.0251 18.3241 20.9454 18.5558 20.78 18.7199L19.09 20.4099Z"
        fill="currentColor"
      />
    </svg>
  );
}
