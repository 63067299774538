import { getCompetitorSetup, getTopTwentyCompetitorSetup } from '@common/api/hotel';
import { GetTopTwentyCompetitor } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { MarketIntelligenceQueryKeys } from '@common/types/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export const useCompetitorSetup = () => {
  const { addNotification } = useNotificationsStore();
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: [MarketIntelligenceQueryKeys.GET_COMPETITOR_SETUP],
    queryFn: () => {
      return getCompetitorSetup();
    },
    retry: false,
    refetchInterval
  });

  // TODO: add back
  // if (isError) addNotification('fail', 'Failed to get competitor setup');

  useEffect(() => {
    const dataPercentage = data?.data.complete_percentage as number;
    const interval = dataPercentage !== 100 ? 120000 : false;
    setRefetchInterval(interval);
  }, [data]);

  return {
    competitorSetup: data?.data,
    isLoading,
    refetch,
    error
  };
};

export const useGetTopTwenty = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: GetTopTwentyCompetitor) => {
      return getTopTwentyCompetitorSetup(data);
    },
    onSuccess: () => {
      addNotification('success', 'Success get 20 top competitor');
      queryClient.invalidateQueries({
        queryKey: [MarketIntelligenceQueryKeys.GET_HOTEL_COMPETITOR]
      });
    },
    onError: (error: any) => {
      addNotification('fail', error.response.data.message);
    }
  });

  return {
    getTopTwenty: mutate,
    isLoading
  };
};
