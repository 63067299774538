import { LineTable } from '@common/components/molecules/LineTable/LineTable';
import { useSurgeManageModal } from '@common/store/surgeModal';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { compact, find, isNil, map } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useGetAccountHotel } from '@pages/Client/hooks/useGetAccountHotel';
import { useEffect, useState } from 'react';
import { DATE_FORMAT_WITH_DAY } from '@common/constants/date';
import { localeDateFormat } from '@pages/Client/Calendar/utils/localeDateFormat';
import { formatDateWithoutTime } from '@common/utils/formatDateWithoutTime';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Center, Checkbox, Group, ScrollArea, Text } from '@mantine/core';
import { RoomPriceDataTooltipTable } from '@pages/Client/PricingStrategy/SurgeProtection/components/RoomPriceDataTooltipTable';

export interface MappedSurgeEvent {
  id: number;
  date: string;
  reservationCount: number;
  timeStamp: string;
  reservationId: string;
  isActive: boolean;
  expiryDatetime: string;
}

interface SurgeEvent {
  id: number;
  date: string;
  reservation_count?: number;
  timestamp?: string;
  reservation_id?: string | null;
  active?: boolean;
  expiry_datetime?: string;
}

interface RoomPriceData {
  roomId: string;
  date: string;
  price: number;
  name: string;
}

function RoomPricePopoverContent({ data }: { data: RoomPriceData[] }): JSX.Element {
  const referencePrice = find(data, { is_reference_room: true }) as RoomPriceData;

  if (!referencePrice) return <>n.A.</>;

  return (
    <Group gap="sm" justify="flex-end">
      <Text size="sm" w="45px" ta="right" ml={-10}>
        {CurrencyFormatter.format(referencePrice?.price)}
      </Text>
      <RoomPriceDataTooltipTable date={referencePrice.date} />
    </Group>
  );
}

export function SurgeBulkManage({ isLoading }: { isLoading: boolean }): JSX.Element {
  const { t } = useTranslation();
  const {
    hasSurgeEvents,
    eventsToDismiss,
    addEventToDismiss,
    removeEventToDismiss,
    addAllEventsToDismiss,
    clearEventsToDismiss
  } = useSurgeManageModal();
  const { roomPrices } = useRoomPrices();
  const { sortedHotelRooms } = useHotelRoomsList();
  const { data: hotel, isLoading: isLoadingHotelAccount } = useGetAccountHotel();
  const { surgeEvents } = useSurgeEvents(!isLoadingHotelAccount);
  const [mappedSurgeEvents, setMappedSurgeEvents] = useState<MappedSurgeEvent[]>([]);

  useEffect(() => {
    if (hasSurgeEvents && surgeEvents?.results) {
      const mappedEvents: MappedSurgeEvent[] = [];
      map(surgeEvents.results, (event: SurgeEvent) => {
        mappedEvents.push({
          id: event.id,
          date: event?.date,
          reservationCount: event?.reservation_count || 0,
          timeStamp: dayjs(event?.timestamp).format(
            hotel?.hotel?.prefered_date_format || 'YYYY-MM-DD'
          ),
          reservationId: event?.reservation_id || '',
          isActive: event?.active || false,
          expiryDatetime: event.expiry_datetime || ''
        });
      });
      setMappedSurgeEvents(mappedEvents);
    } else {
      setMappedSurgeEvents([]);
    }
  }, [hasSurgeEvents, surgeEvents, hotel]);

  function handleAddEventToDismiss(event: MappedSurgeEvent) {
    addEventToDismiss(event);
  }

  function handleRemoveEventToDismiss(event: MappedSurgeEvent) {
    removeEventToDismiss(event);
  }

  function handleAddAllEventsToDismiss() {
    addAllEventsToDismiss(mappedSurgeEvents);
  }

  function handleRemoveAllEventsToDismiss() {
    clearEventsToDismiss();
  }

  function getRoomsDataArray(event: MappedSurgeEvent): RoomPriceData[] {
    const data = roomPrices?.prices?.data?.[event?.date];
    return data
      ? compact(
          map(data, (value, roomId) => {
            const room = find(sortedHotelRooms, { id: Number(roomId) });
            return room
              ? {
                  roomId,
                  date: event?.date,
                  price: value.price,
                  name: room.name,
                  is_reference_room: room.is_reference_room
                }
              : null;
          })
        )
      : [];
  }

  const eventsToDismissLength = eventsToDismiss.length;

  return (
    <div className="px-4">
      <LineTable>
        <thead>
          <tr>
            <th className="w-2/12  ">
              <Center w="100%">
                <Checkbox
                  disabled={isLoading}
                  labelPosition="right"
                  styles={{
                    label: {
                      fontSize: '12px',
                      minWidth: '80px',
                      textAlign: 'left'
                    }
                  }}
                  label={eventsToDismissLength > 0 ? t(`Select None`) : t('Select All')}
                  checked={eventsToDismissLength > 0}
                  onChange={() =>
                    eventsToDismissLength > 0
                      ? handleRemoveAllEventsToDismiss()
                      : handleAddAllEventsToDismiss()
                  }
                  indeterminate={
                    eventsToDismissLength > 0 && eventsToDismissLength < mappedSurgeEvents.length
                  }
                />
              </Center>
            </th>
            <th className="w-2/12  pl-2">{t('Surge Date')}</th>
            <th className="w-2/12  text-center">{t('Reservations in last 24 hours')}</th>
            <th className="ml-3 w-2/12  text-center">{t('Current Reference Room Price')}</th>
          </tr>
        </thead>
      </LineTable>
      <ScrollArea h="calc(100dvh - 230px)">
        <LineTable>
          <tbody>
            {map(mappedSurgeEvents, (event: MappedSurgeEvent) => {
              const setAsDismiss = !isNil(find(eventsToDismiss, { id: event.id }));
              const roomsDataArray = getRoomsDataArray(event);
              const displayDate = `${
                hotel?.hotel?.prefered_date_format ? `${dayjs(event.date).format('ddd')}, ` : null
              } ${localeDateFormat(
                dayjs(event.date).toDate(),
                dayjs(event.date).format(
                  formatDateWithoutTime(hotel?.hotel?.prefered_date_format ?? DATE_FORMAT_WITH_DAY)
                )
              )}`;

              return (
                <tr key={event?.id}>
                  <td className="w-2/12 ">
                    <Center>
                      <Checkbox
                        disabled={isLoading}
                        checked={setAsDismiss}
                        onChange={() =>
                          setAsDismiss
                            ? handleRemoveEventToDismiss(event)
                            : handleAddEventToDismiss(event)
                        }
                      />
                    </Center>
                  </td>
                  <td className="w-2/12  pl-2">{displayDate}</td>
                  <td className="w-2/12  text-center">{event?.reservationCount}</td>
                  <td className="w-2/12  text-center">
                    <RoomPricePopoverContent data={roomsDataArray} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </LineTable>
      </ScrollArea>
    </div>
  );
}
