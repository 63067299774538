import { getScheduleOnDemand } from '@common/api/amalgamation';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { useQuery } from '@tanstack/react-query';

interface QueryParameters {
  page?: number;
  search?: string;
  status?: number;
}

export const useOnDemandSchedule = ({ page = 1, search, status }: QueryParameters) => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, error, isError } = useQuery({
    queryKey: [UtilQueryKeys.GET_ON_DEMAND_LIST, page, search, status],
    queryFn: () => getScheduleOnDemand(page, search, status),
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get ondemand schedule list');

  return {
    onDemandScheduleLists: data?.data,
    isLoading,
    error
  };
};
