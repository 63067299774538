export function Edit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9024 3.43923L20.5609 4.0979C20.8416 4.37837 20.9995 4.75888 20.9999 5.15577V5.91424C21.0049 6.31803 20.8466 6.70672 20.5609 6.99206L18.7151 8.8084C18.6215 8.90287 18.4939 8.956 18.3609 8.956C18.2279 8.956 18.1004 8.90287 18.0067 8.8084L15.2032 5.96414C15.013 5.77011 15.013 5.45958 15.2032 5.26555L17.009 3.43923C17.2943 3.15347 17.6829 2.99512 18.0866 3.00011H18.8448C19.2416 3.00046 19.622 3.15841 19.9024 3.43923ZM16.8195 10.6846C17.0096 10.4906 17.0096 10.1801 16.8195 9.98603L13.966 7.1717C13.8723 7.07724 13.7448 7.0241 13.6118 7.0241C13.4788 7.0241 13.3513 7.07724 13.2576 7.1717L5.20608 15.2255C5.01142 15.4184 4.85859 15.6494 4.75711 15.9041L3.04105 20.2354C2.94383 20.4602 3.02415 20.7224 3.23061 20.8541C3.37201 20.9953 3.58402 21.0385 3.76938 20.9639L8.09945 19.2074C8.35403 19.1059 8.58498 18.953 8.7779 18.7583L16.8195 10.6846Z"
      />
    </svg>
  );
}
