import { Header } from '@common/components/molecules/Header/Header';
import { Page } from '@common/components/organisms/Page';
import { useDocumentTitle } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const ErrorTicketsLayout = () => {
  const { t } = useTranslation();
  useDocumentTitle('Error Tickets');
  const subMenuLink = [
    {
      id: 'support-critical',
      label: t('Support Critical'),
      to: 'support-critical'
    },
    {
      id: 'support-other',
      label: t('Support Other'),
      to: 'support-other'
    },
    {
      id: 'dev-critical',
      label: t('Dev Critical'),
      to: 'dev-critical'
    },
    {
      id: 'dashboard-critical',
      label: t('Dashboard Support Critical'),
      to: 'dashboard-critical'
    },
    {
      id: 'dashboard-other',
      label: t('Dashboard Dev Critical'),
      to: 'dashboard-other'
    }
  ];
  return (
    <Page header={<Header title="Error Ticket List" tabs={subMenuLink} />}>
      <Outlet />
    </Page>
  );
};
