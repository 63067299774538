import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { MultiSelect } from '@common/components/atoms/MultiSelect';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { useHotelStore } from '@common/store/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { usePmsProvider } from '@pages/Client/hooks/usePmsProvider';
import { useHotelDetails } from '@pages/Client/hooks/useHotelDetails';
import { useCreateRoomRatesStore } from '@pages/Client/Account/Integrations/store/createRoomRate';
import { usePmsRoomType } from '@pages/Client/Account/Integrations/hooks/usePmsRoomType';
import { useCreatePmsRoomRate } from '@pages/Client/Account/Integrations/hooks/usePmsRoomRate';
import { CreateRatePlanPayload } from '@common/api/hotel/types';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  rate_id: z.string().trim().min(1, { message: 'Required' }),
  description: z.string().nullable(),
  room_types: z.array(z.any()).optional(),
  other_rate: z.string().nullable().optional()
});

export const AddNewRoomRateModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { hotelDetails } = useHotelDetails();
  const { setCreateLoading } = useCreateRoomRatesStore();
  const { pmsRoomTypeList } = usePmsRoomType();
  const pmsOrChannelManager = hotelDetails?.is_channel_manager ? 'Channel Manager' : 'PMS';
  const { hotelAuthToken } = useHotelStore();
  const { createRoomRate, isLoading } = useCreatePmsRoomRate();
  const { hasIndependentRates } = usePmsProvider();

  function getSchema(hasIndependentRates: boolean) {
    return hasIndependentRates
      ? schema.extend({
          room_types: z.array(z.any()).nonempty({ message: 'Room type is required' })
        })
      : schema;
  }
  const roomRateSchema = getSchema(!hasIndependentRates());

  const { handleSubmit, control, setValue, formState, reset } = useForm<
    z.infer<typeof roomRateSchema>
  >({
    resolver: zodResolver(roomRateSchema),
    defaultValues: {
      name: '',
      rate_id: '',
      description: '',
      room_types: [],
      other_rate: ''
    }
  });

  const pmsProviderId = hotelDetails?.pms_provider ? hotelDetails?.pms_provider : 0;

  const roomTypeOptions = pmsRoomTypeList?.map((item) => ({
    value: item.id,
    label: item.name
  }));

  useEffect(() => {
    if (isLoading) {
      setCreateLoading(true);
    } else {
      setCreateLoading(false);
    }
  }, [isLoading]);

  const onSubmit = async (data: z.infer<typeof roomRateSchema>) => {
    const payload = {
      ...data,
      token: hotelAuthToken
    };
    await createRoomRate(payload as CreateRatePlanPayload);
    reset();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      onClick={handleSubmit(onSubmit)}
      disableCloseOnOk={!formState.isValid}
      okText={`${t('Save')}`}
      size={'xl'}
      open={isOpen}>
      <div>
        <div className="flex flex-col items-start gap-4">
          <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
            {t(`Create New Rate`)}
          </Typography>

          <div className="flex w-full items-start justify-between gap-4">
            <div className="w-1/2 flex-1 items-start">
              <Controller
                name="rate_id"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    background="grey"
                    tabIndex={1}
                    label={`${t('Rate Plan Code')}*`}
                    placeholder={`${t('Please enter Rate Plan Code')}`}
                    value={value || ''}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={
                            error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                          }
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                    onClear={() => {
                      setValue('rate_id', '');
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/2 flex-1 items-start">
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    background="grey"
                    tabIndex={1}
                    label={`${t('Rate Plan Name')}*`}
                    placeholder={`${t('Please enter Rate Plan Name')}`}
                    value={value || ''}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={
                            error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                          }
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                    onClear={() => {
                      setValue('name', '');
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex w-full items-start justify-between gap-4">
            <div className="w-1/2 flex-1 items-start">
              <Controller
                name="room_types"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <>
                    <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                      {t(`${pmsOrChannelManager} Room Type`)} {!hasIndependentRates() ? '*' : ''}
                    </Typography>
                    <MultiSelect
                      fullWidth
                      options={roomTypeOptions}
                      placeholder={`${t(`Select ${pmsOrChannelManager} Room`)}`}
                      value={value}
                      onChange={onChange}
                      disabled={hasIndependentRates()}
                      inputClassName="py-[14px]"
                      name="room_types"
                    />
                    {invalid && (
                      <InputHelperMessage
                        icon={error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null}
                        message={error?.message}
                      />
                    )}
                  </>
                )}
              />
            </div>
            <div className="w-1/2 flex-1 items-start">
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    background="grey"
                    tabIndex={1}
                    label={`${t('Description')}*`}
                    placeholder={`${t('Please enter Description')}`}
                    value={value || ''}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={
                            error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                          }
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                    onClear={() => {
                      setValue('description', '');
                    }}
                  />
                )}
              />
            </div>
          </div>
          {pmsProviderId === 22 ? (
            <div className="w-full flex-1 items-start">
              <Controller
                name="other_rate"
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                  <Input
                    disabled={false}
                    type="text"
                    background="grey"
                    tabIndex={1}
                    label={`${t('Rate Flex Table')}`}
                    placeholder={`${t('Please enter Rate Flex Table')}`}
                    value={value || ''}
                    error={invalid}
                    helperMessage={
                      invalid && (
                        <InputHelperMessage
                          icon={
                            error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                          }
                          message={error?.message}
                        />
                      )
                    }
                    onChange={onChange}
                    onClear={() => {
                      setValue('other_rate', '');
                    }}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
