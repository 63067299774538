import { createNote, editNote, deleteNote, getNotes } from '@common/api/hotel';
import { NotesResponse } from '@common/api/hotel/types';
import { useNotificationsStore } from '@common/store/notifications';
import { UtilQueryKeys } from '@common/types/query-keys';
import { EditNote } from '@pages/Client/Notes/types/note';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useCreateNote = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (note: string) => {
      return createNote(note);
    },
    onSuccess: () => {
      addNotification('success', 'Create Note Successfully');
      queryClient.invalidateQueries({ queryKey: [UtilQueryKeys.HOTEL_NOTES] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Create Note');
    }
  });

  return {
    createNote: mutate,
    isLoading
  };
};

export const useEditNote = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (payload: EditNote) => {
      return editNote(payload.id, payload.note);
    },
    onSuccess: () => {
      addNotification('success', 'Edit Note Successfully');
      queryClient.invalidateQueries({ queryKey: [UtilQueryKeys.HOTEL_NOTES] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Edit Note');
    }
  });

  return {
    editNote: mutate,
    isLoading
  };
};

export const useDeleteNote = () => {
  const { addNotification } = useNotificationsStore();
  const queryClient = useQueryClient();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (id: number) => {
      return deleteNote(id);
    },
    onSuccess: () => {
      addNotification('success', 'Delete Note Successfully');
      queryClient.invalidateQueries({ queryKey: [UtilQueryKeys.HOTEL_NOTES] });
    },
    onError: () => {
      addNotification('fail', 'Failed to Delete Note');
    }
  });

  return {
    deleteNote: mutate,
    isLoading
  };
};

export const useHotelNotes = () => {
  const { addNotification } = useNotificationsStore();

  const { data, isLoading, isError, error } = useQuery<NotesResponse>({
    queryKey: [UtilQueryKeys.HOTEL_NOTES],
    queryFn: () => {
      return getNotes();
    },
    retry: false
  });

  if (isError) addNotification('fail', 'Failed to get notes');

  return {
    hotelNotes: data,
    isLoading,
    error
  };
};
