export function AccountCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5ZM12 18.9868C14.2151 18.9868 16.2994 17.9384 17.62 16.16C17.8246 15.8609 17.8514 15.4745 17.69 15.15L17.46 14.68C16.9568 13.6531 15.9136 13.0015 14.77 13H9.23C8.07012 13.0012 7.01488 13.671 6.52 14.72L6.31 15.16C6.15251 15.4818 6.17921 15.8633 6.38 16.16C7.70056 17.9384 9.78493 18.9868 12 18.9868Z"
      />
    </svg>
  );
}
