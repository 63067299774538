import { EditOwner } from '@common/api/account/types';
import { Button } from '@common/components/atoms/Button';
import { Input } from '@common/components/atoms/Input';
import { InputHelperMessage } from '@common/components/atoms/InputHelperMessage';
import { SelectDropdown } from '@common/components/atoms/Select/SelectDropdown';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { Modal } from '@common/components/molecules/Modal';
import { LANGUAGES } from '@common/constants/language';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useOwnerUpdate } from '@pages/Client/Account/User/hooks/useUser';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClick?: () => void;
  children?: React.ReactNode;
  selectedUser?: EditOwner;
};

export const EditOwnerModal = ({ isOpen, onClose, selectedUser }: Props) => {
  const { t } = useTranslation();
  const schema = z.object({
    email: z.string().email({ message: `${t('Please enter a valid email address')}` }),
    first_name: z.string().nonempty({ message: `${t('Please enter  first name')}` }),
    last_name: z.string().nonempty({ message: `${t('Please enter last name')}` }),
    language: z.string().nonempty({ message: `${t('Please enter a valid email address')}` })
  });
  const { handleSubmit, control, setValue } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { mutate: handleUpdateOwner } = useOwnerUpdate();

  useEffect(() => {
    setValue('email', selectedUser?.email as string);
    setValue('first_name', selectedUser?.first_name as string);
    setValue('last_name', selectedUser?.last_name as string);
    setValue('language', selectedUser?.language as string);
  }, [selectedUser]);

  const onUpdate = (data: any) => {
    handleUpdateOwner(data);
    onClose();
  };

  return (
    <Modal open={isOpen} showFooter={false} onClose={onClose} size="custom">
      <div className="min-w-[480px] max-w-[480px] rounded-xl bg-white">
        <Button
          type="button"
          icon
          className="absolute right-6 top-6 z-30 translate-x-[50%] translate-y-[-50%] cursor-pointer bg-lightGrey"
          onClick={() => onClose()}>
          <Icon.Clear className="h-5 w-5" />
        </Button>
        <div className="relative flex flex-col items-start gap-4 p-5">
          <div className="flex w-full flex-col gap-4">
            <Typography className="mb-2" element="h6" color="darkGrey" variant="h6">
              {t('Edit')}: {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
            </Typography>
            <div className="w-full items-start ">
              <div>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                    <Input
                      disabled={false}
                      type="email"
                      background="grey"
                      tabIndex={1}
                      label={`${t('User Email')}*`}
                      placeholder={`${t('Please enter Email')}`}
                      value={value || ''}
                      error={invalid}
                      helperMessage={
                        invalid && (
                          <InputHelperMessage
                            icon={
                              error ? <Icon.WarningOutline className="h-3 w-3 fill-uiRed" /> : null
                            }
                            message={error?.message}
                          />
                        )
                      }
                      onChange={onChange}
                      showClearButton={false}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex w-full items-start justify-between gap-4">
              <div className="w-1/2 flex-1 items-start">
                <div>
                  <Controller
                    control={control}
                    name="first_name"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={false}
                        type="text"
                        background="grey"
                        tabIndex={1}
                        label={`${t('First Name')}*`}
                        placeholder={`${t('Please enter First Name')}`}
                        value={value || ''}
                        error={invalid}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                        showClearButton={false}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="w-1/2 flex-1 items-start">
                <div>
                  <Controller
                    control={control}
                    name="last_name"
                    render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                      <Input
                        disabled={false}
                        type="text"
                        background="grey"
                        tabIndex={1}
                        label={`${t('Last Name')}*`}
                        placeholder={`${t('Please enter Last Name')}`}
                        value={value || ''}
                        error={invalid}
                        helperMessage={
                          invalid && (
                            <InputHelperMessage
                              icon={
                                error ? (
                                  <Icon.WarningOutline className="h-3 w-3 fill-uiRed" />
                                ) : null
                              }
                              message={error?.message}
                            />
                          )
                        }
                        onChange={onChange}
                        showClearButton={false}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full items-start justify-between gap-4">
              <div className="w-full flex-1 items-start">
                <Typography element="p" variant="meta-1" className="mb-1 text-grey">
                  {t('Language')} *
                </Typography>
                <Controller
                  name="language"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <SelectDropdown
                        fullWidth
                        name="language"
                        placeholder="Select Language"
                        options={LANGUAGES}
                        background="grey"
                        value={value}
                        onChange={onChange}
                      />
                      <div className="mt-1">
                        {error && (
                          <div className="ml-2 flex items-center gap-2 text-error">
                            {error ? <Icon.WarningOutline className="w-4" /> : ''}
                            <Typography element="span" color="error" variant="meta-2">
                              {error ? error.message?.toString() : ''}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-[70px] w-full items-center justify-between rounded-b-xl bg-lightGrey-light px-[24px]">
          <div className="flex w-full items-center justify-end gap-3">
            <Button
              type="button"
              intent="text"
              onClick={() => onClose()}
              className="text-copyTextGrey">
              {t('Cancel')}
            </Button>
            <Button type="button" intent="primary" onClick={handleSubmit(onUpdate)}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
