import { Icon } from '@common/components/foundations/icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@common/components/molecules/Tooltip';
import { cn } from '@common/utils/cn';

export const TooltipIcon = ({
  content,
  icon,
  iconClassName
}: {
  content: string | React.ReactNode;
  icon?: React.ReactNode;
  iconClassName?: string;
}) => {
  return (
    <TooltipProvider delayDuration={75}>
      <Tooltip>
        <TooltipTrigger type="button">
          {icon || (
            <Icon.Help
              className={cn('h-3.5 w-3.5 fill-mediumGrey', iconClassName)}
              data-html2canvas-ignore="true"
            />
          )}
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          className="w-auto max-w-sm overflow-hidden whitespace-normal text-left">
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
