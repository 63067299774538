import { useUser } from '@clerk/clerk-react';
import { Flags } from '@common/constants/flags';
import { ldIdentify } from '@common/helpers/ld-identify';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

export function useClerkConditionalRender() {
  const clerk = useUser();
  const flags = useFlags();
  const ldClient = useLDClient();
  const [renderClerk, setRenderClerk] = useState<boolean | null>(null);

  const getFlags = useCallback(async () => {
    if (ldClient && clerk.isSignedIn) {
      await ldIdentify(ldClient, {
        user: {
          key: 'user',
          clerkId: clerk.user.id,
          email: clerk.user.primaryEmailAddress?.emailAddress
        }
      });
      const tempRenderClerk = await flags[Flags.ShowClerkComponent];
      setRenderClerk(tempRenderClerk);
    }
  }, [ldClient, clerk, flags]);

  useEffect(() => {
    if (clerk && isNil(renderClerk)) getFlags();
  }, [clerk, ldClient, flags]);

  return { renderClerk, clerk };
}
