import clsx from 'clsx';
import { CalendarView } from '@pages/Client/Calendar/components/BigCalendar/CalendarView';
import { useCalendarPageStore } from '@pages/Client/Calendar/store/calendar';
import { DateCellContent } from '@pages/Client/Calendar/pages/Occupancy/components/DateCellContent/DateCellContent';
import styles from '@pages/Client/Calendar/pages/Occupancy/Occupancy.module.css';
import { useHotelEvents } from '@pages/Client/Calendar/hooks/useHotelEvents';
import { API_DATE_FORMAT } from '@common/constants/date';
import dayjs from 'dayjs';
import { useRoomPrices } from '@pages/Client/Calendar/hooks/useRoomPrices';
import { CalendarOptions } from '@pages/Client/Calendar/components/CalendarOptions/CalendarOptions';
import { useHotelRoomsList } from '@pages/Client/Calendar/hooks/useHotelRoomsList';
import React, { useEffect } from 'react';
import { useHotelRoomNotes } from '@pages/Client/Calendar/hooks/useHotelRoomNotes';
import { CurrencyFormatter } from '@common/utils/formatCurrency';
import { Icon } from '@common/components/foundations/icons';
import { Typography } from '@common/components/foundations/Typography';
import { useCalendarInteractions } from '@pages/Client/Calendar/hooks/useCalendarInteractions';
import { CalendarOccupancy } from '@pages/Client/Calendar/components/CalendarOccupancy';
import { useTranslation } from 'react-i18next';
import { Feature, useFeaturesStore } from '@common/store/features';
import { NO_MARKET_DATA } from '@pages/Client/Calendar/constants';
import { OccupancyLegend } from '@pages/Client/Calendar/pages/Occupancy/components/OccupancyLegend';
import { useSurgePrice } from '@pages/Client/Calendar/hooks/useSurgePrice';
import { useSurgeEvents } from '@pages/Client/PricingStrategy/SurgeProtection/hooks/useSurgeProtection';
import { useDocumentTitle } from '@mantine/hooks';
import { getOccupancyColor } from '@pages/Client/Calendar/pages/Occupancy/utils/getOccupancyColor';

export const Occupancy = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('Occupancy Calendar'));
  const { features } = useFeaturesStore();
  const {
    liliusInstance,
    comparePickupTo,
    isTableView,
    setIsTableView,
    setSelectedHotelRoomId,
    initialRoomId
  } = useCalendarPageStore();
  const { getEventNameByDay } = useHotelEvents();
  const { getNoteByDay } = useHotelRoomNotes();
  const { getSurgePriceForDay } = useSurgePrice();
  const { getSurgeEventByDay } = useSurgeEvents();
  const {
    roomPrices,
    pricingSettings,
    cachePriceQuery: { isFetching: isCachePriceLoading }
  } = useRoomPrices();
  const { selectedHotelRoomId } = useHotelRoomsList();
  const interactions = useCalendarInteractions();

  useEffect(() => {
    if (isTableView) {
      setIsTableView(false);
      if (initialRoomId) {
        setSelectedHotelRoomId(initialRoomId);
      }
      return;
    }
  }, []);

  const getPickupNumber = (day: Date) => {
    if (!roomPrices) return 0;

    let pickupLeft: number | string = 0;

    const dateKey = dayjs(day).startOf('day').format(API_DATE_FORMAT);
    const boostPrice = roomPrices?.pickup_boost_prices;

    if (boostPrice?.[dateKey]?.arguments) {
      if (comparePickupTo == 'yesterday') {
        pickupLeft = boostPrice?.[dateKey]?.arguments?.roomsleft_change_vs_yesterday ?? '-';
      }

      if (comparePickupTo == 'last-week') {
        pickupLeft = boostPrice?.[dateKey]?.arguments?.roomsleft_change_vs_7daysago ?? '-';
      }

      if (comparePickupTo == 'last-month') {
        pickupLeft = boostPrice?.[dateKey]?.arguments?.roomsleft_change_vs_30daysago ?? '-';
      }
    }

    return pickupLeft;
  };

  const getCellContent = (day: Date): React.ComponentProps<typeof DateCellContent>['content'] => {
    if (!roomPrices?.prices.data) return {};

    const dateKey = dayjs(day).startOf('day').format(API_DATE_FORMAT);
    const roomPriceData = roomPrices.prices.data?.[dateKey]?.[String(selectedHotelRoomId)] ?? {};
    const propertyData = roomPrices.prices.data?.[dateKey]?.property ?? {};
    const pickupNumber = getPickupNumber(day);

    const { isSurgePrice, surgePrice } = getSurgePriceForDay(dateKey, selectedHotelRoomId);

    return {
      compareText: features?.includes(Feature.PickupBoostPrice)
        ? dayjs(day).startOf('day').isBefore(dayjs().tz(), 'date')
          ? '- Pickup'
          : `${pickupNumber ?? '-'} Pickup`
        : '',
      occupancyPercentage: `${propertyData.occupancy ?? '-'}%`,
      priceText: isSurgePrice
        ? String(CurrencyFormatter.format(surgePrice) ?? '')
        : roomPriceData.price
        ? String(CurrencyFormatter.format(roomPriceData.price) ?? '')
        : ''
    };
  };

  const getNoteAndEvent = (day: Date): string | null => {
    if (!selectedHotelRoomId) return null;
    const formattedDate = dayjs(day).startOf('day').format(API_DATE_FORMAT);
    const noMarketData =
      roomPrices?.prices.data?.[formattedDate]?.property?.note === NO_MARKET_DATA
        ? t('No Market Data')
        : null;
    const note = getNoteByDay(day);
    const event = getEventNameByDay(day);
    const isSurgeEvent = getSurgeEventByDay(day)?.active;

    const surgeEventNote = isSurgeEvent ? t('Surge Event') : null;

    return noMarketData ?? surgeEventNote ?? note ?? event ?? null;
  };

  if (!roomPrices?.prices?.data && !isCachePriceLoading)
    return (
      <div className="mx-auto max-w-md sm:max-w-3xl">
        <div className="w-full rounded-lg border-2 border-dashed border-mediumGrey p-12">
          <div className="flex flex-col items-center gap-4">
            <Icon.Calendar className="text-copyTextGrey" />
            <div className="flex flex-col items-center gap-1">
              <Typography element="span" variant="paragraph-1" color="copyTextGrey">
                {t('Occupancy data not yet available')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      <div className="my-2 grid gap-4 xl:grid-cols-2">
        {isTableView ? null : <CalendarOccupancy />}
        <div className="inline-flex flex-1 items-center justify-end text-paragraph-3 leading-none text-copyTextGrey">
          <OccupancyLegend />
          {isTableView ? null : <CalendarOptions optionType="occupancy" />}
        </div>
      </div>

      <div>
        <CalendarView
          {...interactions}
          context="occupancy"
          topRightCellContent={(day) => <span>{getNoteAndEvent(day)}</span>}
          dateCellClassName={(day) =>
            clsx(styles.occupancyCell, getOccupancyColor(day, roomPrices).cellColorStyles)
          }
          dateCellContent={(date) => <DateCellContent content={getCellContent(date)} />}
          liliusInstance={liliusInstance!}
          pricingSettings={pricingSettings}
          selectedRoomId={pricingSettings?.rooms?.reference?.id}
          roomPrices={roomPrices}
          isLoading={isCachePriceLoading}
          surgeEvent={(day) => {
            const isSurgeEvent = getSurgeEventByDay(day)?.active ?? false;
            const surgePriceForDay = getSurgePriceForDay(
              dayjs(day).format(API_DATE_FORMAT),
              selectedHotelRoomId
            );
            const hasSurgePrice = surgePriceForDay?.isSurgePrice;
            return { isSurgeEvent, hasSurgePrice };
          }}
        />
      </div>
    </div>
  );
};
