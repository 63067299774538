export function FilterList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 7H3.5C3.22386 7 3 6.77614 3 6.5V5.5C3 5.22386 3.22386 5 3.5 5H20.5C20.7761 5 21 5.22386 21 5.5V6.5C21 6.77614 20.7761 7 20.5 7ZM18 12.5V11.5C18 11.2239 17.7761 11 17.5 11H6.5C6.22386 11 6 11.2239 6 11.5V12.5C6 12.7761 6.22386 13 6.5 13H17.5C17.7761 13 18 12.7761 18 12.5ZM15 17.5V18.5C15 18.7761 14.7761 19 14.5 19H9.5C9.22386 19 9 18.7761 9 18.5V17.5C9 17.2239 9.22386 17 9.5 17H14.5C14.7761 17 15 17.2239 15 17.5Z"
      />
    </svg>
  );
}
