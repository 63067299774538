import { create } from 'zustand';

type RoomSetupState = {
  isOccupancyPercentDerivation: boolean;
  setIsOccupancyPercentDerivation: (value: RoomSetupState['isOccupancyPercentDerivation']) => void;
  isAbsoluteAdjustmentToReferenceRoom: boolean;
  setIsAbsoluteAdjustmentToReferenceRoom: (
    value: RoomSetupState['isAbsoluteAdjustmentToReferenceRoom']
  ) => void;
  isApartment: boolean;
  setIsApartment: (value: RoomSetupState['isApartment']) => void;
  newBasePrice: number;
  setNewBasePrice: (value: RoomSetupState['newBasePrice']) => void;
  newMinPrice: number;
  setNewMinPrice: (value: RoomSetupState['newMinPrice']) => void;
  newMaxPrice: number;
  setNewMaxPrice: (value: RoomSetupState['newMaxPrice']) => void;
};

export const useRoomSetupStore = create<RoomSetupState>()((set) => ({
  isOccupancyPercentDerivation: false,
  setIsOccupancyPercentDerivation: (value) => set({ isOccupancyPercentDerivation: value }),
  isAbsoluteAdjustmentToReferenceRoom: false,
  setIsAbsoluteAdjustmentToReferenceRoom: (value) =>
    set({ isAbsoluteAdjustmentToReferenceRoom: value }),
  isApartment: false,
  setIsApartment: (value) => set({ isApartment: value }),
  newBasePrice: 0,
  setNewBasePrice: (value) => set({ newBasePrice: value }),
  newMinPrice: 0,
  setNewMinPrice: (value) => set({ newMinPrice: value }),
  newMaxPrice: 0,
  setNewMaxPrice: (value) => set({ newMaxPrice: value })
}));
