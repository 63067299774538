import { create } from 'zustand';
interface ErrorPasswordStore {
  isError: boolean;
  isOldPasswordError: boolean;
  setError: (newError: boolean) => void;
  setOldPasswordError: (newError: boolean) => void;
  message: string;
  oldPasswordMessage: string;
  setMessage: (message: string) => void;
  setOldPasswordMessage: (message: string) => void;
}

export const useErrorProfileAdminChangePasswordStore = create<ErrorPasswordStore>((set) => ({
  isError: false,
  setError: (newError: boolean) => set({ isError: newError }),
  message: '',
  setMessage: (newMessage: string) => set({ message: newMessage }),
  isOldPasswordError: false,
  setOldPasswordError: (newError: boolean) => set({ isOldPasswordError: newError }),
  oldPasswordMessage: '',
  setOldPasswordMessage: (newMessage: string) => set({ oldPasswordMessage: newMessage })
}));
